import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import ArrowDown from "../../../assets/icons/ArrowDown";
import ArrowUp from "../../../assets/icons/ArrowUp";
import IconDeGrowth from "../../../assets/icons/DegrowthIcon";
import IconGrowth from "../../../assets/icons/GrowthIcon";
import IconNeutral from "../../../assets/icons/NeutralIcon";
import SortDownArrowIcon from "../../../assets/icons/SortDownArrowIcon";
import SortArrowIcon from "../../../assets/icons/SortDownArrowIcon";
import SortUpArrowIcon from "../../../assets/icons/SortUpArrowIcon";
import {
  DefaultFlag,
  DefaultFlagIcon,
  DefaultFlagImage,
  DefaultPostImage,
  defaultSocialIconsUrls,
} from "../../../constants/constants";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import NumberSeparator from "../../../utils/NumberSeparator";
import TextFormatter from "../../../utils/TextFormatter";

import Wrapper from "../../helpers/Wrapper";
import Button from "../Button";
import NoDataAvailableLoader from "../loaders/NoDataAvailable";
import SomethingWentWrong from "../loaders/SomethingWentWrong";
import SectionDefinationsBadge from "../SectionDefinationsBadge";
import CreatableSelect from 'react-select/creatable';
import { useSelector } from "react-redux";
import axios from "../../helpers/axios";
import { useContext } from "react";
import AuthContext from "../../../store/authContext";
import { el, id } from "date-fns/locale";
import moment from "moment";
import { getUniqueKeyWithObj, sumTotals } from "../../../utils/Utils";
import NoDataAvailableChecker from "../../../utils/NoDataAvailableChecker";
import NewNumberSeparator from "../../../utils/NewNumberSeparator";
import Mark from 'mark.js'
import useApi from "../../../hooks/useApi";
import Dropdown from "../dropdown/Dropdown";
import Variables from "../../../styles/mainStyle.scss";


const CheckBoxDatatable = ({
  tableTitle,
  tableHeader,
  tableData,
  tableLength,
  tableDropdown,
  searchPlaceHolder,
  searchSingleColumnNumberRow,
  searchSingleRowPlaceholder,
  searchSingleColumnNumber,
  hrefTitle,
  tableExportDropdown,
  tableID,
  tablePlatform,
  isCountryFlagVisible,
  isDynamicFlagVisible,
  checkbox,
  onCheckboxClickHandler, // ADMIN CAMPAIGN CREATE BUCKET
  clearSelectedItems, // ADMIN CAMPAIGN CREATE BUCKET
  tablePageSubModule,
  tablePagePlatform,
  tablePagePrimaryTab,
  isHalfSection,
  hideBoxShadow,
  showGrowthDegrowth,
  showTotal,
  isCreatableDropdown,
  tableConfigs,
  setTableConfigs,
  tablePaginationData,
  showTotalRow,
  showFixedSpend,
  removeImgExtensions,
  dynamicColumns,
  hasTableLenghtDropdown,
  dynamicCheckboxRef,
  highlightSearch
}) => {

  const createBucketAPI = useApi()
  const addRemoveCampaignsAPI = useApi()

  const searchMenu = useRef()
  if (isCountryFlagVisible === undefined) {
    isCountryFlagVisible = false;
  } else {
    isCountryFlagVisible = isCountryFlagVisible;
  }

  if (removeImgExtensions === undefined) {
    removeImgExtensions = false
  }

  // PERCENTAGE METRICS ARRAY
  const stringHeaderTitles = [
    "Hashtag",
    "Channel Name",
    "Landing Page",
    "Source",
    "Category",
    "Categories",
    "Event Action",
    "Product Name",
    "City Name",
    "Source Type",
    "Name",
    "Platform",
    "Objective",
    "Campaign Name",
    "Zone",
    "State",
    "Creative Name",
    "Full Name",
    "Profile",
    "Age",
    "Gender",
    "Device",
    "Link",
    "Country",
    "City",
    "Campaign Bucket",
    "Start Date",
    "End Date",
    "Brands",
    "Line Item Name",
    "Markets",
    "Brand Name",
    "Region",
    "Medium",
    "Publishing Medium",
    "Ad Name",
    "Geography",
    "Week/Month",
    "VG Group",
    "Date",
    'Keyword',
    "Ad Group Name",
    'Ad ID',
    'Search Term',
    'Landing Page',
    'Campaign Group Name',
    'Ad group Name',
    'Keyword Name',
    'Post ID',
    'Creative ID',
    'Creative',
    'Line Item',
    'Bucket Name',
    'Ad Set Name',
  ];


  const IDFields = ['id']

  const [filterTableheaders, setFilterTableheaders] = useState(tableHeader)

  const HandleTableheaderSearch = (e) => {

    const filterTable = tableHeader.filter((x) => {
      if (x.name.toLowerCase().includes(e.target.value.toLowerCase())) {
        return x
      }

    });

    setFilterTableheaders(filterTable)
  }


  const [updateTableLength, setUpdateTableLength] = useState(tableLength)

  const [formattedTableHeaders, setFormattedTableHeaders] = useState([]);

  const [isDynamicTableSearrchDisabled, setisDynamicTableSearrchDisabled] = useState(false)

  const [showDynamicDropdown, setshowDynamicDropdown] = useState(false)

  const closeOpenMenus = (e) => {

    if (
      searchMenu.current &&
      showDynamicDropdown &&
      !searchMenu.current.contains(e.target)
    ) {
      setshowDynamicDropdown(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);


  const HandleDisableColumn = () => {

    let enabledVals = []
    tableHeader.map((item) => {
      let getCheckbox = document.getElementById(item.field);

      if (getCheckbox.checked) {
        enabledVals.push(item)
      }
    })

    enabledVals.length > 1 ? setisDynamicTableSearrchDisabled(false) : setisDynamicTableSearrchDisabled(true)
  }


  const HandleColumnChange = () => {
    let tableValues = tableHeader.map((item) => {

      let getCheckbox = document.getElementById(item.field);

      if (getCheckbox === null) return

      if (!getCheckbox.checked) return

      return ({
        ...item
      })

    }).filter((x) => x)

    setFormattedTableHeaders(tableValues)
    setshowDynamicDropdown(false)
  }


  const HandleClearColumnChange = () => {
    let tableValues = tableHeader.map((item, num) => {

      let getCheckbox = document.getElementById(item.field);
      let getSelectAll = document.getElementById('select_all');

      if (getCheckbox === null) return
      getCheckbox.checked = false
      getSelectAll.checked = false;

      if (num <= 2) {
        getCheckbox.checked = true

        return ({
          ...item
        })
      }
    }).filter((x) => x)

  }



  useEffect(() => {
    if (dynamicColumns) {
      HandleColumnChange();
    } else {
      setFormattedTableHeaders(tableHeader)
    }
  }, [])

  const authCtx = useContext(AuthContext);

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const [fixedBottomRow, setFixedBottomRow] = useState([])

  // PERCENTAGE METRICS ARRAY
  const capsDataList = ["Tv"];

  const growthIconMetrics = [
    "Sales Vol. Change",
    "Sales Val. Change",
    "Change",
    // "Ad Name"
  ];


  const HeaderTitleAlignmentSetter = (headerTitle) => {
    if (stringHeaderTitles.includes(headerTitle)) {
      return true;
    } else {
      return false;
    }
  };

  let dataLength = 0;

  if (tableData.length === 0 || typeof tableData !== "array") {
    dataLength = 0;
  } else {
    dataLength = tableData.length;
  }

  let tempHref;

  if (hrefTitle === undefined) {
    tempHref = "Visit Link";
  } else {
    tempHref = hrefTitle;
  }

  const DefaultSortFieldHandler = () => {
    if (
      tableData.length !== 0 ||
      tableData === undefined
    ) {
      for (
        let tableDataIndex = 0;
        tableDataIndex < tableData.length;
        tableDataIndex++
      ) {
        for (
          let dataObjectIndex = 0;
          dataObjectIndex < Object.keys(tableData[tableDataIndex]).length;
          dataObjectIndex++
        ) {
          if (
            typeof Object.values(tableData[tableDataIndex])[dataObjectIndex] ===
            "number" &&
            Object.values(tableData[tableDataIndex])[dataObjectIndex] !== 0
          ) {
            return Object.keys(tableData[tableDataIndex])[dataObjectIndex];
          }
        }
      }
    } else {
      return "";
    }
  };

  const [totalItems, setTotalItems] = useState(tablePaginationData?.total_records ?? dataLength);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState(1);

  const [searchValue, setSearchValue] = useState(null)
  const [searchTerm, setSeachTerm] = useState(null);
  const [searchSingleColumnData, setSearchSingleColumnData] = useState("");

  const [sortingField, setSortingField] = useState(tableConfigs?.order_by ?? DefaultSortFieldHandler);

  const [sortingOrder, setSortingOrder] = useState(tableConfigs?.order_type ?? "asc");

  const handleTableConfigs = (data) => {
    if (tableConfigs !== undefined && setTableConfigs !== undefined) {
      if (tableConfigs.page_num !== data) {
        setTableConfigs({ ...tableConfigs, page_num: data })
      }
    }
  }

  useEffect(() => {

    if (tableConfigs !== undefined && setTableConfigs !== undefined) {
      if (searchTerm !== null) {
        const delayDebounceFn = setTimeout(() => {
          // setTableConfigs({ ...tableConfigs, search_value: searchTerm, page_num: 1 })
        }, 1500)
        return () => clearTimeout(delayDebounceFn)
      }
    }

  }, [searchTerm])


  const SearchHandler = () => {
    if (tableConfigs !== undefined && setTableConfigs !== undefined) {
      if (searchTerm !== null) {
        setTableConfigs({ ...tableConfigs, search_value: searchTerm, page_num: 1 })
      }
    }
  }


  function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  const onSortingChange = (field) => {
    let order = "";
    if (field === sortingField && sortingOrder === "asc") {
      order = "desc";
    } else {
      order = "asc";
    }

    if (tableConfigs !== undefined && setTableConfigs !== undefined) {
      setTableConfigs({ ...tableConfigs, order_by: field, order_type: order })
    }
    setSortingField(field);
    setSortingOrder(order);
  };


  useEffect(() => {

    if (highlightSearch) {


      var instance = new Mark(document.getElementById(tableID));
      if (searchTerm) {

        setTimeout(() => {
          instance.mark(searchTerm, {
            exclude: ['th', '.th_wrapper', '.th_title'],
          })
        }, 1);

      }

      return () => {
        instance.unmark()
      }
    }

  }, [searchTerm, sortingField, sortingOrder, currentPage, updateTableLength])


  function tableRowFilter(data, searchTerm) {
    let sampleArr = Object.keys(data);
    for (let k = 0; k < sampleArr.length; k++) {
      if (
        data[Object.keys(data)[k]]
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      ) {
        return true;
      }
    }
  }

  function tableRowFilter2(data, searchSingleColumnData) {
    // let sampleArr = Object.keys(data);
    if (
      // data[Object.keys(data)[k]] === "Platform" &&
      data[Object.keys(data)[searchSingleColumnNumber]] //searchSingleColumnNumber is column number from the api
        .toString()
        .toLowerCase()
        .includes(searchSingleColumnData.toLowerCase())
    ) {
      return true;
    }
  }

  const filteredTableData = useMemo(() => {
    let unfilreredTableData = tableData;
    if (searchTerm) {
      unfilreredTableData = unfilreredTableData.filter((data) => {

        return tableRowFilter(data, searchTerm)
      }
      );
    }
    if (searchSingleColumnData) {
      unfilreredTableData = unfilreredTableData.filter((data) =>
        tableRowFilter2(data, searchSingleColumnData)
      );
    }

    //SORTING


    if (sortingField && tableConfigs === undefined) {
      const reversed = sortingOrder === 'asc' ? 1 : -1;
      unfilreredTableData = unfilreredTableData.sort((a, b) => {
        if (isNumber(a[sortingField]) && isNumber(b[sortingField])) {
          if (sortingOrder === 'asc') {
            return b[sortingField] - a[sortingField];
          } else {
            return a[sortingField] - b[sortingField];
          }
        } else {
          return a[sortingField] === undefined || b[sortingField] === undefined
            ? reversed * 0
            : reversed * a[sortingField].localeCompare(b[sortingField]);
        }
      });
    }

    if (tableConfigs === undefined) {
      setTotalItems(unfilreredTableData.length);
    }

    return unfilreredTableData.slice(
      (currentPage - 1) * updateTableLength,
      (currentPage - 1) * updateTableLength + updateTableLength
    );


  }, [
    tableHeader,
    tableData,
    currentPage,
    searchTerm,
    sortingField,
    sortingOrder,
    searchSingleColumnNumberRow,
    searchSingleColumnData,
    searchSingleColumnNumber,
    updateTableLength
  ]);


  useEffect(() => {
    if (tableConfigs === undefined) {
      if (totalItems > 0 && updateTableLength > 0) {
        setTotalPages(Math.ceil(totalItems / updateTableLength));
      }
    }
  }, [totalItems, updateTableLength]);

  useEffect(() => {
    if (tablePaginationData !== undefined) {
      setTotalPages(tablePaginationData.total_pages)
      setCurrentPage(tablePaginationData.current_page)
    }

  }, [tablePaginationData])



  useEffect(() => {
    if (tableConfigs === undefined) {
      checkedItems.length === 0 && setCurrentPage(1);
    }

    if (!NoDataAvailableChecker(tableData)) {
      var tempValues = sumTotals(tableData)
      // setFixedBottomRow([tempValues])
    }

  }, [tableData, tableHeader]);


  // CREATABLE DROPDOWN

  const [isCreatableDropdownLoading, setIsCreatableDropdownLoading] = useState(false);
  const [creatableDropdownValue, setCreatableDropdownValue] = useState(null);
  const [creatableDropdownOptions, setCreatableDropdownOptions] = useState([]);
  const [creatableDropdownNewOption, setCreatableDropdownNewOption] = useState([]);
  const [creatableDropdownNewOptionName, setCreatableDropdownNewOptionName] = useState(null);
  const [creatableDropdownAllData, setCreatableDropdownAllData] = useState([])

  useEffect(() => {
    const fetchDropdownOptionsHandler = async () => {
      setIsCreatableDropdownLoading(true);

      const config = {
        params: {
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/google/bucket-list/", config);

        setCreatableDropdownOptions(
          res?.data?.data?.map((el) => ({ label: el.bucket_name, value: el.bucket_id, isFixed: true, isDisabled: false }) ?? [])
        );
        setCreatableDropdownAllData(res?.data?.data ?? [])

        setIsCreatableDropdownLoading(false);
      } catch (error) {
        setCreatableDropdownOptions([]);
        setCreatableDropdownAllData([])
        setIsCreatableDropdownLoading(false);
      }
    };

    fetchDropdownOptionsHandler()
  }, [authCtx, selectedDates])



  const handleCreate = (inputValue, el) => {
    var tempArr = el.bucket.split(',')[0] === '' ? [] : el.bucket.split(',').map((i) => ({ label: i, value: i.toLowerCase().replace(/\W/g, ''), isFixed: true }))



    setIsCreatableDropdownLoading(true);
    setTimeout(() => {
      const newOption = createableDropdownOption(inputValue);
      setIsCreatableDropdownLoading(false);
      setCreatableDropdownOptions((prev) => [...prev, newOption]);
      setCreatableDropdownNewOptionName(inputValue)
      // setCreatableDropdownValue(newOption);
      setCreatableDropdownValue([...tempArr, { label: inputValue, value: inputValue.toLowerCase().replace(/\W/g, ''), isFixed: true }])
    }, 1000);
  };

  const createableDropdownOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
    isFixed: true
  });

  const styles = {
    control: (base, state) => ({
      ...base,
      flex: 1,
      autosize: false,
      background: Variables.light,
      borderRadius: 7,
      width: 200,
      height: 40,
      borderColor: state.isFocused ? Variables.primary500 : Variables.gray50,
      boxShadow: state.isFocused ? null : null,
      textColor: Variables.primary500,
      "&:hover": {
        borderColor: state.isFocused
          ? Variables.primary500
          : Variables.primary500,
        cursor: "pointer",
      },
    }),
    menu: (base) => ({
      ...base,
      autosize: false,
      borderRadius: 7,
      marginTop: 5,
      zIndex: 999,
      // width: 300,
    }),

    menuList: (base) => ({
      ...base,
      autosize: false,
      fontSize: 14,
      padding: 0,
      height: 150
    }),
    groupHeading: (base) => ({
      ...base,
      padding: 5,
      backgroundColor: '#CEDFE8',
      color: '#213345',
      fontSize: '12px',
      fontWeight: 'bold',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        autosize: false,
        backgroundColor: isFocused ? Variables.dropdownBg : isDisabled ? '#eee' : null,
        color: isFocused
          ? Variables.dropdownOptionFocused
          : isDisabled ? "#222" : Variables.dropdownOptions,
        cursor: isDisabled ? 'not-allowed' : null
      };
    },
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: "none" } : base;
    }
  };


  useEffect(() => {

    const CreateBucketHandler = async () => {

      try {
        const data = {
          name: creatableDropdownNewOptionName,
          add_items: {
            [creatableDropdownNewOption[0].platform]: [creatableDropdownNewOption[0].campaign_id]
          }
        }
        createBucketAPI.postRawJSONData('performance/google/bucket/', JSON.stringify(data)).then(() => {
          window.location.reload();
        })

      } catch (error) {
        console.log(error)
      }
    };


    (creatableDropdownNewOption.length !== 0 && creatableDropdownNewOptionName !== null) && CreateBucketHandler()
  }, [creatableDropdownNewOption, creatableDropdownNewOptionName])


  const [editCampaignBucket, setEditCampaignBucket] = useState([])
  const [editCampaignBucketSingleElement, setEditCampaignBucketSingleElement] = useState([])

  const [editCampaignBucketIdName, setEditCampaignBucketIdName] = useState(null)


  // TO GENERATE CATEGORY TAGS
  const generateTags = (data, el) => {
    let temp = [];
    var str_array = data === null ? null : data.split(",");
    if (str_array !== null) {
      for (var i = 0; i < str_array.length; i++) {
        str_array[i] = str_array[i].replace(/^\s*/, "").replace(/\s*$/, "");
        temp.push(str_array[i]);
      }
    }


    const HandleChange = (newValue) => {



      const unique = tableData.filter(
        (obj, index) =>
          tableData.findIndex((item) => (item.name === el.name && item.start_date === el.start_date && item.end_date === el.end_date)) === index
      );


      var newUnique = unique.map(({ bucket, ...rest }) => {
        var temp = bucket.split(',');
        temp.push(newValue[newValue.length - 1]?.label ?? '');
        temp.join(",")


        return ({
          bucket: temp.join(","), ...rest
        })

      })


      tableData.push(...newUnique)

      setEditCampaignBucketSingleElement(el)

      var editBucket = newValue[newValue.length - 1]
      var filteredItem = creatableDropdownAllData.filter((item) => item.name === editBucket.label ? item : null)


      var updatedDate = el;
      updatedDate.start_date = el.start_date === null || el.start_date === "" ? "" : moment(el.start_date).format("DD MMM yyyy");
      updatedDate.end_date = el.end_date === null || el.end_date === "" ? "" : moment(el.end_date).format("DD MMM yyyy");


      var existingItems = filteredItem.map((x) => {
        var fooBar = x.items;

        var newMappedArr = fooBar.map(({ start_date, end_date, ...rest }) => ({
          start_date: start_date === null || start_date === "" ? "" : moment(start_date).format("DD MMM yyyy"),
          end_date: end_date === null || end_date === "" ? "" : moment(end_date).format("DD MMM yyyy"),
          ...rest
        }))

        return [...newMappedArr, updatedDate]

      })

      setEditCampaignBucket(existingItems);


      setEditCampaignBucketIdName(filteredItem.map((x) => ({ id: x.id, name: x.name })))




      var tempArr = el.bucket.split(',').map((i) => i);

      if (newValue.filter((item) => tempArr.includes(item.label))) {
        setCreatableDropdownValue(newValue)
      } else {
        setCreatableDropdownValue([...tempArr, ...newValue])
      }
    }

    if (isCreatableDropdown) {
      var creatableDropdownOptionsCheck = creatableDropdownOptions === undefined || creatableDropdownOptions === null || creatableDropdownOptions.length === 0 ? [] : creatableDropdownOptions
      var vals = el.bucket.split(',').length === 0 || el.bucket.split(',')[0] === '' ? creatableDropdownOptionsCheck : el.bucket.split(',')
      var filterDisabledOptions = creatableDropdownOptions === undefined || creatableDropdownOptions === null || creatableDropdownOptions.length === 0 ? [] : creatableDropdownOptions.map((item) => {

        if (vals.includes(item.label)) {
          return {
            ...item,
            isDisabled: true
          }

        } else {
          return item
        }
      })
    }



    let metricCardsSection = (
      <Wrapper>
        <ErrorBoundary>
          {temp.map((tempdata, index) => (
            <div key={index} className="badge_wrapper">
              <button type="button" className="button_badge">
                {tempdata}
              </button>
            </div>
          ))}


          {isCreatableDropdown && el && <div id={getUniqueKeyWithObj(el).join("_")} style={{ display: 'none' }} className="camp_admin_dd">
            <CreatableSelect
              isClearable={false}
              isMulti
              options={filterDisabledOptions}
              isDisabled={isCreatableDropdownLoading}
              menuPlacement={'auto'}
              isLoading={isCreatableDropdownLoading}
              onChange={(newValue) => HandleChange(newValue, el)}
              onCreateOption={(item) => handleCreate(item, el)}
              formatCreateLabel={(el) => 'Create New Bucket "' + el + '"'}
              value={creatableDropdownValue}
              styles={styles}
            />
          </div>}


          {el && <button className="button_badge" key={getUniqueKeyWithObj(el).join("_")} id={getUniqueKeyWithObj(el).join("_") + "_btn"} onClick={(e) => showRow(e, el)}>+</button>}

        </ErrorBoundary>
      </Wrapper>
    );

    return metricCardsSection;
  };


  useEffect(() => {
    const EditBucketHandler = async () => {
      setIsCreatableDropdownLoading(true)


      try {

        const configs = {
          'name': creatableDropdownValue[0]?.label ?? '',
          'add_items': {
            [editCampaignBucketSingleElement.platform]: [editCampaignBucketSingleElement.campaign_id]
          }
        }

        let bucketId = creatableDropdownValue[0]?.value ?? ''

        await addRemoveCampaignsAPI.patchRawJSONData(`performance/google/bucket/${bucketId}`, JSON.stringify(configs))
          .then(() => window.location.reload())
          .catch(() => {
            setIsCreatableDropdownLoading(false)
          })
      } catch (error) {
        console.log(error)
      }

    };


    (editCampaignBucketSingleElement !== null && creatableDropdownValue !== null) && EditBucketHandler();
  }, [editCampaignBucket, editCampaignBucketSingleElement])

  const showRow = (e, el) => {

    setCreatableDropdownNewOption([el])
    setCreatableDropdownValue(null)
    tableData.map((item) => {
      var foo = document.getElementById(getUniqueKeyWithObj(item).join("_"));
      var btn = document.getElementById(getUniqueKeyWithObj(item).join("_") + "_btn");

      if (foo === null || foo === undefined) {

      } else {
        foo.style.display = "none"
        btn.style.display = 'block'
      }

    })

    let blockEl = getUniqueKeyWithObj(el).join('_')

    document.getElementById(blockEl).style.display = "block";
    document.getElementById(blockEl + "_btn").style.display = "none"
  }

  // SELECTED CAMPAIGN IN BUCKET
  const [checkedItems, setCheckedItems] = useState(dynamicCheckboxRef ?? []);


  const onCheckboxClick = (tableRow, isChecked) => {

    if (checkedItems.some((a) => JSON.stringify(a) === JSON.stringify(tableRow)) && !isChecked) {

      checkedItems.splice(checkedItems.findIndex((b) => JSON.stringify(b) === JSON.stringify(tableRow)), 1);
      setCheckedItems([...checkedItems]);

    } else if (isChecked) {

      let itemExists = checkedItems.some((item) => JSON.stringify(item) === JSON.stringify(tableRow) ? true : false)

      if (!itemExists) {
        checkedItems.push(tableRow);
        setCheckedItems([...checkedItems]);
      }
    }

    onCheckboxClickHandler(checkedItems);
  };


  const HandleRowCheckboxSelectAll = (ev) => {

    checkTable.map((row) => {
      let rowElement = document.getElementById(getUniqueKeyWithObj(row).join('_'));

      if (ev.target.checked) {
        rowElement.checked = true
      } else {
        rowElement.checked = false
      }

      onCheckboxClick(row, rowElement.checked)
    })

  }

  const ClearRowCheckbox = (ev) => {
    let selectAllBox = document.getElementById('checktable-select-all');
    selectAllBox.checked = false;

    checkTable.map((row) => {
      let rowElement = document.getElementById(getUniqueKeyWithObj(row).join('_'));

      rowElement.checked = false

      onCheckboxClick(row, rowElement.checked)
    })
  }


  const flagIconUrls = DefaultFlagImage;
  function handleImage(data) {
    var temp;

    for (let i = 0; i < flagIconUrls.length; i++) {
      if (data === flagIconUrls[i].title) {
        temp = flagIconUrls[i].iconUrl;
        break;
      } else {
        temp = DefaultFlagIcon;
      }
    }

    return temp;
  }

  // Calculate the total of number type columns
  function calculateTotalOfNumberColumns(data) {
    // Object to store the totals of number columns
    const numberColumnsTotal = {};

    // Initialize all columns with "" (empty)
    data.forEach((row) => {
      for (const key in row) {
        numberColumnsTotal[key] = "";
      }
    });

    // Iterate through each row of the data
    data.forEach((row) => {
      // Iterate through each column of the row
      for (const key in row) {
        // Check if the value is a number
        if (typeof row[key] === "number") {
          // If the column is "" (empty), initialize it to the current value
          if (numberColumnsTotal[key] === "") {
            numberColumnsTotal[key] = row[key];
          }
          // If the column has been encountered before, add the current value to the total
          else {
            numberColumnsTotal[key] += row[key];
          }
        }
      }
    });

    return numberColumnsTotal;
  }

  // Calculate the total of number type columns
  const totalNumberColumns = calculateTotalOfNumberColumns(filteredTableData);
  var checkTable = tableConfigs === undefined ? filteredTableData : tableData;

  let DataTable;


  useEffect(() => {
    checkbox && updateSelectAllCheckbox()
  }, [currentPage, sortingField, sortingOrder, updateTableLength, searchTerm,])


  function updateSelectAllCheckbox() {
    const selectAllBox = document.getElementById('checktable-select-all');

    let boolArr = []

    checkTable.map((row) => {

      let rowElement = document.getElementById(getUniqueKeyWithObj(row).join('_'));

      if (rowElement === null) return

      boolArr.push(rowElement.checked)

    })

    if (selectAllBox) {
      if (boolArr.filter(x => x === false).length === 0) {
        selectAllBox.checked = true
      } else {
        selectAllBox.checked = false
      }
    }

  }



  try {
    DataTable = (
      <Wrapper>
        <table id={tableID} className="table table_striped table_bordered">
          <thead>
            <tr>
              {checkbox && <th>
                <input type="checkbox" id="checktable-select-all" className="checktable-input-box" onChange={HandleRowCheckboxSelectAll} />
              </th>}

              {formattedTableHeaders.map((header) => (
                <th
                  key={header.name}
                  onClick={() => (
                    header.sortable === undefined &&
                    onSortingChange(header.field),
                    setCurrentPage(1)
                  )}
                >
                  <div
                    className={
                      HeaderTitleAlignmentSetter(header.name) === true
                        ? "th_wrapper"
                        : "th_wrapper flex justify_end"
                    }
                  >
                    <div className="th_title">{header.name}</div>
                    {(header.sortable === undefined && tableConfigs === undefined) ? (
                      <div className="icon_wrapper">
                        <ArrowUp
                          className={
                            sortingField &&
                              sortingField === header.field &&
                              sortingOrder === "asc"
                              ? "icon active"
                              : "icon"
                          }
                        />
                        <ArrowDown
                          className={
                            sortingField &&
                              sortingField === header.field &&
                              sortingOrder === "desc"
                              ? "icon active"
                              : "icon"
                          }
                        />
                      </div>
                    ) : (
                      <div className="icon_wrapper">
                        <ArrowUp
                          className={
                            sortingField &&
                              sortingField === header.field &&
                              sortingOrder === "desc"
                              ? "icon active"
                              : "icon"
                          }
                        />
                        <ArrowDown
                          className={
                            sortingField &&
                              sortingField === header.field &&
                              sortingOrder === "asc"
                              ? "icon active"
                              : "icon"
                          }
                        />
                      </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {checkTable.map((tableRow, tableRowIndex) =>
              checkbox === true ? (
                // TO ADD CHECKBOX IN THE FIRST COLUMN OF THE ROW

                <tr
                  // onClick={() => onCheckboxClick(tableRow)}
                  // className={
                  //   // checkedItems.some((a) => a === tableRow) === true
                  //   //   ? "active"
                  //   //   :
                  //   "noactive"
                  // }
                  key={getUniqueKeyWithObj(tableRow).join('_') + tableRowIndex}
                >
                  <td>

                    <input type="checkbox"
                      id={getUniqueKeyWithObj(tableRow).join('_')}
                      checked={checkedItems.some((a) => JSON.stringify(a) === JSON.stringify(tableRow)) ? true : false}
                      className="checktable-input-box checkbox_table_row"
                      onChange={(e) => {
                        onCheckboxClick(tableRow, e.target.checked)
                        updateSelectAllCheckbox()
                      }}
                    />
                  </td>


                  {formattedTableHeaders.map((tableTD, tableTDIndex) =>
                    tableRow[tableTD.field] === undefined ? (
                      <td key={tableTDIndex}>0</td>
                    ) : tableRow[tableTD.field] === "" ||
                      tableRow[tableTD.field] === "NA" ||
                      tableRow[tableTD.field] === null ||
                      tableRow[tableTD.field] === " " ? (
                      <td
                        className="no_data_align"
                        data-label={tableTD.name}
                        key={tableTDIndex}
                      >
                        N/A
                      </td>
                    ) : tableTDIndex === 0 ? (
                      <td
                        key={tableTDIndex}
                        data-label={tableTD.name}
                        className={
                          isNumber(tableRow[tableTD.field]) === true
                            ? "td_checkbox number_align"
                            : "td_checkbox text_align"
                        }
                        title={tableRow[tableTD.field]}
                      >
                        {/* <input
                          className="input_checkbox"
                          type="checkbox"
                          id={tableRow.platform_campaign_id}
                          name={tableRow.platform_campaign_id}
                          value={tableRow}
                          onChange={() => onCheckboxClick(tableRow)}
                          defaultChecked={checkedItems.some((a) => a === tableRow) === true ? true : false}
                        /> */}
                        {isNumber(tableRow[tableTD.field]) === true ? (
                          tableTD.name === "Viral Grade" ? (
                            <ErrorBoundary>
                              <div className="cell_badge_wrapper">
                                <NumberSeparator
                                  fixedSpend={showFixedSpend}
                                  platform={tablePlatform}
                                  title={tableTD.name}
                                  number={tableRow[tableTD.field]}
                                />
                                {tableRow[tableTD.field] > 0 && (
                                  <div className="badge_wrapper">
                                    {tableRow[tableTD.field] < 1000 ? (
                                      <div className="badge positive">A</div>
                                    ) : (
                                      <div className="badge negative">B</div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </ErrorBoundary>
                          ) : showGrowthDegrowth ? (
                            <ErrorBoundary>
                              <div className="cell_badge_wrapper hello_469">
                                <NumberSeparator
                                  fixedSpend={showFixedSpend}
                                  platform={tablePlatform}
                                  title={tableTD.name}
                                  number={tableRow[tableTD.field]}
                                />
                                {tableRow[tableTD.field] !== 0 ? (
                                  <div className="badge_wrapper">
                                    {tableRow[tableTD.field] > 0 ? (
                                      <div className="icon">
                                        <IconGrowth />
                                      </div>
                                    ) : (
                                      <div className="icon">
                                        <IconDeGrowth />
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div className="badge_wrapper">
                                    {
                                      <div className="icon">
                                        <IconNeutral />
                                      </div>
                                    }
                                  </div>
                                )}
                              </div>
                            </ErrorBoundary>
                          ) : // TO SHOW GROWTH ICONS
                            growthIconMetrics.includes(tableTD.name) ? (
                              <ErrorBoundary>
                                <div className="cell_badge_wrapper">
                                  <NumberSeparator
                                    fixedSpend={showFixedSpend}
                                    platform={tablePlatform}
                                    title={tableTD.name}
                                    number={tableRow[tableTD.field]}
                                  />
                                  {tableRow[tableTD.field] !== 0 ? (
                                    <div className="badge_wrapper">
                                      {tableRow[tableTD.field] > 0 ? (
                                        <div className="icon">
                                          <IconGrowth />
                                        </div>
                                      ) : (
                                        <div className="icon">
                                          <IconDeGrowth />
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="badge_wrapper">
                                      {
                                        <div className="icon">
                                          <IconNeutral />
                                        </div>
                                      }
                                    </div>
                                  )}
                                </div>
                              </ErrorBoundary>
                            ) : (
                              <ErrorBoundary>
                                <NumberSeparator
                                  fixedSpend={showFixedSpend}
                                  platform={tablePlatform}
                                  title={tableTD.name}
                                  number={tableRow[tableTD.field]}
                                />
                              </ErrorBoundary>
                            )
                        ) : // TO MAKE URL LINK CLICKABLE
                          tableRow[tableTD.field].match(
                            /^(https?:\/\/[a-zA-Z0-9_+%-]+(\.[a-zA-Z0-9+\_%-]+)*(:[0-9]{1, 5})?(\/[a-zA-Z0-9+()?#~=&\._%-]*)*)?$/
                          ) !== null &&
                            tableRow[tableTD.field].match(
                              /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                            ) === null &&
                            tableRow[tableTD.field].includes("ggpht") ===
                            false ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  '<a target="_blank" href=' +
                                  tableRow[tableTD.field] +
                                  ">" +
                                  tempHref +
                                  "</a>",
                              }}
                            />
                          ) : // TO TRUNKATE THE LINK AFTER 50 CHARACTERS
                            tableTD.name !== "Campaign Bucket" &&
                              tableRow[tableTD.field].length > 50 &&
                              tableRow[tableTD.field].match(
                                /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                              ) == null &&
                              tableRow[tableTD.field].includes("ggpht") ===
                              false ? (
                              <ErrorBoundary>
                                <div title={tableRow[tableTD.field]}>
                                  <TextFormatter
                                    string={
                                      tableRow[tableTD.field].substring(0, 40) +
                                      "..."
                                    }
                                  />
                                </div>
                              </ErrorBoundary>
                            ) : // TO SHOW IMAGE IN A COLUMN
                              tableRow[tableTD.field].match(
                                /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                              ) != null ||
                                tableRow[tableTD.field].includes("ggpht") === true ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      '<img style="width:50px" src=' +
                                      tableRow[tableTD.field] +
                                      ">",
                                  }}
                                />
                              ) : tableTD.name === "Categories" ||
                                tableTD.name === "Campaign Bucket" ? (
                                <div className="badges_list_wrapper">
                                  {generateTags(tableRow[tableTD.field])}
                                </div>
                              ) : defaultSocialIconsUrls.find((element) => {
                                if (
                                  element.title.toLowerCase() ===
                                  tableTD.name.toLowerCase()
                                ) {
                                  return true;
                                }
                              }) ? (
                                <div className="badges_list_wrapper" key={getUniqueKeyWithObj(tableRow).join("_")}>
                                  {isCreatableDropdown ? generateTags(tableRow[tableTD.field], tableRow) : generateTags(tableRow[tableTD.field])}
                                </div>
                              ) : // TO SHOW COUNTRY FLAG
                                isCountryFlagVisible === true ? (
                                  <Wrapper>
                                    <div className="country_flag_section">
                                      <div className="country_flag_wrapper">
                                        {/*  <img
                                  className="flag_img"
                                  src={DefaultFlagImage}
                                /> */}
                                        <img
                                          className="flag_img2"
                                          src={handleImage(tableRow[tableTD.field])}
                                        />
                                      </div>
                                      <ErrorBoundary>
                                        <TextFormatter
                                          string={tableRow[tableTD.field]}
                                        />
                                      </ErrorBoundary>
                                    </div>
                                  </Wrapper>
                                ) : // TO SHOW CITY FLAG
                                  isDynamicFlagVisible === true ? (
                                    <Wrapper>
                                      <div className="country_flag_section">
                                        <div className="country_flag_wrapper">
                                          <img
                                            className="flag_img"
                                            src={DefaultFlagIcon}
                                          />
                                          <span className="cityabb">
                                            <TextFormatter
                                              string={
                                                tableRow[tableTD.field].match(
                                                  /[^\u0000-\u007F]+/
                                                )
                                                  ? tableRow[tableTD.field]
                                                    .match(/[^\u0000-\u007F]+/)
                                                    .join("")
                                                    .substring(0, 2)
                                                  : tableRow[tableTD.field].indexOf(
                                                    " "
                                                  ) >= 0 &&
                                                    tableRow[tableTD.field].indexOf(",") >
                                                    0
                                                    ? tableRow[tableTD.field].substring(
                                                      0,
                                                      2
                                                    )
                                                    : tableRow[tableTD.field].indexOf(
                                                      " "
                                                    ) >= 0 &&
                                                      tableRow[tableTD.field].indexOf(",") <
                                                      0
                                                      ? tableRow[tableTD.field]
                                                        .match(/\b\w/g)
                                                        .join("")
                                                        .substring(0, 2) ||
                                                      tableRow[tableTD.field]
                                                        .match(/[^\u0000-\u007F]+/)
                                                        .join("")
                                                        .substring(0, 2)
                                                      : tableRow[tableTD.field].substring(
                                                        0,
                                                        2
                                                      )
                                              }
                                            />
                                          </span>
                                        </div>
                                        <ErrorBoundary>
                                          <TextFormatter
                                            string={tableRow[tableTD.field]}
                                          />
                                        </ErrorBoundary>
                                      </div>
                                    </Wrapper>
                                  ) : (
                                    <ErrorBoundary>
                                      <TextFormatter string={tableRow[tableTD.field]} />
                                    </ErrorBoundary>
                                  )}
                      </td>
                    ) : (
                      <td
                        key={tableTDIndex}
                        data-label={tableTD.name}
                        title={tableRow[tableTD.field]}
                        className={
                          isNumber(tableRow[tableTD.field]) === true
                            ? "number_align"
                            : "text_align"
                        }
                      >
                        {isNumber(tableRow[tableTD.field]) === true ? (
                          tableTD.name === "Viral Grade" ? (
                            <ErrorBoundary>
                              <div className="cell_badge_wrapper">
                                <NumberSeparator
                                  fixedSpend={showFixedSpend}
                                  platform={tablePlatform}
                                  title={tableTD.name}
                                  number={tableRow[tableTD.field]}
                                />
                                {tableRow[tableTD.field] > 0 && (
                                  <div className="badge_wrapper">
                                    {tableRow[tableTD.field] < 1000 ? (
                                      <div className="badge positive">A</div>
                                    ) : (
                                      <div className="badge negative">B</div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </ErrorBoundary>
                          ) : // TO SHOW GROWTH ICONS
                            growthIconMetrics.includes(tableTD.name) ? (
                              <ErrorBoundary>
                                <div className="cell_badge_wrapper">
                                  <NumberSeparator
                                    fixedSpend={showFixedSpend}
                                    platform={tablePlatform}
                                    title={tableTD.name}
                                    number={tableRow[tableTD.field]}
                                  />
                                  {tableRow[tableTD.field] !== 0 ? (
                                    <div className="badge_wrapper">
                                      {tableRow[tableTD.field] > 0 ? (
                                        <div className="icon">
                                          <IconGrowth />
                                        </div>
                                      ) : (
                                        <div className="icon">
                                          <IconDeGrowth />
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="badge_wrapper">
                                      {
                                        <div className="icon">
                                          <IconNeutral />
                                        </div>
                                      }
                                    </div>
                                  )}
                                </div>
                              </ErrorBoundary>
                            ) : (
                              <ErrorBoundary>
                                <NumberSeparator
                                  fixedSpend={showFixedSpend}
                                  platform={tablePlatform}
                                  title={tableTD.name}
                                  number={tableRow[tableTD.field]}
                                />
                              </ErrorBoundary>
                            )
                        ) : // TO MAKE URL LINK CLICKABLE
                          tableRow[tableTD.field].match(
                            /^(https?:\/\/[a-zA-Z0-9_+%-]+(\.[a-zA-Z0-9+\_%-]+)*(:[0-9]{1, 5})?(\/[a-zA-Z0-9+()?#~=&\._%-]*)*)?$/
                          ) !== null &&
                            tableRow[tableTD.field].match(
                              /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                            ) === null &&
                            tableRow[tableTD.field].includes("ggpht") ===
                            false ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  '<a target="_blank" href=' +
                                  tableRow[tableTD.field] +
                                  ">" +
                                  tempHref +
                                  "</a>",
                              }}
                            />
                          ) : // TO TRUNKATE THE LINK AFTER 50 CHARACTERS
                            tableTD.name !== "Campaign Bucket" &&
                              tableRow[tableTD.field].length > 50 &&
                              tableRow[tableTD.field].match(
                                /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                              ) == null &&
                              tableRow[tableTD.field].includes("ggpht") ===
                              false ? (
                              <ErrorBoundary>
                                <div title={tableRow[tableTD.field]}>
                                  <TextFormatter
                                    string={
                                      tableRow[tableTD.field].substring(0, 50) +
                                      "..."
                                    }
                                  />
                                </div>
                              </ErrorBoundary>
                            ) : // TO SHOW IMAGE IN A COLUMN
                              tableRow[tableTD.field].match(
                                /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                              ) != null ||
                                tableRow[tableTD.field].includes("ggpht") === true ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      '<img style="width:50px" src=' +
                                      tableRow[tableTD.field] +
                                      ">",
                                  }}
                                />
                              ) : tableTD.name === "Categories" ||
                                tableTD.name === "Campaign Bucket" ? (
                                <div className="badges_list_wrapper">
                                  {isCreatableDropdown ? generateTags(tableRow[tableTD.field], tableRow) : generateTags(tableRow[tableTD.field])}
                                </div>
                              ) : defaultSocialIconsUrls.find((element) => {
                                if (
                                  element.title.toLowerCase() ===
                                  tableTD.name.toLowerCase()
                                ) {
                                  return true;
                                }
                              }) ? (
                                <div className="badges_list_wrapper">
                                  {isCreatableDropdown ? generateTags(tableRow[tableTD.field], tableRow) : generateTags(tableRow[tableTD.field])}
                                </div>
                              ) : // TO SHOW COUNTRY FLAG
                                isCountryFlagVisible === true ? (
                                  <Wrapper>
                                    <div className="country_flag_section">
                                      <div className="country_flag_wrapper">
                                        {/*  <img
                                  className="flag_img"
                                  src={DefaultFlagImage}
                                /> */}
                                        <img
                                          className="flag_img2"
                                          src={handleImage(tableRow[tableTD.field])}
                                        />
                                      </div>
                                      <ErrorBoundary>
                                        <TextFormatter
                                          string={tableRow[tableTD.field]}
                                        />
                                      </ErrorBoundary>
                                    </div>
                                  </Wrapper>
                                ) : // TO SHOW CITY FLAG
                                  isDynamicFlagVisible === true ? (
                                    <Wrapper>
                                      <div className="country_flag_section">
                                        <div className="country_flag_wrapper">
                                          <img
                                            className="flag_img"
                                            src={DefaultFlagIcon}
                                          />
                                          <span className="cityabb">
                                            <TextFormatter
                                              string={
                                                tableRow[tableTD.field].match(
                                                  /[^\u0000-\u007F]+/
                                                )
                                                  ? tableRow[tableTD.field]
                                                    .match(/[^\u0000-\u007F]+/)
                                                    .join("")
                                                    .substring(0, 2)
                                                  : tableRow[tableTD.field].indexOf(
                                                    " "
                                                  ) >= 0 &&
                                                    tableRow[tableTD.field].indexOf(",") >
                                                    0
                                                    ? tableRow[tableTD.field].substring(
                                                      0,
                                                      2
                                                    )
                                                    : tableRow[tableTD.field].indexOf(
                                                      " "
                                                    ) >= 0 &&
                                                      tableRow[tableTD.field].indexOf(",") <
                                                      0
                                                      ? tableRow[tableTD.field]
                                                        .match(/\b\w/g)
                                                        .join("")
                                                        .substring(0, 2)
                                                      : tableRow[tableTD.field].substring(
                                                        0,
                                                        2
                                                      )
                                              }
                                            />
                                          </span>
                                        </div>
                                        <ErrorBoundary>
                                          <TextFormatter
                                            string={tableRow[tableTD.field]}
                                          />
                                        </ErrorBoundary>
                                      </div>
                                    </Wrapper>
                                  ) : (
                                    <ErrorBoundary>
                                      <TextFormatter string={tableRow[tableTD.field]} />
                                    </ErrorBoundary>
                                  )}
                      </td>
                    )
                  )}
                </tr>
              ) : (
                <tr key={tableRowIndex}>
                  {formattedTableHeaders.map((tableTD, tableTDIndex) =>
                    tableRow[tableTD.field] === undefined ? (
                      <td key={tableTDIndex}>0</td>
                    ) : tableRow[tableTD.field] === "" ||
                      tableRow[tableTD.field] === "NA" ||
                      tableRow[tableTD.field] === null ||
                      tableRow[tableTD.field] === " " ? (
                      <td
                        className="no_data_align 895"
                        data-label={tableTD.name}
                        key={tableTDIndex}
                      >
                        {isCreatableDropdown && tableTD.name === "Campaign Bucket" ? generateTags(null, tableRow) : "N/A"}
                      </td>
                    ) :
                      (
                        <td
                          key={tableTDIndex}
                          data-label={tableTD.name}
                          title={tableRow[tableTD.field]}
                          className={
                            isNumber(tableRow[tableTD.field]) === true
                              ? "number_align"
                              : "text_align"
                          }
                        >
                          {isNumber(tableRow[tableTD.field]) === true ? (
                            tableTD.name === "Viral Grade" ? (
                              <ErrorBoundary>
                                <div className="cell_badge_wrapper">
                                  <NumberSeparator
                                    fixedSpend={showFixedSpend}
                                    platform={tablePlatform}
                                    title={tableTD.name}
                                    number={tableRow[tableTD.field]}
                                  />
                                  {tableRow[tableTD.field] > 0 && (
                                    <div className="badge_wrapper">
                                      {tableRow[tableTD.field] < 1000 ? (
                                        <div className="badge positive">A</div>
                                      ) : (
                                        <div className="badge negative">B</div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </ErrorBoundary>
                            ) : showGrowthDegrowth ? (
                              <ErrorBoundary>
                                <div className="cell_badge_wrapper hellooo_904">
                                  <NumberSeparator
                                    fixedSpend={showFixedSpend}
                                    platform={tablePlatform}
                                    title={tableTD.name}
                                    number={Math.abs(tableRow[tableTD.field])}
                                  />
                                  {tableRow[tableTD.field] !== 0 ? (
                                    <div className="badge_wrapper">
                                      {tableRow[tableTD.field] > 0 ? (
                                        <div className="icon">
                                          <IconGrowth className="fs_12" />
                                        </div>
                                      ) : (
                                        <div className="icon">
                                          <IconDeGrowth className="fs_12" />
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="badge_wrapper">
                                      {
                                        <div className="icon">
                                          <IconNeutral className="fs_12" />
                                        </div>
                                      }
                                    </div>
                                  )}
                                </div>
                              </ErrorBoundary>
                            ) : // TO SHOW GROWTH ICONS
                              growthIconMetrics.includes(tableTD.name) ? (
                                <ErrorBoundary>
                                  <div className="cell_badge_wrapper">
                                    <NumberSeparator
                                      fixedSpend={showFixedSpend}
                                      platform={tablePlatform}
                                      title={tableTD.name}
                                      number={tableRow[tableTD.field]}
                                    />
                                    {tableRow[tableTD.field] !== 0 ? (
                                      <div className="badge_wrapper">
                                        {tableRow[tableTD.field] > 0 ? (
                                          <div className="icon">
                                            <IconGrowth />
                                          </div>
                                        ) : (
                                          <div className="icon">
                                            <IconDeGrowth />
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="badge_wrapper">
                                        {
                                          <div className="icon">
                                            <IconNeutral />
                                          </div>
                                        }
                                      </div>
                                    )}
                                  </div>
                                </ErrorBoundary>
                              ) : (
                                // regular number is here
                                <ErrorBoundary>
                                  {typeof tableRow[tableTD.field] === "number" || typeof parseFloat(tableRow[tableTD.field]) === 'number' ?
                                    <NumberSeparator
                                      fixedSpend={showFixedSpend}
                                      platform={tablePlatform}
                                      title={tableTD.name}
                                      number={tableRow[tableTD.field]}
                                    />
                                    : tableRow[tableTD.field]}
                                </ErrorBoundary>
                              )
                          ) : // TO MAKE URL LINK CLICKABLE
                            tableRow[tableTD.field].match(
                              /^(https?:\/\/[a-zA-Z0-9_+%-]+(\.[a-zA-Z0-9+\_%-]+)*(:[0-9]{1, 5})?(\/[a-zA-Z0-9+()?#~=&\._%-]*)*)?$/
                            ) !== null &&
                              tableRow[tableTD.field].match(
                                /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                              ) === null &&
                              tableRow[tableTD.field].includes("ggpht") ===
                              false ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    '<a target="_blank" href=' +
                                    tableRow[tableTD.field] +
                                    ">" +
                                    tempHref +
                                    "</a>",
                                }}
                              />
                            ) : // TO TRUNKATE THE LINK AFTER 50 CHARACTERS
                              tableTD.name !== "Campaign Bucket" &&
                                tableRow[tableTD.field].length > 50 &&
                                tableRow[tableTD.field].match(
                                  /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                                ) == null &&
                                tableRow[tableTD.field].includes("ggpht") ===
                                false ? (
                                <ErrorBoundary>
                                  <div title={tableRow[tableTD.field]}>
                                    <TextFormatter
                                      string={
                                        tableRow[tableTD.field].substring(0, 50) +
                                        "..."
                                      }
                                    />
                                  </div>
                                </ErrorBoundary>
                              ) : // TO SHOW IMAGE NAME AS A TEXT IN A COLUMN
                                removeImgExtensions === true ? (
                                  <ErrorBoundary>
                                    <div title={tableRow[tableTD.field]}>
                                      <TextFormatter
                                        string={tableRow[tableTD.field]}
                                      />
                                    </div>
                                  </ErrorBoundary>
                                ) :
                                  tableRow[tableTD.field].match(
                                    /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                                  ) != null ||
                                    tableRow[tableTD.field].includes("ggpht") === true ? (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          '<img style="width:50px" src=' +
                                          tableRow[tableTD.field] +
                                          ">",
                                      }}
                                    />
                                  ) : tableTD.name === "Categories" ||
                                    tableTD.name === "Campaign Bucket" ? (
                                    <div className="badges_list_wrapper">
                                      {isCreatableDropdown ? generateTags(tableRow[tableTD.field], tableRow) : generateTags(tableRow[tableTD.field])}
                                    </div>
                                  ) : // TO SHOW LOADER FOR SINGLE CELL IF DATA IS NOT LOADED FOR SINGLE CELL
                                    tableRow[tableTD.field] === "loading" ? (
                                      <div className="table_cell_skeleton_wrapper">
                                        <div className="table_cell_skeleton"></div>
                                      </div>
                                    ) : // TO SHOW LOADER FOR SINGLE CELL IF SOMETHING WENT WRONG FROM API FOR SINGLE CELL
                                      tableRow[tableTD.field] === "error" ? (
                                        <div className="error_cell_wrapper">
                                          <div className="info_wrapper">
                                            {/* <p className="info1">Data Unavailable</p> */}
                                            <p>Data Unavailable</p>
                                          </div>
                                        </div>
                                      ) : defaultSocialIconsUrls.find((element) => {
                                        if (
                                          element.title.toLowerCase() ===
                                          tableTD.name.toLowerCase()
                                        ) {
                                          return true;
                                        }
                                      }) ? (
                                        <div className="badges_list_wrapper">
                                          {isCreatableDropdown ? generateTags(tableRow[tableTD.field], tableRow) : generateTags(tableRow[tableTD.field])}
                                        </div>
                                      ) : // TO SHOW COUNTRY FLAG
                                        isCountryFlagVisible === true ? (
                                          <Wrapper>
                                            <div className="country_flag_section">
                                              <div className="country_flag_wrapper">
                                                <img
                                                  className="flag_img"
                                                  src={handleImage(tableRow[tableTD.field])}
                                                />
                                              </div>
                                              <ErrorBoundary>
                                                <TextFormatter
                                                  string={tableRow[tableTD.field]}
                                                />
                                              </ErrorBoundary>
                                            </div>
                                          </Wrapper>
                                        ) : // TO SHOW CITY FLAG
                                          isDynamicFlagVisible === true ? (
                                            <Wrapper>
                                              <div className="country_flag_section">
                                                <div className="country_flag_wrapper">
                                                  <img
                                                    className="flag_img"
                                                    src={DefaultFlagIcon}
                                                  />
                                                  <span className="cityabb">
                                                    <TextFormatter
                                                      string={
                                                        tableRow[tableTD.field].match(
                                                          /[^\u0000-\u007F]+/
                                                        )
                                                          ? tableRow[tableTD.field]
                                                            .match(/[^\u0000-\u007F]+/)
                                                            .join("")
                                                            .substring(0, 2)
                                                          : tableRow[tableTD.field].indexOf(
                                                            " "
                                                          ) >= 0 &&
                                                            tableRow[tableTD.field].indexOf(",") >
                                                            0
                                                            ? tableRow[tableTD.field].substring(
                                                              0,
                                                              2
                                                            )
                                                            : tableRow[tableTD.field].indexOf(
                                                              " "
                                                            ) >= 0 &&
                                                              tableRow[tableTD.field].indexOf(",") <
                                                              0
                                                              ? tableRow[tableTD.field]
                                                                .match(/\b\w/g)
                                                                .join("")
                                                                .substring(0, 2)
                                                              : tableRow[tableTD.field].substring(
                                                                0,
                                                                2
                                                              )
                                                      }
                                                    />
                                                  </span>
                                                </div>
                                                <ErrorBoundary>
                                                  <TextFormatter
                                                    string={tableRow[tableTD.field]}
                                                  />
                                                </ErrorBoundary>
                                              </div>
                                            </Wrapper>
                                          ) : tableTD.name === "Geography" ? (
                                            <ErrorBoundary>
                                              {tableRow[tableTD.field]}
                                            </ErrorBoundary>
                                          ) : (
                                            <ErrorBoundary>
                                              <TextFormatter string={tableRow[tableTD.field]} />
                                            </ErrorBoundary>
                                          )}
                        </td>
                      )
                  )}
                </tr>
              )
            )}

            {(!NoDataAvailableChecker(showTotalRow) || showTotalRow === false) && showTotalRow.map((tableRow, tableRowIndex) =>
            (
              <tr className="bottomRow_divider">
                {
                  formattedTableHeaders.map((tableTD, tableTDIndex) => (
                    (typeof tableRow[tableTD.field] !== 'number' ?
                      (tableTDIndex === 0 ?
                        <td> <b>Total</b></td>

                        : <td className={"text_align"} title={tableRow[tableTD.field]}>

                          {tableRow[tableTD.field]}
                        </td>)
                      :
                      <td className={"number_align totalRow_number"} title={tableRow[tableTD.field]}>
                        {tableTD.name === 'End Date' ? 'N/A' :
                          <ErrorBoundary>
                            <NumberSeparator fixedSpend={showFixedSpend} title={tableTD.name} number={tableRow[tableTD.field]} />
                          </ErrorBoundary>
                        }
                      </td>)
                  ))
                }
              </tr>
            ))
            }

          </tbody>
          {showTotal && (
            <tfoot>
              <tr>
                {Object.entries(totalNumberColumns).map(([key, value], index) => {
                  const className =
                    typeof value === "number"
                      ? "number_align"
                      : "no_data_align";
                  return (
                    <td key={key} data-key={key} className={className}>
                      {index === 0 && (value.length === 0) ? 'Total' : null}
                      {typeof value === "number" ? (
                        <NumberSeparator
                          fixedSpend={showFixedSpend}
                          platform={tablePlatform}
                          title={key}
                          number={value}
                          footerNumber={true}
                        />
                      ) : (
                        value
                      )}
                    </td>
                  );
                })}


              </tr>
            </tfoot>
          )}
        </table>
      </Wrapper >
    );
  } catch (error) {
    DataTable = (
      <Wrapper>
        <SomethingWentWrong />
      </Wrapper>
    );
  }


  const tableLengthOptions = [
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  const [tableLenthSelectedValue, setTableLenthSelectedValue] = useState({ label: `${updateTableLength}`, value: updateTableLength });


  const tableLengthDropdownHandler = (e) => {
    if (e === null) {
      setTableLenthSelectedValue(tableLengthOptions[0])
      setUpdateTableLength(tableLengthOptions[0].value)

      tableConfigs && setTableConfigs({ ...tableConfigs, page_size: tableLengthOptions[0].value })

    } else {
      setTableLenthSelectedValue(e)
      setUpdateTableLength(e.value)
      tableConfigs && setTableConfigs({ ...tableConfigs, page_size: e.value, page_num: 1 })
    }
    setCurrentPage(1)
  }



  useEffect(() => {
    clearSelectedItems === true && ClearRowCheckbox() // setCheckedItems([]);
  }, [clearSelectedItems]);

  return (
    <div
      className={`grid grid_cols_4 fullpage_section_card ${isHalfSection ? "" : "section_card"
        } ${hideBoxShadow ? "no_boxshadow" : ""} `}
    >
      <div className="grid col_span_4">
        <div className="section_card_body">
          <Wrapper>
            <div className="section_card_header">
              <div className="section_info">
                <h2 className="section_card_title"> {tableTitle}</h2>
                {tableTitle &&
                  tablePageSubModule &&
                  tablePagePlatform &&
                  tablePagePrimaryTab && (
                    <SectionDefinationsBadge
                      title={tableTitle.split(":")[0]}
                      module={tablePageSubModule}
                      platform={tablePagePlatform}
                      section={tablePagePrimaryTab}
                    />
                  )}
              </div>
              <div className="filters_wrapper">
                <div data-html2canvas-ignore={true}>
                  <div className="filter column_filter " ref={searchMenu}>
                    {dynamicColumns && <div className="table-toggle-filter">
                      <Button handleClick={() => setshowDynamicDropdown(true)} className="secondary_btn">
                        Click
                      </Button>
                      {<div className="checkbox-list" style={showDynamicDropdown ? { display: 'block' } : { display: 'none' }} >
                        <div className="checkbox_list_search">
                          <div className="checkbox_search_wrap">
                            <input type="text" id="checkbox_search_input" placeholder="Search" className="checkbox_list_search_input" onChange={HandleTableheaderSearch} />
                            {tableHeader.length !== filterTableheaders.length && <button className="checbox_search_clear"
                              onClick={() => {
                                var getInputVal = document.getElementById('checkbox_search_input')
                                getInputVal.value = ''
                                setFilterTableheaders(tableHeader)
                              }}
                            >
                              &times;
                            </button>}
                          </div>
                          <div className="checkbox_selectall_checkbox">
                            <input type="checkbox" id="select_all" onChange={(e) => {
                              if (e.target.checked) {
                                tableHeader.map((item) => {

                                  let getCheckbox = document.getElementById(item.field);
                                  getCheckbox.checked = true
                                })
                              }
                            }} />
                            <label htmlFor="select_all">Select All</label>
                          </div>
                        </div>

                        <div className='checkbox-list-items' id="checkbox_items">
                          {filterTableheaders.map((item, num) => {

                            return (
                              <div className="checkbox-list-wrap">
                                <input type="checkbox" name={item.field} for={item.field} id={item.field} defaultChecked={
                                  num >= 10 ? false : true
                                }
                                  onChange={() => {
                                    tableHeader.map((item) => {
                                      let getCheckbox = document.getElementById(item.field);
                                      let getSelectAll = document.getElementById('select_all');
                                      if (getCheckbox.checked === false) {
                                        getSelectAll.checked = false
                                      }
                                    })

                                    HandleDisableColumn()
                                  }}

                                />
                                <label htmlFor={item.field} className="">{item.name}</label>
                              </div>
                            )
                          })}
                        </div>
                        <div className="checkbox_button_wrap">
                          <Button className={'secondary_btn'} handleClick={HandleClearColumnChange}  >
                            Clear
                          </Button>
                          <Button disabled={isDynamicTableSearrchDisabled} className={'primary_btn'} handleClick={HandleColumnChange}>
                            Apply
                          </Button>
                        </div>
                      </div>}

                    </div>}
                  </div>
                  <div className="filter">
                    {
                      tablePaginationData === undefined ? (
                        <input
                          className="form_control input"
                          type="text"
                          autoComplete="off"
                          placeholder={searchPlaceHolder}
                          defaultValue={tableConfigs?.search_value ?? ''}
                          onChange={(event) => {
                            setSeachTerm(event.target.value);
                            setCurrentPage(1);
                          }}
                        />
                      ) : (
                        <div className="flex filter-searchbar-input flex-align-center">
                          {tableConfigs.search_value &&
                            <button className="filter-searchbar-clear" onClick={() => {
                              if (tableConfigs.search_value === null) {
                                document.getElementById('searchField').value = ''
                                setSeachTerm(null)
                              } else {
                                setTableConfigs(current => {
                                  const { search_value, ...rest } = current;
                                  return { ...rest, page_number: 1 }
                                })

                              }
                            }}>&times;</button>}
                          <input
                            id="searchField"
                            className="form_control input"
                            type="text"
                            value={searchValue}
                            autoComplete="off"
                            placeholder={searchPlaceHolder}
                            disabled={tableConfigs.search_value === undefined || tableConfigs.search_value === null ? false : true}
                            defaultValue={tableConfigs?.search_value ?? ''}
                            onChange={(event) => {
                              setSeachTerm(event.target.value);
                              setCurrentPage(1);
                            }}
                          />
                          <Button handleClick={() => SearchHandler()} className="secondary_btn">
                            Search
                          </Button>
                        </div>
                      )
                    }
                  </div>
                  {tableDropdown && (
                    <div className="filter">{tableDropdown}</div>
                  )}
                  {tableExportDropdown && (
                    <div className="filter-export">{tableExportDropdown}</div>
                  )}
                </div>

                {searchSingleColumnNumberRow === true && (
                  <div className="filter filter2">
                    <input
                      className="form_control input"
                      type="text"
                      autoComplete="off"
                      placeholder={searchSingleRowPlaceholder}
                      onChange={(event) => {
                        setSearchSingleColumnData(event.target.value);
                        setCurrentPage(1);
                      }}
                      value={searchSingleColumnData}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="table_wrapper">
              {checkTable.length === 0 ? (
                <NoDataAvailableLoader />
              ) : (
                DataTable
              )}
            </div>
            {tableData.length !== 0 && (
              <div className="grid grid_cols_4 footer">
                <div className="grid col_span_2 info_text">
                  {totalItems > updateTableLength ? (
                    currentPage === totalPages ? (
                      <Wrapper>
                        Showing {totalItems} of {totalItems} entries
                      </Wrapper>
                    ) :

                      <Wrapper>
                        Showing {tablePaginationData?.record_end ?? (currentPage * updateTableLength)} of {totalItems} {" "}
                        entries
                      </Wrapper>

                  ) : (
                    <Wrapper>
                      Showing {totalItems} of {totalItems} entries
                    </Wrapper>
                  )}
                </div>

                {(hasTableLenghtDropdown && tableData.length >= 10) &&
                  <div className={`grid col_span_1 flex justify_end`}>
                    <Dropdown
                      isMulti={false}
                      isClearable={false}
                      options={tableLengthOptions}
                      className={'form_dropdown'}
                      value={tableLenthSelectedValue}
                      selectedOptions={tableLenthSelectedValue}
                      setStatedropdown={tableLengthDropdownHandler}

                    />
                  </div>}

                {totalItems > updateTableLength && (
                  <div className="col_span_1">
                    <div className="pagination grid col_span_2 flex justify_end">
                      <div className="btn_wrapper">
                        <Button
                          handleClick={() => {
                            setCurrentPage(1)
                            handleTableConfigs(1)
                          }}
                          className="secondary_btn"
                          disabled={currentPage === 1 ? true : false}
                        >
                          1
                        </Button>
                      </div>
                      <div className="btn_wrapper">
                        <Button
                          handleClick={() => {
                            setCurrentPage(currentPage - 2)
                            handleTableConfigs(currentPage - 2)
                          }}
                          className="secondary_btn"
                          disabled={currentPage - 2 < 1 ? true : false}
                        >
                          Prev
                        </Button>
                      </div>
                      {currentPage - 1 >= 1 && (
                        <div className="btn_wrapper">
                          <Button
                            handleClick={() => {
                              setCurrentPage(currentPage - 1)
                              handleTableConfigs(currentPage - 1)
                            }}
                            className="secondary_btn"
                          >
                            {currentPage - 1}
                          </Button>
                        </div>
                      )}
                      <div className="btn_wrapper">
                        <Button
                          handleClick={() => {
                            setCurrentPage(currentPage)
                            handleTableConfigs(currentPage)
                          }}
                          className="primary_btn"
                        >
                          {currentPage}
                        </Button>
                      </div>
                      {currentPage + 1 > totalPages ? (
                        ""
                      ) : (
                        <div className="btn_wrapper">
                          <Button
                            handleClick={() => {
                              setCurrentPage(currentPage + 1)
                              handleTableConfigs(currentPage + 1)
                            }}
                            className="secondary_btn"
                          >
                            {currentPage + 1}
                          </Button>
                        </div>
                      )}
                      <div className="btn_wrapper">
                        <Button
                          handleClick={() => {
                            setCurrentPage(currentPage + 2)
                            handleTableConfigs(currentPage + 2)
                          }}
                          className="secondary_btn"
                          disabled={totalPages < currentPage + 2}
                        >
                          Next
                        </Button>
                      </div>
                      <div className="btn_wrapper">
                        <Button
                          handleClick={() => {
                            setCurrentPage(totalPages)
                            handleTableConfigs(totalPages)
                          }}
                          className="secondary_btn"
                          disabled={totalPages === currentPage}
                        >
                          {totalPages}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Wrapper>
        </div>
      </div >
    </div >
  );
};

CheckBoxDatatable.propTypes = {
  tableTitle: PropTypes.string,
  tableHeader: PropTypes.array,
  tableData: PropTypes.array,
  tableLength: PropTypes.number,
  tableDropdown: PropTypes.any,
  searchPlaceHolder: PropTypes.string,
  searchSingleColumnNumberRow: PropTypes.bool,
  searchSingleColumnNumber: PropTypes.number,
  hrefTitle: PropTypes.string,
  tableExportDropdown: PropTypes.any,
  tableID: PropTypes.string,
  tablePlatform: PropTypes.string,
  checkbox: PropTypes.bool,
  onCheckboxClickHandler: PropTypes.func,
  isDynamicFlagVisible: PropTypes.bool,
  showGrowthDegrowth: PropTypes.bool,
  showTotal: PropTypes.bool,
  isCreatableDropdown: PropTypes.bool,
  setTableConfigs: PropTypes.func,
  tablePaginationData: PropTypes.object,
  showTotalRow: PropTypes.any,
  dynamicColumns: PropTypes.bool,
  hasTableLenghtDropdown: PropTypes.bool,
  dynamicCheckboxRef: PropTypes.array,
  highlightSearch: PropTypes.bool
};

export default CheckBoxDatatable;
