const DownloadIcon = (props) => {
  return (
    <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
       <g id="Group_1157" data-name="Group 1157" transform="translate(-1024 -296)">
    <rect id="Rectangle_161" data-name="Rectangle 161" width="54" height="54" transform="translate(1024 296)" fill="none"/>
    <g id="_9027545_upload_arrow_icon" data-name="9027545_upload_arrow_icon" transform="translate(1034 306)">
      <g id="Group_1158" data-name="Group 1158" transform="translate(33 27.876) rotate(180)">
        <path id="Path_731" data-name="Path 731" d="M12,4V24.314" transform="translate(4.5 0.563)" fill="none" stroke="#486f88" strokeLinecap="square" strokeWidth="1.5"/>
        <path id="Path_732" data-name="Path 732" d="M7,10.813,14.813,3l7.813,7.813" transform="translate(1.688)" fill="none" stroke="#486f88" strokeLinecap="square" strokeWidth="1.5"/>
      </g>
      <path id="Path_733" data-name="Path 733" d="M29,21H4" transform="translate(0 10.126)" fill="none" stroke="#486f88" strokeLinecap="square" strokeWidth="1.5"/>
    </g>
  </g>
    </svg>
  );
};

export default DownloadIcon;
