import React, { useContext, useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";

//STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

//LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import axios from "../../../../components/helpers/axios";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import Slider from "../../../../components/ui/Slider";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import Datatable from "../../../../components/ui/tables/Datatable";
import Card from "../../../../components/ui/Card";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";

// CHARTS
import BarChart from "../../../../components/ui/charts/BarChart";

// ASSETS
import FemaleIcon from "../../../../assets/icons/FemaleIcon";
import MaleIcon from "../../../../assets/icons/MaleIcon";
import UnknownGenderIcon from "../../../../assets/icons/UnknownGenderIcon";

// UTILS
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";
import EventTracker from "../../../../components/eventsTracking/EventTracker";


const SocialTwitterAudience = ({ topCardData }) => {
  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Social"
  let pagePlatform = "Twitter"
  let pagePrimaryTab = "Audience"


  const authCtx = useContext(AuthContext);

  const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // AGE DISTRIBUTION
  const [ageDistribution, setAgeDistribution] = useState([]);
  const [ageDistributionLabel, setAgeDistributionLabel] = useState([]);
  const [ageDistributionAllDataLabels, setAgeDistributionLabelAllDataLabels] =
    useState([]);
  const [isAgeDistributionLoading, setIsAgeDistributionLoading] =
    useState(false);
  const [isAgeDistributionLoaded, setIsAgeDistributionLoaded] = useState(false);
  const [ageDistributionError, setAgeDistributionError] = useState(null);
  const [audienceDistributionAllDataAge, setaudienceDistributionAllDataAge] =
    useState([]);


  // GENDER DISTRIBUTION

  // const [genderDistribution, setGenderDistribution] = useState([]);
  // const [
  //   audienceDistributionAllDataGender,
  //   setaudienceDistributionAllDataGender,
  // ] = useState([]);
  // const [genderDistributionLabel, setGenderDistributionLabel] = useState([]);
  // const [genderDistributionAllDataLabels, setGenderDistributionAllDataLabels] =
  //   useState([]);
  // const [isGenderDistributionLoading, setIsGenderDistributionLoading] =
  //   useState(false);
  // const [isGenderDistributionLoaded, setIsGenderDistributionLoaded] =
  //   useState(false);
  // const [genderDistributionError, setGenderDistributionError] = useState(null);

  const [genderWiseAudience, setGenderWiseAudience] = useState([]);
  const [genderWiseAudienceLoading, setGenderWiseAudienceLoading] =
    useState(false);
  const [genderWiseAudienceLoaded, setGenderWiseAudienceLoaded] =
    useState(false);
  const [genderWiseAudienceError, setGenderWiseAudienceError] = useState(null);

  const [genderWiseAudienceAllData, setGenderWiseAudienceAllData] = useState(
    []
  );

  // COUNTRY DISTRIBUTION
  const [countryData, setCountryData] = useState([]);
  const [countryAllDropdownsData, setCountryAllDropdownsData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataError, setCountryDataError] = useState(null);

  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: "Reach", field: "reach" },
    { name: "Change", field: "percentage_growth" },
  ];

  // // CITY DISTRIBUTION
  // const [cityData, setCityData] = useState([]);
  // const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  // const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  // const [cityDataerror, setCityDataError] = useState(null);

  // const CityTableDataHeaders = [
  //   { name: "City", field: "name" },
  //   { name: "Likes", field: "likes_users" },
  //   { name: "Change", field: "percentage_growth" },
  // ];

  // DROPDOWN OPTIONS
  const audienceDistributionOptions = [
    { value: "followers", label: "Followers" },
    { value: "impressions", label: "Impressions" },
  ];
  const [
    audienceDistributionDropdownValue,
    setAudienceDistributionDropdownValue,
  ] = useState(audienceDistributionOptions[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownSelectionHandler = (e) => {
    if (e === null) {
      setAudienceDistributionDropdownValue("");
    } else {
      setAudienceDistributionDropdownValue(e);
    }
    IAEvent_Dropdown_Visit(
      "Marketing Intelligence",
      "Social",
      "Twitter",
      "Audience",
      null,
      "Audience Distribution: By Age / Gender",
      e.label
    );

    EventTracker({
      eventType: "dropdown_selected",
      module: pageModule,
      subModule: pageSubModule,
      platform: pagePlatform,
      tab: pagePrimaryTab,
      section: "Audience Distribution: By Age / Gender",
      dropdownValue: e === null ? "Overall" : e.label,
      ...(BRAND_ID && { brands: BRAND_ID }),
      date: {
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate,
        previousStartDate: selectedDates.prevStartDate,
        previousEndDate: selectedDates.prevEndDate,
      }
    })
  };

  const fetchAudienceDistributionHandler = async () => {
    if (audienceDistributionDropdownValue.value === "followers") {
      setAgeDistribution(audienceDistributionAllDataAge.followers);
      setAgeDistributionLabel(ageDistributionAllDataLabels.followers);
      setGenderWiseAudience(genderWiseAudienceAllData.followers);
      setCountryData(countryAllDropdownsData.followers);
      // setGenderWiseAudienceLabel(genderDistributionAllDataLabels.followers);
    } else if (audienceDistributionDropdownValue.value === "impressions") {
      setAgeDistribution(audienceDistributionAllDataAge.impressions);
      setAgeDistributionLabel(ageDistributionAllDataLabels.impressions);
      setGenderWiseAudience(genderWiseAudienceAllData.impressions);
      setCountryData(countryAllDropdownsData.impressions);
      // setGenderWiseAudienceLabel(genderDistributionAllDataLabels.impressions);
    } else {
      setAgeDistribution([]);
      setAgeDistributionLabel([]);
      setGenderWiseAudience([]);
      setCountryData([]);
      // setGenderWiseAudienceLabel([]);
    }
  };

  useEffect(() => {
    fetchAudienceDistributionHandler();
  }, [BRAND_ID, audienceDistributionDropdownValue, selectedDates]);

  // TWITTER TOP MENTIONS
  const [topMentions, setTopMentions] = useState([]);
  const [isTopMentionsLoading, setTopMentionsIsLoading] = useState(false);
  const [isTopMentionsLoaded, setTopMentionsIsLoaded] = useState(false);
  const [topMentionsError, setTopMentionsError] = useState(null);

  // TWITTER TOP FOLLOWERS
  const [topFollowers, setTopFollowers] = useState([]);
  const [isTopFollowersLoading, setTopFollowersIsLoading] = useState(false);
  const [isTopFollowersLoaded, setTopFollowersIsLoaded] = useState(false);
  const [topFollowersError, setTopFollowersError] = useState(null);

  // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
  useEffect(() => {
    fetchAgeDistributionHandler();
    // fetchGenderDistributionHandler();
    fetchCountryHandler();
    // fetchCityHandler();
    fetchTopFollowersHandler();
    fetchTopMentionsHandler();
    fetchGenderWiseSelectionHandler();
  }, [BRAND_ID, selectedDates]);

  // --------------------------
  // --------------------------
  // --------------------------
  // --------------------------
  // --------------------------
  // AGE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchAgeDistributionHandler = async () => {
    setIsAgeDistributionLoading(true);
    setIsAgeDistributionLoaded(false);
    setAgeDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: "Organic",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/audience/age-distribution/",
        config
      );


      setAgeDistribution(res?.data?.data?.followers ?? [])
      setAgeDistributionLabel(res?.data?.label?.followers ?? [])

      setaudienceDistributionAllDataAge(res?.data?.data ?? [])
      setAgeDistributionLabelAllDataLabels(res?.data?.label ?? [])


      setIsAgeDistributionLoading(false);
      setIsAgeDistributionLoaded(true);
      setAgeDistributionError(null);
    } catch (error) {
      setAgeDistribution([]);
      setAgeDistributionLabel([]);
      setIsAgeDistributionLoading(false);
      setIsAgeDistributionLoaded(false);
      setAgeDistributionError(error.code);
    }
  };

  let ageDistributionChart = (
    <Wrapper>
      <Loader loaderType="barChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isAgeDistributionLoaded && !isAgeDistributionLoading) {
    if (
      NoDataAvailableChecker(ageDistribution) ||
      NoDataAvailableChecker(ageDistributionLabel)
    ) {
      ageDistributionChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      ageDistributionChart = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_male"
              chartClass="section_card_chart"
              chartData={ageDistribution}
              chartLabels={ageDistributionLabel}
              showLegend={false}
              /*  showLabels={true}
             showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (ageDistributionError) {
    ageDistributionChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="barChartType"
          error={ageDistributionError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAgeDistributionLoading) {
    ageDistributionChart = (
      <Wrapper>
        <Loader loaderType="barChartLoader" />
      </Wrapper>
    );
  }

  // --------------------------
  // --------------------------
  // --------------------------
  // --------------------------
  // --------------------------
  // GENDER DISTRIBUTION
  // const fetchGenderDistributionHandler = async () => {
  //   setIsGenderDistributionLoading(true);
  //   setIsGenderDistributionLoaded(false);
  //   setGenderDistributionError(null);

  //   const config = {
  //     params: {
  //       brand_id: BRAND_ID,
  //       start_date: selectedDates.startDate,
  //       end_date: selectedDates.endDate,
  //       previous_start_date: selectedDates.prevStartDate,
  //       previous_end_date: selectedDates.prevEndDate,
  //       post_type: "Organic",
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + authCtx.token,
  //     },
  //   };

  //   try {
  //     const res = await axios.get(
  //       "social/twitter/audience/gender-distribution/",
  //       config
  //     );

  //     setaudienceDistributionAllDataGender(res.data.data);
  //     setGenderDistributionAllDataLabels(res.data.label);
  //     setGenderDistribution(res.data.data.followers);
  //     setGenderDistributionLabel(res.data.label.followers);

  //     setIsGenderDistributionLoading(false);
  //     setIsGenderDistributionLoaded(true);
  //     setGenderDistributionError(null);
  //   } catch (error) {
  //     setaudienceDistributionAllDataGender([]);
  //     setGenderDistributionAllDataLabels([]);
  //     setGenderDistribution([]);
  //     setGenderDistributionLabel([]);
  //     setIsGenderDistributionLoading(false);
  //     setIsGenderDistributionLoaded(false);
  //     setGenderDistributionError(error.code);
  //   }
  // };

  // let genderDistributionChart = <NoDataAvailableLoader />;

  // // IF DATA IS AVAILABLE
  // if (isGenderDistributionLoaded && !isGenderDistributionLoading) {
  //   if (
  //     genderDistribution === [] ||
  //     genderDistribution === undefined ||
  //     genderDistribution.length === 0
  //   ) {
  //     genderDistributionChart = <NoDataAvailableLoader />;
  //   } else {
  //     genderDistributionChart = (
  //       <Wrapper>
  //         <ErrorBoundary>
  //           <BarChart
  //             chartId="gender_chart"
  //             chartClass="section_card_chart"
  //             chartData={genderDistribution}
  //             chartLabels={genderDistributionLabel}
  //             showLegend={false}
  //             showLabels={true}
  //             showVerticalLabels={true}
  //           />
  //         </ErrorBoundary>
  //       </Wrapper>
  //     );
  //   }
  // }

  // // IF THERE IS SOME ERROR FROM SERVER
  // if (genderDistributionError) {
  //   genderDistributionChart = (
  //     <ServerErrorsLoader error={genderDistributionError} />
  //   );
  // }

  // // IF DATA LOADING
  // if (isGenderDistributionLoading) {
  //   genderDistributionChart = <Loader />;
  // }

  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // GENDER WISE
  const fetchGenderWiseSelectionHandler = async () => {
    setGenderWiseAudienceLoading(true);
    setGenderWiseAudienceLoaded(false);
    setGenderWiseAudienceError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        // post_id: postIdentity.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        `social/twitter/audience/gender-distribution/`,
        config
      );


      setGenderWiseAudience(res?.data?.followers ?? [])
      setGenderWiseAudienceAllData(res?.data ?? [])

      setGenderWiseAudienceLoading(false);
      setGenderWiseAudienceLoaded(true);
      setGenderWiseAudienceError(null);
    } catch (error) {
      setGenderWiseAudience([]);
      setGenderWiseAudienceAllData([]);

      setGenderWiseAudienceLoading(false);
      setGenderWiseAudienceLoaded(false);
      setGenderWiseAudienceError(error.code);
    }
  };

  let genderWiseAudienceChart = (
    <Wrapper>
      <Loader loaderType="genderLoader" />
    </Wrapper>
  );

  const totalMaleCountPercentage = (male, female, unknown) => {
    if (isNaN(male / (male + female + unknown))) {
      return 0;
    } else {
      return male / (male + female + unknown);
    }
  };

  const totalFemaleCountPercentage = (male, female, unknown) => {
    if (isNaN(female / (male + female + unknown))) {
      return 0;
    } else {
      return female / (male + female + unknown);
    }
  };

  const totalUnknownCountPercentage = (male, female, unknown) => {
    if (isNaN(unknown / (male + female + unknown))) {
      return 0;
    } else {
      return unknown / (male + female + unknown);
    }
  };

  // IF DATA IS AVAILABLE
  if (genderWiseAudienceLoaded && !genderWiseAudienceLoading) {
    if (NoDataAvailableChecker(genderWiseAudience)) {
      genderWiseAudienceChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      genderWiseAudienceChart = (
        <Wrapper>
          <ErrorBoundary chartType="genderChartType">
            <Wrapper>
              <div className="">
                <div className="section_icon flex column">
                  <ErrorBoundary chartType="genderChartType">
                    <MaleIcon
                      percentage={
                        totalMaleCountPercentage(
                          genderWiseAudience.male_count,
                          genderWiseAudience.female_count,
                          genderWiseAudience.unknown_count
                        ) * 100
                      }
                    />
                  </ErrorBoundary>
                </div>
                <div className="p_20 text_center">
                  <h3>
                    <ErrorBoundary>
                      {+Math.abs(
                        totalMaleCountPercentage(
                          genderWiseAudience.male_count,
                          genderWiseAudience.female_count,
                          genderWiseAudience.unknown_count
                        ) * 100
                      )
                        .toFixed(2)
                        .replace(/\.0$/, "") + "%"}
                    </ErrorBoundary>
                  </h3>
                  <h3>Male</h3>
                </div>
              </div>

              <div className="">
                <div className="section_icon flex column">
                  <ErrorBoundary chartType="genderChartType">
                    <FemaleIcon
                      percentage={
                        totalFemaleCountPercentage(
                          genderWiseAudience.male_count,
                          genderWiseAudience.female_count,
                          genderWiseAudience.unknown_count
                        ) * 100
                      }
                    />
                  </ErrorBoundary>
                </div>
                <div className="p_20 text_center">
                  <h3>
                    <ErrorBoundary>
                      {+Math.abs(
                        totalFemaleCountPercentage(
                          genderWiseAudience.male_count,
                          genderWiseAudience.female_count,
                          genderWiseAudience.unknown_count
                        ) * 100
                      )
                        .toFixed(2)
                        .replace(/\.0$/, "") + "%"}
                    </ErrorBoundary>
                  </h3>
                  <h3>Female</h3>
                </div>
              </div>

              <div className="">
                <div className="section_icon flex column">
                  <ErrorBoundary chartType="genderChartType">
                    <UnknownGenderIcon
                      percentage={
                        totalUnknownCountPercentage(
                          genderWiseAudience.male_count,
                          genderWiseAudience.female_count,
                          genderWiseAudience.unknown_count
                        ) * 100
                      }
                    />
                  </ErrorBoundary>
                </div>
                <div className="p_20 text_center">
                  <h3>
                    <ErrorBoundary>
                      {+Math.abs(
                        totalUnknownCountPercentage(
                          genderWiseAudience.male_count,
                          genderWiseAudience.female_count,
                          genderWiseAudience.unknown_count
                        ) * 100
                      )
                        .toFixed(2)
                        .replace(/\.0$/, "") + "%"}
                    </ErrorBoundary>
                  </h3>
                  <h3>Unknown</h3>
                </div>
              </div>
            </Wrapper>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (genderWiseAudienceError) {
    genderWiseAudienceChart = (
      <Wrapper>
        <div className="flex justify_center grid_cols_1">
          <ServerErrorsLoader
            chartType="genderChartType"
            error={genderWiseAudienceError}
          />
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (genderWiseAudienceLoading) {
    genderWiseAudienceChart = (
      <Wrapper>
        <div className="flex justify_center grid_cols_1">
          <Loader loaderType="genderLoader" />
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/audience/country/", config);

      setCountryData(res?.data?.data?.followers ?? []);
      setCountryAllDropdownsData(res?.data?.data ?? []);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryData([]);
      setCountryAllDropdownsData([]);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let countryMap = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card ">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title"> By Country {" : " + audienceDistributionDropdownValue.label}
              <SectionDefinationsBadge
                title="By Country"
                module="social"
                platform={pagePlatform}
                section="audience"
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (NoDataAvailableChecker(countryData) || NoDataAvailableChecker(CountryTableDataHeaders)) {
      countryMap = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card ">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title"> By Country {" : " + audienceDistributionDropdownValue.label}
                  <SectionDefinationsBadge
                    title="By Country"
                    module="social"
                    platform={pagePlatform}
                    section="audience"
                  />
                </h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      countryMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By Country : " + audienceDistributionDropdownValue.label}
              tablePagePlatform={pagePlatform}
              tablePagePrimaryTab={pagePrimaryTab}
              tablePageSubModule={pageSubModule}
              tableID={"aud_distri_country"}
              isCountryFlagVisible={true}
              tableHeader={CountryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"search_campaign_section"}
                  sectionName={`SocialTTCountry_By Country_${audienceDistributionDropdownValue.value}`}
                  sectionData={countryData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataError) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title"> By Country {" : " + audienceDistributionDropdownValue.label}
                <SectionDefinationsBadge
                  title="By Country"
                  module="social"
                  platform={pagePlatform}
                  section="audience"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={countryDataError} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title"> By Country {" : " + audienceDistributionDropdownValue.label}
                <SectionDefinationsBadge
                  title="By Country"
                  module="social"
                  platform={pagePlatform}
                  section="audience"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CITY MAP

  // const fetchCityHandler = async () => {
  //   setCityDataIsLoading(true);
  //   setCityDataIsLoaded(false);
  //   setCityDataError(null);

  //   const config = {
  //     params: {
  //       brand_id: BRAND_ID,
  //       start_date: selectedDates.startDate,
  //       end_date: selectedDates.endDate,
  //       previous_start_date: selectedDates.prevStartDate,
  //       previous_end_date: selectedDates.prevEndDate,
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + authCtx.token,
  //     },
  //   };

  //   try {
  //     const res = await axios.get("social/twitter/audience/region/", config);

  //     setCityData(
  //       res.data === undefined || res.data.length === 0
  //         ? []
  //         : res.data.data === undefined || res.data.data.length === 0
  //           ? []
  //           : res.data.data.followers === undefined ||
  //             res.data.data.followers.length === 0
  //             ? []
  //             : res.data.data.followers
  //     );
  //     setCountryAllDropdownsData(
  //       res.data === undefined || res.data.length === 0
  //         ? []
  //         : res.data.data === undefined || res.data.data.length === 0
  //           ? []
  //           : res.data.data
  //     );
  //     setCityDataIsLoading(false);
  //     setCityDataIsLoaded(true);
  //     setCityDataError(null);
  //   } catch (error) {
  //     setCountryData([]);
  //     setCountryAllDropdownsData([]);
  //     setCityDataIsLoading(false);
  //     setCityDataIsLoaded(false);
  //     setCityDataError(error.code);
  //   }
  // };

  // // AUDIENCE DISTRIBUTION: MALE
  // let cityMap = (
  //   <Wrapper>
  //     <Loader loaderType="smallTableLoader" />
  //   </Wrapper>
  // );

  // // IF DATA IS AVAILABLE
  // if (isCityDataLoaded && !isCityDataLoading) {
  //   if (NoDataAvailableChecker(cityData)) {
  //     cityMap = (
  //       <Wrapper>
  //         <NoDataAvailableLoader chartType="tableChartType" />
  //       </Wrapper>
  //     );
  //   } else {
  //     cityMap = (
  //       <Wrapper>
  //         <ErrorBoundary chartType="tableChartType">
  //           <Datatable
  //             tableHeader={CityTableDataHeaders}
  //             tableData={cityData}
  //             tableLength={5}
  //             isFooterShow={true}
  //             searchPlaceHolder={"Search City"}
  //           />
  //         </ErrorBoundary>
  //       </Wrapper>
  //     );
  //   }
  // }

  // // IF THERE IS SOME ERROR FROM SERVER
  // if (cityDataerror) {
  //   cityMap = (
  //     <Wrapper>
  //       <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
  //     </Wrapper>
  //   );
  // }

  // // IF DATA LOADING
  // if (isCityDataLoading) {
  //   cityMap = (
  //     <Wrapper>
  //       <Loader loaderType="smallTableLoader" />
  //     </Wrapper>
  //   );
  // }

  // --------------------------
  // --------------------------
  // --------------------------
  // --------------------------
  // --------------------------
  // Top Tweet Mentions

  const fetchTopMentionsHandler = async () => {
    setTopMentionsIsLoading(true);
    setTopMentionsIsLoaded(false);
    setTopMentionsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/audience/top-mentions/",
        config
      );
      setTopMentions(res?.data?.data ?? []);
      setTopMentionsIsLoading(false);
      setTopMentionsIsLoaded(true);
      setTopMentionsError(null);
    } catch (error) {
      setTopMentions([]);
      setTopMentionsIsLoading(false);
      setTopMentionsIsLoaded(false);
      setTopMentionsError(error.code);
    }
  };

  let topMentionsChart = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">
            <h2 className="section_title">
              Twitter Top Mentions
            </h2> <SectionDefinationsBadge
              title="Twitter Top Mentions"
              module="social"
              platform="twitter"
              section="audience"
            />
          </div>
        </div>
        <div className="grid col_span_4 ">
          <Loader loaderType="cardLoader" />
        </div>
      </div >
    </Wrapper >
  );

  // IF DATA IS AVAILABLE
  if (isTopMentionsLoaded && !isTopMentionsLoading) {
    if (NoDataAvailableChecker(topMentions)) {
      topMentionsChart = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">   <h2 className="section_title">
                Twitter Top Mentions
              </h2> <SectionDefinationsBadge
                  title="Twitter Top Mentions"
                  module="social"
                  platform="twitter"
                  section="audience"
                />
              </div>
            </div>
            <div className="grid col_span_4">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div >
        </Wrapper >
      );
    } else {
      topMentionsChart = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider
              platform={"twitter"}
              tab={"audience"}
              slidestoshow={3}
              sliderTitle={"Twitter Top Mentions"}
              sliderModule="social"
              sliderPlatform="twitter"
              sliderSection="audience"
              breakpoints={{
                0: {
                  slidesPerView: "auto",
                },
                426: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1300: {
                  slidesPerView: 3,
                },
              }}
            >
              {topMentions.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    key={index}
                    cardClass={"topMentionsCard"}
                    variant={"twitter_top_mentions"}
                    module="social"
                    social_platform="Twitter"
                    module_section="organic"
                    cardData={data}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topMentionsError) {
    topMentionsChart = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">     <h2 className="section_title">
              Twitter Top Mentions
            </h2> <SectionDefinationsBadge
                title="Twitter Top Mentions"
                module="social"
                platform="twitter"
                section="audience"
              />
            </div>
          </div>
          <div className="grid col_span_4 ">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={topMentionsError}
            />
          </div>
        </div >
      </Wrapper >
    );
  }

  // IF DATA LOADING
  if (isTopMentionsLoading) {
    topMentionsChart = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">   <h2 className="section_title">
              Twitter Top Mentions
            </h2> <SectionDefinationsBadge
                title="Twitter Top Mentions"
                module="social"
                platform="twitter"
                section="audience"
              />
            </div>
          </div>
          <div className="grid col_span_4 ">
            <Loader loaderType="cardLoader" />
          </div>
        </div >
      </Wrapper >
    );
  }

  // --------------------------
  // --------------------------
  // --------------------------
  // --------------------------
  // --------------------------
  // TOP FOLLOWERS
  const fetchTopFollowersHandler = async () => {
    setTopFollowersIsLoading(true);
    setTopFollowersIsLoaded(false);
    setTopFollowersError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/audience/top-followers/",
        config
      );
      setTopFollowers(res?.data?.data ?? []);
      setTopFollowersIsLoading(false);
      setTopFollowersIsLoaded(true);
      setTopFollowersError(null);
    } catch (error) {
      setTopFollowers([]);
      setTopFollowersIsLoading(false);
      setTopFollowersIsLoaded(false);
      setTopFollowersError(error.code);
    }
  };

  let topFollowersChart = (
    <Wrapper>
      <div className="grid grid_cols_4 ">
        <div className="grid col_span_4 section_header">
          <div className="section_info">    <h2 className="section_title">
            Twitter Top Followers
          </h2> <SectionDefinationsBadge
              title="Twitter Top Followers"
              module="social"
              platform="twitter"
              section="audience"
            />
          </div>
        </div>
        <div className="grid col_span_4 ">
          <Loader loaderType="cardLoader" />
        </div>
      </div >
    </Wrapper >
  );

  // IF DATA IS AVAILABLE
  if (isTopFollowersLoaded && !isTopFollowersLoading) {
    if (NoDataAvailableChecker(topFollowers)) {
      topFollowersChart = (
        <Wrapper>
          <div className="grid grid_cols_4 ">
            <div className="grid col_span_4 section_header">
              <div className="section_info">   <h2 className="section_title">
                Twitter Top Followers
              </h2>  <SectionDefinationsBadge
                  title="Twitter Top Followers"
                  module="social"
                  platform="twitter"
                  section="audience"
                />
              </div>
            </div>
            <div className="grid col_span_4 ">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div >
        </Wrapper >
      );
    } else {
      topFollowersChart = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider
              platform={"twitter"}
              tab={"audience"}
              slidestoshow={3}
              sliderTitle={"Twitter Top Followers"}
              sliderModule="social"
              sliderPlatform="twitter"
              sliderSection="audience"
              breakpoints={{
                0: {
                  slidesPerView: "auto",
                },
                426: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1300: {
                  slidesPerView: 3,
                },
              }}
            >
              {topFollowers.map((data, index) => (
                <SwiperSlide key={index}>
                  <div className="card_wrapper">
                    <Card
                      key={index}
                      cardClass={"topFollowersCard"}
                      variant={"twitter_top_followers"}
                      cardData={data}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topFollowersError) {
    topFollowersChart = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">   <h2 className="section_title">
              Twitter Top Followers
            </h2> <SectionDefinationsBadge
                title="Twitter Top Followers"
                module="social"
                platform="twitter"
                section="audience"
              />
            </div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={topFollowersError}
            />
          </div>
        </div >
      </Wrapper >
    );
  }

  // IF DATA LOADING
  if (isTopFollowersLoading) {
    topFollowersChart = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">   <h2 className="section_title">
              Twitter Top Followers
            </h2> <SectionDefinationsBadge
                title="Twitter Top Followers"
                module="social"
                platform="twitter"
                section="audience"
              />
            </div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div >
      </Wrapper >
    );
  }

  var ageExport = {
    [`AgeBarChartSocialTT_Age Distribution - Age_${audienceDistributionDropdownValue.value}`]: ageDistribution,
  };

  var genderExport = {
    "Age Distribution - Gender": NoDataAvailableChecker(genderWiseAudience)
      ? []
      : [
        {
          [`Male - ${audienceDistributionDropdownValue.label}`]: genderWiseAudience.male_count,
          [`Female - ${audienceDistributionDropdownValue.label}`]: genderWiseAudience.female_count,
          [`Unknown - ${audienceDistributionDropdownValue.label}`]: genderWiseAudience.unknown_count,
        },
      ],
  }

  var cityCountryData = {
    // City: NoDataAvailableChecker(cityData) ? [] : NoDataAvailableChecker(cityData.features) ? [] : cityData.features,
    [`SocialTTCountry_By Country_${audienceDistributionDropdownValue.value}`]: countryData,
  };

  var allData = {
    ...topCardData,
    ...genderExport,
    ...ageExport,
    // ...ageGenderCSV,
    // ...cityCountryData,
    [`SocialTTCountry_By Country_${audienceDistributionDropdownValue.value}`]: countryData,
    "TopMentions_Top Mentions": topMentions,
    "TopFollowers_Top Followers": topFollowers,
  };

  useEffect(() => {
    setFullPageExport(allData);
  }, [
    genderWiseAudience,
    ageDistribution,
    countryData,
    topMentions,
    topFollowers,
  ]);

  useEffect(() => {
    if (
      (genderWiseAudienceError !== null ? true : genderWiseAudienceLoaded && (genderWiseAudienceLoading === false)) &&
      (ageDistributionError !== null ? true : isAgeDistributionLoaded && (isAgeDistributionLoading === false)) &&
      (countryDataError !== null ? true : isCountryDataLoaded && (isCountryDataLoading === false)) &&
      (topMentionsError !== null ? true : isTopMentionsLoaded && (isTopMentionsLoading === false)) &&
      (topFollowersError !== null ? true : isTopFollowersLoaded && (isTopFollowersLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    genderWiseAudienceError, genderWiseAudienceLoaded, genderWiseAudienceLoading,
    ageDistributionError, isAgeDistributionLoaded, isAgeDistributionLoading,
    countryDataError, isCountryDataLoaded, isCountryDataLoading,
    topMentionsError, isTopMentionsLoaded, isTopMentionsLoading,
    topFollowersError, isTopFollowersLoaded, isTopFollowersLoading,
  ])

  return (
    <Wrapper>
      <div className="section_card section_dropdown_header">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="grid grid_cols_2">
              <div className="grid cols_span_1">
                <h2 className="section_card_title">Audience Distribution</h2>
              </div>
              <div className="grid cols_span_3 flex justify_end">
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={audienceDistributionOptions}
                  className="form_dropdown section_dropdown"
                  value={audienceDistributionDropdownValue}
                  selectedOptions={audienceDistributionDropdownValue}
                  setStatedropdown={dropdownSelectionHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid_margin_bottom">
        <div
          id="audience_distribution_age_gender_section"
          className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
        >
          {/* <div className="grid grid_cols_1">
            <div className="section_card_header">
              <h2 className="section_card_title">Audience Distribution</h2>
              <div data-html2canvas-ignore={true}>
                <ExportDropdown
                  sectionId={"audience_distribution_age_gender_section"}
                  sectionName={"Audience Distribution"}
                  sectionData={ageGenderCSV}
                  sectionDataHeaders={[["Male","Female","Unknown"],['Age',"Followers"]]}
                />
              </div>
            </div>
          </div> */}
          <div className="grid grid_cols_4">
            <div className="grid col_span_2 card_border_right flex column">
              <div className="section_card_header">
                <div className="section_info">   <h2 className="section_card_title">
                  By Age {" : " + audienceDistributionDropdownValue.label}
                </h2> <SectionDefinationsBadge
                    title="By Age"
                    module="social"
                    platform="twitter"
                    section="audience"
                  />
                </div>
                <div data-html2canvas-ignore={true}>
                  <ExportDropdown
                    moduleName={pageModule}
                    subModuleName={pageSubModule}
                    platform={pagePlatform}
                    tab={pagePrimaryTab}
                    sectionId={"audience_distribution_age_gender_section"}
                    sectionName={`AgeBarChartSocialTT_Age Distribution - Age_${audienceDistributionDropdownValue.value}`}
                    sectionData={ageExport}
                  />
                </div>
              </div>
              <div className="grid grid_cols_4 section_card_body">
                <div className="grid col_span_4 section_card_chart_wrapper">
                  {ageDistributionChart}
                </div>
              </div>
            </div>
            <div className="grid col_span_2 flex column">
              <div className="grid col_span_4 section_card_header">
                <div className="section_info">  <h2 className="section_card_title">
                  By Gender {" : " + audienceDistributionDropdownValue.label}
                </h2> <SectionDefinationsBadge
                    title="By Gender"
                    module="social"
                    platform="twitter"
                    section="audience"
                  />
                </div>
                <div data-html2canvas-ignore={true}>
                  <ExportDropdown
                    moduleName={pageModule}
                    subModuleName={pageSubModule}
                    platform={pagePlatform}
                    tab={pagePrimaryTab}
                    sectionId={"audience_distribution_age_gender_section"}
                    sectionName={"Audience Distribution - Gender"}
                    sectionData={genderExport}
                  />
                </div>
              </div>
              {/* <div className="grid grid_cols_3 section_card_body">
                {genderWiseAudienceChart}
              </div> */}
              <div className="grid col_span_4">
                <div className="grid col_span_3">
                  <div className="section_card_body">
                    <div className="section_card_chart_wrapper">
                      <div className="grid col_span_4 section_card_chart_wrapper">
                        <div className="grid col_span_1 flex row justify_center align_center">
                          {genderWiseAudienceChart}
                          {/* <div>{maleAudienceDistributionIcon}</div>
                          <div>{femaleAudienceDistributionIcon}</div>
                          <div>{unknownAudienceDistributionIcon}</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="audience_distribution_country_city_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          {/* <div className="grid col_span_2 card_border_right flex column"> */}
          {/* <div className="section_card_header">
            <h2 className="section_card_title">
              By Country {" : " + audienceDistributionDropdownValue.label}
               </h2> <SectionDefinationsBadge
                title="By Country"
                module="social"
                platform="twitter"
                section="audience"
              />
            </h2> */}
          {/* <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"audience_distribution_country_city_section"}
                sectionName={"Audience Distribution"}
                sectionData={cityCountryData}
              />
            </div> */}
          {/* </div> */}
          {/* <div className="grid grid_cols_1 section_card_body"> */}
          {countryMap}
          {/* </div> */}
          {/* </div> */}
          {/* <div className="grid col_span_2 flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              {cityMap}
            </div>
          </div> */}
        </div>
      </div>

      <div className="grid grid_cols_4 fullpage_section_card ">
        <div className="grid col_span_4 card_border_right flex column">
          {topMentionsChart}
        </div>
      </div>

      <div className="grid grid_cols_4 fullpage_section_card ">
        <div className="grid col_span_4 card_border_right flex column">
          {topFollowersChart}
        </div>
      </div>
    </Wrapper>
  );
};

export default SocialTwitterAudience;
