import Wrapper from "../../components/helpers/Wrapper";

const LikeIcon = (props) => {
  return (
    <Wrapper>
      <svg className={props.className} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.8639 6.62459C22.3659 6.12632 21.7745 5.73105 21.1237 5.46138C20.4728 5.1917 19.7752 5.0529 19.0707 5.0529C18.3662 5.0529 17.6686 5.1917 17.0178 5.46138C16.3669 5.73105 15.7756 6.12632 15.2775 6.62459L14.2443 7.65788L13.2103 6.62459C12.7148 6.11502 12.123 5.709 11.4692 5.43013C10.8154 5.15126 10.1127 5.0051 9.40194 5.00013C8.69118 4.99516 7.98651 5.1315 7.32889 5.4012C6.67127 5.67091 6.07381 6.06862 5.57121 6.57121C5.06862 7.07381 4.67091 7.67127 4.4012 8.32889C4.1315 8.98651 3.99516 9.69117 4.00013 10.4019C4.0051 11.1127 4.15126 11.8154 4.43013 12.4692C4.709 13.123 5.11502 13.7148 5.62459 14.2103L6.65788 15.2411L14.2443 22.83L21.83 15.2411L22.8633 14.2078C23.3616 13.7097 23.7568 13.1184 24.0265 12.4676C24.2962 11.8167 24.435 11.1191 24.435 10.4146C24.435 9.71009 24.2962 9.01249 24.0265 8.36164C23.7568 7.71079 23.3616 7.11945 22.8633 6.62141L22.8639 6.62459Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Wrapper>
  );
};

export default LikeIcon;
