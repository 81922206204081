import Wrapper from "../../components/helpers/Wrapper";

const SliderArrowLeft = (props) => {
    return (
        <Wrapper>
            <svg className={props.className} width="13" height="12" viewBox="0 0 13 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.4 6H1.39999M1.39999 6L6.39999 1M1.39999 6L6.39999 11" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </Wrapper>
    )
}

export default SliderArrowLeft;
