const WebsiteIcon = (props) => {
  return (
    <svg
      className={props.className}
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      stroke="#486F88"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5 40.25C35.0939 40.25 41.25 34.0939 41.25 26.5C41.25 18.9061 35.0939 12.75 27.5 12.75C19.9061 12.75 13.75 18.9061 13.75 26.5C13.75 34.0939 19.9061 40.25 27.5 40.25Z"
        strokeWidth="1.5"
      />
      <path
        d="M27.5 40.25C30.6756 40.25 33.25 34.0939 33.25 26.5C33.25 18.9061 30.6756 12.75 27.5 12.75C24.3244 12.75 21.75 18.9061 21.75 26.5C21.75 34.0939 24.3244 40.25 27.5 40.25Z"
        strokeWidth="1.5"
      />
      <path d="M14.948 26.802H40.468" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export default WebsiteIcon;
