const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    selectedBrands: [],
    selectedAds: [],
    selectedAgeGender: [],
};

const tvHaleonBrandsReducer = createSlice({
    name: "tvBrandsCamapaigns",
    initialState,
    reducers: {
        setSelectedBrandsFromDropdown(state, action) {
            state.selectedBrands = action.payload;
        },
        setSelectedAdsFromDropdown(state, action) {
            state.selectedAds = action.payload;
        },
        setSelectedAgeGenderFromDropdown(state, action) {
            state.selectedAgeGender = action.payload;
        },
        resetTvBrands: () => initialState
    },
});

export const { setSelectedBrandsFromDropdown, setSelectedAgeGenderFromDropdown, setSelectedAdsFromDropdown, resetTvBrands } = tvHaleonBrandsReducer.actions;

export default tvHaleonBrandsReducer.reducer;
