import { useState } from "react";

//COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";
import SocialLinkedinPostOrganic from "./SocialLinkedinPostOrganic";
import SocialLinkedinPostOverall from "./SocialLinkedinPostOverall";
import SocialLinkedinPostPaid from "./SocialLinkedinPostPaid";

import { IAEvent_Secondary_Tab_Visit } from "../../../../utils/IAEvents";

const SocialLinkedinPost = ({ topCardData }) => {

  const tabData = [
    "Overall",
    "Organic",
    "Paid",
  ];

  const [selectedTab, setSelectedTab] = useState(tabData[0]);

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Secondary_Tab_Visit("Marketing Intelligence", "Social", "Linkedin", "Post", event)
  };

  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <Tab
          varient={"secondary"}
          tabData={tabData}
          activeTab={selectedTab}
          handleCallback={(event) => handleCallback(event)}
        />
      </div>
      {selectedTab.toLowerCase() === tabData[0].toLowerCase() && <SocialLinkedinPostOverall topCardData={topCardData} />}
      {selectedTab.toLowerCase() === tabData[1].toLowerCase() && <SocialLinkedinPostOrganic topCardData={topCardData} />}
      {selectedTab.toLowerCase() === tabData[2].toLowerCase() && <SocialLinkedinPostPaid topCardData={topCardData} />}
    </Wrapper>
  );
};

export default SocialLinkedinPost;
