import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import IconEye from "../../assets/icons/EyeIcon";
import IconHideEye from "../../assets/icons/HideEyeIcon";
import Wrapper from "../helpers/Wrapper";

import IcogzLogo from "../../assets/brand/icogz-logo.svg";

import {
    API_URL,
    InvalidEmailMsg,
    RequiredField,
} from "../../constants/constants";

import Button from "../ui/Button";

import AuthContext from "../../store/authContext";

export default function ForgotPasswordForm() {
    const [isLoading, setLoading] = useState(false);

    const [formErros, setFormErrors] = useState();
    const [formData, setFormData] = useState({
        email: ""
    });

    const [isCurrentFieldsValidated, setCurrentFieldsValidated] = useState(false);

    const [emailIsValid, setEmailIsValid] = useState(true);
    const [emailError, setEmailError] = useState("");

    // EMAIL ID
    const emailChangeHandler = (event) => {
        setFormData({ ...formData, email: event.target.value });
    };
    const validateEmailHandler = () => {
        setEmailIsValid(
            /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
                formData.email
            )
        );

        if (formData.email.length === 0) {
            setEmailError(RequiredField);
        } else if (emailIsValid === false) {
            setEmailError(InvalidEmailMsg);
        } else {
            setEmailError();
        }
    };

    const formHandler = () => {
        setLoading(true);
        const ApiData = {
            email: formData.email
        };

        alert(formData.email)
    };

    useEffect(() => {
        formData.email !== "" ? setCurrentFieldsValidated(true) : setCurrentFieldsValidated(false);
    });

    return (
        <Wrapper>
            <div className="form_wrapper">
                <div className="form_header flex column align_center p_10">
                    <div className="form_header_logo">
                        <img className="logo" src={IcogzLogo} alt="logo"/>
                    </div>
                </div>
                <div className="p_10 flex justify_center">
                    <h4 className="form_subtitle">Forgot Password</h4>
                </div>
                <div className="p_10 flex justify_center">
                    <h6 className="error_text">{formErros}</h6>
                </div>
                <form className="form" action="">
                    <div className="form_group">
                        <input
                            className={
                                emailIsValid === true ? "form_control" : "form_control invalid"
                            }
                            type="email"
                            name="email"
                            id="email"
                            autoComplete="off"
                            placeholder="Please Enter Your Email"
                            value={formData.email}
                            onChange={emailChangeHandler}
                            onBlur={validateEmailHandler}
                        />
                        {emailIsValid === false && (
                            <h6 className="error_text">{emailError}</h6>
                        )}
                    </div>
                    <div className="flex justify_between p_10">
                        <Link className="link" to={"/register"}><h6>Create an account</h6></Link>
                        <Link className="link" to={"/login"}><h6>Login with existing account</h6></Link>
                    </div>
                    <div className="flex justify_between">
                        <Button
                            handleClick={formHandler}
                            disabled={!isCurrentFieldsValidated}
                            className="primary_btn"
                            type="button"
                        >
                            {!isLoading ? "Send Email" : "Sending Email"}
                        </Button>
                    </div>
                </form>
            </div>
        </Wrapper>
    );
}
