import LoginForm from "../components/forms/LoginForm";
import Wrapper from "../components/helpers/Wrapper";

export default function LoginPage() {
    return (
        <Wrapper>
            <div className="grid grid_cols_4 grid_center">
                <div className="grid col_span_2 col_start_2 section_card max_width grid_center h_auto">
                    <LoginForm />
                </div>
            </div>
        </Wrapper >

    )
}