import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// STORAGE
import AuthContext from "../../../../store/authContext";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import PageHeader from "../../../../components/layout/pageHeader";
import Button from "../../../../components/ui/Button";
import Tab from "../../../../components/ui/Tab";
import CampaignPlatformObjectiveDropdown from "../../../../components/ui/dropdown/CampaignPlatformObjectiveDropdown";

// UTILS
import { RequiredField } from "../../../../constants/constants";
import NewCampaignList from "../campaignLists/newCampaignList";
import useApi from "../../../../hooks/useApi";
import Alert from "../../../../components/ui/alert/Alert";

const NewCreateBucket = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const createBucketAPI = useApi()


  // SELECTED OBJECTIVES FROM DROPDOWN
  const objectiveFilter = useSelector((state) => state.campaignAdmin.adminCreateBucketCampaignObjectivesFilter);

  // SELECTED CAMPAIGN IN BUCKET

  // FILTER ACTIVE OR NOT
  const [isBucketFilterActive, setBucketFilterActive] = useState(false);

  const [isClearButtonClicked, setClearButtonClicked] = useState(false);

  useEffect(() => {
    setClearButtonClicked(false);
  }, [isClearButtonClicked]);

  const [isSaveEditButtonDisabled, setSaveEditButtonDisabled] = useState(true);
  const [isSaveBucketButtonDisabled, setSaveBucketButtonDisabled] = useState(false);

  // BUCKET NAME
  const [bucketName, setBucketName] = useState(state !== null ? state.name : "");
  const [bucketNameIsTouched, setBucketNameIsTouched] = useState(false);
  const [bucketNameIsValid, setBucketNameIsValid] = useState(false);
  const [bucketNameError, setBucketNameError] = useState();

  // BUCKET DESCRIPTION
  const [bucketDescription, setBucketDescription] = useState(state !== null ? state.description : "");
  const [bucketDescriptionIsTouched, setBucketDescriptionIsTouched] = useState(false);
  const [bucketDescriptionIsValid, setBucketDescriptionIsValid] = useState(false);
  const [bucketDescriptionError, setBucketDescriptionError] = useState();

  const [selectedCampaigns, setselectedCampaigns] = useState([]);



  const [campaignsObj, setcampaignsObj] = useState({})

  const [selectedTab, setSelectedTab] = useState("All Campaigns");

  const tabData = ["All Campaigns"];

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
  };

  const [alertType, setAlertType] = useState("");
  const [alertTypeValue, setAlertTypeValue] = useState(0);




  const createBucketHandler = async (e) => {
    e.preventDefault()

    setSaveBucketButtonDisabled(true);

    const configs = {
      name: bucketName,
      description: bucketDescription,
      add_items: campaignsObj
    }


    try {

      await createBucketAPI.postRawJSONData('performance/google/bucket/', JSON.stringify(configs)).then(() => {

        setAlertType('Campaigns Added')
        setAlertTypeValue(selectedCampaigns.length)
        setTimeout(() => {
          navigate('/admin/campaign/')
        }, 3000)

      }).catch((err) => { throw new Error(err) })

    } catch (error) {
      console.log('Add Bucket error', error)
    }

  }

  let addBucketButtonName = "Create Bucket";

  if (createBucketAPI.apiStatus.isLoading) {
    addBucketButtonName = "Creating Bucket";
  }

  if (createBucketAPI.apiStatus.isLoaded) {
    addBucketButtonName = "Created Bucket";
  }


  const bucketDescriptionInputIsInvalid = !bucketDescriptionIsValid && bucketDescriptionIsTouched;
  const bucketNameInputIsInvalid = !bucketNameIsValid && bucketNameIsTouched;
  const bucketNameInputClasses = bucketNameInputIsInvalid ? "form_control invalid" : "form_control";
  const bucketDescriptionInputClasses = bucketDescriptionInputIsInvalid ? "form_control invalid" : "form_control";

  const bucketNameInputBlurHandler = (event) => {
    setBucketNameIsTouched(true);
    if (event.target.value.length === 0) {
      setSaveEditButtonDisabled(true);
      setBucketNameIsValid(false);
      setBucketNameError(RequiredField);
    } else {
      setSaveEditButtonDisabled(false);
      setBucketNameIsValid(true);
      setBucketNameError(null);
    }
  }


  const bucketNameInputChangeHandler = (event) => {
    setBucketName(event.target.value);
  };

  const bucketDescriptionInputChangeHandler = (event) => {
    setBucketDescription(event.target.value);
  };


  const HandleCheckboxClick = (data) => {
    let checkedData = [...data];
    let obj = {};
    setselectedCampaigns(data)
    checkedData.forEach((item) => {

      if (obj[item.platform]) {
        obj[item.platform].push(item.campaign_id)
      } else {
        obj[item.platform] = [item.campaign_id]
      }
    })

    setcampaignsObj(obj)
  }


  return (
    <Wrapper>
      <div className="content">
        <div className="content_section">
          <div className="flex justify_between align_center">
            <PageHeader
              pageTitle={"Create New Bucket"}
            />
          </div>
          <div className="page_content">
            <Alert
              alertType={alertType}
              alertTypeValue={alertTypeValue}
            />
            {/* <div className={`admin_alert_wrap ${alertBoxValue}`}>
              {alertBoxSection}
            </div> */}
            <form className="form" onSubmit={createBucketHandler}>
              <div className="grid grid_cols_3">
                <div className="grid grid_span_2 grid_margin_bottom">
                  <div className="form_group flex">
                    <div className="mr_20">
                      <input
                        className={bucketNameInputClasses}
                        type="text"
                        name="bucket_name"
                        id="bucket_name"
                        placeholder="Enter Bucket Name"
                        value={bucketName}
                        onChange={bucketNameInputChangeHandler}
                        onBlur={bucketNameInputBlurHandler}
                      />
                      <div className="error_section">
                        {bucketNameInputIsInvalid && (
                          <h6 className="error_text m_t_5">{bucketNameError}</h6>
                        )}
                      </div>
                    </div>
                    <div>
                      <input
                        className={bucketDescriptionInputClasses}
                        type="text"
                        name="bucket_desc"
                        id="bucket_desc"
                        placeholder="Enter Description"
                        maxLength={100}
                        value={bucketDescription ?? ''}
                        onChange={bucketDescriptionInputChangeHandler}
                      />
                      <div className="error_section">
                        {bucketDescription?.length === 100 && (
                          <h6 className="error_text m_t_5">Character Limit Reached!</h6>
                        )}
                      </div>
                    </div>

                  </div>
                </div>
                <div className="grid grid_span_1 grid_margin_bottom">
                  <div className="flex start top_nav_options">
                    <CampaignPlatformObjectiveDropdown
                      isFilterActive={isBucketFilterActive}
                      filterCount={objectiveFilter === undefined ? 0 : objectiveFilter.length}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid_margin_bottom">
                <Tab
                  varient={"primary"}
                  tabData={tabData}
                  activeTab={selectedTab}
                  handleCallback={(event) => handleCallback(event)}
                />
              </div>
              <Wrapper>
                <div className="grid_margin_bottom">
                  <NewCampaignList
                    title={'Select campaigns to add in bucket'}
                    hasCheckbox={true}
                    HandleCheckboxClick={HandleCheckboxClick}
                    isCleared={isClearButtonClicked}
                    selectedCampaigns={selectedCampaigns}
                  />
                </div>
                <div className="grid grid_cols_4 flex row justify_between">
                  <div className="grid col_span_2">
                    {selectedCampaigns.length !== 0 &&
                      <h4 className="info_text">
                        {selectedCampaigns.length + " campaigns selected to add in the bucket"}
                      </h4>
                    }
                  </div>
                  <div className="grid col_span_2 form_actions flex row justify_end">
                    <div className="btn_wrapper p_10">
                      <Button
                        handleClick={() => navigate("/admin/campaign/")}
                        className="secondary_danger_btn"
                        type="button"
                        disabled={isSaveBucketButtonDisabled}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="btn_wrapper p_10">
                      <Button
                        // handleClick={() => onClickClearButtonHandler()}
                        handleClick={() => {
                          setClearButtonClicked(true)
                        }}
                        className="secondary_btn"
                        type="button"
                      >
                        Clear Selected
                      </Button>
                    </div>
                    <div className="btn_wrapper p_10">
                      <Button
                        disabled={selectedCampaigns.length === 0 || isSaveBucketButtonDisabled || bucketName.length === 0 ? true : false}
                        className="primary_btn"
                        type="submit">
                        {addBucketButtonName}
                      </Button>
                    </div>
                  </div>
                </div>
              </Wrapper>
            </form>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default NewCreateBucket;
