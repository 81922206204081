import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";

// STORAGE
import AuthContext from "../../../store/authContext";
import FullPageExportContext from "../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../components/helpers/axios";
import Wrapper from "../../../components/helpers/Wrapper";
import Datatable from "../../../components/ui/tables/Datatable";
import ExportDropdown from "../../../components/ui/dropdown/ExportDropdown";
import SectionDefinationsBadge from "../../../components/ui/SectionDefinationsBadge";

// LOADERS
import NoDataAvailableLoader from "../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../components/ui/loaders/ServerErrosLoader";
import Loader from "../../../components/ui/loaders/Loader";

// UTILS
import ErrorBoundary from "../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../utils/NoDataAvailableChecker";


const WebsiteLandingPage = ({ viewId }) => {

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Website"
  let pagePlatform = "Traffic"
  let pagePrimaryTab = "Landing Page"

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, exportSummary, setIsExportDisabled } = useContext(FullPageExportContext);

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [tableDataError, setTableDataError] = useState(null);

  // TO FETCH TABLE DATA FROM API
  const fetchTableDataHandler = async () => {
    setTableDataLoaded(false);
    setTableDataLoading(true);
    setTableDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        view_id: viewId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("/website/ga-landing-page/", config);

      setTableData(res?.data?.data ?? []);
      setTableHeaders(res?.data?.label ?? [])

      setTableDataLoaded(true);
      setTableDataLoading(false);
      setTableDataError(null);
    } catch (error) {
      setTableDataLoaded(true);
      setTableDataLoading(false);
      setTableDataError(error.code);
    }
  };

  // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
  useEffect(() => {
    viewId === undefined
      ? <NoDataAvailableLoader chartType="tableChartType" />
      : viewId.length === 0
        ? <NoDataAvailableLoader chartType="tableChartType" />
        : fetchTableDataHandler();
  }, [BRAND_ID, viewId, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TABLE DATA
  let summaryTable = (
    <Wrapper>
      <div className="section_card fullpage_section_card grid">
        <div className="section_card_header">
          <h2 className="section_card_title">Summary
            <SectionDefinationsBadge
              title={"Summary"}
              module="website"
              platform="landing page"
              section="overall"
            />
          </h2>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            {viewId === undefined ? (
              <NoDataAvailableLoader chartType="tableChartType" />
            ) : viewId.length === 0 ? (
              <NoDataAvailableLoader chartType="tableChartType" />
            ) : (
              <Loader loaderType="smallTableLoader" />
            )}

          </div>
        </div>
      </div>
    </Wrapper>
  );

  if (tableDataLoaded && !tableDataLoading) {
    if (NoDataAvailableChecker(tableData) || NoDataAvailableChecker(tableHeaders)) {
      summaryTable = <Wrapper>
        <div className="section_card fullpage_section_card grid">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary
              <SectionDefinationsBadge
                title={"Summary"}
                module="website"
                platform="landing page"
                section="overall"
              />
            </h2>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <NoDataAvailableLoader chartType="tableChartType" />
            </div>
          </div>
        </div>
      </Wrapper>
    } else {
      summaryTable = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"Summary"}
              tablePageSubModule="website"
              tablePagePlatform="landing page"
              tablePagePrimaryTab="overall"
              tableHeader={tableHeaders}
              tableData={tableData}
              tableLength={10}
              isFooterShow={true}
              searchPlaceHolder={"Search Pages"}
              tableID={"pages_table_section"}
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"pages_table_section"}
                  sectionName="Summary Table"
                  sectionData={tableData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }

  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (tableDataError) {
    summaryTable = (
      <Wrapper>
        <div className="section_card fullpage_section_card grid">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary
              <SectionDefinationsBadge
                title={"Summary"}
                module="website"
                platform="landing page"
                section="overall"
              />
            </h2>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={tableDataError} />
            </div>
          </div>
        </div>
      </Wrapper>);
  }

  // IF DATA LOADING
  if (tableDataLoading) {
    summaryTable = <Wrapper>
      <div className="section_card fullpage_section_card grid">
        <div className="section_card_header">
          <h2 className="section_card_title">Summary
            <SectionDefinationsBadge
              title={"Summary"}
              module="website"
              platform="landing page"
              section="overall"
            />
          </h2>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>

    </Wrapper>
  }

  var allData = { Summary: exportSummary, "Summary Table": tableData };

  useEffect(() => {
    setFullPageExport(allData);
  }, [tableData]);

  useEffect(() => {
    if (tableDataError !== null ? true : tableDataLoaded && (tableDataLoading === false)) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [tableDataError, tableDataLoaded, tableDataLoading])


  return (
    <Wrapper>
      {summaryTable}
    </Wrapper>
  );
};

export default WebsiteLandingPage;
