import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SomethingWentWrong from "../components/ui/loaders/SomethingWentWrong";

function ErrorBoundary({ chartType, children }) {

    const [hasError, setHasError] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (hasError) {
            setHasError(false);
        }
    }, [location.key]);

    return (
        <ErrorBoundaryInner
            hasError={hasError}
            setHasError={setHasError}
            chartType={chartType}
        >
            {children}
        </ErrorBoundaryInner>
    );
}


class ErrorBoundaryInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_error) {
        return { hasError: true };
    }

    componentDidUpdate(prevProps, _previousState) {
        if (!this.props.hasError && prevProps.hasError) {
            this.setState({ hasError: false });
        }
    }

    componentDidCatch(_error, _errorInfo) {
        this.props.setHasError(true);
    }

    render() {
        if (this.state.hasError) {
            return <SomethingWentWrong chartType={this.props.chartType} />
        }

        return this.props.children;
    }
}

export default ErrorBoundary;