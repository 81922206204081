import { useContext, useEffect, useState } from "react";

// STORAGE
import AuthContext from "../../../../store/authContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/ui/loaders/Loader";
import Datatable from "../../../../components/ui/tables/Datatable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import FullPageExportContext from "../../../../store/fullPageExportContext";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import useApi from "../../../../hooks/useApi";
import CheckBoxDatatable from "../../../../components/ui/tables/CheckboxDatatable";
import { useLocation } from "react-router-dom";
import { setAdminCreateBucketCampaignObjectivesFilter } from "../../../../store/admin/campaignAdminSlice";

const NewCampaignList = ({
  title,
  hasCheckbox,
  isCreatableDropdown,
  HandleCheckboxClick,
  excludeData,
  isNonDynamic,
  isCleared,
  selectedCampaigns
}) => {
  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();
  const location = useLocation()

  const campaignListAPI = useApi()
  const campaignListAllDataAPI = useApi()


  const { setFullPageExport, setIsExportDisabled, setExportPageName } = useContext(FullPageExportContext);


  const [campaignSummaryConfig, setCampaignSummaryConfig] = useState({
    page_num: 1,
    page_size: isNonDynamic ? -1 : 10,
    order_type: "desc",
    order_by: "bucket",
  })


  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null
  } else {
    BRAND_ID = globalSelectedBrandIds.join(',')
  }


  const objectiveFilter = useSelector((state) => state.campaignAdmin.adminCreateBucketCampaignObjectivesFilter);

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date)

  const campaignListTableHeaders = [
    { name: "Campaign Name", field: "platform_campaign_name" },
    { name: "Brand Name", field: "brand_name" },
    { name: "Campaign Bucket", field: "bucket" },
    { name: "Objective", field: "objective" },
    { name: "Platform", field: "platform" },
    { name: "Start Date", field: "start_date" },
    { name: "End Date", field: "end_date" }
  ];


  const fetchCampaignListAllData = async () => {
    const params = {
      start_date: selectedDates.startDate,
      end_date: selectedDates.endDate,
      previous_start_date: selectedDates.prevStartDate,
      previous_end_date: selectedDates.prevEndDate,
      page_num: 1,
      page_size: -1,
      order_type: "desc",
      order_by: "bucket",
    }

    await campaignListAllDataAPI.fetchData('performance/google/all-campaign-list/', params)
  }

  useEffect(() => {
    fetchCampaignListAllData()
  }, [selectedDates])



  // TO FETCH METRIC CARDS FROM API
  const fetchCampaignList = async () => {

    let objectiveFiilter = objectiveFilter.length === 0 || objectiveFilter === undefined ? null : [...new Set(objectiveFilter.map((item) => item.campaign_objective)),].join(",");
    let platformFilter = objectiveFilter.length === 0 || objectiveFilter === undefined ? null : [...new Set(objectiveFilter.map((item) => item.platform))].join(",");


    const params = {
      start_date: selectedDates.startDate,
      end_date: selectedDates.endDate,
      previous_start_date: selectedDates.prevStartDate,
      previous_end_date: selectedDates.prevEndDate,
      ...(objectiveFiilter ? { objective: objectiveFiilter } : {}),
      ...(platformFilter ? { platform: platformFilter } : {}),
      ...campaignSummaryConfig,
    }


    await campaignListAPI.fetchData('performance/google/all-campaign-list/', params)
  };


  const formattedData = excludeData && excludeData.length !== 0 ? campaignListAPI?.data?.data?.filter((item) => !excludeData.includes(item.campaign_id)) ?? [] : campaignListAPI?.data?.data ?? []

  let campaignListTable =
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">{title ?? "Campaign List"}</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>


  if (!campaignListAPI.apiStatus.isLoading && campaignListAPI.apiStatus.isLoaded) {
    campaignListTable = <Wrapper>
      <CheckBoxDatatable
        tableID={'new-table'}
        tableTitle={title ?? 'Campaign List'}
        tableData={formattedData ?? []}
        checkbox={hasCheckbox ?? false}
        onCheckboxClickHandler={HandleCheckboxClick}
        tableHeader={campaignListTableHeaders}
        tableLength={isNonDynamic ? 10 : campaignSummaryConfig.page_size ?? 10}
        isFooterShow={true}
        searchPlaceHolder={"Search Campaigns"}
        tableConfigs={isNonDynamic ? undefined : campaignSummaryConfig}
        setTableConfigs={isNonDynamic ? undefined : setCampaignSummaryConfig}
        tablePaginationData={isNonDynamic ? undefined : campaignListAPI.data.pagination}
        isCreatableDropdown={isCreatableDropdown ?? false}
        dynamicCheckboxRef={selectedCampaigns ?? []}
        clearSelectedItems={isCleared}
        hasTableLenghtDropdown={true}
        tableExportDropdown={<ExportDropdown
          sectionId={"campaignList"}
          sectionName={'Campaign Lists'}
          sectionData={formattedData ?? []}
        />}
      />
    </Wrapper>

  }


  useEffect(() => {
    fetchCampaignList();
  }, [selectedDates, campaignSummaryConfig, objectiveFilter]);

  useEffect(() => {
    objectiveFilter.length !== 0 && dispatch(setAdminCreateBucketCampaignObjectivesFilter([]));
  }, [location.pathname])


  useEffect(() => {
    setExportPageName('Campaign List')
    setFullPageExport(campaignListAllDataAPI?.data?.data ?? [])
  }, [campaignListAllDataAPI.data]);

  useEffect(() => {
    if (campaignListAllDataAPI.apiStatus.error !== null ? true : campaignListAllDataAPI.apiStatus.isLoaded && (campaignListAllDataAPI.apiStatus.isLoading === false)) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [campaignListAllDataAPI.apiStatus])



  return (
    <Wrapper>
      <div className="grid_margin_bottom">
        {campaignListTable}
      </div>
    </Wrapper>
  )
}
export default NewCampaignList;