const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    user: false,
    allLegendsActive: false
};

const multiLineClusteredReducer = createSlice({
    name: "multiLineCluster",
    initialState,
    reducers: {
        setMultiLineCluster(state, action) {
            state.user = action.payload;
        },
        setShowAllLegendsInMultiLineCluster(state, action) {
            state.allLegendsActive = action.payload;
        },
    },
});

export const { setMultiLineCluster, setShowAllLegendsInMultiLineCluster } = multiLineClusteredReducer.actions;

export default multiLineClusteredReducer.reducer;
