const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  getGa4Section: false,
};

const ga4SectionReducer = createSlice({
  name: "ga4section",
  initialState,
  reducers: {
    setGa4Section(state, action) {
      state.getGa4Section = action.payload;
    },
    resetGa4Section: () => initialState,

  },
});

export const { setGa4Section, resetGa4Section } = ga4SectionReducer.actions;

export default ga4SectionReducer.reducer;
