import React, { useEffect } from 'react'
import ReactDOM from "react-dom";
import Wrapper from '../helpers/Wrapper'
import EventTracker from '../eventsTracking/EventTracker';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

export default function Modal({
    open,
    title,
    parentId,
    dropdown,
    exportDropdown,
    chart,
    setOpen
}) {

    const location = useLocation();
    let splitURL = location.pathname.split("/");
    let activeModule = splitURL[1];
    let activeSubmodule = splitURL[2];



    const selectedDates = useSelector((state) => state.date);
    const selectedBrands = useSelector((state) => state.globalSelectedBrandIds.selectedBrands);

    let [searchParams, setSearchParams] = useSearchParams();
    let activePlatform = searchParams.get("platform");

    useEffect(() => {
        if (open) {
            document.body.classList.toggle('modal_open', open);

            EventTracker({
                eventType: "chart_expand_click",
                ...(activeModule && { module: activeModule }),
                ...(activeSubmodule && { subModule: activeSubmodule }),
                ...(activePlatform && { platform: activePlatform }),
                // ...(tab && { tab }),
                // ...(subtab && { subtab }),
                ...(title && { section: title }),
                ...(selectedDates && {
                    date: {
                        startDate: selectedDates.startDate,
                        endDate: selectedDates.endDate,
                        previousStartDate: selectedDates.prevStartDate,
                        previousEndDate: selectedDates.prevEndDate,
                    }
                }),
                ...(selectedBrands && { brands: selectedBrands }),
                // ...(campaigns && { campaigns }),
                // ...(campaignBuckets && { campaignBuckets }),

            })
        }

    }, [open])


    if (!open) {
        return (<></>)
    }

    window.scroll(0, 0);


    return ReactDOM.createPortal(
        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
            <div className="popup_wrapper">
                <div className="section_card_header">
                    <div className="section_info">
                        <h2 className="section_card_title">
                            {title && title}
                        </h2>
                    </div>
                    <div data-html2canvas-ignore={true}>
                        <button
                            onClick={() => {
                                parentId && document.getElementById(parentId).scrollIntoView({ block: 'center' })
                                setOpen(false)

                            }}
                            className="close_popup icon_btn btn-small-secondary">
                            &times;
                        </button>
                        {dropdown && dropdown}
                        {exportDropdown && exportDropdown}
                    </div>
                </div>
                <div className="popup_box section_card_body" >
                    <div className="section_card_chart_wrapper unset_height">
                        {chart && chart}
                    </div>

                </div>
            </div>
        </div>,
        document.getElementById("modal")
    )
}

