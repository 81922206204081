import { useEffect, useState, useContext, useRef } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import IconEye from "../../assets/icons/EyeIcon";
import IconHideEye from "../../assets/icons/HideEyeIcon";
import Wrapper from "../helpers/Wrapper";

import IcogzLogo from "../../assets/brand/icogz-logo.svg";

import {
  API_URL,
  EmailRegex,
  InvalidEmailMsg,
  RequiredField,
  DefaultPostImage,
  COKE_COMPANY_ID,
  HALEON_COMPANY_ID
} from "../../constants/constants";

import Button from "../ui/Button";

import AuthContext from "../../store/authContext";
import { useDispatch } from "react-redux";
import { getBrandsDropdownOptionsList, setSelectedBrandIds, setSelectedBrands } from "../../store/globalBrandsDropdownSlice";
import { setUserData } from "../../store/userSlice";
import { setSidebarData } from "../../store/sidebarSlice";
import { setModuleData } from "../../store/moduleSlice";
import { setMultiLineCluster, setShowAllLegendsInMultiLineCluster } from "../../store/multiLineClusteredSlice";
import axios from "axios";
import { setGlobalFilterCampaignBucket } from "../../store/userPermissionSlice";
import { setGa4Section } from "../../store/ga4SectionSlice";
import { trackLogin, TrackLogin } from "../eventsTracking/EventsTracking";


export default function LoginForm() {
  const GA4_FLAG = process.env.REACT_GA4_NEW_SECTIONS
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const authCtx = useContext(AuthContext);

  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const [isLoading, setLoading] = useState(false);

  const [formErros, setFormErrors] = useState();
  const [loginFormData, setLoginFormData] = useState({
    email: "",
    password: "",
  });

  const [isCurrentFieldsValidated, setCurrentFieldsValidated] = useState(true);

  const [emailIsTouched, setEmailIsTouched] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [emailError, setEmailError] = useState();

  const [passwordIsTouched, setPasswordIsTouched] = useState(false);
  const [passdwordIsValid, setPasswordIsValid] = useState(false);
  const [passwordError, setPasswordError] = useState();

  const [passwordType, setPasswordType] = useState("password");

  // EMAIL ID
  const emailChangeHandler = () => {
    setEmailIsTouched(true);

    if (!EmailRegex.test(emailInputRef.current.value)) {
      setEmailError(InvalidEmailMsg);
      setEmailIsValid(false);
      setLoginFormData({
        ...loginFormData,
        email: emailInputRef.current.value,
      });
    } else {
      setEmailError();
      setEmailIsValid(true);
      setLoginFormData({
        ...loginFormData,
        email: emailInputRef.current.value,
      });
    }
  };
  const validateEmailHandler = () => {
    setEmailIsTouched(true);
    if (emailInputRef.current.value === "") {
      setEmailIsValid(false);
      setEmailError(RequiredField);
    }
  };

  // PASSWORD
  const passwordChangeHandler = () => {
    setPasswordIsTouched(true);
    setPasswordIsValid(true);
    setPasswordError();
    setLoginFormData({
      ...loginFormData,
      password: passwordInputRef.current.value,
    });
  };
  const validatePasswordHandler = () => {
    setPasswordIsTouched(true);
    if (passwordInputRef.current.value === "") {
      setPasswordIsValid(false);
      setPasswordError(RequiredField);
    }
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const formHandler = () => {
    setLoading(true);
    const ApiData = {
      email: loginFormData.email,
      password: loginFormData.password,
    };

    fetch(API_URL + "auth/login-user/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(ApiData),
    })
      .then((res) => {
        setLoading(false);
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            setFormErrors(data.detail);
          });
        }
      })
      .then((res) => {
        if (res != undefined) {

          dispatch(setUserData(res.user_data)); // USER DATA
          dispatch(setSidebarData(res.side_bar)); // SIDEBAR DATA

          dispatch(setMultiLineCluster(API_URL === "https://uatapi.icogz.com/api/" ? HALEON_COMPANY_ID !== res?.user_data[0]?.company?.id ? true : false : true))
          // SHOW ALL MULTILINE CLUSTER CHART LEGENDS OR NOT

          dispatch(setShowAllLegendsInMultiLineCluster(true))

          dispatch(setGa4Section(true))

          // TO ADD AUTO LOGOUT FROM API RESPONSE
          const expirationTime = new Date(new Date().getTime() + 14400 * 1000); // TO ADD AUTO LOGOUT IN SECONDS 1 SECOND = 1000
          authCtx.login(res.access, expirationTime.toISOString());
          //   authCtx.login(res.access);

          fetchLoggedInData(res.access);

          // TRACK EVENT
          trackLogin(res.user_data[0], "password")

          // IA LOGIN EVENT
          window.icogz.trackEvent(
            JSON.stringify({
              email: res.user_data[0].email
            }), "User Logged In");

          // TO REDIRECT USER TO THE LAST VISITED PAGE FROM WHICH IT GOT LOGOUT
          if (location.state?.from) {
            navigate(location.state.from);
          } else {
            navigate("/");
          }
        }
      });
  };

  const fetchLoggedInData = async (authTok) => {
    const config = {
      params: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authTok,
      },
    };

    try {
      const res = await axios.get(API_URL + "auth/permission-list/", config);

      if (res !== undefined) {
        let temp = [];
        res.data.brand_list.map((data) => {
          try {
            let a =
            {
              value: data.brand_id,
              label: data.brand_name,
              icon: data.brand_logo

            }

            temp.push(a);
          } catch (error) {
            console.log('error')
          }
        })

        let brandsDropdownOpts = res.data.brand_list === "" ? [] : temp;
        dispatch(getBrandsDropdownOptionsList(brandsDropdownOpts));  // BRANDS DROPWON DATA

        dispatch(setGlobalFilterCampaignBucket(res?.data?.user_permissions?.section?.global_filter_campaign_bucket ?? false)); // CAMPAIGN BUCKET FILTER 
        // dispatch(setGlobalFilterCampaignBucket(true)); // CAMPAIGN BUCKET FILTER 

        dispatch(setModuleData(res?.data?.module_data ?? [])); // MODULE DATA 

      }
    } catch (error) {
      console.log('error')
    }
  };

  const emailInputIsInvalid = !emailIsValid && emailIsTouched;
  const emailInputClasses = emailInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  const passwordInputIsInvalid = !passdwordIsValid && passwordIsTouched;
  const passwordInputClasses = passwordInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  useEffect(() => {
    loginFormData.email !== "" && loginFormData.password !== ""
      ? setCurrentFieldsValidated(false)
      : setCurrentFieldsValidated(true);
  }, [loginFormData]);

  const onSSOLoginBtnClickHandler = () => {
    navigate("/enterprise/login")
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (loginFormData.email === null || loginFormData.email.length === 0 || loginFormData.password === null || loginFormData.password.length === 0) {
        if (loginFormData.email === null) {
          setEmailError(RequiredField);
        }

        if (loginFormData.password === null) {
          setPasswordError(RequiredField);
        }

      } else {
        e.preventDefault();
        formHandler();
      }
    }
  };


  return (
    <Wrapper>
      <div className="form_wrapper">
        <div className="form_header flex column align_center p_10">
          <div className="form_header_logo">
            <img className="logo" src={IcogzLogo} alt="logo" />
          </div>
        </div>
        <div className="p_10 flex justify_center">
          <h6 className="error_text">{formErros}</h6>
        </div>
        <form className="form" action="">
          <div className="form_group">
            <input
              className={emailInputClasses}
              type="email"
              name="email"
              id="email"
              autoComplete="off"
              placeholder="Please Enter Your Email"
              value={loginFormData.email}
              onChange={emailChangeHandler}
              onBlur={validateEmailHandler}
              ref={emailInputRef}
              onKeyDown={handleKeyPress}
            />
            <div className="error_section">
              {emailInputIsInvalid && (
                <h6 className="error_text">{emailError}</h6>
              )}
            </div>
          </div>
          <div className="form_group">
            <input
              className={passwordInputClasses}
              type={passwordType}
              name="password"
              id="password"
              placeholder="Please Enter Your Password"
              value={loginFormData.password}
              onChange={passwordChangeHandler}
              onBlur={validatePasswordHandler}
              ref={passwordInputRef}
              onKeyDown={handleKeyPress}
            />
            <div className="form_group_btn_wrapper">
              <button
                className="show_password_btn"
                type="button"
                onClick={() => togglePassword()}
              >
                {passwordType === "password" ? (
                  <IconHideEye className={"icon"} />
                ) : (
                  <IconEye className={"icon"} />
                )}
              </button>
            </div>
            <div className="error_section">
              {passwordInputIsInvalid && (
                <h6 className="error_text">{passwordError}</h6>
              )}
            </div>
          </div>
          <div className="flex justify_between p_10">
            <Link to={"/forgot-password"}>
              <div className="help_text">Forgot Password</div>
            </Link>
            <Link to={"/register"}>
              <div className="help_text">Create new account</div>
            </Link>
          </div>
          <div className="flex justify_between">
            <div className="btn_wrapper btn_group_wrapper">
              <Button
                handleClick={formHandler}
                disabled={isCurrentFieldsValidated}
                className="primary_btn"
                type="button"
              >
                {!isLoading ? "Login" : "Checking your login details"}
              </Button>
            </div>
          </div>
          <div className="flex justify_between">
            <div className="btn_wrapper btn_group_wrapper">
              <Button
                handleClick={onSSOLoginBtnClickHandler}
                className="secondary_btn"
                type="button"
              >
                Sign In with SSO
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Wrapper>
  );
}
