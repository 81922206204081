import React, { useContext, useEffect, useState } from "react";
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";

// import ThumbnailYoutubeVideoOverall from "./ThumbnailYoutubeVideoOverall";
import ThumbnailYoutubeVideoOrganic from "./ThumbnailYoutubeVideoOrganic";
import ThumbnailYoutubeVideoPaid from "./ThumbnailYoutubeVideoPaid";
import FullPageExportContext from "../../../../store/fullPageExportContext";

import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";
import { useSelector } from "react-redux";
import { COKE_COMPANY_ID, HALEON_COMPANY_ID, IMPRIVATA_COMPANY_ID } from "../../../../constants/constants";

export default function ThumbnailYoutubeVideo({ videoIdentity, videoDurationType, topCardData }) {
  const { setExportPageName } = useContext(FullPageExportContext);

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  let companyID = userData?.company?.id ?? '';

  let tabData = [];

  if (companyID === COKE_COMPANY_ID || companyID === HALEON_COMPANY_ID || companyID === IMPRIVATA_COMPANY_ID) {
    tabData = ["Overall"];
  } else {
    tabData = ["Overall", "Paid"];
  }

  // const tabData = ["Overall", "Organic", "Paid"];

  const [selectedTab, setSelectedTab] = useState(tabData[0]);

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Video", "Youtube Thumbnail", event)
  };

  useEffect(() => {
    setExportPageName("Video Youtube Thumbnail " + selectedTab)
  }, [selectedTab])

  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <Tab
          varient={"secondary"}
          tabData={tabData}
          activeTab={selectedTab}
          handleCallback={(event) => handleCallback(event)}
        />
      </div>
      {/* {selectedTab === "Overall" && <ThumbnailYoutubeVideoOverall videoIdentity={videoIdentity} videoDurationType={videoDurationType} />}
      {selectedTab === "Organic" && <ThumbnailYoutubeVideoOrganic videoIdentity={videoIdentity} videoDurationType={videoDurationType} />} */}
      {selectedTab.toLowerCase() === "overall" && <ThumbnailYoutubeVideoOrganic videoIdentity={videoIdentity} videoDurationType={videoDurationType} topCardData={topCardData} />}
      {selectedTab.toLowerCase() === "paid" && <ThumbnailYoutubeVideoPaid videoIdentity={videoIdentity} videoDurationType={videoDurationType} topCardData={topCardData} />}
    </Wrapper>
  );
}
