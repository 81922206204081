import React, { useMemo, useState, useEffect, useContext } from "react";
import { SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import axios from "../../../../components/helpers/axios";
import TopProductCompare from "../../../../components/ui/TopProductCompare";
import Datatable from "../../../../components/ui/tables/Datatable";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import Slider from "../../../../components/ui/Slider";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";

// LOADERS
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";

// CHARTS
import PieofaPieChart from "../../../../components/ui/charts/PieofaPieChart";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import FunnelChart from "../../../../components/ui/charts/FunnelChart";
import HeatMap from "../../../../components/ui/charts/HeatMap";
import LineChart from "../../../../components/ui/charts/LineChart";

// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { IAEvent_Hashtag_Visit } from "../../../../utils/IAEvents";
import { HALEON_COMPANY_ID } from "../../../../constants/constants";

// ASSETS



export default function YoutubeAllVideosPaid({
  videoDurationType,
  topCardData,
}) {
  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Video"
  let pagePlatform = "Youtube"
  let pagePrimaryTab = "Paid"

  const authCtx = useContext(AuthContext);

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  let companyID = userData?.company?.id ?? '';

  const { setFullPageExport, exportSummary, setIsExportDisabled } = useContext(FullPageExportContext);

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const selectedDates = useSelector((state) => state.date);
  const [searchTerm, setSeachTerm] = useState("");

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // VIDEO LENGTH ANALYSIS
  const [videoLengthAnalysis, setVideoLengthAnalysis] = useState([]);
  const [videoLengthAnalysisLabel, setVideoLengthAnalysisLabel] = useState([]);
  const [isVideoLengthAnalysisLoading, setVideoLengthAnalysisIsLoading] = useState(false);
  const [isVideoLengthAnalysisLoaded, setVideoLengthAnalysisIsLoaded] = useState(false);
  const [videoLengthAnalysiserror, setVideoLengthAnalysisError] = useState(null);

  // DEVICE OS TRAFFIC
  const [deviceTraffic, setDeviceTraffic] = useState([]);
  const [isDeviceTrafficLoading, setDeviceTrafficIsLoading] = useState(false);
  const [isDeviceTrafficLoaded, setDeviceTrafficIsLoaded] = useState(false);
  const [deviceTrafficerror, setDeviceTrafficError] = useState(null);

  // AUDIENCE ACTIVITY TREND
  const [audienceActivityTrend, setAudienceActivityTrend] = useState([]);
  const [audienceActivityTrendXaxisLabel, setAudienceActivityTrendXaxisLabel] = useState([]);
  const [audienceActivityTrendYaxisLabel, setAudienceActivityTrendYaxisLabel] = useState([]);
  const [isAudienceActivityTrendLoading, setAudienceActivityTrendIsLoading] = useState(false);
  const [isAudienceActivityTrendLoaded, setAudienceActivityTrendIsLoaded] = useState(false);
  const [audienceActivityTrendError, setAudienceActivityTrendError] = useState(null);

  // COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: "No of Views", field: "no_of_views" },
    { name: "Change", field: "percentage_growth" }
  ]

  // VIEW TREND ANALYSIS
  const [viewTrendAnalysis, setViewTrendAnalysis] = useState([]);
  const [viewTrendAnalysisLabel, setViewTrendAnalysisLabel] = useState([]);
  const [isViewTrendAnalysisLoading, setViewTrendAnalysisIsLoading] = useState(false);
  const [isViewTrendAnalysisLoaded, setViewTrendAnalysisIsLoaded] = useState(false);
  const [viewTrendAnalysisError, setViewTrendAnalysisError] = useState(null);

  // VIEW ANALYSIS
  const [viewAnalysis, setViewAnalysis] = useState([]);
  const [isViewAnalysisLoading, setViewAnalysisIsLoading] = useState(false);
  const [isViewAnalysisLoaded, setViewAnalysisIsLoaded] = useState(false);
  const [viewAnalysisError, setViewAnalysisError] = useState(null);

  // USER TOP POST
  const [topPosts, setTopPosts] = useState([]);
  const [topPostsLoading, settopPostsLoading] = useState(false);
  const [topPostsLoaded, setTopPostsloaded] = useState(false);
  const [topPostserror, setTopPostsError] = useState(null);

  // CAMPAIGN SUMMARY
  const [channelSummary, setChannelSummary] = useState([]);
  const [isChannelSummaryLoading, setChannelSummaryIsLoading] = useState(false);
  const [isChannelSummaryLoaded, setChannelSummaryIsLoaded] = useState(false);
  const [channelSummaryerror, setChannelSummaryError] = useState(null);

  const channelSummaryTableHeaders = [
    { name: "Channel Logo", field: "channel_logo" },
    { name: "Channel Name", field: "channel_name" },
    { name: "Link", field: "link" },
    { name: "Subscriber", field: "subscriber" },
    { name: "SAC", field: "sac" },
    { name: "RS", field: "rs" },
    { name: "Videos", field: "videos" },
    { name: "Push Score", field: "push_score" },
    { name: "Views", field: "views" },
    { name: "Net Audience Views", field: "net_audience_views" },
    { name: "TOFE", field: "tofe" },
    { name: "PES", field: "pes" },
  ];


  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState('');

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag === "") {
      setSelectedHashtag(badge.hashtag);
      CallAPIs(badge.hashtag)
    } else if (selectedHashtag === badge.hashtag) {
      setSelectedHashtag("");
      CallAPIs()
    } else {
      setSelectedHashtag(badge.hashtag);
      CallAPIs(badge.hashtag)
    }
    IAEvent_Hashtag_Visit("Marketing Intelligence", "Video", "Youtube", null, "Paid", badge.hashtag)

  };

  const CallAPIs = (hashtag) => {
    fetchMetricCardsHandler(hashtag);
    fetchVideoLengthAnalysisHandler(hashtag);
    fetchViewTrendAnalysisHandler(hashtag);
    fetchAudienceActivityTrendHandler(hashtag);
    fetchViewAnalysisHandler(hashtag);
    fetchCountryHandler(hashtag);
    fetchDevicetrafficHandler(hashtag);
    companyID !== HALEON_COMPANY_ID && fetchChannelSummaryHandler(hashtag);
    fetchTopPostsHandler(hashtag);
  }

  useEffect(() => {
    setSelectedHashtag('')
    CallAPIs()
    fetchHashtagListHandler()
  }, [
    BRAND_ID,
    selectedDates,
    videoDurationType,
  ]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS

  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/paid/hashtags/", config);

      setHashtagList(res?.data?.data ?? []);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  let topTrendingHashtagsList = <Wrapper><Loader loaderType="hashLoader" /></Wrapper>;

  if (isHashtagListLoaded && !isHashtagListLoading) {
    if (NoDataAvailableChecker(filteredHashtagList)) {
      topTrendingHashtagsList = (
        <Wrapper>
          <NoDataAvailableLoader chartType="hashChartType" />
        </Wrapper>
      );
    } else {
      topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
        <Wrapper key={index}>
          <ErrorBoundary chartType="hashChartType">
            <div className="badge_wrapper">
              <button
                type="button"
                className={
                  selectedHashtag === badge.hashtag
                    ? "button_badge active"
                    : "button_badge"
                }
                onClick={() => handleEdit(badge)}
              >
                {badge.hashtag}
              </button>
            </div>
          </ErrorBoundary>
        </Wrapper>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <Wrapper>
        <ServerErrorsLoader chartType="hashChartType" error={hashtagListerror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList =
      <Wrapper>
        <Loader loaderType="hashLoader" />
      </Wrapper>
  }

  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader = ` : ` + (selectedHashtag !== undefined ? selectedHashtag : "");
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARD
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/paid/metric-card/", config);

      setMetricCards(res?.data?.data ?? []);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = <Wrapper><NoDataAvailableLoader chartType="cardChartType" /></Wrapper>
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary" + selectedHashtagSectionHeader}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="video"
                    platform="youtube"
                    section="paid"
                    key={index}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cradChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection =
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIDEO LENGTH ANALYSIS
  const fetchVideoLengthAnalysisHandler = async () => {
    setVideoLengthAnalysisIsLoading(true);
    setVideoLengthAnalysisIsLoaded(false);
    setVideoLengthAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/youtube/paid/engagement-chart/",
        config
      );

      setVideoLengthAnalysis(res?.data?.data ?? []);
      setVideoLengthAnalysisLabel(res?.data?.label ?? []);
      setVideoLengthAnalysisIsLoading(false);
      setVideoLengthAnalysisIsLoaded(true);
      setVideoLengthAnalysisError(null);
    } catch (error) {
      setVideoLengthAnalysis([]);
      setVideoLengthAnalysisLabel([]);
      setVideoLengthAnalysisIsLoading(false);
      setVideoLengthAnalysisIsLoaded(false);
      setVideoLengthAnalysisError(error.code);
    }
  };

  let videoLengthAnalysisChart = <Wrapper><Loader loaderType="pieChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isVideoLengthAnalysisLoaded && !isVideoLengthAnalysisLoading) {
    if (NoDataAvailableChecker(videoLengthAnalysis) || NoDataAvailableChecker(videoLengthAnalysisLabel)) {
      videoLengthAnalysisChart = <Wrapper><NoDataAvailableLoader chartType="pieChartType" /></Wrapper>;
    } else {
      videoLengthAnalysisChart = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <PieofaPieChart
              chartClass="section_card_chart"
              chartData={videoLengthAnalysis}
              chartId="videoLengthAnalysisChart"
              chartLabels={videoLengthAnalysisLabel}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoLengthAnalysiserror) {
    videoLengthAnalysisChart = <Wrapper><ServerErrorsLoader chartType="pieChartType" error={videoLengthAnalysiserror} /></Wrapper>;
  }

  // IF DATA LOADING
  if (isVideoLengthAnalysisLoading) {
    videoLengthAnalysisChart = <Wrapper><Loader loaderType="pieChartLoader" /></Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE ACTIVITY TREND

  const fetchAudienceActivityTrendHandler = async () => {
    setAudienceActivityTrendIsLoading(true);
    setAudienceActivityTrendIsLoaded(false);
    setAudienceActivityTrendError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/paid/activity-trend/", config);

      setAudienceActivityTrend(res?.data?.data ?? []);
      setAudienceActivityTrendXaxisLabel(res?.data?.xaxis_labels ?? []);
      setAudienceActivityTrendYaxisLabel(res?.data?.yaxis_labels ?? []);
      setAudienceActivityTrendIsLoading(false);
      setAudienceActivityTrendIsLoaded(true);
      setAudienceActivityTrendError(null);
    } catch (error) {
      setAudienceActivityTrend([]);
      setAudienceActivityTrendXaxisLabel([]);
      setAudienceActivityTrendYaxisLabel([]);
      setAudienceActivityTrendIsLoading(false);
      setAudienceActivityTrendIsLoaded(false);
      setAudienceActivityTrendError(error.code);
    }
  };

  let audienceActivityTrendSection = <Wrapper><Loader loaderType="heatChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isAudienceActivityTrendLoaded && !isAudienceActivityTrendLoading) {
    if (
      NoDataAvailableChecker(audienceActivityTrend) ||
      NoDataAvailableChecker(audienceActivityTrendXaxisLabel) ||
      NoDataAvailableChecker(audienceActivityTrendYaxisLabel)
    ) {
      audienceActivityTrendSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="heatChartType" />
        </Wrapper>
      );
    } else {
      audienceActivityTrendSection = (
        <Wrapper>
          <ErrorBoundary chartType="heatChartType">
            <HeatMap
              chartClass="section_card_chart"
              chartId="heatmapChart"
              chartXaxisLabels={audienceActivityTrendXaxisLabel}
              chartYaxisLabels={audienceActivityTrendYaxisLabel}
              chartData={audienceActivityTrend}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (audienceActivityTrendError) {
    audienceActivityTrendSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="heatChartType"
          error={audienceActivityTrendError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAudienceActivityTrendLoading) {
    audienceActivityTrendSection =
      <Wrapper>
        <Loader loaderType="heatChartLoader" />
      </Wrapper>
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TRAFFIC OS HANDLER
  const fetchDevicetrafficHandler = async () => {
    setDeviceTrafficIsLoading(true);
    setDeviceTrafficIsLoaded(false);
    setDeviceTrafficError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/paid/device/", config);

      setDeviceTraffic(res?.data?.data ?? []);
      setDeviceTrafficIsLoading(false);
      setDeviceTrafficIsLoaded(true);
      setDeviceTrafficError(null);
    } catch (error) {
      setDeviceTraffic([]);
      setDeviceTrafficIsLoading(false);
      setDeviceTrafficIsLoaded(false);
      setDeviceTrafficError(error.code);
    }
  };

  let deviceTrafficSection = (
    <Wrapper>
      <Loader loaderType="donutChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isDeviceTrafficLoaded && !isDeviceTrafficLoading) {
    if (NoDataAvailableChecker(deviceTraffic)) {
      deviceTrafficSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="donutChartType" />
        </Wrapper>
      );
    } else {
      deviceTrafficSection = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={deviceTraffic}
              chartId="deviceTrafficSection2"
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (deviceTrafficerror) {
    deviceTrafficSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={deviceTrafficerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isDeviceTrafficLoading) {
    deviceTrafficSection =
      <Wrapper>
        <Loader loaderType="donutChartLoader" />
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/paid/country-chart/", config);

      setCountryData(res?.data ?? []);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryData([]);

      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let countryMap = <Wrapper>
    <div className="grid grid_cols_4 fullpage_section_card section_card">
      <div className="grid col_span_4">
        <div className="section_card_header">
          <h2 className="section_card_title">
            Audience Distribution By Country
            {selectedHashtag ? " : " + selectedHashtag : ""}
            <SectionDefinationsBadge
              title={"Audience Distribution By Country"}
              module="video"
              platform="youtube"
              section="paid"
            />
          </h2>
        </div>
      </div>
      <div className="grid col_span_4">
        <div className="section_card_body">
          <Loader loaderType="smallTableLoader" />
        </div>
      </div>
    </div>
  </Wrapper>

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (NoDataAvailableChecker(countryData) || NoDataAvailableChecker(CountryTableDataHeaders)) {
      countryMap = <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Audience Distribution By Country
                {selectedHashtag ? " : " + selectedHashtag : ""}
                <SectionDefinationsBadge
                  title={"Audience Distribution By Country"}
                  module="video"
                  platform="youtube"
                  section="paid"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <NoDataAvailableLoader chartType="tableChartType" />
            </div>
          </div>
        </div>
      </Wrapper>
    } else {
      countryMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"Audience Distribution By Country"}
              tablePageSubModule="video"
              tablePagePlatform="youtube"
              tablePagePrimaryTab="paid"
              isCountryFlagVisible={true}
              tableHeader={CountryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
              tableID="aud_distri"
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"aud_distri"}
                  sectionName={"VideoYTCountry_Audience Distribution - Country"}
                  sectionData={NoDataAvailableChecker(countryData) ? [] : countryData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Audience Distribution By Country
                {selectedHashtag ? " : " + selectedHashtag : ""}
                <SectionDefinationsBadge
                  title={"Audience Distribution By Country"}
                  module="video"
                  platform="youtube"
                  section="paid"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Audience Distribution By Country
              {selectedHashtag ? " : " + selectedHashtag : ""}
              <SectionDefinationsBadge
                title={"Audience Distribution By Country"}
                module="video"
                platform="youtube"
                section="paid"
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIEW TREND ANALYSIS
  const fetchViewTrendAnalysisHandler = async () => {
    setViewTrendAnalysisIsLoading(true);
    setViewTrendAnalysisIsLoaded(false);
    setViewTrendAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/paid/rate-analysis/", config);

      setViewTrendAnalysis(res?.data?.data ?? []);
      setViewTrendAnalysisLabel(res?.data?.label ?? []);
      setViewTrendAnalysisIsLoading(false);
      setViewTrendAnalysisIsLoaded(true);
      setViewTrendAnalysisError(null);
    } catch (error) {
      setViewTrendAnalysis([]);
      setViewTrendAnalysisLabel([]);
      setViewTrendAnalysisIsLoading(false);
      setViewTrendAnalysisIsLoaded(false);
      setViewTrendAnalysisError(error.code);
    }
  };

  let viewTrendAnalysisSection = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isViewTrendAnalysisLoaded && !isViewTrendAnalysisLoading) {
    if (
      NoDataAvailableChecker(viewTrendAnalysis) ||
      NoDataAvailableChecker(viewTrendAnalysisLabel)
    ) {
      viewTrendAnalysisSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      viewTrendAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartClass="section_card_chart"
              chartData={viewTrendAnalysis}
              chartId="viewTrendAnalysis"
              chartLabels={viewTrendAnalysisLabel}
              onBulletsClick={() => { }}
              showLabels
              showLegend
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (viewTrendAnalysisError) {
    viewTrendAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={viewTrendAnalysisError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isViewTrendAnalysisLoading) {
    viewTrendAnalysisSection =
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIEW  ANALYSIS
  const fetchViewAnalysisHandler = async () => {
    setViewAnalysisIsLoading(true);
    setViewAnalysisIsLoaded(false);
    setViewAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/paid/funnel-chart/", config);

      setViewAnalysis(res?.data ?? []);
      setViewAnalysisIsLoading(false);
      setViewAnalysisIsLoaded(true);
      setViewAnalysisError(null);
    } catch (error) {
      setViewAnalysis([]);
      setViewAnalysisIsLoading(false);
      setViewAnalysisIsLoaded(false);
      setViewAnalysisError(error.code);
    }
  };

  let viewAnalysisSection = (
    <Wrapper>
      <Loader loaderType="funnelChartLoader" />
    </Wrapper>
  );
  // IF DATA IS LOADED
  if (isViewAnalysisLoaded && !isViewAnalysisLoading) {
    if (NoDataAvailableChecker(viewAnalysis) || viewAnalysis.every((val, i, arr) => val.value === arr[0].value) === true) {
      viewAnalysisSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="funnelChartType" />
        </Wrapper>
      );
    } else {
      viewAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="funnelChartType">
            <FunnelChart
              chartClass="section_card_chart"
              chartData={viewAnalysis}
              chartId="viewAnalysis"
              onBulletsClick={() => { }}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (viewAnalysisError) {
    viewAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="funnelChartType"
          error={viewAnalysisError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isViewAnalysisLoading) {
    viewAnalysisSection = <Wrapper><Loader loaderType="funnelChartLoader" /></Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY

  const fetchChannelSummaryHandler = async () => {
    setChannelSummaryIsLoading(true);
    setChannelSummaryIsLoaded(false);
    setChannelSummaryError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: selectedHashtag,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/paid/channel-table/", config);
      setChannelSummary(res?.data ?? []);
      setChannelSummaryIsLoading(false);
      setChannelSummaryIsLoaded(true);
      setChannelSummaryError(null);
    } catch (error) {
      setChannelSummary([])
      setChannelSummaryIsLoading(false);
      setChannelSummaryIsLoaded(false);
      setChannelSummaryError(error.code);
    }
  };

  let channelSummaryTable = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Channel Summary {selectedHashtagSectionHeader}
              <SectionDefinationsBadge
                title={"Channel Summary"}
                module="video"
                platform="youtube"
                section="paid"
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isChannelSummaryLoaded && !isChannelSummaryLoading) {
    if (NoDataAvailableChecker(channelSummary)) {
      channelSummaryTable = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">
                  Channel Summary {selectedHashtagSectionHeader}
                  <SectionDefinationsBadge
                    title={"Channel Summary"}
                    module="video"
                    platform="youtube"
                    section="paid"
                  />
                </h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      channelSummaryTable = (
        <ErrorBoundary chartType="tableChartType">
          <Datatable
            tableTitle={"Channel Summary"}
            tablePagePlatform={"youtube"}
            tablePageSubModule={"video"}
            tablePagePrimaryTab={"paid"}
            tableHeader={channelSummaryTableHeaders}
            tableData={channelSummary}
            tableLength={10}
            isFooterShow={true}
            searchPlaceHolder={"Search Channel"}
            tableID="channel_summary_table_section"
            tableExportDropdown={
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"channel_summary_table_section"}
                sectionName={"Channel Summary Table"}
                sectionData={channelSummary}
              />
            }
          />
        </ErrorBoundary>
      );
    }
  }


  // IF THERE IS SOME ERROR FROM SERVER
  if (channelSummaryerror) {
    channelSummaryTable = <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Channel Summary {selectedHashtagSectionHeader}
              <SectionDefinationsBadge
                title={"Channel Summary"}
                module="video"
                platform="youtube"
                section="paid"
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <ServerErrorsLoader chartType="tableChartType" error={channelSummaryerror} />
          </div>
        </div>
      </div>
    </Wrapper>;
  }

  // IF DATA LOADING
  if (isChannelSummaryLoading) {
    channelSummaryTable = <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Channel Summary {selectedHashtagSectionHeader}
              <SectionDefinationsBadge
                title={"Channel Summary"}
                module="video"
                platform="youtube"
                section="paid"
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  }

  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  // TOP POST
  const fetchTopPostsHandler = async () => {
    settopPostsLoading(true);
    setTopPostsloaded(false);
    setTopPostsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };
    try {
      const res = await axios.get("video/youtube/paid/top-post/", config);

      setTopPosts(res?.data?.data ?? []);
      settopPostsLoading(false);
      setTopPostsloaded(true);
      setTopPostsError(null);
    } catch (error) {
      setTopPosts([]);
      settopPostsLoading(false);
      setTopPostsloaded(false);
      setTopPostsError(error.code);
    }
  };

  // Top Tweets Chart
  let topPostsChart = (
    <Wrapper>
      <div className="section_card fullpage_section_card grid">
        <div className="grid col_span_4 section_card_header">

          <div className="section_info">
            <h2 className="section_card_title">Top 5 Videos</h2>
            <SectionDefinationsBadge
              title={"Top 5 Videos"}
              module="video"
              platform="youtube"
              section="paid"
            /></div>

        </div>
        <div className="grid col_span_4">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (topPostsLoaded && !topPostsLoading) {
    if (NoDataAvailableChecker(topPosts)) {
      topPostsChart = (
        <Wrapper>
          <div className="section_card fullpage_section_card grid">
            <div className="grid col_span_4 section_card_header">

              <div className="section_info">
                <h2 className="section_card_title">Top 5 Videos</h2>
                <SectionDefinationsBadge
                  title={"Top 5 Videos"}
                  module="video"
                  platform="youtube"
                  section="paid"
                /></div>
            </div>

            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      topPostsChart = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <TopProductCompare
              module={"video"}
              Title={"Videos"}
              products={topPosts}
              social_platform="Youtube"
              module_section="paid"
              hasExportDropdown={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topPostserror) {
    topPostsChart = (
      <Wrapper><div className="section_card fullpage_section_card grid">
        <div className="grid col_span_4 section_card_header">

          <div className="section_info">
            <h2 className="section_card_title">Top 5 Videos</h2>
            <SectionDefinationsBadge
              title={"Top 5 Videos"}
              module="video"
              platform="youtube"
              section="paid"
            /></div>

        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader chartType="cardChartType" error={topPostserror} />
        </div>
      </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (topPostsLoading) {
    topPostsChart = (
      <Wrapper><div className="section_card fullpage_section_card grid">
        <div className="grid col_span_4 section_card_header">

          <div className="section_info">
            <h2 className="section_card_title">Top 5 Videos</h2>
            <SectionDefinationsBadge
              title={"Top 5 Videos"}
              module="video"
              platform="youtube"
              section="paid"
            /></div>
        </div>

        <div className="grid col_span_4">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </div>
      </Wrapper>
    );
  }

  var allData = companyID !== HALEON_COMPANY_ID ? {
    ...topCardData,
    "VideoSummaryCard_Summary Cards": metricCards,
    "PieofPie_Video Length Analysis": videoLengthAnalysis,
    "View Trend Analysis": viewTrendAnalysis,
    "Heatmap_Published Time View Analysis": audienceActivityTrend,
    "Views Bucket wise Funel": viewAnalysis,
    "VideoYTCountry_Audience Distribution - Country": countryData,
    "View Of Device": deviceTraffic,
    "Channel Summary": channelSummary,
    "Compare_Top 5 Videos": topPosts,
  } : {
    ...topCardData,
    "VideoSummaryCard_Summary Cards": metricCards,
    "PieofPie_Video Length Analysis": videoLengthAnalysis,
    "View Trend Analysis": viewTrendAnalysis,
    "Heatmap_Published Time View Analysis": audienceActivityTrend,
    "Views Bucket wise Funel": viewAnalysis,
    "VideoYTCountry_Audience Distribution - Country": countryData,
    "View Of Device": deviceTraffic,
    "Compare_Top 5 Videos": topPosts,
  }

  useEffect(() => {
    setFullPageExport(allData);
  }, [
    exportSummary,
    metricCards,
    videoLengthAnalysis,
    viewTrendAnalysis,
    audienceActivityTrend,
    viewAnalysis,
    countryData,
    deviceTraffic,
    channelSummary,
    topPosts,
  ]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (videoLengthAnalysiserror !== null ? true : isVideoLengthAnalysisLoaded && (isVideoLengthAnalysisLoading === false)) &&
      (viewTrendAnalysisError !== null ? true : isViewTrendAnalysisLoaded && (isViewTrendAnalysisLoading === false)) &&
      (audienceActivityTrendError !== null ? true : isAudienceActivityTrendLoaded && (isAudienceActivityTrendLoading === false)) &&
      (viewAnalysisError !== null ? true : isViewAnalysisLoaded && (isViewAnalysisLoading === false)) &&
      (countryDataerror !== null ? true : isCountryDataLoaded && (isCountryDataLoading === false)) &&
      (deviceTrafficerror !== null ? true : isDeviceTrafficLoaded && (isDeviceTrafficLoading === false)) &&
      (channelSummaryerror !== null ? true : isChannelSummaryLoaded && (isChannelSummaryLoading === false)) &&
      (topPostserror !== null ? true : topPostsLoaded && (topPostsLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    videoLengthAnalysiserror, isVideoLengthAnalysisLoaded, isVideoLengthAnalysisLoading,
    viewTrendAnalysisError, isViewTrendAnalysisLoaded, isViewTrendAnalysisLoading,
    audienceActivityTrendError, isAudienceActivityTrendLoaded, isAudienceActivityTrendLoading,
    viewAnalysisError, isViewAnalysisLoaded, isViewAnalysisLoading,
    countryDataerror, isCountryDataLoaded, isCountryDataLoading,
    deviceTrafficerror, isDeviceTrafficLoaded, isDeviceTrafficLoading,
    channelSummaryerror, isChannelSummaryLoaded, isChannelSummaryLoading,
    topPostserror, topPostsLoaded, topPostsLoading,
  ])

  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <div className="section_info">    <h2 className="section_card_title">Trending Hashtags
          </h2> <SectionDefinationsBadge
              title={"Trending Hashtags"}
              module="video"
              platform="youtube"
              section="paid"
            />
          </div>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topTrendingHashtagsList}</div>
        </div>
      </div>
      <Wrapper>
        <div className="grid grid_margin_bottom">
          <div className="card_wrapper">{metricCardsSection}</div>
        </div>
      </Wrapper>

      <div
        id="video_l"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Video Length Analysis
              {selectedHashtag && " : " + selectedHashtag}
            </h2> <SectionDefinationsBadge
                title={"Video Length Analysis"}
                module="video"
                platform="youtube"
                section="paid"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"video_l"}
                sectionName={"PieofPie_Video Length Analysis"}
                sectionData={videoLengthAnalysis}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper  p_t_0 p_b_0">
              {videoLengthAnalysisChart}
            </div>
          </div>
        </div>
      </div>

      <div
        id="view_trend_analysis"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">
              View Trend Analysis {selectedHashtag && " : " + selectedHashtag}
            </h2>  <SectionDefinationsBadge
                title={"View Trend Analysis"}
                module="video"
                platform="youtube"
                section="paid"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"view_trend_analysis"}
                sectionName={"View Trend Analysis"}
                sectionData={typeof viewTrendAnalysis === "object" ? [] : viewTrendAnalysis}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {viewTrendAnalysisSection}
            </div>
          </div>
        </div>
      </div>

      <div
        id="view_bucket"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              View Bucket wise Funnel
              {selectedHashtag && " : " + selectedHashtag}
            </h2> <SectionDefinationsBadge
                title={"View Bucket wise Funnel"}
                module="video"
                platform="youtube"
                section="paid"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"view_bucket"}
                sectionName={"View Bucket wise Funnel"}
                sectionData={viewAnalysis}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {viewAnalysisSection}
            </div>
          </div>
        </div>
      </div>
      {/* 
      <div id="aud_distri" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
          <div className="section_info">    <h2 className="section_card_title">
              Audience Distribution By Country
              {selectedHashtag && " : " + selectedHashtag}
              </h2> <SectionDefinationsBadge
                title={"Audience Distribution By Country"}
                module="video"
                platform="youtube"
                section="paid"
              />
            </h2> */}
      {/* <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"aud_distri"}
                sectionName={"Audience Distribution"}
                sectionData={NoDataAvailableChecker(countryData) ? [] : countryData}
              />
            </div> */}
      {/* </div>
        </div> */}
      {/* <div className="grid col_span_1">
          <div className="section_card_body"> */}
      <div className="grid_margin_bottom">
        {countryMap}
      </div>
      {/* </div>
        </div>
      </div> */}

      <div id="view_device" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card ">
        <div className="grid col_span_4 section_card">
          <div className="grid col_span_4 ">
            <div className="section_card_header">
              <div className="section_info">    <h2 className="section_card_title">
                View of Device {selectedHashtag && " : " + selectedHashtag}
              </h2> <SectionDefinationsBadge
                  title={"View of Device"}
                  module="video"
                  platform="youtube"
                  section="paid"
                />
              </div>
              <div data-html2canvas-ignore={true}>
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"view_device"}
                  sectionName={"View of Device"}
                  sectionData={deviceTraffic}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {deviceTrafficSection}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="aud_activity" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">
              Published Time Views Analysis
              {selectedHashtag && " : " + selectedHashtag}
            </h2><SectionDefinationsBadge
                title={"Published Time Views Analysis"}
                module="video"
                platform="youtube"
                section="paid"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"aud_activity"}
                sectionName={"Heatmap_Published Time Views Analysis"}
                sectionData={audienceActivityTrend}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {audienceActivityTrendSection}
            </div>
          </div>
        </div>
      </div>

      {companyID !== HALEON_COMPANY_ID &&
        // <div className="grid grid_cols_4  fullpage_section_card section_card">
        //   <div className="grid col_span_4">
        //     <div className="section_card_header">
        //       <h2 className="section_card_title">
        //         Channel Summary {selectedHashtagSectionHeader}
        //         <SectionDefinationsBadge
        //           title={"Channel Summary"}
        //           module="video"
        //           platform="youtube"
        //           section="paid"
        //         />
        //       </h2>
        //     </div>
        //   </div>
        //   <div className="grid col_span_4">
        //     <div className="section_card_body">
        <div className="grid_margin_bottom">
          {channelSummaryTable}
        </div>
        //</div>
        //</div>
        //</div> 
      }

      {topPostsChart}
    </Wrapper>
  );
}
