import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import axios from "../../../../components/helpers/axios";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";

// CHARTS
import LineChart from "../../../../components/ui/charts/LineChart";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";

// UTILS
import NumberFormatter from "../../../../utils/NumberFormatter";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";

const SocialLinkedinPage = ({ topCardData }) => {
  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Social"
  let pagePlatform = "Linkedin"
  let pagePrimaryTab = "Page"


  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // PAGE VIEWS
  const [pageViews, setPageViews] = useState([]);
  const [pageViewsLabels, setPageViewsLabels] = useState([]);
  const [isPageViewsLoading, setPageViewsIsLoading] = useState(false);
  const [isPageViewsLoaded, setPageViewsIsLoaded] = useState(false);
  const [pageViewserror, setPageViewsError] = useState(null);

  // PAGE REACH
  const [pageReach, setPageReach] = useState([]);
  const [pageReachLabels, setPageReachLabels] = useState([]);
  const [isPageReachLoading, setPageReachIsLoading] = useState(false);
  const [isPageReachLoaded, setPageReachIsLoaded] = useState(false);
  const [pageReacherror, setPageReachError] = useState(null);

  // CAREER PAGE VIEW
  const [careerPageView, setCareerPageView] = useState([]);
  const [isCareerPageViewLoading, setCareerPageViewIsLoading] = useState(false);
  const [isCareerPageViewLoaded, setCareerPageViewIsLoaded] = useState(false);
  const [careerPageViewerror, setCareerPageViewError] = useState(null);

  // JOB PAGE VIEW
  const [jobPageView, setJobPageView] = useState([]);
  const [isJobPageViewLoading, setJobPageViewIsLoading] = useState(false);
  const [isJobPageViewLoaded, setJobPageViewIsLoaded] = useState(false);
  const [jobPageViewerror, setJobPageViewError] = useState(null);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchPageViewsHandler();
    fetchPageReachHandler();
    fetchCareerPageViewHandler();
    fetchJobPageViewHandler();
  }, [BRAND_ID, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/linkedin/page/metric-card/", config);
      setMetricCards(res?.data?.data ?? []);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Summary
              </h2></div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider
              sliderTitle={"Summary"}
              platform={"linkedin"}
              tab={"page"}
            >
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="social"
                    platform="linkedin"
                    section="page"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PAGE VIEWS

  // TO FETCH PAGE VIEWS DATA FROM API
  const fetchPageViewsHandler = async () => {
    setPageViewsIsLoading(true);
    setPageViewsIsLoaded(false);
    setPageViewsError(null);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/page/page-view-trend/",
        config
      );

      setPageViews(res?.data?.metric_data ?? []);
      setPageViewsLabels(res?.data?.page_view_label ?? []);
      setPageViewsIsLoading(false);
      setPageViewsIsLoaded(true);
      setPageViewsError(null);
    } catch (error) {
      setPageViewsIsLoading(false);
      setPageViewsIsLoaded(false);
      setPageViewsError(error.code);
      setPageViews([]);
      setPageViewsLabels([]);
    }
  };

  let pageViewsLineChart = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;

  // IF DATA IS AVAILABLE
  if (isPageViewsLoaded && !isPageViewsLoading) {
    if (
      NoDataAvailableChecker(pageViews) ||
      NoDataAvailableChecker(pageViewsLabels)
    ) {
      pageViewsLineChart = <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>;
    } else {
      pageViewsLineChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="page_views"
              chartClass="section_card_chart"
              chartData={pageViews}
              chartLabels={pageViewsLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (pageViewserror) {
    pageViewsLineChart = (
      <Wrapper>
        <ServerErrorsLoader chartType="lineChartType" error={pageViewserror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPageViewsLoading) {
    pageViewsLineChart = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PAGE REACH

  // TO FETCH PAGE GROWTH DATA FROM API
  const fetchPageReachHandler = async () => {
    setPageReachIsLoading(true);
    setPageReachIsLoaded(false);
    setPageReachError(null);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/linkedin/page/page-reach/", config);

      setPageReach(res?.data?.metric_data ?? []);
      setPageReachLabels(res?.data?.page_view_label ?? []);

      setPageReachIsLoading(false);
      setPageReachIsLoaded(true);
      setPageReachError(null);
    } catch (error) {
      setPageReach([]);
      setPageReachLabels([]);
      setPageReachIsLoading(false);
      setPageReachIsLoaded(false);
      setPageReachError(error.code);
    }
  };

  let reachLineChart = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;

  // IF DATA IS AVAILABLE
  if (isPageReachLoaded && !isPageReachLoading) {
    if (
      NoDataAvailableChecker(pageReach) ||
      NoDataAvailableChecker(pageReachLabels)
    ) {
      reachLineChart = <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>;
    } else {
      reachLineChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="page_reach"
              chartClass="section_card_chart"
              chartData={pageReach}
              chartLabels={pageReachLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (pageReacherror) {
    reachLineChart = (
      <Wrapper>
        <ServerErrorsLoader chartType="lineChartType" error={pageReacherror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPageReachLoading) {
    reachLineChart = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // REACTIONS SENTIMENT

  // TO FETCH DATA FROM API
  const fetchCareerPageViewHandler = async () => {
    setCareerPageViewIsLoading(true);
    setCareerPageViewIsLoaded(false);
    setCareerPageViewError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/page/carrier-page-views/",
        config
      );
      setCareerPageView(res?.data?.metric ?? []);

      setCareerPageViewIsLoading(false);
      setCareerPageViewIsLoaded(true);
      setCareerPageViewError(null);
    } catch (error) {
      setCareerPageView([]);
      setCareerPageViewIsLoading(false);
      setCareerPageViewIsLoaded(false);
      setCareerPageViewError(error.code);
    }
  };

  // POSTIVE ACTIONS CHART
  let careerPageViewLineChart = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
  let careerPageViewDetails = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isCareerPageViewLoaded && !isCareerPageViewLoading) {
    if (NoDataAvailableChecker(careerPageView)) {
      careerPageViewLineChart = (
        <Wrapper> <NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
      );
      careerPageViewDetails = (
        <Wrapper> <NoDataAvailableLoader chartType="tableChartType" /></Wrapper>
      );
    } else {
      careerPageViewLineChart = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartId={"career_page_view"}
              chartClass={"section_card_chart"}
              chartData={careerPageView}
              showLegend={true}
              showLabels={true}
              showVerticalLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
      careerPageViewDetails = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <div className="chart_details_wrapper">
              <div className="main_wrapper">
                <div className="body">
                  {careerPageView && careerPageView.map(
                    (details, postImpressionIndex) => (
                      <Wrapper key={postImpressionIndex}>
                        <div className="row">
                          <div className="title_wrapper">
                            {details.label}
                          </div>
                          <div className="value_wrapper">
                            <ErrorBoundary>
                              <NumberFormatter number={details.value} />
                            </ErrorBoundary>
                          </div>
                        </div>
                      </Wrapper>
                    ))}
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (careerPageViewerror) {
    careerPageViewLineChart = (
      <Wrapper> <ServerErrorsLoader
        chartType="donutChartType"
        error={careerPageViewerror}
      />
      </Wrapper>
    );
    careerPageViewDetails = (
      <Wrapper> <ServerErrorsLoader
        chartType="tableChartType"
        error={careerPageViewerror}
      /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCareerPageViewLoading) {
    careerPageViewLineChart = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
    careerPageViewDetails = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // REACTIONS SENTIMENT

  // TO FETCH DATA FROM API
  const fetchJobPageViewHandler = async () => {
    setJobPageViewIsLoading(true);
    setJobPageViewIsLoaded(false);
    setJobPageViewError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/page/jobs-page-views/",
        config
      );
      setJobPageView(res?.data?.metric_data ?? []);

      setJobPageViewIsLoading(false);
      setJobPageViewIsLoaded(true);
      setJobPageViewError(null);
    } catch (error) {
      setJobPageView([]);
      setJobPageViewIsLoading(false);
      setJobPageViewIsLoaded(false);
      setJobPageViewError(error.code);
    }
  };

  // POSTIVE ACTIONS CHART
  let jobPageViewLineChart = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
  let jobPageViewDetails = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isJobPageViewLoaded && !isJobPageViewLoading) {
    if (NoDataAvailableChecker(jobPageView)) {
      jobPageViewLineChart = (
        <Wrapper>  <NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
      );
      jobPageViewDetails = <Wrapper> <NoDataAvailableLoader chartType="tableChartType" /></Wrapper>;
    } else {
      jobPageViewLineChart = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartId={"job_page_view"}
              chartClass={"section_card_chart"}
              chartData={jobPageView}
              showLegend={true}
              showLabels={true}
              showVerticalLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
      jobPageViewDetails = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <div className="chart_details_wrapper">
              <div className="main_wrapper">
                <div className="body">
                  {jobPageView && jobPageView.map(
                    (details, postImpressionIndex) => (
                      <Wrapper key={postImpressionIndex}>
                        <div className="row">
                          <div className="title_wrapper">
                            {details.label}
                          </div>
                          <div className="value_wrapper">
                            <ErrorBoundary>
                              <NumberFormatter number={details.value} />
                            </ErrorBoundary>
                          </div>
                        </div>
                      </Wrapper>
                    ))}
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (jobPageViewerror) {
    jobPageViewLineChart = (
      <Wrapper>
        <ServerErrorsLoader chartType="donutChartType" error={jobPageViewerror} />
      </Wrapper>
    );
    jobPageViewDetails = (
      <Wrapper>
        <ServerErrorsLoader chartType="tableChartType" error={jobPageViewerror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isJobPageViewLoading) {
    jobPageViewLineChart = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
    jobPageViewDetails = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>;
  }

  var allData = {
    ...topCardData,
    "SummaryCard_Summary Card": metricCards,
    "Page Views": pageViews,
    "Page Reach": pageReach,
    "SocialLKDPage_Career Page View": careerPageView,
    "SocialLKDPage_Jobs Page Views": jobPageView,
  };

  useEffect(() => {
    setFullPageExport(allData);
  }, [metricCards, pageViews, pageReach, careerPageView, jobPageView]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (pageViewserror !== null ? true : isPageViewsLoaded && (isPageViewsLoading === false)) &&
      (pageReacherror !== null ? true : isPageReachLoaded && (isPageReachLoading === false)) &&
      (careerPageViewerror !== null ? true : isCareerPageViewLoaded && (isCareerPageViewLoading === false)) &&
      (jobPageViewerror !== null ? true : isJobPageViewLoaded && (isJobPageViewLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    pageViewserror, isPageViewsLoaded, isPageViewsLoading,
    pageReacherror, isPageReachLoaded, isPageReachLoading,
    careerPageViewerror, isCareerPageViewLoaded, isCareerPageViewLoading,
    jobPageViewerror, isJobPageViewLoaded, isJobPageViewLoading
  ])


  return (
    <Wrapper>
      {metricCardsSection}
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div id="page_views_section" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">Page views
            </h2> <SectionDefinationsBadge
                title={"Page views"}
                module="social"
                platform="linkedin"
                section="page"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"page_views_section"}
                sectionName={"Page views"}
                sectionData={pageViews}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {pageViewsLineChart}
            </div>
          </div>
        </div>
        <div id="reach_section" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">Reach
            </h2> <SectionDefinationsBadge
                title={"Reach"}
                module="social"
                platform="linkedin"
                section="page"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"reach_section"}
                sectionName={"Reach"}
                sectionData={pageReach}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">{reachLineChart}</div>
          </div>
        </div>
      </div>
      <div className="grid grid_cols_4">
        <div className="grid col_span_2 section_card">
          <div id="career_page_section" className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">Career Page View
            </h2> <SectionDefinationsBadge
                title={"Career Page View"}
                module="social"
                platform="linkedin"
                section="page"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"career_page_section"}
                sectionName={"SocialLKDPage_Career Page View"}
                sectionData={careerPageView}
              />
            </div>
          </div>
          <div className="grid col_span_1 section_card_body">
            <div className="section_card_chart_wrapper grid col_span_2">
              {careerPageViewLineChart}
            </div>
            <div className="section_card_table_wrapper grid col_span_2">
              {careerPageViewDetails}
            </div>
          </div>
        </div>
        <div id="jobs_view_section" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">Jobs Page View
            </h2> <SectionDefinationsBadge
                title={"Jobs Page View"}
                module="social"
                platform="linkedin"
                section="page"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"jobs_view_section"}
                sectionName={"SocialLKDPage_Jobs Page View"}
                sectionData={jobPageView}

              />
            </div>
          </div>
          <div className="grid col_span_1 section_card_body">
            <div className="section_card_chart_wrapper grid col_span_2">
              {jobPageViewLineChart}
            </div>
            <div className="section_card_table_wrapper grid col_span_2">
              {jobPageViewDetails}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SocialLinkedinPage;
