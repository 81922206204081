const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  globalFilterCampaignBucket: false
};

const userPermissionSlice = createSlice({
    name: "globalFilterCampaignBucket",
    initialState,
    reducers: {
        setGlobalFilterCampaignBucket(state, action) {
            state.globalFilterCampaignBucket = action.payload;
        },
        resetGlobalFilterCampaignBucket: () => initialState,
    },
});

export const { setGlobalFilterCampaignBucket, resetGlobalFilterCampaignBucket } = userPermissionSlice.actions;

export default userPermissionSlice.reducer;
