const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    email: null,
    data: [],
    code: null,
    consent: false
};

const ssoLoginSlice = createSlice({
    name: "sso",
    initialState,
    reducers: {
        setSSOEmail(state, action) {
            state.email = action.payload;
        },
        setSSOData(state, action) {
            state.data = action.payload;
        },
        setSSOCode(state, action) {
            state.code = action.payload;
        },
        setSSOConsent(state, action) {
            state.consent = action.payload;
        },
        resetSSOData: () => initialState,
    },
});

export const { setSSOEmail, setSSOData, setSSOCode, setSSOConsent, resetSSOData } = ssoLoginSlice.actions;

export default ssoLoginSlice.reducer;
