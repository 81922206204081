import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { SwiperSlide } from 'swiper/react';

// STORAGE 
import AuthContext from '../../store/authContext';
import FullPageExportContext from '../../store/fullPageExportContext';

// COMPONENT
import axios from "../../components/helpers/axios";
import Wrapper from '../../components/helpers/Wrapper'
import ExportDropdown from '../../components/ui/dropdown/ExportDropdown';
import Slider from '../../components/ui/Slider';
import PageHeader from '../../components/layout/pageHeader';
import Card from '../../components/ui/Card';
import Datatable from '../../components/ui/tables/Datatable';
import Dropdown from '../../components/ui/dropdown/Dropdown';

// CHARTS
import IndiaMap from '../../components/ui/map/IndiaMap';
import BubbleChartSizeShapeDynamic from '../../components/ui/charts/BubbleChartSizeShapeDynamic';
import SimpleTreeMap from '../../components/ui/charts/SimpleTreeMap';
import MultiAxisMultiLine from '../../components/ui/charts/MultiAxisMultiLine';
import StackedClusteredTwoBarChart from '../../components/ui/charts/StackedClusteredTwoBarChart';
import FunnelChart from '../../components/ui/charts/FunnelChart';

// LOADERS
import Loader from '../../components/ui/loaders/Loader';
import ServerErrorsLoader from '../../components/ui/loaders/ServerErrosLoader';
import NoDataAvailableLoader from '../../components/ui/loaders/NoDataAvailable';

// UTILS
import ErrorBoundary from '../../utils/ErrorBoundary';
import NoDataAvailableChecker from '../../utils/NoDataAvailableChecker';
import TextFormatter from '../../utils/TextFormatter';
import NumberFormatter from '../../utils/NumberFormatter';
import ExpandIcon from '../../assets/icons/ExpandIcon';
import LineChart from '../../components/ui/charts/LineChart';
import PartitionedLineChart from '../../components/ui/charts/PartitionedLineChart';
import Modal from '../../components/ui/Modal';
import { COKE_COMPANY_ID } from '../../constants/constants';
import { useParams, useSearchParams } from 'react-router-dom';

export default function MarketIDFC() {
	// PAGE CONSTANTS
	let pageModule = "Retail Intelligence"
	let pageSubModule = "Market"
	let pagePlatform = "Market"

	const authCtx = useContext(AuthContext);
	const { setFullPageExport, setExportPageName, setexportSummary, exportSummary, setIsExportDisabled } = useContext(FullPageExportContext);
	const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
	const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)

	const params = useParams();
	let [searchParams, setSearchParams] = useSearchParams();

	const userData = useSelector((state) => state.userData.user[0]);

	let companyID = userData?.company?.id ?? '';

	// currentDate / endDate / prevCurrentDate / prevEndDate
	const selectedDates = useSelector((state) => state.date);
	const selectedBrands = useSelector((state) => state.globalSelectedBrandIds.selectedBrands);
	const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

	let BRAND_ID = null;
	if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
		BRAND_ID = null;
	} else {
		BRAND_ID = globalSelectedBrandIds.join(",");
	}

	// INDIA MAP
	const [MapData, setMapData] = useState([]);
	const [isMapLoading, setMapIsLoading] = useState(false);
	const [isMapLoaded, setIsMapLoaded] = useState(false);
	const [MapError, setMapError] = useState(null);
	const [centerPoint, setCenterPoint] = useState(["82,22"]);
	const [northPoint, setNorthPoint] = useState(["72.6416015625, 37.71859032558816",]);
	const [southPoint, setSouthPoint] = useState(["93.1640625, 5.7908968128719565",]);
	let zoomLevel = 2.5;
	const [mapLevel, setMapLevel] = useState(1);
	const [mapState, setMapState] = useState(null);
	const [mapStateCode, setMapStateCode] = useState(null);
	const [mapBreadCrumbList, setMapBreadCrumbList] = useState(["India"]);

	var stateCodeLabel = mapState === undefined || mapState === null ? "" : " : " + mapState;


	// PERFORMANCE METRIC CARDS
	const [performanceMetricCards, setPerformanceMetricCards] = useState([]);
	const [isPerformanceMetricCardsLoading, setPerformanceMetricCardsIsLoading] = useState(false);
	const [isPerformanceMetricCardsLoaded, setPerformanceMetricCardsIsLoaded] = useState(false);
	const [performanceMetricCardserror, setPerformanceMetricCardsError] = useState(null);

	// MARKET COMPARE : ALL INDIA
	const [isMarketTableHidden, setIsMarketTableHidden] = useState({ class: "hide_rows", text: "View More" })
	const [marketCompareAllIndia, setMarketCompareAllIndia] = useState([]);
	const [marketCompareAllIndiaLabel, setMarketCompareAllIndiaLabel] = useState([]);
	const [marketCompareAllIndiaTable, setMarketCompareAllIndiaTable] = useState([]);
	const [isMarketCompareAllIndiaLoading, setMarketCompareAllIndiaIsLoading] = useState(false);
	const [isMarketCompareAllIndiaLoaded, setMarketCompareAllIndiaIsLoaded] = useState(false);
	const [marketCompareAllIndiaerror, setMarketCompareAllIndiaError] = useState(null);

	const [marketComparisonDropdownOptions, setMarketComparisonDropdownOptions] = useState([])
	const [marketComparisonDropdownOptionsLoading, setMarketComparisonDropdownOptionsLoading] = useState(false)

	const [marketAllIndiaDropdownValue, setMarketAllIndiaDropdownValue] = useState('');

	const marketAllIndiaDropdownHandler = (e) => {
		if (e === null) {
			setMarketAllIndiaDropdownValue("")
		} else {
			setMarketAllIndiaDropdownValue(e)
		}
	};

	// MARKET COMPARE : STATE
	const [marketCompareState, setMarketCompareState] = useState([]);
	const [marketCompareStateLabel, setMarketCompareStateLabel] = useState([]);
	const [marketCompareStateTable, setMarketCompareStateTable] = useState([]);
	const [isMarketCompareStateLoading, setMarketCompareStateIsLoading] = useState(false);
	const [isMarketCompareStateLoaded, setMarketCompareStateIsLoaded] = useState(false);
	const [marketCompareStateerror, setMarketCompareStateError] = useState(null);

	const [marketStateDropdownValue, setMarketStateDropdownValue] = useState("");

	const marketStateDropdownHandler = (e) => {
		if (e === null) {
			setMarketStateDropdownValue("")
		} else {
			setMarketStateDropdownValue(e)
		}
	};

	useEffect(() => {
		const fetchMarketAllIndiaDropdownValue = async () => {
			setMarketComparisonDropdownOptionsLoading(true);

			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/market/dropdown-market-comparison/", config);
				setMarketComparisonDropdownOptions(res?.data ?? []);

				setMarketStateDropdownValue(res?.data[1] ?? '')
				setMarketAllIndiaDropdownValue(res?.data[0] ?? '')
				setMarketComparisonDropdownOptionsLoading(false);
			} catch (error) {
				setMarketStateDropdownValue("")
				setMarketAllIndiaDropdownValue("")
				setMarketComparisonDropdownOptions([]);
				setMarketComparisonDropdownOptionsLoading(false);

				setMarketCompareAllIndiaIsLoading(false)
				setMarketCompareAllIndiaError(error.code)

				setMarketCompareStateIsLoading(false)
				setMarketCompareStateError(error.code)
			}
		};
		fetchMarketAllIndiaDropdownValue()
	}, [BRAND_ID, selectedDates, authCtx])


	// PERFORMANCE TREND 
	const [performanceTrend, setPerformanceTrend] = useState([]);
	const [performanceTrendLabel, setPerformanceTrendLabel] = useState([]);
	const [performanceTrendTable, setPerformanceTrendTable] = useState([]);
	const [isPerformanceTrendLoading, setPerformanceTrendIsLoading] = useState(false);
	const [isPerformanceTrendLoaded, setPerformanceTrendIsLoaded] = useState(false);
	const [performanceTrenderror, setPerformanceTrendError] = useState(null);

	const [perfTrendDropdownOptions, setPerfTrendDropdownOptions] = useState([])
	const [perfTrendFirstDropdownValue, setperfTrendFirstDropdownValue] = useState('');

	const perfTrendFirstDropdownHandler = (e) => {
		if (e === null) {
			setperfTrendFirstDropdownValue("")
		} else {
			setperfTrendFirstDropdownValue(e)
		}
	};

	const [perfTrendSecondDropdownValue, setperfTrendSecondDropdownValue] = useState("");

	const perfTrendSecondDropdownHandler = (e) => {
		if (e === null) {
			setperfTrendSecondDropdownValue("")
		} else {
			setperfTrendSecondDropdownValue(e)
		}
	};

	const [perfTrendThirdDropdownValue, setperfTrendThirdDropdownValue] = useState("");

	const perfTrendThirdDropdownHandler = (e) => {
		if (e === null) {
			setperfTrendThirdDropdownValue("")
		} else {
			setperfTrendThirdDropdownValue(e)
		}
	};

	const [perfTrendDropdownOptionsLoading, setPerfTrendDropdownOptionsLoading] = useState(false)
	const [perfTrendDropdownOptionsLoaded, setPerfTrendDropdownOptionsLoaded] = useState(false)

	useEffect(() => {
		const fetchPerfTrendDropdownOptions = async () => {
			setPerfTrendDropdownOptionsLoading(true);
			setPerfTrendDropdownOptionsLoaded(false)
			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/market/dropdown-performance-trend/", config);
				setPerfTrendDropdownOptions(res?.data ?? []);

				var tvSpendsSelected = res?.data?.find(item => item.value === "tv_spends") ?? []

				var tvVisitsSelected = res?.data?.find(item => item.value === "visits") ?? []

				var tvImpressionsSelected = res?.data?.find(item => item.value === "tv_impressions") ?? []


				setperfTrendFirstDropdownValue(tvSpendsSelected === undefined ? "" : tvSpendsSelected)
				setperfTrendSecondDropdownValue(tvVisitsSelected === undefined ? "" : tvVisitsSelected)
				setperfTrendThirdDropdownValue(tvImpressionsSelected === undefined ? "" : tvImpressionsSelected)

				setPerfTrendDropdownOptionsLoading(false);
				setPerfTrendDropdownOptionsLoaded(true)
			} catch {
				setPerfTrendDropdownOptions([]);

				setperfTrendFirstDropdownValue("")
				setperfTrendSecondDropdownValue("")
				setperfTrendThirdDropdownValue("")
				setPerfTrendDropdownOptionsLoaded(false)
				setPerfTrendDropdownOptionsLoading(false);
			}
		};
		fetchPerfTrendDropdownOptions()
	}, [authCtx, selectedDates, BRAND_ID,])


	// TOP BOTTOM PERFORMING MARKETS
	const [topBottomPerformingMarketsData, setTopBottomPerformingMarketsData] = useState([]);
	const [topBottomPerformingMarketsTableData, setTopBottomPerformingMarketsTableData] = useState([]);
	const [topBottomPerformingMarketsTableHeader, setTopBottomPerformingMarketsTableHeader] = useState([]);

	const [topBottomPerformingMarketsLabels, setTopBottomPerformingMarketsLables] = useState([]);
	const [isTopBottomPerformingMarketsLoading, setTopBottomPerformingMarketsIsLoading] = useState(false);
	const [isTopBottomPerformingMarketsLoaded, setTopBottomPerformingMarketsIsLoaded] = useState(false);
	const [topBottomPerformingMarketsError, setTopBottomPerformingMarketsError] = useState(null);

	const TopBottomPerformingMarketsTableHeaderTV = [
		{ name: "Markets", field: "state_name" },
		{ name: "Visits", field: "visits" },
		{ name: "Visits % Change", field: "visits_percent_change" },
		{ name: "% Total Visit", field: "percentage_of_total_visits" },
		{ name: "Visit with event", field: "total_visits_with_events" },
		{ name: "TV Spends", field: "spends" },
		{ name: "TV Impressions", field: "impressions" },
		{ name: "% of TV Spends", field: "spends_percentage" },
		{ name: "% of TV Impressions", field: "impressions_percentage" },
	]

	const TopBottomPerformingMarketsTableHeaderAllDigital = [
		{ name: "Markets", field: "state_name" },
		{ name: "Visits", field: "visits" },
		{ name: "Visits % Change", field: "visits_percent_change" },
		{ name: "% Total Visit", field: "percentage_of_total_visits" },
		{ name: "Visit with event", field: "total_visits_with_events" },
		{ name: "All Digital Spends", field: "spends" },
		{ name: "All Digital Impressions", field: "impressions" },
		{ name: "% of All Digital Spends", field: "spends_percentage" },
		{ name: "% of All Digital Impressions", field: "impressions_percentage" },
	]

	const TopBottomPerformingMarketsTableHeaderDigitalMeta = [
		{ name: "Markets", field: "state_name" },
		{ name: "Visits", field: "visits" },
		{ name: "Visits % Change", field: "visits_percent_change" },
		{ name: "% Total Visit", field: "percentage_of_total_visits" },
		{ name: "Visit with event", field: "total_visits_with_events" },
		{ name: "Digital Meta Spends", field: "spends" },
		{ name: "Digital Meta Impressions", field: "impressions" },
		{ name: "% of Digital Meta Spends", field: "spends_percentage" },
		{ name: "% of Digital Meta Impressions", field: "impressions_percentage" },
	]

	const TopBottomPerformingMarketsTableHeaderDigitalDV360 = [
		{ name: "Markets", field: "state_name" },
		{ name: "Visits", field: "visits" },
		{ name: "Visits % Change", field: "visits_percent_change" },
		{ name: "% Total Visit", field: "percentage_of_total_visits" },
		{ name: "Visit with event", field: "total_visits_with_events" },
		{ name: "Digital Google Spends", field: "spends" },
		{ name: "Digital Google Impressions", field: "impressions" },
		{ name: "% of Digital Google Spends", field: "spends_percentage" },
		{ name: "% of Digital Google Impressions", field: "impressions_percentage" },
	]

	const TopBottomPerformingMarketsTableHeaderDigitalTvDigital = [
		{ name: "Markets", field: "state_name" },
		{ name: "Visits", field: "visits" },
		{ name: "Visits % Change", field: "visits_percent_change" },
		{ name: "% Total Visit", field: "percentage_of_total_visits" },
		{ name: "Visit with event", field: "total_visits_with_events" },
		{ name: "TV + Digital Spends", field: "spends" },
		{ name: "TV + Digital Impressions", field: "impressions" },
		{ name: "% of TV + Digital Spends", field: "spends_percentage" },
		{ name: "% of TV + Digital Impressions", field: "impressions_percentage" },
	];

	const [topBottomPerformDataDropdownOptions, setTopBottomPerformDataDropdownOptions] = useState([])
	const [topBottomPerformDataDropdownOptionsLoading, setTopBottomPerformDataDropdownOptionsLoading] = useState(false);
	const [topBottomPerformDataDropdownOptionsLoaded, setTopBottomPerformDataDropdownOptionsLoaded] = useState(false);

	const [topBottomPerformDataDropdownValue, settopBottomPerformDataDropdownValue] = useState("");

	var topBottomPerformSelectedLabel = topBottomPerformDataDropdownValue === undefined || topBottomPerformDataDropdownValue === "" ? "" :
		topBottomPerformDataDropdownValue.label === undefined ? "" : topBottomPerformDataDropdownValue.label

	const topBottomPerformDataDropdownHandler = (e) => {
		if (e === null) {
			settopBottomPerformDataDropdownValue("")
		} else {
			settopBottomPerformDataDropdownValue(e)
		}
	};

	useEffect(() => {
		const fetchTopBottomPerformingMarketDropdown = async () => {
			setTopBottomPerformDataDropdownOptionsLoading(true);
			setTopBottomPerformDataDropdownOptionsLoaded(false)

			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/market/dropdown-top-bottom-market/", config);
				setTopBottomPerformDataDropdownOptions(res?.data ?? []);


				settopBottomPerformDataDropdownValue(res?.data[0] ?? '')
				setTopBottomPerformDataDropdownOptionsLoading(false);
				setTopBottomPerformDataDropdownOptionsLoaded(true)
			} catch {
				settopBottomPerformDataDropdownValue("")
				setTopBottomPerformDataDropdownOptions([]);
				setTopBottomPerformDataDropdownOptionsLoading(false);
				setTopBottomPerformDataDropdownOptionsLoaded(false)

			}
		};
		fetchTopBottomPerformingMarketDropdown()
	}, [BRAND_ID, selectedDates, authCtx])


	useEffect(() => {

		const fetchTopBottomPerformingMarketTableHeader = () => {
			if (topBottomPerformDataDropdownValue.value === "all_digital") {
				setTopBottomPerformingMarketsTableHeader(TopBottomPerformingMarketsTableHeaderAllDigital);
			} else if (topBottomPerformDataDropdownValue.value === "tv") {
				setTopBottomPerformingMarketsTableHeader(TopBottomPerformingMarketsTableHeaderTV)
			} else if (topBottomPerformDataDropdownValue.value === "digital_meta") {
				setTopBottomPerformingMarketsTableHeader(TopBottomPerformingMarketsTableHeaderDigitalMeta)
			} else if (topBottomPerformDataDropdownValue.value === "digital_google") {
				setTopBottomPerformingMarketsTableHeader(TopBottomPerformingMarketsTableHeaderDigitalDV360)
			} else if (topBottomPerformDataDropdownValue.value === "tv_digital") {
				setTopBottomPerformingMarketsTableHeader(TopBottomPerformingMarketsTableHeaderDigitalTvDigital)
			} else {
				setTopBottomPerformingMarketsTableHeader([])
			}
		}

		fetchTopBottomPerformingMarketTableHeader()

	}, [topBottomPerformDataDropdownValue])

	// EVENT FUNNEL
	const [eventFunnel, setEventFunnel] = useState([]);
	const [isEventFunnelIsLoading, setEventFunnelIsLoading] = useState(false);
	const [isEventFunnelIsLoaded, setEventFunnelIsLoaded] = useState(false);
	const [eventFunnelerror, setEventFunnelError] = useState(null);

	const [eventFunnelDropdownValue, setEventFunnelDropdownValue] = useState("");
	var eventFunnelLabel =
		eventFunnelDropdownValue === undefined || eventFunnelDropdownValue === null ? "" :
			eventFunnelDropdownValue.label === undefined || eventFunnelDropdownValue.label === null ? "" :
				" : " + eventFunnelDropdownValue.label;

	const [eventFunnelDropdownOptions, setEventFunnelDataDropdownOptions] = useState([])
	const [eventFunnelDropdownOptionsLoading, setEventFunnelDataDropdownOptionsLoading] = useState(false);
	const [eventFunnelDropdownOptionsLoaded, setEventFunnelDataDropdownOptionsLoaded] = useState(false);

	const eventFunnelDropdownHandler = (e) => {
		if (e === null) {
			setEventFunnelDropdownValue("")
		} else {
			setEventFunnelDropdownValue(e)
		}
	};

	useEffect(() => {
		const fetchEventFunnelingMarketDropdown = async () => {
			setEventFunnelDataDropdownOptionsLoading(true);
			setEventFunnelDataDropdownOptionsLoaded(false)

			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/market/dropdown-event-funnel/", config);
				setEventFunnelDataDropdownOptions(res?.data ?? []);
				setEventFunnelDropdownValue(res?.data[0] ?? '');

				setEventFunnelDataDropdownOptionsLoading(false);
				setEventFunnelDataDropdownOptionsLoaded(true)
			} catch {
				setEventFunnelDropdownValue("")
				setEventFunnelDataDropdownOptions([]);
				setEventFunnelDataDropdownOptionsLoading(false);
				setEventFunnelDataDropdownOptionsLoaded(false)

			}
		};
		fetchEventFunnelingMarketDropdown()
	}, [BRAND_ID, selectedDates, authCtx])


	// MARKET WISE PRODUCT
	const [marketCampaignDeepdive, setMarketCampaignDeepdive] = useState([]);
	const [isMarketCampaignDeepdiveLoading, setMarketCampaignDeepdiveIsLoading] = useState(false);
	const [isMarketCampaignDeepdiveLoaded, setMarketCampaignDeepdiveIsLoaded] = useState(false);
	const [marketCampaignDeepdiveerror, setMarketCampaignDeepdiveError] = useState(null);


	//   BRANDWISE PERFORMANCE - TREE MAP HANDLER
	let treemapLevel = 1;
	const [treemapStateCode, setTreemapStateCode] = useState(null);
	const [treemapStateName, setTreemapStateName] = useState("");

	const [treemapParams, setTreemapParams] = useState({});

	useEffect(() => {
		if (mapStateCode === undefined || mapStateCode === null) {
			setTreemapParams({
				brand_id: BRAND_ID,
				start_date: selectedDates.startDate,
				end_date: selectedDates.endDate,
				previous_start_date: selectedDates.prevStartDate,
				previous_end_date: selectedDates.prevEndDate,
				level: 1,
			})
			setTreemapStateCode(null)
			setTreemapStateName('')
		} else {
			setTreemapParams({
				brand_id: BRAND_ID,
				start_date: selectedDates.startDate,
				end_date: selectedDates.endDate,
				previous_start_date: selectedDates.prevStartDate,
				previous_end_date: selectedDates.prevEndDate,
				state_code: mapStateCode,
				level: 1,
			})
			setTreemapStateCode(null)
			setTreemapStateName('')
		}
	}, [BRAND_ID, selectedDates, mapStateCode])



	const chartlevelHandler = (data) => {
		if (data.level === 1) {
			setTreemapParams({
				brand_id: BRAND_ID,
				start_date: selectedDates.startDate,
				end_date: selectedDates.endDate,
				previous_start_date: selectedDates.prevStartDate,
				previous_end_date: selectedDates.prevEndDate,
				state_code: mapStateCode === undefined || mapStateCode === null ? data.state_code : mapStateCode,
				level: 2,
			});
			setTreemapStateCode(data.state_code);
			setTreemapStateName(data.name)
		}
		else {
			alert("No further drill down available");
		}
	};

	// OVERALL MEDIA PERFORMANCE
	const [overallMediaPerformance, setOverallMediaPerformance] = useState([]);
	const [overallMediaPerformanceLabel, setOverallMediaPerformanceLabel] = useState([]);
	const [overallMediaPerformanceTableData, setOverallMediaPerformanceTableData] = useState([]);
	const [isOverallMediaPerformanceLoading, setOverallMediaPerformanceIsLoading] = useState(false);
	const [isOverallMediaPerformanceLoaded, setOverallMediaPerformanceIsLoaded] = useState(false);
	const [overallMediaPerformanceerror, setOverallMediaPerformanceError] = useState(null);


	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// INDIA MAP
	useEffect(() => {
		const fetchMapDataHandler = async () => {
			setMapIsLoading(true);
			setIsMapLoaded(false);
			setMapError(null);

			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: mapStateCode,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/market/market-map/", config);

				setMapData(res?.data ?? []);
				setMapIsLoading(false);
				setIsMapLoaded(true);
				setMapError(null);
			} catch (error) {
				setMapIsLoading(false);
				setIsMapLoaded(false);
				setMapError(error.code);
			}
		};

		fetchMapDataHandler();
	}, [
		authCtx,
		BRAND_ID,
		selectedDates,
		mapStateCode,
	]);

	// INDIA MAP
	let IndiaMapChart = <Wrapper><Loader loaderType="indiaMapLoader" /></Wrapper>

	// IF DATA IS LOADED
	if (isMapLoaded && !isMapLoading) {
		if (NoDataAvailableChecker(MapData)) {
			IndiaMapChart = <Wrapper><NoDataAvailableLoader chartType="indiaChartType" /></Wrapper>
		} else {
			IndiaMapChart = (
				<Wrapper>
					<ErrorBoundary chartType="indiaChartType">
						<IndiaMap
							showBreadcrumb={true}
							geoJson={MapData}
							northPoint={northPoint}
							southPoint={southPoint}
							centerPoint={centerPoint}
							zoomLevel={zoomLevel}
							mapLevel={mapLevel}
							onChange={handleIndiaMapChange}
							growthDegrowth={"visits"}
							toolTipValues={["visits", "unique_visitors", "tv_spends", "tv_grp", "digital_impressions", "digital_spends"]}
							toolTipLabels={["Visits", "Unique Visitors", "TV Spends", "TV GRP", "Digital Impressions", "Digital Spends"]}
						/>
					</ErrorBoundary>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (MapError) {
		IndiaMapChart = (
			<Wrapper><ServerErrorsLoader chartType="indiaChartType" error={MapError} /></Wrapper>
		);
	}

	// IF DATA LOADING
	if (isMapLoading) {
		IndiaMapChart = <Wrapper><Loader loaderType="indiaMapLoader" /></Wrapper>;
	}

	function mapBreadcrumbsHandler(index, value) {

		if (index === 0) {
			let a = ["INDIA"];
			setMapBreadCrumbList(a);
			setMapLevel(1);
			setMapState(null);
			setMapStateCode(null);
			setCenterPoint(["82,22"]);
			setNorthPoint(["72.6416015625, 37.71859032558816"]);
			setSouthPoint(["93.1640625, 5.7908968128719565"]);
		}
		if (index === 1) {
			let a = ["INDIA", value];
			setMapBreadCrumbList(a);
			setMapLevel(2);
			setMapState(null);
			setMapStateCode(null);
		}
	}

	async function handleIndiaMapChange(value) {
		setMapLevel(value[0] + 1);
		setCenterPoint(value[1]);
		setNorthPoint(value[2]);
		setSouthPoint(value[3]);
		setMapState(value[5]);
		setMapStateCode(value[6]);
		setMapBreadCrumbList(["INDIA", value[5]]);

	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// PERFORMANCE METRIC CARDS

	// TO FETCH PERFORMANCE METRIC CARDS FROM API

	useEffect(() => {

		const fetchPerformanceMetricCardsHandler = async () => {
			setPerformanceMetricCardsIsLoading(true);
			setPerformanceMetricCardsIsLoaded(false);
			setPerformanceMetricCardsError(null);

			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: mapStateCode,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/market/metric-card/", config);

				setPerformanceMetricCards(res?.data?.data ?? []);

				setPerformanceMetricCardsIsLoading(false);
				setPerformanceMetricCardsIsLoaded(true);
				setPerformanceMetricCardsError(null);
			} catch (error) {
				setPerformanceMetricCards([]);
				setPerformanceMetricCardsIsLoading(false);
				setPerformanceMetricCardsIsLoaded(false);
				setPerformanceMetricCardsError(error.code);
			}
		};

		fetchPerformanceMetricCardsHandler()
	}, [
		authCtx,
		BRAND_ID,
		selectedDates,
		mapStateCode,
	])

	// OVERVIEW METRICS CARDS SLIDER DATA HANDLING
	let performanceMetricCardsSection = (
		<Wrapper>
			<div className="grid grid_cols_4 grid_margin_bottom">
				<div className="grid col_span_4 section_header">
					<div className="section_info">
						<h2 className="section_card_title">Performance Metrics
						</h2>
					</div>
				</div>
				<div className="grid col_span_4 ">
					<Loader loaderType="cardLoader" />
				</div>
			</div>
		</Wrapper>
	);

	// IF DATA IS LOADED
	if (isPerformanceMetricCardsLoaded && !isPerformanceMetricCardsLoading) {
		if (NoDataAvailableChecker(performanceMetricCards)) {
			performanceMetricCardsSection = (
				<Wrapper>
					<div className="grid grid_cols_4 grid_margin_bottom">
						<div className="grid col_span_4 section_header">
							<div className="section_info">
								<h2 className="section_card_title">Performance Metrics
								</h2>
							</div>
						</div>
						<div className="grid col_span_4  ">
							<NoDataAvailableLoader chartType="cardChartType" />
						</div>
					</div>
				</Wrapper>
			);
		} else {
			performanceMetricCardsSection = (
				<Wrapper>
					<ErrorBoundary chartType="cardChartType">
						<Slider sliderTitle={"Performance Metrics"}>
							{performanceMetricCards.map((data, index) => (
								<SwiperSlide key={index}>
									<Card
										key={index}
										cardTitle={data.label}
										currentValue={data.currentValue}
										previousValue={data.previousValue}
										rowOneTitle={"Previous"}
										numberVariant={"number"}
									/>
								</SwiperSlide>
							))}
						</Slider>
					</ErrorBoundary>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (performanceMetricCardserror) {
		performanceMetricCardsSection = (
			<Wrapper>
				<div className="grid grid_cols_4 grid_margin_bottom">
					<div className="grid col_span_4 section_header">
						<div className="section_info">
							<h2 className="section_card_title">Performance Metrics</h2>
						</div>
					</div>
					<div className="grid col_span_4  ">
						<ServerErrorsLoader chartType="cardChartType" error={performanceMetricCardserror} />
					</div>
				</div>
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (isPerformanceMetricCardsLoading) {
		performanceMetricCardsSection = (
			<Wrapper>
				<div className="grid grid_cols_4 grid_margin_bottom">
					<div className="grid col_span_4 section_header">
						<div className="section_info">
							<h2 className="section_card_title">Performance Metrics
							</h2>
						</div>
					</div>
					<div className="grid col_span_4  ">
						<Loader loaderType="cardLoader" />
					</div>
				</div>
			</Wrapper>
		);
	}
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// MARKET COMPARISON : ALL INDIA
	let defaultCompareAllIndiaDisabledvalues = [];

	if (showAllLegendsinMultiLineCluster) {
		defaultCompareAllIndiaDisabledvalues = []
	} else {
		defaultCompareAllIndiaDisabledvalues = []
	}


	const [isCompareAllIndiaOpen, setIsCompareAllIndiaOpen] = useState(false)
	const [compareAllIndiaDisabledvalues, setCompareAllIndiaDisabledvalues] = useState(defaultCompareAllIndiaDisabledvalues)

	useEffect(() => {
		const fetchMarketCompareAllIndiaHandler = async () => {
			setMarketCompareAllIndiaIsLoading(true);
			setMarketCompareAllIndiaIsLoaded(false);
			setMarketCompareAllIndiaError(null);

			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: marketAllIndiaDropdownValue.value
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/market/market-comparison/", config);

				setMarketCompareAllIndiaTable(res?.data?.table_data ?? []);
				setMarketCompareAllIndia(res?.data?.data ?? []);
				setMarketCompareAllIndiaLabel(res?.data?.dimension ?? []);
				setMarketCompareAllIndiaIsLoading(false);
				setMarketCompareAllIndiaIsLoaded(true);
				setMarketCompareAllIndiaError(null);
			} catch (error) {
				setMarketCompareAllIndiaTable([]);
				setMarketCompareAllIndia([]);
				setMarketCompareAllIndiaLabel([]);
				setMarketCompareAllIndiaIsLoading(false);
				setMarketCompareAllIndiaIsLoaded(false);
				setMarketCompareAllIndiaError(error.code);
			}
		};
		setIsMarketTableHidden({ text: "View More", class: "hide_rows" });
		(marketAllIndiaDropdownValue !== undefined && marketAllIndiaDropdownValue !== null && marketAllIndiaDropdownValue !== "" && typeof marketAllIndiaDropdownValue !== "string") && fetchMarketCompareAllIndiaHandler()
	}, [
		authCtx,
		BRAND_ID,
		selectedDates,
		marketAllIndiaDropdownValue
	])

	let marketCompareAllIndiaSection = <Wrapper><Loader loaderType="lineChartLoader" /></Wrapper>
	let marketCompareAllIndiaSection2 = <Wrapper><Loader loaderType="lineChartLoader" /></Wrapper>
	let marketCompareAllIndiaTableSection = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>

	// IF DATA IS LOADED
	if (isMarketCompareAllIndiaLoaded && !isMarketCompareAllIndiaLoading) {
		if (NoDataAvailableChecker(marketCompareAllIndia) || NoDataAvailableChecker(marketCompareAllIndiaLabel)) {
			marketCompareAllIndiaSection = (<Wrapper><NoDataAvailableLoader chartType="multiAxisMultiLineChartLoader" /></Wrapper>);
			marketCompareAllIndiaSection2 = (<Wrapper><NoDataAvailableLoader chartType="multiAxisMultiLineChartLoader" /></Wrapper>);
			marketCompareAllIndiaTableSection = (<Wrapper><NoDataAvailableLoader chartType="tableChartType" /></Wrapper>);

		} else {
			if (isMultiLineCluster) {
				marketCompareAllIndiaSection = (
					<Wrapper>
						<ErrorBoundary chartType="lineChartType">
							<LineChart
								chartClass="section_card_chart"
								chartData={marketCompareAllIndia}
								chartId="marketCompareAllIndia"
								chartLabels={marketCompareAllIndiaLabel}
								showLegend={true}
								disabledLegendArray={compareAllIndiaDisabledvalues}
								setDisabledValues={setCompareAllIndiaDisabledvalues}
							/>
						</ErrorBoundary>
					</Wrapper>
				);
				marketCompareAllIndiaSection2 = (
					<Wrapper>
						<ErrorBoundary chartType="lineChartType">
							<PartitionedLineChart
								chartClass="section_card_chart"
								chartData={marketCompareAllIndia}
								chartId="marketCompareAllIndia_2"
								chartLabels={marketCompareAllIndiaLabel}
								showLegend={true}
								disabledLegendArray={compareAllIndiaDisabledvalues}
								setDisabledValues={setCompareAllIndiaDisabledvalues}
							/>
						</ErrorBoundary>
					</Wrapper>
				);
			} else {
				marketCompareAllIndiaSection = (
					<Wrapper>
						<ErrorBoundary chartType="lineChartType">
							<MultiAxisMultiLine
								chartClass="section_card_chart"
								chartData={marketCompareAllIndia}
								chartId="marketCompareAllIndia"
								chartLabels={marketCompareAllIndiaLabel}
								showLegend={true}
							/>
						</ErrorBoundary>
					</Wrapper>
				);
			}

			let len = marketCompareAllIndiaTable === undefined || marketCompareAllIndiaTable.length === 0 ? [] : marketCompareAllIndiaTable.length - 1;
			var marketAllIndiaArr = []


			for (var i = 0; i <= len; i++) {
				if (marketAllIndiaArr === undefined) break;
				if (i % 2 === 0) {
					var obj = {}


					if (marketCompareAllIndiaTable[i] !== undefined) {
						obj["label"] = marketCompareAllIndiaTable[i].label
						obj["value"] = marketCompareAllIndiaTable[i].value
					} else {
						obj["label"] = ''
						obj["value"] = ""
					}

					if (marketCompareAllIndiaTable[i + 1] !== undefined && marketCompareAllIndiaTable[i] !== undefined) {
						obj["label1"] = marketCompareAllIndiaTable[i + 1].label
						obj["value1"] = marketCompareAllIndiaTable[i + 1].value
					} else if (marketCompareAllIndiaTable[i + 1] === undefined && marketCompareAllIndiaTable[i] !== undefined) {
						obj["label"] = marketCompareAllIndiaTable[i].label
						obj["value"] = marketCompareAllIndiaTable[i].value
						obj["label1"] = ""
						obj["value1"] = ""
					} else {
						obj["label"] = ""
						obj["value"] = ''
						obj["label1"] = ""
						obj["value1"] = ""
					}

					marketAllIndiaArr.push(obj)
				}
			}

			marketCompareAllIndiaTableSection = (
				<Wrapper>
					<ErrorBoundary chartType={"tableChartType"}>
						<table className={`market_compare_table ${isMarketTableHidden.class}`} id='market_compare_india' >
							<tbody>
								{marketAllIndiaArr && marketAllIndiaArr.map((item) => {
									return (
										<Wrapper>
											<tr>
												<td>
													<div className='market_compare_label'>
														{item.label}
													</div>
													<div className='market_compare_value'>
														<ErrorBoundary>
															<NumberFormatter number={item.value} title={item.label} />
														</ErrorBoundary>
													</div>
												</td>
												<td>
													<div className='market_compare_label'>
														{item.label1}
													</div>
													<div className='market_compare_value'>
														<ErrorBoundary>
															<NumberFormatter number={item.value1} title={item.label1} />
														</ErrorBoundary>
													</div>
												</td>
											</tr>
										</Wrapper>
									)
								})}
							</tbody>
						</table>
					</ErrorBoundary>
				</Wrapper>
			)
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (marketCompareAllIndiaerror) {
		marketCompareAllIndiaSection = <Wrapper><ServerErrorsLoader chartType="lineChartType" error={marketCompareAllIndiaerror} /></Wrapper>
		marketCompareAllIndiaSection2 = <Wrapper><ServerErrorsLoader chartType="lineChartType" error={marketCompareAllIndiaerror} /></Wrapper>
		marketCompareAllIndiaTableSection = <Wrapper><ServerErrorsLoader chartType="tableChartType" error={marketCompareAllIndiaerror} /></Wrapper>;

	}

	// IF DATA LOADING
	if (isMarketCompareAllIndiaLoading) {
		marketCompareAllIndiaSection = <Wrapper><Loader loaderType="multiAxisMultiLineChartLoader" /></Wrapper>
		marketCompareAllIndiaSection2 = <Wrapper><Loader loaderType="multiAxisMultiLineChartLoader" /></Wrapper>
		marketCompareAllIndiaTableSection = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>;

	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// MARKET COMPARISON : STATE
	let defaultCompareStateDisabledvalues = [];

	if (showAllLegendsinMultiLineCluster) {
		defaultCompareStateDisabledvalues = []
	} else {
		defaultCompareStateDisabledvalues = []
	}

	const [isCompareStateOpen, setIsCompareStateOpen] = useState(false)
	const [compareStateDisabledvalues, setCompareStateDisabledvalues] = useState(defaultCompareStateDisabledvalues)

	useEffect(() => {
		const fetchMarketCompareStateHandler = async () => {
			setMarketCompareStateIsLoading(true);
			setMarketCompareStateIsLoaded(false);
			setMarketCompareStateError(null);

			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: marketStateDropdownValue.value
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/market/market-comparison/", config);

				setMarketCompareState(res?.data?.data ?? []);
				setMarketCompareStateLabel(res?.data?.dimension ?? []);
				setMarketCompareStateTable(res?.data?.table_data ?? []);

				setMarketCompareStateIsLoading(false);
				setMarketCompareStateIsLoaded(true);
				setMarketCompareStateError(null);
			} catch (error) {
				setMarketCompareState([]);
				setMarketCompareStateLabel([]);
				setMarketCompareStateTable([]);
				setMarketCompareStateIsLoading(false);
				setMarketCompareStateIsLoaded(false);
				setMarketCompareStateError(error.code);
			}
		};
		(marketStateDropdownValue !== undefined && marketStateDropdownValue !== null && marketStateDropdownValue !== "" && typeof marketStateDropdownValue !== "string") && fetchMarketCompareStateHandler()
	}, [
		authCtx,
		BRAND_ID,
		selectedDates,
		marketStateDropdownValue
	])

	let marketCompareStateSection = <Wrapper><Loader loaderType="lineChartLoader" /></Wrapper>;
	let marketCompareStateSection2 = <Wrapper><Loader loaderType="lineChartLoader" /></Wrapper>;
	let marketCompareStateTableSection = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>;

	// IF DATA IS LOADED
	if (isMarketCompareStateLoaded && !isMarketCompareStateLoading) {
		if (NoDataAvailableChecker(marketCompareState) || NoDataAvailableChecker(marketCompareStateLabel)) {
			marketCompareStateSection = <Wrapper><NoDataAvailableLoader chartType="multiAxisMultiLineChartLoader" /></Wrapper>
			marketCompareStateSection2 = <Wrapper><NoDataAvailableLoader chartType="multiAxisMultiLineChartLoader" /></Wrapper>
			marketCompareStateTableSection = <Wrapper><NoDataAvailableLoader chartType="tableChartType" /></Wrapper>

		} else {
			if (isMultiLineCluster) {
				marketCompareStateSection = (
					<Wrapper>
						<ErrorBoundary chartType="lineChartType">
							<LineChart
								chartClass="section_card_chart"
								chartData={marketCompareState}
								chartId="marketCompareState"
								chartLabels={marketCompareStateLabel}
								showLegend={true}
								disabledLegendArray={compareStateDisabledvalues}
								setDisabledValues={setCompareStateDisabledvalues}
							/>
						</ErrorBoundary>
					</Wrapper>
				);

				marketCompareStateSection2 = (
					<Wrapper>
						<ErrorBoundary chartType="lineChartType">
							<PartitionedLineChart
								chartClass="section_card_chart"
								chartData={marketCompareState}
								chartId="marketCompareState_2"
								chartLabels={marketCompareStateLabel}
								showLegend={true}
								disabledLegendArray={compareStateDisabledvalues}
								setDisabledValues={setCompareStateDisabledvalues}
							/>
						</ErrorBoundary>
					</Wrapper>
				);
			} else {
				marketCompareStateSection = (
					<Wrapper>
						<ErrorBoundary chartType="lineChartType">
							<MultiAxisMultiLine
								chartClass="section_card_chart"
								chartData={marketCompareState}
								chartId="marketCompareState"
								chartLabels={marketCompareStateLabel}
								showLegend={true}
							/>
						</ErrorBoundary>
					</Wrapper>
				);
			}


			let len = marketCompareStateTable === undefined || marketCompareStateTable.length === 0 ? [] : marketCompareStateTable.length - 1;
			var marketStateArr = []

			for (var i = 0; i <= len; i++) {
				if (marketStateArr === undefined) break;
				if (i % 2 === 0) {
					var obj = {}


					if (marketCompareStateTable[i] !== undefined) {
						obj["label"] = marketCompareStateTable[i].label
						obj["value"] = marketCompareStateTable[i].value
					} else {
						obj["label"] = ''
						obj["value"] = ""
					}


					if (marketCompareStateTable[i + 1] !== undefined && marketCompareStateTable[i] !== undefined) {
						obj["label1"] = marketCompareStateTable[i + 1].label
						obj["value1"] = marketCompareStateTable[i + 1].value
					} else if (marketCompareStateTable[i + 1] === undefined && marketCompareStateTable[i] !== undefined) {
						obj["label"] = marketCompareStateTable[i].label
						obj["value"] = marketCompareStateTable[i].value
						obj["label1"] = ""
						obj["value1"] = ""
					} else {
						obj["label"] = ""
						obj["value"] = ''
						obj["label1"] = ""
						obj["value1"] = ""
					}

					marketStateArr.push(obj)
				}
			}

			marketCompareStateTableSection = (
				<Wrapper>
					<ErrorBoundary chartType={'tableChartType'}>
						<table className={`market_compare_table ${isMarketTableHidden.class}`} id='market_compare_state'>
							<tbody>
								{marketStateArr && marketStateArr.map(item => {
									return (
										<Wrapper>
											<tr>
												<td>
													<div className='market_compare_label'>
														{item.label}
													</div>
													<div className='market_compare_value'>
														<ErrorBoundary>
															<NumberFormatter number={item.value} title={item.label} />
														</ErrorBoundary>
													</div>
												</td>
												<td>
													<div className='market_compare_label'>
														{item.label1}
													</div>
													<div className='market_compare_value'>
														<ErrorBoundary>
															<NumberFormatter number={item.value1} title={item.label1} />
														</ErrorBoundary>
													</div>
												</td>
											</tr>
										</Wrapper>)
								})}
							</tbody>
						</table>
					</ErrorBoundary>
				</Wrapper>
			)
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (marketCompareStateerror) {
		marketCompareStateSection = <Wrapper><ServerErrorsLoader chartType="lineChartType" error={marketCompareStateerror} /> </Wrapper>
		marketCompareStateSection2 = <Wrapper><ServerErrorsLoader chartType="lineChartType" error={marketCompareStateerror} /> </Wrapper>
		marketCompareStateTableSection = <Wrapper><ServerErrorsLoader chartType="tableChartType" error={marketCompareStateerror} /></Wrapper>

	}

	// IF DATA LOADING
	if (isMarketCompareStateLoading) {
		marketCompareStateSection = <Wrapper><Loader loaderType="multiAxisMultiLineChartLoader" /></Wrapper>
		marketCompareStateSection2 = <Wrapper><Loader loaderType="multiAxisMultiLineChartLoader" /></Wrapper>
		marketCompareStateTableSection = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>;

	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// PERFORMANCE TREND

	let defaultPerformanceTrendDisabledvalues = [];

	if (showAllLegendsinMultiLineCluster) {
		defaultPerformanceTrendDisabledvalues = []
	} else {
		defaultPerformanceTrendDisabledvalues = []
	}

	const [isPerformanceTrendOpen, setIsPerformanceTrendOpen] = useState(false)
	const [performanceTrendDisabledvalues, setPerformanceTrendDisabledvalues] = useState(defaultPerformanceTrendDisabledvalues)

	useEffect(() => {
		const fetchPerformanceTrendHandler = async () => {
			setPerformanceTrendIsLoading(true);
			setPerformanceTrendIsLoaded(false);
			setPerformanceTrendError(null);

			var dropdown2 = perfTrendSecondDropdownValue === undefined ? "" : perfTrendSecondDropdownValue.value === undefined ? perfTrendDropdownOptions[1].value : perfTrendSecondDropdownValue.value
			var dropdown1 = perfTrendFirstDropdownValue === undefined ? "" : perfTrendFirstDropdownValue.value === undefined ? perfTrendDropdownOptions[0].value : perfTrendFirstDropdownValue.value
			var dropdown3 = perfTrendThirdDropdownValue === undefined ? "" : perfTrendThirdDropdownValue.value === undefined ? perfTrendDropdownOptions[2].value : perfTrendThirdDropdownValue.value
			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: mapStateCode,
					performance_filter: `${dropdown1},${dropdown2},${dropdown3}`
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/market/performance-trend-line/", config);

				setPerformanceTrend(res?.data?.data ?? []);
				setPerformanceTrendLabel(res?.data?.dimension ?? []);
				setPerformanceTrendTable(res?.data?.table_data ?? []);

				setPerformanceTrendIsLoading(false);
				setPerformanceTrendIsLoaded(true);
				setPerformanceTrendError(null);
			} catch (error) {
				setPerformanceTrend([]);
				setPerformanceTrendLabel([]);
				setPerformanceTrendTable([])
				setPerformanceTrendIsLoading(false);
				setPerformanceTrendIsLoaded(false);
				setPerformanceTrendError(error.code);
			}
		};
		(perfTrendDropdownOptionsLoaded === true) && fetchPerformanceTrendHandler()
	}, [
		authCtx,
		BRAND_ID,
		selectedDates,
		mapStateCode,
		perfTrendDropdownOptions,
		perfTrendFirstDropdownValue,
		perfTrendSecondDropdownValue,
		perfTrendThirdDropdownValue,
		perfTrendDropdownOptionsLoaded
	])

	let performanceTrendSection = <Wrapper><Loader loaderType="multiAxisMultiLineChartLoader" /></Wrapper>;
	let performanceTrendSection2 = <Wrapper><Loader loaderType="multiAxisMultiLineChartLoader" /></Wrapper>;
	let performanceTrendTableSection = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>;

	// IF DATA IS LOADED
	if (isPerformanceTrendLoaded && !isPerformanceTrendLoading) {
		if (NoDataAvailableChecker(performanceTrend) || NoDataAvailableChecker(performanceTrendLabel)) {
			performanceTrendSection = (<Wrapper><NoDataAvailableLoader chartType="multiAxisMultiLineChartLoader" /></Wrapper>);
			performanceTrendSection2 = (<Wrapper><NoDataAvailableLoader chartType="multiAxisMultiLineChartLoader" /></Wrapper>);
			performanceTrendTableSection = (<Wrapper><NoDataAvailableLoader chartType="tableChartType" /></Wrapper>);
		} else {
			if (isMultiLineCluster) {
				performanceTrendSection = (
					<Wrapper>
						<ErrorBoundary chartType="lineChartType">
							<LineChart
								chartClass="section_card_chart"
								chartData={performanceTrend}
								chartId="performanceTrend"
								chartLabels={performanceTrendLabel}
								showLegend={true}
								disabledLegendArray={performanceTrendDisabledvalues}
								setDisabledValues={setPerformanceTrendDisabledvalues}
							/>
						</ErrorBoundary>
					</Wrapper>
				);

				performanceTrendSection2 = (
					<Wrapper>
						<ErrorBoundary chartType="lineChartType">
							<PartitionedLineChart
								chartClass="section_card_chart"
								chartData={performanceTrend}
								chartId="performanceTrend_2"
								chartLabels={performanceTrendLabel}
								showLegend={true}
								disabledLegendArray={performanceTrendDisabledvalues}
								setDisabledValues={setPerformanceTrendDisabledvalues}
							/>
						</ErrorBoundary>
					</Wrapper>
				);


			} else {
				performanceTrendSection = (
					<Wrapper>
						<ErrorBoundary chartType="lineChartType">
							<MultiAxisMultiLine
								chartClass="section_card_chart"
								chartData={performanceTrend}
								chartId="performanceTrend"
								chartLabels={performanceTrendLabel}
								showLegend={true}
							/>
						</ErrorBoundary>
					</Wrapper>
				);
			}

			performanceTrendTableSection = (
				<Wrapper>
					<ErrorBoundary chartType="tableChartType">
						<div className="main_wrapper">
							<div className="body">
								{performanceTrendTable && performanceTrendTable.map(
									(details, postImpressionIndex) => (
										<Wrapper key={postImpressionIndex}>
											<div className="row">
												<div className="title_wrapper">
													{details.label}
												</div>
												<div className="value_wrapper">
													<ErrorBoundary>
														<NumberFormatter number={details.value} title={details.label} />
													</ErrorBoundary>

												</div>
											</div>
										</Wrapper>
									))}
							</div>

						</div>
					</ErrorBoundary>
				</Wrapper>
			)

		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (performanceTrenderror) {
		performanceTrendSection = <Wrapper><ServerErrorsLoader chartType="lineChartType" error={performanceTrenderror} /></Wrapper>
		performanceTrendSection2 = <Wrapper><ServerErrorsLoader chartType="lineChartType" error={performanceTrenderror} /></Wrapper>
		performanceTrendTableSection = <Wrapper><ServerErrorsLoader loaderType="tableChartType" error={performanceTrenderror} /></Wrapper>

	}

	// IF DATA LOADING
	if (isPerformanceTrendLoading) {
		performanceTrendSection = <Wrapper><Loader loaderType="multiAxisMultiLineChartLoader" /></Wrapper>
		performanceTrendSection2 = <Wrapper><Loader loaderType="multiAxisMultiLineChartLoader" /></Wrapper>
		performanceTrendTableSection = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>

	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// TOP BOTTOM PERFORMING MARKETS

	useEffect(() => {
		const fetchTopBottomPerformingMarketHandler = async () => {
			setTopBottomPerformingMarketsIsLoading(true);
			setTopBottomPerformingMarketsIsLoaded(false);
			setTopBottomPerformingMarketsError(null);

			var dropdownVal = topBottomPerformDataDropdownValue === undefined || topBottomPerformDataDropdownValue === "" ? "" :
				topBottomPerformDataDropdownValue.value === undefined ? topBottomPerformDataDropdownOptions[0].value :
					`${topBottomPerformDataDropdownValue.value}`

			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: mapStateCode,
					performance_filter: dropdownVal,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/market/top-bottom-performing-market/", config);

				setTopBottomPerformingMarketsData(res?.data?.data ?? []);
				setTopBottomPerformingMarketsLables(res?.data?.dimension ?? []);
				setTopBottomPerformingMarketsTableData(res?.data?.table_data ?? []);

				setTopBottomPerformingMarketsIsLoading(false);
				setTopBottomPerformingMarketsIsLoaded(true);
				setTopBottomPerformingMarketsError(null);
			} catch (error) {
				setTopBottomPerformingMarketsData([]);
				setTopBottomPerformingMarketsLables([]);
				setTopBottomPerformingMarketsIsLoading(false);
				setTopBottomPerformingMarketsIsLoaded(false);
				setTopBottomPerformingMarketsError(error.code);
			}

		};
		(topBottomPerformDataDropdownOptionsLoaded === true) && fetchTopBottomPerformingMarketHandler()
	}, [
		BRAND_ID,
		authCtx,
		selectedDates,
		topBottomPerformDataDropdownValue,
		mapStateCode,
		topBottomPerformDataDropdownOptionsLoading,
		topBottomPerformDataDropdownOptions,
		topBottomPerformDataDropdownOptionsLoaded

	])


	// SALES METRICS CARDS SLIDER DATA HANDLING
	let topBottomPerfMarketSection = <Wrapper><Loader loaderType="bubbleChartLoader" /></Wrapper>;
	let topBottomPerfMarketTableSection = (
		<Wrapper>
			<div className="grid grid_cols_4 fullpage_section_card section_card">
				<div className="grid col_span_4">
					<div className="section_card_body">
						<Loader loaderType="smallTableLoader" />
					</div>
				</div>
			</div>
		</Wrapper>
	);

	// IF DATA IS LOADED
	if (isTopBottomPerformingMarketsLoaded && !isTopBottomPerformingMarketsLoading) {
		if (NoDataAvailableChecker(topBottomPerformingMarketsData) || NoDataAvailableChecker(topBottomPerformingMarketsLabels)) {
			topBottomPerfMarketSection = <Wrapper><NoDataAvailableLoader chartType="bubbleChartType" /></Wrapper>
			topBottomPerfMarketTableSection = (
				<Wrapper>
					<div className="grid grid_cols_4 fullpage_section_card section_card">
						<div className="grid col_span_4">
							<div className="section_card_body">
								<NoDataAvailableLoader chartType="tableChartType" />
							</div>
						</div>
					</div>
				</Wrapper>
			);
		} else {
			topBottomPerfMarketSection = (
				<Wrapper>
					<ErrorBoundary chartType="bubbleChartType">
						<BubbleChartSizeShapeDynamic
							chartId="zoomablechart"
							chartClass="section_card_chart"
							showLegend={true}
							showLabels={true}
							chartData={topBottomPerformingMarketsData}
							chartLabels={topBottomPerformingMarketsLabels}
							riMarketIDFC={true}
						/* riMarketIDFCLabels={topBottomPerformSelectedLabel} */
						/>
					</ErrorBoundary>
				</Wrapper>
			);

			topBottomPerfMarketTableSection = (
				<ErrorBoundary>
					<Wrapper>
						<Datatable
							tableTitle='Top / Bottom Performing Markets Summary'
							tableHeader={topBottomPerformingMarketsTableHeader}
							tableData={topBottomPerformingMarketsTableData}
							tableLength={10}
							isFooterShow={true}
							tableDropdown={false}
							searchPlaceHolder={"Search Markets"}
							tableID={"top_bottom_table"}
							tableExportDropdown={
								<ExportDropdown
									moduleName={pageModule}
									platform={pagePlatform}
									subModuleName={pageSubModule}
									sectionId={"top_bottom_table"}
									sectionName={`TableDynaLabels_Top - Bottom Performing Table_${": " + topBottomPerformDataDropdownValue.label + " " + stateCodeLabel}`}
									sectionData={topBottomPerformingMarketsTableData}
								/>
							}
						/>
					</Wrapper>
				</ErrorBoundary>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (topBottomPerformingMarketsError) {
		topBottomPerfMarketSection = <Wrapper><ServerErrorsLoader chartType="bubbleChartType" error={topBottomPerformingMarketsError} /></Wrapper>

		topBottomPerfMarketTableSection = (
			<Wrapper>
				<div className="grid grid_cols_4 fullpage_section_card section_card">
					<div className="grid col_span_4">
						<div className="section_card_body">
							<ServerErrorsLoader chartType="tableChartType" error={topBottomPerformingMarketsError} />
						</div>
					</div>
				</div>
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (isTopBottomPerformingMarketsLoading) {
		topBottomPerfMarketSection = <Wrapper><Loader loaderType="bubbleChartLoader" /></Wrapper>;

		topBottomPerfMarketTableSection =
			(
				<Wrapper>
					<div className="grid grid_cols_4 fullpage_section_card section_card">
						<div className="grid col_span_4">
							<div className="section_card_body">
								<Loader loaderType="smallTableLoader" />
							</div>
						</div>
					</div>
				</Wrapper>
			);
	}


	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// EVENT FUNNEL

	// TO FETCH DATA FROM API
	useEffect(() => {
		const fetchEventFunnelHandler = async () => {
			setEventFunnelIsLoading(true);
			setEventFunnelIsLoaded(false);
			setEventFunnelError(null);

			var selectedVal =
				eventFunnelDropdownValue === undefined || eventFunnelDropdownValue === null ? null :
					eventFunnelDropdownValue.value === undefined || eventFunnelDropdownValue.value === null ? null : eventFunnelDropdownValue.value

			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: mapStateCode,
					dropdown_value: selectedVal
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get(
					"ri/market/event-funnel/",
					config
				);
				setEventFunnel(res?.data ?? []);
				setEventFunnelIsLoading(false);
				setEventFunnelIsLoaded(true);
				setEventFunnelError(null);
			} catch (error) {
				setEventFunnel([]);
				setEventFunnelIsLoading(false);
				setEventFunnelIsLoaded(false);
				setEventFunnelError(error.code);
			}
		};
		(eventFunnelDropdownOptionsLoaded === true) && fetchEventFunnelHandler()
	}, [
		authCtx,
		BRAND_ID,
		selectedDates,
		mapStateCode,
		eventFunnelDropdownValue,
		eventFunnelDropdownOptionsLoaded
	])

	// OVERVIEW METRICS CARDS SLIDER DATA HANDLING
	let eventFunnelChart = <Wrapper><Loader loaderType="funnelChartLoader" /></Wrapper>

	// IF DATA IS LOADED
	if (isEventFunnelIsLoaded && !isEventFunnelIsLoading) {
		if (NoDataAvailableChecker(eventFunnel) || eventFunnel.every((val, i, arr) => val.value === arr[0].value) === true) {
			eventFunnelChart = (
				<Wrapper>  <NoDataAvailableLoader chartType="funnelChartType" /></Wrapper>
			);
		} else {
			eventFunnelChart = (
				<Wrapper>
					<ErrorBoundary chartType="funnelChartType">
						<FunnelChart
							chartId="campaign_overview"
							chartClass="section_card_chart"
							chartData={eventFunnel}
						/>
					</ErrorBoundary>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (eventFunnelerror) {
		eventFunnelChart = (
			<Wrapper>
				<ServerErrorsLoader chartType="funnelChartType" error={eventFunnelerror} />
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (isEventFunnelIsLoading) {
		eventFunnelChart = <Wrapper>  <Loader loaderType="funnelChartLoader" /></Wrapper>;
	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	//   MARKET CAMPAIGN / CREATIVE
	useEffect(() => {
		const fetchMarketCampaignDeepdiveHandler = async () => {
			setMarketCampaignDeepdiveIsLoading(true);
			setMarketCampaignDeepdiveIsLoaded(false);
			setMarketCampaignDeepdiveError(null);

			const config = {
				params: treemapParams,
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/market/market-event-tree-map/", config);

				setMarketCampaignDeepdive(res?.data?.data ?? []);
				setMarketCampaignDeepdiveIsLoading(false);
				setMarketCampaignDeepdiveIsLoaded(true);
				setMarketCampaignDeepdiveError(null);
			} catch (error) {
				setMarketCampaignDeepdive([]);
				setMarketCampaignDeepdiveIsLoading(false);
				setMarketCampaignDeepdiveIsLoaded(false);
				setMarketCampaignDeepdiveError(error.code);
			}
		};
		Object.keys(treemapParams).length !== 0 && fetchMarketCampaignDeepdiveHandler();
	}, [authCtx, treemapParams]);


	let marketCampaignDeepdiveSection = (
		<Wrapper>
			<div className="section_card_chart_wrapper flex column">
				<Loader loaderType="treeChartLoader" />
			</div>
		</Wrapper>
	);

	// IF DATA IS AVAILABLE
	if (isMarketCampaignDeepdiveLoaded && !isMarketCampaignDeepdiveLoading) {
		if (NoDataAvailableChecker(marketCampaignDeepdive) || (marketCampaignDeepdive.length === 1 && marketCampaignDeepdive[0].cc_success_configurations === 0)) {
			marketCampaignDeepdiveSection = (
				<Wrapper>
					<div className="section_card_chart_wrapper flex column">
						<NoDataAvailableLoader chartType="treeChartType" />
					</div>
				</Wrapper>
			);
		} else {
			if (treemapLevel > 3) {
				marketCampaignDeepdiveSection = (
					<Wrapper>
						<div className="section_card_chart_wrapper flex column">
							<NoDataAvailableLoader chartType="treeChartType" />
						</div>
					</Wrapper>
				);
			} else {
				marketCampaignDeepdiveSection = (
					<Wrapper>
						<div className="section_card_chart_wrapper flex column">
							<ErrorBoundary chartType="treeChartType">
								<SimpleTreeMap
									chartId="simpleTreemaptest"
									chartClass="section_card_chart"
									chartData={marketCampaignDeepdive}
									levelHandler={chartlevelHandler}
									colorChangeParameter={"growth_percentage"}
									chartLabels={[
										{
											name: "name",
											value: "cc_success_configurations",
											toolTipvalue1: "cc_success_configurations",
											toolTipvalue1Label: "Visits with event",
											toolTipvalue2: "spends",
											toolTipvalue2Label: "Spends (TV + Digital)",
											toolTipvalue3: "impressions",
											toolTipvalue3Label: "Impressions (TV + Digital)",
											colorChangeParameter: "growth_percentage"
										},
									]}
									positiveNegativeNeutral={true}
								/>
							</ErrorBoundary>
						</div>
						{isMarketCampaignDeepdiveLoaded &&
							!isMarketCampaignDeepdiveLoading &&
							Object.keys(marketCampaignDeepdive).length !== 0 && (
								<div className="w_100">
									<ul className="icz-rilist-inline">
										<li>
											<div className="legend-circle circleGreen"></div> Visits Growth
										</li>
										<li>
											<div className="legend-circle circleOrange"></div> Stable Visits
										</li>
										<li>
											<div className="legend-circle circleRed"></div> Visits Decline
										</li>
									</ul>
								</div>
							)}
					</Wrapper>
				);
			}
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (marketCampaignDeepdiveerror) {
		marketCampaignDeepdiveSection = (
			<Wrapper>
				<div className="section_card_chart_wrapper flex column">
					<ServerErrorsLoader chartType="treeChartType" error={marketCampaignDeepdiveerror} />
				</div>
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (isMarketCampaignDeepdiveLoading) {
		marketCampaignDeepdiveSection = (
			<Wrapper>
				<div className="section_card_chart_wrapper flex column">
					<Loader loaderType="treeChartLoader" />
				</div>
			</Wrapper>
		);
	}


	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// OVERALL MEDIA PERFORMANCE

	useEffect(() => {
		const fetchOverallMediaPerformanceHandler = async () => {
			setOverallMediaPerformanceIsLoading(true);
			setOverallMediaPerformanceIsLoaded(false);
			setOverallMediaPerformanceError(null);

			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: mapStateCode,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/market/overall-media-performance/", config);


				setOverallMediaPerformance(res?.data?.chart_data?.data ?? [])
				setOverallMediaPerformanceLabel(res?.data?.chart_data?.dimension ?? [])
				setOverallMediaPerformanceTableData(res?.data?.table_data ?? [])

				setOverallMediaPerformanceIsLoading(false);
				setOverallMediaPerformanceIsLoaded(true);
				setOverallMediaPerformanceError(null);
			} catch (error) {
				setOverallMediaPerformance([]);
				setOverallMediaPerformanceLabel([]);
				setOverallMediaPerformanceIsLoading(false);
				setOverallMediaPerformanceIsLoaded(false);
				setOverallMediaPerformanceError(error.code);
			}
		};
		fetchOverallMediaPerformanceHandler()
	}, [
		authCtx,
		BRAND_ID,
		selectedDates,
		mapStateCode,
	])

	let overallMediaPerformanceSection = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>;
	let overallMediaPerformanceTableSection = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>;

	// IF DATA IS LOADED
	if (isOverallMediaPerformanceLoaded && !isOverallMediaPerformanceLoading) {
		if (NoDataAvailableChecker(overallMediaPerformance) || NoDataAvailableChecker(overallMediaPerformanceLabel)) {
			overallMediaPerformanceSection = <Wrapper><NoDataAvailableLoader chartType="multiAxisMultiLineChartLoader" /></Wrapper>
			overallMediaPerformanceTableSection = <Wrapper><NoDataAvailableLoader chartType="tableChartType" /></Wrapper>;
		} else {
			overallMediaPerformanceSection = (
				<Wrapper>
					<ErrorBoundary chartType="barChartType">
						<StackedClusteredTwoBarChart
							chartClass="section_card_chart"
							chartData={overallMediaPerformance}
							chartId="overallMediaPerformance"
							chartLabels={overallMediaPerformanceLabel}
							minGridDistance={20}
						/>
					</ErrorBoundary>
				</Wrapper>
			);

			overallMediaPerformanceTableSection = (
				<Wrapper>
					<ErrorBoundary chartType="tableChartType">
						<div className="main_wrapper">
							<div className="body">
								{overallMediaPerformanceTableData && overallMediaPerformanceTableData.map(
									(details, postImpressionIndex) => (
										<Wrapper key={postImpressionIndex}>
											<div className="row">
												<div className="title_wrapper">
													{details.label}
												</div>
												<div className="value_wrapper">
													<ErrorBoundary>
														<NumberFormatter number={details.value} title={details.label} />
													</ErrorBoundary>

												</div>
											</div>
										</Wrapper>
									))}
							</div>

						</div>
					</ErrorBoundary>
				</Wrapper>
			)

		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (overallMediaPerformanceerror) {
		overallMediaPerformanceSection = (
			<Wrapper>
				<ServerErrorsLoader
					chartType="barChartType"
					error={overallMediaPerformanceerror}
				/>
			</Wrapper>
		);
		overallMediaPerformanceTableSection = <Wrapper><ServerErrorsLoader loaderType="tableChartType" error={overallMediaPerformanceerror} /></Wrapper>;

	}

	// IF DATA LOADING
	if (isOverallMediaPerformanceLoading) {
		overallMediaPerformanceSection = (<Wrapper><Loader loaderType="barChartLoader" /></Wrapper>);
		overallMediaPerformanceTableSection = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>

	}


	function HandleTableView() {
		var sectionIndia = document.getElementById('market_compare_india')

		if (sectionIndia.classList.contains("hide_rows")) {
			setIsMarketTableHidden({ text: "View Less", class: "" })
		} else {
			setIsMarketTableHidden({ text: "View More", class: "hide_rows" })
		}
	}

	var brandsExport = (function () {
		if (selectedBrands === undefined || selectedBrands.length === 0) {
			return { "": "" }
		} else {
			var obj = {};
			for (var i = 0; i < selectedBrands.length; i++) {
				if (selectedBrands.length === 1) {
					obj["Brand"] = selectedBrands[i].label
				} else {
					obj["Brand " + (i + 1)] = selectedBrands[i].label
				}
			}
			return obj;
		}
	})()




	useEffect(() => {
		setexportSummary([
			selectedDates, brandsExport
		]);
	}, [selectedDates, selectedBrands]);

	var performanceTrendExport = {
		[`DateFirstNonActiveDropdownLabels_Performance Trend_${stateCodeLabel}`]: performanceTrend,
		[`TableDynaLabels_Performance Trend Table_${stateCodeLabel}`]: performanceTrendTable
	}

	var topBottomPerformingMarketsExport = {
		[`TopPerfMarketSummaryIDFC_Top - Bottom Performing Markets_${topBottomPerformDataDropdownValue.label + stateCodeLabel}`]: topBottomPerformingMarketsData

	}

	var marketCompareExport = {
		[`DateFirstNonActiveDropdownLabels_Market Compare 1 Chart_${" : " + (marketAllIndiaDropdownValue && marketAllIndiaDropdownValue.label)}`]: marketCompareAllIndia,
		[`TableDynaLabels_Market Compare 1 Table_${" : " + (marketAllIndiaDropdownValue && marketAllIndiaDropdownValue.label)}`]: marketCompareAllIndiaTable,
		[`DateFirstNonActiveDropdownLabels_Market Compare 2 Chart_${" : " + (marketStateDropdownValue && marketStateDropdownValue.label)}`]: marketCompareState,
		[`TableDynaLabels_Market Compare 2 Table_${" : " + (marketStateDropdownValue && marketStateDropdownValue.label)}`]: marketCompareStateTable
	}

	var overallMediaPerformanceExport = {
		[`DateFirstNonActiveDropdownLabels_Overall Media Performance_${stateCodeLabel}`]: overallMediaPerformance,
		[`TableDynaLabels_Overall Media Perf Table_${stateCodeLabel}`]: overallMediaPerformanceTableData
	}

	useEffect(() => {
		var allData = {
			Summary: exportSummary,
			"IdfcMarketIndiaChart_India Map": MapData.features,
			[`SummaryCardDynaLabels_Performance Metrics_${stateCodeLabel}`]: performanceMetricCards,
			...marketCompareExport,
			...performanceTrendExport,
			...topBottomPerformingMarketsExport,
			[`TableDynaLabels_Top - Bottom Performing Table_${": " + topBottomPerformDataDropdownValue.label + " " + stateCodeLabel}`]: topBottomPerformingMarketsTableData,
			[`IdfcMarketTreemap_Market Campaign Deepdive_${stateCodeLabel}`]: marketCampaignDeepdive,
			...overallMediaPerformanceExport,
		};
		setExportPageName("Market Summary");
		setFullPageExport(allData);
	}, [
		exportSummary,
		MapData,
		performanceMetricCards,
		performanceTrend,
		marketCompareAllIndia,
		marketCompareState,
		overallMediaPerformance,
		overallMediaPerformanceTableData,
		marketCampaignDeepdive,
		mapState,
	])


	useEffect(() => {
		if (
			(MapError !== null ? true : isMapLoaded && (isMapLoading === false)) &&
			(performanceMetricCardserror !== null ? true : isPerformanceMetricCardsLoaded && (isPerformanceMetricCardsLoading === false)) &&
			(performanceTrenderror !== null ? true : isPerformanceTrendLoaded && (isPerformanceTrendLoading === false)) &&
			(marketCompareAllIndiaerror !== null ? true : isMarketCompareAllIndiaLoaded && (isMarketCompareAllIndiaLoading === false)) &&
			(marketCompareStateerror !== null ? true : isMarketCompareStateLoaded && (isMarketCompareStateLoading === false)) &&
			(overallMediaPerformanceerror !== null ? true : isOverallMediaPerformanceLoaded && (isOverallMediaPerformanceLoading === false)) &&
			(marketCampaignDeepdiveerror !== null ? true : isMarketCampaignDeepdiveLoaded && (isMarketCampaignDeepdiveLoading === false))
		) {
			setIsExportDisabled(false)
		} else {
			setIsExportDisabled(true)
		}
	}, [
		MapError, isMapLoaded, isMapLoading,
		performanceMetricCardserror, isPerformanceMetricCardsLoaded, isPerformanceMetricCardsLoading,
		performanceTrenderror, isPerformanceTrendLoaded, isPerformanceTrendLoading,
		marketCompareAllIndiaerror, isMarketCompareAllIndiaLoaded, isMarketCompareAllIndiaLoading,
		marketCompareStateerror, isMarketCompareStateLoaded, isMarketCompareStateLoading,
		overallMediaPerformanceerror, isOverallMediaPerformanceLoaded, isOverallMediaPerformanceLoading,
		marketCampaignDeepdiveerror, isMarketCampaignDeepdiveLoaded, isMarketCampaignDeepdiveLoading,
	])

	useEffect(() => {

		setSearchParams({
			brands: selectedBrands.length === 0 ? "overall" : selectedBrands,
		});

	}, [selectedBrands])

	return (
		<Wrapper>
			<div className="grid drawer_grid">
				<div className="left_drawer col_span_1 fixed-left-grid mapRightWrapper" id="india_map">
					<div className="section_card">
						<div className="mapHeaderWrapper w_100">
							<div className="grid col_span_4">
								<div className="section_card_header mapHeader">
									<div className="section_info">
										<h2 className="section_card_title card_map_title">
											India
										</h2>
									</div>
									<div data-html2canvas-ignore={true}>
										<ExportDropdown
											sectionId={"india_map"}
											sectionName={"IdfcMarketIndiaChart_India Map"}
											sectionData={MapData.features}
										/>
									</div>
								</div>
							</div>
							<div className="grid col_span_4">
								<div className="section_card_header map_breadcrumb_header">
									{mapBreadCrumbList.map((item, index) => (
										<div
											onClick={() => {
												mapBreadcrumbsHandler(index, item);
											}}
											key={index}
										>
											{index === 0 ? " " : "/ "}
											<TextFormatter string={item} />
										</div>
									))}
								</div>
							</div>
						</div>

						<div className="section_card_body mapBoxContainer">
							<div className="grid grid_cols_4 grid_margin_bottom">
								<div className="grid col_span_4">
									<div className="section_card_body">
										<div className="section_card_map_wrapper">
											{IndiaMapChart}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="mapBoxContainer">
							<div className="w_100">
								<ul className="icz-rilist-inline">
									<li>
										<div className="legend-circle circleGreen"></div> Growth
									</li>
									<li>
										<div className="legend-circle circleRed"></div> Degrowth
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="right_side col_span_3 relative-right-grid">
					<div className="content">
						<div className="content_section">
							<PageHeader pageTitle={"Market"} />
							<div className="page_content">
								<div className="dashboard_home">
									{performanceMetricCardsSection}

									<div className="section_card section_dropdown_header">
										<div className="grid grid_cols_1">
											<div className="section_card_header">
												<h2 className="section_card_title">Market Comparison View</h2>
												<div data-html2canvas-ignore={true}>
													<ExportDropdown
														sectionId={"top_genra_grp_section"}
														sectionName={"Market Comparison"}
														sectionData={marketCompareExport}
													/>

												</div>
											</div>
										</div>
									</div>

									<div id="top_genra_grp_section" className="grid grid_cols_2 grid_margin_bottom">
										<div id="time_slot_grp_section" className="grid col_span_1 section_card h_auto adjust_new_line">
											<div className="section_card_header">
												<h2 className="section_card_title title_new_line">
													{marketAllIndiaDropdownValue && marketAllIndiaDropdownValue.label}
												</h2>
												<Modal
													open={isCompareAllIndiaOpen}
													setOpen={setIsCompareAllIndiaOpen}
													exportDropdown={
														<ExportDropdown
															sectionId={"marketCompareAllIndia_2"}
															sectionName={"Market Comparison"}
															sectionData={marketCompareExport}
														/>}
													parentId={"time_slot_grp_section"}
													dropdown={
														<Dropdown
															ismulti={false}
															placeholder={"Select Market"}
															options={marketComparisonDropdownOptions}
															className="form_dropdown section_dropdown pl_20"
															value={marketAllIndiaDropdownValue}
															selectedOptions={marketAllIndiaDropdownValue}
															dropdownLoading={marketComparisonDropdownOptionsLoading}
															setStatedropdown={marketAllIndiaDropdownHandler}
														/>
													}
													title={`${marketAllIndiaDropdownValue && marketAllIndiaDropdownValue.label}`}
													chart={marketCompareAllIndiaSection2}
												/>
												<div data-html2canvas-ignore={true}>
													{isMultiLineCluster && <button onClick={() => setIsCompareAllIndiaOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
														<ExpandIcon />
													</button>}
													<Dropdown
														ismulti={false}
														placeholder={"Select Market"}
														options={marketComparisonDropdownOptions}
														className="form_dropdown section_dropdown pl_20"
														value={marketAllIndiaDropdownValue}
														selectedOptions={marketAllIndiaDropdownValue}
														dropdownLoading={marketComparisonDropdownOptionsLoading}
														setStatedropdown={marketAllIndiaDropdownHandler}
													/>
												</div>
											</div>
											<div className="grid grid_cols_1 section_card_body">
												<div className="section_card_chart_wrapper">
													{marketCompareAllIndiaSection}
												</div>
												<div className='table_wrapper'>
													{marketCompareAllIndiaTableSection}
													{isMarketCompareAllIndiaLoaded && <div className='text_end'>
														<a className='market_compare_link' onClick={() => HandleTableView()}>{isMarketTableHidden.text}</a>
													</div>}
												</div>
											</div>
										</div>
										<div id="ad_duration_wise_grp_section" className="grid col_span_1 section_card adjust_new_line">
											<div className="section_card_header">
												<div className="section_info">
													<h2 className="section_card_title title_new_line">
														{marketStateDropdownValue && marketStateDropdownValue.label}
													</h2>
												</div>
												<Modal
													open={isCompareStateOpen}
													setOpen={setIsCompareStateOpen}
													exportDropdown={
														<ExportDropdown
															sectionId={"marketCompareState_2"}
															sectionName={"Market Comparison"}
															sectionData={marketCompareExport}
														/>}
													parentId={"ad_duration_wise_grp_section"}
													dropdown={
														<Dropdown
															ismulti={false}
															placeholder={"Select Market"}
															options={marketComparisonDropdownOptions}
															className="form_dropdown section_dropdown pl_20"
															value={marketStateDropdownValue}
															selectedOptions={marketStateDropdownValue}
															dropdownLoading={marketComparisonDropdownOptionsLoading}
															setStatedropdown={marketStateDropdownHandler}
														/>
													}
													title={`${marketStateDropdownValue && marketStateDropdownValue.label}`}
													chart={marketCompareStateSection2}
												/>
												<div data-html2canvas-ignore={true}>
													{isMultiLineCluster && <button onClick={() => setIsCompareStateOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
														<ExpandIcon />
													</button>}
													<Dropdown
														ismulti={false}
														placeholder={"Select Market"}
														options={marketComparisonDropdownOptions}
														className="form_dropdown section_dropdown pl_20"
														value={marketStateDropdownValue}
														selectedOptions={marketStateDropdownValue}
														dropdownLoading={marketComparisonDropdownOptionsLoading}
														setStatedropdown={marketStateDropdownHandler}
													/>

												</div>
											</div>
											<div className="grid grid_cols_1 section_card_body h_auto">
												<div className="section_card_chart_wrapper">
													{marketCompareStateSection}
												</div>
												<div className='table_wrapper'>
													{marketCompareStateTableSection}
													{isMarketCompareStateLoaded && <div className='text_end'>
														<a className='market_compare_link' onClick={() => HandleTableView()}>{isMarketTableHidden.text}</a>
													</div>}
												</div>

											</div>
										</div>
									</div>
									<div id="performance_trend" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
										<div className="grid col_span_4 adjust_new_line ">
											<div className="section_card_header perfTrendLine">
												<div className="section_info">
													<h2 className="section_card_title">
														Performance Trend
													</h2>
												</div>
												<Modal
													open={isPerformanceTrendOpen}
													setOpen={setIsPerformanceTrendOpen}
													exportDropdown={
														<ExportDropdown
															sectionId="performanceTrend_2"
															sectionName="Performance Trend"
															sectionData={performanceTrendExport}
														/>}
													dropdown={
														<>
															<Dropdown
																ismulti={false}
																isClearable={false}
																placeholder={"Select Trend"}
																options={perfTrendDropdownOptions}
																className="form_dropdown section_dropdown pl_20 mr_20"
																value={perfTrendFirstDropdownValue}
																selectedOptions={perfTrendFirstDropdownValue}
																setStatedropdown={perfTrendFirstDropdownHandler}
																dropdownLoading={perfTrendDropdownOptionsLoading}
															/>
															<Dropdown
																ismulti={false}
																isClearable={false}
																placeholder={"Select Trend"}
																options={perfTrendDropdownOptions}
																className="form_dropdown section_dropdown  pl_20"
																value={perfTrendSecondDropdownValue}
																selectedOptions={perfTrendSecondDropdownValue}
																setStatedropdown={perfTrendSecondDropdownHandler}
																dropdownLoading={perfTrendDropdownOptionsLoading}
															/>
															<Dropdown
																ismulti={false}
																isClearable={false}
																placeholder={"Select Trend"}
																options={perfTrendDropdownOptions}
																className="form_dropdown section_dropdown  pl_20"
																value={perfTrendThirdDropdownValue}
																selectedOptions={perfTrendThirdDropdownValue}
																setStatedropdown={perfTrendThirdDropdownHandler}
																dropdownLoading={perfTrendDropdownOptionsLoading}
															/>
														</>
													}
													parentId={"performance_trend"}
													title={`Performance Trend`}
													chart={performanceTrendSection2}
												/>
												<div data-html2canvas-ignore={true}>
													{isMultiLineCluster && <button onClick={() => setIsPerformanceTrendOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
														<ExpandIcon />
													</button>}
													<Dropdown
														ismulti={false}
														isClearable={false}
														placeholder={"Select Trend"}
														options={perfTrendDropdownOptions}
														className="form_dropdown section_dropdown pl_20"
														value={perfTrendFirstDropdownValue}
														selectedOptions={perfTrendFirstDropdownValue}
														setStatedropdown={perfTrendFirstDropdownHandler}
														dropdownLoading={perfTrendDropdownOptionsLoading}
													/>
													<Dropdown
														ismulti={false}
														isClearable={false}
														placeholder={"Select Trend"}
														options={perfTrendDropdownOptions}
														className="form_dropdown section_dropdown  pl_20"
														value={perfTrendSecondDropdownValue}
														selectedOptions={perfTrendSecondDropdownValue}
														setStatedropdown={perfTrendSecondDropdownHandler}
														dropdownLoading={perfTrendDropdownOptionsLoading}
													/>
													<Dropdown
														ismulti={false}
														isClearable={false}
														placeholder={"Select Trend"}
														options={perfTrendDropdownOptions}
														className="form_dropdown section_dropdown  pl_20"
														value={perfTrendThirdDropdownValue}
														selectedOptions={perfTrendThirdDropdownValue}
														setStatedropdown={perfTrendThirdDropdownHandler}
														dropdownLoading={perfTrendDropdownOptionsLoading}
													/>
													<ExportDropdown
														sectionId="performance_trend"
														sectionName="Performance Trend"
														sectionData={performanceTrendExport}
													/>
												</div>
											</div>
										</div>
										<div className="grid grid_cols_4">
											<div className="grid col_span_3">
												<div className="section_card_body">
													<div className="section_card_chart_wrapper">
														{performanceTrendSection}
													</div>
												</div>
											</div>
											<div className="grid col_span_1">
												<div className="section_card_body">
													<div className="chart_details_wrapper">
														{performanceTrendTableSection}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div id="Bottom-performance-market" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
										<div className="grid col_span_4">
											<div className="section_card_header">
												<div className="section_info">
													<h2 className="section_card_title">
														Top / Bottom Performing Markets
													</h2>
												</div>
												<div data-html2canvas-ignore={true}>
													<Dropdown
														ismulti={false}
														placeholder={"Select Market"}
														options={topBottomPerformDataDropdownOptions}
														className="form_dropdown section_dropdown pl_20"
														value={topBottomPerformDataDropdownValue}
														selectedOptions={topBottomPerformDataDropdownValue}
														dropdownLoading={topBottomPerformDataDropdownOptionsLoading}
														setStatedropdown={topBottomPerformDataDropdownHandler}
													/>
													<ExportDropdown
														sectionId="Bottom-performance-market"
														sectionName="Top - Bottom Performing Markets"
														sectionData={topBottomPerformingMarketsExport}
													/>
												</div>
											</div>
										</div>
										<div className="grid col_span_4">
											<div className="section_card_body">
												<div className="section_card_chart_wrapper flex column">
													{topBottomPerfMarketSection}
												</div>
												{isTopBottomPerformingMarketsLoaded &&
													!isTopBottomPerformingMarketsLoading &&
													Object.keys(topBottomPerformingMarketsData)
														.length !== 0 && (
														<div className="w_100">
															<ul className="icz-rilist-inline">
																<li>
																	<div className="legend-circle circleGreen"></div>{" "}
																	Growth
																</li>
																<li>
																	<div className="legend-circle circleRed"></div>{" "}
																	Degrowth
																</li>
															</ul>
														</div>
													)}
											</div>
										</div>
									</div>

									<div className="grid_margin_bottom">
										{topBottomPerfMarketTableSection}
									</div>

									<div id="event_funnel" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
										<div className="grid col_span_4">
											<div className="section_card_header">
												<div className="section_info">
													<h2 className="section_card_title">Event Funnel</h2>
												</div>
												<div data-html2canvas-ignore={true}>
													<Dropdown
														ismulti={false}
														placeholder={"Select Product"}
														options={eventFunnelDropdownOptions}
														className="form_dropdown section_dropdown pl_20"
														value={eventFunnelDropdownValue}
														selectedOptions={eventFunnelDropdownValue}
														setStatedropdown={eventFunnelDropdownHandler}
														dropdownLoading={eventFunnelDropdownOptionsLoading}
													/>
													<ExportDropdown
														sectionId={"event_funnel"}
														sectionName={`TableDynaLabels_Event Funnel_${eventFunnelLabel + stateCodeLabel}`}
														sectionData={eventFunnel}
													/>
												</div>
											</div>
										</div>
										<div className="grid col_span_4">
											<div className="section_card_body">
												<div className="section_card_chart_wrapper">
													{eventFunnelChart}
												</div>
											</div>
										</div>
									</div>

									<div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
										<div className="grid col_span_4">
											<div className="section_card_header p_b_0 ">
												<div className="flex column align_start">
													<div className="section_info">
														<h2 className="section_card_title">
															Market Wise Product Event Success
														</h2>
													</div>
													{(
														<div className="treemap_breadcrumb">
															<span
																onClick={() => {
																	setTreemapParams({
																		brand_id: BRAND_ID,
																		start_date: selectedDates.startDate,
																		end_date: selectedDates.endDate,
																		previous_start_date: selectedDates.prevStartDate,
																		previous_end_date: selectedDates.prevEndDate,
																		state_code: mapStateCode === undefined || mapStateCode === null ? null : mapStateCode,
																		level: 1,
																	});
																	setTreemapStateCode(null)
																	setTreemapStateName('')
																}}
															>
																Home
															</span>
															{treemapStateName && (
																<span
																	onClick={() => {
																		setTreemapParams({
																			brand_id: BRAND_ID,
																			start_date: selectedDates.startDate,
																			end_date: selectedDates.endDate,
																			previous_start_date: selectedDates.prevStartDate,
																			previous_end_date: selectedDates.prevEndDate,
																			state_code: mapStateCode === undefined || mapStateCode === null ? treemapStateCode : mapStateCode,
																			level: 2,
																		});
																	}}
																>
																	{" "}/ <TextFormatter string={treemapStateName} />
																</span>
															)}
														</div>
													)}
												</div>

												<div data-html2canvas-ignore={true}>
													<ExportDropdown
														sectionId={"brandwise_performance_section"}
														sectionName={`IdfcMarketTreemap_Market Campaign Deepdive_${stateCodeLabel}`}
														sectionData={marketCampaignDeepdive}
													/>
												</div>
											</div>
										</div>
										<div className="grid col_span_4">
											<div className="section_card_body">
												{marketCampaignDeepdiveSection}
											</div>
										</div>
									</div>

									<div id="overall_media_performance" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
										<div className="grid col_span_4">
											<div className="section_card_header">
												<div className="section_info">
													<h2 className="section_card_title">
														Overall Media Performance
													</h2>
												</div>
												<div data-html2canvas-ignore={true}>
													<ExportDropdown
														sectionId="overall_media_performance"
														sectionName="Overall Media Performance"
														sectionData={overallMediaPerformanceExport}
													/>
												</div>
											</div>
										</div>
										<div className="grid grid_cols_4">
											<div className="grid col_span_3">
												<div className="section_card_body">
													<div className="section_card_chart_wrapper">
														{overallMediaPerformanceSection}
													</div>
												</div>
											</div>
											<div className="grid col_span_1">
												<div className="section_card_body">
													<div className="chart_details_wrapper">
														{overallMediaPerformanceTableSection}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	)
}
