import { useEffect, useRef, useState } from "react";

import Wrapper from "../helpers/Wrapper";
import {
  AddressLineCharLimit,
  CityCharLimit,
  LandmarkCharLimit,
  StateCharLimit,
} from "../../constants/inputLimit";
import {
  API_URL,
  InvalidNameMsg,
  MaxLimitReachedInputMsg,
  OnlyCharRegex,
  RequiredField,
} from "../../constants/constants";
import Dropdown from "../ui/dropdown/Dropdown";

const RegistrationCompanyLocationForm = ({
  formData,
  setFormData,
  formValidation,
  setFormValidation,
  formStepsData,
  setFormStepsData,
}) => {
  const companyAddressLineOneInputRef = useRef();
  const companyAddressLineTwoInputRef = useRef();
  const companyLandmarkInputRef = useRef();
  const companyStateInputRef = useRef();
  const companyCityInputRef = useRef();

  const [companyTimezoneIsTouched, setCompanyTimezoneIsTouched] =
    useState(false);
  const [companyTimezoneSelectedState, setCompanyTimezoneSelectedState] =
    useState();
  const [companyTimezoneOptions, setCompanyTimezoneOptions] = useState([]);
  const [companyTimezoneIsValid, setCompanyTimezoneIsValid] = useState(true);
  const [companyTimezonError, setCompanyTimezoneError] = useState();

  const [companyAddressLineOneIsTouched, setCompanyAddressLineOneIsTouched] =
    useState(false);
  const [companyAddressLineOneIsValid, setCompanyAddressLineOneIsValid] =
    useState(false);
  const [companyAddressLineOneError, setCompanyAddressLineOneError] =
    useState();

  const [companyAddressLineTwoIsTouched, setCompanyAddressLineTwoIsTouched] =
    useState(false);
  const [companyAddressLineTwoIsValid, setCompanyAddressLineTwoIsValid] =
    useState(false);
  const [companyAddressLineTwoError, setCompanyAddressLineTwoError] =
    useState();

  const [companyLandmarkIsTouched, setCompanyLandmarkIsTouched] =
    useState(false);
  const [companyLandmarkIsValid, setCompanyLandmarkIsValid] = useState(false);
  const [companyLandmarkError, setCompanyLandmarkError] = useState();

  const [companyStateIsTouched, setCompanyStateIsTouched] = useState(false);
  const [companyStateIsValid, setCompanyStateIsValid] = useState(false);
  const [companyStateError, setCompanyStateError] = useState();

  const [companyCityIsTouched, setCompanyCityIsTouched] = useState(false);
  const [companyCityIsValid, setCompanyCityIsValid] = useState(false);
  const [companyCityError, setCompanyCityError] = useState();

  const [currentSelectedCountry, setCurrentSelectedCountry] = useState(
    formData.companyCountryLabel
  );
  const [companyCountryOptions, setCompanyCountryOptions] = useState([]);

  // TIMEZONE
  const CompanyTimezoneChangeHandler = (event) => {
    setCompanyTimezoneIsTouched(true);
    setCompanyTimezoneSelectedState(event);
    setFormValidation({ ...formValidation, companyTimezone: true });
    setFormData({
      ...formData,
      companyTimezone: event === null ? "" : event.value,
    });
    if (event === null) {
      setCompanyTimezoneError(RequiredField);
      setCompanyTimezoneIsValid(false);
      setFormValidation({ ...formValidation, companyTimezone: false });
    } else {
      setCompanyTimezoneIsValid(true);
      setCompanyTimezoneError();
      setFormValidation({ ...formValidation, companyTimezone: true });
    }
  };
  const dropdownOnBlurHandler = () => {
    if (formData.companyTimezone === "") {
      setCompanyTimezoneError(RequiredField);
      setCompanyTimezoneIsValid(false);
      setCompanyTimezoneIsTouched(true);
      setFormValidation({ ...formValidation, companyTimezone: false });
    } else {
      setCompanyTimezoneIsValid(true);
      setCompanyTimezoneError();
      setCompanyTimezoneIsTouched(true);
      setFormValidation({ ...formValidation, companyTimezone: true });
    }
  };

  // ADDRESS LINE 1
  const companyAddressLineOneChangeHandler = () => {
    setFormData({
      ...formData,
      companyAddressLine1: companyAddressLineOneInputRef.current.value,
    });
  };
  const validateCompanyAddressLineOneHandler = () => {
    if (
      companyAddressLineOneInputRef.current.value.length >= AddressLineCharLimit
    ) {
      setCompanyAddressLineOneIsTouched(true);
      setCompanyAddressLineOneError();
      setCompanyAddressLineOneIsValid(true);
    }
  };

  // ADDRESS LINE 2
  const companyAddressLineTwoChangeHandler = () => {
    setFormData({
      ...formData,
      companyAddressLine2: companyAddressLineTwoInputRef.current.value,
    });
  };
  const validateCompanyAddressLineTwoHandler = () => {
    if (
      companyAddressLineTwoInputRef.current.value.length >= AddressLineCharLimit
    ) {
      setCompanyAddressLineTwoIsTouched(true);
      setCompanyAddressLineTwoError();
      setCompanyAddressLineTwoIsValid(true);
    }
  };

  // LANDMARK
  const companyLandmarkChangeHandler = () => {
    setCompanyLandmarkError();
    setCompanyLandmarkIsTouched(true);
    setCompanyLandmarkIsValid(true);
    setFormData({
      ...formData,
      companyLandmark: companyLandmarkInputRef.current.value,
    });
    if (companyLandmarkInputRef.current.value.length >= LandmarkCharLimit) {
      setCompanyLandmarkError(MaxLimitReachedInputMsg + LandmarkCharLimit);
      setCompanyLandmarkIsValid(false);
    }
  };
  const validateCompanyLandmarkChangeHandler = () => {
    if (companyLandmarkInputRef.current.value.length >= LandmarkCharLimit) {
      setCompanyLandmarkError();
      setCompanyLandmarkIsTouched(true);
      setCompanyLandmarkIsValid(true);
    }
  };

  // COUNTRY
  const companyCountryChangeHandler = (event) => {
    setFormData({
      ...formData,
      companyCountry: event === null ? "" : event.value,
      companyCountryLabel: event === null ? "" : event.label,
    });
    setCurrentSelectedCountry(event === null ? "" : event.label);
  };

  // STATE
  const companyStateChangeHandler = () => {
    setCompanyStateIsTouched(true);
    if (
      companyStateInputRef.current.value === "" ||
      OnlyCharRegex.test(companyStateInputRef.current.value)
    ) {
      setCompanyStateError();
      setCompanyStateIsValid(true);
      setFormData({
        ...formData,
        companyState: companyStateInputRef.current.value,
      });
      if (companyStateInputRef.current.value.length >= StateCharLimit) {
        setCompanyStateError(MaxLimitReachedInputMsg + StateCharLimit);
        setCompanyStateIsValid(false);
      }
    } else {
      setCompanyStateError(InvalidNameMsg);
      setCompanyStateIsValid(false);
    }
  };
  const validateCompanyStateHandler = () => {
    if (companyStateInputRef.current.value.length >= StateCharLimit) {
      setCompanyStateError();
      setCompanyStateIsTouched(true);
      setCompanyStateIsValid(true);
    }

    if (companyStateInputRef.current.value === "") {
      setCompanyStateError();
      setCompanyStateIsTouched(true);
      setCompanyStateIsValid(true);
    }

    if (companyStateInputRef.current.value !== "") {
      setCompanyStateError();
      setCompanyStateIsTouched(true);
      setCompanyStateIsValid(true);
    }
  };

  // CITY
  const companyCityChangeHandler = () => {
    setCompanyCityIsTouched(true);
    if (
      companyCityInputRef.current.value === "" ||
      OnlyCharRegex.test(companyCityInputRef.current.value)
    ) {
      setCompanyCityError();
      setCompanyCityIsValid(true);
      setFormData({
        ...formData,
        companyCity: companyCityInputRef.current.value,
      });
      if (companyCityInputRef.current.value.length >= CityCharLimit) {
        setCompanyCityError(MaxLimitReachedInputMsg + CityCharLimit);
        setCompanyCityIsValid(false);
      }
    } else {
      setCompanyCityError(InvalidNameMsg);
      setCompanyCityIsValid(false);
    }
  };
  const validateCompanyCityHandler = () => {
    if (companyCityInputRef.current.value.length >= CityCharLimit) {
      setCompanyCityError();
      setCompanyCityIsTouched(true);
      setCompanyCityIsValid(true);
    }

    if (companyCityInputRef.current.value === "") {
      setCompanyCityError();
      setCompanyCityIsTouched(true);
      setCompanyCityIsValid(true);
    }

    if (companyCityInputRef.current.value !== "") {
      setCompanyCityError();
      setCompanyCityIsTouched(true);
      setCompanyCityIsValid(true);
    }
  };

  const companyTimezoneInputIsInvalid =
    !companyTimezoneIsValid && companyTimezoneIsTouched;
  const companytimezoneInputClasses = companyTimezoneInputIsInvalid
    ? "form_dropdown invalid"
    : "form_dropdown";

  const companyAddressLineOneInputIsInvalid =
    !companyAddressLineOneIsValid && companyAddressLineOneIsTouched;
  const addressLineOneInputClasses = companyAddressLineOneInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  const companyAddressLineTwoInputIsInvalid =
    !companyAddressLineTwoIsValid && companyAddressLineTwoIsTouched;
  const addressLineTwoInputClasses = companyAddressLineTwoInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  const companyLandmarkInputIsInvalid =
    !companyLandmarkIsValid && companyLandmarkIsTouched;
  const companyLandmarkInputClasses = companyLandmarkInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  const companyStateInputIsInvalid =
    !companyStateIsValid && companyStateIsTouched;
  const companyStateInputClasses = companyStateInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  const companyCityInputIsInvalid = !companyCityIsValid && companyCityIsTouched;
  const companyCityInputClasses = companyCityInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  // FOR GETTING THE TIMEZONE OPTIONS
  useEffect(() => {
    fetch(API_URL + "master/timezone", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((result) => {
        let data_list = [];
        result["timezones"].map((timezone, index) => {
          try {
            let a = {
              label: timezone,
              value: timezone,
            };
            data_list.push(a);
          } catch (e) { }
        });
        setCompanyTimezoneOptions(data_list);
      });
  }, []);

  // FOR GETTING THE COUNTRY LISTS
  useEffect(() => {
    fetch(API_URL + "master/country/", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((result) => {
        let data_list = [];
        result.map((country, index) => {
          try {
            let a = {
              label: country.country_name,
              value: country.id,
            };
            data_list.push(a);
          } catch (e) { }
        });
        setCompanyCountryOptions(data_list);
      });
  }, []);

  // FOR CHECKING THE FORM VALIDATION
  useEffect(() => {
    if (formValidation.companyTimezone === true) {
      setFormStepsData({ ...formStepsData, fourthStepIsValid: true });
    } else {
      setFormStepsData({ ...formStepsData, fourthStepIsValid: false });
    }
  }, [formValidation]);

  return (
    <Wrapper>
      <div className="form_group">
        <Dropdown
          className={companytimezoneInputClasses}
          placeholder="*Please Select Your Company Timezone"
          options={companyTimezoneOptions}
          value={companyTimezoneSelectedState}
          selectedOptions={
            formData.companyTimezone !== ""
              ? {
                value: formData.companyTimezone,
                label: formData.companyTimezone,
              }
              : ""
          }
          setStatedropdown={CompanyTimezoneChangeHandler}
          onBlur={dropdownOnBlurHandler}
        />
        <div className="error_section">
          {companyTimezoneInputIsInvalid && (
            <h6 className="error_text">{companyTimezonError}</h6>
          )}
        </div>
      </div>
      <div className="form_group">
        <input
          className={addressLineOneInputClasses}
          type="text"
          name="companyAddressLine1"
          id="companyAddressLine1"
          autoComplete="off"
          maxLength={AddressLineCharLimit}
          placeholder="Please Enter Your Address Line 1"
          value={formData.companyAddressLine1}
          onChange={companyAddressLineOneChangeHandler}
          onBlur={validateCompanyAddressLineOneHandler}
          ref={companyAddressLineOneInputRef}
        />
        <div className="error_section">
          {companyAddressLineOneInputIsInvalid && (
            <h6 className="error_text">{companyAddressLineOneError}</h6>
          )}
        </div>
      </div>
      <div className="form_group">
        <input
          className={addressLineTwoInputClasses}
          type="text"
          name="companyAddressLine2"
          id="companyAddressLine2"
          autoComplete="off"
          maxLength={AddressLineCharLimit}
          placeholder="Please Enter Your Address Line 2"
          value={formData.companyAddressLine2}
          onChange={companyAddressLineTwoChangeHandler}
          onBlur={validateCompanyAddressLineTwoHandler}
          ref={companyAddressLineTwoInputRef}
        />
        <div className="error_section">
          {companyAddressLineTwoInputIsInvalid && (
            <h6 className="error_text">{companyAddressLineTwoError}</h6>
          )}
        </div>
      </div>
      <div className="form_group">
        <input
          className={companyLandmarkInputClasses}
          type="text"
          name="companyLandmark"
          id="companyLandmark"
          autoComplete="off"
          maxLength={LandmarkCharLimit}
          placeholder="Please Enter Your Company Landmark"
          value={formData.companyLandmark}
          onChange={companyLandmarkChangeHandler}
          onBlur={validateCompanyLandmarkChangeHandler}
          ref={companyLandmarkInputRef}
        />
        <div className="error_section">
          {companyLandmarkInputIsInvalid && (
            <h6 className="error_text">{companyLandmarkError}</h6>
          )}
        </div>
      </div>
      <div className="form_group">
        <Dropdown
          className="form_dropdown"
          placeholder="Please Select Your Company Country"
          options={companyCountryOptions}
          value={currentSelectedCountry}
          selectedOptions={
            formData.companyCountry !== ""
              ? {
                value: formData.companyCountry,
                label: currentSelectedCountry,
              }
              : ""
          }
          setStatedropdown={companyCountryChangeHandler}
        />
      </div>
      <div className="form_group">
        <input
          className={companyStateInputClasses}
          type="text"
          name="companyState"
          id="companyState"
          autoComplete="off"
          maxLength={StateCharLimit}
          placeholder="Please Enter Your Company State"
          value={formData.companyState}
          onChange={companyStateChangeHandler}
          onBlur={validateCompanyStateHandler}
          ref={companyStateInputRef}
        />
        <div className="error_section">
          {companyStateInputIsInvalid && (
            <h6 className="error_text">{companyStateError}</h6>
          )}
        </div>
      </div>
      <div className="form_group">
        <input
          className={companyCityInputClasses}
          type="text"
          name="companyCity"
          id="companyCity"
          autoComplete="off"
          maxLength={CityCharLimit}
          placeholder="Please Enter Your Company City"
          value={formData.companyCity}
          onChange={companyCityChangeHandler}
          onBlur={validateCompanyCityHandler}
          ref={companyCityInputRef}
        />
        <div className="error_section">
          {companyCityInputIsInvalid && (
            <h6 className="error_text">{companyCityError}</h6>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default RegistrationCompanyLocationForm;
