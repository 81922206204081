const ExportIcon = (props) => {
    return (
        <svg className={props.className} width="6" height="24" viewBox="0 0 6 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="3" cy="3" r="3" />
            <circle cx="3" cy="12" r="3" />
            <circle cx="3" cy="21" r="3" />
        </svg>
    );
};

export default ExportIcon;
