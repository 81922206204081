import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

// STORAGE
import AuthContext from "../../../store/authContext";
import FullPageExportContext from "../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../components/helpers/axios";
import Wrapper from "../../../components/helpers/Wrapper";
import PageHeader from "../../../components/layout/pageHeader";
import Dropdown from "../../../components/ui/dropdown/Dropdown";
import Tab from "../../../components/ui/Tab";

// LOADERS
// import NoDataAvailableLoader from "../../../components/ui/loaders/NoDataAvailable";
import Loader from "../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../components/ui/loaders/ServerErrosLoader";


// PAGES
import WebsiteBehaviour from "./Behaviour";
import WebsiteEcom from "./Ecom";
import WebsiteEvents from "./Events";
import WebsiteLandingPage from "./LandingPage";
import WebsiteLocation from "./location/Location";
import WebsiteOverview from "./Overview";
import WebsiteTechnology from "./technology/Technology";
import WebsiteTraffic from "./traffic/Traffic";

// UTILS
// import NoDataAvailableChecker from "../../../utils/NoDataAvailableChecker";
import { IAEvent_Primary_Tab_Visit } from "../../../utils/IAEvents";
import { COKE_COMPANY_ID, IMPRIVATA_COMPANY_ID } from "../../../constants/constants";
import SearchConsole from "./SearchConsole";
import { useParams, useSearchParams } from "react-router-dom";
import EventTracker from "../../../components/eventsTracking/EventTracker";

const Website = () => {
  const authCtx = useContext(AuthContext);
  const { setExportPageName, setexportSummary } = useContext(FullPageExportContext);

  const params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();

  const userData = useSelector((state) => state.userData.user[0]);

  let companyID = userData?.company?.id ?? ""

  // BRANDS LIST FROM DROPDOWN
  const brandsDropdownOptionsList = useSelector(
    (state) => state.globalSelectedBrandIds.brandOptions
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null
  } else {
    BRAND_ID = globalSelectedBrandIds.join(',')
  }

  const selectedDates = useSelector((state) => state.date);

  let default_tabs = [
    "Overview",
    "Traffic",
    "Behaviour",
    "Technology",
    "Location",
    "Landing Page",
    "Events",
    "Ecom",
    'Search Console'
  ]

  let coke_tabs = [
    "Overview",
    "Traffic",
    "Technology",
    "Location",
    "Landing Page",
    "Events",
    "Ecom",
    'Search Console'
  ]

  let imprivata_tabs = [
    "Overview",
    "Traffic",
    "Technology",
    "Location",
    "Landing Page",
    "Events",
    'Search Console'
  ]


  const [tabData, setTabData] = useState(default_tabs)

  useEffect(() => {
    if (companyID === IMPRIVATA_COMPANY_ID) {
      setTabData(imprivata_tabs)
    } else if (companyID === COKE_COMPANY_ID) {
      setTabData(coke_tabs)
    } else {
      setTabData(default_tabs)
    }
  }, [companyID])




  const [selectedTab, setSelectedTab] = useState(tabData[0]);


  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Website", null, event)
  };

  const [selectedViewId, setSelectedViewId] = useState([]);

  const [viewIdOptions, setViewIdOptions] = useState([]);
  const [isViewIdOptionsLoading, setViewIdOptionsIsLoading] = useState(false);
  // const [isViewIdOptionsLoaded, setViewIdOptionsIsLoaded] = useState(false);
  const [viewIdOptionsError, setPlaformCardsError] = useState(null);

  // --------------------------------
  // --------------------------------
  // --------------------------------
  // --------------------------------
  // --------------------------------
  //  VIEW ID
  // const fetchViewIdDropdownOptions = async () => {
  //   setViewIdOptionsIsLoading(true);
  //   setViewIdOptionsIsLoaded(false);
  //   setPlaformCardsError(null);

  //   const config = {
  //     params: {
  //       start_date: selectedDates.startDate,
  //       end_date: selectedDates.endDate,
  //       previous_start_date: selectedDates.prevStartDate,
  //       previous_end_date: selectedDates.prevEndDate,
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + authCtx.token,
  //     },
  //   };
  //   try {
  //     const res = await axios.get("website/ga-view-ids/", config);

  //     let viewIds = []

  //     // CREATING AN ARRAY TO STORE ALL VIEW IDS OF BRANDS FROM BRANDS DROPDOWN
  //     globalSelectedBrandIds.map((brand, index) => (
  //       viewIds.push(
  //         res.data === undefined || res.data.length === 0 ? [] :
  //           res.data[brand])
  //     ))

  //     // VIEW IDS OF FIRST BRAND FROM BRANDS DROPDOWN
  //     let defaultViewId = [];

  //     // DEFAULT FIRST VIEW ID OF FIRST BRAND SELECTED FROM BRANDS DROPDOWN
  //     defaultViewId.push(res.data[brandsDropdownOptionsList[0].value][0])

  //     let defaultGlobalBrandsViewIds = [] // DEFAULT FIRST BRAND SELECTED IDS
  //     let selectedGlobalBrandsViewIds = [] // VIEW IDS OF SELECTED BRANDS FROM BRANDS DROPDOWN

  //     globalSelectedBrandIds === undefined || globalSelectedBrandIds.length === 0 ?
  //       // TO CREATE AN ARRAY OF VIEW IDS OF FIRST BRAND FROM BRANDS DROPDOWN
  //       (
  //         defaultViewId.map((defaultBrandViewId, defaultBrandViewIdIndex) => (
  //           defaultGlobalBrandsViewIds.push(defaultBrandViewId),
  //           setViewIdOptions(defaultGlobalBrandsViewIds)
  //         ))
  //       )
  //       :
  //       // TO CREATE AN ARRAY OF VIEW IDS OF SELECTED BRANDS FROM BRANDS DROPDOWN
  //       viewIds.map((brandViewId, brandViewIdIndex) => (
  //         brandViewId.length === 0 ?
  //           // TO UPDATE THE ARRAY IF BRAND HAS NO VIEW IDS
  //           setViewIdOptions(viewIdOptions) :
  //           // TO UPDATE THE ARRAY IF BRAND HAS VIEW IDS
  //           brandViewId.map((brandArray, brandArrayIndex) => (
  //             selectedGlobalBrandsViewIds.push(brandArray),
  //             setViewIdOptions(selectedGlobalBrandsViewIds)
  //           ))
  //       ))

  //     // SET THE DEFAULT VIEW ID
  //     globalSelectedBrandIds === undefined || globalSelectedBrandIds.length === 0 ?
  //       setSelectedViewId(
  //         defaultViewId === undefined || defaultViewId.length === 0 ? [] :
  //           defaultViewId[0] === undefined || defaultViewId[0].length === 0 ? [] :
  //             defaultViewId[0]
  //       )
  //       :
  //       setSelectedViewId(
  //         viewIds === undefined || viewIds.length === 0 ? [] :
  //           viewIds[0] === undefined || viewIds[0].length === 0 ? [] :
  //             viewIds[0][0] === undefined || viewIds[0][0].length === 0 ? [] :
  //               viewIds[0][0]
  //       )
  //     setViewIdOptionsIsLoading(false);
  //     setViewIdOptionsIsLoaded(true);
  //     setPlaformCardsError(null);
  //   } catch (error) {
  //     setViewIdOptions([]);
  //     setViewIdOptionsIsLoading(false);
  //     setViewIdOptionsIsLoaded(false);
  //     setPlaformCardsError(error.code);
  //   }
  // };

  const fetchViewIdDropdownOptions = async () => {
    setViewIdOptionsIsLoading(true);
    // setViewIdOptionsIsLoaded(false);
    setPlaformCardsError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };
    try {
      const res = await axios.get("website/ga-view-ids/", config);

      let viewIds = []

      globalSelectedBrandIds.map((brand, index) => (
        viewIds.push(res?.data[brand] ?? [])))

      let selectedGlobalBrandsViewIds = []

      viewIds.map((brandViewId) => (
        brandViewId.length === 0 ?
          // TO UPDATE THE ARRAY IF BRAND HAS NO VIEW IDS
          setViewIdOptions([]) :
          // TO UPDATE THE ARRAY IF BRAND HAS VIEW IDS
          brandViewId.map((brandArray) => (
            selectedGlobalBrandsViewIds.push(brandArray),
            setViewIdOptions(selectedGlobalBrandsViewIds)
          ))
      ))

      setSelectedViewId(
        viewIds === undefined || viewIds.length === 0 ? [] :
          viewIds[0] === undefined || viewIds[0].length === 0 ? [] :
            viewIds[0][0] === undefined || viewIds[0][0].length === 0 ? [] :
              viewIds[0][0]
      )
      setViewIdOptionsIsLoading(false);
      // setViewIdOptionsIsLoaded(true);
      setPlaformCardsError(null);
    } catch (error) {
      setViewIdOptions([]);
      setViewIdOptionsIsLoading(false);
      // setViewIdOptionsIsLoaded(false);
      setPlaformCardsError(error.code);
    }
  };

  let websiteModuleTabs = <Wrapper><Loader loaderType="cardLoader" /></Wrapper>

  websiteModuleTabs =
    <Wrapper>
      {selectedTab.toLowerCase() === "overview" && <WebsiteOverview viewId={selectedViewId.value} />}
      {selectedTab.toLowerCase() === "traffic" && <WebsiteTraffic viewId={selectedViewId.value} />}
      {selectedTab.toLowerCase() === "behaviour" && <WebsiteBehaviour viewId={selectedViewId.value} />}
      {selectedTab.toLowerCase() === "technology" && <WebsiteTechnology viewId={selectedViewId.value} />}
      {selectedTab.toLowerCase() === "location" && <WebsiteLocation viewId={selectedViewId.value} />}
      {selectedTab.toLowerCase() === "landing page" && <WebsiteLandingPage viewId={selectedViewId.value} />}
      {selectedTab.toLowerCase() === "events" && <WebsiteEvents viewId={selectedViewId.value} />}
      {selectedTab.toLowerCase() === "ecom" && <WebsiteEcom viewId={selectedViewId.value} />}
      {selectedTab.toLowerCase() === "search console" && <SearchConsole viewId={selectedViewId.value} />}
      {selectedTab.toLowerCase() === "Real Time" && "No Data Available"}
    </Wrapper>

  if (viewIdOptionsError) {
    websiteModuleTabs =
      <Wrapper>
        <ServerErrorsLoader error={viewIdOptionsError} />
      </Wrapper>
  }

  if (isViewIdOptionsLoading) {
    websiteModuleTabs =
      <Wrapper>
        <Loader loaderType="cardLoader" />
      </Wrapper>
  }

  const viewIdDropdownHandler = (e) => {
    if (e === null) {
      setSelectedViewId("");
    } else {
      setSelectedViewId(e);
    }

    EventTracker({
      eventType: "dropdown_selected",
      module: "Marketing Intelligence",
      subModule: "Website",
      section: "View ID",
      dropdownValue: e === null ? "Overall" : e.label,
      ...(BRAND_ID && { brands: BRAND_ID }),
      date: {
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate,
        previousStartDate: selectedDates.prevStartDate,
        previousEndDate: selectedDates.prevEndDate,
      }
    })

  };

  useEffect(() => {
    setexportSummary([
      selectedDates,
      selectedViewId,
      {
        "Brand ID": BRAND_ID,
      },
    ]);
    fetchViewIdDropdownOptions();
    if (BRAND_ID === null) {
      setSelectedViewId("");
      setViewIdOptions([]);
    }
  }, [BRAND_ID]);

  useEffect(() => {
    setExportPageName("Website " + selectedTab)
  }, [selectedTab])


  useEffect(() => {
    setSearchParams({
      brands: globalSelectedBrandIds.length === 0 ? "overall" : globalSelectedBrandIds,

    })
  }, [globalSelectedBrandIds])


  return (
    <Wrapper>
      <div className="content">
        <div className="content_section">
          <div className="flex justify_between align_center">
            <PageHeader pageTitle={"Website"} />
            <div className="p_20 w_100 flex justify_end">
              <Dropdown
                ismulti={false}
                isClearable={false}
                placeholder={"Select View Id"}
                options={viewIdOptions}
                value={selectedViewId}
                selectedOptions={selectedViewId}
                setStatedropdown={viewIdDropdownHandler}
                dropdownLoading={isViewIdOptionsLoading}
                className="form_dropdown section_dropdown"
              />
            </div>
          </div>
          <div className="page_content">
            <div className="dashboard_home">
              <div className="grid grid_margin_bottom">
                <Tab
                  varient={"primary"}
                  tabData={tabData}
                  activeTab={selectedTab}
                  handleCallback={(event) => handleCallback(event)}
                />
              </div>
              {websiteModuleTabs}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Website;
