const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  selectedCategories: [],
  selectedBrandIds: []
};

const riSalesBrandsSlice = createSlice({
  name: "riSalesBrands",
  initialState,
  reducers: {
    setSelectedCategoriesFromBrandsCategoryDropdown(state, action) {
      state.selectedCategories = action.payload;
    },
    setSelectedBrandsFromBrandsCategoryDropdown(state, action) {
      state.selectedBrandIds = action.payload;
    },
    resetRiSalesBrands: () => initialState
  },
});

export const { setSelectedCategoriesFromBrandsCategoryDropdown, setSelectedBrandsFromBrandsCategoryDropdown, resetRiSalesBrands } = riSalesBrandsSlice.actions;

export default riSalesBrandsSlice.reducer;
