import Wrapper from "../../components/helpers/Wrapper"

const WarningIcon = (props) => {
  return (
    <Wrapper>
      <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
        <path d="M8.57046 13.7151C8.34445 13.7151 8.12352 13.6481 7.9356 13.5226C7.74768 13.397 7.60121 13.2185 7.51472 13.0097C7.42823 12.8009 7.4056 12.5711 7.44969 12.3495C7.49378 12.1278 7.60262 11.9242 7.76243 11.7644C7.92225 11.6046 8.12586 11.4957 8.34753 11.4516C8.5692 11.4076 8.79896 11.4302 9.00777 11.5167C9.21657 11.6032 9.39504 11.7496 9.52061 11.9376C9.64617 12.1255 9.71319 12.3464 9.71319 12.5724C9.71319 12.8755 9.5928 13.1661 9.37849 13.3804C9.16419 13.5948 8.87353 13.7151 8.57046 13.7151Z" fill="#FF9931" />
        <path d="M8.57051 10.2859C8.41897 10.2859 8.27365 10.2257 8.16649 10.1186C8.05934 10.0114 7.99915 9.86608 7.99915 9.71454V5.14363C7.99915 4.99209 8.05934 4.84677 8.16649 4.73961C8.27365 4.63246 8.41897 4.57227 8.57051 4.57227C8.72204 4.57227 8.86737 4.63246 8.97453 4.73961C9.08168 4.84677 9.14187 4.99209 9.14187 5.14363V9.71454C9.14187 9.86608 9.08168 10.0114 8.97453 10.1186C8.86737 10.2257 8.72204 10.2859 8.57051 10.2859Z" fill="#FF9931" />
        <path d="M15.3011 16H1.8398C1.52053 16.0022 1.20629 15.9204 0.928536 15.763C0.650778 15.6056 0.419246 15.378 0.257117 15.1029C0.0949108 14.8341 0.00634642 14.5273 0.000328566 14.2133C-0.00568928 13.8994 0.0710515 13.5894 0.222835 13.3145L6.95351 0.955938C7.11277 0.666441 7.34684 0.425012 7.63127 0.25686C7.91569 0.0887074 8.24005 0 8.57047 0C8.90088 0 9.22524 0.0887074 9.50966 0.25686C9.79409 0.425012 10.0282 0.666441 10.1874 0.955938L16.9181 13.3145C17.0699 13.5894 17.1466 13.8994 17.1406 14.2133C17.1346 14.5273 17.046 14.8341 16.8838 15.1029C16.7217 15.378 16.4902 15.6056 16.2124 15.763C15.9346 15.9204 15.6204 16.0022 15.3011 16ZM8.57047 1.14449C8.44518 1.14174 8.3216 1.17397 8.21361 1.23755C8.10562 1.30113 8.01749 1.39356 7.95911 1.50445L1.22844 13.8573C1.16793 13.9573 1.13595 14.0719 1.13595 14.1887C1.13595 14.3056 1.16793 14.4202 1.22844 14.5201C1.28696 14.6204 1.37019 14.7041 1.47021 14.7631C1.57023 14.8221 1.6837 14.8545 1.7998 14.8572H15.3011C15.4172 14.8545 15.5307 14.8221 15.6307 14.7631C15.7307 14.7041 15.814 14.6204 15.8725 14.5201C15.933 14.4202 15.965 14.3056 15.965 14.1887C15.965 14.0719 15.933 13.9573 15.8725 13.8573L9.18182 1.50445C9.12344 1.39356 9.03531 1.30113 8.92732 1.23755C8.81933 1.17397 8.69576 1.14174 8.57047 1.14449Z" fill="#FF9931" />
      </svg>
    </Wrapper>
  )
}

export default WarningIcon;