import React, { useContext, useEffect, useState } from "react";

// STORAGE
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";

// PAGES
import TwitterAllVideoDetailsOverall from "./TwitterAllVideoDetailsOverall";
import TwitterAllVideoDetailsOrganic from "./TwitterAllVideoDetailsOrganic";
import TwitterAllVideoDetailsPaid from "./TwitterAllVideoDetailsPaid";

// UTILS
import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";

export default function TwitterAllVideoDetails({ videoDurationType, topCardData }) {
  const { setExportPageName } = useContext(FullPageExportContext);
  const tabData = ["Overall", "Organic", "Paid"];
  const [selectedTab, setSelectedTab] = useState(tabData[0]);

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Video", "Youtube Thumbnail", event)
  };

  useEffect(() => {
    setExportPageName("Video Twitter " + selectedTab)
  }, [selectedTab])

  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <Tab
          varient={"secondary"}
          tabData={tabData}
          activeTab={selectedTab}
          handleCallback={(event) => handleCallback(event)}
        />
      </div>
      {selectedTab.toLowerCase() === tabData[0].toLowerCase() && <TwitterAllVideoDetailsOverall videoDurationType={videoDurationType} topCardData={topCardData} />}
      {selectedTab.toLowerCase() === tabData[1].toLowerCase() && <TwitterAllVideoDetailsOrganic videoDurationType={videoDurationType} topCardData={topCardData} />}
      {selectedTab.toLowerCase() === tabData[2].toLowerCase() && <TwitterAllVideoDetailsPaid videoDurationType={videoDurationType} topCardData={topCardData} />}
    </Wrapper>
  );
}
