import PropTypes from "prop-types";
import Wrapper from "../helpers/Wrapper";

function Button({
  label,
  type,
  icon,
  className,
  handleClick,
  children,
  disabled,
  notificationNumber,
}) {
  return (
    <Wrapper>
      {/* <div className="btn_wrapper"> */}
      <button
        type={type || "button"}
        className={"theme_button " + className}
        onClick={handleClick}
        disabled={disabled}
      >
        {notificationNumber !== 0 && notificationNumber !== undefined && (
          <div className="notification_wrapper">{notificationNumber}</div>
        )}
        {icon && <div className="icon_wrapper">{icon}</div>}
        {children}
      </button>
      {/* </div> */}
    </Wrapper>
  );
}

Button.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  notificationNumber: PropTypes.number,
};

export default Button;
