const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    selectedBrandIds: [],
    selectedBrands: [],
    brandOptions: [],
};

const setGlobalBrandsSlice = createSlice({
    name: "globalBrandsSelectionDropdown",
    initialState,
    reducers: {
        setSelectedBrands(state, action) {
            state.selectedBrands = action.payload;
        },
        setSelectedBrandIds(state, action) {
            state.selectedBrandIds = action.payload;
        },
        getBrandsDropdownOptionsList(state, action) {
            state.brandOptions = action.payload;
        },
        resetBrandsDropdownOptionsList: () => initialState
    },
});

export const { setSelectedBrands, setSelectedBrandIds, getBrandsDropdownOptionsList, resetBrandsDropdownOptionsList } = setGlobalBrandsSlice.actions;

export default setGlobalBrandsSlice.reducer;
