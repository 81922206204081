import React from "react";
import ReactDOM from "react-dom"; // Use from react-dom, no 'client' in React 16
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from "redux-persist";
import { PostHogProvider } from 'posthog-js/react'
import { Provider } from "react-redux";

import { AuthContextProvider } from "./store/authContext";
import store from "./store/store";

import "./index.css";
import App from "./App";

let persistor = persistStore(store);

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

ReactDOM.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AuthContextProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </AuthContextProvider>
        </PersistGate>
      </Provider>
    </PostHogProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
