import { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import Modal from "../../../../components/ui/Modal";
import Tab from "../../../../components/ui/Tab";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// CHARTS
import LineChart from "../../../../components/ui/charts/LineChart";
import Datatable from "../../../../components/ui/tables/Datatable";
import BarChart from "../../../../components/ui/charts/BarChart";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import PartitionedLineChart from "../../../../components/ui/charts/PartitionedLineChart";

// ASSETS
import MaleIcon from "../../../../assets/icons/MaleIcon";
import FemaleIcon from "../../../../assets/icons/FemaleIcon";
import UnknownGenderIcon from "../../../../assets/icons/UnknownGenderIcon";

// UTILS
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import { BlueColorStart500, COKE_COMPANY_ID, HALEON_COMPANY_ID } from "../../../../constants/constants";
import MultiAxisMultiLine from "../../../../components/ui/charts/MultiAxisMultiLine";

import ExpandIcon from "../../../../assets/icons/ExpandIcon";
import { DataFormatterExtraFields, DataFormatters } from "../../../../utils/DataFormatter";
import { getHeaderKeysWithLabels, getUniqueValuesFromArrofObj } from "../../../../utils/Utils";
import EventTracker from "../../../../components/eventsTracking/EventTracker";

const CampaignBingOverallOverall = ({ topCardData }) => {

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Campaign"
  let pagePlatform = "Bing"
  let pagePrimaryTab = "Overall"

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  let companyID = userData?.company?.id ?? "";

  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)


  let AudienceDistributionSectionTitle = ""
  if (companyID === HALEON_COMPANY_ID) {
    AudienceDistributionSectionTitle = "Audience Distribution - Meta level";
  } else {
    AudienceDistributionSectionTitle = "Audience Distribution";
  }

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // GLOBAL CAMPAIGN DROPDOWN
  const selectedCampaignPlatformsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignPlatform);
  const selectedCampaignObjectivesFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignObjectives);
  const selectedCampaignsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaigns);
  const selectedBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedBuckets);
  const selectedCampaignIdandPlatform = useSelector((state) => state.campaignModuleFilter.selectedCampaignIDandPlatform);

  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (selectedCampaignPlatformsFromFilter.length === 0 || selectedCampaignPlatformsFromFilter === undefined) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(",");
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (selectedCampaignObjectivesFromFilter.length === 0 || selectedCampaignObjectivesFromFilter === undefined) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(",");
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (selectedCampaignsFromFilter.length === 0 || selectedCampaignsFromFilter === undefined) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(",");
  }

  let selectedIdandPlatform = null;
  if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
    selectedIdandPlatform = null;
  } else {
    selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (DONUT)
  const [childDonutSliceToggle, setChildDonutSliceToggle] = useState(null);
  const [childDonutCampaignObjective, setChildDonutCampaignObjective] = useState([]);
  const [childDonutCampaignObjectiveAllData, setChildDonutCampaignObjectiveAllData] = useState([]);
  const [childDonutCampaignObjectiveLoading, setChildDonutCampaignObjectiveLoading] = useState([]);
  const [childDonutCampaignObjectiveLoaded, setChildDonutCampaignObjectiveLoaded] = useState([]);
  const [childDonutCampaignObjectiveError, setChildDonutCampaignObjectiveError] = useState([]);

  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (LINECHART)
  const [lineChartCampaignObjective, setLineChartCampaignObjective] = useState([]);
  const [lineChartCampaignObjectiveLabel, setLineChartCampaignObjectiveLabel] = useState([]);
  const [lineChartCampaignObjectiveAllData, setLineChartCampaignObjectiveAllData] = useState([]);
  const [lineChartCampaignObjectiveLoading, setLineChartCampaignObjectiveLoading] = useState([]);
  const [lineChartCampaignObjectiveLoaded, setLineChartCampaignObjectiveLoaded] = useState([]);
  const [lineChartCampaignObjectiveError, setLineChartCampaignObjectiveError] = useState([]);

  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION DROPDOWN

  const campaignObjectiveDropdownOptions = [
    { value: "ad_type", label: "Ad Type" },
    { value: "campaign_type", label: "Campaign Type" },
  ]

  const [selectedCampaignObjectiveDropdownValue, setSelectedCampaignObjectiveDropdownValue] = useState(campaignObjectiveDropdownOptions[0]);

  const campaignObjectiveDropdownHandler = (e) => {
    if (e === null) {
      setSelectedCampaignObjectiveDropdownValue("");
    } else {
      setSelectedCampaignObjectiveDropdownValue(e);
    }

    setChildDonutSliceToggle(null)

    EventTracker({
      eventType: "dropdown_selected",
      module: pageModule,
      section: "Objective Wise Distribution",
      dropdownValue: e === null ? "Overall" : e.label,
      ...(BRAND_ID && { brands: BRAND_ID }),
      date: {
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate,
        previousStartDate: selectedDates.prevStartDate,
        previousEndDate: selectedDates.prevEndDate,
      }
    })
  };


  useEffect(() => {

    const fetchCampaignObjectiveDropdownHandler = () => {
      if (selectedCampaignObjectiveDropdownValue.value === 'ad_type') {

        setLineChartCampaignObjective(lineChartCampaignObjectiveAllData?.data.ad_type ?? [])
        setLineChartCampaignObjectiveLabel(lineChartCampaignObjectiveAllData?.dimension.ad_type ?? [])
        setChildDonutCampaignObjectiveAllData(childDonutCampaignObjectiveAllData?.ad_type ?? [])

      } else if (selectedCampaignObjectiveDropdownValue.value === 'campaign_type') {

        setLineChartCampaignObjective(lineChartCampaignObjectiveAllData?.data.campaign_type ?? [])
        setLineChartCampaignObjectiveLabel(lineChartCampaignObjectiveAllData?.dimension.campaign_type ?? [])
        setChildDonutCampaignObjectiveAllData(childDonutCampaignObjectiveAllData?.campaign_type ?? [])

      } else {

        setLineChartCampaignObjective([])
        setLineChartCampaignObjectiveLabel([])
        setChildDonutCampaignObjectiveAllData([])

      }
    }


  }, [BRAND_ID, selectedDates, selectedCampaignObjectiveDropdownValue, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform])


  // MID SECTION DROPDOWN
  // DROPDOWN OPTIONS
  const midSectionDropdonwOptions = [
    { value: "impressions", label: "Impressions" },
    { value: "clicks", label: "Clicks" },
    { value: "conversions", label: "Conversions" },
    { value: "spends", label: "Spends" },
  ];

  // DROPDOWN DEFAULT STATE
  const [selectedMidSectionDropdownOption, setSelectedMidSectionDropdownDropdownOption] = useState(midSectionDropdonwOptions[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const midSectionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMidSectionDropdownDropdownOption("");
    } else {
      setSelectedMidSectionDropdownDropdownOption(e);
    }

    EventTracker({
      eventType: "dropdown_selected",
      module: pageModule,
      section: "Performance By KPI",
      dropdownValue: e === null ? "Overall" : e.label,
      ...(BRAND_ID && { brands: BRAND_ID }),
      date: {
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate,
        previousStartDate: selectedDates.prevStartDate,
        previousEndDate: selectedDates.prevEndDate,
      }
    })
  };

  // DEVICE DISTRIBUTION
  const [deviceDistribution, setDeviceDistribution] = useState([]);
  const [deviceDistributionAllDropdownsData, setDeviceDistributionAllDropdownsData] = useState([]);
  const [isDeviceDistributionLoading, setDeviceDistributionIsLoading] = useState(false);
  const [isDeviceDistributionLoaded, setDeviceDistributionIsLoaded] = useState(false);
  const [deviceDistributionerror, setDeviceDistributionError] = useState(null);

  // GENDER
  const [totalMaleCount, setTotalMaleCount] = useState(0);
  const [totalFemaleCount, setTotalFemaleCount] = useState(0);
  const [totalUnknownCount, setTotalUnknownCount] = useState(0);
  const [genderAllDropdownsData, setGenderAllDropdownsData] = useState([]);
  const [isGenderLoading, setGenderIsLoading] = useState(false);
  const [isGenderLoaded, setGenderIsLoaded] = useState(false);
  const [gendererror, setGenderError] = useState(null);

  // AGE DISTRIBUTION
  const [ageDistribution, setAgeDistribution] = useState([]);
  const [ageDistributionLabel, setAgeDistributionLabel] = useState([]);
  const [ageDistributionAllDropdownsData, setAgeDistributionAllDropdownsData] = useState([]);
  const [isAgeDistributionLoading, setAgeDistributionIsLoading] = useState(false);
  const [isAgeDistributionLoaded, setAgeDistributionIsLoaded] = useState(false);
  const [ageDistributionerror, setAgeDistributionError] = useState(null);

  // COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [countryTableDataHeaders, setCountryTableDataHeaders] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);


  // STATE
  const [stateData, setStateData] = useState([]);
  const [stateTableDataHeaders, setStateTableDataHeaders] = useState([]);
  const [isStateDataLoading, setStateDataIsLoading] = useState(false);
  const [isStateDataLoaded, setStateDataIsLoaded] = useState(false);
  const [stateDataerror, setStateDataError] = useState(null);

  // CITY DATA
  const [cityData, setCityData] = useState([]);
  const [cityTableDataHeaders, setCityTableDataHeaders] = useState([]);
  const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  const [cityDataerror, setCityDataError] = useState(null);

  // CAMPAIGN SUMMARY
  const [campaignSummary, setCampaignSummary] = useState([]);
  const [campaignSummaryTableHeaders, setCampaignSummaryTableHeaders] = useState([]);
  const [campaignSummaryExportData, setCampaignSummaryExportData] = useState([]);

  const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] = useState(false);
  const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
  const [campaignSummaryerror, setCampaignSummaryError] = useState(null);

  const [campaignSummaryTablePageData, setCampaignSummaryTablePageData] = useState({})

  // CAMPAIGN SUMMARY DROPDOWN OPTIONS
  const campaignSummaryOptions = [
    { value: "campaign", label: "Campaign" },
    { value: "ad", label: "Ad" },
    { value: "ad_group", label: "Ad Group" },
    { value: "keyword", label: "Keyword" },
    { value: "search_term", label: "Search Term" },

  ];

  const [campaignSummaryConfig, setCampaignSummaryConfig] = useState({ page_num: 1, page_size: 10, order_type: "desc", order_by: "clicks", search_value: null, dropdown_value: campaignSummaryOptions[0].value })

  // CAMPAIGN SUMMARY DROPDOWN DEFAULT STATE
  const [selectedCampaignSummaryDropdownOption, setSelectedCampaignSummaryDropdownOption] = useState(campaignSummaryOptions[0]);

  // CAMPAIGN SUMMARY DROPDOWN OPTIONS SELECTION HANDLER
  const campaignSummaryDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedCampaignSummaryDropdownOption("");
    } else {
      setSelectedCampaignSummaryDropdownOption(e);
      setCampaignSummaryConfig({ ...campaignSummaryConfig, dropdown_value: e.value, page_num: 1, search_value: null })
    }

    EventTracker({
      eventType: "dropdown_selected",
      module: pageModule,
      section: "Campaign Summary Table",
      dropdownValue: e === null ? "Overall" : e.label,
      ...(BRAND_ID && { brands: BRAND_ID }),
      date: {
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate,
        previousStartDate: selectedDates.prevStartDate,
        previousEndDate: selectedDates.prevEndDate,
      }
    })

  };


  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchMidSectionDropdownHandler = async () => {
    if (selectedMidSectionDropdownOption.value === "clicks") {

      setTotalMaleCount(genderAllDropdownsData?.male?.clicks ?? []);
      setTotalFemaleCount(genderAllDropdownsData?.female?.clicks ?? []);
      setTotalUnknownCount(genderAllDropdownsData?.unknown?.clicks ?? []);
      setAgeDistribution(ageDistributionAllDropdownsData?.data?.clicks ?? [])
      setAgeDistributionLabel(ageDistributionAllDropdownsData?.dimension?.clicks ?? [])

      setDeviceDistribution(deviceDistributionAllDropdownsData?.clicks ?? [])

    } else if (selectedMidSectionDropdownOption.value === "conversions") {

      setTotalMaleCount(genderAllDropdownsData?.male?.conversions ?? []);
      setTotalFemaleCount(genderAllDropdownsData?.female?.conversions ?? []);
      setTotalUnknownCount(genderAllDropdownsData?.unknown?.conversions ?? []);
      setAgeDistribution(ageDistributionAllDropdownsData?.data?.conversions ?? [])
      setAgeDistributionLabel(ageDistributionAllDropdownsData?.dimension?.conversions ?? [])
      setDeviceDistribution(deviceDistributionAllDropdownsData?.conversions ?? [])

    } else if (selectedMidSectionDropdownOption.value === "impressions") {

      setTotalMaleCount(genderAllDropdownsData?.male?.impressions ?? []);
      setTotalFemaleCount(genderAllDropdownsData?.female?.impressions ?? []);
      setTotalUnknownCount(genderAllDropdownsData?.unknown?.impressions ?? []);
      setAgeDistribution(ageDistributionAllDropdownsData?.data?.impressions ?? [])
      setAgeDistributionLabel(ageDistributionAllDropdownsData?.dimension?.impressions ?? [])
      setDeviceDistribution(deviceDistributionAllDropdownsData?.impressions ?? [])

    } else if (selectedMidSectionDropdownOption.value === "spends") {

      setTotalMaleCount(genderAllDropdownsData?.male?.spends ?? []);
      setTotalFemaleCount(genderAllDropdownsData?.female?.spends ?? []);
      setTotalUnknownCount(genderAllDropdownsData?.unknown?.spends ?? []);
      setAgeDistribution(ageDistributionAllDropdownsData?.data?.spends ?? [])
      setAgeDistributionLabel(ageDistributionAllDropdownsData?.dimension?.spends ?? [])
      setDeviceDistribution(deviceDistributionAllDropdownsData?.spends ?? [])

    } else {

      setTotalMaleCount([]);
      setTotalFemaleCount([]);
      setTotalUnknownCount([]);
      setAgeDistribution([])
      setAgeDistributionLabel([])
      setDeviceDistribution([])

    }
  };

  // CHNAGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchMidSectionDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedMidSectionDropdownOption, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  useEffect(() => {

    // TO FETCH METRIC CARDS FROM API
    const fetchMetricCardsHandler = async () => {
      setMetricCardsIsLoading(true);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/bing/metric-card/", config);

        const allData = res.data.data;

        let formattedData = DataFormatters("metricCards", allData)

        setMetricCards(formattedData?.data ?? []);
        setMetricCardsIsLoading(false);
        setMetricCardsIsLoaded(true);
        setMetricCardsError(null);
      } catch (error) {
        setMetricCards([]);
        setMetricCardsIsLoading(false);
        setMetricCardsIsLoaded(false);
        setMetricCardsError(error.code);
      }
    };
    fetchMetricCardsHandler()

  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform])


  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">
            <h2 className="section_card_title">Summary
            </h2>
          </div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Summary
              </h2></div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider
              sliderTitle={"Summary"}
              tab={"overall"}
              platform={"bing"}
            >
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="campaign"
                    platform="facebook"
                    section="overall"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }

  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (DONUT)

  useEffect(() => {
    const fetchChildDonutCampaignHandler = async () => {
      setChildDonutCampaignObjectiveLoading(true);
      setChildDonutCampaignObjectiveLoaded(false);
      setChildDonutCampaignObjectiveError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {

        if (selectedCampaignObjectiveDropdownValue.value === 'ad_type') {

          const res = await axios.get("performance/bing/ad-type-lifetime-data/", config);
          const allData = res.data.data.map(({ ad_type, no_of_campaign }) => ({
            label: ad_type,
            value: no_of_campaign
          }));

          let formattedData = allData;
          setChildDonutCampaignObjective(formattedData ?? []);
        } else {

          const res = await axios.get("performance/bing/campaign-type-lifetime-data/", config);
          const allData = res.data.data.map(({ objective, no_of_campaign }) => ({
            label: objective,
            value: no_of_campaign
          }));

          let formattedData = allData;
          setChildDonutCampaignObjective(formattedData ?? []);
        }


        // setChildDonutCampaignObjectiveAllData(formattedData)
        setChildDonutCampaignObjectiveLoading(false);
        setChildDonutCampaignObjectiveLoaded(true);
        setChildDonutCampaignObjectiveError(null);
      } catch (error) {
        setChildDonutCampaignObjective([]);

        // setChildDonutCampaignObjectiveAllData([])
        setChildDonutCampaignObjectiveLoading(false);
        setChildDonutCampaignObjectiveLoaded(false);
        setChildDonutCampaignObjectiveError(error.code);
      }
    };


    fetchChildDonutCampaignHandler();

  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform, selectedCampaignObjectiveDropdownValue]);



  let childDonutCampaignChart = (
    <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (childDonutCampaignObjectiveLoaded && !childDonutCampaignObjectiveLoading) {
    if (NoDataAvailableChecker(childDonutCampaignObjective)) {

      childDonutCampaignChart = <Wrapper><NoDataAvailableLoader chartType="donutChartType" /></Wrapper>

    } else {
      childDonutCampaignChart = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={childDonutCampaignObjective}
              chartId="CampaignDonutChild"
              onSliceClick={(data) => setChildDonutSliceToggle(data.label)}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (childDonutCampaignObjectiveError) {
    childDonutCampaignChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={childDonutCampaignObjectiveError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (childDonutCampaignObjectiveLoading) {
    childDonutCampaignChart = <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION (LINECHART)
  const [isLineChartCampaignOpen, setIsLineChartCampaignOpen] = useState(false)

  let defaultLineChartCampaignDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultLineChartCampaignDisabledvalues = []
  } else {
    defaultLineChartCampaignDisabledvalues = ["Impressions", "Clicks", "Spends", "Conversions"]
  }

  const [lineChartCampaignDisabledvalues, setLineChartCampaignDisabledvalues] = useState(defaultLineChartCampaignDisabledvalues)


  useEffect(() => {

    const fetchLineChartCampaignHandler = async () => {
      setLineChartCampaignObjectiveLoading(true);
      setLineChartCampaignObjectiveLoaded(false);
      setLineChartCampaignObjectiveError(null);

      var objectiveConfig = (function () {
        if (selectedObjectives === null && (childDonutSliceToggle === null || childDonutSliceToggle === undefined)) {
          return { "objective": null }
        } else if (selectedObjectives !== null && (childDonutSliceToggle === undefined || childDonutSliceToggle === null)) {
          return { "objective": selectedObjectives }
        } else {
          return { "objective": selectedCampaignObjectiveDropdownValue.value === 'ad_type' ? null : childDonutSliceToggle }
        }
      })()

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          ...objectiveConfig,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
          ad_type: selectedCampaignObjectiveDropdownValue.value === 'ad_type' ? childDonutSliceToggle : null
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {

        if (selectedCampaignObjectiveDropdownValue.value === 'ad_type') {
          const res = await axios.get("performance/bing/ad-type-daily-data/", config);

          const allData = res.data.data;
          const dataLabel = "data_date";
          const unwantedValues = ['data_date', 'ad_type']
          const dropdownArray = []

          let formattedData = DataFormatters("linechart", allData, dataLabel, dropdownArray, unwantedValues)

          setLineChartCampaignObjective(formattedData?.data ?? [])
          setLineChartCampaignObjectiveLabel(formattedData?.dimension ?? [])

        } else {

          const res = await axios.get("performance/bing/campaign-type-daily-data/", config);

          const allData = res.data.data;
          const dataLabel = "data_date";
          const unwantedValues = ['data_date', 'objective']
          const dropdownArray = []

          let formattedData = DataFormatters("linechart", allData, dataLabel, dropdownArray, unwantedValues)

          setLineChartCampaignObjective(formattedData?.data ?? [])
          setLineChartCampaignObjectiveLabel(formattedData?.dimension ?? [])
        }


        setLineChartCampaignObjectiveLoading(false);
        setLineChartCampaignObjectiveLoaded(true);
        setLineChartCampaignObjectiveError(null);
      } catch (error) {
        setLineChartCampaignObjective([]);
        setLineChartCampaignObjectiveLabel([]);
        setLineChartCampaignObjectiveLoading(false);
        setLineChartCampaignObjectiveLoaded(false);
        setLineChartCampaignObjectiveError(error.code);
      }
    };
    fetchLineChartCampaignHandler();

  }, [BRAND_ID, selectedDates, childDonutSliceToggle, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform, selectedCampaignObjectiveDropdownValue])

  let lineChartCampaignSection = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
  let lineChartCampaignSection2 = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
  // IF DATA IS LOADED
  if (lineChartCampaignObjectiveLoaded && !lineChartCampaignObjectiveLoading) {
    if (NoDataAvailableChecker(lineChartCampaignObjective) || NoDataAvailableChecker(lineChartCampaignObjectiveLabel)) {

      lineChartCampaignSection = <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      lineChartCampaignSection2 = <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>

    } else {

      if (isMultiLineCluster) {
        lineChartCampaignSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={lineChartCampaignObjective}
                chartId="multiLineChart"
                chartLabels={lineChartCampaignObjectiveLabel}
                onBulletsClick={function noRefCheck() { }}
                showLabels
                showLegend
                impressionLabel="impression"
                showImpression={companyID === HALEON_COMPANY_ID ? true : false}
                disabledLegendArray={lineChartCampaignDisabledvalues}
                setDisabledValues={setLineChartCampaignDisabledvalues}
              />

            </ErrorBoundary>
          </Wrapper>
        );

        lineChartCampaignSection2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={lineChartCampaignObjective}
                chartId="multiLineChart_2"
                chartLabels={lineChartCampaignObjectiveLabel}
                showLegend={true}
                disabledLegendArray={lineChartCampaignDisabledvalues}
                setDisabledValues={setLineChartCampaignDisabledvalues}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      } else {
        lineChartCampaignSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={lineChartCampaignObjective}
                chartId="multiLineChart"
                chartLabels={lineChartCampaignObjectiveLabel}
                onBulletsClick={function noRefCheck() { }}
                showLabels
                showLegend
                impressionLabel="impression"
                showImpression={companyID === HALEON_COMPANY_ID ? true : false}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (lineChartCampaignObjectiveError) {
    lineChartCampaignSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={lineChartCampaignObjectiveError}
        />
      </Wrapper>
    );

    lineChartCampaignSection2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={lineChartCampaignObjectiveError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (lineChartCampaignObjectiveLoading) {
    lineChartCampaignSection = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>
    lineChartCampaignSection2 = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>

  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // DEVICE DISTRIBUTION

  // TO FETCH DATA FROM API

  useEffect(() => {

    const fetchDeviceDistributionHandler = async () => {
      setDeviceDistributionIsLoading(true);
      setDeviceDistributionIsLoaded(false);
      setDeviceDistributionError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {

        const res = await axios.get("performance/bing/device-wise/", config);
        const allData = res.data.data;
        const dataLabel = "device";
        const uniqueKeys = getUniqueValuesFromArrofObj(allData, dataLabel)
        const dropdownArray = uniqueKeys

        let formattedData = {}

        uniqueKeys.map((key) => {
          formattedData[key] = []
        })

        // ALL DATA ALL DROPDOWNS
        Object.keys(formattedData).map((data, dataInd) => {
          allData.map((rawData, rawDataIndex) => {
            formattedData[data].push({
              "label": rawData[dataLabel],
              "value": rawData[data]
            })
          })
        })

        setDeviceDistribution(formattedData?.impressions ?? []);
        setDeviceDistributionAllDropdownsData(formattedData ?? []);
        setDeviceDistributionIsLoading(false);
        setDeviceDistributionIsLoaded(true);
        setDeviceDistributionError(null);
      } catch (error) {
        setDeviceDistributionAllDropdownsData([]);
        setDeviceDistributionIsLoading(false);
        setDeviceDistributionIsLoaded(false);
        setDeviceDistributionError(error.code);
      }
    };
    fetchDeviceDistributionHandler();

  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform])



  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let deviceDistributionChart = (
    <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (isDeviceDistributionLoaded && !isDeviceDistributionLoading) {
    if (NoDataAvailableChecker(deviceDistribution)) {
      deviceDistributionChart = (
        <Wrapper><NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
      );
    } else {
      deviceDistributionChart = (
        <DonutPieChart
          chartId={"device_wise"}
          chartClass={"section_card_chart"}
          chartData={deviceDistribution}
          showLegend={true}
          showLabels={true}
          showVerticalLabels={true}
        />
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (deviceDistributionerror) {
    deviceDistributionChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={deviceDistributionerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isDeviceDistributionLoading) {
    deviceDistributionChart = <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AGE DISTRIBUTION

  useEffect(() => {

    const fetchAgeDistributionHandler = async () => {
      setAgeDistributionIsLoading(true);
      setAgeDistributionIsLoaded(false);
      setAgeDistributionError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/bing/age-wise/", config);


        const allData = res.data.data;
        const dataLabel = "age";
        const dropdownArray = ["clicks", "conversions", "impressions", "spends"]

        let formattedData = DataFormatters("barchart", allData, dataLabel, dropdownArray)


        setAgeDistribution(formattedData?.data?.impressions ?? [])
        setAgeDistributionLabel(formattedData?.dimension?.impressions ?? [])
        setAgeDistributionAllDropdownsData(formattedData ?? [])

        setAgeDistributionIsLoading(false);
        setAgeDistributionIsLoaded(true);
        setAgeDistributionError(null);
      } catch (error) {
        setAgeDistribution([]);
        setAgeDistributionLabel([]);
        setAgeDistributionAllDropdownsData([]);
        setAgeDistributionIsLoading(false);
        setAgeDistributionIsLoaded(false);
        setAgeDistributionError(error.code);
      }
    };
    fetchAgeDistributionHandler()

  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform])


  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let ageDistributionChart = (
    <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (isAgeDistributionLoaded && !isAgeDistributionLoading) {
    if (NoDataAvailableChecker(ageDistribution)) {
      ageDistributionChart = <Wrapper><NoDataAvailableLoader chartType="barChartType" /></Wrapper>
    } else {

      const sortedData = ageDistribution.sort((a, b) => a.label.localeCompare(b.label));
      ageDistributionChart = (
        <BarChart
          chartId={"reactions_sentiment"}
          chartClass={"section_card_chart"}
          chartData={sortedData}
          chartLabels={ageDistributionLabel}
        />
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (ageDistributionerror) {
    ageDistributionChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="barChartType"
          error={ageDistributionerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAgeDistributionLoading) {
    ageDistributionChart = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // GENDER DISTRIBUTION

  useEffect(() => {

    const fetchGenderHandler = async () => {
      setGenderIsLoading(true);
      setGenderIsLoaded(false);
      setGenderError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {

        const res = await axios.get("performance/bing/gender-wise/", config);


        const allData = res.data.data;
        const dataLabel = "gender";
        const dropdownArray = ["clicks", "conversions", "impressions", "spends"]

        let formattedData = DataFormatters("maleFemaleCount", allData, dataLabel, dropdownArray)


        setGenderAllDropdownsData(formattedData ?? [])
        setTotalFemaleCount(formattedData?.male?.impressions ?? 0)
        setTotalMaleCount(formattedData?.female?.impressions ?? 0)
        setTotalUnknownCount(formattedData?.unknown?.impressions ?? 0)

        setGenderIsLoading(false);
        setGenderIsLoaded(true);
        setGenderError(null);
      } catch (error) {

        setGenderAllDropdownsData([])
        setTotalFemaleCount(0)
        setTotalMaleCount(0)
        setTotalUnknownCount(0)
        setGenderIsLoading(false);
        setGenderIsLoaded(false);
        setGenderError(error.code);
      }
    };
    fetchGenderHandler()

  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform])


  let maleAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
  let femaleAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
  let unknownAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>

  let totalMaleCountPercentage = 0;
  if (isNaN(totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)) === true) {
    totalMaleCountPercentage = 0;
  } else {
    totalMaleCountPercentage = totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalFemaleCountPercentage = 0;
  if (isNaN(totalFemaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)) === true) {
    totalFemaleCountPercentage = 0;
  } else {
    totalFemaleCountPercentage = totalFemaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalUnknownCountPercentage = 0;
  if (isNaN(totalUnknownCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)) === true) {
    totalUnknownCountPercentage = 0;
  } else {
    totalUnknownCountPercentage = totalUnknownCount / (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }


  if (isGenderLoaded && !isGenderLoading) {
    if (NoDataAvailableChecker(totalMaleCountPercentage)) {

      maleAudienceDistributionIcon = <Wrapper><NoDataAvailableLoader chartType="genderChartType" /></Wrapper>
      femaleAudienceDistributionIcon = <Wrapper><NoDataAvailableLoader chartType="genderChartType" /></Wrapper>
      unknownAudienceDistributionIcon = <Wrapper><NoDataAvailableLoader chartType="genderChartType" /></Wrapper>


    } else {

      maleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <MaleIcon percentage={totalMaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalMaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
            <h3>Male</h3>
          </div>
        </Wrapper>
      );

      femaleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalFemaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
            <h3>Female</h3>
          </div>
        </Wrapper>
      );


      unknownAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <UnknownGenderIcon percentage={totalUnknownCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalUnknownCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
            <h3>Unknown</h3>
          </div>
        </Wrapper>
      );
    }

  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (gendererror) {
    maleAudienceDistributionIcon = <Wrapper><ServerErrorsLoader chartType="genderChartType" error={gendererror} /></Wrapper>
    femaleAudienceDistributionIcon = <Wrapper><ServerErrorsLoader chartType="genderChartType" error={gendererror} /></Wrapper>
    unknownAudienceDistributionIcon = <Wrapper><ServerErrorsLoader chartType="genderChartType" error={gendererror} /></Wrapper>

  }

  // IF DATA LOADING
  if (isGenderLoading) {
    maleAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
    femaleAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
    unknownAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
  }


  const tabData = ["By Country", "By State", 'By City'];
  const [selectedTab, setSelectedTab] = useState(tabData[0]);
  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
  };


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // STATE MAP
  useEffect(() => {

    const fetchStateHandler = async () => {
      setStateDataIsLoading(true);
      setStateDataIsLoaded(false);
      setStateDataError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          drop_down: 'state',
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag:
            selectedBucketsFromFilter.length === 0
              ? null
              : selectedBucketsFromFilter.join(","),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/bing/country-region-distributions/", config);


        let allData = res?.data?.data?.map(({ name, ...rest }) => ({ 'state': name, ...rest })) ?? [];

        let uniqueHeaders = getUniqueValuesFromArrofObj(allData)

        let formattedHeaders = []

        uniqueHeaders.map((header) => {
          formattedHeaders.push({
            name: getHeaderKeysWithLabels(header),
            field: header,
          })
        })

        setStateData(allData ?? []);
        setStateTableDataHeaders(formattedHeaders ?? [])
        setStateDataIsLoading(false);
        setStateDataIsLoaded(true);
        setStateDataError(null);
      } catch (error) {
        setStateData([]);
        setStateTableDataHeaders([])
        setStateDataIsLoading(false);
        setStateDataIsLoaded(false);
        setStateDataError(error.code);
      }
    };
    fetchStateHandler();

  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform]);



  // AUDIENCE DISTRIBUTION: STATE
  let audienceDistributionState = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By State</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isStateDataLoaded && !isStateDataLoading) {
    if (
      NoDataAvailableChecker(stateData) ||
      NoDataAvailableChecker(stateTableDataHeaders)
    ) {
      audienceDistributionState = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By State</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      audienceDistributionState = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By State"}
              isDynamicFlagVisible={true}
              tableHeader={stateTableDataHeaders}
              tableData={stateData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search State"}
              tableID="audience_distribution_country_state_section"
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"audience_distribution_country_state_section"}
                  sectionName={"StateTable_Audience Distribution By State"}
                  sectionData={stateData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (stateDataerror) {
    audienceDistributionState = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By State</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={stateDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isStateDataLoading) {
    audienceDistributionState = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By State</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CITY MAP

  useEffect(() => {

    const fetchCityHandler = async () => {
      setCityDataIsLoading(true);
      setCityDataIsLoaded(false);
      setCityDataError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          drop_down: 'city',
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag:
            selectedBucketsFromFilter.length === 0
              ? null
              : selectedBucketsFromFilter.join(","),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/bing/country-region-distributions/", config);


        let allData = res?.data?.data?.map(({ name, ...rest }) => ({ 'city': name, ...rest })) ?? [];

        let uniqueHeaders = getUniqueValuesFromArrofObj(allData)

        let formattedHeaders = []

        uniqueHeaders.map((header) => {
          formattedHeaders.push({
            name: getHeaderKeysWithLabels(header),
            field: header,
          })
        })

        setCityData(allData ?? []);
        setCityTableDataHeaders(formattedHeaders ?? [])
        setCityDataIsLoading(false);
        setCityDataIsLoaded(true);
        setCityDataError(null);
      } catch (error) {
        setCityData([]);
        setCityTableDataHeaders([])
        setCityDataIsLoading(false);
        setCityDataIsLoaded(false);
        setCityDataError(error.code);
      }
    };
    fetchCityHandler()
  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform])


  // AUDIENCE DISTRIBUTION: CITY
  let audienceDistributionCity = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By City</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (
      NoDataAvailableChecker(cityData) ||
      NoDataAvailableChecker(cityTableDataHeaders)
    ) {
      audienceDistributionCity = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By City</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      audienceDistributionCity = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By City"}
              isDynamicFlagVisible={true}
              tableHeader={cityTableDataHeaders}
              tableData={cityData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search City"}
              tableID="audience_distribution_country_city_section"
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"audience_distribution_country_city_section"}
                  sectionName={"CityTable_Audience Distribution By City"}
                  sectionData={cityData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    audienceDistributionCity = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    audienceDistributionCity = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY TABLE

  useEffect(() => {
    const fetchCountryHandler = async () => {
      setCountryDataIsLoading(true);
      setCountryDataIsLoaded(false);
      setCountryDataError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          drop_down: 'country',
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag:
            selectedBucketsFromFilter.length === 0
              ? null
              : selectedBucketsFromFilter.join(","),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/bing/country-region-distributions/", config);


        let allData = res?.data?.data?.map(({ name, ...rest }) => ({ 'country': name, ...rest })) ?? [];

        let uniqueHeaders = getUniqueValuesFromArrofObj(allData)

        let formattedHeaders = []

        uniqueHeaders.map((header) => {
          formattedHeaders.push({
            name: getHeaderKeysWithLabels(header),
            field: header,
          })
        })


        setCountryData(allData ?? []);
        setCountryTableDataHeaders(formattedHeaders ?? [])
        setCountryDataIsLoading(false);
        setCountryDataIsLoaded(true);
        setCountryDataError(null);
      } catch (error) {
        setCountryData([]);
        setCountryTableDataHeaders([])
        setCountryDataIsLoading(false);
        setCountryDataIsLoaded(false);
        setCountryDataError(error.code);
      }
    };

    fetchCountryHandler();

  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform]);



  // AUDIENCE DISTRIBUTION: CITY
  let audienceDistributionCountry = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By Country</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(countryTableDataHeaders)
    ) {
      audienceDistributionCountry = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By Country</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      audienceDistributionCountry = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By Country"}
              isCountryFlagVisible={true}
              tableHeader={countryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
              tableID="audience_distribution_country_country_section"
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"audience_distribution_country_country_section"}
                  sectionName={"CountryTable_Audience Distribution - Country"}
                  sectionData={countryData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    audienceDistributionCountry = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    audienceDistributionCountry = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }



  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY

  // TO FETCH METRIC CARDS FROM API

  useEffect(() => {

    const fetchCampaignSummaryHandler = async () => {


      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          dropdown_value: selectedCampaignSummaryDropdownOption?.value ?? '',
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
          ...campaignSummaryConfig,
          page_size: -1,
          page_num: 1,

        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/bing/summary-table/", config);

        setCampaignSummaryExportData(res?.data?.data?.results ?? []);

      } catch (error) {
        setCampaignSummaryExportData([]);

      }
    };
    fetchCampaignSummaryHandler();

  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform, selectedCampaignSummaryDropdownOption, campaignSummaryConfig])

  useEffect(() => {

    const fetchCampaignSummaryHandler = async () => {
      setCampaignSummaryIsLoading(true);
      setCampaignSummaryIsLoaded(false);
      setCampaignSummaryError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          dropdown_value: selectedCampaignSummaryDropdownOption?.value ?? '',
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
          ...campaignSummaryConfig

        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get(
          "performance/bing/summary-table/",
          config
        );

        let allData = res.data.data.results;

        let uniqueHeaders = getUniqueValuesFromArrofObj(allData)

        let formattedHeaders = []

        uniqueHeaders.map((header) => {
          formattedHeaders.push({
            name: getHeaderKeysWithLabels(header),
            field: header,
          })
        })

        setCampaignSummaryTablePageData(res?.data?.data?.page ?? {})

        setCampaignSummary(allData ?? []);
        setCampaignSummaryTableHeaders(formattedHeaders ?? [])
        setCampaignSummaryIsLoading(false);
        setCampaignSummaryIsLoaded(true);
        setCampaignSummaryError(null);
      } catch (error) {
        setCampaignSummary([]);
        setCampaignSummaryTableHeaders([])
        setCampaignSummaryIsLoading(false);
        setCampaignSummaryIsLoaded(false);
        setCampaignSummaryError(error.code);
      }
    };
    fetchCampaignSummaryHandler();

  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform, selectedCampaignSummaryDropdownOption, campaignSummaryConfig]);



  let campaignSummaryTable = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );


  // IF DATA IS LOADED
  if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {

    campaignSummaryTable = (
      <Wrapper>
        <ErrorBoundary chartType="tableChartType">
          <Datatable
            tableTitle="Summary"
            tableHeader={campaignSummaryTableHeaders}
            tableData={campaignSummary}
            tableLength={campaignSummaryConfig?.page_size ?? 10}
            isFooterShow={true}
            searchPlaceHolder={"Search Campaign"}
            tableConfigs={campaignSummaryConfig}
            setTableConfigs={setCampaignSummaryConfig}
            tablePaginationData={campaignSummaryTablePageData}
            tableDropdown={
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={campaignSummaryOptions}
                className="form_dropdown section_dropdown"
                value={selectedCampaignSummaryDropdownOption}
                selectedOptions={selectedCampaignSummaryDropdownOption}
                setStatedropdown={campaignSummaryDropdownSelectionHandler}
              />
            }
            tableID={"search_campaign_section"}
            tableExportDropdown={
              <ExportDropdown
                sectionId={"search_campaign_section"}
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionName={`TableDynaLabels_Summary Table _${": " + selectedCampaignSummaryDropdownOption.label}`}
                sectionData={campaignSummaryExportData}
              />
            }
          />
        </ErrorBoundary>
      </Wrapper>
    );

  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignSummaryerror) {
    campaignSummaryTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">Summary</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={campaignSummaryerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCampaignSummaryLoading) {
    campaignSummaryTable =
      (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">Summary
                </h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <Loader loaderType="smallTableLoader" />
              </div>
            </div>
          </div>
        </Wrapper>
      )
  }


  useEffect(() => {
    setChildDonutSliceToggle(null)
  }, [selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

  var campaignObjectCSV = {
    [`DonutWithActiveDropdown_Campaign Objective Donut_${selectedCampaignObjectiveDropdownValue?.label ?? ''}`]: childDonutCampaignObjective,
    [`CampObjectiveDynaLabels_Campaign Objective Line_${(selectedCampaignObjectiveDropdownValue?.label ?? '') + " : " + (childDonutSliceToggle ?? '')}`]: lineChartCampaignObjective,
  };



  var maleFemaleDataCSV = {
    "Gender": [
      {
        ["Male - " + selectedMidSectionDropdownOption?.label ?? '']: totalMaleCount,
        ["Female - " + selectedMidSectionDropdownOption?.label ?? '']: totalFemaleCount,
        ["Unknown - " + selectedMidSectionDropdownOption?.label ?? '']: totalUnknownCount,
      },
    ],
  };


  var deviceDistributionExport = {
    [`CampaignDeviceDistDynaLabels_Device Distribution_${selectedMidSectionDropdownOption.value}`]: deviceDistribution,
  }



  var allData = {
    ...topCardData,
    "SummaryCard_Summary Card": metricCards,
    ...campaignObjectCSV,
    ...maleFemaleDataCSV,
    ...deviceDistributionExport,
    [`AgeBarChartCampaignDynalabelsBing_Audience Distribution - Age_${selectedMidSectionDropdownOption?.value ?? ''}`]: ageDistribution,
    ["CountryTable_Audience Distribution-Country"]: countryData,
    ["StateTable_Audience Distribution-State"]: stateData,
    [`CityTable_Audience Distribution-City`]: cityData,
    [`TableDynaLabels_Summary Table_${": " + selectedCampaignSummaryDropdownOption.label}`]: campaignSummaryExportData,
  };

  useEffect(() => {
    setFullPageExport(allData);
  }, [
    metricCards,
    childDonutCampaignObjective,
    lineChartCampaignObjective,
    genderAllDropdownsData,
    ageDistribution,
    deviceDistribution,
    stateData,
    countryData,
    cityData,
    campaignSummaryExportData,
  ]);


  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (childDonutCampaignObjectiveError !== null ? true : childDonutCampaignObjectiveLoaded && (childDonutCampaignObjectiveLoading === false)) &&
      (lineChartCampaignObjectiveError !== null ? true : lineChartCampaignObjectiveLoaded && (lineChartCampaignObjectiveLoading === false)) &&
      (gendererror !== null ? true : isGenderLoaded && (isGenderLoading === false)) &&
      (deviceDistributionerror !== null ? true : isDeviceDistributionLoaded && (isDeviceDistributionLoading === false)) &&
      (ageDistributionerror !== null ? true : isAgeDistributionLoaded && (isAgeDistributionLoading === false)) &&
      (countryDataerror !== null ? true : isCountryDataLoaded && (isCountryDataLoading === false)) &&
      (stateDataerror !== null ? true : isStateDataLoaded && (isStateDataLoading === false)) &&
      (cityDataerror !== null ? true : isCityDataLoaded && (isCityDataLoading === false)) &&
      (campaignSummaryerror !== null ? true : isCampaignSummaryLoaded && (isCampaignSummaryLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    childDonutCampaignObjectiveError, childDonutCampaignObjectiveLoaded, childDonutCampaignObjectiveLoading,
    lineChartCampaignObjectiveError, lineChartCampaignObjectiveLoaded, lineChartCampaignObjectiveLoading,
    gendererror, isGenderLoaded, isGenderLoading,
    stateDataerror, isStateDataLoaded, isStateDataLoading,
    countryDataerror, isCountryDataLoaded, isCountryDataLoading,
    deviceDistributionerror, isDeviceDistributionLoaded, isDeviceDistributionLoading,
    campaignSummaryerror, isCampaignSummaryLoaded, isCampaignSummaryLoading,
    ageDistributionerror, isAgeDistributionLoaded, isAgeDistributionLoading,
    cityDataerror, isCityDataLoaded, isCityDataLoading,
  ])


  return (
    <Wrapper>
      {metricCardsSection}
      <div
        id="campaign_objective_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">Objective Wise Distribution</h2>
            </div>
            <Modal
              open={isLineChartCampaignOpen}
              setOpen={setIsLineChartCampaignOpen}
              dropdown={
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={campaignObjectiveDropdownOptions}
                  className="form_dropdown section_dropdown ml_20"
                  value={selectedCampaignObjectiveDropdownValue}
                  selectedOptions={selectedCampaignObjectiveDropdownValue}
                  setStatedropdown={campaignObjectiveDropdownHandler}
                />
              }
              exportDropdown={
                <ExportDropdown
                  sectionId={"multiLineChart_2"}
                  sectionName={"Campaign Objective Wise distribution"}
                  sectionData={campaignObjectCSV}
                />}
              parentId={"campaign_objective_section"}
              title={`Objective Wise Distribution`}
              chart={lineChartCampaignSection2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsLineChartCampaignOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={campaignObjectiveDropdownOptions}
                className="form_dropdown section_dropdown ml_20"
                value={selectedCampaignObjectiveDropdownValue}
                selectedOptions={selectedCampaignObjectiveDropdownValue}
                setStatedropdown={campaignObjectiveDropdownHandler}
              />
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"campaign_objective_section"}
                sectionName={"Campaign Objective Wise distribution"}
                sectionData={campaignObjectCSV}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_1 ">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {childDonutCampaignChart}
              </div>
            </div>
          </div>
          <div className="grid col_span_3 ">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {lineChartCampaignSection}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section_card section_dropdown_header">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">Performance By KPI</h2>
            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={midSectionDropdonwOptions}
              className="form_dropdown section_dropdown"
              value={selectedMidSectionDropdownOption}
              selectedOptions={selectedMidSectionDropdownOption}
              setStatedropdown={midSectionDropdownSelectionHandler}
            />
          </div>
        </div>
      </div>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div id="audience_distribution" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                {AudienceDistributionSectionTitle} {": " + selectedMidSectionDropdownOption.label} </h2>
            </div>

            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"audience_distribution"}
                sectionName={"Audience Distribution"}
                sectionData={maleFemaleDataCSV}

              />
            </div>
          </div>
          <div className="grid grid_cols_3">
            <div className="grid col_span_1 card_border_right flex column">
              <div className="grid grid_cols_4 section_card_body">
                <div className="grid col_span_4 flex column justify_center align_center">
                  {maleAudienceDistributionIcon}
                </div>
              </div>
            </div>
            <div className="grid col_span_1 card_border_right flex column">
              <div className="grid grid_cols_4 section_card_body">
                <div className="grid col_span_4 flex column justify_center align_center">
                  {femaleAudienceDistributionIcon}
                </div>

              </div>
            </div>
            <div className="grid col_span_1 flex column">
              <div className="grid grid_cols_4 section_card_body">
                <div className="grid col_span_4 flex column justify_center align_center">
                  {unknownAudienceDistributionIcon}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="device_distribution" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                Device Distribution{": " + selectedMidSectionDropdownOption.label}</h2>
            </div>

            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"device_distribution"}
                sectionName={"CampaignDeviceDistDynaLabels_Device Distribution"}
                sectionData={deviceDistributionExport}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {deviceDistributionChart}
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid_cols_4 grid_margin_bottom">

        <div id="age_distribution" className="grid col_span_4 section_card">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                Age Distribution {": " + selectedMidSectionDropdownOption.label}</h2>
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown

                sectionId={"age_distribution"}
                sectionName={`AgeBarChartCampaignDynalabelsBing_Audience Distribution - Age_${selectedMidSectionDropdownOption?.value ?? ''}`}
                sectionData={ageDistribution}
              />
            </div>

          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {ageDistributionChart}
            </div>
          </div>
        </div>
      </div>


      <div id="audience_distri_country_city_section">
        <div className="section_card section_dropdown_header">
          <div className="grid grid_cols_1">
            <div className="section_card_header">
              <div className="section_info">   <h2 className="section_card_title">
                Audience Distribution
              </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="grid  grid_margin_bottom ">
          <Tab
            varient={"primary"}
            tabData={tabData}
            activeTab={selectedTab}
            handleCallback={(event) => handleCallback(event)}
          />
        </div>

        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          {selectedTab === "By Country" && (
            <Wrapper>
              {audienceDistributionCountry}
            </Wrapper>
          )}
          {selectedTab === "By State" && (
            <Wrapper>
              {audienceDistributionState}
            </Wrapper>
          )}
          {selectedTab === "By City" && (
            <Wrapper>
              {audienceDistributionCity}
            </Wrapper>
          )}

        </div>
      </div>


      {campaignSummaryTable}
    </Wrapper>
  );
};

export default CampaignBingOverallOverall;
