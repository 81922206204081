import { useContext, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import FullPageExportContext from "../store/fullPageExportContext";
import AuthContext from "../store/authContext";


// COMPONENTS
import axios from "../components/helpers/axios";
import Wrapper from "../components/helpers/Wrapper";

// CHARTS
import LineChart from "../components/ui/charts/LineChart";

// UTILS
import ErrorBoundary from "../utils/ErrorBoundary";

import { BlueColorStart500, COKE_COMPANY_ID } from "../constants/constants";

import { DataFormatterExtraFields } from "../utils/DataFormatter";
import BarChart from "../components/ui/charts/BarChart";
import PieofaPieChart from "../components/ui/charts/PieofaPieChart";
import NoDataAvailableChecker from "../utils/NoDataAvailableChecker";
import Loader from "../components/ui/loaders/Loader";
import ServerErrorsLoader from "../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../components/ui/loaders/NoDataAvailable";
import CheckBoxDatatable from "../components/ui/tables/CheckboxDatatable";
import { TableHeaders, TableSampleData } from "../data/tableDataSamples";

const DashboardTest = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport } = useContext(FullPageExportContext);

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);


  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (
    globalSelectedBrandIds.length === 0 ||
    globalSelectedBrandIds === undefined
  ) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // GLOBAL CAMPAIGN DROPDOWN
  const selectedCampaignPlatformsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignPlatform
  );
  const selectedCampaignObjectivesFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignObjectives
  );
  const selectedCampaignsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaigns
  );
  const selectedBucketsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedBuckets
  );

  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (
    selectedCampaignPlatformsFromFilter.length === 0 ||
    selectedCampaignPlatformsFromFilter === undefined
  ) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(",");
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (
    selectedCampaignObjectivesFromFilter.length === 0 ||
    selectedCampaignObjectivesFromFilter === undefined
  ) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(",");
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (
    selectedCampaignsFromFilter.length === 0 ||
    selectedCampaignsFromFilter === undefined
  ) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);




  const rawData = {
    data: [
      {
        data_date: "Week 35 2023",
        no_of_videos: 7.0,
        total_views: 100.0,
        min_viral_grade: 2,
        max_viral_grade: 5,
      },
      {
        data_date: "Week 36 2023",
        no_of_videos: 40.0,
        total_views: 80.0,
        min_viral_grade: 10,
        max_viral_grade: 30,
      },
    ],
  }



  // CONTENT ANALYSIS
  const [lineChartData, setLineChartData] = useState([]);
  const [lineChartLabel, setLineChartLabel] = useState(
    []
  );
  const [isLineChartLoading, setLineChartIsLoading] =
    useState(false);
  const [isLineChartLoaded, setLineChartIsLoaded] = useState(false);
  const [lineChartError, setLineChartError] = useState(null);

  // TO FETCH DATA FROM API
  const fetchLineChartHandler = async () => {
    setLineChartIsLoading(true);
    setLineChartIsLoaded(false);
    setLineChartError(null);


    try {
      /*  const res = await axios.get(
         "social/facebook/post/overall/content-analysis/",
         config
       ); */


      const allData = rawData;
      const selectedLabel = "data_date";
      const selectedMetrics = ["no_of_videos", "total_views"];
      const extraFields = ["min_viral_grade", "max_viral_grade"];

      const convertedData = DataFormatterExtraFields(
        allData,
        selectedLabel,
        selectedMetrics,
        extraFields,
        "lineChart"
      );

      setLineChartData(
        convertedData.data);
      setLineChartLabel(
        convertedData.dimension);
      setLineChartIsLoading(false);
      setLineChartIsLoaded(true);
      setLineChartError(null);
    } catch (error) {
      setLineChartData([]);
      setLineChartLabel([]);
      setLineChartIsLoading(false);
      setLineChartIsLoaded(false);
      setLineChartError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let lineChart = (
    <Wrapper>
      <Loader loaderType="lineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isLineChartLoaded && !isLineChartLoading) {
    /*   if (
        NoDataAvailableChecker(lineChartData) ||
        NoDataAvailableChecker(lineChartLabel)
      ) {
        lineChart = <Wrapper> <NoDataAvailableLoader chartType="pieChartType" /></Wrapper>
      } else { */

    lineChart = (
      <Wrapper>
        <ErrorBoundary chartType="barChartType">
          <LineChart
            chartClass="section_card_chart"
            chartData={lineChartData}
            chartId={"chart" + Math.random()}
            chartLabels={lineChartLabel}
            showLegend={true}
          />
        </ErrorBoundary>
      </Wrapper>
    );
    /* } */
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (lineChartError) {
    lineChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={lineChartError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isLineChartLoading) {
    lineChart = <Wrapper> <Loader loaderType="lineChartLoader" /></Wrapper>
  }



  const [barData, setBarData] = useState([]);
  const [barLabel, setBarLabel] = useState([]);
  // TO FETCH METRIC DATA FROM API
  const fetchBarChart = async () => {
    try {
      const allData = rawData;
      const selectedLabel = "data_date";
      const selectedMetrics = ["no_of_videos"];
      const extraFields = [];

      const convertedData = DataFormatterExtraFields(
        allData,
        selectedLabel,
        selectedMetrics,
        extraFields,
        "barChart"
      );
      setBarData(convertedData.data);
      setBarLabel(convertedData.dimension);
    } catch (error) {
      setBarData([]);
      setBarLabel([]);
    }
  };







  // CONTENT ANALYSIS
  const [pieOfPieChartData, setPieOfPieChartData] = useState([]);
  const [pieOfPieChartLabel, setPieOfPieChartLabel] = useState(
    []
  );
  const [isPieOfPieChartLoading, setPieOfPieChartIsLoading] =
    useState(false);
  const [isPieOfPieChartLoaded, setPieOfPieChartIsLoaded] = useState(false);
  const [pieOfPieChartError, setPieOfPieChartError] = useState(null);

  // TO FETCH DATA FROM API
  const fetchPieOfPieChartHandler = async () => {
    setPieOfPieChartIsLoading(true);
    setPieOfPieChartIsLoaded(false);
    setPieOfPieChartError(null);


    try {
      /*  const res = await axios.get(
         "social/facebook/post/overall/content-analysis/",
         config
       ); */


      const allData = rawData;
      const selectedLabel = "data_date";
      const selectedMetrics = ["no_of_videos"];
      const extraFields = ["min_viral_grade", "max_viral_grade"];

      const convertedData = DataFormatterExtraFields(
        allData,
        selectedLabel,
        selectedMetrics,
        extraFields,
        "pieOfPieChart"
      );


      setPieOfPieChartData(
        convertedData.data);
      setPieOfPieChartLabel(
        convertedData.dimension);
      setPieOfPieChartIsLoading(false);
      setPieOfPieChartIsLoaded(true);
      setPieOfPieChartError(null);
    } catch (error) {
      setPieOfPieChartData([]);
      setPieOfPieChartLabel([]);
      setPieOfPieChartIsLoading(false);
      setPieOfPieChartIsLoaded(false);
      setPieOfPieChartError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let pieOfPieChart = (
    <Wrapper>
      <Loader loaderType="pieChartLoader" />fgdsgg
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isPieOfPieChartLoaded && !isPieOfPieChartLoading) {
    /*   if (
        NoDataAvailableChecker(pieOfPieChartData) ||
        NoDataAvailableChecker(pieOfPieChartLabel)
      ) {
        pieOfPieChart = <Wrapper> <NoDataAvailableLoader chartType="pieChartType" /></Wrapper>
      } else { */

    pieOfPieChart = (
      <Wrapper>
        <ErrorBoundary chartType="pieChartType">
          <PieofaPieChart
            chartId="content_analysis_pie_of_pie_chart"
            chartClass="section_card_chart"
            chartData={pieOfPieChartData}
            chartLabels={pieOfPieChartLabel}
          />
        </ErrorBoundary>
      </Wrapper>
    );
    /* } */
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (pieOfPieChartError) {
    pieOfPieChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="pieChartType"
          error={pieOfPieChartError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPieOfPieChartLoading) {
    pieOfPieChart = <Wrapper> <Loader loaderType="pieChartLoader" /></Wrapper>
  }



  useEffect(() => {
    fetchLineChartHandler();
    fetchBarChart();
    fetchPieOfPieChartHandler();
  }, []);



  return (
    <Wrapper>
      <div className="content">
        <div className="content_section">



          <div className="page_content">

            <div className="grid_margin_bottom">
              <CheckBoxDatatable
                tableID={'new-table'}
                tableTitle={'Limited Columns'}
                tableData={TableSampleData ?? []}
                dynamicColumns={true}
                checkbox={true}
                onCheckboxClickHandler={() => console.log('hii')}
                tableHeader={TableHeaders}
                tableLength={2}
                isFooterShow={true}
                hasTableLenghtDropdown={true}
                searchPlaceHolder={"Search Age"}
              />
            </div>


            <div className="section_card section_dropdown_header">
              <div className="grid grid_cols_1">
                <div className="section_card_header">
                  <h2 className="section_card_title">Raw Data Demo Charts</h2>
                </div>
              </div>
            </div>

            <div className="grid grid_cols_4 fullpage_section_card section_card grid_margin_bottom">
              <div className="section_card_body">
                <div id={"chart" + Math.random()}>
                  <div className="grid  section_card">
                    <div className="section_card_header">
                      <div className="section_info">
                        <h2 className="section_card_title">LineChart</h2>
                      </div>
                    </div>
                    <div className="grid grid_cols_1 section_card_body">
                      <div className="section_card_chart_wrapper">
                        {lineChart}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid_cols_4 fullpage_section_card section_card grid_margin_bottom">
              <div className="section_card_body">
                <div id={"chart" + Math.random()}>
                  <div className="grid  section_card">
                    <div className="section_card_header">
                      <div className="section_info">
                        <h2 className="section_card_title">BarChart</h2>
                      </div>
                    </div>
                    <div className="grid grid_cols_1 section_card_body">
                      <div className="section_card_chart_wrapper">
                        <ErrorBoundary chartType="barChartType">


                          <BarChart
                            chartClass="section_card_chart"
                            chartData={barData}
                            chartId={"chart" + Math.random()}
                            chartLabels={barLabel}
                            showLegend={true}
                            chartColorSet={BlueColorStart500}
                            showLabels={true}
                            minimumGridDistance={0}
                            percentageTooltip={true}
                          />
                        </ErrorBoundary>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid_cols_4 fullpage_section_card section_card grid_margin_bottom">
              <div className="section_card_body">
                <div id={"chart" + Math.random()}>
                  <div className="grid  section_card">
                    <div className="section_card_header">
                      <div className="section_info">
                        <h2 className="section_card_title">Pie of Pie</h2>
                      </div>
                    </div>
                    <div className="grid grid_cols_1 section_card_body">
                      <div className="section_card_chart_wrapper">
                        {pieOfPieChart}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div></div></div>
    </Wrapper>
  );
};

export default DashboardTest;
