import { useEffect, useRef, useState } from "react";

import Wrapper from "../helpers/Wrapper";

import {
  AddressLineCharLimit,
  LandmarkCharLimit,
  PincodeCharLimit,
} from "../../constants/inputLimit";
import {
  API_URL,
  InvalidInputMsg,
  MaxLimitReachedInputMsg,
  MinimumInputMsg,
  OnlyNosRegex,
  RequiredField,
} from "../../constants/constants";

import Dropdown from "../ui/dropdown/Dropdown";

const RegistrationPersonalLocationForm = ({
  formData,
  setFormData,
  formValidation,
  setFormValidation,
  formStepsData,
  setFormStepsData,
}) => {
  const addressLineOneInputRef = useRef();
  const addressLineTwoInputRef = useRef();
  const landmarkInputRef = useRef();
  const pincodeInputRef = useRef();

  const [timezoneIsTouched, setTimezoneIsTouched] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState();
  const [timezoneIsValid, setTimezoneIsValid] = useState(false);
  const [timezonError, setTimezoneError] = useState();

  const [addressLineOneIsTouched, setAddressLineOneIsTouched] = useState(false);
  const [addressLineOneIsValid, setAddressLineOneIsValid] = useState(false);
  const [addressLineOneError, setAddressLineOneError] = useState();

  const [addressLineTwoIsTouched, setAddressLineTwoIsTouched] = useState(false);
  const [addressLineTwoIsValid, setAddressLineTwoIsValid] = useState(false);
  const [addressLineTwoError, setAddressLineTwoError] = useState();

  const [landmarkIsTouched, setLandmarkIsTouched] = useState(false);
  const [landmarkIsValid, setLandmarkIsValid] = useState(false);
  const [landmarkError, setLandmarkError] = useState();

  const [pincodeIsTouched, setPincodeIsTouched] = useState(false);
  const [pincodeIsValid, setPincodeIsValid] = useState(false);
  const [pincodeError, setPincodeError] = useState();

  const [timezoneOptions, setTimezoneOptions] = useState([]);

  // TIMEZONE
  const timezoneChangeHandler = (event) => {
    setTimezoneIsTouched(true);
    setSelectedTimezone(event);
    setFormValidation({ ...formValidation, timezone: true });
    setFormData({ ...formData, timezone: event === null ? "" : event.value });
    if (event === null) {
      setTimezoneError(RequiredField);
      setTimezoneIsValid(false);
      setFormValidation({ ...formValidation, timezone: false });
    } else {
      setTimezoneIsValid(true);
      setTimezoneError();
      setFormValidation({ ...formValidation, timezone: true });
    }
  };

  const dropdownOnBlurHandler = () => {
    if (formData.timezone === "") {
      setTimezoneError(RequiredField);
      setTimezoneIsValid(false);
      setTimezoneIsTouched(true);
      setFormValidation({ ...formValidation, timezone: false });
    } else {
      setTimezoneIsValid(true);
      setTimezoneError();
      setTimezoneIsTouched(false);
      setFormValidation({ ...formValidation, timezone: true });
    }
  };

  // ADDRESS LINE 1
  const addressLineOneChangeHandler = () => {
    setAddressLineOneError();
    setAddressLineOneIsTouched(true);
    setAddressLineOneIsValid(true);
    setFormData({
      ...formData,
      addressLine1: addressLineOneInputRef.current.value,
    });
    if (addressLineOneInputRef.current.value.length >= AddressLineCharLimit) {
      setAddressLineOneError(MaxLimitReachedInputMsg + AddressLineCharLimit);
      setAddressLineOneIsValid(false);
    }
  };
  const validateAddressLineOneHandler = () => {
    if (addressLineOneInputRef.current.value.length >= AddressLineCharLimit) {
      setAddressLineOneIsTouched(true);
      setAddressLineOneError();
      setAddressLineOneIsValid(true);
    }
  };

  // ADDRESS LINE 2
  const addressLineTwoChangeHandler = () => {
    setAddressLineTwoIsTouched(true);
    setAddressLineTwoError();
    setAddressLineTwoIsValid(true);
    setFormData({
      ...formData,
      addressLine2: addressLineTwoInputRef.current.value,
    });
    if (addressLineTwoInputRef.current.value.length >= AddressLineCharLimit) {
      setAddressLineTwoError(MaxLimitReachedInputMsg + AddressLineCharLimit);
      setAddressLineTwoIsValid(false);
    }
  };
  const validateAddressLineTwoHandler = () => {
    if (addressLineTwoInputRef.current.value.length >= AddressLineCharLimit) {
      setAddressLineTwoIsTouched(true);
      setAddressLineTwoError();
      setAddressLineTwoIsValid(true);
    }
  };

  // LANDMARK
  const landmarkChangeHandler = () => {
    setLandmarkError();
    setLandmarkIsTouched(true);
    setLandmarkIsValid(true);
    setFormData({ ...formData, landmark: landmarkInputRef.current.value });
    if (landmarkInputRef.current.value.length >= LandmarkCharLimit) {
      setLandmarkError(MaxLimitReachedInputMsg + LandmarkCharLimit);
      setLandmarkIsValid(false);
    }
  };
  const validateLandmarkChangeHandler = () => {
    if (landmarkInputRef.current.value.length >= LandmarkCharLimit) {
      setLandmarkIsTouched(true);
      setLandmarkError();
      setLandmarkIsValid(true);
    }
  };

  // PINCODE
  const pincodeChangeHandler = () => {
    setPincodeIsTouched(true);
    if (
      pincodeInputRef.current.value === "" ||
      OnlyNosRegex.test(pincodeInputRef.current.value)
    ) {
      setPincodeError();
      setPincodeIsValid(true);
      setFormData({ ...formData, pincode: pincodeInputRef.current.value });
      if (pincodeInputRef.current.value.length < PincodeCharLimit) {
        setPincodeError(MinimumInputMsg + PincodeCharLimit);
        setPincodeIsValid(false);
        setFormValidation({ ...formValidation, timezone: false });
      } else if (pincodeInputRef.current.value.length === PincodeCharLimit) {
        setPincodeError();
        setPincodeIsValid(true);
        setFormValidation({ ...formValidation, timezone: true });
      } else {
        setPincodeError(MaxLimitReachedInputMsg + PincodeCharLimit);
        setPincodeIsValid(false);
        setFormValidation({ ...formValidation, timezone: false });
      }
    } else {
      setPincodeIsValid(false);
      setPincodeError(InvalidInputMsg);
    }
  };

  const validatePincodeHandler = () => {
    if (pincodeInputRef.current.value.length >= PincodeCharLimit) {
      setPincodeIsTouched(true);
      setPincodeError();
      setPincodeIsValid(true);
    }
  };

  const timezoneInputIsInvalid = !timezoneIsValid && timezoneIsTouched;
  const timezoneInputClasses = timezoneInputIsInvalid
    ? "form_dropdown invalid"
    : "form_dropdown";

  const addressLineOneInputIsInvalid =
    !addressLineOneIsValid && addressLineOneIsTouched;
  const addressLineOneInputClasses = addressLineOneInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  const addressLineTwoInputIsInvalid =
    !addressLineTwoIsValid && addressLineTwoIsTouched;
  const addressLineTwoInputClasses = addressLineTwoInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  const landmarkInputIsInvalid = !landmarkIsValid && landmarkIsTouched;
  const landmarkInputClasses = landmarkInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  const pincodeInputIsInvalid = !pincodeIsValid && pincodeIsTouched;
  const pincodeInputClasses = pincodeInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  // FETCH TIMEZONE
  useEffect(() => {
    fetch(API_URL + "master/timezone", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((result) => {
        let data_list = [];
        result["timezones"].map((timezone, index) => {
          try {
            let a = {
              label: timezone,
              value: timezone,
            };
            data_list.push(a);
          } catch (e) { }
        });
        setTimezoneOptions(data_list);
      });
  }, []);

  useEffect(() => {
    if (formValidation.timezone === true) {
      setFormStepsData({ ...formStepsData, secondStepIsValid: true });
    } else {
      setFormStepsData({ ...formStepsData, secondStepIsValid: false });
    }
  }, [formValidation]);

  return (
    <Wrapper>
      <div className="form_group">
        <Dropdown
          className={timezoneInputClasses}
          placeholder="*Please Select Your Timezone"
          options={timezoneOptions}
          value={selectedTimezone}
          selectedOptions={
            formData.timezone !== ""
              ? { value: formData.timezone, label: formData.timezone }
              : ""
          }
          setStatedropdown={timezoneChangeHandler}
          onBlur={dropdownOnBlurHandler}
        />
        <div className="error_section">
          {timezoneInputIsInvalid && (
            <h6 className="error_text">{timezonError}</h6>
          )}
        </div>
      </div>
      <div className="form_group">
        <input
          className={addressLineOneInputClasses}
          type="text"
          name="addressline1"
          id="address_line_1"
          autoComplete="off"
          maxLength={AddressLineCharLimit}
          placeholder="Please Enter Your Address Line 1"
          value={formData.addressLine1}
          onChange={addressLineOneChangeHandler}
          onBlur={validateAddressLineOneHandler}
          ref={addressLineOneInputRef}
        />
        <div className="error_section">
          {addressLineOneInputIsInvalid && (
            <h6 className="error_text">{addressLineOneError}</h6>
          )}
        </div>
      </div>
      <div className="form_group">
        <input
          className={addressLineTwoInputClasses}
          type="text"
          name="addressline2"
          id="address_line_2"
          autoComplete="off"
          maxLength={AddressLineCharLimit}
          placeholder="Please Enter Your Address Line 2"
          value={formData.addressLine2}
          onChange={addressLineTwoChangeHandler}
          onBlur={validateAddressLineTwoHandler}
          ref={addressLineTwoInputRef}
        />
        <div className="error_section">
          {addressLineTwoInputIsInvalid && (
            <h6 className="error_text">{addressLineTwoError}</h6>
          )}
        </div>
      </div>
      <div className="form_group">
        <input
          className={landmarkInputClasses}
          type="text"
          name="landmark"
          id="landmark"
          autoComplete="off"
          maxLength={LandmarkCharLimit}
          placeholder="Please Enter Your Landmark"
          value={formData.landmark}
          onChange={landmarkChangeHandler}
          onBlur={validateLandmarkChangeHandler}
          ref={landmarkInputRef}
        />
        <div className="error_section">
          {landmarkInputIsInvalid && (
            <h6 className="error_text">{landmarkError}</h6>
          )}
        </div>
      </div>
      <div className="form_group">
        <input
          className={pincodeInputClasses}
          type="text"
          name="pincode"
          id="pincode"
          autoComplete="off"
          maxLength={PincodeCharLimit}
          placeholder="Please Enter Your Pincode"
          value={formData.pincode}
          onChange={pincodeChangeHandler}
          onBlur={validatePincodeHandler}
          ref={pincodeInputRef}
        />
        <div className="error_section">
          {pincodeInputIsInvalid && (
            <h6 className="error_text">{pincodeError}</h6>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default RegistrationPersonalLocationForm;
