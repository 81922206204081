import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5wc from "@amcharts/amcharts5/wc";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";

am5.addLicense("AM5C258896422");

const WordCloud = ({
  chartId,
  chartClass,
  chartData
}) => {

  useLayoutEffect(() => {
    var root = am5.Root.new(chartId);

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    // Add series
    // https://www.amcharts.com/docs/v5/charts/word-cloud/
    var series = root.container.children.push(am5wc.WordCloud.new(root, {
      maxCount: 100,
      minWordLength: 2,
      maxFontSize: am5.percent(50),
      minFontSize: am5.percent(10),
      colors: am5.ColorSet.new(root, {
        colors: [
          am5.color(0x11A1FD),
          am5.color(0x5A75F9),
          am5.color(0x07C180),
          am5.color(0xFF9931),
          am5.color(0x7D9EB5),
          am5.color(0x085DB6),
          am5.color(0x3247B5),
          am5.color(0x038673),
          am5.color(0xB45917),
          am5.color(0x486F88),
          am5.color(0x0C7DD9),
          am5.color(0x4259D4),
          am5.color(0x06A57D),
          am5.color(0xDB7823),
          am5.color(0xA7C1D2),
          am5.color(0x4CC3FD),
          am5.color(0x8298FB),
          am5.color(0x3CD795),
          am5.color(0xFFB866),
          am5.color(0xCEDFE8),
        ]
      }),
      text: chartData,
    }));

    // to format numbers
    root.numberFormatter.setAll({
      numberFormat: "#.##a",

      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: [
        { number: 1e3, suffix: "K" },
        { number: 1e6, suffix: "M" },
        { number: 1e9, suffix: "B" },
      ],

      // Do not use small number prefixes at all
      smallNumberPrefixes: [],
    });


    // Configure labels
    series.labels.template.setAll({
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 5,
      paddingRight: 5,
      fontFamily: "Ubuntu"
    });

    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <Wrapper>
      <div id={chartId} className={chartClass}></div>
    </Wrapper>
  );
};

WordCloud.propTypes = {
  chartId: PropTypes.string,
  chartClass: PropTypes.string,
  chartData: PropTypes.string,
};

export default WordCloud;
