import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Wrapper from "../../../components/helpers/Wrapper";
import PageHeader from "../../../components/layout/pageHeader";
import Button from "../../../components/ui/Button";
import Tab from "../../../components/ui/Tab";
import BucketLists from "./bucketLists/BucketLists";
import CampaignList from "./campaignLists/camapignList";
import FullPageExportContext from "../../../store/fullPageExportContext";
import CheckIcon from "../../../assets/icons/CheckIcon";
import NewCampaignList from "./campaignLists/newCampaignList";
import NewBucketLists from "./bucketLists/newBucketList";
import Alert from "../../../components/ui/alert/Alert";

const CampaignAdmin = () => {
    const { setExportPageName } = useContext(FullPageExportContext);
    const { state } = useLocation();
    const navigate = useNavigate();

    // SELECT TAB
    const [selectedTab, setSelectedTab] = useState("Bucket List");

    const tabData = ["Bucket List", "Campaign List"];

    const handleCallback = (event) => {
        if (selectedTab !== event) {
            setSelectedTab(event);
        }
    };

    useEffect(() => {

        setExportPageName("Admin " + selectedTab)
    }, [selectedTab])


    // var alertBoxSection;
    // const [alertType, setAlertType] = useState("");
    // const [alertTypeValue, setAlertTypeValue] = useState("");
    // const [alertBoxValue, setAlertBoxValue] = useState("hide")

    // if (alertType === "Admin Create") {
    //     alertBoxSection = (
    //         <Wrapper>
    //             <dialog open className="admin_alert_box success">
    //                 <button className="close_button" type="button" onClick={() => setAlertBoxValue("hide")}>&#x2715; </button>
    //                 <div className="alert_main_wrap">
    //                     <div className="check_icon_wrap">
    //                         <CheckIcon className="alert_check_icon" />
    //                     </div>
    //                     <div>
    //                         <h4 className="alert_title">Bucket Created.</h4>
    //                         <div className="alert_body">Bucket name <b>{alertTypeValue}</b> has been created successfully!</div>
    //                     </div>
    //                 </div>
    //             </dialog>
    //         </Wrapper>
    //     )
    // } else if (alertType === "Admin Delete") {
    //     alertBoxSection = (
    //         <Wrapper>
    //             <dialog open className="admin_alert_box success">
    //                 <button className="close_button" type="button" onClick={() => setAlertBoxValue("hide")}>&#x2715; </button>
    //                 <div className="alert_main_wrap">
    //                     <div className="check_icon_wrap">
    //                         <CheckIcon className="alert_check_icon" />
    //                     </div>
    //                     <div>
    //                         <h4 className="alert_title">Bucket Deleted.</h4>
    //                         <div className="alert_body">Bucket name <b>{alertTypeValue}</b> has been deleted successfully!</div>
    //                     </div>
    //                 </div>


    //             </dialog>
    //         </Wrapper>
    //     )
    // }


    // function triggerAlertBox() {
    //     setAlertBoxValue("show")
    //     setTimeout(() => {
    //         setAlertBoxValue("hide")
    //     }, 4000)
    // }

    // useEffect(() => {
    //     triggerAlertBox()
    // }, [alertType, alertTypeValue])

    // useEffect(() => {
    //     if (state !== null) {
    //         setAlertType(state.alertType)
    //         setAlertTypeValue(state.alertTypeValue)

    //         navigate({ state: { alertType: '', alertTypeValue: '' } })
    //     }
    // }, [state])

    const [alertProps, setAlertProps] = useState({
        type: '',
        value: 0,
        name: '',
        error: null
    })


    return (
        <Wrapper>
            <div className="content">
                <div className="content_section">
                    <div className="flex justify_between align_center">
                        <PageHeader pageTitle={"Campaign"} />
                        <div className="p_20 flex justify_end">
                            <div className="btn_wrapper ">
                                <Link to="/admin/campaign/create-bucket">
                                    <Button className="primary_btn" type="button">
                                        Create New Bucket
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <Alert
                        alertType={alertProps.type}
                        alertTypeValue={alertProps.value}
                        bucketName={alertProps.name}
                        editBucketerror={alertProps.error}

                    />
                    {/* <div className={`admin_alert_wrap ${alertBoxValue}`}>
                        {alertBoxSection}
                    </div> */}
                    <div className="page_content">
                        <div className="dashboard_home">
                            <div className="grid grid_margin_bottom">
                                <Tab
                                    varient={"primary"}
                                    tabData={tabData}
                                    activeTab={selectedTab}
                                    handleCallback={(event) => handleCallback(event)}
                                />
                            </div>
                            {selectedTab === "Bucket List" && <NewBucketLists setAlertProps={setAlertProps} />}
                            {selectedTab === "Campaign List" && <NewCampaignList isCreatableDropdown={true} />}
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
export default CampaignAdmin;