import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import Wrapper from "../../../../components/helpers/Wrapper";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import Datatable from "../../../../components/ui/tables/Datatable";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";

// ASSETS
import FemaleIcon from "../../../../assets/icons/FemaleIcon";
import MaleIcon from "../../../../assets/icons/MaleIcon";
import UnknownGenderIcon from "../../../../assets/icons/UnknownGenderIcon";

// CHARTS
import LineChart from "../../../../components/ui/charts/LineChart";
import BarChart from "../../../../components/ui/charts/BarChart";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import PieofaPieChart from "../../../../components/ui/charts/PieofaPieChart";

// UTILS
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import NumberFormatter from "../../../../utils/NumberFormatter";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";
import { COKE_COMPANY_ID } from "../../../../constants/constants";
import PartitionedLineChart from "../../../../components/ui/charts/PartitionedLineChart";
import ExpandIcon from "../../../../assets/icons/ExpandIcon";
import Modal from "../../../../components/ui/Modal";
import { getHeaderKeysWithLabels, getUniqueValuesFromArrofObj } from "../../../../utils/Utils";
import EventTracker from "../../../../components/eventsTracking/EventTracker";


const CampaignGoogleOverall = ({ topCardData }) => {

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Campaign"
  let pagePlatform = "Google"
  let pagePrimaryTab = "Overall"

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled } = useContext(
    FullPageExportContext
  );

  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  let companyID = userData?.company?.id ?? "";

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // SELECTED CAMPAIGNS
  const selectedCampaignPlatformsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignPlatform
  );
  const selectedCampaignObjectivesFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignObjectives
  );
  const selectedCampaignsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaigns
  );
  const selectedBucketsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedBuckets
  );
  const selectedCampaignIdandPlatform = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignIDandPlatform
  );

  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (selectedCampaignPlatformsFromFilter.length === 0 || selectedCampaignPlatformsFromFilter === undefined) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(",");
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (selectedCampaignObjectivesFromFilter.length === 0 || selectedCampaignObjectivesFromFilter === undefined) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(",");
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (selectedCampaignsFromFilter.length === 0 || selectedCampaignsFromFilter === undefined) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(",");
  }

  let selectedIdandPlatform = null;
  if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
    selectedIdandPlatform = null;
  } else {
    selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // // CAMPAIGN OBJECTIVE WISE DISTRIBUTION
  // const [
  //   campaignObjectivewiseDistribution,
  //   setCampaignObjectivewiseDistribution,
  // ] = useState([]);
  // const [
  //   campaignObjectivewiseDistributionDataLabels,
  //   setCampaignObjectivewiseDistributionDataLabels,
  // ] = useState([]);
  // const [
  //   isContentAnalysisLoading,
  //   setCampaignObjectivewiseDistributionIsLoading,
  // ] = useState(false);
  // const [
  //   isContentAnalysisLoaded,
  //   setCampaignObjectivewiseDistributionIsLoaded,
  // ] = useState(false);
  // const [
  //   campaignObjectivewiseDistributionerror,
  //   setCampaignObjectivewiseDistributionError,
  // ] = useState(null);

  // MID SECTION DROPDOWN


  // DROPDOWN OPTIONS
  const midSectionDropdonwOptions = [
    { value: "impressions", label: "Impressions" },
    { value: "clicks", label: "Clicks" },
    { value: "conversions", label: "Conversions" },
    { value: "spends", label: "Spends" },
  ];
  // DROPDOWN DEFAULT STATE
  const [
    selectedMidSectionDropdownOption,
    setSelectedMidSectionDropdownDropdownOption,
  ] = useState(midSectionDropdonwOptions[0]);


  // DROPDOWN OPTIONS SELECTION HANDLER
  const midSectionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMidSectionDropdownDropdownOption("");
    } else {
      setSelectedMidSectionDropdownDropdownOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Marketing Intelligence",
      "Campaigns",
      "Google",
      "Overall",
      null,
      "Performance By KPI",
      e.label
    );

    EventTracker({
      eventType: "dropdown_selected",
      module: pageModule,
      subModule: pageSubModule,
      platform: pagePlatform,
      tab: pagePrimaryTab,
      section: "Performance By KPI",
      dropdownValue: e === null ? "Overall" : e.label,
      ...(BRAND_ID && { brands: BRAND_ID }),
      date: {
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate,
        previousStartDate: selectedDates.prevStartDate,
        previousEndDate: selectedDates.prevEndDate,
      }
    })
  };

  // DROPDOWN DEFAULT STATE
  const [
    performanceLocationDropdownOption,
    setPerformanceLocationDropdownDropdownOption,
  ] = useState();

  // DROPDOWN OPTIONS
  const performanceLocationDropdonwOptions = [
    { value: "country", label: "Country" },
    { value: "city", label: "City" },
  ];

  // DROPDOWN OPTIONS SELECTION HANDLER
  const performanceLocationDropdownSelectionHandler = (e) => {
    if (e === null) {
      setPerformanceLocationDropdownDropdownOption("");
    } else {
      setPerformanceLocationDropdownDropdownOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Marketing Intelligence",
      "Campaigns",
      "Google",
      "Overall",
      null,
      "Performance By KPI City / Country",
      e
    );
  };

  // // DROPDOWN OPTIONS

  const [performanceSourceDropdownOptions, setPerformanceSourceDropdownOptions] = useState([]);
  const [isPerformanceSourceDropdownOptionsLoading, setPerformanceSourceDropdownOptionsLoading] = useState(true)

  // DROPDOWN DEFAULT STATE
  const [
    performanceSourceDropdownValue,
    setPerformanceSourceDropdownDropdownValue,
  ] = useState("");

  // DROPDOWN OPTIONS SELECTION HANDLER
  const performanceSourceDropdownSelectionHandler = (e) => {
    if (e === null) {
      setPerformanceSourceDropdownDropdownValue("");
      IAEvent_Dropdown_Visit(
        "Marketing Intelligence",
        "Campaigns",
        "Google",
        "Overall",
        null,
        "Performance By KPI Source",
        e
      );
    } else {
      setPerformanceSourceDropdownDropdownValue(e);
      IAEvent_Dropdown_Visit(
        "Marketing Intelligence",
        "Campaigns",
        "Google",
        "Overall",
        null,
        "Performance By KPI Source",
        e.label
      );
    }

    EventTracker({
      eventType: "dropdown_selected",
      module: pageModule,
      subModule: pageSubModule,
      platform: pagePlatform,
      tab: pagePrimaryTab,
      section: "Performance By KPI Source",
      dropdownValue: e === null ? "Overall" : e.label,
      ...(BRAND_ID && { brands: BRAND_ID }),
      date: {
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate,
        previousStartDate: selectedDates.prevStartDate,
        previousEndDate: selectedDates.prevEndDate,
      }
    })

  };

  const fetchPerformanceSourceDropdownHandler = async () => {

    setPerformanceSourceDropdownOptionsLoading(true);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,

        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/google/ad-network-type/",
        config
      );
      setPerformanceSourceDropdownOptions(res?.data ?? []);
      setPerformanceSourceDropdownOptionsLoading(false);

    } catch {
      setPerformanceSourceDropdownOptions([]);
      setPerformanceSourceDropdownOptionsLoading(true);
    }
  };

  // // PERFORMANCE
  // const [performance, setPerformance] = useState([]);
  // const [performanceAllDropdownsData, setPerformanceAllDropdownsData] = useState([]);
  // const [performanceDataLabels, setPerformanceDataLabels] = useState([]);
  // const [performanceAllDropdownsDataLabels, setPerformanceAllDropdownsDataLabels] = useState([]);
  // const [performanceDetails, setPerformanceDetails] = useState([]);
  // const [isPerformanceLoading, setPerformanceIsLoading] = useState(false);
  // const [isPerformanceLoaded, setPerformanceIsLoaded] = useState(false);
  // const [performanceerror, setPerformanceError] = useState(null);

  // DEVICE DISTRIBUTION
  const [deviceDistribution, setDeviceDistribution] = useState([]);
  const [deviceDistributionAllDropdownsData, setDeviceDistributionAllDropdownsData] = useState([]);
  const [isDeviceDistributionLoading, setDeviceDistributionIsLoading] = useState(false);
  const [isDeviceDistributionLoaded, setDeviceDistributionIsLoaded] = useState(false);
  const [deviceDistributionerror, setDeviceDistributionError] = useState(null);

  // AGE COUNT
  const [ageCount, setAgeCount] = useState([]);
  const [ageCountAllDropdownsData, setAgeCountAllDropdownsData] = useState([]);
  const [ageCountLabels, setAgeCountLabels] = useState([]);
  const [ageCountAllDropdownsDataLabels, setAgeCountAllDropdownsDataLabels] = useState([]);
  const [isAgeCountLoading, setAgeCountIsLoading] = useState(false);
  const [isAgeCountLoaded, setAgeCountIsLoaded] = useState(false);
  const [ageCounterror, setAgeCountError] = useState(null);

  /*   // GENDER COUNT
    const [genderCount, setGenderCount] = useState([]);
    const [genderCountAllDropdownsData, setGenderCountAllDropdownsData] =
      useState([]);
    const [genderCountLabels, setGenderCountLabels] = useState([]);
    const [
      genderCountAllDropdownsDataLabels,
      setGenderCountAllDropdownsDataLabels,
    ] = useState([]);
    const [isGenderCountLoading, setGenderCountIsLoading] = useState(false);
    const [isGenderCountLoaded, setGenderCountIsLoaded] = useState(false);
    const [genderCounterror, setGenderCountError] = useState(null);
   */
  // COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [countryAllDropdownsData, setCountryAllDropdownsData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: selectedMidSectionDropdownOption.label, field: "value" },
    { name: "Change", field: "percentage_growth" },
  ];

  // CITY
  const [cityData, setCityData] = useState([]);
  const [cityAllDropdownsData, setCityAllDropdownsData] = useState([]);
  const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  const [cityDataerror, setCityDataError] = useState(null);

  const CityTableDataHeaders = [
    { name: "City", field: "name" },
    { name: selectedMidSectionDropdownOption.label, field: "value" },
    { name: "Change", field: "percentage_growth" },
  ];

  // MALE COUNT
  const [totalMaleCount, setTotalMaleCount] = useState(0);
  const [totalMaleCountAllDropdownsData, setTotalMaleCountAllDropdownsData] = useState([]);
  const [isTotalMaleCountLoading, setTotalMaleCountLoading] = useState(false);
  const [isTotalMaleCountLoaded, setTotalMaleCountIsLoaded] = useState(false);
  const [maleCounterror, setMaleCountError] = useState(null);

  // FEMALE COUNT
  const [totalFemaleCount, setTotalFemaleCount] = useState(0);
  const [totalFemaleCountAllDropdownsData, setTotalFemaleCountAllDropdownsData,] = useState([]);
  const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
  const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
  const [femaleCounterror, setFemaleCountError] = useState(null);

  // UNKNOWN COUNT
  const [totalUnknownCount, setTotalUnknownCount] = useState(0);
  const [totalUnknownCountAllDropdownsData, setTotalUnknownCountAllDropdownsData] = useState([]);
  const [isUnknownCountLoading, setUnknownCountIsLoading] = useState(false);
  const [isUnknownCountLoaded, setUnknownCountIsLoaded] = useState(false);
  const [unknownCounterror, setUnknownCountError] = useState(null);

  // CAMPAIGN SUMMARY
  const [campaignSummary, setCampaignSummary] = useState([]);
  const [campaignSummaryTableHeaders, setCampaignSummaryTableHeaders] = useState([]);
  const [campaignSummaryAllDropdownsData, setCampaignSummaryAllDropdownsData] = useState([]);
  const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] = useState(false);
  const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
  const [campaignSummaryerror, setCampaignSummaryError] = useState(null);
  const [campaignSummaryExportData, setCampaignSummaryExportData] = useState([]);

  const [campaignSummaryTablePageData, setCampaignSummaryTablePageData] = useState({})


  // DEVICE DISTRIBUTION DROPDOWN OPTIONS
  const campaignSummaryOptions = [
    { value: "campaign", label: "Campaign" },
    { value: "ad", label: "Ad" },
    { value: "ad_group", label: "Ad Group" },
    { value: "keyword", label: "Keyword" },
  ];

  const [campaignSummaryConfig, setCampaignSummaryConfig] = useState({ page_num: 1, page_size: 10, order_type: "desc", order_by: "clicks", search_value: null, dropdown_value: campaignSummaryOptions[0].value })


  // DEVICE DISTRIBUTION DROPDOWN DEFAULT STATE
  const [selectedCampaignSummaryDropdownOption, setSelectedCampaignSummaryDropdownOption] = useState(campaignSummaryOptions[0]);


  // FOR COKE
  let cokeCampaignSummaryTableHeaders = [
    { name: `${selectedCampaignSummaryDropdownOption.label + " Name"}`, field: "campaign_name" },
    { name: "Objective", field: "objective" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Conversions", field: "conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "Benchmark CPM", field: "benchmark_cpm" },
    { name: "CPC", field: "cpc" },
    { name: "Benchmark CPC", field: "benchmark_cpc" },
    { name: "CPV", field: "cpv" },
    { name: "Benchmark CPV", field: "benchmark_cpv" },
    { name: "CR", field: "cr" },
    { name: "View Rate", field: "view_rate" },
    { name: "Benchmark View Rate", field: "benchmark_view_rate" },
    { name: "Frequency", field: "frequency" },
    { name: "Reach Rate", field: "reach_rate" },
    { name: "Engagement Rate", field: "engagement_rate" },
  ]

  // FOR ALL BRANDS
  let defaultCampaignSummaryTableHeaders = [
    { name: `${selectedCampaignSummaryDropdownOption.label + " Name"}`, field: "campaign_name" },
    { name: "Objective", field: "objective" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Conversions", field: "conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "CPC", field: "cpc" },
    { name: "CPV", field: "cpv" },
    { name: "CR", field: "cr" },
    { name: "Engagement Rate", field: "engagement_rate" },
  ]


  // FOR COKE
  let cokeKeywordsSummaryTableHeaders = [
    { name: `${selectedCampaignSummaryDropdownOption.label + " Name"}`, field: "campaign_name" },
    { name: "Objective", field: "objective" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "Benchmark CPM", field: "benchmark_cpm" },
    { name: "CPC", field: "cpc" },
    { name: "Benchmark CPC", field: "benchmark_cpc" },
    { name: "CPV", field: "cpv" },
    { name: "Benchmark CPV", field: "benchmark_cpv" },
    { name: "CR", field: "cr" },
    { name: "View Rate", field: "view_rate" },
    { name: "Benchmark View Rate", field: "benchmark_view_rate" },
    { name: "Frequency", field: "frequency" },
    { name: "Reach Rate", field: "reach_rate" },
    { name: "Engagement Rate", field: "engagement_rate" },
    { name: "Interactions", field: "interactions" },
  ]

  // FOR ALL BRANDS
  let defaultKeywordsSummaryTableHeaders = [
    { name: `${selectedCampaignSummaryDropdownOption.label + " Name"}`, field: "campaign_name" },
    { name: "Objective", field: "objective" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "CPC", field: "cpc" },
    { name: "CPV", field: "cpv" },
    { name: "CR", field: "cr" },
    { name: "Engagement Rate", field: "engagement_rate" },
    { name: "Interactions", field: "interactions" },
  ];

  // const campaignSummaryKeywordsTableHeaders = companyID === COKE_COMPANY_ID ? cokeKeywordsSummaryTableHeaders : defaultKeywordsSummaryTableHeaders;

  // DEVICE DISTRIBUTION DROPDOWN

  // DEVICE DISTRIBUTION DROPDOWN OPTIONS SELECTION HANDLER
  const campaignSummaryDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedCampaignSummaryDropdownOption("");
    } else {
      setSelectedCampaignSummaryDropdownOption(e);
      setCampaignSummaryConfig({ ...campaignSummaryConfig, dropdown_value: e.value, page_num: 1, search_value: null })
    }
    IAEvent_Dropdown_Visit(
      "Marketing Intelligence",
      "Campaigns",
      "Google",
      "Overall",
      null,
      "Summary",
      e.label
    );

    EventTracker({
      eventType: "dropdown_selected",
      module: pageModule,
      subModule: pageSubModule,
      platform: pagePlatform,
      tab: pagePrimaryTab,
      section: "Campaign Summary Table",
      dropdownValue: e === null ? "Overall" : e.label,
      ...(BRAND_ID && { brands: BRAND_ID }),
      date: {
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate,
        previousStartDate: selectedDates.prevStartDate,
        previousEndDate: selectedDates.prevEndDate,
      }
    })
  };

  useEffect(() => {

    // if (companyID === COKE_COMPANY_ID) {
    //   if (selectedCampaignSummaryDropdownOption.value === "keywords") {
    //     setCampaignSummaryTableHeaders(cokeKeywordsSummaryTableHeaders)
    //   } else {
    //     setCampaignSummaryTableHeaders(cokeCampaignSummaryTableHeaders)
    //   }
    // } else {
    //   if (selectedCampaignSummaryDropdownOption.value === "keywords") {
    //     setCampaignSummaryTableHeaders(defaultKeywordsSummaryTableHeaders)
    //   } else {
    //     setCampaignSummaryTableHeaders(defaultCampaignSummaryTableHeaders)
    //   }
    // }

  }, [BRAND_ID,
    selectedDates,
    selectedCampaignSummaryDropdownOption,
    selectedBucketsFromFilter,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,])

  // CHANGE CAMPAIGN SUMMARY DATA AS PER THE DROPDOWN VALUE
  const fetchCampaignSummaryDropdownHandler = async () => {
    if (selectedCampaignSummaryDropdownOption.value === "ad") {
      setCampaignSummary(campaignSummaryAllDropdownsData?.ad ?? []);
    } else if (selectedCampaignSummaryDropdownOption.value === "ad_group") {
      setCampaignSummary(campaignSummaryAllDropdownsData?.ad_group ?? []);
    } else if (selectedCampaignSummaryDropdownOption.value === "campaign") {
      setCampaignSummary(campaignSummaryAllDropdownsData?.campaign ?? []);
    } else if (selectedCampaignSummaryDropdownOption.value === "keywords") {
      setCampaignSummary(campaignSummaryAllDropdownsData?.keywords ?? []);
    } else {
      setCampaignSummary([]);
    }
  };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  useEffect(() => {
    fetchCampaignSummaryDropdownHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedCampaignSummaryDropdownOption,
    selectedBucketsFromFilter,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
  ]);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    // fetchCampaignObjectivewiseDistributionHandler();
    // fetchPerformanceHandler();
    // fetchCampaignSummaryHandler();
    fetchDonutChartPlatformWiseHandler();
    fetchAudienceDistributionHandler();
    fetchPerformanceSourceDropdownHandler();
    fetchDeviceDistributionHandler();
    fetchAudienceDistributionAgeHandler();
    fetchCityHandler();
    fetchCountryHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedBucketsFromFilter,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
  ]);

  useEffect(() => {
    fetchDeviceDistributionHandler();
    fetchAudienceDistributionAgeHandler();
    fetchCityHandler();
    fetchCountryHandler();
    fetchAudienceDistributionHandler()
  }, [
    BRAND_ID,
    selectedDates,
    performanceSourceDropdownValue,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedBucketsFromFilter,
    selectedPlatform,
  ]);

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchMidSectionDropdownHandler = async () => {
    if (selectedMidSectionDropdownOption.value === "impressions") {
      // setPerformance(performanceAllDropdownsData.impressions);
      // setPerformanceDataLabels(performanceAllDropdownsDataLabels.impressions);
      setDeviceDistribution(deviceDistributionAllDropdownsData?.impressions ?? []);
      setAgeCount(ageCountAllDropdownsData?.impressions ?? []);
      setAgeCountLabels(ageCountAllDropdownsDataLabels?.impressions ?? []);
      setCityData(cityAllDropdownsData?.impressions ?? []);
      setCountryData(countryAllDropdownsData?.impressions ?? []);
      setTotalUnknownCount(totalUnknownCountAllDropdownsData?.impressions ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.impressions ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.impressions ?? []);
    } else if (selectedMidSectionDropdownOption.value === "clicks") {
      // setPerformance(performanceAllDropdownsData.clicks);
      // setPerformanceDataLabels(performanceAllDropdownsDataLabels.clicks);
      setDeviceDistribution(deviceDistributionAllDropdownsData?.clicks ?? []);
      setAgeCount(ageCountAllDropdownsData?.clicks ?? []);
      setAgeCountLabels(ageCountAllDropdownsDataLabels?.clicks ?? []);
      setCountryData(countryAllDropdownsData?.clicks ?? []);
      setCityData(cityAllDropdownsData?.clicks ?? []);

      setTotalUnknownCount(totalUnknownCountAllDropdownsData?.clicks ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.clicks ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData.clicks);
    } else if (selectedMidSectionDropdownOption.value === "conversions") {
      // setPerformance(performanceAllDropdownsData.conversions);
      // setPerformanceDataLabels(performanceAllDropdownsDataLabels.conversions);
      setDeviceDistribution(deviceDistributionAllDropdownsData?.conversions ?? []);
      setAgeCount(ageCountAllDropdownsData?.conversions ?? []);
      setAgeCountLabels(ageCountAllDropdownsDataLabels?.conversions ?? []);
      setCountryData(countryAllDropdownsData?.conversions ?? []);
      setCityData(cityAllDropdownsData?.conversions ?? []);

      setTotalUnknownCount(totalUnknownCountAllDropdownsData?.conversions ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.conversions ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.conversions ?? []);
    } else if (selectedMidSectionDropdownOption.value === "spends") {
      // setPerformance(performanceAllDropdownsData.spends);
      // setPerformanceDataLabels(performanceAllDropdownsDataLabels.spends);
      setDeviceDistribution(deviceDistributionAllDropdownsData?.spends ?? []);
      setAgeCount(ageCountAllDropdownsData?.spends ?? []);
      setAgeCountLabels(ageCountAllDropdownsDataLabels?.spends ?? []);
      setCountryData(countryAllDropdownsData?.spends ?? []);
      setCityData(cityAllDropdownsData?.spends ?? []);

      setTotalUnknownCount(totalUnknownCountAllDropdownsData?.spends ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.spends ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.spends ?? []);
    } else {
      // setPerformance([]);
      // setPerformanceDataLabels([]);
      setDeviceDistribution([]);
    }
  };

  // CHNAGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchMidSectionDropdownHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedMidSectionDropdownOption,
    selectedBucketsFromFilter,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
  ]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/google/ad-metric/", config);

      setMetricCards(res?.data?.data ?? []);
      //   setMetricCards(GoogleMetricCardsSampleData);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Summary
              </h2></div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider
              sliderTitle={"Summary"}
              tab={"overall"}
              platform={"google"}
            >
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="campaign"
                    platform="google"
                    section="overall"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION

  // // TO FETCH DATA FROM API
  // const fetchCampaignObjectivewiseDistributionHandler = async () => {
  //   setCampaignObjectivewiseDistributionIsLoading(true);
  //   setCampaignObjectivewiseDistributionIsLoaded(false);
  //   setCampaignObjectivewiseDistributionError(null);

  //   const config = {
  //     params: {
  //       brand_id: BRAND_ID,
  //       start_date: selectedDates.startDate,
  //       end_date: selectedDates.endDate,
  //       previous_start_date: selectedDates.prevStartDate,
  //       previous_end_date: selectedDates.prevEndDate,
  //       objective: selectedObjectives,
  //       platform_campaign: selectedIdandPlatform,
  //       platform: selectedPlatform,
  //       campaign: selectedCampaigns,
  //       campaign_tag:
  //         selectedBucketsFromFilter.length === 0
  //           ? null
  //           : selectedBucketsFromFilter.join(","),
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + authCtx.token,
  //     },
  //   };

  //   try {
  //     const res = await axios.get(
  //       "performance/google/objective-distribution",
  //       config
  //     );
  //     setCampaignObjectivewiseDistribution(res.data === undefined || res.data.length === 0 ? [] :
  //       res.data[0] === undefined || res.data[0].length === 0 ? [] :
  //         res.data[0].total_campaign === undefined || res.data[0].total_campaign.length === 0 ? [] :
  //           res.data[0].total_campaign.data === undefined || res.data[0].total_campaign.data.length === 0 ? [] :
  //             res.data[0].total_campaign.data);

  //     /*   setCampaignObjectivewiseDistribution(
  //         res.data.length === 0 ? [] : res.data[0].total_campaign.data
  //       );
  //  */
  //     setCampaignObjectivewiseDistribution(res.data === undefined || res.data.length === 0 ? [] :
  //       res.data[0] === undefined || res.data[0].length === 0 ? [] :
  //         res.data[0].total_campaign === undefined || res.data[0].total_campaign.length === 0 ? [] :
  //           res.data[0].total_campaign.label === undefined || res.data[0].total_campaign.label.length === 0 ? [] :
  //             res.data[0].total_campaign.label);

  //     /*  setCampaignObjectivewiseDistributionDataLabels(
  //        res.data.length === 0 ? [] : res.data[0].total_campaign.label
  //      ); */
  //     // setCampaignObjectivewiseDistribution(GoogleCampaignObjectivewiseDistributionSampleData);
  //     // setCampaignObjectivewiseDistributionDataLabels(
  //     //   GoogleCampaignObjectivewiseDistributionSampleDataLabels
  //     // );
  //     setCampaignObjectivewiseDistributionIsLoading(false);
  //     setCampaignObjectivewiseDistributionIsLoaded(true);
  //     setCampaignObjectivewiseDistributionError(null);
  //   } catch (error) {
  //     setCampaignObjectivewiseDistribution([]);
  //     setCampaignObjectivewiseDistributionDataLabels([]);
  //     setCampaignObjectivewiseDistributionIsLoading(false);
  //     setCampaignObjectivewiseDistributionIsLoaded(false);
  //     setCampaignObjectivewiseDistributionError(error.code);
  //   }
  // };

  // // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  // let campaignObjectivewiseDistributionPie = (
  //   <Wrapper>
  //     <Loader loaderType="pieChartLoader" />
  //   </Wrapper>
  // );

  // // IF DATA IS LOADED
  // if (isContentAnalysisLoaded && !isContentAnalysisLoading) {
  //   if (
  //     NoDataAvailableChecker(campaignObjectivewiseDistribution) ||
  //     NoDataAvailableChecker(campaignObjectivewiseDistributionDataLabels)
  //   ) {
  //     campaignObjectivewiseDistributionPie = (
  //       <Wrapper>
  //         <NoDataAvailableLoader chartType="pieChartType" />
  //       </Wrapper>
  //     );
  //   } else {
  //     campaignObjectivewiseDistributionPie = (
  //       <Wrapper>
  //         <ErrorBoundary chartType="pieChartType">
  //           <PieofaPieChart
  //             chartId="content_analysis"
  //             chartClass="section_card_chart"
  //             chartData={campaignObjectivewiseDistribution}
  //             chartLabels={campaignObjectivewiseDistributionDataLabels}
  //           />
  //         </ErrorBoundary>
  //       </Wrapper>
  //     );
  //   }
  // }

  // // IF THERE IS SOME ERROR FROM SERVER
  // if (campaignObjectivewiseDistributionerror) {
  //   campaignObjectivewiseDistributionPie = (
  //     <Wrapper>
  //       <ServerErrorsLoader
  //         chartType="pieChartType"
  //         error={campaignObjectivewiseDistributionerror}
  //       />
  //     </Wrapper>
  //   );
  // }

  // // IF DATA LOADING
  // if (isContentAnalysisLoading) {
  //   campaignObjectivewiseDistributionPie = (
  //     <Wrapper>
  //       <Loader loaderType="pieChartLoader" />
  //     </Wrapper>
  //   );
  // }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE BY SOURCE

  // // TO FETCH DATA FROM API
  // const fetchPerformanceHandler = async () => {
  //   setPerformanceIsLoading(true);
  //   setPerformanceIsLoaded(false);
  //   setPerformanceError(null);

  //   const config = {
  //     params: {
  //       brand_id: BRAND_ID,
  //       start_date: selectedDates.startDate,
  //       end_date: selectedDates.endDate,
  //       previous_start_date: selectedDates.prevStartDate,
  //       previous_end_date: selectedDates.prevEndDate,
  //       objective: selectedObjectives,
  //        platform_campaign: selectedIdandPlatform,
  //       platform: selectedPlatform,
  //       campaign: selectedCampaigns,
  //       campaign_tag:
  //         selectedBucketsFromFilter.length === 0
  //           ? null
  //           : selectedBucketsFromFilter.join(","),
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + authCtx.token,
  //     },
  //   };

  //   try {
  //     const res = await axios.get("performance/google/metric-by-date/", config);


  //     setPerformance(
  //       res.data === undefined || res.data.length === 0 ? [] :
  //         res.data.metric_list === undefined || res.data.metric_list.length === 0 ? [] :
  //           res.data.metric_list.data === undefined || res.data.metric_list.data.length === 0 ? [] :
  //             res.data.metric_list.data.impressions === undefined || res.data.metric_list.data.impressions.length === 0 ? [] :
  //               res.data.metric_list.data.impressions
  //     )
  //     /*  setPerformance(
  //        res.data.length === 0 ? [] : res.data.metric_list.data.impressions
  //      );
  // */
  //     setPerformanceDataLabels(
  //       res.data === undefined || res.data.length === 0 ? [] :
  //         res.data.metric_list === undefined || res.data.metric_list.length === 0 ? [] :
  //           res.data.metric_list.label === undefined || res.data.metric_list.label.length === 0 ? [] :
  //             res.data.metric_list.label.impressions === undefined || res.data.metric_list.label.impressions.length === 0 ? [] :
  //               res.data.metric_list.label.impressions
  //     )
  //     /*  setPerformanceDataLabels(
  //        res.data.length === 0 ? [] : res.data.metric_list.label.impressions
  //      ); */

  //     setPerformanceAllDropdownsData(
  //       res.data === undefined || res.data.length === 0 ? [] :
  //         res.data.metric_list === undefined || res.data.metric_list.length === 0 ? [] :
  //           res.data.metric_list.data === undefined || res.data.metric_list.data.length === 0 ? [] :
  //             res.data.metric_list.data
  //     )

  //     /*  setPerformanceAllDropdownsData(
  //        res.data.length === 0 ? [] : res.data.metric_list.data
  //      );
  // */

  //     setPerformanceAllDropdownsDataLabels(
  //       res.data.length === undefined || res.data === 0 ? [] :
  //         res.data.metric_list === undefined || res.data.metric_list.length === 0 ? [] :
  //           res.data.metric_list.label === undefined || res.data.metric_list.label.length === 0 ? [] :
  //             res.data.metric_list.label
  //     )
  //     /*  setPerformanceAllDropdownsDataLabels(
  //        res.data.length === 0 ? [] : res.data.metric_list.label
  //      ); */


  //     setPerformanceDetails(res.data === undefined || res.data.length === 0 ? [] :
  //       res.data.metric === undefined || res.data.metric.length === 0 ? [] : res.data.metric);

  //     /*  setPerformanceDetails(res.data.length === 0 ? [] : res.data.metric); */

  //     // setPerformanceAllDropdownsData(PerformanceSampleData);
  //     // setPerformanceAllDropdownsDataLabels(PerformanceSampleDataLabels);
  //     setPerformanceIsLoading(false);
  //     setPerformanceIsLoaded(true);
  //     setPerformanceError(null);
  //   } catch (error) {
  //     setPerformanceAllDropdownsData([]);
  //     setPerformanceAllDropdownsDataLabels([]);
  //     setPerformanceDetails([]);
  //     setPerformanceIsLoading(false);
  //     setPerformanceIsLoaded(false);
  //     setPerformanceError(error.code);
  //   }
  // };

  // // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  // let performanceChart = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;

  // // IF DATA IS LOADED
  // if (isPerformanceLoaded && !isPerformanceLoading) {
  //   if (
  //     NoDataAvailableChecker(performance) ||
  //     NoDataAvailableChecker(performanceDataLabels)
  //   ) {
  //     performanceChart = <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>;
  //   } else {
  //     performanceChart = (
  //       <Wrapper>
  //         <ErrorBoundary chartType="lineChartType">
  //           <LineChart
  //             chartId="performance_by_source"
  //             chartClass="section_card_chart"
  //             chartData={performance}
  //             chartLabels={performanceDataLabels}
  //             showLegend={true}
  //             showLabels={true}
  //           />
  //         </ErrorBoundary>
  //       </Wrapper>
  //     );
  //   }
  // }

  // // IF THERE IS SOME ERROR FROM SERVER
  // if (performanceerror) {
  //   performanceChart = (
  //     <Wrapper>
  //       <ServerErrorsLoader chartType="lineChartType" error={performanceerror} />
  //     </Wrapper>
  //   );
  // }

  // // IF DATA LOADING
  // if (isPerformanceLoading) {
  //   performanceChart = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;
  // }

  // PERFORMANCE DETAILS
  // // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  // let performanceDetailsData = (
  //   <Wrapper>
  //     <NoDataAvailableLoader chartType="tableChartType" />
  //   </Wrapper>
  // );

  // // IF DATA IS LOADED
  // if (isPerformanceLoaded && !isPerformanceLoading) {
  //   if (
  //     performance === [] ||
  //     performance === undefined ||
  //     performance.length === 0
  //   ) {
  //     performanceDetailsData = (
  //       <Wrapper>
  //         <NoDataAvailableLoader chartType="tableChartType" />
  //       </Wrapper>
  //     );
  //   } else {
  //     performanceDetailsData = (
  //       <Wrapper>
  //         <table className="table table_striped table_bordered">
  //           <tbody>
  //             {performanceDetails.map((details, postImpressionIndex) => {
  //               return (
  //                 <Wrapper key={postImpressionIndex}>
  //                   <tr>
  //                     <td>{details.label}</td>
  //                     <td className="bold">
  //                       <NumberFormatter number={details.value} />
  //                     </td>
  //                   </tr>
  //                 </Wrapper>
  //               );
  //             })}
  //           </tbody>
  //         </table>
  //       </Wrapper>
  //     );
  //   }
  // }

  // // IF THERE IS SOME ERROR FROM SERVER
  // if (performanceerror) {
  //   performanceDetailsData = (
  //     <Wrapper>
  //       <ServerErrorsLoader chartType="tableChartType" error={performanceerror} />
  //     </Wrapper>
  //   );
  // }

  // // IF DATA LOADING
  // if (isPerformanceLoading) {
  //   performanceDetailsData = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>;
  // }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // DEVICE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchDeviceDistributionHandler = async () => {
    setDeviceDistributionIsLoading(true);
    setDeviceDistributionIsLoaded(false);
    setDeviceDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
        ad_network_type:
          performanceSourceDropdownValue === undefined
            ? null
            : performanceSourceDropdownValue.value,
        country_city: null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/google/device-distribution/",
        config
      );


      setDeviceDistribution(res?.data?.data[selectedMidSectionDropdownOption.value] ?? [])
      setDeviceDistributionAllDropdownsData(res?.data?.data ?? [])

      setDeviceDistributionIsLoading(false);
      setDeviceDistributionIsLoaded(true);
      setDeviceDistributionError(null);
    } catch (error) {
      setDeviceDistributionAllDropdownsData([]);
      setDeviceDistributionIsLoading(false);
      setDeviceDistributionIsLoaded(false);
      setDeviceDistributionError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let deviceDistributionChart = (
    <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (isDeviceDistributionLoaded && !isDeviceDistributionLoading) {
    if (NoDataAvailableChecker(deviceDistribution)) {
      deviceDistributionChart = (
        <Wrapper> <NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
      );
    } else {
      deviceDistributionChart = (
        <Wrapper>
          <DonutPieChart
            chartId={"reactions_sentiment"}
            chartClass={"section_card_chart"}
            chartData={deviceDistribution}
            showLegend={true}
            showLabels={true}
            showVerticalLabels={true}
          />
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (deviceDistributionerror) {
    deviceDistributionChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={deviceDistributionerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isDeviceDistributionLoading) {
    deviceDistributionChart = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY

  useEffect(() => {

    const fetchCampaignSummaryHandler = async () => {


      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          dropdown_value: selectedCampaignSummaryDropdownOption?.value ?? '',
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
          ...campaignSummaryConfig,
          page_size: -1,
          page_num: 1,

        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/google/ad-summary/v2/", config);

        var allData = res?.data?.data?.results ?? [];

        // var headerKeys = campaignSummaryTableHeaders.map((x) => x.field)
        // var formattedData = allData.map((row) => {

        //   var newData = {};
        //   Object.keys(row).forEach((x, y) => {
        //     if (headerKeys.includes(x)) {
        //       newData[x] = row[x]
        //     }
        //   })
        //   return newData
        // })

        setCampaignSummaryExportData(allData ?? []);

      } catch (error) {
        setCampaignSummaryExportData([]);

      }
    };
    fetchCampaignSummaryHandler();

  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform, selectedCampaignSummaryDropdownOption, campaignSummaryConfig.dropdown_value])



  useEffect(() => {
    const fetchCampaignSummaryHandler = async () => {
      setCampaignSummaryIsLoading(true);
      setCampaignSummaryIsLoaded(false);
      setCampaignSummaryError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
          ...campaignSummaryConfig
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/google/ad-summary/v2/", config);

        let allData = res.data.data.results;


        let uniqueHeaders = getUniqueValuesFromArrofObj(allData)
        let formattedHeaders = []
        uniqueHeaders.map((header) => {
          formattedHeaders.push({
            name: getHeaderKeysWithLabels(header),
            field: header,
          })
        })

        setCampaignSummaryTablePageData(res?.data?.data?.page ?? {})
        setCampaignSummary(allData ?? []);
        setCampaignSummaryTableHeaders(formattedHeaders ?? [])

        setCampaignSummaryIsLoading(false);
        setCampaignSummaryIsLoaded(true);
        setCampaignSummaryError(null);
      } catch (error) {
        setCampaignSummary([])
        setCampaignSummaryTableHeaders([])
        setCampaignSummaryIsLoading(false);
        setCampaignSummaryIsLoaded(false);
        setCampaignSummaryError(error.code);
      }
    };
    fetchCampaignSummaryHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedBucketsFromFilter,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    campaignSummaryConfig
  ])


  let campaignSummaryTable = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary
              <SectionDefinationsBadge
                title={"Summary"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // TABLE HEADER PROVIDER AS PER THE DROPDOWN

  // IF DATA IS LOADED
  if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {

    campaignSummaryTable = (
      <Wrapper>
        <ErrorBoundary chartType="tableChartType">
          <Datatable
            tableTitle="Summary"
            tablePagePlatform={pagePlatform}
            tablePageSubModule={pageSubModule}
            tablePagePrimaryTab={pagePrimaryTab}
            tableHeader={campaignSummaryTableHeaders}
            tableData={campaignSummary}
            tableLength={campaignSummaryConfig?.page_size ?? 10}
            isFooterShow={true}
            searchPlaceHolder={"Search Campaign"}
            tableConfigs={campaignSummaryConfig}
            setTableConfigs={setCampaignSummaryConfig}
            tablePaginationData={campaignSummaryTablePageData}
            tableDropdown={
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={campaignSummaryOptions}
                className="form_dropdown section_dropdown"
                value={selectedCampaignSummaryDropdownOption}
                selectedOptions={selectedCampaignSummaryDropdownOption}
                setStatedropdown={campaignSummaryDropdownSelectionHandler}
              />
            }
            tableID={"search_campaign_section"}
            tableExportDropdown={
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"search_campaign_section"}
                sectionName={`CampaignTableDynaLabels_Summary Table _${selectedCampaignSummaryDropdownOption.label}`
                }
                sectionData={campaignSummaryExportData}
              />
            }
          />
        </ErrorBoundary>
      </Wrapper>
    );
    // }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignSummaryerror) {
    campaignSummaryTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">Summary
                <SectionDefinationsBadge
                  title={"Summary"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={campaignSummaryerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCampaignSummaryLoading) {
    campaignSummaryTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">Summary
                <SectionDefinationsBadge
                  title={"Summary"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>)
  }

  // DONUT CHART PLATFORM WISE
  const [donutChartPlatformWise, setDonutChartPlatformWise] =
    useState([]);

  const [platformWiseDonutSliceToggle, setPlatformWiseDonutSliceToggle] = useState(null);

  const [
    donutChartPlatformWiseLoading,
    setDonutChartPlatformWiseLoading,
  ] = useState([]);
  const [
    donutChartPlatformWiseLoaded,
    setDonutChartPlatformWiseLoaded,
  ] = useState([]);
  const [
    donutChartPlatformWiseError,
    setDonutChartPlatformWiseError,
  ] = useState([]);

  // FETCH CAMPAIGN OBJECTIVE

  const fetchDonutChartPlatformWiseHandler = async () => {
    setDonutChartPlatformWiseLoading(true);
    setDonutChartPlatformWiseLoaded(false);
    setDonutChartPlatformWiseError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/google/objective-pie-chart/",
        config
      );

      setDonutChartPlatformWise(res?.data?.data ?? []);

      setDonutChartPlatformWiseLoading(false);
      setDonutChartPlatformWiseLoaded(true);
      setDonutChartPlatformWiseError(null);
    } catch (error) {
      setDonutChartPlatformWise([]);
      setDonutChartPlatformWiseLoading(false);
      setDonutChartPlatformWiseLoaded(false);
      setDonutChartPlatformWiseError(error.code);
    }
  };

  let donutChartCampaignSection = (
    <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (donutChartPlatformWiseLoaded && !donutChartPlatformWiseLoading) {
    if (NoDataAvailableChecker(donutChartPlatformWise)) {
      donutChartCampaignSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="donutChartType" />
        </Wrapper>
      );
    }
    else {
      donutChartCampaignSection = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={donutChartPlatformWise}
              chartId="CampaignDonut12"
              onSliceClick={(data) =>
                setPlatformWiseDonutSliceToggle(data.label)
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (donutChartPlatformWiseError) {
    donutChartCampaignSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={donutChartPlatformWiseError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (donutChartPlatformWiseLoading) {
    donutChartCampaignSection = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
  }

  // CHILD DONUT CAMPAIGN OBJECTIVE
  /* 
    const [childDonutCampaignObjective, setChildDonutCampaignObjective] =
      useState([]);
    const [childDonutSliceToggle, setChildDonutSliceToggle] = useState(null);
  
    const [
      childDonutCampaignObjectiveLoading,
      setChildDonutCampaignObjectiveLoading,
    ] = useState([]);
    const [
      childDonutCampaignObjectiveLoaded,
      setChildDonutCampaignObjectiveLoaded,
    ] = useState([]);
    const [
      childDonutCampaignObjectiveError,
      setChildDonutCampaignObjectiveError,
    ] = useState([]);
  
    const fetchChildDonutCampaignHandler = async () => {
      setChildDonutCampaignObjectiveLoading(true);
      setChildDonutCampaignObjectiveLoaded(false);
      setChildDonutCampaignObjectiveError(null);
  
      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag:
            selectedBucketsFromFilter.length === 0
              ? null
              : selectedBucketsFromFilter.join(","),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };
  
      try {
        const res = await axios.get("performance/google/ad-summary/", config);
  
        // setChildDonutCampaignObjective(res.data.campaign);
  
        setChildDonutCampaignObjectiveLoading(false);
        setChildDonutCampaignObjectiveLoaded(true);
        setChildDonutCampaignObjectiveError(null);
      } catch (error) {
        setChildDonutCampaignObjective([]);
        setChildDonutCampaignObjectiveLoading(false);
        setChildDonutCampaignObjectiveLoaded(false);
        setChildDonutCampaignObjectiveError(error.code);
      }
    };
  
    let childDonutCampaignChart = (
      <Wrapper>  <Loader loaderType="donutChartLoader" /></Wrapper>
    );
  
    // IF DATA IS LOADED
    if (
      childDonutCampaignObjectiveLoaded &&
      !childDonutCampaignObjectiveLoading
    ) {
      childDonutCampaignChart = (
        <Wrapper> 
            <ErrorBoundary chartType="donutChartType">
          <DonutPieChart
            chartClass="section_card_chart"
            chartData={DonutPieChartDataSample}
            chartId="CampaignDonutChild"
            onSliceClick={(data) => setChildDonutSliceToggle(data)}
          />
        </ErrorBoundary>
        </Wrapper>
      );
    }
  
    // IF THERE IS SOME ERROR FROM SERVER
    if (childDonutCampaignObjectiveError) {
      childDonutCampaignChart = (
        <Wrapper> 
           <ServerErrorsLoader
          chartType="donutChartType"
          error={childDonutCampaignObjectiveError}
        />
        </Wrapper>
      );
    }
  
    // IF DATA LOADING
    if (childDonutCampaignObjectiveLoading) {
      childDonutCampaignChart = <Wrapper>  <Loader loaderType="donutChartLoader" /></Wrapper>;
    }
  
    useEffect(() => {
      fetchChildDonutCampaignHandler();
    }, [
      BRAND_ID,
      platformWiseDonutSliceToggle,
      selectedBucketsFromFilter,
      selectedCampaigns,
      selectedObjectives,
      selectedIdandPlatform,
      selectedPlatform,
    ]);
   */
  // LINE CHART CAMPAIGN OBJECTIVE

  const [lineChartCampaignObjective, setLineChartCampaignObjective] = useState(
    []
  );

  const [lineChartCampaignObjectiveLabel, setLineChartCampaignObjectiveLabel] =
    useState([]);
  const [lineChartCampaignObjectiveLoading, setLineChartCampaignObjectiveLoading] = useState([]);
  const [lineChartCampaignObjectiveLoaded, setLineChartCampaignObjectiveLoaded] = useState([]);
  const [lineChartCampaignObjectiveError, setLineChartCampaignObjectiveError] = useState([]);


  let defaultLineChartCampaignDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultLineChartCampaignDisabledvalues = []
  } else {
    defaultLineChartCampaignDisabledvalues = ["Impressions", "Clicks", "Spends", "Conversions"]
  }

  const [isLineChartCampaignOpen, setIsLineChartCampaignOpen] = useState(false)
  const [lineChartCampaignDisabledvalues, setLineChartCampaignDisabledvalues] = useState(defaultLineChartCampaignDisabledvalues)


  const fetchLineChartCampaignHandler = async () => {
    setLineChartCampaignObjectiveLoading(true);
    setLineChartCampaignObjectiveLoaded(false);
    setLineChartCampaignObjectiveError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
        ad_network_type: platformWiseDonutSliceToggle,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/google/objective-multiline/",
        config
      );
      setLineChartCampaignObjective(res?.data?.data ?? []);

      setLineChartCampaignObjectiveLabel(res?.data?.dimension ?? []);

      setLineChartCampaignObjectiveLoading(false);
      setLineChartCampaignObjectiveLoaded(true);
      setLineChartCampaignObjectiveError(null);
    } catch (error) {
      setLineChartCampaignObjective([]);
      setLineChartCampaignObjectiveLoading(false);
      setLineChartCampaignObjectiveLoaded(false);
      setLineChartCampaignObjectiveError(error.code);
    }
  };

  let lineChartCampaignSection = (
    <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>
  );

  let lineChartCampaignSection2 = (
    <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (lineChartCampaignObjectiveLoaded && !lineChartCampaignObjectiveLoading) {
    if (
      NoDataAvailableChecker(lineChartCampaignObjective) ||
      NoDataAvailableChecker(lineChartCampaignObjectiveLabel)
    ) {
      lineChartCampaignSection = (
        <Wrapper>  <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
      lineChartCampaignSection2 = (
        <Wrapper>  <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
    } else {

      if (isMultiLineCluster) {
        lineChartCampaignSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={lineChartCampaignObjective}
                chartId="multiLineChart"
                chartLabels={lineChartCampaignObjectiveLabel}
                onBulletsClick={function noRefCheck() { }}
                showLabels
                showLegend
                disabledLegendArray={lineChartCampaignDisabledvalues}
                setDisabledValues={setLineChartCampaignDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        lineChartCampaignSection2 = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={lineChartCampaignObjective}
                chartId="multiLineChart_2"
                chartLabels={lineChartCampaignObjectiveLabel}
                onBulletsClick={function noRefCheck() { }}
                showLabels
                showLegend
                disabledLegendArray={lineChartCampaignDisabledvalues}
                setDisabledValues={setLineChartCampaignDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      } else {
        lineChartCampaignSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={lineChartCampaignObjective}
                chartId="multiLineChart"
                chartLabels={lineChartCampaignObjectiveLabel}
                onBulletsClick={function noRefCheck() { }}
                showLabels
                showLegend
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }


    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (lineChartCampaignObjectiveError) {
    lineChartCampaignSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={lineChartCampaignObjectiveError}
        />
      </Wrapper>
    );

    lineChartCampaignSection2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={lineChartCampaignObjectiveError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (lineChartCampaignObjectiveLoading) {
    lineChartCampaignSection = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;
    lineChartCampaignSection2 = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;

  }

  useEffect(() => {
    fetchLineChartCampaignHandler();
  }, [
    BRAND_ID,
    platformWiseDonutSliceToggle,
    selectedBucketsFromFilter,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
  ]);

  // / -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: AGE
  const fetchAudienceDistributionAgeHandler = async () => {
    setAgeCountIsLoading(true);
    setAgeCountIsLoaded(false);
    setAgeCountError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
        ad_network_type:
          performanceSourceDropdownValue === undefined
            ? null
            : performanceSourceDropdownValue.value,
        country_city: null,
        /*  selectedDrodown: selectedMidSectionDropdownOption.value */
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/google/age-distribution/",
        config
      );

      setAgeCount(res?.data?.data[selectedMidSectionDropdownOption.value] ?? [])
      setAgeCountLabels(res?.data?.label[selectedMidSectionDropdownOption.value] ?? [])
      setAgeCountAllDropdownsData(res?.data?.data ?? []);
      setAgeCountAllDropdownsDataLabels(res?.data?.label ?? []);

      setAgeCountIsLoading(false);
      setAgeCountIsLoaded(true);
      setAgeCountError(null);
    } catch (error) {
      setAgeCountAllDropdownsData([]);
      setAgeCountAllDropdownsDataLabels([]);
      setAgeCountIsLoading(false);
      setAgeCountIsLoaded(false);
      setAgeCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: AGE
  let ageAudienceDistribution = (
    <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isAgeCountLoaded && !isAgeCountLoading) {
    if (
      NoDataAvailableChecker(ageCount) ||
      NoDataAvailableChecker(ageCountLabels)
    ) {
      ageAudienceDistribution = (
        <Wrapper>  <NoDataAvailableLoader chartType="barChartType" /></Wrapper>
      );
    } else {
      ageAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_age"
              chartClass="section_card_chart"
              chartData={ageCount}
              chartLabels={ageCountLabels}
              showLegend={false}
              /*  showLabels={true}
               showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (ageCounterror) {
    ageAudienceDistribution = (
      <Wrapper>  <ServerErrorsLoader chartType="barChartType" error={ageCounterror} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAgeCountLoading) {
    ageAudienceDistribution = <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: MALE / FEMALE

  // TO FETCH MALE / FEMALE DATA FROM API
  const fetchAudienceDistributionHandler = async () => {
    setTotalMaleCountLoading(true);
    setTotalMaleCountIsLoaded(false);
    setMaleCountError(null);

    setFemaleCountIsLoading(true);
    setFemaleCountIsLoaded(false);
    setFemaleCountError(null);

    setUnknownCountIsLoading(true);
    setUnknownCountIsLoaded(false);
    setUnknownCountError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),

        ad_network_type:
          performanceSourceDropdownValue === undefined
            ? null
            : performanceSourceDropdownValue.value,
        country_city: null,
        /*  selectedDrodown: selectedMidSectionDropdownOption.value */
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/google/gender-distribution/",
        config
      );

      setTotalMaleCount(res?.data?.male_count[selectedMidSectionDropdownOption.value] ?? [])
      setTotalFemaleCount(res?.data?.female_count[selectedMidSectionDropdownOption.value] ?? [])
      setTotalUnknownCount(res?.data?.unknown[selectedMidSectionDropdownOption.value] ?? [])

      setTotalMaleCountAllDropdownsData(res?.data?.male_count ?? [])
      setTotalMaleCountLoading(false);
      setTotalMaleCountIsLoaded(true);
      setMaleCountError(null);

      setTotalFemaleCountAllDropdownsData(res?.data?.female_count ?? [])


      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(true);
      setFemaleCountError(null);

      setTotalUnknownCountAllDropdownsData(res?.data?.unknown ?? [])

      setUnknownCountIsLoading(false);
      setUnknownCountIsLoaded(true);
      setUnknownCountError(null);
    } catch (error) {
      // setMaleCountAllDropdownsData([]);
      // setMaleCountAllDropdownsDataLabels([]);
      setTotalMaleCountAllDropdownsData([]);
      setTotalMaleCountLoading(false);
      setTotalMaleCountIsLoaded(false);
      setMaleCountError(error.code);

      // setFemaleCountAllDropdownsData([]);
      // setFemaleCountAllDropdownsDataLabels([]);
      setTotalFemaleCountAllDropdownsData([]);
      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(false);
      setFemaleCountError(error.code);

      setTotalUnknownCountAllDropdownsData([]);
      setUnknownCountIsLoading(false);
      setUnknownCountIsLoaded(false);
      setUnknownCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let maleAudienceDistributionIcon = (
    <Wrapper>  <Loader loaderType="genderLoader" /></Wrapper>
  );

  let totalMaleCountPercentage = 0;
  if (
    isNaN(
      totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalMaleCountPercentage = 0;
  } else {
    totalMaleCountPercentage =
      totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalFemaleCountPercentage = 0;
  if (
    isNaN(
      totalFemaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalFemaleCountPercentage = 0;
  } else {
    totalFemaleCountPercentage =
      totalFemaleCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalUnknownCountPercentage = 0;
  if (
    isNaN(
      totalUnknownCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalUnknownCountPercentage = 0;
  } else {
    totalUnknownCountPercentage =
      totalUnknownCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  // IF DATA IS AVAILABLE
  if (isTotalMaleCountLoaded && !isTotalMaleCountLoading) {
    if (NoDataAvailableChecker(totalMaleCount)) {
      maleAudienceDistributionIcon = (
        <Wrapper>  <NoDataAvailableLoader chartType="genderChartType" /></Wrapper>
      );
    } else {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <MaleIcon percentage={totalMaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20 text_center">
            <h3>
              {+Math.abs(totalMaleCountPercentage * 100)
                .toFixed(2)
                .replace(/\.0$/, "") + "%"}
            </h3>
            <h3>Male</h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (maleCounterror) {
    maleAudienceDistributionIcon = (
      <Wrapper>  <ServerErrorsLoader chartType="genderChartType" error={maleCounterror} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTotalMaleCountLoading) {
    maleAudienceDistributionIcon = <Wrapper>  <Loader loaderType="genderLoader" /></Wrapper>;
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let femaleAudienceDistributionIcon = (
    <Wrapper>  <NoDataAvailableLoader chartType="genderChartType" /></Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isFemaleCountLoaded && !isFemaleCountLoading) {
    if (NoDataAvailableChecker(totalFemaleCount)) {
      femaleAudienceDistributionIcon = (
        <Wrapper>   <NoDataAvailableLoader chartType="genderChartType" /></Wrapper>
      );
    } else {
      femaleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20 text_center">
            <h3>
              {+Math.abs(totalFemaleCountPercentage * 100)
                .toFixed(2)
                .replace(/\.0$/, "") + "%"}
            </h3>
            <h3>Female</h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (femaleCounterror) {
    femaleAudienceDistributionIcon = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={femaleCounterror}
        />
      </Wrapper>
    );

    // unknownAudienceDistributionIcon = (
    //   <ServerErrorsLoader error={femaleCounterror} />
    // );
  }

  // IF DATA LOADING
  if (isFemaleCountLoading) {
    femaleAudienceDistributionIcon = <Wrapper>  <Loader loaderType="genderLoader" /></Wrapper>;
  }

  // AUDIENCE DISTRIBUTION: UNKNOWN
  let unknownAudienceDistributionIcon = (
    <Wrapper>  <Loader loaderType="genderLoader" /></Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isUnknownCountLoaded && !isUnknownCountLoading) {
    if (NoDataAvailableChecker(totalUnknownCount)) {
      unknownAudienceDistributionIcon = (
        <Wrapper>   <NoDataAvailableLoader chartType="genderChartType" /></Wrapper>
      );
    } else {
      unknownAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <UnknownGenderIcon
                percentage={totalUnknownCountPercentage * 100}
              />
            </ErrorBoundary>
          </div>
          <div className="p_20 text_center">
            <h3>
              {+Math.abs(totalUnknownCountPercentage * 100)
                .toFixed(2)
                .replace(/\.0$/, "") + "%"}
            </h3>
            <h3>Others</h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (unknownCounterror) {
    unknownAudienceDistributionIcon = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={unknownCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isUnknownCountLoading) {
    unknownAudienceDistributionIcon = <Wrapper>  <Loader loaderType="genderLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
        ad_network_type:
          performanceSourceDropdownValue === undefined
            ? null
            : performanceSourceDropdownValue.value,
        country_city: null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/google/country-distribution/",
        config
      );

      setCountryData(res?.data?.data[selectedMidSectionDropdownOption.value] ?? []);
      setCountryAllDropdownsData(res?.data?.data ?? []);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryAllDropdownsData([]);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: COUNTRY
  let countryMap = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By Country</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(CountryTableDataHeaders)
    ) {
      countryMap = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By Country</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      countryMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle="By Country"
              isHalfSection={true}
              isCountryFlagVisible={true}
              tableHeader={CountryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CITY MAP

  const fetchCityHandler = async () => {
    setCityDataIsLoading(true);
    setCityDataIsLoaded(false);
    setCityDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
        ad_network_type:
          performanceSourceDropdownValue === undefined
            ? null
            : performanceSourceDropdownValue.value,
        country_city: null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/google/city-distribution/",
        config
      );

      setCityData(res?.data?.data[selectedMidSectionDropdownOption.value] ?? []);
      setCityAllDropdownsData(res?.data?.data ?? []);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(true);
      setCityDataError(null);
    } catch (error) {
      setCityAllDropdownsData([]);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(false);
      setCityDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: CITY
  let cityMap = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By City</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (
      NoDataAvailableChecker(cityData) ||
      NoDataAvailableChecker(CityTableDataHeaders)
    ) {
      cityMap = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By City</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      cityMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle="By City"
              isHalfSection={true}
              isDynamicFlagVisible={true}
              tableHeader={CityTableDataHeaders}
              tableData={cityData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search City"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    cityMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    cityMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  var campaignObjectCSV = {
    "CampaignObjDonut_Campaign Objective Donut": donutChartPlatformWise,
    [`CampObjectiveDynaLabels_Campaign Objective Line_${platformWiseDonutSliceToggle === undefined || platformWiseDonutSliceToggle === null ? "empty" : platformWiseDonutSliceToggle.replace("_", "-")}`]: lineChartCampaignObjective,
  };

  var dropdownOptions = performanceSourceDropdownValue === "" ? selectedMidSectionDropdownOption.label : performanceSourceDropdownValue.label.replace("_", "-") + " : " + selectedMidSectionDropdownOption.label

  var maleFemaleDataCSV = {
    "Gender": [
      {
        ["Male - " + dropdownOptions]: totalMaleCount,
        ["Female - " + dropdownOptions]: totalFemaleCount,
        ["Unknown - " + dropdownOptions]: totalUnknownCount,
      },
    ],
  };

  var cityCountryData = {
    [`CityChartDynaLabels_Audience Distribution - City_${dropdownOptions}`]: cityData,
    [`CountryChartDynaLabels_Audience Distribution - Country_${dropdownOptions}`]: countryData,
  };

  var deviceDistributionExport = {
    [`CampaignDeviceDistDynaLabels_Device Distribution_${dropdownOptions}`]: deviceDistribution,
  }

  var ageCountExport = {
    [`AgeBarChartCampaignDynalabels_Audience Distribution - Age_${dropdownOptions}`]: ageCount,
  }

  var allData = {
    ...topCardData,
    "SummaryCard_Summary Card": metricCards,
    ...campaignObjectCSV,
    ...maleFemaleDataCSV,
    ...deviceDistributionExport,
    ...ageCountExport,
    ...cityCountryData,
    [`CampaignTableDynaLabels_Summary Table _${selectedCampaignSummaryDropdownOption.label}`]: campaignSummaryExportData,
  };

  useEffect(() => {
    setFullPageExport(allData);
  }, [
    metricCards,
    donutChartPlatformWise,
    lineChartCampaignObjective,
    totalMaleCount,
    deviceDistribution,
    ageCount,
    cityData,
    countryData,
    campaignSummary,
    campaignSummaryExportData
  ]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (donutChartPlatformWiseError !== null ? true : donutChartPlatformWiseLoaded && (donutChartPlatformWiseLoading === false)) &&
      (lineChartCampaignObjectiveError !== null ? true : lineChartCampaignObjectiveLoaded && (lineChartCampaignObjectiveLoading === false)) &&
      (maleCounterror !== null ? true : isTotalMaleCountLoaded && (isTotalMaleCountLoading === false)) &&
      (deviceDistributionerror !== null ? true : isDeviceDistributionLoaded && (isDeviceDistributionLoading === false)) &&
      (ageCounterror !== null ? true : isAgeCountLoaded && (isAgeCountLoading === false)) &&
      (cityDataerror !== null ? true : isCityDataLoaded && (isCityDataLoading === false)) &&
      (countryDataerror !== null ? true : isCountryDataLoaded && (isCountryDataLoading === false)) &&
      (campaignSummaryerror !== null ? true : isCampaignSummaryLoaded && (isCampaignSummaryLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [

    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    donutChartPlatformWiseError, donutChartPlatformWiseLoaded, donutChartPlatformWiseLoading,
    lineChartCampaignObjectiveError, lineChartCampaignObjectiveLoaded, lineChartCampaignObjectiveLoading,
    maleCounterror, isTotalMaleCountLoaded, isTotalMaleCountLoading,
    deviceDistributionerror, isDeviceDistributionLoaded, isDeviceDistributionLoading,
    ageCounterror, isAgeCountLoaded, isAgeCountLoading,
    cityDataerror, isCityDataLoaded, isCityDataLoading,
    countryDataerror, isCountryDataLoaded, isCountryDataLoading,
    campaignSummaryerror, isCampaignSummaryLoaded, isCampaignSummaryLoading
  ])


  return (
    <Wrapper>
      {metricCardsSection}
      <div
        id="Platform_Objective_Wise_distribution_Section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">Platform Wise Distribution</h2>
              <SectionDefinationsBadge
                title={"Platform Wise Distribution"}
                module="campaign"
                platform="google"
                section="overall"
              />
            </div>
            <Modal
              open={isLineChartCampaignOpen}
              setOpen={setIsLineChartCampaignOpen}
              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"multiLineChart_2"}
                  sectionName={"Platform Wise Distribution"}
                  sectionData={campaignObjectCSV}
                />}
              parentId={"Platform_Objective_Wise_distribution_Section"}
              title={`Platform Wise Distribution`}
              chart={lineChartCampaignSection2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsLineChartCampaignOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"Platform_Objective_Wise_distribution_Section"}
                sectionName={"Platform Wise Distribution"}
                sectionData={campaignObjectCSV}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_1">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {donutChartCampaignSection}
              </div>
            </div>
          </div>
          {/* <div className="grid col_span_1 ">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {childDonutCampaignChart}
              </div>
            </div>
          </div> */}
          <div className="grid col_span_3">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {lineChartCampaignSection}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section_card section_dropdown_header">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="grid grid_cols_2">
              <div className="grid cols_span_1">
                <h2 className="section_card_title">Performance By KPI

                </h2>
              </div>
              <div className="grid cols_span_3 flex justify_end">
                {/* <Dropdown
                  ismulti={false}
                  isClearable={true}
                  placeholder={"Select Region"}
                  options={performanceLocationDropdonwOptions}
                  className="form_dropdown section_dropdown"
                  value={performanceLocationDropdownOption}
                  selectedOptions={performanceLocationDropdownOption}
                  setStatedropdown={performanceLocationDropdownSelectionHandler}
                /> */}
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={midSectionDropdonwOptions}
                  className="form_dropdown section_dropdown"
                  value={selectedMidSectionDropdownOption}
                  selectedOptions={selectedMidSectionDropdownOption}
                  setStatedropdown={midSectionDropdownSelectionHandler}
                />
                {performanceSourceDropdownOptions.length !== 0 && (
                  <Dropdown
                    ismulti={false}
                    isClearable={true}
                    placeholder={"Select Source"}
                    options={performanceSourceDropdownOptions}
                    className="form_dropdown section_dropdown"
                    value={performanceSourceDropdownValue}
                    selectedOptions={performanceSourceDropdownValue}
                    setStatedropdown={performanceSourceDropdownSelectionHandler}
                    dropdownLoading={isPerformanceSourceDropdownOptionsLoading}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div id="gender_section" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">
              Gender : {selectedMidSectionDropdownOption.label}
            </h2> <SectionDefinationsBadge
                title={"Audience Distribution By Gender"}
                module="campaign"
                platform="google"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"gender_section"}
                sectionName={"Gender"}
                sectionData={maleFemaleDataCSV}
              />
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="grid col_span_3">
              <div className="section_card_body">
                <div className="section_card_chart_wrapper">
                  <div className="grid col_span_4 section_card_chart_wrapper">
                    <div className="grid col_span_1 flex row justify_center align_center">
                      <div>{maleAudienceDistributionIcon}</div>
                      <div>{femaleAudienceDistributionIcon}</div>
                      <div>{unknownAudienceDistributionIcon}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="device_distribution" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <div className="section_info">     <h2 className="section_card_title">
              Device Distribution : {selectedMidSectionDropdownOption.label}
            </h2> <SectionDefinationsBadge
                title={"Device Distribution"}
                module="campaign"
                platform="google"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"device_distribution"}
                sectionName={"CampaignDeviceDistDynaLabels_Device Distribution"}
                sectionData={deviceDistributionExport}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {deviceDistributionChart}
            </div>
          </div>
        </div>
      </div>

      <div
        id="audience_distribution"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">
              Audience Distribution : {selectedMidSectionDropdownOption.label}
            </h2><SectionDefinationsBadge
                title={"Audience Distribution By Age"}
                module="campaign"
                platform="google"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_distribution"}
                sectionName={"AgeBarChartCampaignDynalabels_Audience Distribution"}
                sectionData={ageCountExport}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_4  flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Age


              </h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_4 section_card_chart_wrapper">
                {ageAudienceDistribution}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="audience_distribution_place"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">     <h2 className="section_card_title">
              Audience Distribution : {selectedMidSectionDropdownOption.label}
            </h2> <SectionDefinationsBadge
                title={"Audience Distribution By Country"}
                module="campaign"
                platform="google"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_distribution_place"}
                sectionName={"Audience Distribution"}
                sectionData={cityCountryData}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            {countryMap}
          </div>
          <div className="grid col_span_2 flex column">
            {cityMap}
          </div>
        </div>
      </div>
      {campaignSummaryTable}
    </Wrapper>
  );
};

export default CampaignGoogleOverall;
