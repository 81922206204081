import React, { useState, useMemo, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";
import axioss from "axios";

//STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

//LOADERS
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Slider from "../../../../components/ui/Slider";
import Card from "../../../../components/ui/Card";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";

// CHARTS
import LineChart from "../../../../components/ui/charts/LineChart";
import CombinedBarLineMultipleAxesChart from "../../../../components/ui/charts/CombinedBarLineMultipleAxesChart";
import BarChart from "../../../../components/ui/charts/BarChart";
import ZooomableBubblechart from "../../../../components/ui/charts/ZoomableBubbleChart";
import HeatMap from "../../../../components/ui/charts/HeatMap";

// UTILS
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import {
  IAEvent_Hashtag_Visit,
  IAEvent_Dropdown_Visit,
} from "../../../../utils/IAEvents";


import PartitionedCombinedBarLineMultipleAxesChart from "../../../../components/ui/charts/PartitionedCombinedBarLineMultipleAxesChart";
import CombinedBarLineChart from "../../../../components/ui/charts/CombinedBarLineChart";
import ExpandIcon from "../../../../assets/icons/ExpandIcon";
import Modal from "../../../../components/ui/Modal";
import EventTracker from "../../../../components/eventsTracking/EventTracker";


const SocialTwitterPostOverall = ({ pageDropdownObject, topCardData }) => {
  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Social"
  let pagePlatform = "Twitter"
  let pagePrimaryTab = "Post"
  let pageSecondaryTab = "Overall"


  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled, setExportPageName } =
    useContext(FullPageExportContext);

  let tweetTypeDropdown = pageDropdownObject.length === 0 ? "" : " : " + pageDropdownObject.label
  let pageDropdown = pageDropdownObject.length === 0 ? null : pageDropdownObject.value

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // const PostType = "Overall";

  const [searchTerm, setSeachTerm] = useState("");

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState();
  // const [topPostSelectedDate, setTopPostSelectedDate] = useState("");

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // POST IMPRESSIONS
  const [tweetImpressions, setTweetImpressions] = useState([]);
  const [tweetImpressionsDataLabels, setTweetImpressionsDataLabels] = useState(
    []
  );
  const [isTweetImpressionsLoading, setTweetImpressionsIsLoading] =
    useState(false);
  const [isTweetImpressionsLoaded, setTweetImpressionsIsLoaded] =
    useState(false);
  const [tweetImpressionserror, setTweetImpressionsError] = useState(null);

  // VIDEO VIEWS
  const [videoViews, setVideoViews] = useState([]);
  const [videoViewsDataLabels, setVideoViewsDataLabels] = useState([]);
  const [isVideoViewsLoading, setVideoViewsIsLoading] = useState(false);
  const [isVideoViewsLoaded, setVideoViewsIsLoaded] = useState(false);
  const [videoViewserror, setVideoViewsError] = useState(null);

  // VIDEO VIEWS QUARTILES
  const [videoViewQuartiles, setVideoViewQuartiles] = useState([]);
  const [videoViewQuartilesDataLabels, setVideoViewQuartilesDataLabels] =
    useState([]);
  const [isVideoViewQuartilesLoading, setVideoViewQuartilesIsLoading] =
    useState(false);
  const [isVideoViewQuartilesLoaded, setVideoViewQuartilesIsLoaded] =
    useState(false);
  const [videoViewQuartileserror, setVideoViewQuartilesError] = useState(null);

  // POLL VOTES
  const [pollVotes, setPollVotes] = useState([]);
  const [pollVotesDataLabels, setPollVotesDataLabels] = useState([]);
  const [isPollVotesLoading, setPollVotesIsLoading] = useState(false);
  const [isPollVotesLoaded, setPollVotesIsLoaded] = useState(false);
  const [pollVoteserror, setPollVotesError] = useState(null);

  // TWEET ENGAGEMENT
  const [tweetEngagement, setTweetEngagement] = useState([]);
  const [tweetEngagementDataLabels, setTweetEngagementDataLabels] = useState(
    []
  );
  const [isTweetEngagementLoading, setTweetEngagementIsLoading] =
    useState(false);
  const [isTweetEngagementLoaded, setTweetEngagementIsLoaded] = useState(false);
  const [tweetEngagementerror, setTweetEngagementError] = useState(null);

  const [tweetEngagementAllData, setTweetEngagementAllData] = useState([]);
  const [tweetEngagementAllLabels, setTweetEngagementAllLabels] = useState([]);

  // DROPDOWN OPTIONS
  const tweetEngagementOptions = [
    { value: "typewise", label: "Typewise" },
    { value: "total_engagement", label: "Total" },
  ];

  const [tweetEngagementDropdownOption, setTweetEngagementDropdownOption] =
    useState(tweetEngagementOptions[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const tweetEngagementSelectionHandler = (e) => {
    if (e === null) {
      setTweetEngagementDropdownOption("");
    } else {
      setTweetEngagementDropdownOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Marketing Intelligence",
      "Social",
      "Twitter",
      "Post",
      "Overall",
      "Tweet Engagement",
      e.label
    );

    EventTracker({
      eventType: "dropdown_selected",
      module: pageModule,
      subModule: pageSubModule,
      platform: pagePlatform,
      tab: pagePrimaryTab,
      section: "Tweet Engagement",
      dropdownValue: e === null ? "Overall" : e.label,
      ...(BRAND_ID && { brands: BRAND_ID }),
      date: {
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate,
        previousStartDate: selectedDates.prevStartDate,
        previousEndDate: selectedDates.prevEndDate,
      }
    })
  };

  const FetchTweetEngagementDropdownHandler = () => {
    if (tweetEngagementDropdownOption.value === "typewise") {
      setTweetEngagement(tweetEngagementAllData.typewise);
      setTweetEngagementDataLabels(tweetEngagementAllLabels.typewise);
    } else if (tweetEngagementDropdownOption.value === "total_engagement") {
      setTweetEngagement(tweetEngagementAllData.total_engagement);
      setTweetEngagementDataLabels(tweetEngagementAllLabels.total_engagement);
    } else {
      setTweetEngagement(tweetEngagementAllData.typewise);
      setTweetEngagementDataLabels(tweetEngagementAllLabels.typewise);
    }
  };

  useEffect(() => {
    FetchTweetEngagementDropdownHandler();
  }, [BRAND_ID, tweetEngagementDropdownOption, selectedDates]);

  // CAMPAIGN EFFECTIVENESS
  const [campaignEffectiveness, setCampaignEffectiveness] = useState([]);
  const [campaignEffectivenessDataLabels, setCampaignEffectivenessDataLabels] =
    useState([]);
  const [isCampaignEffectivenessLoading, setCampaignEffectivenessIsLoading] =
    useState(false);
  const [isCampaignEffectivenessLoaded, setCampaignEffectivenessIsLoaded] =
    useState(false);
  const [campaignEffectivenesserror, setCampaignEffectivenessError] =
    useState(null);

  const [campaignEffectivenessAllData, setCampaignEffectivenessAllData] =
    useState([]);

  // DROPDOWN OPTIONS
  const campaignEffectivenessOptions = [
    { value: "impressions", label: "Impressions" },
    { value: "engagement", label: "Engagements" },
  ];

  const [campaignEffectivenessDropdown, setcampaignEffectivenessDropdown] =
    useState(campaignEffectivenessOptions[0]);

  const campaignSelectionHandler = (e) => {
    if (e === null) {
      setcampaignEffectivenessDropdown("");
    } else {
      setcampaignEffectivenessDropdown(e);
    }

    EventTracker({
      eventType: "dropdown_selected",
      module: pageModule,
      subModule: pageSubModule,
      platform: pagePlatform,
      tab: pagePrimaryTab,
      section: "Campaign Effectiveness",
      dropdownValue: e === null ? "Overall" : e.label,
      ...(BRAND_ID && { brands: BRAND_ID }),
      date: {
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate,
        previousStartDate: selectedDates.prevStartDate,
        previousEndDate: selectedDates.prevEndDate,
      }
    })
  };

  const fetchCampaignEffectivenessDropdownHandler = async (hashtag) => {
    /*  if (isCampaignEffectivenessLoaded) { */
    if (campaignEffectivenessDropdown.value === "engagement") {
      setCampaignEffectiveness(
        campaignEffectivenessAllData.data[0].engagement
      );
      setCampaignEffectivenessDataLabels(
        campaignEffectivenessAllData.dimension[0].engagement
      );
    } else if (campaignEffectivenessDropdown.value === "impressions") {
      setCampaignEffectiveness(campaignEffectivenessAllData.data[0].impressions);
      setCampaignEffectivenessDataLabels(campaignEffectivenessAllData.dimension[0].impressions);
    } else {
      setCampaignEffectiveness([]);
      setCampaignEffectivenessDataLabels([]);
    }
    /* } */
  };

  useEffect(() => {
    fetchCampaignEffectivenessDropdownHandler();
  }, [BRAND_ID, campaignEffectivenessDropdown, selectedDates]);

  // TWEET ACTIVITY
  const [tweetActivity, setTweetActivity] = useState([]);
  const [tweetActivityDataXAxisLabels, setTweetActivityDataXAxisLabels] =
    useState([]);
  const [tweetActivityDataYAxisLabels, setTweetActivityDataYAxisLabels] =
    useState([]);
  const [isTweetActivityLoading, setTweetActivityIsLoading] = useState(false);
  const [isTweetActivityLoaded, setTweetActivityIsLoaded] = useState(false);
  const [tweetActivityerror, setTweetActivityError] = useState(null);

  const [twitterActivityAllData, setTwitterActivityAllData] = useState([]);

  // DROPDOWN OPTIONS
  const tweetActivityOptions = [
    { value: "organic", label: "Organic" },
    { value: "paid", label: "Paid" },
    { value: "overall", label: "Overall" },
  ];

  const [tweetActivityDropdown, setTweetActivityDropdown] = useState(
    tweetActivityOptions[0]
  );

  const tweetActivitySelectionHandler = (e) => {
    if (e === null) {
      setTweetActivityDropdown("");
    } else {
      setTweetActivityDropdown(e);
    }

    EventTracker({
      eventType: "dropdown_selected",
      module: pageModule,
      subModule: pageSubModule,
      platform: pagePlatform,
      tab: pagePrimaryTab,
      section: "Tweet Activity",
      dropdownValue: e === null ? "Overall" : e.label,
      ...(BRAND_ID && { brands: BRAND_ID }),
      date: {
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate,
        previousStartDate: selectedDates.prevStartDate,
        previousEndDate: selectedDates.prevEndDate,
      }
    })
  };

  const fetchTweetActivityDropdownHandler = () => {
    if (tweetActivityDropdown.value === "organic") {
      setTweetActivity(twitterActivityAllData.organic);
    } else if (tweetActivityDropdown.value === "paid") {
      setTweetActivity(twitterActivityAllData.paid);
    } else if (tweetActivityDropdown.value === "overall") {
      setTweetActivity(twitterActivityAllData.overall);
    } else {
      setTweetActivity(twitterActivityAllData.organic);
    }
  };

  useEffect(() => {
    fetchTweetActivityDropdownHandler();
  }, [BRAND_ID, tweetActivityDropdown, selectedDates]);

  // COMPARE ENGAGEMENT IMPRESSIONS
  const [compareEngagementImpression, setCompareEngagementImpression] =
    useState([]);
  const [
    compareEngagementImpressionDataLabels,
    setCompareEngagementImpressionDataLabels,
  ] = useState([]);
  const [
    isCompareEngagementImpressionLoading,
    setCompareEngagementImpressionIsLoading,
  ] = useState(false);
  const [
    isCompareEngagementImpressionLoaded,
    setCompareEngagementImpressionIsLoaded,
  ] = useState(false);
  const [
    compareEngagementImpressionerror,
    setCompareEngagementImpressionError,
  ] = useState(null);

  const [
    compareEngagementImpressionAllData,
    setcompareEngagementImpressionAllData,
  ] = useState([]);
  const [
    compareEngagementImpressionAllLabel,
    setcompareEngagementImpressionAllLabel,
  ] = useState([]);

  // DROPDOWN OPTIONS
  const compareEngagementImpressionOptions = [
    { value: "engagementsVsImpressions", label: "Impression" },
    { value: "engagementsVsTweetLength", label: "Tweet Length" },
  ];

  const [
    compareEngagementImpressionDropdown,
    setcompareEngagementImpressionDropdown,
  ] = useState(compareEngagementImpressionOptions[0]);

  const compareEngagementImpressionSelectionHandler = (e) => {
    if (e === null) {
      setcompareEngagementImpressionDropdown("");
    } else {
      setcompareEngagementImpressionDropdown(e);
    }

    EventTracker({
      eventType: "dropdown_selected",
      module: pageModule,
      subModule: pageSubModule,
      platform: pagePlatform,
      tab: pagePrimaryTab,
      section: "Engagements vs",
      dropdownValue: e === null ? "Overall" : e.label,
      ...(BRAND_ID && { brands: BRAND_ID }),
      date: {
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate,
        previousStartDate: selectedDates.prevStartDate,
        previousEndDate: selectedDates.prevEndDate,
      }
    })

  };

  const fetchCompareEngagementImpressionDropdownHandler = async (hashtag) => {
    if (
      compareEngagementImpressionDropdown.value === "engagementsVsImpressions"
    ) {
      setCompareEngagementImpression(
        compareEngagementImpressionAllData.engagementsVsImpressions
      );
      setCompareEngagementImpressionDataLabels(
        compareEngagementImpressionAllLabel.engagementsVsImpressions
      );
    } else if (
      compareEngagementImpressionDropdown.value === "engagementsVsTweetLength"
    ) {
      setCompareEngagementImpression(
        compareEngagementImpressionAllData.engagementsVsTweetLength
      );
      setCompareEngagementImpressionDataLabels(
        compareEngagementImpressionAllLabel.engagementsVsTweetLength
      );
    } else {
      setCompareEngagementImpression([]);
      setCompareEngagementImpressionDataLabels([]);
    }
  };

  useEffect(() => {
    fetchCompareEngagementImpressionDropdownHandler();
  }, [BRAND_ID, compareEngagementImpressionDropdown, selectedDates]);


  const CallAPIs = (hashtag) => {
    fetchMetricCardsHandler(hashtag);
    fetchTweetImpressionsChartHandler(hashtag);
    fetchVideoViewQuartilesHandler(hashtag);
    fetchVideoViewsChartHandler(hashtag);
    fetchTweetEngagementHandler(hashtag);
    fetchPollVotesHandler(hashtag);
    fetchCampaignEffectivenessHandler(hashtag);
    fetchTweetActivityHandler(hashtag);
    fetchCompareEngagementImpressionHandler(hashtag);
  }


  // LOAD DATA ON FIRST PAGE LOAD OR DATE IS CHANGED
  useEffect(() => {
    CallAPIs()
    fetchHashtagListHandler()
    setSelectedHashtag('')
  }, [BRAND_ID, selectedDates, pageDropdown]);

  // useEffect(() => {
  //   fetchHashtagListHandler();
  // }, [BRAND_ID, selectedDates, pageDropdown]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS

  // TO FETCH TRENDING HASHTAG LIST FROM API
  const fetchHashtagListHandler = async (hashtag) => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/overall/hashtag/", config);

      setHashtagList(res?.data ?? []);

      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag == "") {
      setSelectedHashtag(badge.hashtag);
      CallAPIs(badge.hashtag);
    } else if (selectedHashtag == badge.hashtag) {
      setSelectedHashtag("");
      CallAPIs();
    } else {
      setSelectedHashtag(badge.hashtag);
      CallAPIs(badge.hashtag);
    }
    IAEvent_Hashtag_Visit(
      "Marketing Intelligence",
      "Social",
      "Twitter",
      "Post",
      "Overall",
      badge.hashtag
    );
  };

  let topTrendingHashtagsList = (
    <Wrapper>
      <Loader loaderType="hashLoader" />
    </Wrapper>
  );

  if (isHashtagListLoaded && !isHashtagListLoading) {
    if (NoDataAvailableChecker(filteredHashtagList)) {
      topTrendingHashtagsList = (
        <Wrapper>
          <NoDataAvailableLoader chartType="hashChartType" />
        </Wrapper>
      );
    } else {
      topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
        <Wrapper key={index}>
          <ErrorBoundary chartType="hashChartType">
            <div className="badge_wrapper">
              <button
                type="button"
                className={
                  selectedHashtag === badge.hashtag
                    ? "button_badge active"
                    : "button_badge"
                }
                onClick={() => handleEdit(badge)}
              >
                {badge.hashtag}
              </button>
            </div>
          </ErrorBoundary>
        </Wrapper>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="hashChartType"
          error={hashtagListerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = (
      <Wrapper>

        <Loader loaderType="hashLoader" />
      </Wrapper>
    );
  }

  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader =
      `: ` + (selectedHashtag ? selectedHashtag : "");
  }
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async (hashtag) => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/overall/overview/", config);

      setMetricCards(res?.data?.data ?? []);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary {tweetTypeDropdown}
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Summary {tweetTypeDropdown}
              </h2></div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider
              sliderTitle={"Summary" + tweetTypeDropdown + selectedHashtagSectionHeader}
              platform={"twitter"}
              tab={"post"}
              subtab={"overall"}
            >
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="social"
                    platform="twitter"
                    section="overall"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary {tweetTypeDropdown}
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary {tweetTypeDropdown}
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TWEET IMPRESSIONS

  // TO FETCH DATA FROM API
  const fetchTweetImpressionsChartHandler = async (hashtag) => {
    setTweetImpressionsIsLoading(true);
    setTweetImpressionsIsLoaded(false);
    setTweetImpressionsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/overall/impressions/",
        config
      );
      setTweetImpressions(res?.data?.data ?? []);
      setTweetImpressionsDataLabels(res?.data?.Dimensions ?? []);

      setTweetImpressionsIsLoading(false);
      setTweetImpressionsIsLoaded(true);
      setTweetImpressionsError(null);
    } catch (error) {
      setTweetImpressions([]);
      setTweetImpressionsDataLabels([]);
      setTweetImpressionsIsLoading(false);
      setTweetImpressionsIsLoaded(false);
      setTweetImpressionsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let tweetImpressionsChart = (
    <Wrapper>
      <Loader loaderType="songlLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isTweetImpressionsLoaded && !isTweetImpressionsLoading) {
    if (
      NoDataAvailableChecker(tweetImpressions) ||
      NoDataAvailableChecker(tweetImpressionsDataLabels)
    ) {
      tweetImpressionsChart = (
        <Wrapper>

          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      tweetImpressionsChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="tweet_impressions"
              chartClass="section_card_chart"
              chartData={tweetImpressions}
              chartLabels={tweetImpressionsDataLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (tweetImpressionserror) {
    tweetImpressionsChart = (
      <Wrapper>

        <ServerErrorsLoader
          chartType="lineChartType"
          error={tweetImpressionserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTweetImpressionsLoading) {
    tweetImpressionsChart = (
      <Wrapper>

        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIDEO VIEWS
  const [isVideoViewsOpen, setIsVideoViewsOpen] =
    useState(false);

  let defaultVideoViewsOpenDisabledValues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultVideoViewsOpenDisabledValues = [];
  } else {
    defaultVideoViewsOpenDisabledValues = ["Sales Volume"];
  }

  const [
    videoViewsOpenDisabledValues,
    setVideoViewsOpenDisabledValues,
  ] = useState(defaultVideoViewsOpenDisabledValues);


  // TO FETCH DATA FROM API
  const fetchVideoViewsChartHandler = async (hashtag) => {
    setVideoViewsIsLoading(true);
    setVideoViewsIsLoaded(false);
    setVideoViewsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/overall/video-views/",
        config
      );
      setVideoViews(res?.data?.data ?? []);

      setVideoViewsDataLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.Dimensions === undefined || res.data.Dimensions.length === 0 ? [] :
            res.data.Dimensions[0].linechartLabels === undefined || res.data.Dimensions[0].linechartLabels.length === 0 ? [] :
              res.data.Dimensions[0].barchartLabels === undefined || res.data.Dimensions[0].barchartLabels.length === 0 ? [] :
                res.data.Dimensions
      );

      setVideoViewsIsLoading(false);
      setVideoViewsIsLoaded(true);
      setVideoViewsError(null);
    } catch (error) {
      setVideoViews([]);
      setVideoViewsDataLabels([]);
      setVideoViewsIsLoading(false);
      setVideoViewsIsLoaded(false);
      setVideoViewsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let videoViewsChart = (
    <Wrapper>
      <Loader loaderType="combinedBarLineChartLoader" />
    </Wrapper>
  );
  let videoViewsChart2 = <Wrapper> <Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isVideoViewsLoaded && !isVideoViewsLoading) {
    if (
      NoDataAvailableChecker(videoViews) ||
      NoDataAvailableChecker(videoViewsDataLabels)
    ) {
      videoViewsChart = (
        <Wrapper>

          <NoDataAvailableLoader chartType="combinedBarLineChartType" />
        </Wrapper>
      );
      videoViewsChart2 = (
        <Wrapper> <NoDataAvailableLoader chartType="combinedBarLineChartType" /></Wrapper>
      );
    } else {
      if (isMultiLineCluster) {
        videoViewsChart = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <CombinedBarLineChart
                chartClass="section_card_chart"
                chartId="social_twitter_video_views_1"
                chartData={videoViews}
                chartLabels={videoViewsDataLabels}
                showLegend={true}
                disabledLegendArray={videoViewsOpenDisabledValues}
                setDisabledValues={setVideoViewsOpenDisabledValues}
              />
            </ErrorBoundary>
          </Wrapper>
        );
        videoViewsChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedCombinedBarLineMultipleAxesChart
                chartClass="section_card_chart"
                chartId="social_twitter_video_views_2"
                chartData={videoViews}
                chartLabels={videoViewsDataLabels}
                showLegend={true}
                disabledLegendArray={videoViewsOpenDisabledValues}
                setDisabledValues={setVideoViewsOpenDisabledValues}
                showLabels={true}
                showSecondY={false}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
      else {
        videoViewsChart = (
          <Wrapper>
            <ErrorBoundary chartType="combinedBarLineChartType">
              <CombinedBarLineMultipleAxesChart
                chartId="social_twitter_video_views"
                chartClass="section_card_chart"
                chartData={videoViews}
                chartLabels={videoViewsDataLabels}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoViewserror) {
    videoViewsChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="combinedBarLineChartType"
          error={videoViewserror}
        />
      </Wrapper>
    );
    videoViewsChart2 = (
      <Wrapper> <ServerErrorsLoader
        chartType="combinedBarLineChartType"
        error={videoViewserror}
      />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVideoViewsLoading) {
    videoViewsChart = (
      <Wrapper>
        <Loader loaderType="combinedBarLineChartLoader" />
      </Wrapper>
    );
    videoViewsChart2 = <Wrapper> <Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video View Quartiles

  const fetchVideoViewQuartilesHandler = async (hashtag) => {
    setVideoViewQuartilesIsLoading(true);
    setVideoViewQuartilesIsLoaded(false);
    setVideoViewQuartilesError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/overall/total-video-quartiles/",
        config
      );
      setVideoViewQuartiles(res?.data?.data ?? []);
      setVideoViewQuartilesDataLabels(res?.data?.label_list ?? []);


      setVideoViewQuartilesIsLoading(false);
      setVideoViewQuartilesIsLoaded(true);
      setVideoViewQuartilesError(null);
    } catch (error) {
      setVideoViewQuartiles([]);
      setVideoViewQuartilesDataLabels([]);
      setVideoViewQuartilesIsLoading(false);
      setVideoViewQuartilesIsLoaded(false);
      setVideoViewQuartilesError(error.code);
    }
  };

  let videoViewQuartilesChart = (
    <Wrapper>

      <Loader loaderType="barChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isVideoViewQuartilesLoaded && !isVideoViewQuartilesLoading) {
    if (
      NoDataAvailableChecker(videoViewQuartiles) ||
      NoDataAvailableChecker(videoViewQuartilesDataLabels)
    ) {
      videoViewQuartilesChart = (
        <Wrapper>

          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      videoViewQuartilesChart = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="social_twitter_quartiles"
              chartClass="section_card_chart"
              chartData={videoViewQuartiles}
              chartLabels={videoViewQuartilesDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoViewQuartileserror) {
    videoViewQuartilesChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="barChartType"
          error={videoViewQuartileserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVideoViewQuartilesLoading) {
    videoViewQuartilesChart = (
      <Wrapper>

        <Loader loaderType="barChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // POLL VOTES
  const [isPollVotesOpen, setIsPollVotesOpen] =
    useState(false);

  let defaultPollVotesDisabledValues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultPollVotesDisabledValues = [];
  } else {
    defaultPollVotesDisabledValues = ["Sales Volume"];
  }
  const [
    pollVotesDisabledValues,
    setPollVotesDisabledValues,
  ] = useState(defaultPollVotesDisabledValues);



  const fetchPollVotesHandler = async (hashtag) => {
    setPollVotesIsLoading(true);
    setPollVotesIsLoaded(false);
    setPollVotesError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/overall/poll/", config);

      setPollVotes(res?.data?.typewise?.data ?? []);
      setPollVotesDataLabels(res?.data?.typewise?.dimension ?? []);


      setPollVotesIsLoading(false);
      setPollVotesIsLoaded(true);
      setPollVotesError(null);
    } catch (error) {
      setPollVotes([]);
      setPollVotesDataLabels([]);
      setPollVotesIsLoading(false);
      setPollVotesIsLoaded(false);
      setPollVotesError(error.code);
    }
  };

  let pollVotesChart = <Wrapper><Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;
  let pollVotesChart2 = <Wrapper><Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;


  // IF DATA IS LOADED
  if (isPollVotesLoaded && !isPollVotesLoading) {
    if (NoDataAvailableChecker(pollVotes) || NoDataAvailableChecker(pollVotesDataLabels)) {
      pollVotesChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="combinedBarLineChartType" />
        </Wrapper>
      );
      pollVotesChart2 = (
        <Wrapper>  <NoDataAvailableLoader chartType="combinedBarLineChartType" /></Wrapper>
      );
    } else {
      if (isMultiLineCluster) {
        pollVotesChart = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <CombinedBarLineChart
                chartClass="section_card_chart"
                chartId="social_twitter_poll_votes_1"
                chartData={pollVotes}
                chartLabels={pollVotesDataLabels}
                showLegend={true}
                disabledLegendArray={pollVotesDisabledValues}
                setDisabledValues={setPollVotesDisabledValues}
              />
            </ErrorBoundary>
          </Wrapper>
        );
        pollVotesChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedCombinedBarLineMultipleAxesChart
                chartClass="section_card_chart"
                chartId="social_twitter_poll_votes_2"
                chartData={pollVotes}
                chartLabels={pollVotesDataLabels}
                showLegend={true}
                disabledLegendArray={pollVotesDisabledValues}
                setDisabledValues={setPollVotesDisabledValues}
                showLabels={true}
                showSecondY={false}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      } else {
        pollVotesChart = (
          <Wrapper>
            <ErrorBoundary chartType="combinedBarLineChartType">
              <CombinedBarLineMultipleAxesChart
                chartId="social_twitter_poll_votes"
                chartClass="section_card_chart"
                chartData={pollVotes}
                chartLabels={pollVotesDataLabels}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (pollVoteserror) {
    pollVotesChart = (
      <Wrapper>

        <ServerErrorsLoader
          chartType="combinedBarLineChartType"
          error={pollVoteserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPollVotesLoading) {
    pollVotesChart = (
      <Wrapper>

        <Loader loaderType="combinedBarLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TWEET ENGAGEMENT

  const fetchTweetEngagementHandler = async (hashtag) => {
    setTweetEngagementIsLoading(true);
    setTweetEngagementIsLoaded(false);
    setTweetEngagementError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/overall/engagement/", config);

      setTweetEngagement(res?.data?.data?.typewise ?? []);
      setTweetEngagementDataLabels(res?.data?.dimension?.typewise ?? []);
      setTweetEngagementAllData(res?.data?.data ?? []);
      setTweetEngagementAllLabels(res?.data?.dimension ?? []);

      setTweetEngagementIsLoading(false);
      setTweetEngagementIsLoaded(true);
      setTweetEngagementError(null);
    } catch (error) {
      setTweetEngagementAllData([]);
      setTweetEngagementAllLabels([]);
      setTweetEngagement([]);
      setTweetEngagementDataLabels([]);
      setTweetEngagementIsLoading(false);
      setTweetEngagementIsLoaded(false);
      setTweetEngagementError(error.code);
    }
  };

  let tweetEngagementChart = (
    <Wrapper>

      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isTweetEngagementLoaded && !isTweetEngagementLoading) {
    if (
      NoDataAvailableChecker(tweetEngagement) ||
      NoDataAvailableChecker(tweetEngagementDataLabels)
    ) {
      tweetEngagementChart = (
        <Wrapper>

          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      tweetEngagementChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="social_twitter_engagement_tweet"
              chartClass="section_card_chart"
              chartData={tweetEngagement}
              chartLabels={tweetEngagementDataLabels}
              // onBulletsClick={() => { }}
              showLabels
              showLegend
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (tweetEngagementerror) {
    tweetEngagementChart = (
      <Wrapper>

        <ServerErrorsLoader
          chartType="lineChartType"
          error={tweetEngagementerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTweetEngagementLoading) {
    tweetEngagementChart = (
      <Wrapper>

        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN EFFECTIVENESS

  const fetchCampaignEffectivenessHandler = async (hashtag) => {
    setCampaignEffectivenessIsLoading(true);
    setCampaignEffectivenessIsLoaded(false);
    setCampaignEffectivenessError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/overall/campagin-efffectiveness/",
        config
      );
      setCampaignEffectiveness(res?.data?.data[0]?.impressions ?? []);
      setCampaignEffectivenessDataLabels(res?.data?.dimension[0]?.impressions ?? []);
      setCampaignEffectivenessAllData(res?.data ?? []);

      setCampaignEffectivenessIsLoading(false);
      setCampaignEffectivenessIsLoaded(true);
      setCampaignEffectivenessError(null);
    } catch (error) {
      setCampaignEffectivenessAllData([]);
      setCampaignEffectiveness([]);
      setCampaignEffectivenessDataLabels([]);
      setCampaignEffectivenessIsLoading(false);
      setCampaignEffectivenessIsLoaded(false);
      setPollVotesError(setCampaignEffectivenessError.code);
    }
  };

  let campaignEffectivenessChart = (
    <Wrapper>

      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isCampaignEffectivenessLoaded && !isCampaignEffectivenessLoading) {
    if (
      NoDataAvailableChecker(campaignEffectiveness) ||
      NoDataAvailableChecker(campaignEffectivenessDataLabels)
    ) {
      campaignEffectivenessChart = (
        <Wrapper>

          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      campaignEffectivenessChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="social_twitter_poll_votes"
              chartClass="section_card_chart"
              chartData={campaignEffectiveness}
              chartLabels={campaignEffectivenessDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignEffectivenesserror) {
    campaignEffectivenessChart = (
      <Wrapper>

        <ServerErrorsLoader
          chartType="lineChartType"
          error={campaignEffectivenesserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCompareEngagementImpressionLoading) {
    campaignEffectivenessChart = (
      <Wrapper>

        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TWEET ACTIVITY

  const fetchTweetActivityHandler = async (hashtag) => {
    setTweetActivityIsLoading(true);
    setTweetActivityIsLoaded(false);
    setTweetActivityError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/overall/bubble-heatmap/",
        config
      );
      setTweetActivity(res?.data?.data[0]?.organic ?? []);
      setTwitterActivityAllData(res?.data?.data[0] ?? []);
      setTweetActivityDataXAxisLabels(res?.data?.xaxis_labels ?? []);


      setTweetActivityDataYAxisLabels(res?.data?.yaxis_labels ?? []);


      setTweetActivityIsLoading(false);
      setTweetActivityIsLoaded(true);
      setTweetActivityError(null);
    } catch (error) {
      setTweetActivity([]);
      setTwitterActivityAllData([]);
      setTweetActivityDataXAxisLabels([]);
      setTweetActivityDataYAxisLabels([]);
      setTweetActivityIsLoading(false);
      setTweetActivityIsLoaded(false);
      setPollVotesError(tweetActivityerror.code);
    }
  };

  let tweetActivityChart = (
    <Wrapper>
      <Loader loaderType="heatChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isTweetActivityLoaded && !isTweetActivityLoading) {
    if (
      NoDataAvailableChecker(tweetActivity) ||
      NoDataAvailableChecker(tweetActivityDataXAxisLabels) ||
      NoDataAvailableChecker(tweetActivityDataYAxisLabels)
    ) {
      tweetActivityChart = (
        <Wrapper>

          <NoDataAvailableLoader chartType="heatChartType" />
        </Wrapper>
      );
    } else {
      tweetActivityChart = (
        <Wrapper>
          <ErrorBoundary chartType="heatChartType">
            <HeatMap
              chartClass="section_card_chart"
              varient="Bubble"
              chartData={tweetActivity}
              chartId="tweetActivity"
              chartXaxisLabels={tweetActivityDataXAxisLabels}
              chartYaxisLabels={tweetActivityDataYAxisLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (tweetActivityerror) {
    tweetActivityChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="heatChartType"
          error={tweetActivityerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTweetActivityLoading) {
    tweetActivityChart = (
      <Wrapper>
        <Loader loaderType="heatChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // ENGAGEMENT VS IMPRESSION

  const fetchCompareEngagementImpressionHandler = async (hashtag) => {
    setCompareEngagementImpressionIsLoading(true);
    setCompareEngagementImpressionIsLoaded(false);
    setCompareEngagementImpressionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/overall/bubble-graph/",
        config
      );

      setcompareEngagementImpressionAllData(res?.data?.data[0] ?? []);
      setcompareEngagementImpressionAllLabel(res?.data?.Dimension ?? []);

      setCompareEngagementImpression(res?.data?.data[0]?.engagementsVsImpressions ?? []);
      setCompareEngagementImpressionDataLabels(res?.data?.Dimension?.engagementsVsImpressions ?? []);


      setCompareEngagementImpressionIsLoading(false);
      setCompareEngagementImpressionIsLoaded(true);
      setCompareEngagementImpressionError(null);
    } catch (error) {
      setcompareEngagementImpressionAllData([]);
      setcompareEngagementImpressionAllLabel([]);
      setCompareEngagementImpression([]);
      setCompareEngagementImpressionDataLabels([]);
      setCompareEngagementImpressionIsLoading(false);
      setCompareEngagementImpressionIsLoaded(false);
      setCompareEngagementImpressionError(error.code);
    }
  };

  let compareEngagementImpressionChart = (
    <Wrapper>
      <Loader loaderType="bubbleChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isCompareEngagementImpressionLoaded && !isCampaignEffectivenessLoading) {
    if (
      NoDataAvailableChecker(compareEngagementImpression) ||
      NoDataAvailableChecker(compareEngagementImpressionDataLabels)
    ) {
      compareEngagementImpressionChart = (
        <Wrapper>

          <NoDataAvailableLoader chartType="bubbleChartType" />
        </Wrapper>
      );
    } else {
      compareEngagementImpressionChart = (
        <Wrapper>
          <ErrorBoundary chartType="bubbleChartType">
            <ZooomableBubblechart
              chartClass="section_card_chart"
              chartData={compareEngagementImpression}
              chartId="compareEngagement"
              chartLabels={compareEngagementImpressionDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (compareEngagementImpressionerror) {
    compareEngagementImpressionChart = (
      <Wrapper>

        <ServerErrorsLoader
          chartType="bubbleChartType"
          error={compareEngagementImpressionerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTweetActivityLoading) {
    compareEngagementImpressionChart = (
      <Wrapper>

        <Loader loaderType="bubbleChartLoader" />
      </Wrapper>
    );
  }

  let compareEngagementImpressionChartLegends

  if (isCompareEngagementImpressionLoaded && !isCampaignEffectivenessLoading) {
    if (NoDataAvailableChecker(NoDataAvailableChecker(compareEngagementImpression) ||
      NoDataAvailableChecker(compareEngagementImpressionDataLabels))) {
      compareEngagementImpressionChartLegends =
        <Wrapper>
          <div className="w_100 p_b_10">
            <ul className="icz-rilist-inline">
              <li>
                <div
                  className="legend-circle"
                  style={{ background: "#11a1fd" }}
                ></div>{" "}
                Text
              </li>
              <li>
                <div
                  className="legend-circle"
                  style={{ background: "#5a75f9" }}
                ></div>{" "}
                Photo
              </li>
              <li>
                <div
                  className="legend-circle"
                  style={{ background: "#ff9931" }}
                ></div>{" "}
                Video
              </li>
            </ul>
          </div>
        </Wrapper>
    }
  }

  var combineCSV = function (data) {
    var temp =
      data === undefined || null
        ? []
        : Object.values(data).map((item) => {
          var date = new Date(item.label);

          return { ...item, label: date.toLocaleDateString("en-GB") };
        });

    return temp;
  };

  var tweetTypeExport = pageDropdownObject.length === 0 || pageDropdownObject === "" ? "" : pageDropdownObject.label === undefined ? "" : ": " + pageDropdownObject.label;

  var allData = {
    ...topCardData,
    [`SummaryCardDynaLabels_Summary Card_${tweetTypeExport}`]: metricCards,
    [`DateFirstNonActiveDropdownLabels_Tweet Impressions_${tweetTypeExport}`]: tweetImpressions,
    [`DateFirstNonActiveDropdownLabels_Video Views_${tweetTypeExport}`]: combineCSV(videoViews),
    [`DonutWithActiveDropdown_Video Views Quartiles_${pageDropdownObject.length === 0 || pageDropdownObject === "" ? "Video View Quartiles" : pageDropdownObject.label === undefined ? "Video View Quartiles" : "Video View Quartiles :" + pageDropdownObject.label}`]: videoViewQuartiles,
    "Poll Votes": pollVotes,

    [`SocialTweetEngage_Tweet Engagements_${tweetEngagementDropdownOption.label} ${tweetTypeExport}`]: tweetEngagement,
    [`SocialTweetEngage_Campaign Effectiveness_${campaignEffectivenessDropdown.label} ${tweetTypeExport}`]: campaignEffectiveness,
    [`DynaLabelHeatmap_Tweet Activity_${tweetActivityDropdown.label} ${tweetTypeExport}`]: tweetActivity,
    [`SocialTwitterEngageVsImpression_Engagement vs Impressions_${compareEngagementImpressionDropdown.label} ${tweetTypeExport}`]: compareEngagementImpression,
  };

  useEffect(() => {
    setExportPageName("Social Twitter Post Overall");
    setFullPageExport(allData);
  }, [
    metricCards,
    tweetImpressions,
    videoViews,
    videoViewQuartiles,
    pollVotes,
    tweetEngagement,
    campaignEffectiveness,
    tweetActivity,
    compareEngagementImpression,
  ]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (tweetImpressionserror !== null ? true : isTweetImpressionsLoaded && (isTweetImpressionsLoading === false)) &&
      (videoViewserror !== null ? true : isVideoViewsLoaded && (isVideoViewsLoading === false)) &&
      (videoViewQuartileserror !== null ? true : isVideoViewQuartilesLoaded && (isVideoViewQuartilesLoading === false)) &&
      (pollVoteserror !== null ? true : isPollVotesLoaded && (isPollVotesLoading === false)) &&
      (tweetEngagementerror !== null ? true : isTweetEngagementLoaded && (isTweetEngagementLoading === false)) &&
      (campaignEffectivenesserror !== null ? true : isCampaignEffectivenessLoaded && (isCampaignEffectivenessLoading === false)) &&
      (tweetActivityerror !== null ? true : isTweetActivityLoaded && (isTweetActivityLoading === false)) &&
      (compareEngagementImpressionerror !== null ? true : isCompareEngagementImpressionLoaded && (isCompareEngagementImpressionLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    tweetImpressionserror, isTweetImpressionsLoaded, isTweetImpressionsLoading,
    videoViewserror, isVideoViewsLoaded, isVideoViewsLoading,
    videoViewQuartileserror, isVideoViewQuartilesLoaded, isVideoViewQuartilesLoading,
    pollVoteserror, isPollVotesLoaded, isPollVotesLoading,
    tweetEngagementerror, isTweetEngagementLoaded, isTweetEngagementLoading,
    campaignEffectivenesserror, isCampaignEffectivenessLoaded, isCampaignEffectivenessLoading,
    tweetActivityerror, isTweetActivityLoaded, isTweetActivityLoading,
    compareEngagementImpressionerror, isCompareEngagementImpressionLoaded, isCompareEngagementImpressionLoading,
  ])


  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <div className="section_info">   <h2 className="section_card_title">
            Trending Hashtags {tweetTypeDropdown}
          </h2><SectionDefinationsBadge
              title="Trending Hashtags"
              module="social"
              platform="twitter"
              section="overall"
            />
          </div>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topTrendingHashtagsList}</div>
        </div>
      </div>
      {metricCardsSection}
      <div
        id="tweet_impression_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Tweet Impressions
              {tweetTypeDropdown}
              {selectedHashtagSectionHeader}
            </h2><SectionDefinationsBadge
                title="Tweet Impressions"
                module="social"
                platform="twitter"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"tweet_impression_section"}
                sectionName={`DateFirstNonActiveDropdownLabels_Tweet Impressions_${tweetTypeExport}`}
                sectionData={tweetImpressions}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {tweetImpressionsChart}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div id="video_views_section" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Video Views  {tweetTypeDropdown} {selectedHashtagSectionHeader}
            </h2> <SectionDefinationsBadge
                title="Video views"
                module="social"
                platform="twitter"
                section="overall"
              />
            </div>
            <Modal
              open={isVideoViewsOpen}
              setOpen={setIsVideoViewsOpen}
              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={
                    "social_twitter_video_views_2" //chart2 id
                  }
                  sectionName={`DateFirstNonActiveDropdownLabels_Video Views_${tweetTypeExport}`}
                  sectionData={combineCSV(videoViews)}
                />
              }
              parentId={"video_views_section"} // div section id
              title={`Video Views  ${tweetTypeDropdown ? ` : ${tweetTypeDropdown}` : ''}  ${selectedHashtagSectionHeader ? ` : ${selectedHashtagSectionHeader}` : ''}`}
              chart={videoViewsChart2} //chart2 data
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && (
                <button
                  onClick={() => setIsVideoViewsOpen(true)}
                  className="icon_btn btn-small-secondary expand_btn"
                >
                  <ExpandIcon />
                </button>
              )}
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"video_views_section"}
                sectionName={`DateFirstNonActiveDropdownLabels_Video Views_${tweetTypeExport}`}
                sectionData={combineCSV(videoViews)}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">{videoViewsChart}</div>
          </div>
        </div>
        <div
          id="video_views_quartiles_section"
          className="grid col_span_2 section_card"
        >
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">
              Video Views (Quartiles)  {tweetTypeDropdown} {selectedHashtagSectionHeader}
            </h2> <SectionDefinationsBadge
                title="Video Views (Quartiles)"
                module="social"
                platform="twitter"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"video_views_quartiles_section"}
                sectionName={`DonutWithActiveDropdown_Video Views Quartiles_${pageDropdownObject.length === 0 || pageDropdownObject === "" ? "Video View Quartiles" : pageDropdownObject.label === undefined ? "Video View Quartiles" : "Video View Quartiles :" + pageDropdownObject.label}`}
                sectionData={videoViewQuartiles}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {videoViewQuartilesChart}
            </div>
          </div>
        </div>
      </div>
      <div
        id="poll_votes_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Poll Votes  {tweetTypeDropdown} {selectedHashtagSectionHeader}
            </h2> <SectionDefinationsBadge
                title="Poll Votes"
                module="social"
                platform="twitter"
                section="overall"
              />
            </div>
            <Modal
              open={isPollVotesOpen}
              setOpen={setIsPollVotesOpen}

              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={
                    "social_twitter_poll_votes_2" //chart2 id
                  }
                  sectionName={"Poll Votes"}
                  sectionData={pollVotes}
                />
              }
              parentId={"poll_votes_section"} // div section id
              title={`Poll Votes`}
              chart={pollVotesChart2} //chart2 data
            />

            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && (
                <button
                  onClick={() => setIsPollVotesOpen(true)}
                  className="icon_btn btn-small-secondary expand_btn"
                >
                  <ExpandIcon />
                </button>
              )}
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"poll_votes_section"}
                sectionName={"Poll Votes"}
                sectionData={combineCSV(pollVotes)}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">{pollVotesChart}</div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="tweet_engagements_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info"> <h2 className="section_card_title">
              Tweet Engagement  {tweetTypeDropdown} {" : " + tweetEngagementDropdownOption.label} {selectedHashtagSectionHeader}
            </h2> <SectionDefinationsBadge
                title="Tweet Engagement"
                module="social"
                platform="twitter"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>

              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={tweetEngagementOptions}
                className="form_dropdown section_dropdown"
                value={tweetEngagementDropdownOption}
                selectedOptions={tweetEngagementDropdownOption}
                setStatedropdown={tweetEngagementSelectionHandler}
              />
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"tweet_engagements_section"}
                sectionName={`SocialTweetEngage_Tweet Engagements_${tweetEngagementDropdownOption.label} ${tweetTypeExport}`}
                sectionData={tweetEngagement}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {tweetEngagementChart}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="campaign_effectiveness_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Campaign Effectiveness  {tweetTypeDropdown} {" : " + campaignEffectivenessDropdown.label} {selectedHashtagSectionHeader}
            </h2> <SectionDefinationsBadge
                title="Campaign Effectiveness"
                module="social"
                platform="twitter"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>

              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={campaignEffectivenessOptions}
                className="form_dropdown section_dropdown"
                value={campaignEffectivenessDropdown}
                selectedOptions={campaignEffectivenessDropdown}
                setStatedropdown={campaignSelectionHandler}
              />
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"campaign_effectiveness_section"}
                sectionName={`SocialTweetEngage_Campaign Effectiveness_${campaignEffectivenessDropdown.label}${tweetTypeExport}`}
                sectionData={campaignEffectiveness}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {campaignEffectivenessChart}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="tweet_activity_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Tweet Activity  {tweetTypeDropdown} {" : " + tweetActivityDropdown.label} {selectedHashtagSectionHeader}
            </h2> <SectionDefinationsBadge
                title="Tweet Activity"
                module="social"
                platform="twitter"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>

              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={tweetActivityOptions}
                className="form_dropdown section_dropdown"
                value={tweetActivityDropdown}
                selectedOptions={tweetActivityDropdown}
                setStatedropdown={tweetActivitySelectionHandler}
              />
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"tweet_activity_section"}
                sectionName={`DynaLabelHeatmap_Tweet Activity_${tweetActivityDropdown.label}${tweetTypeExport}`}
                sectionData={tweetActivity}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {tweetActivityChart}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="engagement_impressions_section"
        className="grid grid_cols_4 fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">  <h2 className="section_card_title">
              Engagement vs {compareEngagementImpressionDropdown.label}
              {tweetTypeDropdown}
              {selectedHashtagSectionHeader}
            </h2> <SectionDefinationsBadge
                title={"Engagement vs Impression"}
                module="social"
                platform="twitter"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>

              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={compareEngagementImpressionOptions}
                className="form_dropdown section_dropdown"
                value={compareEngagementImpressionDropdown}
                selectedOptions={compareEngagementImpressionDropdown}
                setStatedropdown={compareEngagementImpressionSelectionHandler}
              />
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"engagement_impressions_section"}
                sectionName={`SocialTwitterEngageVsImpression_Engagement vs Impressions_${compareEngagementImpressionDropdown.label}${tweetTypeExport}`}
                sectionData={compareEngagementImpression}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {compareEngagementImpressionChart}
              </div>
              {compareEngagementImpressionChartLegends}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SocialTwitterPostOverall;
