import Wrapper from "../../components/helpers/Wrapper";

const SliderArrowRight = (props) => {
    return (
        <Wrapper>
            <svg className={props.className} width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 6H11M11 6L6 1M11 6L6 11" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </Wrapper>
    )
}

export default SliderArrowRight;
