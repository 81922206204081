import React from 'react'

export default function PublicIcon(props) {
  return (
    <svg className={props.className} width="45" height="50" viewBox="0 0 45 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44.2863 49V43.5741C44.2866 42.1528 44.0068 40.7453 43.463 39.432C42.9192 38.1188 42.122 36.9256 41.1169 35.9205C40.1118 34.9155 38.9186 34.1183 37.6054 33.5744C36.2922 33.0306 34.8847 32.7508 33.4633 32.7511H11.8211C10.3999 32.7511 8.99262 33.0311 7.67962 33.575C6.36662 34.1189 5.1736 34.9162 4.16875 35.9212C3.16389 36.9262 2.36685 38.1194 1.82315 39.4325C1.27945 40.7456 0.999748 42.1529 1 43.5741V49" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.3" />
      <path d="M33.4628 11.823C33.4628 13.9637 32.828 16.0563 31.6387 17.8362C30.4493 19.6161 28.7589 21.0033 26.7811 21.8224C24.8034 22.6415 22.6271 22.8557 20.5276 22.4379C18.4281 22.0202 16.4996 20.9892 14.9861 19.4754C13.4725 17.9616 12.4419 16.0329 12.0245 13.9333C11.6071 11.8337 11.8217 9.65752 12.6412 7.6799C13.4606 5.70229 14.8482 4.01209 16.6282 2.82307C18.4083 1.63406 20.5011 0.99962 22.6417 1C24.0629 1 25.4702 1.27996 26.7832 1.82389C28.0962 2.36782 29.2892 3.16507 30.2941 4.17011C31.2989 5.17514 32.096 6.36828 32.6397 7.68138C33.1834 8.99447 33.4631 10.4018 33.4628 11.823Z" stroke="white" strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}
