import Wrapper from "../../components/helpers/Wrapper";

const RetweetIcon = (props) => {
  return (
    <Wrapper>
      <svg className={props.className} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.6934 22.8276H11.5761C10.7211 22.8514 9.87058 22.6967 9.07878 22.3732C8.28698 22.0497 7.57131 21.5645 6.9776 20.9488C6.17714 19.7665 5.85079 18.3263 6.06342 16.9144V11" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M9.3066 5.00237H16.4239C17.2789 4.97856 18.1294 5.13331 18.9212 5.45682C19.713 5.78033 20.4287 6.26544 21.0224 6.88117C21.8229 8.06349 22.1492 9.50368 21.9366 10.9156V16.83" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M2 10L6 6L10 10" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18 17L22 21L26 17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Wrapper>
  );
};

export default RetweetIcon;
