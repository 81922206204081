import React, { useContext, useEffect, useState } from "react";


// STORAGE
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";
import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";

// PAGES
import ThumbnailFacebookVideoOverall from "./ThumbnailFacebookVideoOverall";
import ThumbnailFacebookVideoOrganic from "./ThumbnailFacebookVideoOrganic";
import ThumbnailFacebookVideoPaid from "./ThumbnailFacebookVideoPaid";

export default function ThumbnailFacebookVideo({ videoIdentity, videoDurationType, topCardData }) {
  const { setExportPageName } = useContext(FullPageExportContext);

  const tabData = ["Overall", "Organic", "Paid"];
  const [selectedTab, setSelectedTab] = useState(tabData[0]);

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Video", "Facebook Thumbnail", event)
  };

  useEffect(() => {
    setExportPageName("Video Facebook Thumbnail " + selectedTab)
  }, [selectedTab])

  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <Tab
          varient={"secondary"}
          tabData={tabData}
          activeTab={selectedTab}
          handleCallback={(event) => handleCallback(event)}
        />
      </div>
      {selectedTab.toLowerCase() === tabData[0].toLowerCase() && (
        <ThumbnailFacebookVideoOverall videoIdentity={videoIdentity} videoDurationType={videoDurationType} topCardData={topCardData} />
      )}
      {selectedTab.toLowerCase() === tabData[1].toLowerCase() && (
        <ThumbnailFacebookVideoOrganic videoIdentity={videoIdentity} videoDurationType={videoDurationType} topCardData={topCardData} />
      )}
      {selectedTab.toLowerCase() === tabData[2].toLowerCase() && (
        <ThumbnailFacebookVideoPaid videoIdentity={videoIdentity} videoDurationType={videoDurationType} topCardData={topCardData} />
      )}
    </Wrapper>
  );
}
