import { useLocation } from "react-router-dom";

const MainContentWrapper = (props) => {

  const location = useLocation();
  const pathname = location.pathname.split('/')


  let updateClassNames = pathname.includes('tv') || pathname.includes('sales') ? '' : 'main_content_wrapper'

  return (
    <div id="content_wrapper" className={updateClassNames}>
      {props.children}
    </div>
  )
}

export default MainContentWrapper