import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper"
import Tab from "../../../../components/ui/Tab";

// STORAGE
import FullPageExportContext from "../../../../store/fullPageExportContext";

// UTILS
import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";
import CampaignMarketplaceAmazon from "./CampaignMarketplaceAmazon";

// CONSTANT

const CampaignMarketplace = ({ platformData }) => {
    const { exportSummary, setExportPageName } = useContext(FullPageExportContext);
    // let tabData = ["Summary", "Amazon", "Flipkart", "1MG", "Bigbasket"];
    let tabData = ["Amazon"];

    const [selectedTab, setSelectedTab] = useState(tabData[0]);

    const handleCallback = (event) => {
        if (selectedTab !== event) {
            setSelectedTab(event);
        }
        IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Campaign", "Marketplace", event)
    };

    useEffect(() => {
        setExportPageName("Campaign Marketplace " + selectedTab)
    }, [selectedTab])

    return (
        <Wrapper>
            <div className="grid grid_margin_bottom">
                <Tab
                    varient={"primary"}
                    tabData={tabData}
                    activeTab={selectedTab}
                    handleCallback={(event) => handleCallback(event)}
                />
            </div>
            {selectedTab.toLowerCase() === "amazon" && <CampaignMarketplaceAmazon topCardData={{ Summary: exportSummary, "PlatformCards_Platform Cards": platformData }} />}
        </Wrapper>
    )
}

export default CampaignMarketplace;