import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from '../helpers/Wrapper';

const PageHeader = ({ pageTitle }) => {
    return (
        <Wrapper>
            <div className='page_header'>
                <h1>{pageTitle}</h1>
            </div>
        </Wrapper>
    );
};

PageHeader.propTypes = {
    pageTitle: PropTypes.string,
};

export default PageHeader;