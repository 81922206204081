import ForgotPasswordForm from "../components/forms/ForgotPasswordForm";
import Wrapper from "../components/helpers/Wrapper";

export default function ForgotPasswordPage() {
    return (
        <Wrapper>
            <div className="flex justify_center h_100">
                <div className="form_container">
                    <div className="section_card">
                        <ForgotPasswordForm />
                    </div>
                </div>
            </div>
        </Wrapper>

    )
}