import React from "react";
import { useState, useMemo, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import Slider from "../../../../components/ui/Slider";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";
import axios from "../../../../components/helpers/axios";
import TopProductCompare from "../../../../components/ui/TopProductCompare";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import Datatable from "../../../../components/ui/tables/Datatable";
/* import WorldMap from "../../../../components/ui/map/WorldMap"; */

// ASSETS
import FemaleIcon from "../../../../assets/icons/FemaleIcon";
import MaleIcon from "../../../../assets/icons/MaleIcon";

// CHARTS
import BarChart from "../../../../components/ui/charts/BarChart";
import CombinedBarLineMultipleAxesChart from "../../../../components/ui/charts/CombinedBarLineMultipleAxesChart";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import HeatMap from "../../../../components/ui/charts/HeatMap";
import LineChart from "../../../../components/ui/charts/LineChart";
import PieofaPieChart from "../../../../components/ui/charts/PieofaPieChart";
import SolidGauge from "../../../../components/ui/charts/SolidGauge";

// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NumberFormatter from "../../../../utils/NumberFormatter";

// SAMPLE DATA
/* import {
  BarChartDataLabelssample,
  BarChartDataSample,
  CombinedBarLineMultipleAxesChartDataLabelsSample,
  CombinedBarLineMultipleAxesChartDataSample,
  DonutPieChartDataSample,
} from "../../../../data/chartsDataSamples";
import {
  facebookVideoHastags,
  facebookMetricApi,
  videoFacebookOverallHeatmapData,
  videoFacebookOverallHeatmapXaxisLabel,
  videoFacebookOverallHeatmapYaxisLabel,
  mapdata,
} from "../../../../data/mi/video/facebook/facebookVideosSampleData"; */

// import { BRAND_ID, VIDEO_DURATION_ } from "../../../../constants/constants";
import { IAEvent_Hashtag_Visit } from "../../../../utils/IAEvents";

import PartitionedCombinedBarLineMultipleAxesChart from "../../../../components/ui/charts/PartitionedCombinedBarLineMultipleAxesChart";
import CombinedBarLineChart from "../../../../components/ui/charts/CombinedBarLineChart";
import ExpandIcon from "../../../../assets/icons/ExpandIcon";
import Modal from "../../../../components/ui/Modal";
import PartitionedLineChart from "../../../../components/ui/charts/PartitionedLineChart";
import { COKE_COMPANY_ID } from "../../../../constants/constants";
import EventTracker from "../../../../components/eventsTracking/EventTracker";

export default function FacebookAllVideosOverall({
  videoDurationType,
  topCardData,
}) {

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Video"
  let pagePlatform = "Facebook"
  let pagePrimaryTab = "Overall"

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, exportSummary, setIsExportDisabled } = useContext(
    FullPageExportContext
  );
  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );
  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)
  const userData = useSelector((state) => state.userData.user[0]);

  let companyID = userData?.company?.id ?? '';

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const selectedDates = useSelector((state) => state.date);

  const [searchTerm, setSeachTerm] = useState("");

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // Video Length Analysis
  const [videoLengthAnalysis, setVideoLengthAnalysis] = useState([]);
  const [videoLengthAnalysisLabel, setVideoLengthAnalysisLabel] = useState([]);
  const [isVideoLengthAnalysisLoading, setVideoLengthAnalysisIsLoading] =
    useState(false);
  const [isVideoLengthAnalysisLoaded, setVideoLengthAnalysisIsLoaded] =
    useState(false);
  const [videoLengthAnalysiserror, setVideoLengthAnalysisError] =
    useState(null);

  // Video Bifurcation DD
  /*  const [
     videoLengthAnalysisAllDropdownData,
     setVideoLengthAnalysisAllDropdownData,
   ] = useState([]);
   const [
     videoLengthAnalysisAllDropdownLabel,
     setVideoLengthAnalysisAllDropdownLabel,
   ] = useState([]);
 
   const videoBifurcationOptions = [
     {
       label: "Snackable",
       value: "total_video_views_sound_on",
     },
     {
       label: "Long",
       value: "total_video_views_clicked_to_play",
     },
     {
       label: "Short",
       value: "total_video_views_autoplayed",
     },
   ];
 
   const VideoBifurcationSelectionHandler = (e) => {
     if (e === null) {
       setVideoLengthAnalysisAllDropdownData("");
     } else {
       setVideoLengthAnalysisAllDropdownData(e);
     }
   };
 
   const fetchVidoBifurcationDropdownHandler = async (hashtag) => {
     if (
       videoLengthAnalysisAllDropdownData.value === "total_video_views_sound_on"
     ) {
       // setVideoLengthAnalysis([])
       // setVideoLengthAnalysisLabel([])
     } else if (
       videoLengthAnalysisAllDropdownData.value ===
       "total_video_views_clicked_to_play"
     ) {
       // setVideoLengthAnalysis([])
       // setVideoLengthAnalysisLabel([])
     } else if (
       videoLengthAnalysisAllDropdownData.value ===
       "total_video_views_autoplayed"
     ) {
       // setVideoLengthAnalysis([])
       // setVideoLengthAnalysisLabel([])
     } else {
       // setVideoLengthAnalysis([])
       // setVideoLengthAnalysisLabel([])
     }
   }; */

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  // useEffect(() => {
  //   fetchVidoBifurcationDropdownHandler();
  // }, [videoLengthAnalysisAllDropdownData]);

  // View Trend Analysis
  const [viewTrendAnalysis, setViewTrendAnalysis] = useState([]);
  const [isViewTrendAnalysisLoading, setViewTrendAnalysisIsLoading] =
    useState(false);
  const [isViewTrendAnalysisLoaded, setViewTrendAnalysisIsLoaded] =
    useState(false);
  const [viewTrendAnalysisError, setViewTrendAnalysisError] = useState(null);

  // Daily trend line
  const [dailyTrendLine, setDailyTrendLine] = useState([]);
  const [dailyTrendLineLabel, setDailyTrendLineLabel] = useState([]);
  const [isDailyTrendLineLoading, setDailyTrendLineIsLoading] = useState(false);
  const [isDailyTrendLineLoaded, setDailyTrendLineIsLoaded] = useState(false);
  const [dailyTrendLineError, setDailyTrendLineError] = useState(null);

  // Playing Behaviour Analysis Donut Chart
  const [playingBehaviourAnalysis, setPlayingBehaviourAnalysis] = useState([]);
  const [
    playingBehaviourAnalysisAllDropdownsData,
    setPlayingBehaviourAnalysisAllDropdownsData,
  ] = useState([]);
  const [
    isPlayingBehaviourAnalysisLoading,
    setPlayingBehaviourAnalysisIsLoading,
  ] = useState(false);
  const [
    isPlayingBehaviourAnalysisLoaded,
    setPlayingBehaviourAnalysisIsLoaded,
  ] = useState(false);
  const [playingBehaviourAnalysisError, setPlayingBehaviourAnalysisError] =
    useState(null);

  // Group Playing Behaviour  Multiple BarChart
  const [groupPlayingBehaviour, setGroupPlayingBehaviour] = useState([]);
  const [groupPlayingBehaviourLabel, setGroupPlayingBehaviourLabel] = useState(
    []
  );
  const [isGroupPlayingBehaviourLoading, setGroupPlayingBehaviourIsLoading] =
    useState(false);
  const [isGroupPlayingBehaviourLoaded, setGroupPlayingBehaviourIsLoaded] =
    useState(false);
  const [groupPlayingBehaviourError, setGroupPlayingBehaviourError] =
    useState(null);

  // DROPDOWN OPTIONS
  const groupPlayingDropdownOptions = [
    { value: "total_video_views", label: "3 second Views" },
    { value: "total_video_10s_views", label: "10 second Views" },
    { value: "total_video_30s_views", label: "30 second Views" },
    { value: "total_video_complete_views", label: "Complete views" },
    // { value: "total_video_views_sound_on", label: "Sound On" },
    // { value: "total_video_views_clicked_to_play", label: "Clicked To play" },
    // { value: "total_video_views_autoplayed", label: "Autoplay" },
  ];

  const [groupPlayingDropdownValue, setGroupPlayingDropdownValue] = useState(groupPlayingDropdownOptions[0]);
  const [groupPlayingAllData, setGroupPlayingAllData] = useState([]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const groupPlayingSelectionHandler = (e) => {
    if (e === null) {
      setGroupPlayingDropdownValue("");
    } else {
      setGroupPlayingDropdownValue(e);
    }

    EventTracker({
      eventType: "dropdown_selected",
      module: pageModule,
      subModule: pageSubModule,
      platform: pagePlatform,
      tab: pagePrimaryTab,
      section: "Playing Behaviour Analysis",
      dropdownValue: e === null ? "Overall" : e.label,
      ...(BRAND_ID && { brands: BRAND_ID }),
      date: {
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate,
        previousStartDate: selectedDates.prevStartDate,
        previousEndDate: selectedDates.prevEndDate,
      }
    })

  };

  const fetchGroupPlayingHandler = () => {
    if (groupPlayingDropdownValue.value === "total_video_complete_views") {
      setPlayingBehaviourAnalysis(playingBehaviourAnalysisAllDropdownsData?.playing_behaviour_query ?? []);
      setGroupPlayingBehaviour(groupPlayingAllData?.data?.total_video_complete_views ?? []);
      setGroupPlayingBehaviourLabel(groupPlayingAllData?.label?.total_video_complete_views ?? []);
    }
    // else if (
    //   groupPlayingDropdownValue.value === "total_video_views_clicked_to_play"
    // ) {
    //   setPlayingBehaviourAnalysis(playingBehaviourAnalysisAllDropdownsData.length===0?[]: playingBehaviourAnalysisAllDropdownsData);
    //   setGroupPlayingBehaviour(
    //     groupPlayingAllData.length === 0?[]: groupPlayingAllData.data.total_video_views_clicked_to_play
    //   );
    //   setGroupPlayingBehaviourLabel(
    //     groupPlayingAllData.length === 0?[]: groupPlayingAllData.label.total_video_views_clicked_to_play
    //   );
    // }
    // else if (
    //   groupPlayingDropdownValue.value === "total_video_views_autoplayed"
    // ) {
    //   setPlayingBehaviourAnalysis(playingBehaviourAnalysisAllDropdownsData.length===0?[]: playingBehaviourAnalysisAllDropdownsData);
    //   setGroupPlayingBehaviour(
    //     groupPlayingAllData.length === 0?[]: groupPlayingAllData.data.total_video_views_autoplayed
    //   );
    //   setGroupPlayingBehaviourLabel(
    //     groupPlayingAllData.length === 0?[]: groupPlayingAllData.label.total_video_views_autoplayed
    //   );
    // }
    else if (groupPlayingDropdownValue.value === "total_video_views") {

      setPlayingBehaviourAnalysis(playingBehaviourAnalysisAllDropdownsData?.playing_3s_behaviour_query ?? []);
      setGroupPlayingBehaviour(groupPlayingAllData?.data?.total_video_views ?? []);
      setGroupPlayingBehaviourLabel(groupPlayingAllData?.label?.total_video_views ?? []);

    } else if (groupPlayingDropdownValue.value === "total_video_10s_views") {

      setPlayingBehaviourAnalysis(playingBehaviourAnalysisAllDropdownsData?.playing_10s_behaviour_query ?? []);
      setGroupPlayingBehaviour(groupPlayingAllData?.data?.total_video_10s_views ?? []);
      setGroupPlayingBehaviourLabel(groupPlayingAllData?.label?.total_video_10s_views ?? []);

    } else if (groupPlayingDropdownValue.value === "total_video_30s_views") {

      setPlayingBehaviourAnalysis(playingBehaviourAnalysisAllDropdownsData?.playing_30s_behaviour_query ?? []);
      setGroupPlayingBehaviour(groupPlayingAllData?.data?.total_video_30s_views ?? []);
      setGroupPlayingBehaviourLabel(groupPlayingAllData?.label?.total_video_30s_views ?? []);

    } else {
      setPlayingBehaviourAnalysis([]);
      setGroupPlayingBehaviour([]);
      setGroupPlayingBehaviourLabel([]);
    }
  };

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchGroupPlayingHandler();
  }, [groupPlayingDropdownValue]);

  // Unique View Analysis
  const [uniqueViewAnalysis, setUniqueViewAnalysis] = useState([]);
  const [uniqueViewAnalysisLabel, setUniqueViewAnalysisLabel] = useState([]);
  const [isUniqueViewAnalysisLoading, setUniqueViewAnalysisIsLoading] =
    useState(false);
  const [isUniqueViewAnalysisLoaded, setUniqueViewAnalysisIsLoaded] =
    useState(false);
  const [uniqueViewAnalysisError, setUniqueViewAnalysisError] = useState(null);



  // DROPDOWN OPTIONS
  const UniqueViewAnalysisDropdownOptions = [
    { value: "total_video_views", label: "3 second Views" },
    { value: "total_video_10s_views", label: "10 second Views" },
    { value: "total_video_30s_views", label: "30 second Views" },
    { value: "total_video_complete_views", label: "Complete Views" },
  ];

  // Unique Viewer Analysis Page DROPDOWN STATE
  const [uniqueViewAnalysisDropdownValue, setUniqueViewAnalysisDropdownValue] =
    useState(UniqueViewAnalysisDropdownOptions[0]);
  const [uniqueViewAnalysisAllData, setUniqueViewAnalysisAllData] = useState(
    []
  );
  const [uniqueViewAnalysisAllLabel, setUniqueViewAnalysisAllLabel] = useState(
    []
  );

  // DROPDOWN OPTIONS SELECTION HANDLER
  const UniqueViewAnalysisDropdownSelectionHandler = (e) => {
    if (e === null) {
      setUniqueViewAnalysisDropdownValue("");
    } else {
      setUniqueViewAnalysisDropdownValue(e);
    }

    EventTracker({
      eventType: "dropdown_selected",
      module: pageModule,
      subModule: pageSubModule,
      platform: pagePlatform,
      tab: pagePrimaryTab,
      section: "Unique View Analysis",
      dropdownValue: e === null ? "Overall" : e.label,
      ...(BRAND_ID && { brands: BRAND_ID }),
      date: {
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate,
        previousStartDate: selectedDates.prevStartDate,
        previousEndDate: selectedDates.prevEndDate,
      }
    })

  };

  const fetchUniqueViewAnalysisDropdownHandler = async () => {
    if (uniqueViewAnalysisDropdownValue.value === "total_video_views") {
      setUniqueViewAnalysis(uniqueViewAnalysisAllData?.total_video_views ?? []);
      setUniqueViewAnalysisLabel(uniqueViewAnalysisAllLabel?.total_video_views ?? []);
    } else if (
      uniqueViewAnalysisDropdownValue.value === "total_video_10s_views"
    ) {
      setUniqueViewAnalysis(uniqueViewAnalysisAllData?.total_video_10s_views ?? []);
      setUniqueViewAnalysisLabel(uniqueViewAnalysisAllLabel?.total_video_10s_views ?? []);
    } else if (
      uniqueViewAnalysisDropdownValue.value === "total_video_30s_views"
    ) {
      setUniqueViewAnalysis(uniqueViewAnalysisAllData?.total_video_30s_views ?? []);
      setUniqueViewAnalysisLabel(uniqueViewAnalysisAllLabel?.total_video_30s_views ?? []);
    } else if (
      uniqueViewAnalysisDropdownValue.value === "total_video_complete_views"
    ) {
      setUniqueViewAnalysis(uniqueViewAnalysisAllData?.total_video_complete_views ?? []);
      setUniqueViewAnalysisLabel(uniqueViewAnalysisAllLabel?.total_video_complete_views ?? []);
    } else {
      setUniqueViewAnalysis([]);
      setUniqueViewAnalysisLabel([]);
    }
  };

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchUniqueViewAnalysisDropdownHandler();
  }, [uniqueViewAnalysisDropdownValue]);

  // Audience Activity Trend
  const [viewPublishTrend, setViewPublishTrend] = useState([]);
  const [viewPublishTrendXaxisLabel, setViewPublishTrendXaxisLabel] = useState(
    []
  );
  const [viewPublishTrendYaxisLabel, setViewPublishTrendYaxisLabel] = useState(
    []
  );
  const [isViewPublishTrendLoading, setViewPublishTrendIsLoading] =
    useState(false);
  const [isViewPublishTrendLoaded, setViewPublishTrendIsLoaded] =
    useState(false);
  const [viewPublishTrendError, setViewPublishTrendError] = useState(null);

  // VIEW PUBLISH TREND DROPDOWN
  const viewPublishDropdownOptions = [
    { value: "total_video_views", label: "3 second Views" },
    { value: "total_video_10s_views", label: "10 seconds" },
    { value: "total_video_30s_views", label: "30 seconds" },
    { value: "total_video_complete_views", label: "Complete Views" },
  ];

  const [viewPublishDropdownValue, setviewPublishDropdownValue] = useState(viewPublishDropdownOptions[0]);
  const [viewPublisTrendAllData, setviewPublisTrendAllData] = useState([]);

  const viewPublishSelectionHandler = (e) => {
    if (e === null) {
      setviewPublishDropdownValue("");
    } else {
      setviewPublishDropdownValue(e);
    }

    EventTracker({
      eventType: "dropdown_selected",
      module: pageModule,
      subModule: pageSubModule,
      platform: pagePlatform,
      tab: pagePrimaryTab,
      section: "View Publish Trend",
      dropdownValue: e === null ? "Overall" : e.label,
      ...(BRAND_ID && { brands: BRAND_ID }),
      date: {
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate,
        previousStartDate: selectedDates.prevStartDate,
        previousEndDate: selectedDates.prevEndDate,
      }
    })
  };

  const fetchViewPublishHanlder = async () => {
    if (viewPublishDropdownValue.value === "total_video_views") {
      setViewPublishTrend(viewPublisTrendAllData?.data?.total_video_views ?? "");
    } else if (viewPublishDropdownValue.value === "total_video_10s_views") {
      setViewPublishTrend(viewPublisTrendAllData.data.total_video_10s_views);
    } else if (viewPublishDropdownValue.value === "total_video_30s_views") {
      setViewPublishTrend(viewPublisTrendAllData.data.total_video_30s_views);
    } else if (
      viewPublishDropdownValue.value === "total_video_complete_views"
    ) {
      setViewPublishTrend(
        viewPublisTrendAllData.data.total_video_complete_views
      );
    } else {
      setViewPublishTrend([]);
    }
  };

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchViewPublishHanlder();
  }, [viewPublishDropdownValue]);

  // Video Rate Analysis
  const [videoRateAnalysis, setVideoRateAnalysis] = useState([]);
  const [videoRateAnalysisLabel, setVideoRateAnalysisLabel] = useState([]);

  const [isVideoRateAnalysisLoading, setVideoRateAnalysisIsLoading] =
    useState(false);
  const [isVideoRateAnalysisLoaded, setVideoRateAnalysisIsLoaded] =
    useState(false);
  const [videoRateAnalysisError, setVideoRateAnalysisError] = useState(null);

  // POSITIVE SENTIMENT
  const [positiveSentiment, setPositiveSentiment] = useState([]);
  const [isPositiveSentimentLoading, setPositiveSentimentIsLoading] =
    useState(false);
  const [isPositiveSentimentLoaded, setPositiveSentimentIsLoaded] =
    useState(false);
  const [positiveSentimenterror, setPositiveSentimentError] = useState(null);

  // const [videoSentimentCSVData, setVideoSentimentCSVData] = useState([]);

  // NEGATIVE SENTIMENT
  const [negativeSentiment, setNegativeSentiment] = useState([]);
  const [isNegativeSentimentLoading, setNegativeSentimentIsLoading] =
    useState(false);
  const [isNegativeSentimentLoaded, setNegativeSentimentIsLoaded] =
    useState(false);
  const [negativeSentimenterror, setNegativeSentimentError] = useState(null);

  // COMMENT SENTIMENT
  const [commentSentiment, setCommentSentiment] = useState([]);
  const [isCommentSentimentLoading, setCommentSentimentIsLoading] =
    useState(false);
  const [isCommentSentimentLoaded, setCommentSentimentIsLoaded] =
    useState(false);
  const [commentSentimenterror, setCommentSentimentError] = useState(null);

  // COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: "Views in Minutes", field: "views_in_minutes" },
    { name: "Change", field: "percentage_growth" },
  ];

  // CITY
  const [cityData, setCityData] = useState([]);
  const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  const [cityDataerror, setCityDataError] = useState(null);

  const CityTableDataHeaders = [
    { name: "City", field: "name" },
    { name: "Views in Minutes", field: "views_in_minutes" },
    { name: "Change", field: "percentage_growth" },
  ];

  // MALE COUNT
  const [maleCount, setMaleCount] = useState([]);
  const [maleCountLabels, setMaleCountLabels] = useState([]);
  const [totalMaleCount, setTotalMaleCount] = useState([]);
  const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
  const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
  const [maleCounterror, setMaleCountError] = useState(null);

  // FEMALE COUNT
  const [femaleCount, setFemaleCount] = useState([]);
  const [femaleCountLabels, setFemaleCountLabels] = useState([]);
  const [totalFemaleCount, setTotalFemaleCount] = useState([]);
  const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
  const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
  const [femaleCounterror, setFemaleCountError] = useState(null);

  // User Top Post
  const [topPosts, setTopPosts] = useState([]);
  const [topPostsLoading, settopPostsLoading] = useState(false);
  const [topPostsLoaded, setTopPostsloaded] = useState(false);
  const [topPostserror, setTopPostsError] = useState(null);

  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState();

  // WHEN TOP POST IMPRESSION DATE IS SELELCTED
  const CallAPIs = (hashtag) => {
    fetchMetricCardsHandler(hashtag);
    fetchVideoLengthAnalysisHandler(hashtag);
    fetchViewTrendAnalysisHandler(hashtag);
    fetchDailyTrendLineHandler(hashtag);
    fetchPlayingBehaviourAnalysisHandler(hashtag);
    fetchGroupPlayingBehaviourHandler(hashtag);
    fetchUniqueViewAnalysisHandler(hashtag);
    fetchVideoRateAnalysisHandler(hashtag);
    fetchCountryHandler(hashtag);
    fetchCityHandler(hashtag);
    fetchViewPublishTrendHandler(hashtag);
    fetchAudienceDistributionhHandler(hashtag);
    fetchPositiveSentimentHandler(hashtag);
    fetchNegativeSentimentHandler(hashtag);
    fetchCommentSentimentHandler(hashtag);
    fetchTopPostsHandler(hashtag);

  }


  useEffect(() => {
    setSelectedHashtag('')
    CallAPIs()
    fetchHashtagListHandler();
  }, [BRAND_ID, selectedDates, videoDurationType]);


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag == "") {
      setSelectedHashtag(badge.hashtag);
      CallAPIs(badge.hashtag)
    } else if (selectedHashtag == badge.hashtag) {
      setSelectedHashtag("");
      CallAPIs()
    } else {
      setSelectedHashtag(badge.hashtag);
      CallAPIs(badge.hashtag)
    }
    IAEvent_Hashtag_Visit("Marketing Intelligence", "Video", "Facebook", null, "Overall", badge.hashtag)

  };

  // TO FETCH TRENDING HASHTAG LIST FROM API
  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/hashtag_list/",
        config
      );

      setHashtagList(res?.data?.metrics ?? []);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  let topTrendingHashtagsList = (
    <Wrapper>
      <Loader loaderType="hashLoader" />
    </Wrapper>
  );

  if (isHashtagListLoaded && !isHashtagListLoading) {
    if (NoDataAvailableChecker(filteredHashtagList)) {
      topTrendingHashtagsList = (
        <Wrapper>
          <NoDataAvailableLoader chartType="hashChartType" />
        </Wrapper>
      );
    } else {
      topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
        <Wrapper key={index}>
          <ErrorBoundary chartType="hashChartType">
            <div className="badge_wrapper">
              <button
                type="button"
                className={
                  selectedHashtag === badge.hashtag
                    ? "button_badge active"
                    : "button_badge"
                }
                onClick={() => handleEdit(badge)}
              >
                {badge.hashtag}
              </button>
            </div>
          </ErrorBoundary>
        </Wrapper>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <Wrapper>
        <ServerErrorsLoader chartType="hashChartType" error={hashtagListerror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = <Wrapper> <Loader loaderType="hashLoader" /></Wrapper>;
  }

  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader =
      ` : ` + (selectedHashtag !== undefined ? selectedHashtag : "");
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Metric Card
  const fetchMetricCardsHandler = async (hashtag) => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/metrics-card/",
        config
      );
      setMetricCards(res?.data?.metrics?.data ?? []);

      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper >
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary {selectedHashtagSectionHeader && " : " + selectedHashtagSectionHeader}
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </div>
      </Wrapper>
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary" + selectedHashtagSectionHeader}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="video"
                    platform="facebook"
                    section="overall"
                    key={index}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              error={metricCardserror}
              chartType="cardChartType"
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection =
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper >
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video Length Analysis
  const fetchVideoLengthAnalysisHandler = async (hashtag) => {
    setVideoLengthAnalysisIsLoading(true);
    setVideoLengthAnalysisIsLoaded(false);
    setVideoLengthAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/video-lengthwise-analysis/",
        config
      );

      setVideoLengthAnalysis(res?.data?.data ?? []);

      setVideoLengthAnalysisLabel(res?.data?.label ?? []);


      setVideoLengthAnalysisIsLoading(false);
      setVideoLengthAnalysisIsLoaded(true);
      setVideoLengthAnalysisError(null);
    } catch (error) {
      setVideoLengthAnalysis([]);
      setVideoLengthAnalysisLabel([]);
      setVideoLengthAnalysisIsLoading(false);
      setVideoLengthAnalysisIsLoaded(false);
      setVideoLengthAnalysisError(error.code);
    }
  };

  let videoLengthAnalysisChart = (
    <Wrapper> <Loader loaderType="pieChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (isVideoLengthAnalysisLoaded && !isVideoLengthAnalysisLoading) {
    if (
      NoDataAvailableChecker(videoLengthAnalysis) ||
      NoDataAvailableChecker(videoLengthAnalysisLabel)
    ) {
      videoLengthAnalysisChart = (
        <Wrapper> <NoDataAvailableLoader chartType="pieChartType" /></Wrapper>
      );
    } else {
      videoLengthAnalysisChart = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <PieofaPieChart
              chartClass="section_card_chart"
              chartData={videoLengthAnalysis}
              chartId="videoLengthAnalysisChart"
              chartLabels={videoLengthAnalysisLabel}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoLengthAnalysiserror) {
    videoLengthAnalysisChart = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="pieChartType"
              error={videoLengthAnalysiserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVideoLengthAnalysisLoading) {
    videoLengthAnalysisChart = <Wrapper> <Loader loaderType="pieChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // View Trend Analysis

  const fetchViewTrendAnalysisHandler = async (hashtag) => {
    setViewTrendAnalysisIsLoading(true);
    setViewTrendAnalysisIsLoaded(false);
    setViewTrendAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/view-trend-analysis/",
        config
      );

      setViewTrendAnalysis(res?.data ?? []);
      setViewTrendAnalysisIsLoading(false);
      setViewTrendAnalysisIsLoaded(true);
      setViewTrendAnalysisError(null);
    } catch (error) {
      setViewTrendAnalysis([]);
      setViewTrendAnalysisIsLoading(false);
      setViewTrendAnalysisIsLoaded(false);
      setViewTrendAnalysisError(error.code);
    }
  };

  let viewTrendAnalysisSection = (
    <Wrapper>  <Loader loaderType="solidGaugeChartLoader" /></Wrapper>
  );
  // IF DATA IS LOADED
  if (isViewTrendAnalysisLoaded && !isViewTrendAnalysisLoading) {
    if (NoDataAvailableChecker(viewTrendAnalysis)) {
      viewTrendAnalysisSection = (
        <Wrapper> <NoDataAvailableLoader chartType="solidGaugeChartType" /></Wrapper>
      );
    } else {
      viewTrendAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="solidGaugeChartType">
            <SolidGauge
              chartClass="section_card_chart"
              chartData={viewTrendAnalysis}
              chartId="viewTrendAnalysis"
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (viewTrendAnalysisError) {
    viewTrendAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="solidGaugeChartType"
          error={viewTrendAnalysisError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isViewTrendAnalysisLoading) {
    viewTrendAnalysisSection = <Wrapper> <Loader loaderType="solidGaugeChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // DAILY TREND LINE

  let defaultdailyTrendLineDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultdailyTrendLineDisabledvalues = []
  } else {
    defaultdailyTrendLineDisabledvalues = []
  }

  const [isDailyTrendLineOpen, setIsDailyTrendLineOpen] = useState(false)
  const [dailyTrendLineDisabledvalues, setDailyTrendLineDisabledvalues] = useState(defaultdailyTrendLineDisabledvalues)

  const fetchDailyTrendLineHandler = async (hashtag) => {
    setDailyTrendLineIsLoading(true);
    setDailyTrendLineIsLoaded(false);
    setDailyTrendLineError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        brand_id: BRAND_ID,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/daily-trend_line/",
        config
      );
      setDailyTrendLine(res?.data?.data ?? []);
      setDailyTrendLineLabel(res?.data?.dimension ?? []);

      setDailyTrendLineIsLoading(false);
      setDailyTrendLineIsLoaded(true);
      setDailyTrendLineError(null);
    } catch (error) {
      setDailyTrendLine([]);
      setDailyTrendLineLabel([]);
      setDailyTrendLineIsLoading(false);
      setDailyTrendLineIsLoaded(false);
      setDailyTrendLineError(error.code);
    }
  };

  let dailyTrendLineSection = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>
  let dailyTrendLineSection2 = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>

  // IF DATA IS LOADED
  if (isDailyTrendLineLoaded && !isDailyTrendLineLoading) {
    if (
      NoDataAvailableChecker(dailyTrendLine) ||
      NoDataAvailableChecker(dailyTrendLineLabel)
    ) {
      dailyTrendLineSection = (
        <Wrapper>  <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );

      dailyTrendLineSection2 = (
        <Wrapper>  <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
    } else {

      if (isMultiLineCluster) {
        dailyTrendLineSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={dailyTrendLine}
                chartId="dailyTrendLine"
                chartLabels={dailyTrendLineLabel}
                onBulletsClick={() => { }}
                tooltipWidth="auto"
                disabledLegendArray={dailyTrendLineDisabledvalues}
                setDisabledValues={setDailyTrendLineDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        dailyTrendLineSection2 = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={dailyTrendLine}
                chartId="dailyTrendLine_2"
                chartLabels={dailyTrendLineLabel}
                onBulletsClick={() => { }}
                tooltipWidth="auto"
                disabledLegendArray={dailyTrendLineDisabledvalues}
                setDisabledValues={setDailyTrendLineDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      } else {
        dailyTrendLineSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={dailyTrendLine}
                chartId="dailyTrendLine"
                chartLabels={dailyTrendLineLabel}
                onBulletsClick={() => { }}
                tooltipWidth="auto"
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }


    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (dailyTrendLineError) {
    dailyTrendLineSection = <Wrapper> <ServerErrorsLoader error={dailyTrendLineError} /></Wrapper>;
    dailyTrendLineSection2 = <Wrapper> <ServerErrorsLoader error={dailyTrendLineError} /></Wrapper>;

  }

  // IF DATA LOADING
  if (isDailyTrendLineLoading) {
    dailyTrendLineSection = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;
    dailyTrendLineSection2 = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;

  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Playing Behaviour Analysis Donut

  const fetchPlayingBehaviourAnalysisHandler = async (hashtag) => {
    setPlayingBehaviourAnalysisIsLoading(true);
    setPlayingBehaviourAnalysisIsLoaded(false);
    setPlayingBehaviourAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/playing_behaviour-analysis/",
        config
      );
      setPlayingBehaviourAnalysis(res?.data?.playing_3s_behaviour_query ?? []);
      setPlayingBehaviourAnalysisAllDropdownsData(res?.data ?? []);

      setPlayingBehaviourAnalysisIsLoading(false);
      setPlayingBehaviourAnalysisIsLoaded(true);
      setPlayingBehaviourAnalysisError(null);
    } catch (error) {
      setPlayingBehaviourAnalysis([]);
      setPlayingBehaviourAnalysisIsLoading(false);
      setPlayingBehaviourAnalysisIsLoaded(false);
      setPlayingBehaviourAnalysisError(error.code);
    }
  };

  let playingBehaviourAnalysisSection = (
    <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (isPlayingBehaviourAnalysisLoaded && !isPlayingBehaviourAnalysisLoading) {
    if (NoDataAvailableChecker(playingBehaviourAnalysis)) {
      playingBehaviourAnalysisSection = (
        <Wrapper> <NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
      );
    } else {
      playingBehaviourAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={playingBehaviourAnalysis}
              chartId="playingBehaviourAnalys"
              showLabels
              showLegend
              showVerticalLabels
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (playingBehaviourAnalysisError) {
    playingBehaviourAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={playingBehaviourAnalysisError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPlayingBehaviourAnalysisLoading) {
    playingBehaviourAnalysisSection = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Group Playing Behaviour Barchart

  const fetchGroupPlayingBehaviourHandler = async (hashtag) => {
    setGroupPlayingBehaviourIsLoading(true);
    setGroupPlayingBehaviourIsLoaded(false);
    setGroupPlayingBehaviourError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        brand_id: BRAND_ID,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/group_behaviour-chart/",
        config
      );
      setGroupPlayingAllData(res?.data ?? []);
      setGroupPlayingBehaviour(res?.data?.data?.total_video_views ?? []);
      setGroupPlayingBehaviourLabel(res?.data?.label?.total_video_views ?? []);

      setGroupPlayingBehaviourIsLoading(false);
      setGroupPlayingBehaviourIsLoaded(true);
      setGroupPlayingBehaviourError(null);
    } catch (error) {
      setGroupPlayingAllData([]);
      setGroupPlayingBehaviour([]);
      setGroupPlayingBehaviourLabel([]);
      setGroupPlayingBehaviourIsLoading(false);
      setGroupPlayingBehaviourIsLoaded(false);
      setGroupPlayingBehaviourError(error.code);
    }
  };

  let groupPlayingBehaviourSection = (
    <Wrapper>  <Loader loaderType="barChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (isGroupPlayingBehaviourLoaded && !isGroupPlayingBehaviourLoading) {
    if (
      NoDataAvailableChecker(groupPlayingBehaviour) ||
      NoDataAvailableChecker(groupPlayingBehaviourLabel)
    ) {
      groupPlayingBehaviourSection = (
        <Wrapper>  <NoDataAvailableLoader chartType="barChartType" /></Wrapper>
      );
    } else {
      groupPlayingBehaviourSection = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartClass="section_card_chart"
              chartData={groupPlayingBehaviour}
              chartId="groupPlayingBehaviourChart"
              chartLabels={groupPlayingBehaviourLabel}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (groupPlayingBehaviourError) {
    groupPlayingBehaviourSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="barChartType"
          error={groupPlayingBehaviourError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isGroupPlayingBehaviourLoading) {
    groupPlayingBehaviourSection = <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Unique View Analysis
  const [isUniqueViewAnalysisOpen, setIsUniqueViewAnalysisOpen] =
    useState(false);

  let defaultUniqueViewAnalysisDisabledValues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultUniqueViewAnalysisDisabledValues = [];
  } else {
    defaultUniqueViewAnalysisDisabledValues = ["Sales Volume"];
  }

  const [
    uniqueViewAnalysisDisabledValues,
    setUniqueViewAnalysisDisabledValues,
  ] = useState(defaultUniqueViewAnalysisDisabledValues);


  const fetchUniqueViewAnalysisHandler = async (hashtag) => {
    setUniqueViewAnalysisIsLoading(true);
    setUniqueViewAnalysisIsLoaded(false);
    setUniqueViewAnalysisError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        brand_id: BRAND_ID,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/unique_viewer_analysis/",
        config
      );
      setUniqueViewAnalysis(res?.data?.data?.total_video_views ?? []);


      setUniqueViewAnalysisLabel(res?.data?.label?.total_video_views ?? []);

      setUniqueViewAnalysisAllData(res?.data?.data ?? []);
      setUniqueViewAnalysisAllLabel(res?.data?.label ?? []);

      setUniqueViewAnalysisIsLoading(false);
      setUniqueViewAnalysisIsLoaded(true);
      setUniqueViewAnalysisError(null);
    } catch (error) {
      setUniqueViewAnalysis([]);
      setUniqueViewAnalysisLabel([]);
      setUniqueViewAnalysisAllData([]);
      setUniqueViewAnalysisAllLabel([]);
      setUniqueViewAnalysisIsLoading(false);
      setUniqueViewAnalysisIsLoaded(false);
      setUniqueViewAnalysisError(error.code);
    }
  };

  let uniqueViewAnalysisSection = (
    <Wrapper>
      <Loader loaderType="combinedBarLineChartLoader" />
    </Wrapper>
  );
  let uniqueViewAnalysisSection2 = (
    <Wrapper>
      <Loader loaderType="combinedBarLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED

  if (isUniqueViewAnalysisLoaded && !isUniqueViewAnalysisLoading) {
    if (
      NoDataAvailableChecker(uniqueViewAnalysis) ||
      NoDataAvailableChecker(uniqueViewAnalysisLabel)
    ) {
      uniqueViewAnalysisSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="combinedBarLineChartType" />
        </Wrapper>
      );
      uniqueViewAnalysisSection2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType="combinedBarLineChartType" />
        </Wrapper>
      );
    } else {
      if (isMultiLineCluster) {
        uniqueViewAnalysisSection = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <CombinedBarLineChart
                chartClass="section_card_chart"
                chartId="uniqueViewAnalysis_1"
                chartData={uniqueViewAnalysis}
                chartLabels={uniqueViewAnalysisLabel}
                showLegend={true}
                disabledLegendArray={uniqueViewAnalysisDisabledValues}
                setDisabledValues={setUniqueViewAnalysisDisabledValues}
              />
            </ErrorBoundary>
          </Wrapper>
        );
        uniqueViewAnalysisSection2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedCombinedBarLineMultipleAxesChart
                chartClass="section_card_chart"
                chartId="uniqueViewAnalysis_2"
                chartData={uniqueViewAnalysis}
                chartLabels={uniqueViewAnalysisLabel}
                showLegend={true}
                disabledLegendArray={uniqueViewAnalysisDisabledValues}
                setDisabledValues={setUniqueViewAnalysisDisabledValues}
                showLabels={true}
                showSecondY={false}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      } else {
        uniqueViewAnalysisSection = (
          <Wrapper>
            <ErrorBoundary chartType="combinedBarLineChartType">
              <CombinedBarLineMultipleAxesChart
                chartClass="section_card_chart"
                chartId="uniqueViewAnalysis"
                chartData={uniqueViewAnalysis}
                chartLabels={uniqueViewAnalysisLabel}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (uniqueViewAnalysisError) {
    uniqueViewAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="combinedBarLineChartType"
          error={uniqueViewAnalysisError}
        />
      </Wrapper>
    );
    uniqueViewAnalysisSection2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="combinedBarLineChartType"
          error={uniqueViewAnalysisError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isUniqueViewAnalysisLoading) {
    uniqueViewAnalysisSection = (
      <Wrapper>
        <Loader loaderType="combinedBarLineChartLoader" />
      </Wrapper>
    );
    uniqueViewAnalysisSection2 = (
      <Wrapper>
        <Loader loaderType="combinedBarLineChartLoader" />
      </Wrapper>
    );
  }
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video Rate Analysis

  const [isVideoRateOpen, setIsVideoRateOpen] = useState(false)

  let defaultVideoRateDisabledValues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultVideoRateDisabledValues = []
  } else {
    defaultVideoRateDisabledValues = ["Sales Volume"]
  }

  const [videoRateDisabledValues, setVideoRateDisabledValues] = useState(defaultVideoRateDisabledValues)

  const fetchVideoRateAnalysisHandler = async (hashtag) => {
    setVideoRateAnalysisIsLoading(true);
    setVideoRateAnalysisIsLoaded(false);
    setVideoRateAnalysisError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        brand_id: BRAND_ID,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/rate-analysis/",
        config
      );
      setVideoRateAnalysis(res?.data?.data ?? []);

      setVideoRateAnalysisLabel(res?.data?.Dimensions ?? []);

      setVideoRateAnalysisIsLoading(false);
      setVideoRateAnalysisIsLoaded(true);
      setVideoRateAnalysisError(null);
    } catch (error) {
      setVideoRateAnalysis([]);
      setVideoRateAnalysisLabel([]);
      setVideoRateAnalysisIsLoading(false);
      setVideoRateAnalysisIsLoaded(false);
      setVideoRateAnalysisError(error.code);
    }
  };


  let videoRateAnalysisSection = (
    <Wrapper>
      <Loader loaderType="combinedBarLineChartLoader" />
    </Wrapper>
  );

  let videoRateAnalysisSection2 =
    <Wrapper>
      <Loader loaderType="combinedBarLineChartLoader" />
    </Wrapper>

  // IF DATA IS LOADED
  if (isVideoRateAnalysisLoaded && !isVideoRateAnalysisLoading) {
    if (
      NoDataAvailableChecker(videoRateAnalysis) ||
      NoDataAvailableChecker(videoRateAnalysisLabel)
    ) {
      videoRateAnalysisSection =
        <Wrapper>
          <NoDataAvailableLoader chartType="combinedBarLineChartType" />
        </Wrapper>
      videoRateAnalysisSection2 =
        <Wrapper>
          <NoDataAvailableLoader chartType="combinedBarLineChartType" />
        </Wrapper>
    } else {
      if (isMultiLineCluster) {
        videoRateAnalysisSection = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <CombinedBarLineChart
                chartClass="section_card_chart"
                chartId="videoRateAnalysis_1"
                chartData={videoRateAnalysis}
                chartLabels={videoRateAnalysisLabel}
                showLegend={true}
                disabledLegendArray={videoRateDisabledValues}
                setDisabledValues={setVideoRateDisabledValues}

              />
            </ErrorBoundary>
          </Wrapper>
        );
        videoRateAnalysisSection2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedCombinedBarLineMultipleAxesChart
                chartClass="section_card_chart"
                chartId="videoRateAnalysis_2"
                chartData={videoRateAnalysis}
                chartLabels={videoRateAnalysisLabel}
                showLegend={true}
                disabledLegendArray={videoRateDisabledValues}
                setDisabledValues={setVideoRateDisabledValues}
                showLabels={true}
                showSecondY={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
      else {

        videoRateAnalysisSection = (
          <Wrapper>
            <ErrorBoundary chartType="combinedBarLineChartType" >
              <CombinedBarLineMultipleAxesChart
                chartClass="section_card_chart"
                chartData={videoRateAnalysis}
                chartId="videoRateAnalysis"
                chartLabels={videoRateAnalysisLabel}
              />
            </ErrorBoundary>
          </Wrapper>
        );

      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoRateAnalysisError) {
    videoRateAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader chartType="combinedBarLineChartType" error={videoRateAnalysisError} />
      </Wrapper>
    );
    videoRateAnalysisSection2 = (
      <Wrapper>
        <ServerErrorsLoader chartType="combinedBarLineChartType" error={videoRateAnalysisError} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (videoRateAnalysisError) {
    videoRateAnalysisSection =
      <Wrapper>
        <Loader loaderType="combinedBarLineChartLoader" />
      </Wrapper>

    videoRateAnalysisSection2 =
      <Wrapper>
        <Loader loaderType="combinedBarLineChartLoader" />
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async (hashtag) => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/audience-demographics-country/",
        config
      );

      setCountryData(res?.data ?? []);

      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryData([]);

      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let countryMap = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card ">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By Country</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(CountryTableDataHeaders)
    ) {
      countryMap = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card ">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By Country</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      countryMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By Country"}
              isHalfSection={true}
              isCountryFlagVisible={true}
              tableHeader={CountryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CITY MAP

  const fetchCityHandler = async (hashtag) => {
    setCityDataIsLoading(true);
    setCityDataIsLoaded(false);
    setCityDataError(null);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/audience-demographics-region/",
        config
      );

      setCityData(res?.data ?? []);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(true);
      setCityDataError(null);
    } catch (error) {
      setCityData([]);

      setCityDataIsLoading(false);
      setCityDataIsLoaded(false);
      setCityDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let cityMap = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card ">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By City</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (
      NoDataAvailableChecker(cityData) ||
      NoDataAvailableChecker(CityTableDataHeaders)
    ) {
      cityMap = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card ">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By City</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      cityMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By City"}
              isHalfSection={true}
              isDynamicFlagVisible={true}
              tableHeader={CityTableDataHeaders}
              tableData={cityData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search City"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    cityMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    cityMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>);
  }

  //
  //
  //
  //
  // VIEW TREND ANALYSIS

  const fetchViewPublishTrendHandler = async (hashtag) => {
    setViewPublishTrendIsLoading(true);
    setViewPublishTrendIsLoaded(false);
    setViewPublishTrendError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        brand_id: BRAND_ID,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/video-activity-trend/",
        config
      );

      setViewPublishTrend(res?.data?.data?.total_video_views ?? []);
      setViewPublishTrendXaxisLabel(res?.data?.xaxis_labels ?? []);
      setViewPublishTrendYaxisLabel(res?.data?.yaxis_labels ?? []);

      setviewPublisTrendAllData(res?.data ?? []);
      setViewPublishTrendIsLoading(false);
      setViewPublishTrendIsLoaded(true);
      setViewPublishTrendError(null);
    } catch (error) {
      setViewPublishTrend([]);
      setViewPublishTrendXaxisLabel([]);
      setViewPublishTrendYaxisLabel([]);
      setviewPublisTrendAllData([]);
      setViewPublishTrendIsLoading(false);
      setViewPublishTrendIsLoaded(false);
      setViewPublishTrendError(error.code);
    }
  };

  let viewPublishTrendSection = (
    <Wrapper> <Loader loaderType="heatChartLoader" /></Wrapper>
  );
  // IF DATA IS LOADED
  if (isViewPublishTrendLoaded && !isViewPublishTrendLoading) {
    if (
      NoDataAvailableChecker(viewPublishTrend) ||
      NoDataAvailableChecker(viewPublishTrendXaxisLabel) ||
      NoDataAvailableChecker(viewPublishTrendYaxisLabel)
    ) {
      viewPublishTrendSection = (
        <Wrapper>  <NoDataAvailableLoader chartType="heatChartType" /></Wrapper>
      );
    } else {
      viewPublishTrendSection = (
        <Wrapper>
          <ErrorBoundary chartType="heatChartType">
            <HeatMap
              chartClass="section_card_chart"
              chartId="heatmapChart"
              chartXaxisLabels={viewPublishTrendXaxisLabel}
              chartYaxisLabels={viewPublishTrendYaxisLabel}
              chartData={viewPublishTrend}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (viewPublishTrendError) {
    viewPublishTrendSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="heatChartType"
              error={viewPublishTrendError}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isViewPublishTrendLoading) {
    viewPublishTrendSection = <Wrapper> <Loader loaderType="heatChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: MALE / FEMALE

  // TO FETCH MALE / FEMALE DATA FROM API
  const fetchAudienceDistributionhHandler = async (hashtag) => {
    setMaleCountIsLoading(true);
    setMaleCountIsLoaded(false);
    setMaleCountError(null);

    setFemaleCountIsLoading(true);
    setFemaleCountIsLoaded(false);
    setFemaleCountError(null);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/audience-demographics/",
        config
      );
      setMaleCount(res?.data?.male_list?.data ?? []);
      setMaleCountLabels(res?.data?.dimension ?? []);
      setTotalMaleCount(res?.data?.male_count ?? []);

      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(true);
      setMaleCountError(null);

      setFemaleCount(res?.data?.female_list?.data ?? []);
      setFemaleCountLabels(res?.data?.dimension ?? []);
      setTotalFemaleCount(res?.data?.female_count ?? []);

      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(true);
      setFemaleCountError(null);
    } catch (error) {
      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(false);
      setMaleCountError(error.code);
      setMaleCount([]);
      setMaleCountLabels([]);
      setTotalMaleCount([]);

      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(false);
      setFemaleCountError(error.code);
      setFemaleCount([]);
      setFemaleCountLabels([]);
      setTotalFemaleCount([]);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let maleAudienceDistribution = (
    <Wrapper> <NoDataAvailableLoader chartType="barChartType" /></Wrapper>
  );
  let maleAudienceDistributionIcon = (
    <Wrapper> <NoDataAvailableLoader chartType="genderChartType" /></Wrapper>
  );

  let totalMaleCountPercentage = 0;
  if (isNaN(totalMaleCount / (totalMaleCount + totalFemaleCount)) === true) {
    totalMaleCountPercentage = 0;
  } else {
    totalMaleCountPercentage =
      totalMaleCount / (totalMaleCount + totalFemaleCount);
  }

  let totalFemaleCountPercentage = 0;
  if (isNaN(totalFemaleCount / (totalMaleCount + totalFemaleCount)) === true) {
    totalFemaleCountPercentage = 0;
  } else {
    totalFemaleCountPercentage =
      totalFemaleCount / (totalMaleCount + totalFemaleCount);
  }

  // IF DATA IS AVAILABLE
  if (isMaleCountLoaded && !isMaleCountLoading) {
    if (
      NoDataAvailableChecker(maleCount) ||
      NoDataAvailableChecker(maleCountLabels)
    ) {
      maleAudienceDistribution = (
        <Wrapper> <NoDataAvailableLoader chartType="barChartType" /></Wrapper>
      );
    } else {
      maleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_male"
              chartClass="section_card_chart"
              chartData={maleCount}
              chartLabels={maleCountLabels}
              showLegend={false}
              /*  showLabels={true}
             showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
    if (NoDataAvailableChecker(totalMaleCountPercentage)) {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <ErrorBoundary chartType="genderChartType">
            <div className="section_icon flex column">
              <MaleIcon percentage={totalMaleCountPercentage * 100} />
            </div>
            <div className="p_20">
              <h3>
                {+Math.abs(totalMaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </h3>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (maleCounterror) {
    maleAudienceDistribution = (
      <Wrapper> <ServerErrorsLoader chartType="barChartType" error={maleCounterror} /></Wrapper>
    );
    maleAudienceDistributionIcon = (
      <Wrapper>  <ServerErrorsLoader chartType="genderChartType" error={maleCounterror} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMaleCountLoading) {
    maleAudienceDistribution = <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>;
    maleAudienceDistributionIcon = <Wrapper> <Loader loaderType="genderLoader" /></Wrapper>;
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let femaleAudienceDistribution = (
    <Wrapper> <NoDataAvailableLoader chartType="barChartType" /></Wrapper>
  );
  let femaleAudienceDistributionIocn = (
    <Wrapper>  <NoDataAvailableLoader chartType="genderChartType" /></Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isFemaleCountLoaded && !isFemaleCountLoading) {
    if (
      NoDataAvailableChecker(femaleCount) ||
      NoDataAvailableChecker(femaleCountLabels)
    ) {
      femaleAudienceDistribution = (
        <Wrapper>  <NoDataAvailableLoader chartType="barChartType" /></Wrapper>
      );
    } else {
      femaleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_female"
              chartClass="section_card_chart"
              chartData={femaleCount}
              chartLabels={femaleCountLabels}
              showLegend={false}
              /*  showLabels={true}
              showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
    if (NoDataAvailableChecker(totalFemaleCountPercentage)) {
      femaleAudienceDistributionIocn = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      femaleAudienceDistributionIocn = (
        <Wrapper>
          <ErrorBoundary chartType="genderChartType">
            <div className="section_icon flex column">
              <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
            </div>
            <div className="p_20">
              <h3>
                {+Math.abs(totalFemaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </h3>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (femaleCounterror) {
    femaleAudienceDistribution = (
      <Wrapper>   <ServerErrorsLoader chartType="barChartType" error={femaleCounterror} /></Wrapper>
    );
    femaleAudienceDistributionIocn = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={femaleCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isFemaleCountLoading) {
    femaleAudienceDistribution = <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>;
    femaleAudienceDistributionIocn = <Wrapper> <Loader loaderType="genderLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COMMENT SENTIMENT

  const fetchCommentSentimentHandler = async (hashtag) => {
    setCommentSentimentIsLoading(true);
    setCommentSentimentIsLoaded(false);
    setCommentSentimentError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/comment-sentiment/",
        config
      );
      setCommentSentiment(res?.data ?? []);
      setCommentSentimentIsLoading(false);
      setCommentSentimentIsLoaded(true);
      setCommentSentimentError(null);
    } catch (error) {
      setCommentSentiment([]);
      setCommentSentimentIsLoading(false);
      setCommentSentimentIsLoaded(false);
      setCommentSentimentError(error.code);
    }
  };

  let commentSentimentSection = (
    <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>
  );

  let commentSentimentTable = (
    <Wrapper>  <Loader loaderType="smallTableLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (isCommentSentimentLoaded && !isCommentSentimentLoading) {
    if (NoDataAvailableChecker(commentSentiment)) {
      commentSentimentSection = (
        <Wrapper>  <NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
      );
      commentSentimentTable = (
        <Wrapper>   <NoDataAvailableLoader chartType="tableChartType" /></Wrapper>
      );
    } else {
      commentSentimentSection = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={commentSentiment}
              chartId="commentSentiment"
              showLabels
              showLegend
              showVerticalLabels
            />
          </ErrorBoundary>
        </Wrapper>
      );

      commentSentimentTable = (
        <Wrapper>
          <ErrorBoundary>
            <div className="chart_details_wrapper">
              <div className="main_wrapper">
                <div className="body">
                  {commentSentiment && commentSentiment.map(
                    (details, postImpressionIndex) => (
                      <Wrapper key={postImpressionIndex}>
                        <div className="row">
                          <div className="title_wrapper">
                            {details.label}
                          </div>
                          <div className="value_wrapper">
                            <ErrorBoundary>
                              <NumberFormatter number={details.value} />
                            </ErrorBoundary>
                          </div>
                        </div>
                      </Wrapper>
                    ))}
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (commentSentimenterror) {
    commentSentimentSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={commentSentimenterror}
        />
      </Wrapper>
    );
    commentSentimentTable = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={commentSentimenterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCommentSentimentLoading) {
    commentSentimentSection = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
    commentSentimentTable = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // POSITIVE SENTIMENT

  const fetchPositiveSentimentHandler = async (hashtag) => {
    setPositiveSentimentIsLoading(true);
    setPositiveSentimentIsLoaded(false);
    setPositiveSentimentError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/post-sentiment/",
        config
      );

      setPositiveSentiment(res?.data?.positive_sentiment_list ?? []);

      setPositiveSentimentIsLoading(false);
      setPositiveSentimentIsLoaded(true);
      setPositiveSentimentError(null);
    } catch (error) {
      setPositiveSentiment([]);
      setPositiveSentimentIsLoading(false);
      setPositiveSentimentIsLoaded(false);
      setPositiveSentimentError(error.code);
    }
  };

  let positiveSentimentSection = (
    <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
  );

  let positiveSentimentTable = (
    <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (isPositiveSentimentLoaded && !isPositiveSentimentLoading) {
    if (NoDataAvailableChecker(positiveSentiment)) {
      positiveSentimentSection = (
        <Wrapper>  <NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
      );
      positiveSentimentTable = (
        <Wrapper>  <NoDataAvailableLoader chartType="tableChartType" /></Wrapper>
      );
    } else {
      positiveSentimentSection = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={positiveSentiment}
              chartId="positiveSentiment"
              showLabels
              showLegend
              showVerticalLabels
            />
          </ErrorBoundary>
        </Wrapper>
      );

      // positiveSentimentTable = <NoDataAvailableLoader />;
      positiveSentimentTable = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <div className="chart_details_wrapper">
              <div className="main_wrapper">
                <div className="body">
                  {positiveSentiment && positiveSentiment.map(
                    (details, postImpressionIndex) => (
                      <Wrapper key={postImpressionIndex}>
                        <div className="row">
                          <div className="title_wrapper">
                            {details.label}
                          </div>
                          <div className="value_wrapper">
                            <ErrorBoundary>
                              <NumberFormatter number={details.value} />
                            </ErrorBoundary>
                          </div>
                        </div>
                      </Wrapper>
                    ))}
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (positiveSentimenterror) {
    positiveSentimentSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={positiveSentimenterror}
        />
      </Wrapper>
    );
    positiveSentimentTable = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={positiveSentimenterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPositiveSentimentLoading) {
    positiveSentimentSection = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
    positiveSentimentTable = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // NEGATIVE SENTIMENT

  const fetchNegativeSentimentHandler = async (hashtag) => {
    setNegativeSentimentIsLoading(true);
    setNegativeSentimentIsLoaded(false);
    setNegativeSentimentError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/post-sentiment/",
        config
      );

      setNegativeSentiment(res?.data?.negative_sentiment_list ?? []);
      setNegativeSentimentIsLoading(false);
      setNegativeSentimentIsLoaded(true);
      setNegativeSentimentError(null);
    } catch (error) {
      setNegativeSentiment([]);
      setNegativeSentimentIsLoading(false);
      setNegativeSentimentIsLoaded(false);
      setNegativeSentimentError(error.code);
    }
  };

  let negativeSentimentSection = (
    <Wrapper>  <Loader loaderType="donutChartLoader" /></Wrapper>
  );

  let negativeSentimentTable = (
    <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (isNegativeSentimentLoaded && !isNegativeSentimentLoading) {
    if (NoDataAvailableChecker(negativeSentiment)) {
      negativeSentimentSection = (
        <Wrapper> <NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
      );
      negativeSentimentTable = (
        <Wrapper>  <NoDataAvailableLoader chartType="tableChartType" /></Wrapper>
      );
    } else {
      negativeSentimentSection = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={negativeSentiment}
              chartId="negativeSentiment"
              showLabels
              showLegend
              showVerticalLabels
            />
          </ErrorBoundary>
        </Wrapper>
      );

      // negativeSentimentTable = <NoDataAvailableLoader />;
      negativeSentimentTable = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <div className="chart_details_wrapper">
              <div className="main_wrapper">
                <div className="body">
                  {negativeSentiment && negativeSentiment.map(
                    (details, postImpressionIndex) => (
                      <Wrapper key={postImpressionIndex}>
                        <div className="row">
                          <div className="title_wrapper">
                            {details.label}
                          </div>
                          <div className="value_wrapper">
                            <ErrorBoundary>
                              <NumberFormatter number={details.value} />
                            </ErrorBoundary>
                          </div>
                        </div>
                      </Wrapper>
                    ))}
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (negativeSentimenterror) {
    negativeSentimentSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="donutChartType"
              error={negativeSentimenterror}
            />
          </div>
        </div>
      </Wrapper>
    );
    negativeSentimentTable = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={negativeSentimenterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isNegativeSentimentLoading) {
    negativeSentimentSection = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
    negativeSentimentTable = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>;
  }

  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  // TOP POST
  const fetchTopPostsHandler = async (hashtag) => {
    settopPostsLoading(true);
    setTopPostsloaded(false);
    setTopPostsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/top-five-videos/",
        config
      );

      setTopPosts(res?.data ?? []);
      settopPostsLoading(false);
      setTopPostsloaded(true);
      setTopPostsError(null);
    } catch (error) {
      setTopPosts([]);
      settopPostsLoading(false);
      setTopPostsloaded(false);
      setTopPostsError(error.code);
    }
  };

  // Top Tweets Chart
  let topPostsChart = (
    <Wrapper>
      <div className="section_card fullpage_section_card grid">
        <div className="grid col_span_4 section_card_header">

          <div className="section_info">  <h2 className="section_card_title">Top 5 Videos</h2>
            <SectionDefinationsBadge
              title={"Top 5 Videos"}
              module="video"
              platform="facebook"
              section="overall"
            /></div>

        </div>
        <div className="grid col_span_4">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (topPostsLoaded && !topPostsLoading) {
    if (NoDataAvailableChecker(topPosts)) {
      topPostsChart = (
        <Wrapper>
          <div className="section_card fullpage_section_card grid">
            <div className="grid col_span_4 section_card_header">

              <div className="section_info">
                <h2 className="section_card_title">Top 5 Videos</h2>
                <SectionDefinationsBadge
                  title={"Top 5 Videos"}
                  module="video"
                  platform="facebook"
                  section="overall"
                /></div>

            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      topPostsChart = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <TopProductCompare
              module={"video"}
              Title={"Videos"}
              products={topPosts}
              social_platform="facebook"
              module_section="overall"
              hasExportDropdown={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topPostserror) {
    topPostsChart = (
      <Wrapper>
        <div className="section_card fullpage_section_card grid">
          <div className="grid col_span_4 section_card_header">

            <div className="section_info">
              <h2 className="section_card_title">Top 5 Videos</h2>
              <SectionDefinationsBadge
                title={"Top 5 Videos"}
                module="video"
                platform="facebook"
                section="overall"
              /></div>
          </div>

          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader chartType="cardChartType" error={topPostserror} />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (topPostsLoading) {
    topPostsChart = (
      <Wrapper>
        <div className="section_card fullpage_section_card grid">
          <div className="grid col_span_4 section_card_header">

            <div className="section_info">
              <h2 className="section_card_title">Top 5 Videos</h2>
              <SectionDefinationsBadge
                title={"Top 5 Videos"}
                module="video"
                platform="facebook"
                section="overall"
              /></div>
          </div>

          <div className="grid col_span_4">
            <Loader loaderType="cardLoader" variantType="compare" />
          </div>
        </div>
      </Wrapper>
    );
  }

  var cityCountryData = {
    "VideoCity_Audience Distribution - City": NoDataAvailableChecker(cityData)
      ? []
      : cityData,
    "VideoCountry_Audience Distribution - Country": NoDataAvailableChecker(countryData)
      ? []
      : countryData,
  };

  var maleFemaleDataCSV = {
    "Audience Total Count": [
      { "Male Viewers": totalMaleCount, "Female Viewers": totalFemaleCount },
    ],
    "AgeBarChart_Audience Distribution - Male": maleCount,
    "AgeBarChart_Audience Distribution - Female": femaleCount,
  };

  var playBehaviourCSV = {
    [`DonutWithActiveDropdown_Playing Behaviour Analysis_${groupPlayingDropdownValue.label}`]: playingBehaviourAnalysis,
    [`DateFirstActiveDropdownLabels_Group Playing Behaviour_${groupPlayingDropdownValue.label}`]: groupPlayingBehaviour,
  };

  var viewTrendAnalysisCSV = {
    "View Trend Analysis": viewTrendAnalysis,
    "Daily Trend Line Analysis": dailyTrendLine,
  };

  var videoSentimentCSVData = {
    "PositiveSentiment_Positive Sentiment Data": positiveSentiment,
    "NegativeSentiment_Negative Sentiment Data": negativeSentiment,
  };

  var combineCSV = function (data) {
    var temp =
      data === undefined || data === null
        ? []
        : data[0] === undefined
          ? []
          : typeof data[0].label === "number"
            ? Object.values(data).map((item) => {
              var date = new Date(item.label);
              return { ...item, label: date.toLocaleDateString("en-GB") };
            })
            : data;

    return temp;
  };

  var allData = {
    ...topCardData,
    "VideoSummaryCard_Summary Cards": metricCards,
    "PieofPie_Video Length Analysis": videoLengthAnalysis,
    ...viewTrendAnalysisCSV,
    ...playBehaviourCSV,
    [`VideoUniqueView_Unique View Analysis_${uniqueViewAnalysisDropdownValue.label}`]: combineCSV(uniqueViewAnalysis),
    [`DynaLabelHeatmap_View Publish Trend_${viewPublishDropdownValue.label}`]: viewPublishTrend,
    "VideoRate_Video Rate Analysis": combineCSV(videoRateAnalysis),
    ...videoSentimentCSVData,
    "CommentSentiment_Comment Sentiment": commentSentiment,
    ...maleFemaleDataCSV,
    ...cityCountryData,
    "Compare_Top Post": topPosts,
  };

  useEffect(() => {
    setFullPageExport(allData);
  }, [
    exportSummary,
    metricCards,
    videoLengthAnalysis,
    viewTrendAnalysis,
    dailyTrendLine,
    playingBehaviourAnalysis,
    groupPlayingBehaviour,
    uniqueViewAnalysis,
    viewPublishTrend,
    videoRateAnalysis,
    positiveSentiment,
    negativeSentiment,
    commentSentiment,
    femaleCount,
    maleCount,
    cityData,
    countryData,
  ]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (videoLengthAnalysiserror !== null ? true : isVideoLengthAnalysisLoaded && (isVideoLengthAnalysisLoading === false)) &&
      (viewTrendAnalysisError !== null ? true : isViewTrendAnalysisLoaded && (isViewTrendAnalysisLoading === false)) &&
      (dailyTrendLineError !== null ? true : isDailyTrendLineLoaded && (isDailyTrendLineLoading === false)) &&
      (groupPlayingBehaviourError !== null ? true : isGroupPlayingBehaviourLoaded && (isGroupPlayingBehaviourLoading === false)) &&
      (groupPlayingBehaviourError !== null ? true : isGroupPlayingBehaviourLoaded && (isGroupPlayingBehaviourLoading === false)) &&
      (uniqueViewAnalysisError !== null ? true : isUniqueViewAnalysisLoaded && (isUniqueViewAnalysisLoading === false)) &&
      (viewPublishTrendError !== null ? true : isViewPublishTrendLoaded && (isViewPublishTrendLoading === false)) &&
      (videoRateAnalysisError !== null ? true : isVideoRateAnalysisLoaded && (isVideoRateAnalysisLoading === false)) &&
      (positiveSentimenterror !== null ? true : isPositiveSentimentLoaded && (isPositiveSentimentLoading === false)) &&
      (negativeSentimenterror !== null ? true : isNegativeSentimentLoaded && (isNegativeSentimentLoading === false)) &&
      (commentSentimenterror !== null ? true : isCommentSentimentLoaded && (isCommentSentimentLoading === false)) &&
      (femaleCounterror !== null ? true : isFemaleCountLoaded && (isFemaleCountLoading === false)) &&
      (maleCounterror !== null ? true : isMaleCountLoaded && (isMaleCountLoading === false)) &&
      (cityDataerror !== null ? true : isCityDataLoaded && (isCityDataLoading === false)) &&
      (countryDataerror !== null ? true : isCountryDataLoaded && (isCountryDataLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    videoLengthAnalysiserror, isVideoLengthAnalysisLoaded, isVideoLengthAnalysisLoading,
    viewTrendAnalysisError, isViewTrendAnalysisLoaded, isViewTrendAnalysisLoading,
    dailyTrendLineError, isDailyTrendLineLoaded, isDailyTrendLineLoading,
    groupPlayingBehaviourError, isGroupPlayingBehaviourLoaded, isGroupPlayingBehaviourLoading,
    groupPlayingBehaviourError, isGroupPlayingBehaviourLoaded, isGroupPlayingBehaviourLoading,
    uniqueViewAnalysisError, isUniqueViewAnalysisLoaded, isUniqueViewAnalysisLoading,
    viewPublishTrendError, isViewPublishTrendLoaded, isViewPublishTrendLoading,
    videoRateAnalysisError, isVideoRateAnalysisLoaded, isVideoRateAnalysisLoading,
    positiveSentimenterror, isPositiveSentimentLoaded, isPositiveSentimentLoading,
    negativeSentimenterror, isNegativeSentimentLoaded, isNegativeSentimentLoading,
    commentSentimenterror, isCommentSentimentLoaded, isCommentSentimentLoading,
    femaleCounterror, isFemaleCountLoaded, isFemaleCountLoading,
    maleCounterror, isMaleCountLoaded, isMaleCountLoading,
    cityDataerror, isCityDataLoaded, isCityDataLoading,
    countryDataerror, isCountryDataLoaded, isCountryDataLoading,
  ])


  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <div className="section_info">   <h2 className="section_card_title">Trending Hashtags
          </h2> <SectionDefinationsBadge
              title={"Trending Hashtags"}
              module="video"
              platform="facebook"
              section="overall"
            />
          </div>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topTrendingHashtagsList}</div>
        </div>
      </div>

      <div className="grid grid_margin_bottom">
        <div className="card_wrapper">{metricCardsSection}</div>
      </div>

      <div
        id="video_length_analysis"
        className="section_card fullpage_section_card grid grid_margin_bottom"
      >
        <div className="section_card_header">
          <div className="section_info">  <h2 className="section_card_title">
            Video Length Analysis {selectedHashtag && " : " + selectedHashtag}
          </h2> <SectionDefinationsBadge
              title={"video Length Analysis"}
              module="video"
              platform="facebook"
              section="overall"
            />
          </div>
          <div data-html2canvas-ignore={true}>
            <ExportDropdown
              moduleName={pageModule}
              subModuleName={pageSubModule}
              platform={pagePlatform}
              tab={pagePrimaryTab}
              sectionId={"video_length_analysis"}
              sectionName={"PieofPie_Video Length Analysis"}
              sectionData={videoLengthAnalysis}
            />
          </div>
        </div>
        <div className="section_card_body">
          <div className="section_card_chart_wrapper p_t_0 p_b_0">
            {videoLengthAnalysisChart}
          </div>
        </div>
      </div>

      <div
        id="view_trend"
        className="section_card fullpage_section_card grid grid_margin_bottom"
      >
        <div className="section_card_header">
          <div className="section_info">   <h2 className="section_card_title">
            View Trend Analysis {selectedHashtag && " : " + selectedHashtag}
          </h2> <SectionDefinationsBadge
              title={"View Trend Analysis"}
              module="video"
              platform="facebook"
              section="overall"
            />
          </div>
          <Modal
            open={isDailyTrendLineOpen}
            setOpen={setIsDailyTrendLineOpen}
            exportDropdown={
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"dailyTrendLine_2"}
                sectionName={"View Trend Analysis"}
                sectionData={viewTrendAnalysisCSV}
              />}
            parentId={"view_trend"}
            title={`View Trend Analysis ${selectedHashtag ? ":" + selectedHashtag : ""}`}
            chart={dailyTrendLineSection2}
          />
          <div data-html2canvas-ignore={true}>
            {isMultiLineCluster && <button onClick={() => setIsDailyTrendLineOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
              <ExpandIcon />
            </button>}
            <ExportDropdown
              moduleName={pageModule}
              subModuleName={pageSubModule}
              platform={pagePlatform}
              tab={pagePrimaryTab}
              sectionId={"view_trend"}
              sectionName={"View Trend Analysis"}
              sectionData={viewTrendAnalysisCSV}
            />
          </div>
        </div>
        <div className="section_card_body">
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_1 ">
              <div className="grid grid_cols_1 ">
                <div className="section_card_chart_wrapper">
                  {viewTrendAnalysisSection}
                </div>
              </div>
            </div>
            <div className="grid col_span_3 ">
              <div className="grid grid_cols_1 ">
                <div className="section_card_chart_wrapper">
                  {dailyTrendLineSection}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="playing_behaviour"
        className="section_card fullpage_section_card grid grid_margin_bottom"
      >
        <div className="section_card_header">
          <div className="section_info">   <h2 className="section_card_title">
            Playing Behaviour Analysis
            {selectedHashtag && " : " + selectedHashtag}
          </h2> <SectionDefinationsBadge
              title={"Playing Behaviour Analysis"}
              module="video"
              platform="facebook"
              section="overall"
            />
          </div>

          <div data-html2canvas-ignore={true}>
            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={groupPlayingDropdownOptions}
              className="form_dropdown section_dropdown"
              value={groupPlayingDropdownValue}
              selectedOptions={groupPlayingDropdownValue}
              setStatedropdown={groupPlayingSelectionHandler}
            />
            <ExportDropdown
              moduleName={pageModule}
              subModuleName={pageSubModule}
              platform={pagePlatform}
              tab={pagePrimaryTab}
              sectionId={"playing_behaviour"}
              sectionName={"Playing Behaviour Analysis"}
              sectionData={playBehaviourCSV}
            />
          </div>
        </div>
        <div className="section_card_body">
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_1">
              <div className="section_card_body">
                <div className="section_card_chart_wrapper">
                  {playingBehaviourAnalysisSection}
                </div>
              </div>
            </div>
            <div className="grid col_span_3">
              <div className="section_card_body">
                <div className="section_card_chart_wrapper">
                  {groupPlayingBehaviourSection}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="unique_view"
        className="section_card fullpage_section_card grid grid_margin_bottom"
      >
        <div className="section_card_header">
          <div className="section_info">   <h2 className="section_card_title">
            Unique View Analysis {selectedHashtag && " : " + selectedHashtag}
          </h2>  <SectionDefinationsBadge
              title={"Unique View Analysis"}
              module="video"
              platform="facebook"
              section="overall"
            />
          </div>
          <Modal
            open={isUniqueViewAnalysisOpen}
            setOpen={setIsUniqueViewAnalysisOpen}
            dropdown={<Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={UniqueViewAnalysisDropdownOptions}
              className="form_dropdown section_dropdown ml_20"
              value={uniqueViewAnalysisDropdownValue}
              selectedOptions={uniqueViewAnalysisDropdownValue}
              setStatedropdown={UniqueViewAnalysisDropdownSelectionHandler}
            />}
            exportDropdown={
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={
                  "uniqueViewAnalysis_2" //chart2 id
                }
                sectionName={`VideoUniqueView_Unique View Analysis_${uniqueViewAnalysisDropdownValue.label}`}
                sectionData={combineCSV(uniqueViewAnalysis)}
              />
            }
            parentId={"unique_view"} // div section id
            title={`Unique View Analysis  ${selectedHashtag ? ` : ${selectedHashtag}` : ''}`}
            chart={uniqueViewAnalysisSection2} //chart2 data
          />

          <div data-html2canvas-ignore={true}>
            {isMultiLineCluster && (
              <button
                onClick={() => setIsUniqueViewAnalysisOpen(true)}
                className="icon_btn btn-small-secondary expand_btn"
              >
                <ExpandIcon />
              </button>
            )}
            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={UniqueViewAnalysisDropdownOptions}
              className="form_dropdown section_dropdown ml_20"
              value={uniqueViewAnalysisDropdownValue}
              selectedOptions={uniqueViewAnalysisDropdownValue}
              setStatedropdown={UniqueViewAnalysisDropdownSelectionHandler}
            />

            <ExportDropdown
              moduleName={pageModule}
              subModuleName={pageSubModule}
              platform={pagePlatform}
              tab={pagePrimaryTab}
              sectionId={"unique_view"}
              sectionName={`VideoUniqueView_Unique View Analysis_${uniqueViewAnalysisDropdownValue.label}`}
              sectionData={combineCSV(uniqueViewAnalysis)}
            />
          </div>
        </div>
        <div className="section_card_body">
          <div className="section_card_chart_wrapper">
            {uniqueViewAnalysisSection}
          </div>
        </div>
      </div>

      <div
        id="view_publish"
        className="section_card fullpage_section_card grid grid_margin_bottom"
      >
        <div className="section_card_header">
          <div className="section_info">
            <h2 className="section_card_title">
              View Publish Trend {selectedHashtag && " : " + selectedHashtag}
            </h2> <SectionDefinationsBadge
              title={"View publish Trend"}
              module="video"
              platform="facebook"
              section="overall"
            />
          </div>
          <div data-html2canvas-ignore={true}>
            {" "}
            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={viewPublishDropdownOptions}
              className="form_dropdown section_dropdown"
              value={viewPublishDropdownValue}
              selectedOptions={viewPublishDropdownValue}
              setStatedropdown={viewPublishSelectionHandler}
            />
            <ExportDropdown
              moduleName={pageModule}
              subModuleName={pageSubModule}
              platform={pagePlatform}
              tab={pagePrimaryTab}
              sectionId={"view_publish"}
              sectionName={`DynaLabelHeatmap_View Publish Trend_${viewPublishDropdownValue.label}`}
              sectionData={viewPublishTrend}
            />
          </div>
        </div>
        <div className="section_card_body">
          <div className="section_card_chart_wrapper">
            {viewPublishTrendSection}
          </div>
        </div>
      </div>

      <div
        id="video_rate"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="section_card_header">
          <div className="section_info">   <h2 className="section_card_title">
            Video Rate Analysis {selectedHashtag && " : " + selectedHashtag}
          </h2> <SectionDefinationsBadge
              title={"video Rate Analysis"}
              module="video"
              platform="facebook"
              section="overall"
            />
          </div>
          <Modal
            open={isVideoRateOpen}
            setOpen={setIsVideoRateOpen}
            exportDropdown={
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={
                  "videoRateAnalysis_2" //chart2 id
                }
                sectionName={"VideoRate_Video Rate Analysis"}
                sectionData={combineCSV(videoRateAnalysis)}
              />
            }
            parentId={"video_rate"} // div section id
            title={`Video Rate Analysis ${selectedHashtag ? ` : ${selectedHashtag}` : ''}`}
            chart={videoRateAnalysisSection2} //chart2 data
          />
          <div data-html2canvas-ignore={true}>
            {isMultiLineCluster && (
              <button
                onClick={() => setIsVideoRateOpen(true)}
                className="icon_btn btn-small-secondary expand_btn"
              >
                <ExpandIcon />
              </button>
            )}
            <ExportDropdown
              moduleName={pageModule}
              subModuleName={pageSubModule}
              platform={pagePlatform}
              tab={pagePrimaryTab}
              sectionId={"video_rate"}
              sectionName={"VideoRate_Video Rate Analysis"}
              sectionData={combineCSV(videoRateAnalysis)}
            />
          </div>
        </div>
        <div className="section_card_body">
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4">
              <div className="section_card_body">
                <div className="section_card_chart_wrapper">
                  {videoRateAnalysisSection}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="video_sentiment"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">
              Video Sentiment {selectedHashtag && " : " + selectedHashtag}
            </h2> <SectionDefinationsBadge
                title={"Video Sentiment"}
                module="video"
                platform="facebook"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"video_sentiment"}
                sectionName={"Video Sentiment"}
                sectionData={videoSentimentCSVData}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Positive</h2>
            </div>
            <div className="grid col_span_1 section_card_body">
              <div className="section_card_chart_wrapper grid col_span_2">
                {positiveSentimentSection}
              </div>
              <div className="section_card_table_wrapper grid col_span_2">
                <div className="table_wrapper section_card_table_wrapper">
                  {positiveSentimentTable}
                </div>
              </div>
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Negative </h2>
            </div>
            <div className="grid col_span_1 section_card_body">
              <div className="section_card_chart_wrapper grid col_span_2">
                {negativeSentimentSection}
              </div>
              <div className="section_card_table_wrapper grid col_span_2">
                <div className="table_wrapper section_card_table_wrapper">
                  {negativeSentimentTable}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="comment_sentiment"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">
              Comments Sentiment {selectedHashtag && " : " + selectedHashtag}
            </h2> <SectionDefinationsBadge
                title={"Comment Sentiment"}
                module="video"
                platform="facebook"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"comment_sentiment"}
                sectionName={"CommentSentiment_Comment Sentiment"}
                sectionData={commentSentiment}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_4">
            <div className="grid col_span_1 section_card_body">
              <div className="section_card_chart_wrapper grid col_span_2">
                {commentSentimentSection}
              </div>
              <div className="section_card_table_wrapper grid col_span_2">
                <div className="table_wrapper section_card_table_wrapper">
                  {commentSentimentTable}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="audience_dist"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Audience Distribution{" "}
              {selectedHashtag && " : " + selectedHashtag}
            </h2> <SectionDefinationsBadge
                title={"Audience Distribution By Gender"}
                module="video"
                platform="facebook"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_dist"}
                sectionName={"Audience Distribution - Male Female"}
                sectionData={maleFemaleDataCSV}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Male</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {maleAudienceDistributionIcon}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {maleAudienceDistribution}
              </div>
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="grid col_span_4 section_card_header">
              <h2 className="section_card_title">Female</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {femaleAudienceDistributionIocn}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {femaleAudienceDistribution}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="audience_distribution_place"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Audience Distribution{" "}
              {selectedHashtag && " : " + selectedHashtag}
            </h2> <SectionDefinationsBadge
                title={"Audience Distribution By Country"}
                module="video"
                platform="facebook"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_distribution_place"}
                sectionName={"Audience Distribution"}
                sectionData={cityCountryData}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4 ">
          <div className="grid col_span_2 card_border_right flex column">
            {/*     <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body"> */}
            {countryMap}
          </div>
          {/*  </div> */}
          <div className="grid col_span_2 flex column">
            {/*  <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body"> */}
            {cityMap}
          </div>
        </div>
      </div>
      {/* </div> */}

      {topPostsChart}
    </Wrapper >
  );
}
