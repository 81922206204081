import React, { useContext, useEffect, useState } from "react";

// STORAGE
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";

// UTILS
import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";

// pAGES
import ThumbnailTwitterVideoOverall from "./ThumbnailTwitterVideoOverall";
import ThumbnailTwitterVideoOrganic from "./ThumbnailTwitterVideoOrganic";
import ThumbnailTwitterVideoPaid from "./ThumbnailTwitterVideoPaid";

export default function ThumbnailTwitterVideo({ videoIdentity, videoDurationType, topCardData }) {
  const { setExportPageName } = useContext(FullPageExportContext);

  const tabData = ["Overall", "Organic", "Paid"];
  const [selectedTab, setSelectedTab] = useState(tabData[0]);

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Video", "Twitter Thumbnail", event)
  };


  useEffect(() => {
    setExportPageName("Video Twitter Thumbnail " + selectedTab)
  }, [selectedTab])

  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <Tab
          varient={"secondary"}
          tabData={tabData}
          activeTab={selectedTab}
          handleCallback={(event) => handleCallback(event)}
        />
      </div>
      {selectedTab.toLowerCase() === tabData[0].toLowerCase() && <ThumbnailTwitterVideoOverall videoIdentity={videoIdentity} videoDurationType={videoDurationType} topCardData={topCardData} />}
      {selectedTab.toLowerCase() === tabData[1].toLowerCase() && <ThumbnailTwitterVideoOrganic videoIdentity={videoIdentity} videoDurationType={videoDurationType} topCardData={topCardData} />}
      {selectedTab.toLowerCase() === tabData[2].toLowerCase() && <ThumbnailTwitterVideoPaid videoIdentity={videoIdentity} videoDurationType={videoDurationType} topCardData={topCardData} />}
    </Wrapper>
  );
}
