import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";

// STORAGE
import AuthContext from "../../../store/authContext";
import FullPageExportContext from "../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../components/helpers/axios";
import Wrapper from "../../../components/helpers/Wrapper";
import Datatable from "../../../components/ui/tables/Datatable";
import ExportDropdown from "../../../components/ui/dropdown/ExportDropdown";
import SectionDefinationsBadge from "../../../components/ui/SectionDefinationsBadge";

// LOADERS
import NoDataAvailableLoader from "../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../components/ui/loaders/ServerErrosLoader";
import Loader from "../../../components/ui/loaders/Loader";

// UTILS
import ErrorBoundary from "../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../utils/NoDataAvailableChecker";
import { getHeaderKeysWithLabels, getUniqueValuesFromArrofObj } from "../../../utils/Utils";
import LineChart from "../../../components/ui/charts/LineChart";
import { DataFormatters } from "../../../utils/DataFormatter";
import PartitionedLineChart from "../../../components/ui/charts/PartitionedLineChart";
import Modal from "../../../components/ui/Modal";
import ExpandIcon from "../../../assets/icons/ExpandIcon";


const SearchConsole = ({ viewId }) => {

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Website"
  let pagePlatform = ""
  let pagePrimaryTab = "Search Console"

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, exportSummary, setIsExportDisabled } = useContext(FullPageExportContext);
  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);


  const [linechartData, setLinechartData] = useState([])
  const [linechartDataLabel, setLinechartDataLabel] = useState([])
  const [linechartLoaded, setLinechartLoaded] = useState(false);
  const [linechartLoading, setLinechartLoading] = useState(false);
  const [linechartError, setLinechartError] = useState(null);

  let defaultLineChartDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultLineChartDisabledvalues = []
  } else {
    defaultLineChartDisabledvalues = []
  }

  const [isLineChartOpen, setIsLineChartOpen] = useState(false)
  const [lineChartDisabledvalues, setLineChartDisabledvalues] = useState(defaultLineChartDisabledvalues)



  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [tableDataError, setTableDataError] = useState(null);

  // TO FETCH TABLE DATA FROM API
  const fetchTableDataHandler = async () => {
    setTableDataLoaded(false);
    setTableDataLoading(true);
    setTableDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        view_id: viewId,
        dimension: 'landing_page'
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("website/gsc-landing-page/", config);


      let allData = res.data.data ?? []
      let uniqueHeaders = getUniqueValuesFromArrofObj(allData)

      let formattedHeaders = []

      uniqueHeaders.map((header) => {
        formattedHeaders.push({
          name: getHeaderKeysWithLabels(header),
          field: header,
        })
      })


      setTableData(allData ?? []);
      setTableHeaders(formattedHeaders ?? []);
      setTableDataLoaded(true);
      setTableDataLoading(false);
      setTableDataError(null);
    } catch (error) {
      setTableDataLoaded(true);
      setTableDataLoading(false);
      setTableDataError(error);
    }
  };


  useEffect(() => {

    const fetchLinechartHandler = async () => {
      setLinechartLoaded(false);
      setLinechartLoading(true);
      setLinechartError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          view_id: viewId,
          dimension: 'date'
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("website/gsc-landing-page/", config);


        const allData = res?.data?.data ?? [];
        const dataLabel = "date";
        const unwantedValues = ['date']
        const dropdownArray = []

        allData.sort((a, b) => new Date(a.date) - new Date(b.date));

        let formattedData = DataFormatters("linechart", allData, dataLabel, dropdownArray, unwantedValues)

        setLinechartData(formattedData?.data ?? []);
        setLinechartDataLabel(formattedData?.dimension ?? []);

        setLinechartLoaded(true);
        setLinechartLoading(false);
        setLinechartError(null);
      } catch (error) {
        setLinechartData([]);
        setLinechartDataLabel([]);
        setLinechartLoaded(true);
        setLinechartLoading(false);
        setLinechartError(error);
      }
    };

    (viewId !== undefined) && fetchLinechartHandler()

  }, [BRAND_ID, viewId, selectedDates])



  let lineChartSection = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>
  let lineChartSection2 = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>

  // IF DATA IS LOADED
  if (linechartLoaded && !linechartLoading) {
    if (NoDataAvailableChecker(linechartData) || NoDataAvailableChecker(linechartDataLabel)) {
      lineChartSection = (
        <Wrapper>  <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );

      lineChartSection2 = (
        <Wrapper>  <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
    } else {

      if (isMultiLineCluster) {

        lineChartSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={linechartData}
                chartId="multiLineChart"
                chartLabels={linechartDataLabel}
                onBulletsClick={function noRefCheck() { }}
                showLabels
                showLegend
                disabledLegendArray={lineChartDisabledvalues}
                setDisabledValues={setLineChartDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );
        lineChartSection2 = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={linechartData}
                chartId="kpi_trendline_2"
                chartLabels={linechartDataLabel}
                onBulletsClick={function noRefCheck() { }}
                showLabels
                showLegend
                disabledLegendArray={lineChartDisabledvalues}
                setDisabledValues={setLineChartDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

      } else {
        lineChartSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={linechartData}
                chartId="multiLineChart"
                chartLabels={linechartDataLabel}
                onBulletsClick={function noRefCheck() { }}
                showLabels
                showLegend
                disabledLegendArray={lineChartDisabledvalues}
                setDisabledValues={setLineChartDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }



    }


  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (linechartError) {
    lineChartSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={linechartError}
        />
      </Wrapper>
    );
    lineChartSection2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={linechartError}
        />
      </Wrapper>
    );

  }

  // IF DATA LOADING
  if (linechartLoading) {
    lineChartSection = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;
    lineChartSection2 = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;

  }



  // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
  useEffect(() => {
    viewId === undefined
      ? <NoDataAvailableLoader chartType="tableChartType" />
      : viewId.length === 0
        ? <NoDataAvailableLoader chartType="tableChartType" />
        : fetchTableDataHandler();
  }, [BRAND_ID, viewId, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TABLE DATA
  let summaryTable = (
    <Wrapper>
      <div className="section_card fullpage_section_card grid">
        <div className="section_card_header">
          <h2 className="section_card_title">Summary
            <SectionDefinationsBadge
              title={"Summary"}
              module="website"
              platform="landing page"
              section="overall"
            />
          </h2>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            {viewId === undefined ? (
              <NoDataAvailableLoader chartType="tableChartType" />
            ) : viewId.length === 0 ? (
              <NoDataAvailableLoader chartType="tableChartType" />
            ) : (
              <Loader loaderType="smallTableLoader" />
            )}

          </div>
        </div>
      </div>
    </Wrapper>
  );

  if (tableDataLoaded && !tableDataLoading) {
    if (NoDataAvailableChecker(tableData) || NoDataAvailableChecker(tableHeaders)) {
      summaryTable = <Wrapper>
        <div className="section_card fullpage_section_card grid">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary
              <SectionDefinationsBadge
                title={"Summary"}
                module="website"
                platform="landing page"
                section="overall"
              />
            </h2>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <NoDataAvailableLoader chartType="tableChartType" />
            </div>
          </div>
        </div>
      </Wrapper>
    } else {
      summaryTable = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"Summary"}
              tablePageSubModule="website"
              tablePagePlatform="landing page"
              tablePagePrimaryTab="overall"
              tableHeader={tableHeaders}
              tableData={tableData}
              tableLength={10}
              isFooterShow={true}
              searchPlaceHolder={"Search Pages"}
              tableID={"pages_table_section"}
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"pages_table_section"}
                  sectionName="Summary Table"
                  sectionData={tableData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }

  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (tableDataError) {
    summaryTable = (
      <Wrapper>
        <div className="section_card fullpage_section_card grid">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary
              <SectionDefinationsBadge
                title={"Summary"}
                module="website"
                platform="landing page"
                section="overall"
              />
            </h2>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={tableDataError} />
            </div>
          </div>
        </div>
      </Wrapper>);
  }

  // IF DATA LOADING
  if (tableDataLoading) {
    summaryTable = <Wrapper>
      <div className="section_card fullpage_section_card grid">
        <div className="section_card_header">
          <h2 className="section_card_title">Summary
            <SectionDefinationsBadge
              title={"Summary"}
              module="website"
              platform="landing page"
              section="overall"
            />
          </h2>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>

    </Wrapper>
  }

  var allData = { Summary: exportSummary, 'KPI Trendline': linechartData, "Summary Table": tableData };

  useEffect(() => {
    setFullPageExport(allData);
  }, [
    linechartData,
    tableData
  ]);

  useEffect(() => {
    if (linechartError !== null ? true : linechartLoaded && (linechartLoading === false) &&
      tableDataError !== null ? true : tableDataLoaded && (tableDataLoading === false)
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    linechartError, linechartLoaded, linechartLoading,
    tableDataError, tableDataLoaded, tableDataLoading
  ])


  return (
    <Wrapper>

      <div className="grid grid_cols_1 grid_margin_bottom">
        <div id="kpi_trendline" className="grid grid_cols_1 section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info">   <h2 className="section_card_title">KPI Trend Line
              </h2>
              </div>
              <Modal
                open={isLineChartOpen}
                setOpen={setIsLineChartOpen}
                exportDropdown={
                  <ExportDropdown

                    sectionId={"kpi_trendline_2"}
                    sectionName={'KPI Trend Line'}
                    sectionData={linechartData}
                  />}
                parentId={"kpi_trendline"}
                title={`KPI Trend Line`}
                chart={lineChartSection2}
              />
              <div data-html2canvas-ignore={true}>
                {isMultiLineCluster && <button onClick={() => setIsLineChartOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                  <ExpandIcon />
                </button>}
                <ExportDropdown
                  sectionId={"kpi_trendline"}
                  sectionName={'KPI Trend Line'}
                  sectionData={linechartData}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {lineChartSection}
              </div>
            </div>
          </div>
        </div>
      </div>

      {summaryTable}
    </Wrapper>
  );
};

export default SearchConsole;
