import { useEffect, useRef, useState } from "react";

import Wrapper from "../helpers/Wrapper";
import {
  CompanyAboutCharLimit,
  CompanyCategoryCharLimit,
  CompanyNameCharLimit,
  CompanyWebsiteCharLimit,
  EmailCharLimit,
  MobileNoCharLimit,
} from "../../constants/inputLimit";

import {
  InvalidEmailMsg,
  RequiredField,
  shortMobileNoMsg,
  InvalidMobileNoMsg,
  EmailRegex,
  MobileNoRegex,
  OnlyCharRegex,
  MaxLimitReachedInputMsg,
  InvalidInputMsg,
  InvalidCompanyCategoryMsg,
  WebsiteLinkRegex,
  InvalidCompanyWebsiteMsg,
} from "../../constants/constants";

const RegistrationCompanyDetailsForm = ({
  formData,
  setFormData,
  formValidation,
  setFormValidation,
  formStepsData,
  setFormStepsData,
}) => {
  const companyNameInputRef = useRef();
  const companyCategoryInputRef = useRef();
  const aboutCompanyInputRef = useRef();
  const websiteLinkInputRef = useRef();
  const companyEmailInputRef = useRef();
  const companyMobileNoInputRef = useRef();

  const [companyNameIsTouched, setCompanyNameIsTouched] = useState(false);
  const [companyNameIsValid, setCompanyNameIsValid] = useState(false);
  const [companyNameError, setCompanyNameError] = useState();

  const [companyCategoryIsTouched, setCompanyCategoryIsTouched] =
    useState(false);
  const [companyCategoryIsValid, setCompanyCategoryIsValid] = useState(false);
  const [companyCategoryError, setCompanyCategoryError] = useState();

  const [companyAboutIsTouched, setCompanyAboutIsTouched] = useState(false);
  const [companyAboutIsValid, setCompanyAboutIsValid] = useState(false);
  const [companyAboutError, setCompanyAboutError] = useState();

  const [companyWebsiteLinkIsTouched, setCompanyWebsiteLinkIsTouched] =
    useState(false);
  const [companyWebsiteLinkIsValid, setCompanyWebsiteLinkIsValid] =
    useState(false);
  const [companyWebsiteLinkError, setCompanyWebsiteLinkError] = useState();

  const [companyEmailIsTouched, setCompanyEmailIsTouched] = useState(false);
  const [companyEmailIsValid, setCompanyEmailIsValid] = useState(false);
  const [companyEmailError, setCompanyEmailError] = useState();

  const [companyMobileNoIsTouched, setCompanyMobileNoTouched] = useState(false);
  const [companyMobileNoIsValid, setCompanyMobileNoIsValid] = useState(false);
  const [companyMobileNoError, setCompanyMobileNoError] = useState();

  // COMPANY NAME
  const companyNameChangeHandler = () => {
    setCompanyNameIsTouched(true);
    setCompanyNameIsValid(true);
    setFormValidation({ ...formValidation, companyName: true });
    setFormData({
      ...formData,
      companyName: companyNameInputRef.current.value,
    });
  };
  const validateCompanyNameHandler = () => {
    if (formValidation.companyName === false) {
      setCompanyNameIsTouched(true);
    } else {
      setCompanyNameIsTouched(false);
      if (companyNameInputRef.current.value === "") {
        setCompanyNameError(RequiredField);
        setCompanyNameIsValid(false);
        setCompanyNameIsTouched(true);
        setFormValidation({ ...formValidation, companyName: false });
      }
    }

    if (companyNameInputRef.current.value === "") {
      setCompanyNameIsValid(false);
      setCompanyNameError(RequiredField);
      setFormValidation({ ...formValidation, companyName: false });
    }
  };

  // COMPANY CATEGORY
  const companyCategoryChangeHandler = () => {
    setCompanyCategoryIsTouched(true);

    if (
      companyCategoryInputRef.current.value === "" ||
      OnlyCharRegex.test(companyCategoryInputRef.current.value)
    ) {
      setCompanyCategoryError();
      setCompanyCategoryIsValid(true);
      setFormData({
        ...formData,
        companyCategory: companyCategoryInputRef.current.value,
      });
      if (
        companyCategoryInputRef.current.value.length >= CompanyCategoryCharLimit
      ) {
        setCompanyCategoryError(
          MaxLimitReachedInputMsg + CompanyCategoryCharLimit
        );
        setCompanyCategoryIsValid(false);
      }
    } else {
      setCompanyCategoryError(InvalidCompanyCategoryMsg);
      setCompanyCategoryIsValid(false);
    }
  };
  const validateCompanyCategoryHandler = () => {
    if (companyCategoryInputRef.current.value.length >= CompanyAboutCharLimit) {
      setCompanyCategoryError();
      setCompanyCategoryIsTouched(true);
      setCompanyCategoryIsValid(true);
    }

    if (companyCategoryInputRef.current.value.length !== 0) {
      setCompanyCategoryError();
      setCompanyCategoryIsTouched(true);
      setCompanyCategoryIsValid(true);
    }
  };

  // COMPANY ABOUT
  const companyAboutChangeHandler = () => {
    setCompanyAboutError();
    setCompanyAboutIsTouched(true);
    setCompanyAboutIsValid(true);
    setFormData({
      ...formData,
      companyAbout: aboutCompanyInputRef.current.value,
    });
    if (aboutCompanyInputRef.current.value.length >= CompanyAboutCharLimit) {
      setCompanyAboutError(MaxLimitReachedInputMsg + CompanyAboutCharLimit);
      setCompanyAboutIsValid(false);
    }
  };
  const validateCompanyAboutHandler = () => {
    if (aboutCompanyInputRef.current.value.length >= CompanyAboutCharLimit) {
      setCompanyAboutError();
      setCompanyAboutIsTouched(true);
      setCompanyAboutIsValid(true);
    }
  };

  // COMPANY WEBSITE LINK
  const companyWebsiteLinkChangeHandler = () => {
    setCompanyWebsiteLinkIsTouched(true);
    setFormData({
      ...formData,
      companyWebsiteLink: websiteLinkInputRef.current.value,
    });
    if (!WebsiteLinkRegex.test(websiteLinkInputRef.current.value)) {
      setCompanyWebsiteLinkError(InvalidCompanyWebsiteMsg);
      setCompanyWebsiteLinkIsValid(false);
      setFormValidation({ ...formValidation, companyName: false });
    } else if (
      websiteLinkInputRef.current.value.length >= CompanyWebsiteCharLimit
    ) {
      setCompanyWebsiteLinkError(
        MaxLimitReachedInputMsg + CompanyWebsiteCharLimit
      );
      setCompanyWebsiteLinkIsValid(true);
      setFormValidation({ ...formValidation, companyName: false });
    } else {
      setCompanyWebsiteLinkError();
      setCompanyWebsiteLinkIsValid(true);
      setFormValidation({ ...formValidation, companyName: true });
    }
  };
  const validateCompanyWebsiteLinkHandler = () => {
    if (websiteLinkInputRef.current.value.length >= CompanyWebsiteCharLimit) {
      setCompanyWebsiteLinkError();
      setCompanyWebsiteLinkIsTouched(true);
      setCompanyWebsiteLinkIsValid(true);
      setFormValidation({ ...formValidation, companyName: true });
    }
    if (websiteLinkInputRef.current.value === "") {
      setCompanyWebsiteLinkError();
      setCompanyWebsiteLinkIsValid(true);
      setFormValidation({ ...formValidation, companyName: true });
    }
  };

  // COMPANY EMAIL ID
  const companyEmailChangeHandler = () => {
    setCompanyEmailIsTouched(true);
    if (!EmailRegex.test(companyEmailInputRef.current.value)) {
      setCompanyEmailError(InvalidEmailMsg);
      setCompanyEmailIsValid(false);
      setFormData({
        ...formData,
        companyEmail: companyEmailInputRef.current.value,
      });
      setFormValidation({ ...formValidation, companyName: false });
    } else if (companyEmailInputRef.current.value.length >= EmailCharLimit) {
      setCompanyEmailError(MaxLimitReachedInputMsg + EmailCharLimit);
      setCompanyEmailIsValid(false);
      setCompanyEmailIsTouched(false);
      setFormValidation({ ...formValidation, companyEmail: false });
    } else {
      setCompanyEmailError();
      setCompanyEmailIsValid(true);
      setCompanyEmailIsTouched(true);
      setFormData({
        ...formData,
        companyEmail: companyEmailInputRef.current.value,
      });
      setFormValidation({ ...formValidation, companyName: true });
    }
  };
  const validateCompanyEmailHandler = () => {
    if (companyEmailInputRef.current.value.length >= EmailCharLimit) {
      setCompanyEmailError();
      setCompanyEmailIsTouched(true);
      setCompanyEmailIsValid(true);
      setFormValidation({ ...formValidation, companyName: true });
    }
    if (companyEmailInputRef.current.value === "") {
      setCompanyEmailError();
      setCompanyEmailIsValid(true);
      setCompanyEmailIsTouched(true);
      setFormValidation({ ...formValidation, companyName: true });
    }
  };

  // COMPANY MOBILE NUMBER
  const companyMobileNoChangeHandler = () => {
    setCompanyMobileNoTouched(true);
    if (
      companyMobileNoInputRef.current.value === "" ||
      MobileNoRegex.test(companyMobileNoInputRef.current.value)
    ) {
      setFormData({
        ...formData,
        companyMobileNo: companyMobileNoInputRef.current.value,
      });
      if (companyMobileNoInputRef.current.value.length < MobileNoCharLimit) {
        setCompanyMobileNoError(shortMobileNoMsg);
        setCompanyMobileNoIsValid(false);
        setFormValidation({ ...formValidation, companyName: false });
      } else {
        setCompanyMobileNoError();
        setCompanyMobileNoIsValid(true);
        setFormValidation({ ...formValidation, companyName: true });
      }
    } else {
      setCompanyMobileNoError(InvalidMobileNoMsg);
      setCompanyMobileNoIsValid(false);
      setFormValidation({ ...formValidation, companyName: false });
    }
  };

  const validateCompanyMobileNoHandler = () => {
    if (companyEmailInputRef.current.value.length >= MobileNoCharLimit) {
      setCompanyMobileNoError();
      setCompanyMobileNoTouched(true);
      setCompanyMobileNoIsValid(true);
      setFormValidation({ ...formValidation, companyName: true });
    }
    if (companyEmailInputRef.current.value === "") {
      setCompanyMobileNoError();
      setCompanyMobileNoIsValid(true);
      setCompanyMobileNoTouched(true);
      setFormValidation({ ...formValidation, companyName: true });
    }
  };

  const companyNameInputIsInvalid = !companyNameIsValid && companyNameIsTouched;
  const companyNameInputClasses = companyNameInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  const companyCategoryInputIsInvalid =
    !companyCategoryIsValid && companyCategoryIsTouched;
  const companyCategoryInputClasses = companyCategoryInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  const companyAboutInputIsInvalid =
    !companyAboutIsValid && companyAboutIsTouched;
  const companyAboutInputClasses = companyAboutInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  const companyWebsiteLinkInputIsInvalid =
    !companyWebsiteLinkIsValid && companyWebsiteLinkIsTouched;
  const companyWebsiteLinkInputClasses = companyWebsiteLinkInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  const companyEmailInputIsInvalid =
    !companyEmailIsValid && companyEmailIsTouched;
  const companyEmailInputClasses = companyEmailInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  const companyMobileNoInputIsInvalid =
    !companyMobileNoIsValid && companyMobileNoIsTouched;
  const companyMobileNoInputClasses = companyMobileNoInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  useEffect(() => {
    if (formValidation.companyName === true) {
      setFormStepsData({ ...formStepsData, thirdStepIsValid: true });
    } else {
      setFormStepsData({ ...formStepsData, thirdStepIsValid: false });
    }
  }, [formValidation]);

  return (
    <Wrapper>
      <div className="form_group">
        <input
          className={companyNameInputClasses}
          type="text"
          name="companyName"
          id="companyName"
          autoComplete="off"
          maxLength={CompanyNameCharLimit}
          placeholder="*Please Enter Your Company Name"
          value={formData.companyName}
          onChange={companyNameChangeHandler}
          onBlur={validateCompanyNameHandler}
          ref={companyNameInputRef}
          required
        />
        <div className="error_section">
          {companyNameInputIsInvalid && (
            <h6 className="error_text">{companyNameError}</h6>
          )}
        </div>
      </div>
      <div className="form_group">
        <input
          className={companyCategoryInputClasses}
          type="text"
          name="companyCategory"
          id="companyCategory"
          autoComplete="off"
          maxLength={CompanyCategoryCharLimit}
          placeholder="Please Enter Your Company Category"
          value={formData.companyCategory}
          onChange={companyCategoryChangeHandler}
          onBlur={validateCompanyCategoryHandler}
          ref={companyCategoryInputRef}
        />
        <div className="error_section">
          {companyCategoryInputIsInvalid && (
            <h6 className="error_text">{companyCategoryError}</h6>
          )}
        </div>
      </div>
      <div className="form_group">
        <input
          className={companyAboutInputClasses}
          type="text"
          name="companyAbout"
          id="companyAbout"
          autoComplete="off"
          maxLength={CompanyAboutCharLimit}
          placeholder="Please Write About Your Company"
          value={formData.companyAbout}
          onChange={companyAboutChangeHandler}
          onBlur={validateCompanyAboutHandler}
          ref={aboutCompanyInputRef}
        />
        <div className="error_section">
          {companyAboutInputIsInvalid && (
            <h6 className="error_text">{companyAboutError}</h6>
          )}
        </div>
      </div>
      <div className="form_group">
        <input
          className={companyWebsiteLinkInputClasses}
          type="text"
          name="companyWebsiteLink"
          id="companyWebsiteLink"
          autoComplete="off"
          maxLength={CompanyWebsiteCharLimit}
          placeholder="Please Enter Your Company Website Link"
          value={formData.companyWebsiteLink}
          onChange={companyWebsiteLinkChangeHandler}
          onBlur={validateCompanyWebsiteLinkHandler}
          ref={websiteLinkInputRef}
        />
        <div className="error_section">
          {companyWebsiteLinkInputIsInvalid && (
            <h6 className="error_text">{companyWebsiteLinkError}</h6>
          )}
        </div>
      </div>
      <div className="form_group">
        <input
          className={companyEmailInputClasses}
          type="email"
          name="companyEmail"
          id="companyEmail"
          autoComplete="off"
          maxLength={EmailCharLimit}
          placeholder="Please Enter Your Company Email"
          value={formData.companyEmail}
          onChange={companyEmailChangeHandler}
          onBlur={validateCompanyEmailHandler}
          ref={companyEmailInputRef}
        />
        <div className="error_section">
          {companyEmailInputIsInvalid && (
            <h6 className="error_text">{companyEmailError}</h6>
          )}
        </div>
      </div>
      <div className="form_group">
        <input
          className={companyMobileNoInputClasses}
          type="text"
          name="companyMobileNo"
          id="companyMobileNo"
          autoComplete="off"
          maxLength={MobileNoCharLimit}
          placeholder="Please Enter Your Company Mobile Number"
          value={formData.companyMobileNo}
          onChange={companyMobileNoChangeHandler}
          onBlur={validateCompanyMobileNoHandler}
          ref={companyMobileNoInputRef}
        />
        <div className="error_section">
          {companyMobileNoInputIsInvalid && (
            <h6 className="error_text">{companyMobileNoError}</h6>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default RegistrationCompanyDetailsForm;
