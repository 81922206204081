import PropTypes from "prop-types";
import Wrapper from "../components/helpers/Wrapper";

import IconDeGrowth from "../assets/icons/DegrowthIcon";
import IconGrowth from "../assets/icons/GrowthIcon";
import IconNeutral from "../assets/icons/NeutralIcon";

const GrowthConverter = ({ currentValue, previousValue, isReversed }) => {

    let currentValueNumber = Number(currentValue);
    let previousValueNumber = Number(previousValue);

    let icon = "";
    let varient = "";

    if (!currentValueNumber) {
        currentValueNumber = 0
    }

    if (!previousValueNumber) {
        previousValueNumber = 0
    }

    let result = ((currentValueNumber - previousValueNumber) / previousValueNumber) * 100;

    if (isNaN(result)) {
        result = 0
    }

    if (!isFinite(result)) {
        result = 0
    }

    if (isReversed) {
        if (result > 0) {
            varient = "negative_growth"
            icon = <IconDeGrowth />
        } else if (result === 0) {
            varient = "neutral_growth"
            icon = <IconNeutral />
        } else {
            varient = "positive_growth"
            icon = <IconGrowth />
        }
    } else {
        if (result > 0) {
            varient = "positive_growth"
            icon = <IconGrowth />
        } else if (result === 0) {
            varient = "neutral_growth"
            icon = <IconNeutral />
        } else {
            varient = "negative_growth"
            icon = <IconDeGrowth />
        }
    }


    return (
        <Wrapper>
            <div className="card_growth_section">
                <div className="icon_section">{icon}</div>
                <div className={"text_section " + varient}>{+Math.abs(result).toFixed(2)}{result !== 0 && "%"}</div>
            </div>
        </Wrapper>
    )
}

GrowthConverter.propTypes = {
    currentValue: PropTypes.any,
    previousValue: PropTypes.any,
    isReversed: PropTypes.bool
};
export default GrowthConverter;