const IconGrowth = (props) => {
    return (
        <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="17.541" height="15.721" viewBox="0 0 17.541 15.721">
            <g id="Group_205" data-name="Group 205" transform="translate(-295 -615.139)">
                <g id="Group_24" data-name="Group 24" transform="translate(-559 416.054)">
                    <path id="Path_46" data-name="Path 46" d="M14.524,1.816c-.7,0-1.36,0-2.019,0A.906.906,0,0,1,12.44,0Q14.6,0,16.766,0a.882.882,0,0,1,.86.857q.007,2.163,0,4.326a.906.906,0,0,1-1.809,0c-.006-.63,0-1.259,0-1.889a.32.32,0,0,0-.043-.176c-.046.055-.087.115-.138.166Q12.93,5.992,10.224,8.7a.939.939,0,0,1-1.518,0C8.1,8.083,7.483,7.475,6.878,6.86c-.1-.1-.156-.108-.261,0Q4.185,9.3,1.745,11.738a.955.955,0,0,1-1,.318.9.9,0,0,1-.457-1.428,2.242,2.242,0,0,1,.179-.194L6,4.9a.936.936,0,0,1,1.505,0q.928.928,1.855,1.858c.071.071.112.11.206.016,1.64-1.646,3.283-3.289,4.96-4.966" transform="translate(853.911 199.085)" fill="#07c180" />
                    <path id="Path_47" data-name="Path 47" d="M8.776,277.2H.918A.907.907,0,0,1,.5,275.487a.852.852,0,0,1,.4-.1l15.753,0a.906.906,0,0,1-.016,1.812q-3.929,0-7.857,0" transform="translate(853.999 -62.396)" fill="#07c180" />
                </g>
            </g>
        </svg>
    )
}

export default IconGrowth;
