import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// STORAGE
import AuthContext from "../../../../store/authContext";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import PageHeader from "../../../../components/layout/pageHeader";
import axios from "../../../../components/helpers/axios";
import Datatable from "../../../../components/ui/tables/Datatable";
import Button from "../../../../components/ui/Button";
import Tab from "../../../../components/ui/Tab";
import CampaignPlatformObjectiveDropdown from "../../../../components/ui/dropdown/CampaignPlatformObjectiveDropdown";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";


// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { RequiredField } from "../../../../constants/constants";
import CheckIcon from "../../../../assets/icons/CheckIcon";
import { setAdminCreateBucketCampaignObjectivesFilter } from "../../../../store/admin/campaignAdminSlice";
import CrossIcon from "../../../../assets/icons/CrossIcon";
import NewCampaignList from "../campaignLists/newCampaignList";
import useApi from "../../../../hooks/useApi";
import CheckBoxDatatable from "../../../../components/ui/tables/CheckboxDatatable";
import Alert from "../../../../components/ui/alert/Alert";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import FullPageExportContext from "../../../../store/fullPageExportContext";

const NewEditBucket = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const { state, pathname } = useLocation();
  const splitUrl = pathname.split('/');
  const id = splitUrl[4]


  const { setFullPageExport, setIsExportDisabled, setExportPageName } = useContext(FullPageExportContext);

  const getSingleBucket = useApi()
  const addRemoveCampaignsAPI = useApi()
  const deleteBucketAPI = useApi()

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);
  const selectedCampaignIdandPlatform = useSelector((state) => state.campaignAdmin.adminBucketPlatformandObjective);

  let selectedIdandPlatform = null;
  if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
    selectedIdandPlatform = null;
  } else {
    selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
  }

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }



  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // SELECTED OBJECTIVES FROM DROPDOWN
  const objectiveFilter = useSelector((state) => state.campaignAdmin.adminCreateBucketCampaignObjectivesFilter);

  // SELECTED CAMPAIGN IN BUCKET
  const [bucketCampigns, setBucketCampigns] = useState([]);

  // FILTER ACTIVE OR NOT
  const [isBucketFilterActive, setBucketFilterActive] = useState(false);

  const [isClearButtonClicked, setClearButtonClicked] = useState(false);
  useEffect(() => {
    setClearButtonClicked(false);
  }, [isClearButtonClicked]);


  const [isCampaignClearButtonClicked, setCampaignClearButtonClicked] = useState(false);
  useEffect(() => {
    setCampaignClearButtonClicked(false);
  }, [isCampaignClearButtonClicked]);


  const [isSaveEditButtonDisabled, setSaveEditButtonDisabled] = useState(true);
  const [isSaveBucketButtonDisabled, setSaveBucketButtonDisabled] = useState(false);

  // BUCKET NAME
  const [bucketName, setBucketName] = useState(state !== null ? state.name : "");
  const [bucketNameOriginal, setBucketNameOriginal] = useState("");
  const [bucketNameIsTouched, setBucketNameIsTouched] = useState(false);
  const [bucketNameIsValid, setBucketNameIsValid] = useState(false);
  const [bucketNameError, setBucketNameError] = useState();

  // BUCKET DESCRIPTION
  const [bucketDescription, setBucketDescription] = useState("");
  const [bucketDescriptionOriginal, setBucketDescriptionOriginal] = useState("");
  const [bucketDescriptionIsTouched, setBucketDescriptionIsTouched] = useState(false);
  const [bucketDescriptionIsValid, setBucketDescriptionIsValid] = useState(false);
  const [bucketDescriptionError, setBucketDescriptionError] = useState();


  // CAMPAIGNS LIST
  const [selectedCampaignList, setSelectedCampaignList] = useState(state === null ? [] : state.items === null ? [] : state.items);
  const [selectedCampaignsToRemove, setSelectedCampaignsToRemove] = useState([]);


  const [showDeleteButton, setshowDeleteButton] = useState(false)


  const [selectedTab, setSelectedTab] = useState("Selected Campaigns");



  const tabData = ["Selected Campaigns", "All Campaigns"];

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    setSelectedCampaignsToRemove([]);
    setBucketCampigns([]);
  };


  const campaignListTableHeaders = [
    { name: "Campaign Name", field: "platform_campaign_name" },
    { name: "Brand Name", field: "brand_name" },
    { name: "Campaign Bucket", field: "bucket" },
    { name: "Objective", field: "objective" },
    { name: "Platform", field: "platform" },
    { name: "Start Date", field: "start_date" },
    { name: "End Date", field: "end_date" },
  ];

  const [alertType, setAlertType] = useState("");
  const [alertTypeValue, setAlertTypeValue] = useState(0);

  const bucketNameInputIsInvalid = !bucketNameIsValid && bucketNameIsTouched;
  const bucketNameInputClasses = bucketNameInputIsInvalid ? "form_control invalid" : "form_control";

  const bucketDescriptionInputIsInvalid = !bucketDescriptionIsValid && bucketDescriptionIsTouched;
  const bucketDescriptionInputClasses = bucketDescriptionInputIsInvalid ? "form_control invalid" : "form_control";


  const bucketNameInputBlurHandler = (event) => {
    setBucketNameIsTouched(true);
    if (event.target.value.length === 0) {
      setSaveEditButtonDisabled(true);
      setBucketNameIsValid(false);
      setBucketNameError(RequiredField);
    } else {
      setSaveEditButtonDisabled(false);
      setBucketNameIsValid(true);
      setBucketNameError(null);
    }
  }


  const bucketNameInputChangeHandler = (event) => {
    setBucketName(event.target.value);
  };


  const [singleBucketData, setSingleBucketData] = useState([])


  const getSingleBucketHandler = async () => {

    try {

      await getSingleBucket.fetchData(`performance/google/bucket/${state?.id ?? id}`)

    } catch (error) {
      console.log('Get Single Bucket', error)

    }
  }

  useEffect(() => {

    if (getSingleBucket.apiStatus.isLoaded) {
      if (getSingleBucket && getSingleBucket.data.length !== 0) {

        setSingleBucketData(getSingleBucket?.data[0]?.items ?? [])
        setBucketName(getSingleBucket?.data[0]?.name ?? '')
        setBucketNameOriginal(getSingleBucket?.data[0]?.name ?? '')

        setBucketDescription(getSingleBucket?.data[0]?.description ?? '')
        setBucketDescriptionOriginal(getSingleBucket?.data[0]?.description ?? '')

        if (
          getSingleBucket?.data[0]?.items === undefined ||
          getSingleBucket?.data[0]?.items.length === 0 ||
          getSingleBucket?.data[0]?.items === null
        ) {
          setshowDeleteButton(true)
        }
      }
    }

  }, [getSingleBucket.apiStatus, getSingleBucket.data])





  useEffect(() => {
    getSingleBucketHandler();
  }, [selectedDates, objectiveFilter,])



  const deleteOnClickHandler = async () => {

    try {

      await deleteBucketAPI.deleteData(`performance/google/bucket/${state?.id ?? id}`).then(() => {
        setAlertType('Admin Delete')
        setAlertTypeValue(selectedCampaigns.length)

        setTimeout(() => {
          navigate('/admin/campaign')
        }, 2000)
      })

    } catch (error) {

      console.log('Delete Bucket API Error', error)

    }
  };

  const [selectedCampaigns, setSelectedCampaigns] = useState([])

  const onCheckboxRemoveCampaignHandler = (data) => {

    if (data.length === singleBucketData.length) {
      setshowDeleteButton(true)
    } else {
      setshowDeleteButton(false)
    }

    setSelectedCampaigns(data ?? [])

  }



  const [campaignsObj, setcampaignsObj] = useState({})

  const HandleCheckboxClick = (data, id) => {

    let checkedData = [...data];
    let obj = {};

    checkedData.forEach((item) => {

      if (obj[item.platform]) {
        obj[item.platform].push(item[id])
      } else {
        obj[item.platform] = new Array(item[id])
      }
    })

    setcampaignsObj(obj)
  }


  const AddItemsBucket = async () => {

    setSaveBucketButtonDisabled(true);
    try {
      const configs = {
        'name': bucketName,
        'description': bucketDescription,
        'add_items': campaignsObj
      }

      await addRemoveCampaignsAPI.patchRawJSONData(`performance/google/bucket/${state?.id ?? id}`, JSON.stringify(configs))
        .then(() => {
          setAlertType('Campaigns Updated')
          setAlertTypeValue(selectedCampaigns.length)

          setTimeout(() => {
            navigate('/admin/campaign')
          }, 2000)
        })
        .catch(() => {

          setAlertType('Campaigns Updated')
          setAlertTypeValue('Other Value' + Math.floor(Math.random() * 1000000));

          setTimeout(() => {
            setSaveBucketButtonDisabled(false);
          }, 4200)

          setAddBucketButtonName('Update Bucket');
          setEditBucketButtonName('Update Bucket');

          throw new Error('An error ocurred');
        })

    } catch (error) {
      console.log(error)
    }


  }

  const [editBucketButtonName, setEditBucketButtonName] = useState("Remove Campaigns from Bucket");
  const [addBucketButtonName, setAddBucketButtonName] = useState("Add to Existing Bucket");


  useEffect(() => {

    if (bucketName === bucketNameOriginal && bucketDescription === bucketNameOriginal) {
      if (addRemoveCampaignsAPI.apiStatus.isLoading) {
        setEditBucketButtonName('Removing Campaigns from Bucket');
        setAddBucketButtonName('Adding to Existing Bucket')
      } else if (addRemoveCampaignsAPI.apiStatus.isLoaded) {
        setEditBucketButtonName('Removed Campaigns from')
        setAddBucketButtonName('Added to Existing Bucket')
      }
    } else {
      if (addRemoveCampaignsAPI.apiStatus.isLoading) {
        setEditBucketButtonName('Updating Bucket')
        setAddBucketButtonName('Updating Bucket')
      } else if (addRemoveCampaignsAPI.apiStatus.isLoaded) {
        setEditBucketButtonName('Updated Bucket')
        setAddBucketButtonName('Updated Bucket')
      }
    }




  }, [addRemoveCampaignsAPI.apiStatus])


  const bucketNameCompare = bucketName.trim() !== bucketNameOriginal.trim() || bucketDescription.trim() !== bucketDescriptionOriginal.trim()

  useEffect(() => {
    setSaveBucketButtonDisabled(false)
    if (bucketNameCompare) {
      setEditBucketButtonName('Update Bucket');
      setAddBucketButtonName('Update Bucket')
    } else {
      setAddBucketButtonName('Add to Existing Bucket')
      setEditBucketButtonName('Remove Campaigns from Bucket')
    }
  }, [bucketName, bucketDescription])




  const RemoveItemsBucket = async () => {
    setSaveBucketButtonDisabled(true);

    try {

      const configs = {
        'name': bucketName,
        'description': bucketDescription,
        'remove_items': campaignsObj
      }

      await addRemoveCampaignsAPI.patchRawJSONData(`performance/google/bucket/${state?.id ?? id}`, JSON.stringify(configs))
        .then(() => {

          if (bucketName !== bucketNameOriginal || bucketDescription !== bucketDescriptionOriginal) {
            setAlertType('Campaigns Updated')
            setAlertTypeValue(selectedCampaigns.length)
          } else {
            setAlertType('Campaigns Removed')
            setAlertTypeValue(selectedCampaigns.length)
          }


          setTimeout(() => {
            navigate('/admin/campaign')
          }, 2000)

        })
        .catch(() => {

          setAlertType('Campaigns Updated')
          setAlertTypeValue('Other Value' + Math.floor(Math.random() * 1000000));

          setTimeout(() => {
            setSaveBucketButtonDisabled(false);
          }, 4200)

          setAddBucketButtonName('Update Bucket');
          setEditBucketButtonName('Update Bucket');

          throw new Error('An error ocurred');
        })


    } catch (error) {
      console.log(error)
    }
  }

  let selectedCampaignsListTable = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>
  if (getSingleBucket.apiStatus.isLoaded && !getSingleBucket.apiStatus.isLoading) {


    selectedCampaignsListTable = (
      <Wrapper>
        <ErrorBoundary chartType="tableChartType">
          <CheckBoxDatatable
            tableTitle={"Select campaigns to remove from bucket"}
            tableHeader={campaignListTableHeaders}
            tableData={singleBucketData}
            tableLength={10}
            isFooterShow={true}
            searchPlaceHolder="Search Campaign"
            checkbox={true}
            onCheckboxClickHandler={(data) => {
              onCheckboxRemoveCampaignHandler(data)
              HandleCheckboxClick(data, 'platform_campaign_id')
            }}
            dynamicCheckboxRef={selectedCampaigns}
            tableExportDropdown={
              <ExportDropdown
                sectionId={'selected-campaigns'}
                sectionName={'Selected Campaigns'}
                sectionData={singleBucketData}
              />
            }
            hasTableLenghtDropdown={true}
            clearSelectedItems={isClearButtonClicked}
          />
        </ErrorBoundary>
      </Wrapper>
    );
  }

  if (getSingleBucket.apiStatus.error) {
    selectedCampaignsListTable = <ServerErrorsLoader chartType="tableChartType" error={getSingleBucket.apiStatus.error} />
  }

  if (getSingleBucket.apiStatus.isLoading) {
    selectedCampaignsListTable = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>
  }


  useEffect(() => {

    setExportPageName('Edit Bucket')
    setFullPageExport(singleBucketData ?? [])
  }, [getSingleBucket.data]);

  useEffect(() => {
    if (getSingleBucket.apiStatus.error !== null ? true : getSingleBucket.apiStatus.isLoaded && (getSingleBucket.apiStatus.isLoading === false)) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [getSingleBucket.apiStatus])


  let editBucketInfoText = selectedCampaigns.length + " campaigns selected to remove from the bucket"

  return (
    <Wrapper>
      <div className="content">
        <div className="content_section">
          <div className="flex justify_between align_center">
            <PageHeader
              pageTitle={"Edit " + " Bucket"}
            />
          </div>
          <div className="page_content">

            <Alert
              alertType={alertType}
              alertTypeValue={alertTypeValue}
              bucketName={bucketName}
              editBucketerror={addRemoveCampaignsAPI.apiStatus.error}

            />
            <div className="grid grid_cols_3">
              <div className="grid grid_span_2 grid_margin_bottom">
                <div className="form_group flex">
                  <div className="mr_20">
                    <input
                      className={bucketNameInputClasses}
                      type="text"
                      name="bucket_name"
                      id="bucket_name"
                      placeholder="Enter Bucket Name"
                      value={bucketName ?? ''}
                      onChange={bucketNameInputChangeHandler}
                      onBlur={bucketNameInputBlurHandler}
                    />
                    <div className="error_section">
                      {bucketNameInputIsInvalid && (
                        <h6 className="error_text m_t_5">{bucketNameError}</h6>
                      )}
                    </div>
                  </div>
                  <div>
                    <input
                      className={bucketDescriptionInputClasses}
                      type="text"
                      name="bucket_desc"
                      id="bucket_desc"
                      placeholder="Enter Description"
                      maxLength={100}
                      defaultValue={bucketDescription ?? ''}
                      value={bucketDescription ?? ''}
                      onChange={(e) => setBucketDescription(e.target.value)}
                    />
                    <div className="error_section">
                      {bucketDescription?.length === 100 && (
                        <h6 className="error_text m_t_5">Character Limit Reached!</h6>
                      )}
                    </div>
                  </div>

                </div>
              </div>
              <div className="grid grid_span_1 grid_margin_bottom">
                <div className="flex start top_nav_options">
                  <CampaignPlatformObjectiveDropdown
                    isFilterActive={isBucketFilterActive}
                    filterCount={objectiveFilter === undefined ? 0 : objectiveFilter.length}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid_margin_bottom">
              <Tab
                varient={"primary"}
                tabData={tabData}
                activeTab={selectedTab}
                handleCallback={(event) => handleCallback(event)}
              />
            </div>
            {selectedTab === "Selected Campaigns" && (
              <Wrapper>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    RemoveItemsBucket()
                  }}
                >
                  <div className="grid_margin_bottom">
                    {selectedCampaignsListTable}
                  </div>
                  <div className="grid grid_cols_4 flex row justify_between">
                    <div className="grid col_span_2">
                      {selectedCampaigns.length !== 0 &&
                        <h4 className="info_text">
                          {editBucketInfoText}
                        </h4>
                      }
                    </div>
                    <div className="grid col_span_2 form_actions flex row justify_end">
                      {showDeleteButton === true ? (
                        <div className="btn_wrapper p_10">
                          <Button
                            // disabled={isSaveEditButtonDisabled}
                            handleClick={deleteOnClickHandler}
                            className="secondary_danger_btn"
                            type="submit"
                          >
                            {singleBucketData.length === 0 ? 'Delete' : "Update and and Delete Bucket"}
                          </Button>
                        </div>
                      ) : (
                        <>
                          <div className="btn_wrapper p_10">
                            <Button
                              handleClick={() => navigate("/admin/campaign/")}
                              className="secondary_danger_btn"
                              type="button"
                              disabled={isSaveBucketButtonDisabled}
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="btn_wrapper p_10">
                            <Button
                              // handleClick={() => onClickClearButtonHandler()}
                              handleClick={() => setClearButtonClicked(true)}
                              className="secondary_btn"
                              type="button"
                            >
                              Clear Selected
                            </Button>
                          </div>
                          <div className="btn_wrapper p_10">
                            <Button
                              disabled={
                                (selectedCampaigns.length == 0 &&
                                  bucketName === bucketNameOriginal &&
                                  bucketDescription === bucketDescriptionOriginal) ||
                                  isSaveBucketButtonDisabled ? true : false}
                              className="primary_btn"
                              type="submit"
                            >
                              {editBucketButtonName}
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </form>
              </Wrapper>
            )}
            {selectedTab === "All Campaigns" && (
              <Wrapper>
                <form onSubmit={(e) => {
                  e.preventDefault();
                  AddItemsBucket()
                }}>
                  <div className="grid_margin_bottom">
                    <NewCampaignList

                      title={'Select campaigns to add in bucket'}
                      isNonDynamic={true}
                      hasCheckbox={true}
                      HandleCheckboxClick={(data) => {
                        HandleCheckboxClick(data, 'campaign_id')
                        setSelectedCampaigns(data)
                      }}
                      excludeData={singleBucketData?.map((item) => (item.platform_campaign_id)) ?? []}
                      isCleared={isCampaignClearButtonClicked}

                    />
                  </div>
                  <div className="grid grid_cols_4 flex row justify_between">
                    <div className="grid col_span_2">
                      {selectedCampaigns.length !== 0 &&
                        <h4 className="info_text">
                          {selectedCampaigns.length + " campaigns selected to add in the bucket"}
                        </h4>
                      }
                    </div>
                    <div className="grid col_span_2 form_actions flex row justify_end">
                      <div className="btn_wrapper p_10">
                        <Button
                          className="secondary_danger_btn"
                          type="button"
                          handleClick={() => navigate("/admin/campaign/")}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="btn_wrapper p_10">
                        <Button
                          handleClick={() => setCampaignClearButtonClicked(true)}
                          className="secondary_btn"
                          type="button"
                        >
                          Clear Selected
                        </Button>
                      </div>
                      <div className="btn_wrapper p_10">
                        <Button
                          disabled={
                            (selectedCampaigns.length == 0 &&
                              bucketName === bucketNameOriginal &&
                              bucketDescription === bucketDescriptionOriginal) ||
                              isSaveBucketButtonDisabled ? true : false}
                          className="primary_btn"
                          type="submit">
                          {addBucketButtonName}
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </Wrapper>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default NewEditBucket;
