import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// CONSTANTS
import { USER_COUNTRY } from "../constants/constants";
import { CountryCode, CountryCurrency } from "../constants/countryCurrency";
import { useEffect } from "react";
import { useState } from "react";

// const NumberSeparator = ({ title, number, platform }) => {
//   if (title === undefined || title === null) {
//     title = "default"
//   }

//   if (title === undefined || title === null) {
//     title = "default"
//   }

//   // USER DATA
//   const userData = useSelector((state) => state.userData.user[0]);

//   let userCountryCode = userData?.country_code ?? "IN"

//   if (number === "N/A") {
//     return number
//   }

//   let numberValue = Number(number);

//   if (!title) {
//     title = "";
//   }


//   const unformattedNumberMetrics = ["ad id", 'post id', "id", 'creative id', "insertion order id"];

//   if (unformattedNumberMetrics.includes(title.toLowerCase())) {
//     return number;
//   }

//   let variant = "number";

//   const tonnesMetrics = ["monthly sales vol."]
//   if (tonnesMetrics.includes(title.toLowerCase())) {
//     variant = "tonnes";
//   }

//   // PERCENTAGE METRICS ARRAY
//   const percentageMetrics = [
//     "hsm share",
//     "prime time share",
//     "engagement rate",
//     "cr",
//     "ctr",
//     "market share",
//     "bounce rate (%)",
//     "bounce rate",
//     "goal conversion rate",
//     "view rate",
//     "sales vol. change",
//     "sales val. change",
//     "change",
//     "reach rate",
//     "vtr",
//     "cvtr",
//     "conversion rate",
//     "complete view rate",
//     "visits % change",
//     "% total visit",
//     "% of tv spends",
//     "% of tv impressions",
//     "% of all digital spends",
//     "% of all digital impressions",
//     "% of digital meta spends",
//     "% of digital meta impressions",
//     "% of digital google spends",
//     "% of digital google impressions",
//     "% of tv + digital spends",
//     "% of tv + digital impressions",
//     "premium share",
//     'top impression rate',
//     'abs top impression rate',
//   ];
//   if (percentageMetrics.includes(title.toLowerCase())) {
//     variant = "percentage";
//   }

//   // CURRENCY METRICS ARRAY
//   const currencyMetrics = [
//     "spends",
//     "spend",
//     "cpc",
//     "cpm",
//     "cprp",
//     "clicks / conversion",
//     "sales value",
//     "sales",
//     "tv spends",
//     'digital spends',
//     "all digital spends",
//     "digital meta spends",
//     "digital google spends",
//     "tv + digital spends",
//   ];
//   if (currencyMetrics.includes(title.toLowerCase())) {
//     variant = "money";
//   }

//   // TIME METRICS ARRAY
//   const timeMetrics = ["Avg Duration"];
//   if (timeMetrics.includes(title.toLowerCase())) {
//     variant = "time";
//   }

//   let country = {}

//   CountryCode.map((data) => {
//     if (data.countryCode === userCountryCode) {
//       country = data
//     }
//   })

//   if (numberValue === 0) {
//     numberValue = 0;
//   }

//   if (isNaN(numberValue)) {
//     numberValue = 0;
//   }

//   if (!isFinite(numberValue)) {
//     numberValue = 0;
//   }

//   if (country.countryCode === "USA") {
//     numberValue = numberValue.toLocaleString("en-US", { maximumFractionDigits: 2 });
//   } else {
//     numberValue = numberValue.toLocaleString('en-IN', { maximumFractionDigits: 2 });
//   }

//   if (variant === "money") {
//     if (platform === "Twitter") {
//       numberValue =
//         country.alternateCurrencySymbol + numberValue
//     } else {
//       numberValue =
//         country.currencySymbol + numberValue
//     }
//   } else if (variant === "percentage") {
//     numberValue = numberValue + "%";
//   } else if (variant === "time") {
//     numberValue = numberValue + "s";
//   } else if (variant === "tonnes") {
//     numberValue = numberValue + " T";
//   } else if (variant === "liquid") {
//     numberValue = numberValue + "Lit";
//   } else {
//     numberValue = numberValue;
//   }

//   return numberValue;
// };

const NumberSeparator = ({ title, number, platform, fixedSpend }) => {

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  const genericUnformatted = ['adset id', 'id', 'io id', 'creative id', 'insertion order id'];


  let userCountryCode = userData?.country_code ?? "IN"
  const [formattedNumber, setFormattedNumber] = useState(0)


  useEffect(() => {
    let country = {}

    CountryCode.map((data) => {
      if (data.countryCode === userCountryCode) {
        country = data
      }
    })

    if (number === "N/A") {
      return number
    }

    let numberValue = Number(number);

    if (!title) {
      title = "";
    }


    const genericCurrency = ['spend', 'cpm', 'value', 'cprp', 'cpc'];
    const genericPercent = ['%', 'change', 'rate'];
    const genericTime = ['duration'];
    const genericTonnes = ['monthly sales vol.'];
    const edgeCasesArray = ['benchmark cpm', 'benchmark cpv', 'benchmark cpc', 'cpcv'];

    if (fixedSpend === true) {

      genericCurrency.map((str) => {
        if (title.toLowerCase().includes(str)) {
          if (country.countryCode === "USA") {
            numberValue = numberValue.toLocaleString("en-US", { maximumFractionDigits: 0 });
          } else {
            numberValue = numberValue.toLocaleString('en-IN', { maximumFractionDigits: 0 });
          }
        } else {
          if (country.countryCode === "USA") {
            numberValue = numberValue.toLocaleString("en-US", { maximumFractionDigits: 2 });
          } else {
            numberValue = numberValue.toLocaleString('en-IN', { maximumFractionDigits: 2 });
          }
        }
      })

    } else {

      if (country.countryCode === "USA") {
        numberValue = numberValue.toLocaleString("en-US", { maximumFractionDigits: 2 });
      } else {
        numberValue = numberValue.toLocaleString('en-IN', { maximumFractionDigits: 2 });
      }

    }




    // genericUnformatted.map((str) => {
    //   if (title.toLowerCase().includes(str)) {
    //     return number;
    //   }
    // })

    genericTime.map((str) => {
      if (title.toLowerCase().includes(str)) {
        numberValue = numberValue + "s";
      }
    })

    genericPercent.map((str) => {
      if (title.toLowerCase().includes(str) && !numberValue.includes('%')) {
        numberValue = numberValue + "%";
      }
    })

    genericCurrency.map((str) => {
      if (title.toLowerCase().includes(str) && !numberValue.includes('%') && !numberValue.includes(country.currencySymbol)) {

        // if (fixedSpend === true) {
        //   if (platform === 'Twitter') {
        //     numberValue = country.alternateCurrencySymbol + Math.trunc(numberValue);

        //   } else {

        //     numberValue = country.currencySymbol + Math.trunc(numberValue);
        //   }
        // } else {

        if (platform === 'Twitter') {
          numberValue = country.alternateCurrencySymbol + numberValue;

        } else {
          numberValue = country.currencySymbol + numberValue;
        }
        // }

      }
    })

    genericTonnes.map((str) => {
      if (title.toLowerCase().includes(str) && !numberValue.includes('%')) {
        numberValue = numberValue + " T";
      }
    })


    edgeCasesArray.map((str) => {
      if (title.toLowerCase().includes(str)) {
        numberValue = number
      }
    })


    setFormattedNumber(numberValue)

  }, [title, number])


  if (genericUnformatted.some((str) => str === title.toLowerCase())) {
    return number;
  }

  return formattedNumber;

};


NumberSeparator.propTypes = {
  number: PropTypes.number,
  title: PropTypes.string,
  platform: PropTypes.string,
  fixedSpend: PropTypes.bool,
};
export default NumberSeparator;
