import { useContext, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Loader from "../../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import Slider from "../../../../components/ui/Slider";
import AuthContext from "../../../../store/authContext";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import axios from "../../../../components/helpers/axios";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { useNavigate } from "react-router-dom";
import FullPageExportContext from "../../../../store/fullPageExportContext";
import useApi from "../../../../hooks/useApi";

const NewBucketLists = ({ setAlertType, setAlertTypeValue, setAlertProps }) => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const bucketMetricCardAPI = useApi()
  const deleteBucketAPI = useApi()

  const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // BUCKET CARDS
  const [metricCards, setMetricCards] = useState([]);

  const [searchTerm, setSeachTerm] = useState("");


  // FILTER BUCKET LIST AS PER USER SEARCH
  const filteredBucketList = useMemo(() => {
    if (searchTerm === "") return metricCards;
    if (metricCards === "") return metricCards;

    const filteredList = metricCards.filter((bucket) => {
      return bucket.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (typeof bucket.start_date === 'string' && bucket.start_date?.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (typeof bucket.end_date === 'string' && bucket.end_date?.toLowerCase().includes(searchTerm.toLowerCase()))
        || bucket.platform.includes(searchTerm.toLowerCase())
    });
    return filteredList;
  }, [searchTerm, metricCards]);


  // DELETE BUCKET
  const [deleteBucketDone, setDeleteBucketDone] = useState(null);

  const editOnClickHandler = async (bucket) => {
    navigate("/admin/campaign/edit-bucket/" + bucket.id, { state: bucket });
  };

  // DELETE BUCKET
  const deleteOnClickHandler = async (bucket) => {

    try {

      await deleteBucketAPI.deleteData(`performance/google/bucket/${bucket?.id ?? ''}`)
        .then(() => {
          setSeachTerm("")
          fetchMetricCardsHandler()

          setAlertProps({
            type: 'Admin Delete',
            name: bucket.name
          })
        })
        .catch((err) => { throw new Error(err) })

    } catch (error) {

      console.log('Delete Bucket API Error', error)

    }
  };

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS



  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {

    try {

      const params = {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      }

      await bucketMetricCardAPI.fetchData('performance/google/bucket-campaign-list/', params)

    } catch (error) {

      console.log('Metric Card Error', error)
    }


  };


  useEffect(() => {

    if (bucketMetricCardAPI.apiStatus.isLoaded) {
      if (!NoDataAvailableChecker(bucketMetricCardAPI.data)) {
        setMetricCards(bucketMetricCardAPI.data)
      }
    }

  }, [bucketMetricCardAPI])


  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" />
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (bucketMetricCardAPI.apiStatus.isLoaded && !bucketMetricCardAPI.apiStatus.isLoading) {
    if (NoDataAvailableChecker(bucketMetricCardAPI.data)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType={"cardChartType"} />
          </div>
        </Wrapper>
      );
    } else {

      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider
              sliderWrapperClass={"tab_card_slider"}
              showSliderHeader={false}
              showNavigation={false}
              sliderTitle={""}
              slidestoshow={3}
              breakpoints={{
                0: {
                  slidesPerView: "auto",
                },
                426: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
                1300: {
                  slidesPerView: 4,
                },
                2100: {
                  slidesPerView: 5,
                },
              }}
            >
              {filteredBucketList.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    key={index}
                    cardClass={"bucketListCard"}
                    variant={"AdminBucketList"}
                    camapaignBuckeData={data}
                    onEditButtonClick={editOnClickHandler}
                    onDeleteButtonClick={deleteOnClickHandler}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  if (NoDataAvailableChecker(filteredBucketList) && searchTerm.length !== 0) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid col_span_4 grid_margin_bottom">
          <NoDataAvailableLoader chartType={"cardChartType"} />
        </div>
      </Wrapper>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (bucketMetricCardAPI.apiStatus.error) {
    metricCardsSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="cardChartType"
          error={bucketMetricCardAPI.apiStatus.error}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (bucketMetricCardAPI.apiStatus.isLoading) {
    metricCardsSection = (
      <Wrapper>
        <Loader loaderType="cardLoader" />
      </Wrapper>
    );
  }

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
  }, [selectedDates]);

  // useEffect(() => {
  //   deleteBucketDone === "DELETED" && fetchMetricCardsHandler();
  // }, [deleteBucketDone]);

  useEffect(() => {
    setFullPageExport({ "AdminBucketCard_Admin Bucket List": bucketMetricCardAPI.data })
  }, [bucketMetricCardAPI.data]);

  useEffect(() => {
    if (bucketMetricCardAPI.apiStatus.error !== null ? true : bucketMetricCardAPI.apiStatus.isLoaded && (bucketMetricCardAPI.apiStatus.isLoading === false)) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [bucketMetricCardAPI.apiStatus])


  return (
    <Wrapper>
      <div className="flex column">
        <div style={{ "marginBottom": "20px" }}>
          <input
            className="form_control input"
            type="text"
            autoComplete="off"
            placeholder="Search Bucket"
            onChange={(event) => {
              setSeachTerm(event.target.value);
            }}
          />
        </div>
        <div className="card_wrapper">{metricCardsSection}</div>
        {(metricCards.length !== 0 && bucketMetricCardAPI.apiStatus.isLoaded) &&
          <div className="grid grid_cols_4 flex row justify_between" style={{ "marginTop": "10px" }}>
            <div className="grid col_span_2">
              <h4 className="info_text">
                Showing {filteredBucketList.length} from {metricCards.length}
              </h4>
            </div>
          </div>
        }
      </div>
    </Wrapper>
  );
};
export default NewBucketLists;
