const ExpandIcon = (props) => {
    return (
        <svg className={props.className} width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Arrow / Expand">
                <path id="Vector" d="M10 19H5V14M14 5H19V10" stroke="#ccc" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>
    );
};

export default ExpandIcon;
