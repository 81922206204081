import posthog from 'posthog-js';

// TO TRACK HOW USER IS LOGGED IN THE SYSTEM
// BY EMAIL PASSWORD
// BY SSO
// BY 2FA
export const trackLogin = (userData, loginMethod) => {

    // LOGIN METHOD CAN BE AS FOLLOWS
    //// EMAIL PASSWORD
    //// SSO
    //// 2FA

    if (!loginMethod) {
        loginMethod = "password"
    }

    posthog.capture('Login', {
        distinct_id: userData.id,
        email: userData.email,
        login_method: loginMethod,
    });

    posthog.identify(userData.id, {
        email: userData.email,
        userType: userData.user_type, // TO CHECK IF THE USER IS USER OR ADMIN
    });
}

export const trackLogout = (userData) => {
    // Ensure userData is provided
    if (!userData || !userData.id) {
        console.warn('No user data provided for logout tracking.');
        return;
    }

    // Capture the logout event
    posthog.capture('Logout', {
        distinct_id: userData.id,
        email: userData.email,
        userType: userData.user_type, // Check if the user is a user or admin
        timestamp: new Date().toISOString(), // Optional: track the time of logout
    });

    // Optionally, you can also identify the user as logged out (if applicable)
    posthog.identify(userData.id, {
        email: userData.email,
        userType: userData.user_type, // TO CHECK IF THE USER IS USER OR ADMIN
        loggedIn: false // Optional: to indicate the user is no longer logged in
    });
}
