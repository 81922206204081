import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { COKE_COMPANY_ID, IMPRIVATA_COMPANY_ID } from "../constants/constants";
import { CountryCode } from "../constants/countryCurrency";
import Wrapper from "../components/helpers/Wrapper";

const NumberFormatter = ({ platform, title, variant, number }) => {
  const [formattedNumber, setFormattedNumber] = useState(number);

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  let companyID = userData?.company?.id ?? "";

  let userCountryCode = userData?.country_code ?? "IN";

  let country = {};

  CountryCode.map((data) => {
    if (data.countryCode === userCountryCode) {
      country = data;
    }
  });

  useEffect(() => {
    if (typeof number === "string" && number != undefined) {
      var parsedNumber = parseFloat(number);
      if (!isNaN(parsedNumber)) {
        number = parsedNumber; // Convert to number if it's a valid number
      } else {
        // If not a valid number, keep it as a string
        return (number = number);
      }
    } else if (number === "" || number === undefined || number === null) {
      return (number = 0);
    }

    let formatted = formatNumber(number);

    if (variant === "money") {
      if (formatted === "0.00") {
        setFormattedNumber(country.currencySymbol + formatted + "*");
        /*  setFormattedNumber(formatted + "*"); */
      } else {
        setFormattedNumber(country.currencySymbol + formatted);
        /*  setFormattedNumber(formatted); */
      }
    } else {
      if (formatted === "0.00") {
        setFormattedNumber(formatted + "*");
      } else {
        setFormattedNumber(formatted);
      }
    }
  }, [number]);

  if (typeof number === "string") {
    // Check if the string is not empty, undefined, or null
    if (number !== "" && number !== undefined && number !== null) {
      var parsedNumber = parseFloat(number);
      if (!isNaN(parsedNumber)) {
        number = parsedNumber; // Convert to number if it's a valid number
      } else {
        // If not a valid number, keep it as a string
        number = number;
      }
    } else {
      // If the string is empty, undefined, or null, keep it as is
      number = 0;
    }
  }

  if (number == null) {
    number = "N/A";
  }

  if (title === undefined || title === null) {
    title = "default";
  }

  if (typeof number === "string") return number;

  // CURRENCY METRICS ARRAY
  const currencyMetrics = [
    "ecomm spends",
    "Spends",
    "spends",
    "spend",
    "cpc",
    "cpm",
    "cprp",
    "clicks / conversion",
    "cost per video view",
    "cost per view",
    "total media cost (advertiser currency)",
    "cost per complete view",
    "tv spends",
    "digital spends: meta",
    "digital spends: dv360",
    "digital spends",
    "digital meta spends",
    "digital dv360 spends",
    "digital google spends",
    "tv cprp",
    "meta spend",
    "google spend",
    "total spends",
    "total spend",
  ];

  // PERCENTAGE METRICS ARRAY
  const percentageMetrics = [
    "market share percent",
    "market share",
    "ctr",
    "engagement rate",
    "hsm share",
    "prime time share",
    "engagement rate (%)",
    "cr (%)",
    "ctr (%)",
    "average view percentage",
    "reach rate",
    "share of voice",
    "view rate",
    "vtr",
    "cvtr",
    "complete view rate",
    "post click conversion rate",
    "video view rate",
    "tv sov",
    "tv soe",
    "soe",
    "engagment rate: meta",
    "engagement rate: meta",
    "engagment rate: dv360",
    "engagement rate: dv360",
    "ctr: meta",
    "ctr: dv360",
    "view through rate: meta",
    "view through rate: dv360",
    "monthly sales vol. % change",
    "tv spends % change",
    "sov",
    "bounce rate",
    "meta engagement rate",
    "meta ctr",
    "google ctr",
    "meta vtr",
    "google vtr",
    "meta organic er",
    "meta paid er",
    'top impression rate',
    'abs top impression rate',
    'conversion rate',
  ];

  // TIME METRICS ARRAY
  const timeMetrics = ["avg duration", "avg time viewed(in mins)"];

  const viewsMetrics = ["average view duration"];
  const tonnesMetrics = ["monthly sales vol."];

  let inputNumber;

  if (number === undefined) return 0;
  if (number === "blank") return "";

  if (viewsMetrics.includes(title.toLowerCase())) {
    inputNumber = number;
  } else {
    inputNumber = Number(number);

    if (inputNumber === 0) {
      inputNumber = 0;
    } else if (isNaN(inputNumber)) {
      inputNumber = 0;
    } else if (!isFinite(inputNumber)) {
      inputNumber = 0;
    } else if (inputNumber === undefined || inputNumber === null) {
      inputNumber = 0;
    } else {
      inputNumber = inputNumber;
    }
  }

  if (!variant || title === undefined) {
    variant = "number";
  }

  if (percentageMetrics.includes(title.toLowerCase())) {
    variant = "percentage";
  } else if (currencyMetrics.includes(title.toLowerCase())) {
    variant = "money";
  } else if (timeMetrics.includes(title.toLowerCase())) {
    variant = "time";
  } else if (viewsMetrics.includes(title.toLowerCase())) {
    variant = "views";
  } else if (tonnesMetrics.includes(title.toLowerCase())) {
    variant = "tonnes";
  } else {
    variant = "number";
  }

  function formatNumber(inputNumber) {
    if (variant === "money") {
      if (
        (variant === "money" && companyID === COKE_COMPANY_ID) ||
        companyID === IMPRIVATA_COMPANY_ID ||
        (variant === "money" && platform === "Twitter")
      ) {
        if (
          inputNumber % 1 !== 0 &&
          inputNumber.toString().split(".")[1].length === 1 &&
          inputNumber.toString().split(".")[0].length === 1
        ) {
          // Check if the number has 3 digits before decimal and 1 decimal digit, return as string
          return inputNumber.toString();
        } else if (inputNumber >= 1000000000000) {
          // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
          if ((inputNumber / 1000000000000).toFixed(0).length >= 3) {
            return parseFloat((inputNumber / 1000000000000).toFixed(1)) + "T";
          } else {
            return (
              (inputNumber / 1000000000000).toFixed(2).replace(/\.0$/, "") + "T"
            );
          }
        } else if (inputNumber >= 1000000000) {
          // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
          if ((inputNumber / 1000000000).toFixed(0).length >= 3) {
            return parseFloat((inputNumber / 1000000000).toFixed(1)) + "B";
          } else {
            return (
              (inputNumber / 1000000000).toFixed(2).replace(/\.0$/, "") + "B"
            );
          }
        } else if (inputNumber >= 1000000) {
          // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
          if ((inputNumber / 1000000).toFixed(0).length >= 3) {
            return parseFloat((inputNumber / 1000000).toFixed(1)) + "M";
          } else {
            return (inputNumber / 1000000).toFixed(2).replace(/\.0$/, "") + "M";
          }
        } else if (inputNumber >= 1000) {
          // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
          if ((inputNumber / 1000).toFixed(0).length === 3) {
            return parseFloat((inputNumber / 1000).toFixed(1)) + "K";
          } else {
            return (inputNumber / 1000).toFixed(2) + "K";
          }
        } else if (Number.isInteger(inputNumber)) {
          // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
          if (
            inputNumber.toLocaleString(undefined, {
              maximumFractionDigits: 0,
              maximumSignificantDigits: 3,
            }).length === 3
          ) {
            return parseFloat(inputNumber.toFixed(1));
          } else {
            return inputNumber.toLocaleString(undefined, {
              maximumFractionDigits: 0,
              maximumSignificantDigits: 3,
            });
          }
        } else {
          // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
          if (inputNumber.toString().split(".")[0].length >= 3) {
            return parseFloat(inputNumber.toFixed(1));
          } else {
            return inputNumber.toFixed(2).replace(/\.0$/, "");
          }
        }
      } else {
        if (inputNumber >= 10000000) {
          // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
          if ((inputNumber / 10000000).toFixed(0).length >= 3) {
            return parseFloat((inputNumber / 10000000).toFixed(1)) + "Cr";
          } else {
            return (
              (inputNumber / 10000000).toFixed(2).replace(/\.0$/, "") + "Cr"
            );
          }
        } else if (inputNumber >= 100000) {
          // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
          if ((inputNumber / 100000).toFixed(0).length >= 3) {
            return parseFloat((inputNumber / 100000).toFixed(1)) + "L";
          } else {
            return (inputNumber / 100000).toFixed(2).replace(/\.0$/, "") + "L";
          }
        } else if (inputNumber >= 1000) {
          // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
          if ((inputNumber / 1000).toFixed(0).length >= 3) {
            return parseFloat((inputNumber / 1000).toFixed(1)) + "K";
          } else {
            return (inputNumber / 1000).toFixed(2).replace(/\.0$/, "") + "K";
          }
        } else if (Number.isInteger(inputNumber)) {
          // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
          if (
            inputNumber.toLocaleString(undefined, {
              maximumFractionDigits: 0,
              maximumSignificantDigits: 3,
            }).length === 3
          ) {
            return parseFloat(inputNumber.toFixed(1));
          } else {
            return inputNumber.toLocaleString(undefined, {
              maximumFractionDigits: 0,
              maximumSignificantDigits: 3,
            });
          }
        } else {
          // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
          if (inputNumber.toString().split(".")[0].length === 3) {
            return parseFloat(inputNumber.toFixed(1));
          } else {
            return inputNumber.toFixed(2).replace(/\.0$/, "");
          }
        }
      }
    } else if (variant === "liquid") {
      if (
        inputNumber % 1 !== 0 &&
        inputNumber.toString().split(".")[1].length === 1 &&
        inputNumber.toString().split(".")[0].length === 1
      ) {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if (inputNumber.toString().split(".")[0].length === 3) {
          return parseFloat(inputNumber.toFixed(1)).toString();
        } else {
          return inputNumber.toString();
        }
      } else if (inputNumber >= 1000000000000) {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if ((inputNumber / 1000000000000).toFixed(0).length === 3) {
          return parseFloat((inputNumber / 1000000000000).toFixed(1)) + "T-L";
        } else {
          return (
            (inputNumber / 1000000000000).toFixed(2).replace(/\.0$/, "") + "T-L"
          );
        }
      } else if (inputNumber >= 1000000000) {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if ((inputNumber / 1000000000).toFixed(0).length === 3) {
          return parseFloat((inputNumber / 1000000000).toFixed(1)) + "B-L";
        } else {
          return (
            (inputNumber / 1000000000).toFixed(2).replace(/\.0$/, "") + "B-L"
          );
        }
      } else if (inputNumber >= 1000000) {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if ((inputNumber / 1000000).toFixed(0).length === 3) {
          return parseFloat((inputNumber / 1000000).toFixed(1)) + "M-L";
        } else {
          return (inputNumber / 1000000).toFixed(2).replace(/\.0$/, "") + "M-L";
        }
      } else if (inputNumber >= 1000) {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if ((inputNumber / 1000).toFixed(0).length === 3) {
          return parseFloat((inputNumber / 1000).toFixed(1)) + "K-L";
        } else {
          return (inputNumber / 1000).toFixed(2) + "K-L";
        }
      } else if (Number.isInteger(inputNumber)) {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if (
          inputNumber.toLocaleString(undefined, {
            maximumFractionDigits: 0,
            maximumSignificantDigits: 3,
          }).length === 3
        ) {
          return parseFloat(inputNumber.toFixed(1)) + "L";
        } else {
          return (
            inputNumber.toLocaleString(undefined, {
              maximumFractionDigits: 0,
              maximumSignificantDigits: 3,
            }) + "L"
          );
        }
      } else {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if (inputNumber.toString().split(".")[0].length === 3) {
          return parseFloat(inputNumber.toFixed(1)) + "L";
        } else {
          return inputNumber.toFixed(2).replace(/\.0$/, "") + "L";
        }
      }
    } else if (variant === "tonnes") {
      if (
        inputNumber % 1 !== 0 &&
        inputNumber.toString().split(".")[1].length === 1 &&
        inputNumber.toString().split(".")[0].length === 1
      ) {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if (inputNumber.toString().split(".")[0].length === 3) {
          return parseFloat(inputNumber.toFixed(1)).toString();
        } else {
          return inputNumber.toString();
        }
      } else if (inputNumber >= 1000000000000) {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if ((inputNumber / 1000000000000).toFixed(0).length === 3) {
          return parseFloat((inputNumber / 1000000000000).toFixed(1)) + "T T";
        } else {
          return (
            (inputNumber / 1000000000000).toFixed(2).replace(/\.0$/, "") + "T T"
          );
        }
      } else if (inputNumber >= 1000000000) {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if ((inputNumber / 1000000000).toFixed(0).length === 3) {
          return parseFloat((inputNumber / 1000000000).toFixed(1)) + "B T";
        } else {
          return (
            (inputNumber / 1000000000).toFixed(2).replace(/\.0$/, "") + "B T"
          );
        }
      } else if (inputNumber >= 1000000) {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if ((inputNumber / 1000000).toFixed(0).length === 3) {
          return parseFloat((inputNumber / 1000000).toFixed(1)) + "M T";
        } else {
          return (inputNumber / 1000000).toFixed(2).replace(/\.0$/, "") + "M T";
        }
      } else if (inputNumber >= 1000) {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if ((inputNumber / 1000).toFixed(0).length === 3) {
          return parseFloat((inputNumber / 1000).toFixed(1)) + "K T";
        } else {
          return (inputNumber / 1000).toFixed(2) + "K T";
        }
      } else if (Number.isInteger(inputNumber)) {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if (
          inputNumber.toLocaleString(undefined, {
            maximumFractionDigits: 0,
            maximumSignificantDigits: 3,
          }).length === 3
        ) {
          return parseFloat(inputNumber.toFixed(1)) + "T";
        } else {
          return (
            inputNumber.toLocaleString(undefined, {
              maximumFractionDigits: 0,
              maximumSignificantDigits: 3,
            }) + "T"
          );
        }
      }
    } else if (variant === "percentage") {
      if (inputNumber >= 10000) {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if ((inputNumber / 1000).toFixed(0).length >= 3) {
          return parseFloat((inputNumber / 1000).toFixed(1)) + "K %";
        } else {
          return (inputNumber / 1000).toFixed(2).replace(/\.0$/, "") + "K %";
        }
      } else if (Number.isInteger(inputNumber)) {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if (
          inputNumber.toLocaleString(undefined, {
            maximumFractionDigits: 0,
            maximumSignificantDigits: 3,
          }).length === 3
        ) {
          return parseFloat(inputNumber.toFixed(1)) + "%";
        } else {
          return (
            inputNumber.toLocaleString(undefined, {
              maximumFractionDigits: 0,
              maximumSignificantDigits: 3,
            }) + "%"
          );
        }
      } else {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if (inputNumber.toString().split(".")[0].length >= 3) {
          return parseFloat(inputNumber.toFixed(1)) + "%";
        } else {
          return inputNumber.toFixed(2).replace(/\.0$/, "") + "%";
        }
      }
    } else if (variant === "time") {
      inputNumber = number + "s";
      return inputNumber;
    } else if (variant === "views") {
      inputNumber = number;
      return inputNumber;
    } else {

      if (
        inputNumber != undefined &&
        inputNumber % 1 !== 0 &&
        inputNumber.toString().split(".")[1].length === 1 &&
        inputNumber.toString().split(".")[0].length === 1
      ) {
        // Check if the number has 3 digits before decimal and 1 decimal digit, return as string
        return inputNumber.toString();
      } else if (inputNumber >= 1000000000000) {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if ((inputNumber / 1000000000000).toFixed(0).length >= 3) {
          return parseFloat((inputNumber / 1000000000000).toFixed(1)) + "T";
        } else {
          return (
            (inputNumber / 1000000000000).toFixed(2).replace(/\.0$/, "") + "T"
          );
        }
      } else if (inputNumber >= 1000000000) {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if ((inputNumber / 1000000000).toFixed(0).length >= 3) {
          return parseFloat((inputNumber / 1000000000).toFixed(1)) + "B";
        } else {
          return (
            (inputNumber / 1000000000).toFixed(2).replace(/\.0$/, "") + "B"
          );
        }
      } else if (inputNumber >= 1000000) {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if ((inputNumber / 1000000).toFixed(0).length >= 3) {
          return parseFloat((inputNumber / 1000000).toFixed(1)) + "M";
        } else {
          return (inputNumber / 1000000).toFixed(2).replace(/\.0$/, "") + "M";
        }
      } else if (inputNumber >= 1000) {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if ((inputNumber / 1000).toFixed(0).length === 3) {
          return parseFloat((inputNumber / 1000).toFixed(1)) + "K";
        } else {
          return (inputNumber / 1000).toFixed(2) + "K";
        }
      } else if (Number.isInteger(inputNumber)) {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if (
          inputNumber.toLocaleString(undefined, {
            maximumFractionDigits: 0,
            maximumSignificantDigits: 3,
          }).length === 3
        ) {
          return parseFloat(inputNumber.toFixed(1));
        } else {
          return inputNumber.toLocaleString(undefined, {
            maximumFractionDigits: 0,
            maximumSignificantDigits: 3,
          });
        }
      } else {
        // Check if there are 3 digits before decimal, if yes, round to 1 decimal point
        if (inputNumber.toString().split(".")[0].length >= 3) {
          return parseFloat(inputNumber.toFixed(1));
        } else {
          return inputNumber.toFixed(2).replace(/\.0$/, "");
        }
      }
    }
  }

  let midFormattedNumber;
  if (inputNumber % 1 !== 0) {
    if (variant === "money") {
      midFormattedNumber = country.currencySymbol + commaSeperator(inputNumber, 'money');
    } else if (variant === "percentage") {
      midFormattedNumber = commaSeperator(inputNumber) + "%";
    } else {
      midFormattedNumber = commaSeperator(inputNumber);
    }
  } else {
    if (variant === "money") {
      midFormattedNumber = country.currencySymbol + commaSeperator(inputNumber, 'money');
    } else if (variant === "percentage") {
      midFormattedNumber = commaSeperator(inputNumber) + "%";
    } else if (variant === "time") {
      midFormattedNumber = commaSeperator(inputNumber) + "s";
    } else {
      midFormattedNumber = commaSeperator(inputNumber);
    }
  }

  function commaSeperator(numbers) {
    if (country.countryCode === "USA") {
      if (numbers < 1) {
        let numStr = numbers.toString();

        // Find the index of the first nonzero digit after the decimal point
        let decimalIndex = numStr.indexOf('.') + 1;
        let firstNonzeroIndex = decimalIndex;

        // Check if there's a decimal point and if the digit after it is zero
        if (decimalIndex !== 0 && numStr[decimalIndex] === '0') {
          while (firstNonzeroIndex < numStr.length && numStr[firstNonzeroIndex] === '0') {
            firstNonzeroIndex++;
          }

          // Check if there's a second non-zero digit after the decimal point
          if (firstNonzeroIndex < numStr.length - 1) {
            let secondNonzeroIndex = firstNonzeroIndex + 1;

            // If the second nonzero digit is greater than 5, add 1 to the first nonzero digit
            if (parseInt(numStr[secondNonzeroIndex]) > 5) {
              let roundedNum = parseInt(numStr[firstNonzeroIndex]) + 1;
              return numStr.slice(0, firstNonzeroIndex) + roundedNum;
            } else {
              return numStr.slice(0, firstNonzeroIndex + 1); // Keep the first nonzero digit and one zero after decimal
            }
          } else {
            return numStr; // If there's no second nonzero digit, return the number as is
          }
        } else {
          return Number(numbers.toFixed(2))

        }

      } else {

        /*    let nfObject = new Intl.toLocaleString('en-IN') */
        return (numbers = numbers.toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }));
      }
    } else {
      if (numbers < 1) {
        let numStr = numbers.toString();

        // Find the index of the first nonzero digit after the decimal point
        let decimalIndex = numStr.indexOf('.') + 1;
        let firstNonzeroIndex = decimalIndex;

        // Check if there's a decimal point and if the digit after it is zero
        if (decimalIndex !== 0 && numStr[decimalIndex] === '0') {
          while (firstNonzeroIndex < numStr.length && numStr[firstNonzeroIndex] === '0') {
            firstNonzeroIndex++;
          }

          // Check if there's a second non-zero digit after the decimal point
          if (firstNonzeroIndex < numStr.length - 1) {
            let secondNonzeroIndex = firstNonzeroIndex + 1;

            // If the second nonzero digit is greater than 5, add 1 to the first nonzero digit
            if (parseInt(numStr[secondNonzeroIndex]) > 5) {
              let roundedNum = parseInt(numStr[firstNonzeroIndex]) + 1;
              return numStr.slice(0, firstNonzeroIndex) + roundedNum;
            } else {
              return numStr.slice(0, firstNonzeroIndex + 1); // Keep the first nonzero digit and one zero after decimal
            }
          } else {
            return numStr; // If there's no second nonzero digit, return the number as is
          }
        } else {
          return Number(numbers.toFixed(2))

        }

      } else {
        return (numbers = numbers
          .toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })
          .replace(/[^\x00-\x7F]/g, ""));
      }
    }
  }

  function roundToOneDecimalPlace(numbers) {
    return Math.round(numbers * 10) / 10;
  }
  function roundToDecimalPlace(numbers) {
    return Math.round(numbers);
  }

  function removeCommasAndLetters(str) {
    const withoutCommas = str.replace(/,/g, "");

    const match = withoutCommas.match(
      /^(\d*\.\d+|\d+)([%KMBTCrLK %-s]{1,3})?$/i
    );

    if (match) {
      const numericPart = match[1].replace(/\.0$/, "");
      /*   const numericPart = match[1].replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/,'$1'); */
      return numericPart + (match[2] || ""); // Append the matched suffix
    }

    return "";
  }

  return (
    <Wrapper>
      {/*  <div> */}

      {/*  Input : {number} ===== Expected Number : {expected} ===== Result :    */}
      <span className="formatted_number"> {formattedNumber}</span>
      <div className="unformatted_number">{midFormattedNumber}</div>

      {/*  </div>   */}
    </Wrapper>
  );
};

NumberFormatter.propTypes = {
  number: PropTypes.number,
  expected: PropTypes.any,
  variant: PropTypes.string,
  title: PropTypes.string,
  platform: PropTypes.string,
};

export default NumberFormatter;
