import React, { useState, useEffect } from "react";
import packageJson from "../package.json";
import moment from "moment";
import axios from "axios";


const buildDateGreaterThan = (latestDate, currentDate) => {
    const momLatestDateTime = moment(latestDate);
    const momCurrentDateTime = moment(currentDate);

    console.log('build dates meta & package', momLatestDateTime, momCurrentDateTime);

    if (momLatestDateTime.isAfter(momCurrentDateTime)) {
        return true;
    } else {
        return false;
    }
};

function withClearCache(Component) {

    function ClearCacheComponent(props) {
        // const authCtx = useContext(AuthContext);
        // const dispatch = useDispatch();
        const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

        useEffect(() => {

            const config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            try {
                const res = axios.get("/meta.json", config);

                res.then((res) => {
                    const latestVersionDate = res.data.buildDate;
                    const currentVersionDate = packageJson.buildDate;

                    const shouldForceRefresh = buildDateGreaterThan(
                        latestVersionDate,
                        currentVersionDate
                    );
                    if (shouldForceRefresh) {
                        setIsLatestBuildDate(false);
                        refreshCacheAndReload();
                    } else {
                        setIsLatestBuildDate(true);
                    }

                })
            } catch (error) {
                console.log('withClearCache', error);
            }
        }, [packageJson.buildDate]);

        const refreshCacheAndReload = () => {
            if (caches) {
                // Service worker cache should be cleared with caches.delete()
                caches.keys().then((names) => {
                    for (const name of names) {
                        caches.delete(name);
                    }
                });
            }

            localStorage.removeItem("token");
            localStorage.removeItem("expirationTime");

            // delete browser cache and hard reload
            window.location.reload(true);
        };

        return (
            <React.Fragment>
                {isLatestBuildDate ? <Component {...props} /> : null}
            </React.Fragment>
        );
    }

    return ClearCacheComponent;
}

export default withClearCache;