const FilterIcon = (props) => {
  return (
    <svg className={props.className} width="31" height="28" fill="none" viewBox="0 0 31 28" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.146 1H1.11597L12.728 14.731V24.224L18.534 27.124V14.724L30.146 1Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default FilterIcon;
