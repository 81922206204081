import posthog from 'posthog-js';

// Define allowed event types
export const VALID_EVENT_TYPES = [
    "date_selected",
    "brands_selected",
    "campaigns_selected",
    "campaign_buckets_selected",
    "export_selected",
    "filters_selected",
    "dropdown_selected",
    "chart_legends_click",
    "chart_zoom_click",
    "chart_export_click",
    "chart_expand_click",
    "page_export_click",
    "sidebar_navigation_click",
    "posts_compare_click",
    "api_error",
    "cards_previous_arrow_clicked",
    "cards_next_arrow_clicked",
    "tab_clicked",
    "platform_selected"
];

// EventTracker({
//     eventType: "dropdown_selected",
//     module: pageModule,
//     section: "Brand Wise Monthly TV Reach Compare 2 | Month Dropdown",
//     dropdownValue: e === null ? "Overall" : e.label,
//     brands: selectedBrands,
//     date: {
//         startDate: selectedDates.startDate,
//         endDate: selectedDates.endDate,
//         previousStartDate: selectedDates.prevStartDate,
//         previousEndDate: selectedDates.prevEndDate,
//     }
// })

// Main function to track events
const EventTracker = ({
    eventType,
    module,
    subModule,
    platform,
    page,
    subpage,
    tab,
    subtab,
    section,
    date,
    brands,
    campaigns,
    campaignBuckets,
    exportType,
    dropdownValue,
    apiUrl,
    apiError,
    apiData
}) => {

    // Filter out undefined fields from eventData
    const eventData = {
        ...(module && { module }),
        ...(subModule && { subModule }),
        ...(page && { page }),
        ...(subpage && { subpage }),
        ...(platform && { platform }),
        ...(tab && { tab }),
        ...(subtab && { subtab }),
        ...(section && { section }),
        ...(date && { date }),
        ...(brands && { brands }),
        ...(campaigns && { campaigns }),
        ...(campaignBuckets && { campaignBuckets }),
        ...(exportType && { exportType }),
        ...(dropdownValue && { dropdownValue }),
        ...(apiUrl && { apiUrl }),
        ...(apiError && { apiError }),
        ...(apiData && { apiData }),
    };

    // Trigger the event
    posthog.capture(eventType, eventData);
};

// Export as a utility function
export default EventTracker;
