const CalendarIcon = (props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      width="26.814"
      height="29.627"
      viewBox="0 0 26.814 29.627"
      fill="none"
      // stroke="#486f88"
    >
      <g
        id="Group_96"
        data-name="Group 96"
        transform="translate(-351.626 -466.585)"
      >
        <rect
          id="Rectangle_70"
          data-name="Rectangle 70"
          width="25.314"
          height="25.314"
          rx="2"
          transform="translate(352.377 470.148)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          id="Line_23"
          data-name="Line 23"
          y2="5.625"
          transform="translate(370.659 467.335)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          id="Line_24"
          data-name="Line 24"
          y2="5.625"
          transform="translate(359.408 467.335)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          id="Line_25"
          data-name="Line 25"
          x2="25.314"
          transform="translate(352.376 478.587)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};

export default CalendarIcon;
