import Wrapper from "../../components/helpers/Wrapper";

const IconEye = (props) => {
    return (
        <Wrapper>
            <svg className={props.className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.82042 7.25348C8.31415 7.25348 7.08545 8.53932 7.08545 10.1232C7.08545 11.7029 8.31006 12.9928 9.82042 12.9928C11.3267 12.9928 12.5554 11.707 12.5554 10.1232C12.5554 8.54341 11.3267 7.25348 9.82042 7.25348V7.25348Z" />
                <path d="M9.82039 14.6583C7.39565 14.6583 5.41994 12.6255 5.41994 10.1232C5.41994 7.62087 7.39565 5.58801 9.82039 5.58801C12.2451 5.58801 14.2208 7.62087 14.2208 10.1232C14.2208 12.6255 12.2451 14.6583 9.82039 14.6583V14.6583ZM19.8745 9.68231C17.2701 5.50229 13.7596 3.20001 10 3.20001C6.24043 3.20001 2.72987 5.50229 0.125523 9.68231C-0.041841 9.95172 -0.041841 10.2946 0.125523 10.564C2.72987 14.744 6.24043 17.0463 10 17.0463C13.7596 17.0463 17.266 14.744 19.8745 10.564C20.0418 10.2946 20.0418 9.95172 19.8745 9.68231Z" />
            </svg>
        </Wrapper>
    )
}

export default IconEye;
