const PerformanceIcon = (props) => {
  return (
    <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" stroke="#486F88" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.09 41.119V23.544" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M26.544 41.118V13" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16 41.127V30.575" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default PerformanceIcon;
