import React, { useEffect, useState } from 'react'
import Wrapper from '../../helpers/Wrapper';
import ErrorBoundary from '../../../utils/ErrorBoundary';
import CrossIcon from '../../../assets/icons/CrossIcon';
import CheckIcon from '../../../assets/icons/CheckIcon';
import { type } from '@amcharts/amcharts5';

export default function Alert({
  alertType,
  alertTypeValue,
  bucketName,
  editBucketerror
}) {

  const [alertBoxValue, setAlertBoxValue] = useState("hide")
  function triggerAlertBox() {
    setAlertBoxValue("show")
    setTimeout(() => {
      setAlertBoxValue("hide")
    }, 4000)
  }

  useEffect(() => {
    triggerAlertBox()
  }, [alertType, alertTypeValue])


  let alertBoxSection;
  if (alertType === "Campaigns Added") {
    alertBoxSection = (
      <Wrapper>
        <dialog open className={`admin_alert_box success`}>
          <button className="close_button" type="button" onClick={() => setAlertBoxValue("hide")}>&#x2715; </button>
          <div className="alert_main_wrap">
            <div className="check_icon_wrap">
              <CheckIcon className="alert_check_icon" />
            </div>
            <div>
              <h4 className="alert_title">Campaigns Added.</h4>
              <div className="alert_body"> {alertTypeValue} campaigns added in bucket succesfully!</div>
            </div>
          </div>
        </dialog>
      </Wrapper>
    )
  } else if (alertType === "Campaigns Removed") {
    alertBoxSection = (
      <Wrapper>
        <dialog open className={`admin_alert_box success`}>
          <button className="close_button" type="button" onClick={() => setAlertBoxValue("hide")}>&#x2715; </button>
          <div className="alert_main_wrap">
            <div className="check_icon_wrap">
              <CheckIcon className="alert_check_icon" />
            </div>
            <div>
              <h4 className="alert_title">Campaigns Removed.</h4>
              <div className="alert_body"> {alertTypeValue} campaigns removed from bucket succesfully!</div>
            </div>
          </div>
        </dialog>
      </Wrapper>
    )
  } else if (alertType === "Campaigns Updated") {

    if (editBucketerror) {
      alertBoxSection = (
        <Wrapper>
          <dialog open className={`admin_alert_box danger`}>
            <button className="close_button" type="button" onClick={() => setAlertBoxValue("hide")}>&#x2715; </button>
            <div className="alert_main_wrap">
              <div className="check_icon_wrap">
                <CrossIcon className="alert_check_icon" />
              </div>
              <div>
                <h4 className="alert_title">{React.isValidElement(editBucketerror) ? editBucketerror : typeof editBucketerror ==='string' ? editBucketerror:  'An Error occurred'}</h4>
                <div className="alert_body">Please try again later </div>
              </div>
            </div>
          </dialog>
        </Wrapper>
      )
    }
    else {
      alertBoxSection = (
        <Wrapper>
          <dialog open className={`admin_alert_box success`}>
            <button className="close_button" type="button" onClick={() => setAlertBoxValue("hide")}>&#x2715; </button>
            <div className="alert_main_wrap">
              <div className="check_icon_wrap">
                <CheckIcon className="alert_check_icon" />
              </div>
              <div>
                <h4 className="alert_title">Campaigns Updated.</h4>
                <div className="alert_body"> Updated bucket <b>{bucketName}</b> succesfully!</div>
              </div>
            </div>
          </dialog>
        </Wrapper>
      )
    }

  }
  else if (alertType === "Unique Name") {
    alertBoxSection = (
      <Wrapper>
        <dialog open className={`admin_alert_box danger`}>
          <button className="close_button" type="button" onClick={() => setAlertBoxValue("hide")}>&#x2715; </button>
          <div className="alert_main_wrap">
            <div className="check_icon_wrap">
              <CrossIcon className="alert_check_icon" />
            </div>
            <div>
              <h4 className="alert_title">Bucket <b>{bucketName}</b> already exists!</h4>
              <div className="alert_body"> Bucket with name <b>{bucketName}</b> already exists.</div>
            </div>
          </div>
        </dialog>
      </Wrapper>
    )
  } else if (alertType === "Admin Delete") {
    alertBoxSection = (
      <Wrapper>
        <dialog open className="admin_alert_box success">
          <button className="close_button" type="button" onClick={() => setAlertBoxValue("hide")}>&#x2715; </button>
          <div className="alert_main_wrap">
            <div className="check_icon_wrap">
              <CheckIcon className="alert_check_icon" />
            </div>
            <div>
              <h4 className="alert_title">Bucket Deleted.</h4>
              <div className="alert_body">Bucket name <b>{bucketName}</b> has been deleted successfully!</div>
            </div>
          </div>


        </dialog>
      </Wrapper>
    )
  }



  return (
    <Wrapper>
      <ErrorBoundary>
        <div className={`admin_alert_wrap ${alertBoxValue}`}>
          {alertBoxSection}
        </div>
      </ErrorBoundary>
    </Wrapper>
  )
}
