const RetailIntelligenceIcon = (props) => {
    return (
        <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
            <g id="Group_529" data-name="Group 529" transform="translate(-1233 -606)">
                <g id="Icon_feather-shopping-cart" data-name="Icon feather-shopping-cart" transform="translate(1241.5 615.5)">
                    <path id="Path_274" data-name="Path 274" d="M15,31.5A1.5,1.5,0,1,1,13.5,30,1.5,1.5,0,0,1,15,31.5Z" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    <path id="Path_275" data-name="Path 275" d="M31.5,31.5A1.5,1.5,0,1,1,30,30,1.5,1.5,0,0,1,31.5,31.5Z" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    <path id="Path_276" data-name="Path 276" d="M1.5,1.5h6l4.02,20.085a3,3,0,0,0,3,2.415H29.1a3,3,0,0,0,3-2.415L34.5,9H9" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                </g>
            </g>
        </svg>
    )
}

export default RetailIntelligenceIcon;
