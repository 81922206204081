import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../store/authContext";
import FullPageExportContext from "../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../components/helpers/axios";
import Wrapper from "../../../components/helpers/Wrapper";
import Card from "../../../components/ui/Card";
import Slider from "../../../components/ui/Slider";

// LOADERS
import NoDataAvailableLoader from "../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../components/ui/loaders/ServerErrosLoader";
import Loader from "../../../components/ui/loaders/Loader";

//  UTILS
import ErrorBoundary from "../../../utils/ErrorBoundary";
import { IAEvent_Primary_Tab_Visit } from "../../../utils/IAEvents";
import NoDataAvailableChecker from "../../../utils/NoDataAvailableChecker";

const InstagramCompare = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setExportPageName, setIsExportDisabled } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null
  } else {
    BRAND_ID = globalSelectedBrandIds.join(',')
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // NO OF COMPETITORS ON FACEBOOK
  const [isCompetitorsListLoading, setCompetitorsListIsLoading] =
    useState(false);
  const [isCompetitorsListLoaded, setCompetitorsListIsLoaded] = useState(false);
  const [competitorsListError, setCompetitorsListError] = useState(null);

  // TYPE OF CONTENT AVAILABLE
  const [contentTypes, setContentTypes] = useState([]);

  // TYPE OF ENGAGEMENETS FOR IMAGES
  const [imagesLikes, setImagesLikes] = useState([]);
  const [imagesShares, setImagesShares] = useState([]);
  const [imagesComments, setImagesComments] = useState([]);
  const [imagesReactions, setImagesReactions] = useState([]);

  // TYPE OF ENGAGEMENETS FOR ALBUM
  const [albumLikes, setAlbumLikes] = useState([]);
  const [albumShares, setAlbumShares] = useState([]);
  const [albumComments, setAlbumComments] = useState([]);
  const [albumReactions, setAlbumReactions] = useState([]);

  // TYPE OF ENGAGEMENETS FOR VIDEO
  const [videoLikes, setVideoLikes] = useState([]);
  const [videoShares, setVideoShares] = useState([]);
  const [videoComments, setVideoComments] = useState([]);
  const [videoReactions, setVideoReactions] = useState([]);

  // TYPE OF ENGAGEMENETS FOR LINK
  const [linkLikes, setLinkLikes] = useState([]);
  const [linkShares, setLinkShares] = useState([]);
  const [linkComments, setLinkComments] = useState([]);
  const [linkReactions, setLinkReactions] = useState([]);

  const tabData = ["Likes", "Comments"];
  const [selectedTab, setSelectedTab] = useState(tabData[0]);


  // TO FETCH INSTAGRAM COMPETITORS DATA FROM API
  const fetchCompetitorsListHandler = async () => {
    setCompetitorsListIsLoading(true);
    setCompetitorsListIsLoaded(false);
    setCompetitorsListError(null);
    setContentTypes([]);

    setImagesLikes([]);
    setImagesShares([]);
    setImagesComments([]);
    setImagesReactions([]);

    setAlbumLikes([]);
    setAlbumShares([]);
    setAlbumComments([]);
    setAlbumReactions([]);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "/compare/insta-compare-content/",
        config
      );

      let resInstagramContentList = res?.data?.instagram_content_list ?? []

      // FOR GETTING CONTENT TYPES
      let contentTypes = [];
      resInstagramContentList.map((competitor) => {
        try {
          let a = competitor.content_type;
          contentTypes.push(a);
        } catch (e) { }
      });
      let uniqueContentTypes = [...new Set(contentTypes)];

      // FOR GETTING IMAGES LIKES
      let competitorsImagesLikes = [];
      resInstagramContentList.map((competitor) => {
        try {
          if (competitor.content_type === "IMAGE") {
            let a = {
              label: competitor.competitor_name,
              value: competitor.likes_count,
              count: competitor.total,
            };
            competitorsImagesLikes.push(a);
          }
        } catch (e) { }
      });

      // FOR GETTING IMAGES SHARES
      let competitorsImagesShares = [];
      resInstagramContentList.map((competitor) => {
        try {
          if (competitor.content_type === "IMAGE") {
            let a = {
              label: competitor.competitor_name,
              value: competitor.shares_count,
              count: competitor.total,
            };
            competitorsImagesShares.push(a);
          }
        } catch (e) { }
      });

      // FOR GETTING IMAGES COMMENTS
      let competitorsImagesComments = [];
      resInstagramContentList.map((competitor) => {
        try {
          if (competitor.content_type === "IMAGE") {
            let a = {
              label: competitor.competitor_name,
              value: competitor.comment_count,
              count: competitor.total,
            };
            competitorsImagesComments.push(a);
          }
        } catch (e) { }
      });

      // FOR GETTING IMAGES REACTIONS
      let competitorsImagesReactions = [];
      resInstagramContentList.map((competitor) => {
        try {
          if (competitor.content_type === "IMAGE") {
            let a = {
              label: competitor.competitor_name,
              value: competitor.reaction_count,
              count: competitor.total,
            };
            competitorsImagesReactions.push(a);
          }
        } catch (e) { }
      });

      // FOR GETTING ALBUM LIKES
      let competitorsAlbumLikes = [];
      resInstagramContentList.map((competitor) => {
        try {
          if (competitor.content_type === "CAROUSEL_ALBUM") {
            let a = {
              label: competitor.competitor_name,
              value: competitor.likes_count,
              count: competitor.total,
            };
            competitorsAlbumLikes.push(a);
          }
        } catch (e) { }
      });

      // FOR GETTING ALBUM SHARES
      let competitorsAlbumShares = [];
      resInstagramContentList.map((competitor) => {
        try {
          if (competitor.content_type === "CAROUSEL_ALBUM") {
            let a = {
              label: competitor.competitor_name,
              value: competitor.shares_count,
              count: competitor.total,
            };
            competitorsAlbumShares.push(a);
          }
        } catch (e) { }
      });

      // FOR GETTING ALBUM COMMENTS
      let competitorsAlbumComments = [];
      resInstagramContentList.map((competitor) => {
        try {
          if (competitor.content_type === "CAROUSEL_ALBUM") {
            let a = {
              label: competitor.competitor_name,
              value: competitor.comment_count,
              count: competitor.total,
            };
            competitorsAlbumComments.push(a);
          }
        } catch (e) { }
      });

      // FOR GETTING ALBUM REACTIONS
      let competitorsAlbumReactions = [];
      resInstagramContentList.map((competitor) => {
        try {
          if (competitor.content_type === "CAROUSEL_ALBUM") {
            let a = {
              label: competitor.competitor_name,
              value: competitor.reaction_count,
              count: competitor.total,
            };
            competitorsAlbumReactions.push(a);
          }
        } catch (e) { }
      });

      // FOR GETTING VIDEO LIKES
      let competitorsVideoLikes = [];
      resInstagramContentList.map((competitor) => {
        try {
          if (competitor.content_type === "VIDEO") {
            let a = {
              label: competitor.competitor_name,
              value: competitor.likes_count,
              count: competitor.total,
            };
            competitorsVideoLikes.push(a);
          }
        } catch (e) { }
      });

      // FOR GETTING VIDEO SHARES
      let competitorsVideoShares = [];
      resInstagramContentList.map((competitor) => {
        try {
          if (competitor.content_type === "VIDEO") {
            let a = {
              label: competitor.competitor_name,
              value: competitor.shares_count,
              count: competitor.total,
            };
            competitorsVideoShares.push(a);
          }
        } catch (e) { }
      });

      // FOR GETTING VIDEO COMMENTS
      let competitorsVideoComments = [];
      resInstagramContentList.map((competitor) => {
        try {
          if (competitor.content_type === "VIDEO") {
            let a = {
              label: competitor.competitor_name,
              value: competitor.comment_count,
              count: competitor.total,
            };
            competitorsVideoComments.push(a);
          }
        } catch (e) { }
      });

      // FOR GETTING VIDEO REACTIONS
      let competitorsVideoReactions = [];
      resInstagramContentList.map((competitor) => {
        try {
          if (competitor.content_type === "VIDEO") {
            let a = {
              label: competitor.competitor_name,
              value: competitor.reaction_count,
              count: competitor.total,
            };
            competitorsVideoReactions.push(a);
          }
        } catch (e) { }
      });

      // FOR GETTING LINK LIKES
      let competitorsLinkLikes = [];
      resInstagramContentList.map((competitor) => {
        try {
          if (competitor.content_type === "LINK") {
            let a = {
              label: competitor.competitor_name,
              value: competitor.likes_count,
              count: competitor.total,
            };
            competitorsLinkLikes.push(a);
          }
        } catch (e) { }
      });

      // FOR GETTING LINK SHARES
      let competitorsLinkShares = [];
      resInstagramContentList.map((competitor) => {
        try {
          if (competitor.content_type === "LINK") {
            let a = {
              label: competitor.competitor_name,
              value: competitor.shares_count,
              count: competitor.total,
            };
            competitorsLinkShares.push(a);
          }
        } catch (e) { }
      });

      // FOR GETTING LINK COMMENTS
      let competitorsLinkComments = [];
      resInstagramContentList.map((competitor) => {
        try {
          if (competitor.content_type === "LINK") {
            let a = {
              label: competitor.competitor_name,
              value: competitor.comment_count,
              count: competitor.total,
            };
            competitorsLinkComments.push(a);
          }
        } catch (e) { }
      });

      // FOR GETTING LINK REACTIONS
      let competitorsLinkReactions = [];
      resInstagramContentList.map((competitor) => {
        try {
          if (competitor.content_type === "LINK") {
            let a = {
              label: competitor.competitor_name,
              value: competitor.reaction_count,
              count: competitor.total,
            };
            competitorsLinkReactions.push(a);
          }
        } catch (e) { }
      });

      setContentTypes(uniqueContentTypes ?? []);

      setImagesLikes(competitorsImagesLikes ?? []);
      setImagesShares(competitorsImagesShares ?? []);
      setImagesComments(competitorsImagesComments ?? []);
      setImagesReactions(competitorsImagesReactions ?? []);

      setAlbumLikes(competitorsAlbumLikes ?? []);
      setAlbumShares(competitorsAlbumShares ?? []);
      setAlbumComments(competitorsAlbumComments ?? []);
      setAlbumReactions(competitorsAlbumReactions ?? []);

      setVideoLikes(competitorsVideoLikes ?? []);
      setVideoShares(competitorsVideoShares ?? []);
      setVideoComments(competitorsVideoComments ?? []);
      setVideoReactions(competitorsVideoReactions ?? []);

      setLinkLikes(competitorsLinkLikes ?? []);
      setLinkShares(competitorsLinkShares ?? []);
      setLinkComments(competitorsLinkComments ?? []);
      setLinkReactions(competitorsLinkReactions ?? []);

      setCompetitorsListIsLoading(false);
      setCompetitorsListIsLoaded(true);
      setCompetitorsListError(null);
    } catch (error) {
      setCompetitorsListIsLoading(false);
      setCompetitorsListIsLoaded(false);
      setCompetitorsListError(error.code);
    }
  };

  const activeTabHandler = (tab) => {
    if (selectedTab !== tab) {
      setSelectedTab(tab);
    }
    IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Compare", "Instagram", tab)
  };

  // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
  useEffect(() => {
    fetchCompetitorsListHandler();
  }, [BRAND_ID, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // FACEBOOK COMPETITORS

  let Competitors = <Wrapper><Loader loaderType="cardLoader" variantType="compare" /></Wrapper>

  if (isCompetitorsListLoaded && !isCompetitorsListLoading) {
    if (NoDataAvailableChecker(contentTypes)) {
      Competitors = <Wrapper><NoDataAvailableLoader chartType="cardChartType" /></Wrapper>
    } else {
      Competitors = (
        <Wrapper>
          {selectedTab === "Likes" && (
            <Wrapper>
              {contentTypes.includes("IMAGE") && (
                <Wrapper>
                  <ErrorBoundary chartType="cardChartType">
                    <Slider sliderTitle={"Images"}>
                      {imagesLikes.map((data, index) => (
                        <SwiperSlide key={index}>
                          <Card
                            key={index}
                            cardTitle={data.label}
                            variant={"compare"}
                            showPreviousCompare={false}
                            currentValue={data.value}
                            rowOneTitle={"Total Likes"}
                            second_currentValue={data.count}
                            rowTwoTitle={"No of Images"}
                            numberVariant={data.numberVariant}
                            forCountry={data.forCountry}
                          />
                        </SwiperSlide>
                      ))}
                    </Slider>
                  </ErrorBoundary>
                </Wrapper>
              )}
              {contentTypes.includes("VIDEO") && (
                <Wrapper>
                  <ErrorBoundary chartType="cardChartType">
                    <Slider sliderTitle={"Videos"}>
                      {videoLikes.map((data, index) => (
                        <SwiperSlide key={index}>
                          <Card
                            key={index}
                            cardTitle={data.label}
                            variant={"compare"}
                            showPreviousCompare={false}
                            currentValue={data.value}
                            rowOneTitle={"Total Likes"}
                            second_currentValue={data.count}
                            rowTwoTitle={"No of Videos"}
                            numberVariant={data.numberVariant}
                            forCountry={data.forCountry}
                          />
                        </SwiperSlide>
                      ))}
                    </Slider>
                  </ErrorBoundary>
                </Wrapper>
              )}
              {contentTypes.includes("CAROUSEL_ALBUM") && (
                <Wrapper>
                  <ErrorBoundary chartType="cardChartType">
                    <Slider sliderTitle={"Album"}>
                      {albumLikes.map((data, index) => (
                        <SwiperSlide key={index}>
                          <Card
                            key={index}
                            cardTitle={data.label}
                            variant={"compare"}
                            showPreviousCompare={false}
                            currentValue={data.value}
                            rowOneTitle={"Total Likes"}
                            second_currentValue={data.count}
                            rowTwoTitle={"No of Albums"}
                            numberVariant={data.numberVariant}
                            forCountry={data.forCountry}
                          />
                        </SwiperSlide>
                      ))}
                    </Slider>
                  </ErrorBoundary>
                </Wrapper>
              )}
              {contentTypes.includes("LINK") && (
                <Wrapper>
                  <ErrorBoundary chartType="cardChartType">
                    <Slider sliderTitle={"Links"}>
                      {linkLikes.map((data, index) => (
                        <SwiperSlide key={index}>
                          <Card
                            key={index}
                            cardTitle={data.label}
                            variant={"compare"}
                            showPreviousCompare={false}
                            currentValue={data.value}
                            rowOneTitle={"Total Likes"}
                            second_currentValue={data.count}
                            rowTwoTitle={"No of Links"}
                            numberVariant={data.numberVariant}
                            forCountry={data.forCountry}
                          />
                        </SwiperSlide>
                      ))}
                    </Slider>
                  </ErrorBoundary>
                </Wrapper>
              )}
            </Wrapper>
          )}

          {selectedTab === "Shares" && (
            <Wrapper>
              {contentTypes.includes("IMAGE") && (
                <Wrapper>
                  <ErrorBoundary chartType="cardChartType">
                    <Slider sliderTitle={"Images"}>
                      {imagesShares.map((data, index) => (
                        <SwiperSlide key={index}>
                          <Card
                            key={index}
                            cardTitle={data.label}
                            variant={"compare"}
                            showPreviousCompare={false}
                            currentValue={data.value}
                            rowOneTitle={"Total Shares"}
                            second_currentValue={data.count}
                            rowTwoTitle={"No of Images"}
                            numberVariant={data.numberVariant}
                            forCountry={data.forCountry}
                          />
                        </SwiperSlide>
                      ))}
                    </Slider>
                  </ErrorBoundary>
                </Wrapper>
              )}
              {contentTypes.includes("VIDEO") && (
                <Wrapper>
                  <ErrorBoundary chartType="cardChartType">
                    <Slider sliderTitle={"Video"}>
                      {videoShares.map((data, index) => (
                        <SwiperSlide key={index}>
                          <Card
                            key={index}
                            cardTitle={data.label}
                            variant={"compare"}
                            showPreviousCompare={false}
                            currentValue={data.value}
                            rowOneTitle={"Total Shares"}
                            second_currentValue={data.count}
                            rowTwoTitle={"No of Videos"}
                            numberVariant={data.numberVariant}
                            forCountry={data.forCountry}
                          />
                        </SwiperSlide>
                      ))}
                    </Slider>
                  </ErrorBoundary>
                </Wrapper>
              )}
              {contentTypes.includes("CAROUSEL_ALBUM") && (
                <Wrapper>
                  <ErrorBoundary chartType="cardChartType">
                    <Slider sliderTitle={"Album"}>
                      {albumShares.map((data, index) => (
                        <SwiperSlide key={index}>
                          <Card
                            key={index}
                            cardTitle={data.label}
                            variant={"compare"}
                            showPreviousCompare={false}
                            currentValue={data.value}
                            rowOneTitle={"Total Shares"}
                            second_currentValue={data.count}
                            rowTwoTitle={"No of Albums"}
                            numberVariant={data.numberVariant}
                            forCountry={data.forCountry}
                          />
                        </SwiperSlide>
                      ))}
                    </Slider>
                  </ErrorBoundary>
                </Wrapper>
              )}
              {contentTypes.includes("LINK") && (
                <Wrapper>
                  <ErrorBoundary chartType="cardChartType">
                    <Slider sliderTitle={"Link"}>
                      {linkShares.map((data, index) => (
                        <SwiperSlide key={index}>
                          <Card
                            key={index}
                            cardTitle={data.label}
                            variant={"compare"}
                            showPreviousCompare={false}
                            currentValue={data.value}
                            rowOneTitle={"Total Shares"}
                            second_currentValue={data.count}
                            rowTwoTitle={"No of Links"}
                            numberVariant={data.numberVariant}
                            forCountry={data.forCountry}
                          />
                        </SwiperSlide>
                      ))}
                    </Slider>
                  </ErrorBoundary>
                </Wrapper>
              )}
            </Wrapper>
          )}

          {selectedTab === "Comments" && (
            <Wrapper>
              {contentTypes.includes("IMAGE") && (
                <Wrapper>
                  <ErrorBoundary chartType="cardChartType">
                    <Slider sliderTitle={"Images"}>
                      {imagesComments.map((data, index) => (
                        <SwiperSlide key={index}>
                          <Card
                            key={index}
                            cardTitle={data.label}
                            variant={"compare"}
                            showPreviousCompare={false}
                            currentValue={data.value}
                            rowOneTitle={"Total Comments"}
                            second_currentValue={data.count}
                            rowTwoTitle={"No of Images"}
                            numberVariant={data.numberVariant}
                            forCountry={data.forCountry}
                          />
                        </SwiperSlide>
                      ))}
                    </Slider>
                  </ErrorBoundary>
                </Wrapper>
              )}
              {contentTypes.includes("VIDEO") && (
                <Wrapper>
                  <ErrorBoundary chartType="cardChartType">
                    <Slider sliderTitle={"Videos"}>
                      {videoComments.map((data, index) => (
                        <SwiperSlide key={index}>
                          <Card
                            key={index}
                            cardTitle={data.label}
                            variant={"compare"}
                            showPreviousCompare={false}
                            currentValue={data.value}
                            rowOneTitle={"Total Comments"}
                            second_currentValue={data.count}
                            rowTwoTitle={"No of Videos"}
                            numberVariant={data.numberVariant}
                            forCountry={data.forCountry}
                          />
                        </SwiperSlide>
                      ))}
                    </Slider>
                  </ErrorBoundary>
                </Wrapper>
              )}
              {contentTypes.includes("CAROUSEL_ALBUM") && (
                <Wrapper>
                  <ErrorBoundary chartType="cardChartType">
                    <Slider sliderTitle={"Album"}>
                      {albumComments.map((data, index) => (
                        <SwiperSlide key={index}>
                          <Card
                            key={index}
                            cardTitle={data.label}
                            variant={"compare"}
                            showPreviousCompare={false}
                            currentValue={data.value}
                            rowOneTitle={"Total Comments"}
                            second_currentValue={data.count}
                            rowTwoTitle={"No of Albums"}
                            numberVariant={data.numberVariant}
                            forCountry={data.forCountry}
                          />
                        </SwiperSlide>
                      ))}
                    </Slider>
                  </ErrorBoundary>
                </Wrapper>
              )}
              {contentTypes.includes("LINK") && (
                <Wrapper>
                  <ErrorBoundary chartType="cardChartType">
                    <Slider sliderTitle={"Link"}>
                      {linkComments.map((data, index) => (
                        <SwiperSlide key={index}>
                          <Card
                            key={index}
                            cardTitle={data.label}
                            variant={"compare"}
                            showPreviousCompare={false}
                            currentValue={data.value}
                            rowOneTitle={"Total Comments"}
                            second_currentValue={data.count}
                            rowTwoTitle={"No of Links"}
                            numberVariant={data.numberVariant}
                            forCountry={data.forCountry}
                          />
                        </SwiperSlide>
                      ))}
                    </Slider>
                  </ErrorBoundary>
                </Wrapper>
              )}
            </Wrapper>
          )}

          {selectedTab === "Reactions" && (
            <Wrapper>
              {contentTypes.includes("Image") && (
                <Wrapper>
                  <ErrorBoundary chartType="cardChartType">
                    <Slider sliderTitle={"Images"}>
                      {imagesReactions.map((data, index) => (
                        <SwiperSlide key={index}>
                          <Card
                            key={index}
                            cardTitle={data.label}
                            variant={"compare"}
                            showPreviousCompare={false}
                            currentValue={data.value}
                            rowOneTitle={"Total Reactions"}
                            second_currentValue={data.count}
                            rowTwoTitle={"No of Images"}
                            numberVariant={data.numberVariant}
                            forCountry={data.forCountry}
                          />
                        </SwiperSlide>
                      ))}
                    </Slider>
                  </ErrorBoundary>
                </Wrapper>
              )}
              {contentTypes.includes("Video") && (
                <Wrapper>
                  <ErrorBoundary chartType="cardChartType">
                    <Slider sliderTitle={"Videos"}>
                      {videoReactions.map((data, index) => (
                        <SwiperSlide key={index}>
                          <Card
                            key={index}
                            cardTitle={data.label}
                            variant={"compare"}
                            showPreviousCompare={false}
                            currentValue={data.value}
                            rowOneTitle={"Total Reactions"}
                            second_currentValue={data.count}
                            rowTwoTitle={"No of Videos"}
                            numberVariant={data.numberVariant}
                            forCountry={data.forCountry}
                          />
                        </SwiperSlide>
                      ))}
                    </Slider>
                  </ErrorBoundary>
                </Wrapper>
              )}
              {contentTypes.includes("Album") && (
                <Wrapper>
                  <ErrorBoundary chartType="cardChartType">
                    <Slider sliderTitle={"Album"}>
                      {albumReactions.map((data, index) => (
                        <SwiperSlide key={index}>
                          <Card
                            key={index}
                            cardTitle={data.label}
                            variant={"compare"}
                            showPreviousCompare={false}
                            currentValue={data.value}
                            rowOneTitle={"Total Reactions"}
                            second_currentValue={data.count}
                            rowTwoTitle={"No of Albums"}
                            numberVariant={data.numberVariant}
                            forCountry={data.forCountry}
                          />
                        </SwiperSlide>
                      ))}
                    </Slider>
                  </ErrorBoundary>
                </Wrapper>
              )}
              {contentTypes.includes("Link") && (
                <Wrapper>
                  <ErrorBoundary chartType="cardChartType">
                    <Slider sliderTitle={"Link"}>
                      {linkReactions.map((data, index) => (
                        <SwiperSlide key={index}>
                          <Card
                            key={index}
                            cardTitle={data.label}
                            variant={"compare"}
                            showPreviousCompare={false}
                            currentValue={data.value}
                            rowOneTitle={"Total Reactions"}
                            second_currentValue={data.count}
                            rowTwoTitle={"No of Links"}
                            numberVariant={data.numberVariant}
                            forCountry={data.forCountry}
                          />
                        </SwiperSlide>
                      ))}
                    </Slider>
                  </ErrorBoundary>
                </Wrapper>
              )}
            </Wrapper>
          )}
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (competitorsListError) {
    Competitors = <Wrapper><ServerErrorsLoader chartType="cardChartType" error={competitorsListError} /></Wrapper>
  }

  // IF DATA LOADING
  if (isCompetitorsListLoading) {
    Competitors = <Wrapper><Loader loaderType="cardLoader" variantType="compare" /></Wrapper>
  }

  var allData = {
    ...topCardData,
    [selectedTab + " " + "Images"]: eval("images" + selectedTab),
    [selectedTab + " " + "videos"]: eval("video" + selectedTab),
    [selectedTab + " " + "Albums"]: eval("album" + selectedTab),
  };
  useEffect(() => {
    setExportPageName(`Instagram ${selectedTab}`)
    setFullPageExport(allData);
  }, [
    eval("images" + selectedTab),
    eval("video" + selectedTab),
    eval("album" + selectedTab),
  ]);

  useEffect(() => {
    if (competitorsListError !== null ? true : isCompetitorsListLoaded && (isCompetitorsListLoading === false)) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    competitorsListError,
    isCompetitorsListLoaded,
    isCompetitorsListLoading
  ])

  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <div className="tab">
          <ul className="primary_navigation">
            {tabData.map((tab, tabIndex) => {
              return (
                <li
                  key={tabIndex}
                  onClick={() => activeTabHandler(tab)}
                  className={
                    selectedTab === tab ? "tab_item active" : "tab_item"
                  }
                >
                  {tab}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      {Competitors}
    </Wrapper>
  );
};

export default InstagramCompare;
