import Wrapper from "../helpers/Wrapper";

const ProgressBar = ({ targetedValue, achievedValue }) => {
  return (
    <Wrapper>
      <div className="progressbar_section">
        <div className="progressbar_bg">
          <div className="progressbar_target" style={{ left: "80%" }}></div>
          <div
            className="progressbar_range progressbar_current"
            style={{ width: "40%" }}
          ></div>
        </div>
      </div>
      <div className="progressbar_hover">
        <div className="row">
          Targeted: <span className="hover_title">{targetedValue}</span>
        </div>
        <div className="row">
          Achieved: <span className="hover_title">{achievedValue}</span>
        </div>
      </div>
    </Wrapper>
  );
};
export default ProgressBar;
