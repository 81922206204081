import React, { useEffect } from 'react'
import ReactDOM from "react-dom";
import Wrapper from '../helpers/Wrapper'
import Button from './Button';
import { formatDateDisplay } from './Datepicker';
import { useSelector } from 'react-redux';
import moment from 'moment';
import WarningIcon from '../../assets/icons/WarningIcon';
import { capitalizeFirstLetter } from '../../utils/Utils';
import { useLocation, useSearchParams } from 'react-router-dom';

export default function DataUnavailableModal({
  open,
  setOpen,
  setHeader,
  handleDatepicker,
  minDate,
  maxDate,
  brandList
}) {

  const location = useLocation();
  const pathname = location.pathname.split('/')
  const [search] = useSearchParams()

  let activeModule = '';
  let activePlatform = '';
  let activeBrands = '';

  try {
    activeModule = capitalizeFirstLetter(pathname[pathname.length - 1])
    activePlatform = search.get('platform') || search.get('platform') === null ? '' : capitalizeFirstLetter(search.get('platform'))
    activeBrands = brandList.length === 0 ? "" : "Brands " + brandList;
  } catch (error) {
    activeModule = ''
    activePlatform = ''
    activeBrands = ''
  }


  useEffect(() => {
    document.body.classList.toggle('modal_open', open);
    document.body.style.overflow = open ? 'hidden' : ''
  }, [open])


  if (!open) {
    return (<></>)
  }

  window.scroll(0, 0);

  const formattedMinDate = moment(minDate).isValid() ? formatDateDisplay(minDate) : ''
  const formattedMaxDate = moment(maxDate).isValid() ? formatDateDisplay(maxDate) : ''



  return ReactDOM.createPortal(
    <div className='tv_modal_wrapper'>
      <div className='tv_modal'>
        <div className='tv_modal_content'>
          <div className='tv_modal_header'>
            <button
              onClick={() => {
                setOpen(false)
                setHeader(true)
              }}
              className="bg_transparent_icon_btn">
              &times;
            </button>
          </div>
          <div className='tv_modal_body '>
            <div className='tv_modal_icon'>
              <WarningIcon />
            </div>
            <div className='tv_modal_text'>
              <h6 className="mr_5">Warning</h6>
              <p>
                {activeModule} {activePlatform} {activeBrands}  Data is unavailable for selected range.
              </p>
              <p>
                Kindly Select Current and Previous dates within range {formattedMinDate ?? ''} to  {formattedMaxDate ?? ''} to view data.
              </p>

            </div>
          </div>
        </div>
      </div>

    </div>, document.getElementById("modal"))
}


