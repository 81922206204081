import { useEffect, useState } from "react";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import Tab from "../../../../components/ui/Tab";
import SocialTwitterPostOrganic from "./SocialTwitterPostOrganic";
import SocialTwitterPostOverall from "./SocialTwitterPostOverall";
import SocialTwitterPostPaid from "./SocialTwitterPostPaid";

import { IAEvent_Secondary_Tab_Visit } from "../../../../utils/IAEvents";
import EventTracker from "../../../../components/eventsTracking/EventTracker";
import { useSelector } from "react-redux";

const SocialTwitterPost = ({ topCardData }) => {
  const selectedDates = useSelector((state) => state.date);
  // Page DROPDOWN STATE
  const [pageDropdown, setPageDropdown] = useState([]);

  // DROPDOWN OPTIONS
  const pageDropdownOptions = [
    { value: "text", label: "Text" },
    { value: "photo", label: "Photo" },
    { value: "video", label: "Video" },
  ];
  // DROPDOWN OPTIONS SELECTION HANDLER
  const pageDropdownSelectionHandler = (e) => {
    if (e === null) {
      setPageDropdown("");
    } else {
      setPageDropdown(e);
    }

    EventTracker({
      eventType: "dropdown_selected",
      module: "Marketing Intelligence",
      subModule: "Social",
      platform: "Twitter",
      tab: "Post",
      section: "Tweet Type",
      dropdownValue: e === null ? "Overall" : e.label,
      date: {
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate,
        previousStartDate: selectedDates.prevStartDate,
        previousEndDate: selectedDates.prevEndDate,
      }
    })
  };

  const tabData = ["Overall", "Organic", "Paid"];

  const [selectedTab, setSelectedTab] = useState(tabData[0]);

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Secondary_Tab_Visit("Marketing Intelligence", "Social", "Twitter", "Post", event)
  };


  useEffect(() => {
    // topCardData.Summary[0]["Tweet Type"] = pageDropdown === undefined || pageDropdown === null || pageDropdown.length === 0 || pageDropdown === "" ? "" :pageDropdown.label;
  }, [pageDropdown])

  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <div className="grid grid_cols_2">
          <div className="grid col_span_1">
            <Tab
              varient={"secondary"}
              tabData={tabData}
              activeTab={selectedTab}
              handleCallback={(event) => handleCallback(event)}
            />
          </div>
          <div className="grid col_span_1 flex justify_end">
            <Dropdown
              isClearable={true}
              ismulti={false}
              placeholder={"Select Tweet Type"}
              options={pageDropdownOptions}
              className="form_dropdown section_dropdown"
              value={pageDropdown}
              selectedOptions={pageDropdown}
              setStatedropdown={pageDropdownSelectionHandler}
            />
          </div>
        </div>
      </div>
      {/* ,"Tweet Media Type":NoDataAvailableChecker(pageDropdown.label) ? [{"Label" : ""}] : [{"Label" : pageDropdown.label}]  */}
      {selectedTab.toLowerCase() === tabData[0].toLowerCase() && (
        <SocialTwitterPostOverall pageDropdownObject={pageDropdown} topCardData={{ ...topCardData }} />
      )}
      {selectedTab.toLowerCase() === tabData[1].toLowerCase() && (
        <SocialTwitterPostOrganic pageDropdownObject={pageDropdown} topCardData={{ ...topCardData }} />
      )}
      {selectedTab.toLowerCase() === tabData[2].toLowerCase() && (
        <SocialTwitterPostPaid pageDropdownObject={pageDropdown} topCardData={{ ...topCardData }} />
      )}
    </Wrapper>
  );
};

export default SocialTwitterPost;
