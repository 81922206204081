import { useEffect, useState } from "react";
import Wrapper from "../helpers/Wrapper";

import Button from "../ui/Button";
import RegistrationPersonalDetailsForm from "./RegistrationPersonalDetailsForm";
import RegistrationPersonalLocationForm from "./RegistrationPersonalLocationForm";
import RegistrationCompanyDetailsForm from "./RegistrationCompanyDetailsForm";
import RegistrationCompanyLocationForm from "./RegistrationCompanyLocationForm";

import IcogzLogo from "../../assets/brand/icogz-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { API_URL, RequiredField } from "../../constants/constants";

const RegistrationForm = () => {
  const [isLoading, setLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [isCurrentFieldsValidated, setCurrentFieldsValidated] = useState(false);
  const [formErrosLoaded, setFormErrorsLoaded] = useState(false);
  const [formErros, setFormErrors] = useState([]);
  let errorsArray = [];
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNo: "",
    email: "",
    password: "",
    timezone: "",
    addressLine1: "",
    addressLine2: "",
    landmark: "",
    pincode: "",
    companyName: "",
    companyCategory: "",
    companyWebsiteLink: "",
    companyAbout: "",
    companyEmail: "",
    companyMobileNo: "",
    companyTimezone: "",
    companyAddressLine1: "",
    companyAddressLine2: "",
    companyLandmark: "",
    companyCountry: "",
    companyCountryLabel: "",
    companyState: "",
    companyCity: "",
  });
  const [formValidation, setFormValidation] = useState({
    firstName: false,
    middleName: false,
    lastName: false,
    mobileNo: false,
    email: false,
    password: false,
    timezone: false,
    addressLine1: false,
    addressLine2: false,
    landmark: false,
    pincode: false,
    companyName: false,
    companyCategory: false,
    companyWebsiteLink: false,
    companyAbout: false,
    companyEmail: false,
    companyMobileNo: false,
    companyTimezone: false,
    companyAddressLine1: false,
    companyAddressLine2: false,
    companyLandmark: false,
    companyCountry: false,
    companyState: false,
    companyCity: false,
  });
  const [formStepsValidData, setFormStepsValidData] = useState({
    firstStepIsValid: false,
    secondStepIsValid: false,
    thirdStepIsValid: false,
    fourthStepIsValid: false,
  });

  const FormTitles = [
    "Personal Contact Details",
    "Personal Location Details",
    "Company Details",
    "Company Location Details",
  ];

  const pageDisplay = () => {
    if (page === 0) {
      return (
        <RegistrationPersonalDetailsForm
          formData={formData}
          setFormData={setFormData}
          formValidation={formValidation}
          setFormValidation={setFormValidation}
          formStepsData={formStepsValidData}
          setFormStepsData={setFormStepsValidData}
        />
      );
    } else if (page === 1) {
      return (
        <RegistrationPersonalLocationForm
          formData={formData}
          setFormData={setFormData}
          formValidation={formValidation}
          setFormValidation={setFormValidation}
          formStepsData={formStepsValidData}
          setFormStepsData={setFormStepsValidData}
        />
      );
    } else if (page === 2) {
      return (
        <RegistrationCompanyDetailsForm
          formData={formData}
          setFormData={setFormData}
          formValidation={formValidation}
          setFormValidation={setFormValidation}
          formStepsData={formStepsValidData}
          setFormStepsData={setFormStepsValidData}
        />
      );
    } else {
      return (
        <RegistrationCompanyLocationForm
          formData={formData}
          setFormData={setFormData}
          formValidation={formValidation}
          setFormValidation={setFormValidation}
          formStepsData={formStepsValidData}
          setFormStepsData={setFormStepsValidData}
        />
      );
    }
  };

  const navigate = useNavigate();

  const formHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    const ApiData = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      password: formData.password,
      email: formData.email,
      timezone: formData.timezone,
      middle_name: formData.middleName,
      mobile_number: formData.mobileNo,
      address_line_1: formData.addressLine1,
      address_line_2: formData.addressLine2,
      landmark: formData.landmark,
      pincode: formData.pincode,
      company: {
        name: formData.companyName,
        timezone: formData.companyTimezone,
        website_link: formData.companyWebsiteLink,
        address_line_1: formData.companyAddressLine1,
        address_line_2: formData.companyAddressLine2,
        landmark: formData.companyLandmark,
        country: formData.companyCountry,
        state: formData.companyState,
        city: formData.companyCity,
        about: formData.companyAbout,
        email: formData.companyEmail,
        telephone_number: formData.companyMobileNo,
      },
    };

    fetch(API_URL + "auth/register/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(ApiData),
    }).then((res) => {
      setLoading(false);
      if (res.ok) {
        navigate("/login");
      } else {
        return res.json().then((data) => {
          setFormErrorsLoaded(true);

          if (data.data.first_name) {
            errorsArray.push(data.data.first_name[0]);
          }
          if (data.data.middle_name) {
            errorsArray.push(data.data.middle_name[0]);
          }
          if (data.data.last_name) {
            errorsArray.push(data.data.last_name[0]);
          }
          if (data.data.mobile_number) {
            errorsArray.push(data.data.mobile_number[0]);
          }
          if (data.data.email) {
            errorsArray.push(data.data.email[0]);
          }
          if (data.data.password) {
            errorsArray.push(data.data.password[0]);
          }

          if (data.data.timezone) {
            errorsArray.push(data.data.timezone[0]);
          }
          if (data.data.address_line_1) {
            errorsArray.push(data.data.address_line_1[0]);
          }
          if (data.data.address_line_2) {
            errorsArray.push(data.data.address_line_2[0]);
          }
          if (data.data.landmark) {
            errorsArray.push(data.data.landmark[0]);
          }
          if (data.data.pincode) {
            errorsArray.push(data.data.pincode[0]);
          }

          if (data.data.company !== undefined) {
            if (data.data.company.name) {
              errorsArray.push(data.data.company.name[0]);
            }
            if (data.data.company.about) {
              errorsArray.push(data.data.company.about[0]);
            }
            if (data.data.company.website_link) {
              errorsArray.push(data.data.company.website_link[0]);
            }
            if (data.data.company.email) {
              errorsArray.push(data.data.company.email);
            }
            if (data.data.company.telephone_number) {
              errorsArray.push(data.data.company.telephone_number[0]);
            }

            if (data.data.company.timezone) {
              errorsArray.push(data.data.company.timezone[0]);
            }
            if (data.data.company.address_line_1) {
              errorsArray.push(data.data.company.address_line_1[0]);
            }
            if (data.data.company.address_line_2) {
              errorsArray.push(data.data.company.address_line_2[0]);
            }
            if (data.data.company.landmark) {
              errorsArray.push(data.data.company.landmark[0]);
            }
            if (data.data.company.country) {
              errorsArray.push(data.data.company.country[0]);
            }
            if (data.data.company.state) {
              errorsArray.push(data.data.company.state[0]);
            }
            if (data.data.company.city) {
              errorsArray.push(data.data.company.city[0]);
            }
          }

          setFormErrors(errorsArray);
        });
      }
    });
  };
  useEffect(() => {
    switch (page) {
      case 0:
        return formStepsValidData.firstStepIsValid === true
          ? setCurrentFieldsValidated(true)
          : setCurrentFieldsValidated(false);
      case 1:
        return formStepsValidData.firstStepIsValid === true &&
          formStepsValidData.secondStepIsValid === true
          ? setCurrentFieldsValidated(true)
          : setCurrentFieldsValidated(false);
      case 2:
        return formStepsValidData.firstStepIsValid === true &&
          formStepsValidData.secondStepIsValid === true &&
          formStepsValidData.thirdStepIsValid === true
          ? setCurrentFieldsValidated(true)
          : setCurrentFieldsValidated(false);
      case 3:
        return formStepsValidData.firstStepIsValid === true &&
          formStepsValidData.secondStepIsValid === true &&
          formStepsValidData.thirdStepIsValid === true &&
          formStepsValidData.fourthStepIsValid === true
          ? setCurrentFieldsValidated(true)
          : setCurrentFieldsValidated(false);
      default:
        return setCurrentFieldsValidated(false);
    }
  }, [formStepsValidData, page]);

  return (
    <Wrapper>
      <div className="form_wrapper">
        <div className="form_header flex column align_center p_10">
          <div className="form_header_logo">
            <img className="logo" src={IcogzLogo} alt="logo"/>
          </div>
          <div className="form_steps_wrapper p_10">
            <div className="form_steps_header_wrapper">
              <div
                onClick={() => setPage(0)}
                className="form_steps_header active"
              >
                1
              </div>
              <div
                onClick={() => setPage(1)}
                className={
                  page === 1 || page > 1
                    ? "form_steps_header active"
                    : "form_steps_header"
                }
              >
                2
              </div>
              <div
                onClick={() => setPage(2)}
                className={
                  page === 2 || page > 2
                    ? "form_steps_header active"
                    : "form_steps_header"
                }
              >
                3
              </div>
              <div
                onClick={() => setPage(3)}
                className={
                  page === 3 ? "form_steps_header active" : "form_steps_header"
                }
              >
                4
              </div>
            </div>
            <div className="form_steps_path_wrapper">
              <div
                className="active"
                style={{
                  height: "100%",
                  width:
                    page === 0
                      ? "20%"
                      : page === 1
                        ? "50%"
                        : page === 2
                          ? "83%"
                          : "100%",
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="p_10 flex justify_center">
          <h4 className="form_subtitle">{FormTitles[page]}</h4>
        </div>
        {formErrosLoaded && (
          <div className="p_10 flex column justify_center align_center">
            {formErros.map((error, index) => {
              return (
                <h6 key={index} className="error_text p_10">
                  {" "}
                  {error}
                </h6>
              );
            })}
          </div>
        )}
        <form className="form" action="">
          <div className="form_group_container">{pageDisplay()}</div>
          <div className="flex justify_between p_10">
            <div className="info_text help_text">*Required Information</div>
            <Link to={"/login"}>
              <div className="help_text">Login with existing account</div>
            </Link>
          </div>
          <div className="flex justify_between">
            {page !== 0 && (
              <div className="btn_wrapper btn_group_wrapper">
                <Button
                  handleClick={() => setPage((currentPage) => currentPage - 1)}
                  className="secondary_btn"
                  type="button"
                  disabled={page == 0}
                >
                  Prev
                </Button>
              </div>
            )}
            <div className="btn_wrapper btn_group_wrapper">
              <Button
                handleClick={
                  page === FormTitles.length - 1
                    ? (event) => formHandler(event)
                    : () => setPage((currentPage) => currentPage + 1)
                }
                disabled={!isCurrentFieldsValidated}
                className="primary_btn"
                type="button"
              >
                {page === FormTitles.length - 1
                  ? !isLoading
                    ? "Create an account"
                    : "Checking your details"
                  : "Next"}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Wrapper>
  );
};

export default RegistrationForm;
