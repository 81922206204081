const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    selectedCampaignPlatform: [],
    selectedCampaignObjectives: [],
    selectedCampaigns: [],
    selectedBuckets: [],
    selectedAllBuckets: [],
    selectedCampaignIDandPlatform: []
};

const campaignsFilterSlice = createSlice({
    name: "campaignsFilterDropdown",
    initialState,
    reducers: {
        setSelectedCampaignPlatform(state, action) {
            state.selectedCampaignPlatform = action.payload;
        },
        setSelectedCampaignObjectives(state, action) {
            state.selectedCampaignObjectives = action.payload;
        },
        setSelectedFilterCampaigns(state, action) {
            state.selectedCampaigns = action.payload;
        },
        setSelectedBucketCampaigns(state, action) {
            state.selectedBuckets = action.payload;
        },
        setSelectedAllBucketCampaigns(state, action) {
            state.selectedAllBuckets = action.payload;
        },
        setSelectedCampaignIDandPlatform(state, action) {
            state.selectedCampaignIDandPlatform = action.payload;
        },
        resetcampaignsFilterDropdown: () => initialState
    },
});

export const { setSelectedCampaignPlatform, setSelectedCampaignObjectives, setSelectedFilterCampaigns, setSelectedBucketCampaigns, setSelectedAllBucketCampaigns, setSelectedCampaignIDandPlatform, resetcampaignsFilterDropdown } = campaignsFilterSlice.actions;

export default campaignsFilterSlice.reducer;
