import { useEffect } from "react";
import { createPortal } from "react-dom";
import { useLocation, useSearchParams } from "react-router-dom";
import WarningIcon from "../../assets/icons/WarningIcon";
import moment from "moment";
import { formatDateDisplay } from "./Datepicker";
import { capitalizeFirstLetter } from "../../utils/Utils";

export default function DataUnavailableHeader({ show, dateValues, setShowDataUnavailableHeader, brandList }) {

  const location = useLocation();
  const pathname = location.pathname.split('/')
  const [search] = useSearchParams()


  let activeModule = '';
  // let activePlatform = '';
  // let activeBrands = '';

  try {
    activeModule = capitalizeFirstLetter(pathname[pathname.length - 1])
    // activePlatform = search.get('platform') || search.get('platform') === null ? '' : capitalizeFirstLetter(search.get('platform'))
    // activeBrands = brandList.length === 0 ? "" : "Brands " + brandList;
  } catch (error) {
    activeModule = ''
    // activePlatform = ''
    // activeBrands = ''
  }


  let pathSet = pathname.includes('tv') || pathname.includes('sales') ? 'data-unavailable-map-section' : 'data-unavailable-header'

  useEffect(() => {

    if (show) {
      document.getElementById('content_wrapper').classList.add('show')
    } else {
      document.getElementById('content_wrapper').classList.remove('show')
    }
  }, [show])

  if (!show) {
    return <></>
  }

  const formattedMinDate = moment(dateValues.minDate).isValid() ? formatDateDisplay(dateValues.minDate) : ''
  const formattedMaxDate = moment(dateValues.maxDate).isValid() ? formatDateDisplay(dateValues.maxDate) : ''


  let isPathNull = document.getElementById(pathSet) ?? false

  return isPathNull && createPortal(
    <div className="sticky_header_wrapper" >
      <div className="sticky_header_content flex justify_between align_center">
        <div className="sticky_header_body">
          <WarningIcon />
          <b className="mr_5">Warning :</b>
          <span>
            {activeModule} Data is not available for the selected date range.
             Please select Current and Previous dates from {formattedMinDate} to {formattedMaxDate}.
          </span>
        </div>
        <button className="bg_transparent_icon_btn" onClick={() => setShowDataUnavailableHeader(false)}>
          &times;
        </button>
      </div>

    </div>,
    document.getElementById(pathSet)
  )
}
