const TVIcon = (props) => {
    return (
        <svg className={props.className} width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_17_2)">
                <path d="M41 10.75H13C11.7574 10.75 10.75 11.7574 10.75 13V35C10.75 36.2426 11.7574 37.25 13 37.25H41C42.2426 37.25 43.25 36.2426 43.25 35V13C43.25 11.7574 42.2426 10.75 41 10.75Z" strokeWidth="1.5" />
                <path d="M19 40.75H35C35.3315 40.75 35.6495 40.8817 35.8839 41.1161C36.1183 41.3505 36.25 41.6685 36.25 42V42.5C36.25 42.6989 36.171 42.8897 36.0303 43.0303C35.8897 43.171 35.6989 43.25 35.5 43.25H18.5C18.3011 43.25 18.1103 43.171 17.9697 43.0303C17.829 42.8897 17.75 42.6989 17.75 42.5V42C17.75 41.6685 17.8817 41.3505 18.1161 41.1161C18.3505 40.8817 18.6685 40.75 19 40.75V40.75Z" strokeWidth="1.5" />
                <path d="M22.667 37.111L20.741 40.963" strokeWidth="1.5" />
                <path d="M31.334 37.111L33.26 40.963" strokeWidth="1.5" />
            </g>
            <defs>
                <clipPath id="clip0_17_2">
                    <rect width="54" height="54" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default TVIcon;
