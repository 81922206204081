import Wrapper from "../../components/helpers/Wrapper";

const IconHideEye = (props) => {
    return (
        <Wrapper>
            <svg className={props.className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.9178 9.64972C19.3028 8.41988 18.4958 7.30532 17.4965 6.42136L14.2297 9.64972C14.2297 9.76504 14.2297 9.88032 14.2297 9.99564C14.2297 12.34 12.3465 14.2232 10.0021 14.2232C9.88681 14.2232 9.77149 14.2232 9.65621 14.2232L7.58081 16.2986C8.34949 16.4524 9.15657 16.5676 9.96365 16.5676C14.3066 16.5676 18.073 14.0311 19.8793 10.38C20.033 10.1109 20.033 9.88032 19.9178 9.64972Z" />
                <path d="M18.6495 2.15534L17.8424 1.34825C17.6118 1.11766 17.1891 1.15609 16.92 1.46355L14.1144 4.26913C12.8462 3.73109 11.4626 3.46206 10.0022 3.46206C5.65924 3.46206 1.89282 5.99861 0.086474 9.64973C-0.0288247 9.88033 -0.0288247 10.1494 0.086474 10.3415C0.931996 12.071 2.20028 13.493 3.77602 14.5691L1.47006 16.9135C1.20102 17.1826 1.16259 17.6053 1.35476 17.8359L2.16185 18.643C2.39244 18.8736 2.8152 18.8352 3.08424 18.5277L18.5342 3.07773C18.8417 2.8087 18.8801 2.38594 18.6495 2.15534ZM5.77452 9.99565C5.77452 7.65125 7.65776 5.76801 10.0022 5.76801C10.7708 5.76801 11.4626 5.96021 12.0775 6.30609L10.9245 7.45905C10.6171 7.38221 10.3096 7.30533 10.0022 7.30533C8.50328 7.30533 7.31184 8.49677 7.31184 9.99565C7.31184 10.3031 7.38872 10.6106 7.46556 10.918L6.3126 12.071C5.96668 11.4561 5.77452 10.7643 5.77452 9.99565Z" />
            </svg>



        </Wrapper>
    )
}

export default IconHideEye;
