import React, { useMemo, useState, useEffect, useContext } from "react";
import { SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";

// STORAGE 
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Card from "../../../../components/ui/Card";
import Wrapper from "../../../../components/helpers/Wrapper";
import Slider from "../../../../components/ui/Slider";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import TopProductCompare from "../../../../components/ui/TopProductCompare";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";

// CHARTS 
import CombinedBarLineMultipleAxesChart from "../../../../components/ui/charts/CombinedBarLineMultipleAxesChart";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import HeatMap from "../../../../components/ui/charts/HeatMap";
import LineChart from "../../../../components/ui/charts/LineChart";
import PieofaPieChart from "../../../../components/ui/charts/PieofaPieChart";

// LOADERS
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import Loader from "../../../../components/ui/loaders/Loader";

// UTILS
import NumberFormatter from "../../../../utils/NumberFormatter";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { IAEvent_Hashtag_Visit } from "../../../../utils/IAEvents";

import PartitionedCombinedBarLineMultipleAxesChart from "../../../../components/ui/charts/PartitionedCombinedBarLineMultipleAxesChart";
import CombinedBarLineChart from "../../../../components/ui/charts/CombinedBarLineChart";
import ExpandIcon from "../../../../assets/icons/ExpandIcon";
import Modal from "../../../../components/ui/Modal";

export default function LinkedinAllVideosOverall({
  videoDurationType,
  topCardData,
}) {
  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Video"
  let pagePlatform = "Linkedin"
  let pagePrimaryTab = "Overall"

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, exportSummary, setIsExportDisabled } = useContext(FullPageExportContext);

  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const selectedDates = useSelector((state) => state.date);

  const [searchTerm, setSeachTerm] = useState("");

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // VIDEO LENGTH ANALYSIS
  const [videoLengthAnalysis, setVideoLengthAnalysis] = useState([]);
  const [videoLengthAnalysisLabel, setVideoLengthAnalysisLabel] = useState([]);
  const [isVideoLengthAnalysisLoading, setVideoLengthAnalysisIsLoading] = useState(false);
  const [isVideoLengthAnalysisLoaded, setVideoLengthAnalysisIsLoaded] = useState(false);
  const [videoLengthAnalysiserror, setVideoLengthAnalysisError] = useState(null);

  // DAILY TREND LINE
  const [dailyTrendLine, setDailyTrendLine] = useState([]);
  const [dailyTrendLineLabel, setDailyTrendLineLabel] = useState([]);
  const [isDailyTrendLineLoading, setDailyTrendLineIsLoading] = useState(false);
  const [isDailyTrendLineLoaded, setDailyTrendLineIsLoaded] = useState(false);
  const [dailyTrendLineError, setDailyTrendLineError] = useState(null);

  // PUBLISHED TIME VIEW ANALYSIS
  const [audienceActivityTrend, setAudienceActivityTrend] = useState([]);
  const [audienceActivityTrendXaxisLabel, setAudienceActivityTrendXaxisLabel] = useState([]);
  const [audienceActivityTrendYaxisLabel, setAudienceActivityTrendYaxisLabel] = useState([]);
  const [isAudienceActivityTrendLoading, setAudienceActivityTrendIsLoading] = useState(false);
  const [isAudienceActivityTrendLoaded, setAudienceActivityTrendIsLoaded] = useState(false);
  const [audienceActivityTrendError, setAudienceActivityTrendError] = useState(null);

  // VIDEO RATE ANALYSIS
  const [videoRateAnalysis, setVideoRateAnalysis] = useState([]);
  const [videoRateAnalysisLabel, setVideoRateAnalysisLabel] = useState([]);
  const [isVideoRateAnalysisLoading, setVideoRateAnalysisIsLoading] = useState(false);
  const [isVideoRateAnalysisLoaded, setVideoRateAnalysisIsLoaded] = useState(false);
  const [videoRateAnalysisError, setVideoRateAnalysisError] = useState(null);

  // VIDEO ENGAGEMENT
  const [videoEngagement, setVideoEngagement] = useState([]);
  const [isVideoEngagementLoading, setVideoEngagementIsLoading] = useState(false);
  const [isVideoEngagementLoaded, setVideoEngagementIsLoaded] = useState(false);
  const [videoEngagementerror, setVideoEngagementError] = useState(null);

  // COMMENT SENTIMENT
  const [commentSentiment, setCommentSentiment] = useState([]);
  const [isCommentSentimentLoading, setCommentSentimentIsLoading] = useState(false);
  const [isCommentSentimentLoaded, setCommentSentimentIsLoaded] = useState(false);
  const [commentSentimenterror, setCommentSentimentError] = useState(null);

  // TOP POST 
  const [topPosts, setTopPosts] = useState([]);
  const [topPostsLoading, settopPostsLoading] = useState(false);
  const [topPostsLoaded, setTopPostsloaded] = useState(false);
  const [topPostserror, setTopPostsError] = useState(null);

  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState();

  const CallAPIs = (hashtag) => {

    fetchMetricCardsHandler(hashtag);
    fetchAudienceActivityTrendHandler(hashtag);
    fetchDailyTrendLineHandler(hashtag);
    fetchVideoRateAnalysisHandler(hashtag);
    fetchVideEngagementHandler(hashtag);
    fetchCommentSentimentHandler(hashtag);
    fetchVideoLengthAnalysisHandler(hashtag);
    fetchTopPostsHandler(hashtag);

  }

  useEffect(() => {
    fetchHashtagListHandler()
    CallAPIs()
    setSelectedHashtag('')
  }, [
    BRAND_ID,
    selectedDates,
    // selectedHashtag,
    videoDurationType,
  ]);

  // useEffect(() => {
  //   fetchHashtagListHandler();
  // }, [BRAND_ID, selectedDates, videoDurationType]);

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag === "") {
      setSelectedHashtag(badge.hashtag);
      CallAPIs(badge.hashtag);
    } else if (selectedHashtag === badge.hashtag) {
      setSelectedHashtag("");
      CallAPIs();
    } else {
      setSelectedHashtag(badge.hashtag);
      CallAPIs(badge.hashtag);
    }
    IAEvent_Hashtag_Visit("Marketing Intelligence", "Video", "Linkedin", null, "Overall", badge.hashtag)

  };

  // TO FETCH TRENDING HASHTAG LIST FROM API
  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/linkedin/overall/hashtag/", config);

      setHashtagList(res?.data ?? []);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  let topTrendingHashtagsList = <Wrapper><Loader loaderType="hashLoader" /></Wrapper>;

  if (isHashtagListLoaded && !isHashtagListLoading) {
    if (NoDataAvailableChecker(filteredHashtagList) || filteredHashtagList[0] === null) {
      topTrendingHashtagsList = <Wrapper><NoDataAvailableLoader chartType="hashChartType" /></Wrapper>;
    } else {
      topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
        <Wrapper key={index}>
          <ErrorBoundary chartType="hashChartType">
            <div className="badge_wrapper">
              <button
                type="button"
                className={
                  selectedHashtag === badge.hashtag
                    ? "button_badge active"
                    : "button_badge"
                }
                onClick={() => handleEdit(badge)}
              >
                {badge.hashtag}
              </button>
            </div>
          </ErrorBoundary>
        </Wrapper>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = <Wrapper><ServerErrorsLoader chartType="hashChartType" error={hashtagListerror} /></Wrapper>;
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = <Wrapper><Loader loaderType="hashLoader" /></Wrapper>;
  }

  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader = ` : ` + (selectedHashtag !== undefined ? selectedHashtag : "");
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS
  const fetchMetricCardsHandler = async (hashtag) => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/linkedin/overall/metric-card/",
        config
      );

      setMetricCards(res?.data?.data ?? []);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </div>
      </Wrapper>;
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary" + selectedHashtagSectionHeader}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="video"
                    platform="linkedin"
                    section="overall"
                    key={index}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader chartType="cardChartType" error={metricCardserror} />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection =
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIDEO LENGTH ANALYSIS
  const fetchVideoLengthAnalysisHandler = async (hashtag) => {
    setVideoLengthAnalysisIsLoading(true);
    setVideoLengthAnalysisIsLoaded(false);
    setVideoLengthAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/linkedin/overall/bucket/", config);

      setVideoLengthAnalysis(res?.data?.data ?? []);
      setVideoLengthAnalysisLabel(res?.data?.label ?? []);
      setVideoLengthAnalysisIsLoading(false);
      setVideoLengthAnalysisIsLoaded(true);
      setVideoLengthAnalysisError(null);
    } catch (error) {
      setVideoLengthAnalysis([]);
      setVideoLengthAnalysisLabel([]);
      setVideoLengthAnalysisIsLoading(false);
      setVideoLengthAnalysisIsLoaded(false);
      setVideoLengthAnalysisError(error.code);
    }
  };

  let videoLengthAnalysisChart = (
    <Wrapper>
      <Loader loaderType="pieChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isVideoLengthAnalysisLoaded && !isVideoLengthAnalysisLoading) {
    if (NoDataAvailableChecker(videoLengthAnalysis)
    ) {
      videoLengthAnalysisChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="pieChartType" />
        </Wrapper>
      );
    } else {
      videoLengthAnalysisChart = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <PieofaPieChart
              chartClass="section_card_chart"
              chartData={videoLengthAnalysis}
              chartId="videoLengthAnalysisChart"
              chartLabels={videoLengthAnalysisLabel}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoLengthAnalysiserror) {
    videoLengthAnalysisChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="pieChartType"
          error={videoLengthAnalysiserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVideoLengthAnalysisLoading) {
    videoLengthAnalysisChart =
      <Wrapper>
        <Loader loaderType="pieChartLoader" />
      </Wrapper>
  }




  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // DAILY TREND LINE

  const fetchDailyTrendLineHandler = async (hashtag) => {
    setDailyTrendLineIsLoading(true);
    setDailyTrendLineIsLoaded(false);
    setDailyTrendLineError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/linkedin/overall/daily-trend/",
        config
      );

      setDailyTrendLine(res?.data?.data ?? []);
      setDailyTrendLineLabel(res?.data?.dimension ?? []);
      setDailyTrendLineIsLoading(false);
      setDailyTrendLineIsLoaded(true);
      setDailyTrendLineError(null);
    } catch (error) {
      setDailyTrendLine([]);
      setDailyTrendLineLabel([]);
      setDailyTrendLineIsLoading(false);
      setDailyTrendLineIsLoaded(false);
      setDailyTrendLineError(error.code);
    }
  };

  let dailyTrendLineSection = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>;
  // IF DATA IS LOADED
  if (isDailyTrendLineLoaded && !isDailyTrendLineLoading) {
    if (NoDataAvailableChecker(dailyTrendLine) || NoDataAvailableChecker(dailyTrendLineLabel)) {
      dailyTrendLineSection = <Wrapper><NoDataAvailableLoader chartType="lineChartType" /></Wrapper>;
    } else {
      dailyTrendLineSection = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartClass="section_card_chart"
              chartData={dailyTrendLine}
              chartId="dailyTrendLine"
              chartLabels={dailyTrendLineLabel}
              onBulletsClick={() => { }}
              showLabels
              showLegend
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (dailyTrendLineError) {
    dailyTrendLineSection = <Wrapper><ServerErrorsLoader chartType="lineChartType" error={dailyTrendLineError} /></Wrapper>;
  }

  // IF DATA LOADING
  if (isDailyTrendLineLoading) {
    dailyTrendLineSection = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PUBLISHED TIME VIEWS ANALYSIS

  const fetchAudienceActivityTrendHandler = async (hashtag) => {
    setAudienceActivityTrendIsLoading(true);
    setAudienceActivityTrendIsLoaded(false);
    setAudienceActivityTrendError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/linkedin/overall/heatmap/", config);

      setAudienceActivityTrend(res?.data?.data ?? []);
      setAudienceActivityTrendXaxisLabel(res?.data?.xaxis_labels ?? []);
      setAudienceActivityTrendYaxisLabel(res?.data?.yaxis_labels ?? []);
      setAudienceActivityTrendIsLoading(false);
      setAudienceActivityTrendIsLoaded(true);
      setAudienceActivityTrendError(null);
    } catch (error) {
      setAudienceActivityTrend([]);
      setAudienceActivityTrendXaxisLabel([]);
      setAudienceActivityTrendYaxisLabel([]);
      setAudienceActivityTrendIsLoading(false);
      setAudienceActivityTrendIsLoaded(false);
      setAudienceActivityTrendError(error.code);
    }
  };

  let audienceActivityTrendSection = <Wrapper><Loader loaderType="heatChartLoader" /></Wrapper>;
  // IF DATA IS LOADED
  if (isAudienceActivityTrendLoaded && !isAudienceActivityTrendLoading) {
    if (
      NoDataAvailableChecker(audienceActivityTrend) ||
      NoDataAvailableChecker(audienceActivityTrendXaxisLabel) ||
      NoDataAvailableChecker(audienceActivityTrendYaxisLabel)
    ) {
      audienceActivityTrendSection = <Wrapper><NoDataAvailableLoader chartType="heatChartType" /></Wrapper>;
    } else {
      audienceActivityTrendSection = (
        <Wrapper>
          <ErrorBoundary chartType="heatChartType">
            <HeatMap
              chartClass="section_card_chart"
              chartId="heatmapChart"
              chartXaxisLabels={audienceActivityTrendXaxisLabel}
              chartYaxisLabels={audienceActivityTrendYaxisLabel}
              chartData={audienceActivityTrend}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (audienceActivityTrendError) {
    audienceActivityTrendSection = <Wrapper><ServerErrorsLoader chartType="heatChartType" error={audienceActivityTrendError} /></Wrapper>;
  }

  // IF DATA LOADING
  if (isAudienceActivityTrendLoading) {
    audienceActivityTrendSection = <Wrapper><Loader loaderType="heatChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIDEO RATE ANALYSIS

  const [isVideoRateOpen, setIsVideoRateOpen] = useState(false)

  let defaultVideoRateDisabledValues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultVideoRateDisabledValues = []
  } else {
    defaultVideoRateDisabledValues = ["Sales Volume"]
  }

  const [videoRateDisabledValues, setVideoRateDisabledValues] = useState(defaultVideoRateDisabledValues)



  const fetchVideoRateAnalysisHandler = async (hashtag) => {
    setVideoRateAnalysisIsLoading(true);
    setVideoRateAnalysisIsLoaded(false);
    setVideoRateAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/linkedin/overall/bar-graph/", config);

      setVideoRateAnalysis(res?.data?.data ?? []);
      setVideoRateAnalysisLabel(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.Dimensions === undefined || res.data.Dimensions.length === 0 ? [] :
            res.data.Dimensions[0].linechartLabels === undefined || res.data.Dimensions[0].linechartLabels.length === 0 ? [] :
              res.data.Dimensions[0].barchartLabels === undefined || res.data.Dimensions[0].barchartLabels.length === 0 ? [] :
                res.data.Dimensions
      );

      setVideoRateAnalysisIsLoading(false);
      setVideoRateAnalysisIsLoaded(true);
      setVideoRateAnalysisError(null);
    } catch (error) {
      setVideoRateAnalysis([]);
      setVideoRateAnalysisLabel([]);
      setVideoRateAnalysisIsLoading(false);
      setVideoRateAnalysisIsLoaded(false);
      setVideoRateAnalysisError(error.code);
    }
  };

  let videoRateAnalysisSection = (
    <Wrapper>
      <Loader loaderType="combinedBarLineChartLoader" />
    </Wrapper>
  );

  let videoRateAnalysisSection2 =
    <Wrapper>
      <Loader loaderType="combinedBarLineChartLoader" />
    </Wrapper>

  // IF DATA IS LOADED
  if (isVideoRateAnalysisLoaded && !isVideoRateAnalysisLoading) {
    if (
      NoDataAvailableChecker(videoRateAnalysis) ||
      NoDataAvailableChecker(videoRateAnalysisLabel)
    ) {
      videoRateAnalysisSection =
        <Wrapper>
          <NoDataAvailableLoader chartType="combinedBarLineChartType" />
        </Wrapper>
      videoRateAnalysisSection2 =
        <Wrapper>
          <NoDataAvailableLoader chartType="combinedBarLineChartType" />
        </Wrapper>
    } else {
      if (isMultiLineCluster) {
        videoRateAnalysisSection = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <CombinedBarLineChart
                chartClass="section_card_chart"
                chartId="videoRateAnalysis_1"
                chartData={videoRateAnalysis}
                chartLabels={videoRateAnalysisLabel}
                showLegend={true}
                disabledLegendArray={videoRateDisabledValues}
                setDisabledValues={setVideoRateDisabledValues}

              />
            </ErrorBoundary>
          </Wrapper>
        );
        videoRateAnalysisSection2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedCombinedBarLineMultipleAxesChart
                chartClass="section_card_chart"
                chartId="videoRateAnalysis_2"
                chartData={videoRateAnalysis}
                chartLabels={videoRateAnalysisLabel}
                showLegend={true}
                disabledLegendArray={videoRateDisabledValues}
                setDisabledValues={setVideoRateDisabledValues}
                showLabels={true}
                showSecondY={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
      else {

        videoRateAnalysisSection = (
          <Wrapper>
            <ErrorBoundary chartType="combinedBarLineChartType" >
              <CombinedBarLineMultipleAxesChart
                chartClass="section_card_chart"
                chartData={videoRateAnalysis}
                chartId="videoRateAnalysis"
                chartLabels={videoRateAnalysisLabel}
              />
            </ErrorBoundary>
          </Wrapper>
        );

      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoRateAnalysisError) {
    videoRateAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader chartType="combinedBarLineChartType" error={videoRateAnalysisError} />
      </Wrapper>
    );
    videoRateAnalysisSection2 = (
      <Wrapper>
        <ServerErrorsLoader chartType="combinedBarLineChartType" error={videoRateAnalysisError} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (videoRateAnalysisError) {
    videoRateAnalysisSection =
      <Wrapper>
        <Loader loaderType="combinedBarLineChartLoader" />
      </Wrapper>

    videoRateAnalysisSection2 =
      <Wrapper>
        <Loader loaderType="combinedBarLineChartLoader" />
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIDEO ENAGEMENT
  const fetchVideEngagementHandler = async (hashtag) => {
    setVideoEngagementIsLoading(true);
    setVideoEngagementIsLoaded(false);
    setVideoEngagementError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/linkedin/overall/reactions/", config);

      setVideoEngagement(res?.data ?? []);
      setVideoEngagementIsLoading(false);
      setVideoEngagementIsLoaded(true);
      setVideoEngagementError(null);
    } catch (error) {
      setVideoEngagement([]);
      setVideoEngagementIsLoading(false);
      setVideoEngagementIsLoaded(false);
      setVideoEngagementError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let videoEngagementSection = <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>;
  let videoEngagementTable = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isVideoEngagementLoaded && !isVideoEngagementLoading) {
    if (NoDataAvailableChecker(videoEngagement)) {
      videoEngagementSection = <Wrapper><NoDataAvailableLoader chartType="donutChartType" /></Wrapper>;
      videoEngagementTable = <Wrapper><NoDataAvailableLoader chartType="tableChartType" /></Wrapper>;
    } else {
      videoEngagementSection = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={videoEngagement}
              chartId="videoEngagement"
              showLabels
              showLegend
              showVerticalLabels
            />
          </ErrorBoundary>
        </Wrapper>
      );

      videoEngagementTable = (
        <Wrapper>
          <div className="chart_details_wrapper">
            <div className="main_wrapper">
              <div className="body">
                {videoEngagement && videoEngagement.map(
                  (details, postImpressionIndex) => (
                    <Wrapper key={postImpressionIndex}>
                      <div className="row">
                        <div className="title_wrapper">
                          {details.label}
                        </div>
                        <div className="value_wrapper">
                          <ErrorBoundary>
                            <NumberFormatter number={details.value} />
                          </ErrorBoundary>
                        </div>
                      </div>
                    </Wrapper>
                  ))}
              </div>
            </div>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoEngagementerror) {
    videoEngagementSection = <Wrapper><ServerErrorsLoader chartType="donutChartType" error={videoEngagementerror} /></Wrapper>;
    videoEngagementTable = <Wrapper><ServerErrorsLoader chartType="tableChartType" error={videoEngagementerror} /></Wrapper>;
  }

  // IF DATA LOADING
  if (isVideoEngagementLoading) {
    videoEngagementSection = <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>;
    videoEngagementTable = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COMMENT SENTIMENT

  const fetchCommentSentimentHandler = async (hashtag) => {
    setCommentSentimentIsLoading(true);
    setCommentSentimentIsLoaded(false);
    setCommentSentimentError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/linkedin/overall/comment-sentiment/",
        config
      );

      setCommentSentiment(res?.data ?? []);
      setCommentSentimentIsLoading(false);
      setCommentSentimentIsLoaded(true);
      setCommentSentimentError(null);
    } catch (error) {
      setCommentSentiment([]);
      setCommentSentimentIsLoading(false);
      setCommentSentimentIsLoaded(false);
      setCommentSentimentError(error.code);
    }
  };

  let commentSentimentSection = <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>;

  let commentSentimentTable = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isCommentSentimentLoaded && !isCommentSentimentLoading) {
    if (NoDataAvailableChecker(commentSentiment)) {
      commentSentimentSection = (
        <Wrapper><NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
      );
      commentSentimentTable = (
        <Wrapper><NoDataAvailableLoader chartType="tableChartType" /></Wrapper>
      );
    } else {
      commentSentimentSection = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={commentSentiment}
              chartId="commentSentiment"
              showLabels
              showLegend
              showVerticalLabels
            />
          </ErrorBoundary>
        </Wrapper>
      );

      commentSentimentTable = (
        <Wrapper>
          <ErrorBoundary>
            <div className="chart_details_wrapper">
              <div className="main_wrapper">
                <div className="body">
                  {commentSentiment && commentSentiment.map(
                    (details, postImpressionIndex) => (
                      <Wrapper key={postImpressionIndex}>
                        <div className="row">
                          <div className="title_wrapper">
                            {details.label}
                          </div>
                          <div className="value_wrapper">
                            <ErrorBoundary>
                              <NumberFormatter number={details.value} />
                            </ErrorBoundary>
                          </div>
                        </div>
                      </Wrapper>
                    ))}
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (commentSentimenterror) {
    commentSentimentSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              error={commentSentimenterror}
              chartType="donutChartType"
            />
          </div>
        </div>
      </Wrapper>
    );
    commentSentimentTable = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={commentSentimenterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCommentSentimentLoading) {
    commentSentimentSection = <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>;
    commentSentimentTable = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>;
  }

  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  // TOP POST
  const fetchTopPostsHandler = async (hashtag) => {
    settopPostsLoading(true);
    setTopPostsloaded(false);
    setTopPostsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: hashtag ?? null,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/linkedin/overall/top-five-post/",
        config
      );

      setTopPosts(res?.data ?? []);
      settopPostsLoading(false);
      setTopPostsloaded(true);
      setTopPostsError(null);
    } catch (error) {
      setTopPosts([]);
      settopPostsLoading(false);
      setTopPostsloaded(false);
      setTopPostsError(error.code);
    }
  };

  // Top Tweets Chart
  let topPostsChart = (
    <Wrapper>
      <div className="section_card fullpage_section_card grid">
        <div className="grid col_span_4 section_card_header">

          <div className="section_info">
            <h2 className="section_card_title">Top 5 Videos</h2>
            <SectionDefinationsBadge
              title={"Top 5 Videos"}
              module="video"
              platform="linkedin"
              section="overall"
            />

          </div>
        </div>
        <div className="grid col_span_4">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </div>
    </Wrapper>
  );
  // IF DATA IS LOADED
  if (topPostsLoaded && !topPostsLoading) {
    if (NoDataAvailableChecker(topPosts)) {
      topPostsChart = (
        <Wrapper>
          <div className="section_card fullpage_section_card grid">
            <div className="grid col_span_4 section_card_header">

              <div className="section_info">
                <h2 className="section_card_title">Top 5 Videos</h2>
                <SectionDefinationsBadge
                  title={"Top 5 Videos"}
                  module="video"
                  platform="linkedin"
                  section="overall"
                /></div>

            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      topPostsChart = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <TopProductCompare
              module={"video"}
              Title={"Videos"}
              products={topPosts}
              social_platform="Linkedin"

              module_section="overall"
              hasExportDropdown={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topPostserror) {
    topPostsChart = (
      <Wrapper>
        <div className="section_card fullpage_section_card grid">
          <div className="grid col_span_4 section_card_header">

            <div className="section_info">
              <h2 className="section_card_title">Top 5 Videos</h2>
              <SectionDefinationsBadge
                title={"Top 5 Videos"}
                module="video"
                platform="linkedin"
                section="overall"
              /></div>

          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader chartType="cardChartType" error={topPostserror} />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (topPostsLoading) {
    topPostsChart = (
      <Wrapper>
        <div className="section_card fullpage_section_card grid">
          <div className="grid col_span_4 section_card_header">

            <div className="section_info">
              <h2 className="section_card_title">Top 5 Videos</h2>
              <SectionDefinationsBadge
                title={"Top 5 Videos"}
                module="video"
                platform="linkedin"
                section="overall"
              />
            </div>

          </div>
          <div className="grid col_span_4">
            <Loader loaderType="cardLoader" variantType="compare" />
          </div>
        </div>
      </Wrapper>
    );
  }

  var combinedLineChartCSV = function (data) {
    var temp =
      data === undefined || null
        ? []
        : Object.values(data).map((item) => {
          var date = new Date(item.label);

          return { ...item, label: date.toLocaleDateString("en-GB") };
        });

    return temp;
  };

  var allData = {
    ...topCardData,
    "VideoSummaryCard_Summary Cards": metricCards,
    "PieofPie_Video Length Analysis": videoLengthAnalysis,
    "Video Trend Analysis": dailyTrendLine,
    "Heatmap_Published Time Views Analysis": audienceActivityTrend,
    "VideoRateLinkedin_Video Rate Analysis": combinedLineChartCSV(videoRateAnalysis),
    "SocialReaction_Video Engagement": videoEngagement,
    "CommentSentiment_Comment Sentiment": commentSentiment,
    "Compare_Top Videos": topPosts,
  };
  useEffect(() => {
    setFullPageExport(allData);
  }, [
    exportSummary,
    metricCards,
    videoLengthAnalysis,
    dailyTrendLine,
    audienceActivityTrend,
    videoRateAnalysis,
    videoEngagement,
    commentSentiment,
    topPosts,
  ]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (videoLengthAnalysiserror !== null ? true : isVideoLengthAnalysisLoaded && (isVideoLengthAnalysisLoading === false)) &&
      (dailyTrendLineError !== null ? true : isDailyTrendLineLoaded && (isDailyTrendLineLoading === false)) &&
      (audienceActivityTrendError !== null ? true : isAudienceActivityTrendLoaded && (isAudienceActivityTrendLoading === false)) &&
      (videoRateAnalysisError !== null ? true : isVideoRateAnalysisLoaded && (isVideoRateAnalysisLoading === false)) &&
      (videoEngagementerror !== null ? true : isVideoEngagementLoaded && (isVideoEngagementLoading === false)) &&
      (commentSentimenterror !== null ? true : isCommentSentimentLoaded && (isCommentSentimentLoading === false)) &&
      (topPostserror !== null ? true : topPostsLoaded && (topPostsLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    videoLengthAnalysiserror, isVideoLengthAnalysisLoaded, isVideoLengthAnalysisLoading,
    dailyTrendLineError, isDailyTrendLineLoaded, isDailyTrendLineLoading,
    audienceActivityTrendError, isAudienceActivityTrendLoaded, isAudienceActivityTrendLoading,
    videoRateAnalysisError, isVideoRateAnalysisLoaded, isVideoRateAnalysisLoading,
    videoEngagementerror, isVideoEngagementLoaded, isVideoEngagementLoading,
    commentSentimenterror, isCommentSentimentLoaded, isCommentSentimentLoading,
    topPostserror, topPostsLoaded, topPostsLoading,
  ])


  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <div className="section_info">   <h2 className="section_card_title">Trending Hashtags
          </h2> <SectionDefinationsBadge
              title={"Trending Hashtags"}
              module="video"
              platform="linkedin"
              section="overall"
            />
          </div>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topTrendingHashtagsList}</div>
        </div>
      </div>
      <Wrapper>
        <div className="grid grid_margin_bottom">
          <div className="card_wrapper">{metricCardsSection}</div>
        </div>
      </Wrapper>

      <div
        id="video_bi"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">
              Video Length Analysis
              {selectedHashtag ? " : " + selectedHashtag : ""}
            </h2> <SectionDefinationsBadge
                title={"Video Length Analysis"}
                module="video"
                platform="linkedin"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"video_bi"}
                sectionName={"PieofPie_Video Length Analysis"}
                sectionData={videoLengthAnalysis}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper p_t_0 p_b_0">
              {videoLengthAnalysisChart}
            </div>
          </div>
        </div>
      </div>

      <div
        id="video_trend"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">
              Video Trend Analysis
              {selectedHashtag ? " : " + selectedHashtag : ""}
            </h2> <SectionDefinationsBadge
                title={"Video Trend Analysis"}
                module="video"
                platform="linkedin"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"video_trend"}
                sectionName={"Video Trend Analysis"}
                sectionData={dailyTrendLine}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {dailyTrendLineSection}
            </div>
          </div>
        </div>
      </div>

      <div
        id="audience_activity"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">
              Published Time Views Analysis
              {selectedHashtag ? " : " + selectedHashtag : ""}
            </h2> <SectionDefinationsBadge
                title={"Published Time Views Analysis"}
                module="video"
                platform="linkedin"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_activity"}
                sectionName={"Heatmap_Published Time Views Analysis"}
                sectionData={audienceActivityTrend}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {audienceActivityTrendSection}
            </div>
          </div>
        </div>
      </div>

      <div
        id="video_analysis"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">
              Video Rate Analysis {selectedHashtag ? " : " + selectedHashtag : ""}
            </h2> <SectionDefinationsBadge
                title={"Video Rate Analysis"}
                module="video"
                platform="linkedin"
                section="overall"
              />
            </div>
            <Modal
              open={isVideoRateOpen}
              setOpen={setIsVideoRateOpen}
              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={
                    "videoRateAnalysis_2" //chart2 id
                  }
                  sectionName={"VideoRateLinkedin_Video Rate Analysis"}
                  sectionData={combinedLineChartCSV(videoRateAnalysis)}
                />
              }
              parentId={"video_rate"} // div section id
              title={`Video Rate Analysis ${selectedHashtag ? ` : ${selectedHashtag}` : ''}`}
              chart={videoRateAnalysisSection2} //chart2 data
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && (
                <button
                  onClick={() => setIsVideoRateOpen(true)}
                  className="icon_btn btn-small-secondary expand_btn"
                >
                  <ExpandIcon />
                </button>
              )}

              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"video_analysis"}
                sectionName={"VideoRateLinkedin_Video Rate Analysis"}
                sectionData={combinedLineChartCSV(videoRateAnalysis)}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {videoRateAnalysisSection}
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card ">
        <div id="video_engagement" className="grid col_span_2 section_card ">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Video Engagement {selectedHashtag ? " : " + selectedHashtag : ""}
            </h2> <SectionDefinationsBadge
                title={"Video Engagement"}
                module="video"
                platform="linkedin"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"video_engagement"}
                sectionName={"SocialReaction_Video Engagement"}
                sectionData={videoEngagement}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="grid col_span_1 section_card_body">
              <div className="section_card_chart_wrapper grid col_span_2">
                {videoEngagementSection}
              </div>
              <div className="section_card_table_wrapper grid col_span_2">
                <div className="table_wrapper section_card_table_wrapper">
                  {videoEngagementTable}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="comment_sentiment" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Comment Sentiment {selectedHashtag ? " : " + selectedHashtag : ""}
            </h2><SectionDefinationsBadge
                title={"Comment Sentiment"}
                module="video"
                platform="linkedin"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"comment_sentiment"}
                sectionName={"CommentSentiment_Comment Sentiment"}
                sectionData={commentSentiment}
              />
            </div>
          </div>
          <div className="grid col_span_1 section_card_body">
            <div className="section_card_chart_wrapper grid col_span_2">
              {commentSentimentSection}
            </div>
            <div className="section_card_table_wrapper grid col_span_2">
              <div className="table_wrapper section_card_table_wrapper">
                {commentSentimentTable}
              </div>
            </div>
          </div>
        </div>
      </div>

      {topPostsChart}
    </Wrapper>
  );
}
