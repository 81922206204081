import { useEffect, useRef, useState } from "react";

import validator from "validator";

import IconEye from "../../assets/icons/EyeIcon";
import IconHideEye from "../../assets/icons/HideEyeIcon";
import Wrapper from "../helpers/Wrapper";
import {
  EmailCharLimit,
  FirstNameCharLimit,
  LastNameCharLimit,
  MiddleNameCharLimit,
  MobileNoCharLimit,
  PasswordCharLimit,
} from "../../constants/inputLimit";

import {
  InvalidEmailMsg,
  RequiredField,
  shortMobileNoMsg,
  OnlyCharRegex,
  MobileNoRegex,
  EmailRegex,
  InvlidPassMsg,
  InvalidInputMsg,
  InvalidNameMsg,
  InvalidMobileNoMsg,
  MaxLimitReachedInputMsg,
} from "../../constants/constants";

const RegistrationPersonalDetailsForm = ({
  formData,
  setFormData,
  formValidation,
  setFormValidation,
  formStepsData,
  setFormStepsData,
}) => {
  const firstNameInputRef = useRef();
  const middleNameInputRef = useRef();
  const lastNameInputRef = useRef();
  const mobileNoInputRef = useRef();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const [firstNameIsTouched, setFirstNameIsTouched] = useState(false);
  const [firstNameIsValid, setFirstNameIsValid] = useState(false);
  const [firstNameError, setFirstNameError] = useState();

  const [middleNameIsTouched, setMiddleNameIsTouched] = useState(false);
  const [middleNameIsValid, setMiddleNameIsValid] = useState(false);
  const [middleNameError, setMiddleNameError] = useState();

  const [lastNameIsTouched, setLastNameIsTouched] = useState(false);
  const [lastNameIsValid, setLastNameIsValid] = useState(false);
  const [lastNameError, setLastNameError] = useState();

  const [mobileNoIsTouched, setMobileNoIsTouched] = useState(false);
  const [mobileNoIsValid, setMobileNoIsValid] = useState(false);
  const [mobileNoError, setMobileNoError] = useState();

  const [emailIsTouched, setEmailIsTouched] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [emailError, setEmailError] = useState();

  const [passwordIsTouched, setPasswordIsTouched] = useState(false);
  const [passdwordIsValid, setPasswordIsValid] = useState(false);
  const [passwordError, setPasswordError] = useState();
  const [passwordType, setPasswordType] = useState("password");

  // FIRST NAME
  const firstNameChangeHandler = () => {
    setFirstNameIsTouched(true);
    if (
      firstNameInputRef.current.value === "" ||
      OnlyCharRegex.test(firstNameInputRef.current.value)
    ) {
      setFirstNameError();
      setFirstNameIsValid(true);
      setFormValidation({ ...formValidation, firstName: true });
      setFormData({ ...formData, firstName: firstNameInputRef.current.value });
      if (firstNameInputRef.current.value.length >= FirstNameCharLimit) {
        setFirstNameError(MaxLimitReachedInputMsg + FirstNameCharLimit);
        setFirstNameIsValid(false);
        setFormValidation({ ...formValidation, firstName: false });
      }
    } else {
      setFirstNameError(InvalidNameMsg);
      setFirstNameIsValid(false);
      setFormValidation({ ...formValidation, firstName: false });
    }
  };
  const validateFirstNameHandler = () => {
    if (formValidation.firstName === false) {
      setFirstNameIsTouched(true);
    } else {
      setFirstNameIsTouched(false);
      if (firstNameInputRef.current.value === "") {
        setFirstNameError(RequiredField);
        setFirstNameIsValid(false);
        setFirstNameIsTouched(true);
        setFormValidation({ ...formValidation, firstName: false });
      }
      if (firstNameInputRef.current.value.length === FirstNameCharLimit) {
        setFirstNameError();
        setFirstNameIsValid(false);
        setFirstNameIsTouched(true);
        setFormValidation({ ...formValidation, firstName: false });
      }
    }

    if (firstNameInputRef.current.value === "") {
      setFirstNameError(RequiredField);
      setFirstNameIsValid(false);
      setFormValidation({ ...formValidation, firstName: false });
    }

    if (firstNameInputRef.current.value.length === FirstNameCharLimit) {
      setFirstNameError();
      setFirstNameIsValid(true);
      setFormValidation({ ...formValidation, firstName: true });
    }
  };

  // MIDDLE NAME
  const middleNameChangeHandler = () => {
    setMiddleNameIsTouched(true);
    if (
      middleNameInputRef.current.value === "" ||
      OnlyCharRegex.test(middleNameInputRef.current.value)
    ) {
      setMiddleNameError();
      setMiddleNameIsValid(true);
      setFormData({
        ...formData,
        middleName: middleNameInputRef.current.value,
      });
      if (middleNameInputRef.current.value.length >= MiddleNameCharLimit) {
        setMiddleNameError(MaxLimitReachedInputMsg + MiddleNameCharLimit);
        setMiddleNameIsValid(false);
      }
    } else {
      setMiddleNameError(InvalidNameMsg);
      setMiddleNameIsValid(false);
    }
  };

  const validateMiddleNameHandler = () => {
    if (middleNameInputRef.current.value.length >= MiddleNameCharLimit) {
      setMiddleNameError();
      setMiddleNameIsTouched(true);
      setMiddleNameIsValid(true);
    }
  };

  // LAST NAME
  const lastNameChangeHandler = () => {
    setLastNameIsTouched(true);
    if (
      lastNameInputRef.current.value === "" ||
      OnlyCharRegex.test(lastNameInputRef.current.value)
    ) {
      setLastNameError();
      setLastNameIsValid(true);
      setFormValidation({ ...formValidation, lastName: true });
      setFormData({ ...formData, lastName: lastNameInputRef.current.value });
      if (lastNameInputRef.current.value.length >= LastNameCharLimit) {
        setLastNameError(MaxLimitReachedInputMsg + LastNameCharLimit);
        setLastNameIsValid(false);
      }
    } else {
      setLastNameError(InvalidNameMsg);
      setLastNameIsValid(false);
      setFormValidation({ ...formValidation, lastName: false });
    }
  };
  const validateLastNameHandler = () => {
    if (formValidation.lastName === false) {
      setLastNameIsTouched(true);
    } else {
      setLastNameIsTouched(false);
      if (lastNameInputRef.current.value === "") {
        setLastNameError(RequiredField);
        setLastNameIsValid(false);
        setLastNameIsTouched(true);
        setFormValidation({ ...formValidation, lastName: false });
      }
      if (lastNameInputRef.current.value.length === LastNameCharLimit) {
        setLastNameError();
        setLastNameIsValid(true);
        setFormValidation({ ...formValidation, lastName: true });
      }
    }

    if (lastNameInputRef.current.value === "") {
      setLastNameError(RequiredField);
      setLastNameIsValid(false);
      setFormValidation({ ...formValidation, lastName: false });
    }

    if (lastNameInputRef.current.value.length === LastNameCharLimit) {
      setLastNameError();
      setLastNameIsValid(true);
      setFormValidation({ ...formValidation, lastName: true });
    }
  };

  // MOBILE NUMBER
  const mobileNoChangeHandler = () => {
    setMobileNoIsTouched(true);
    if (
      mobileNoInputRef.current.value === "" ||
      MobileNoRegex.test(mobileNoInputRef.current.value)
    ) {
      setMobileNoError();
      setMobileNoIsValid(true);
      setFormValidation({ ...formValidation, mobileNo: true });
      setFormData({ ...formData, mobileNo: mobileNoInputRef.current.value });
      if (mobileNoInputRef.current.value.length < 10) {
        setMobileNoError(shortMobileNoMsg);
        setMobileNoIsValid(false);
        setFormValidation({ ...formValidation, mobileNo: false });
      }
    } else {
      setMobileNoError(InvalidMobileNoMsg);
      setMobileNoIsValid(false);
      setFormValidation({ ...formValidation, mobileNo: false });
    }
  };

  const validateMobileNoHandler = () => {
    if (mobileNoInputRef.current.value === false) {
      setMobileNoIsTouched(true);
    } else {
      setMobileNoIsTouched(false);
      if (mobileNoInputRef.current.value === "") {
        setMobileNoError(RequiredField);
        setMobileNoIsValid(false);
        setMobileNoIsTouched(true);
        setFormValidation({ ...formValidation, mobileNo: false });
      }
    }
    if (mobileNoInputRef.current.value === "") {
      setMobileNoError(RequiredField);
      setMobileNoIsValid(false);
      setFormValidation({ ...formValidation, mobileNo: false });
    }
  };

  // EMAIL ID
  const emailChangeHandler = () => {
    setEmailIsTouched(true);
    if (!EmailRegex.test(emailInputRef.current.value)) {
      setEmailError(InvalidEmailMsg);
      setEmailIsValid(false);
      setFormValidation({ ...formValidation, email: false });
      setFormData({ ...formData, email: emailInputRef.current.value });
    } else {
      setEmailError();
      setEmailIsValid(true);
      setFormValidation({ ...formValidation, email: true });
      setFormData({ ...formData, email: emailInputRef.current.value });
    }
  };
  const validateEmailHandler = () => {
    if (formValidation.email === false) {
      setEmailIsTouched(true);
    } else {
      setEmailIsTouched(false);
      if (emailInputRef.current.value === "") {
        setEmailError(RequiredField);
        setEmailIsValid(false);
        setEmailIsTouched(true);
        setFormValidation({ ...formValidation, email: false });
      }
    }
    if (emailInputRef.current.value === "") {
      setEmailError(RequiredField);
      setEmailIsValid(false);
      setFormValidation({ ...formValidation, email: false });
    }
  };

  // PASSWORD
  const passwordChangeHandler = () => {
    setPasswordIsTouched(true);
    if (
      !validator.isStrongPassword(passwordInputRef.current.value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setPasswordIsValid(false);
      setPasswordError(InvlidPassMsg);
      setFormValidation({ ...formValidation, password: false });
      setFormData({ ...formData, password: passwordInputRef.current.value });
    } else {
      setPasswordIsValid(true);
      setPasswordError();
      setFormValidation({ ...formValidation, password: true });
      setFormData({ ...formData, password: passwordInputRef.current.value });
    }
  };
  const validatePasswordHandler = () => {
    if (formValidation.password === true) {
      setPasswordIsTouched(false);
    } else {
      setPasswordIsTouched(true);
      if (formData.password === "") {
        setPasswordError(RequiredField);
        setPasswordIsValid(false);
        setPasswordIsTouched(true);
        setFormValidation({ ...formValidation, password: false });
      }
    }
    if (formData.password === "") {
      setPasswordError(RequiredField);
      setPasswordIsValid(false);
      setFormValidation({ ...formValidation, password: false });
    }
  };

  // to show and hide password
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const firstNameInputIsInvalid = !firstNameIsValid && firstNameIsTouched;
  const firstNameInputClasses = firstNameInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  const middleNameInputIsInvalid = !middleNameIsValid && middleNameIsTouched;
  const middleNameInputClasses = middleNameInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  const lastNameInputIsInvalid = !lastNameIsValid && lastNameIsTouched;
  const lastNameInputClasses = lastNameInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  const mobileNoInputIsInvalid = !mobileNoIsValid && mobileNoIsTouched;
  const mobileNoInputClasses = mobileNoInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  const emailInputIsInvalid = !emailIsValid && emailIsTouched;
  const emailInputClasses = emailInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  const passwordInputIsInvalid = !passdwordIsValid && passwordIsTouched;
  const passwordInputClasses = passwordInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  useEffect(() => {
    if (
      formValidation.firstName === true &&
      formValidation.lastName === true &&
      formValidation.mobileNo === true &&
      formValidation.email === true &&
      formValidation.password === true
    ) {
      setFormStepsData({ ...formStepsData, firstStepIsValid: true });
    } else {
      setFormStepsData({ ...formStepsData, firstStepIsValid: false });
    }
  }, [formValidation]);

  return (
    <Wrapper>
      <div className="form_group">
        <input
          className={firstNameInputClasses}
          type="text"
          name="firstName"
          id="firstName"
          autoComplete="off"
          maxLength={FirstNameCharLimit}
          value={formData.firstName}
          placeholder="*Please Enter Your First Name"
          onChange={firstNameChangeHandler}
          onBlur={validateFirstNameHandler}
          ref={firstNameInputRef}
          required
        />
        <div className="error_section">
          {firstNameInputIsInvalid && (
            <h6 className="error_text">{firstNameError}</h6>
          )}
        </div>
      </div>
      <div className="form_group">
        <input
          className={middleNameInputClasses}
          type="text"
          name="middleName"
          id="middleName"
          autoComplete="off"
          value={formData.middleName}
          maxLength={MiddleNameCharLimit}
          placeholder="Please Enter Your Middle Name"
          onChange={middleNameChangeHandler}
          onBlur={validateMiddleNameHandler}
          ref={middleNameInputRef}
        />
        <div className="error_section">
          {middleNameInputIsInvalid && (
            <h6 className="error_text">{middleNameError}</h6>
          )}
        </div>
      </div>
      <div className="form_group">
        <input
          className={lastNameInputClasses}
          type="text"
          name="lastName"
          id="lastName"
          autoComplete="off"
          maxLength={LastNameCharLimit}
          placeholder="*Please Enter Your Last Name"
          value={formData.lastName}
          onChange={lastNameChangeHandler}
          onBlur={validateLastNameHandler}
          ref={lastNameInputRef}
          required
        />
        <div className="error_section">
          {lastNameInputIsInvalid && (
            <h6 className="error_text">{lastNameError}</h6>
          )}
        </div>
      </div>
      <div className="form_group">
        <input
          className={mobileNoInputClasses}
          type="text"
          name="mobileNo"
          id="mobileNo"
          autoComplete="off"
          maxLength={MobileNoCharLimit}
          placeholder="*Please Enter Your Mobile Number"
          value={formData.mobileNo}
          onChange={mobileNoChangeHandler}
          onBlur={validateMobileNoHandler}
          ref={mobileNoInputRef}
          required
        />
        <div className="error_section">
          {mobileNoInputIsInvalid && (
            <h6 className="error_text">{mobileNoError}</h6>
          )}
        </div>
      </div>
      <div className="form_group">
        <input
          className={emailInputClasses}
          type="email"
          name="email"
          id="email"
          autoComplete="off"
          maxLength={EmailCharLimit}
          placeholder="*Please Enter Your Email"
          value={formData.email}
          onChange={emailChangeHandler}
          onBlur={validateEmailHandler}
          ref={emailInputRef}
          required
        />
        <div className="error_section">
          {emailInputIsInvalid && <h6 className="error_text">{emailError}</h6>}
        </div>
      </div>
      <div className="form_group">
        <input
          className={passwordInputClasses}
          type={passwordType}
          name="password"
          id="password"
          autoComplete="off"
          maxLength={PasswordCharLimit}
          placeholder="*Please Enter Your Password"
          value={formData.password}
          onChange={passwordChangeHandler}
          onBlur={validatePasswordHandler}
          ref={passwordInputRef}
          required
        />
        <div className="form_group_btn_wrapper">
          <button
            className="show_password_btn"
            type="button"
            onClick={() => togglePassword()}
          >
            {passwordType === "password" ? (
              <IconHideEye className={"icon"} />
            ) : (
              <IconEye className={"icon"} />
            )}
          </button>
        </div>
        <div className="error_section">
          {passwordInputIsInvalid && (
            <h6 className="error_text">{passwordError}</h6>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default RegistrationPersonalDetailsForm;
