const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  selectedBrands: [],
  selectedCampaigns: [],
  selectedAds: []
};

const tvBrandsCampaignsSlice = createSlice({
  name: "tvBrandsCamapaigns",
  initialState,
  reducers: {
    setSelectedBrandsFromDropdown(state, action) {
      state.selectedBrands = action.payload;
    },
    setSelectedCampaignsFromDropdown(state, action) {
      state.selectedCampaigns = action.payload;
    },
    setSelectedAdsFromDropdown(state, action) {
      state.selectedAds = action.payload;
    },
    resetTvBrandsCamapaigns: () => initialState
  },
});

export const { setSelectedBrandsFromDropdown, setSelectedCampaignsFromDropdown, setSelectedAdsFromDropdown, resetTvBrandsCamapaigns } = tvBrandsCampaignsSlice.actions;

export default tvBrandsCampaignsSlice.reducer;
