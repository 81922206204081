import { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../store/authContext";
import FullPageExportContext from "../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../components/helpers/axios";
import Wrapper from "../../components/helpers/Wrapper";
import PageHeader from "../../components/layout/pageHeader";
import Card from "../../components/ui/Card";
import ExportDropdown from "../../components/ui/dropdown/ExportDropdown";
import Slider from "../../components/ui/Slider";

// LOADERS
import Loader from "../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../components/ui/loaders/ServerErrosLoader";

// CHARTS
import DonutPieChart from "../../components/ui/charts/DonutPieChart";
import BarChart from "../../components/ui/charts/BarChart";
import Datatable from "../../components/ui/tables/Datatable";

// UTILS
import ErrorBoundary from "../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../utils/NoDataAvailableChecker";
import {
  IAEvent_Platform_Visit,
  IAEvent_Hashtag_Visit,
} from "../../utils/IAEvents";
import NumberFormatter from "../../utils/NumberFormatter";
import { useParams, useSearchParams } from "react-router-dom";
import SectionDefinationsBadge from "../../components/ui/SectionDefinationsBadge";
import EventTracker from "../../components/eventsTracking/EventTracker";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Hashtag = () => {

  const authCtx = useContext(AuthContext);
  const {
    setFullPageExport,
    setExportPageName,
    exportSummary,
    setexportSummary,
    setIsExportDisabled
  } = useContext(FullPageExportContext);

  const params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const [selectedPlatform, setSelectedPlatform] = useState(null);

  const [searchTerm, setSeachTerm] = useState("");

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const selectedBrands = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrands
  );

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Hashtag"
  let pagePlatform = selectedPlatform === null ? "overall" : selectedPlatform;
  let pagePrimaryTab = "overall"

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // SELECTED HASHTAG ON CLICK
  const [isSelectedHashtag, setisSelectedHashtag] = useState(null);

  // HASHTAG LIST TABLE
  const [hashtagTableData, setHashtagTableData] = useState([]);
  const [isHashtagTableDataLoading, setHashtagTableDataIsLoading] =
    useState(false);
  const [isHashtagTableDataLoaded, setHashtagTableDataIsLoaded] =
    useState(false);
  const [hashtagTableDataerror, setHashtagTableDataError] = useState(null);

  // HASHTAG ENGAGEMENTS CHART
  const [hashtagEngagementsData, setHashtagEngagementsData] = useState([]);
  const [hashtagTotalEngagements, setHashtagTotalEngagements] = useState(0);
  const [isHashtagEngagementsDataLoading, setHashtagEngagementsDataIsLoading] = useState(false);
  const [isHashtagEngagementsDataLoaded, setHashtagEngagementsDataIsLoaded] = useState(false);
  const [hashtagEngagementsDataerror, setHashtagEngagementsDataError] = useState(null);

  // HASHTAG REACTIONS CHART
  const [hashtagReactionsData, setHashtagReactionsData] = useState([]);
  const [hashtagTotalReactions, setHashtagTotalReactions] = useState(0);
  const [isHashtagReactionsDataLoading, setHashtagReactionsDataIsLoading] = useState(false);
  const [isHashtagReactionsDataLoaded, setHashtagReactionsDataIsLoaded] = useState(false);
  const [hashtagReactionsDataerror, setHashtagReactionsDataError] = useState(null);

  // TOP HASHTAG BAR CHART DATA
  // const [currentSelectedChartPlatform, setCurrentSelectedChartPlatform] =
  //   useState("");
  const [topHashtagChartData, setTopHashtagChartData] = useState([]);
  const [topHashtagChartDataLabels, setTopHashtagChartDataLabels] = useState([]);
  const [isHashtagChartDataLoading, setHashtagChartDataIsLoading] = useState(false);
  const [isHashtagChartDataLoaded, setHashtagChartDataIsLoaded] = useState(false);
  const [hashtagCharterror, setHashtagChartError] = useState(null);
  const [noOfBarsToShow, setNoOfBarsToShow] = useState(0);

  const tableHeaders = [
    { name: "Hashtag", field: "hashtag" },
    { name: "Impressions", field: "impression_count" },
    { name: "Engagements", field: "engangment_count" },
    // { name: "Engagement Rate", field: "engangment_rate" },
  ];

  // ON FIRST PAGE LOAD OR DATE CHANGE
  useEffect(() => {
    fetchMetricCardsHandler();
  }, [BRAND_ID, selectedDates]);

  // LOAD PAGE WHEN PLATFORM IS SELECTED
  useEffect(() => {
    fetchHashtagListHandler();
    fetchHashtagChartDataHandler();
  }, [BRAND_ID, selectedDates, selectedPlatform]);

  // WHEN HASHTAG IS SELELCTED
  useEffect(() => {
    fetchHashtagTableDataHandler();
    fetchHashtagEngagementsHandler();
    fetchHashtagReactionsHandler();
  }, [BRAND_ID, selectedDates, selectedPlatform, isSelectedHashtag]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/hashtag/", config);
      setMetricCards(res?.data ?? []);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <Loader loaderType="hashtagLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = <Wrapper><NoDataAvailableLoader chartType="cardChartType" /></Wrapper>
    } else {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_margin_bottom">
            <div className="card_wrapper">
              <ErrorBoundary chartType="cardChartType">
                <Slider
                  sliderWrapperClass={"tab_card_slider"}
                  showSliderHeader={false}
                  showNavigation={false}
                  sliderTitle={"Summary"}
                >
                  {metricCards.map((data, index) => (
                    <SwiperSlide
                      key={index}
                      onClick={() => activePlatform(data.cardTitle)}
                    >
                      <Card
                        cardClass={
                          selectedPlatform === data.cardTitle
                            ? "tabCard active"
                            : "tabCard "
                        }
                        variant={"compare"}
                        cardTitle={data.cardTitle}
                        currentValue={data.currentValue}
                        previousValue={data.previousValue}
                        rowOneTitle={data.rowOneTitle}
                        second_currentValue={data.second_currentValue}
                        second_previousValue={data.second_previousValue}
                        rowTwoTitle={data.rowTwoTitle}
                      />
                    </SwiperSlide>
                  ))}
                </Slider>
              </ErrorBoundary>
            </div>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <ServerErrorsLoader chartType="cardChartType" error={metricCardserror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <Loader loaderType="hashtagLoader" />
      </Wrapper>
    );
  }

  // TO OPEN OR CLOSE THE SIZEBAR AS PER SCREEN SIZE
  useEffect(() => {
    if (window.innerWidth < 400) {
      setNoOfBarsToShow(5);
    } else if (window.innerWidth < 768) {
      setNoOfBarsToShow(10);
    } else if (window.innerWidth < 992) {
      setNoOfBarsToShow(15);
    } else if (window.innerWidth < 1599) {
      setNoOfBarsToShow(20);
    } else {
      setNoOfBarsToShow(20);
    }

    const windowResize = () => {
      if (window.innerWidth < 400) {
        setNoOfBarsToShow(5);
      } else if (window.innerWidth < 768) {
        setNoOfBarsToShow(10);
      } else if (window.innerWidth < 992) {
        setNoOfBarsToShow(15);
      } else if (window.innerWidth < 1599) {
        setNoOfBarsToShow(20);
      } else {
        setNoOfBarsToShow(20);
      }
    };

    window.addEventListener("resize", windowResize);
    return () => {
      window.removeEventListener("resize", windowResize);
    };
  }, [window.innerWidth]);

  // TO FETCH TRENDING HASHTAG LIST FROM API
  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        platform: selectedPlatform,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("hashtag/trending-list/", config);

      setHashtagList(res?.data?.hashtag_trend_data ?? []);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO FETCH HASHTAG ENGAGEMEBT DATA FROM API WHEN HASHTAG IS SELECTED
  const fetchHashtagEngagementsHandler = async () => {
    setHashtagEngagementsDataIsLoading(true);
    setHashtagEngagementsDataIsLoaded(false);
    setHashtagEngagementsDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: isSelectedHashtag === "" ? null : isSelectedHashtag,
        platform: selectedPlatform,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("hashtag/engagement-chart/", config);

      let resEngagement = res?.data?.engagement ?? []

      let data_list = [];
      resEngagement.map((hashtag) => {
        try {
          let a = {
            label: hashtag.label,
            value: hashtag.value,
          };
          data_list.push(a);
        } catch (e) { }
      });

      data_list.sort((a, b) => {
        return b.value - a.value;
      });

      let total = 0;
      for (let index = 0; index < data_list.length; index++) {
        total = total + data_list[index].value;
      }

      setHashtagEngagementsData(data_list ?? []);
      setHashtagTotalEngagements(total);
      setHashtagEngagementsDataIsLoading(false);
      setHashtagEngagementsDataIsLoaded(true);
      setHashtagEngagementsDataError(null);
    } catch (error) {
      setHashtagEngagementsData([]);
      setHashtagTotalEngagements([]);
      setHashtagEngagementsDataIsLoading(false);
      setHashtagEngagementsDataIsLoaded(false);
      setHashtagEngagementsDataError(error.code);
    }
  };

  // TO FETCH HASHTAG ENGAGEMEBT DATA FROM API WHEN HASHTAG IS SELECTED
  const fetchHashtagReactionsHandler = async () => {
    setHashtagReactionsDataIsLoading(true);
    setHashtagReactionsDataIsLoaded(false);
    setHashtagReactionsDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: isSelectedHashtag === "" ? null : isSelectedHashtag,
        platform: selectedPlatform,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("hashtag/reaction-sentiment/", config);

      let resReactionSentiment = res?.data?.reaction_sentiment_data ?? []
      let data_list = [];
      resReactionSentiment.map((hashtag) => {
        try {
          let a = {
            label: hashtag.label,
            value: hashtag.value,
          };
          data_list.push(a);
        } catch (e) { }
      });

      data_list.sort((a, b) => {
        return b.value - a.value;
      });

      let total = 0;
      for (let index = 0; index < data_list.length; index++) {
        total = total + data_list[index].value;
      }

      setHashtagReactionsData(data_list ?? []);
      setHashtagTotalReactions(total);
      setHashtagReactionsDataIsLoading(false);
      setHashtagReactionsDataIsLoaded(true);
      setHashtagReactionsDataError(null);
    } catch (error) {
      setHashtagReactionsDataIsLoading(false);
      setHashtagReactionsDataIsLoaded(false);
      setHashtagReactionsDataError(error.code);
    }
  };

  // TO FETCH HASHTAG TABLE DATA LIST FROM API
  const fetchHashtagTableDataHandler = async () => {
    setHashtagTableDataIsLoading(true);
    setHashtagTableDataIsLoaded(false);
    setHashtagTableDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: isSelectedHashtag === "" ? null : isSelectedHashtag,
        platform: selectedPlatform,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("hashtag/table-list/", config);
      setHashtagTableData(res?.data?.hashtag_table_data ?? []);
      setHashtagTableDataIsLoading(false);
      setHashtagTableDataIsLoaded(true);
      setHashtagTableDataError(null);
    } catch (error) {
      setHashtagTableData([]);
      setHashtagTableDataIsLoading(false);
      setHashtagTableDataIsLoaded(false);
      setHashtagTableDataError(error.code);
    }
  };

  // TO FETCH HASHTAG CHART DATA FROM API
  const fetchHashtagChartDataHandler = async () => {
    setHashtagChartDataIsLoading(true);
    setHashtagChartDataIsLoaded(false);
    setHashtagChartError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        platform: selectedPlatform,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("hashtag/chart-list/", config);

      let resUnsortedData = res?.data?.data ?? []

      resUnsortedData.sort((a, b) => {
        return b.value - a.value;
      });

      setTopHashtagChartData(res?.data?.data ?? []);
      setTopHashtagChartDataLabels(res?.data?.label ?? []);
      setHashtagChartDataIsLoaded(true);
      setHashtagChartDataIsLoading(false);
      setHashtagChartError(null);
    } catch (error) {
      setHashtagChartDataIsLoading(false);
      setHashtagChartDataIsLoaded(false);
      setHashtagChartError(error.code);
    }
  };

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (isSelectedHashtag === "") {
      setisSelectedHashtag(badge.hashtag);
    } else if (isSelectedHashtag === badge.hashtag) {
      setisSelectedHashtag(null);
    } else {
      setisSelectedHashtag(badge.hashtag);
    }
    IAEvent_Hashtag_Visit(
      "Marketing Intelligence",
      "Hashtag",
      selectedPlatform,
      null,
      null,
      badge.hashtag
    );
  };

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS
  let topTrendingHashtagsList = (
    <Wrapper>
      <Loader loaderType="hashLoader" />
    </Wrapper>
  );

  if (isHashtagListLoaded && !isHashtagListLoading) {
    if (NoDataAvailableChecker(filteredHashtagList)) {
      topTrendingHashtagsList = (<Wrapper><NoDataAvailableLoader chartType="hashChartType" /></Wrapper>);
    } else {
      topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
        <Wrapper key={index}>
          <ErrorBoundary chartType="hashChartType">
            <div className="badge_wrapper">
              <button
                type="button"
                className={
                  isSelectedHashtag === badge.hashtag
                    ? "button_badge active"
                    : "button_badge"
                }
                onClick={() => handleEdit(badge)}
              >
                {badge.hashtag}
              </button>
            </div>
          </ErrorBoundary>
        </Wrapper>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <Wrapper>
        <ServerErrorsLoader chartType="hashChartType" error={hashtagListerror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = (
      <Wrapper>
        <Loader loaderType="hashLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // HASHTAGS ENGAGEMENTS
  let hashtagEnagegmentsDonutPieChart = (
    <Wrapper>
      <Loader loaderType="donutChartLoader" />
    </Wrapper>
  );

  let hashtagEngageMentDataTable = (
    <Wrapper><Loader loaderType="smallTableLoader" /> </Wrapper>
  )

  if (isHashtagEngagementsDataLoaded && !isHashtagEngagementsDataLoading) {
    if (NoDataAvailableChecker(hashtagEngagementsData)) {
      hashtagEnagegmentsDonutPieChart =
        <Wrapper>
          <NoDataAvailableLoader chartType="donutChartType" />
        </Wrapper>

      hashtagEngageMentDataTable = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      )

    } else {
      hashtagEnagegmentsDonutPieChart = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartId={"hashtagEngagementDonutPieChart"}
              chartClass={"section_card_chart"}
              chartData={hashtagEngagementsData}
              showLegend={true}
              showLabels={true}
              showVerticalLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );

      hashtagEngageMentDataTable = (
        <div className="chart_details_wrapper">
          <div className="main_wrapper">
            <div className="body">
              {hashtagEngagementsData && hashtagEngagementsData.map(
                (details, postImpressionIndex) => (
                  <Wrapper key={postImpressionIndex}>
                    <div className="row">
                      <div className="title_wrapper">
                        {details.label}
                      </div>
                      <div className="value_wrapper">
                        <ErrorBoundary>
                          <NumberFormatter number={details.value} />
                        </ErrorBoundary>
                        {details.metric &&
                          <div className="metrics_wrapper">
                            <div className="title_wrapper">
                              {details.metric[0].label + " : "}
                            </div>
                            <div className="value_wrapper">
                              <ErrorBoundary>
                                <NumberFormatter
                                  number={details.metric[0].value}
                                />
                              </ErrorBoundary>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </Wrapper>
                ))}
            </div>
            {/* <div className="footer">
              <div className="title_wrapper">
                Total Engagements
              </div>
              <div className="value_wrapper">
                <NumberFormatter number={hashtagTotalEngagements}
                />
              </div>
            </div> */}
          </div>
        </div>
      )

    }

  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagEngagementsDataerror) {
    hashtagEnagegmentsDonutPieChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={hashtagEngagementsDataerror}
        />
      </Wrapper>
    );

    hashtagEngageMentDataTable = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={hashtagEngagementsDataerror}
        />
      </Wrapper>
    )

  }

  // IF DATA LOADING
  if (isHashtagEngagementsDataLoading) {
    hashtagEnagegmentsDonutPieChart = (
      <Wrapper>
        <Loader loaderType="donutChartLoader" />
      </Wrapper>
    );

    hashtagEngageMentDataTable = (
      <Wrapper><Loader loaderType="smallTableLoader" /> </Wrapper>
    )
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // HASHTAGS REACTIONS
  let hashtagReactionSentimentDonutPieChart = (
    <Wrapper>
      <Loader loaderType="donutChartLoader" />
    </Wrapper>
  );

  let hashtagReactionSentimentDataTable = (
    <Wrapper><Loader loaderType="smallTableLoader" /> </Wrapper>

  )

  if (isHashtagReactionsDataLoaded && !isHashtagReactionsDataLoading) {
    if (NoDataAvailableChecker(hashtagReactionsData)) {
      hashtagReactionSentimentDonutPieChart = <Wrapper><NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
      hashtagReactionSentimentDataTable = <Wrapper><NoDataAvailableLoader chartType="tableChartType" /></Wrapper>
    } else {
      hashtagReactionSentimentDonutPieChart = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartId={"hashtagSentimentDonutPieChart"}
              chartClass={"section_card_chart"}
              chartData={hashtagReactionsData}
              showLegend={true}
              showLabels={true}
              showVerticalLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );

      hashtagReactionSentimentDataTable = (
        <div className="chart_details_wrapper">
          <div className="main_wrapper">
            <div className="body">
              {hashtagReactionsData && hashtagReactionsData.map(
                (details, postImpressionIndex) => (
                  <Wrapper key={postImpressionIndex}>
                    <div className="row">
                      <div className="title_wrapper">
                        {details.label}
                      </div>
                      <div className="value_wrapper">
                        <ErrorBoundary>
                          <NumberFormatter number={details.value} />
                        </ErrorBoundary>
                        {details.metric &&
                          <div className="metrics_wrapper">
                            <div className="title_wrapper">
                              {details.metric[0].label + " : "}
                            </div>
                            <div className="value_wrapper">
                              <ErrorBoundary>
                                <NumberFormatter
                                  number={details.metric[0].value}
                                />
                              </ErrorBoundary>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </Wrapper>
                ))}
            </div>
            {/* <div className="footer">
              <div className="title_wrapper">
                Total Reactions
              </div>
              <div className="value_wrapper">
                <NumberFormatter number={hashtagTotalReactions}
                />
              </div>
            </div> */}
          </div>
        </div>
      )

    }

  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagReactionsDataerror) {
    hashtagReactionSentimentDonutPieChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={hashtagReactionsDataerror}
        />
      </Wrapper>
    );

    hashtagReactionSentimentDataTable = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={hashtagReactionsDataerror}
        />
      </Wrapper>
    )
  }

  // IF DATA LOADING
  if (isHashtagReactionsDataLoading) {
    hashtagReactionSentimentDonutPieChart = (
      <Wrapper>
        <Loader loaderType="donutChartLoader" />
      </Wrapper>
    );

    hashtagReactionSentimentDataTable = (
      <Wrapper><Loader loaderType="smallTableLoader" /> </Wrapper>

    )
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP HASHTAGS TABLE DATA
  let HashtagTableData = <Wrapper>
    <div className="grid grid_cols_4 fullpage_section_card section_card">
      <div className="grid col_span_4">
        <div className="section_card_header">
          <h2 className="section_card_title">{selectedPlatform ? selectedPlatform + " " : ''}Hashtag List</h2>
        </div>
      </div>
      <div className="grid col_span_4">
        <div className="section_card_body">
          <Loader loaderType="smallTableLoader" />
        </div>
      </div>
    </div>
  </Wrapper>

  if (isHashtagTableDataLoaded && !isHashtagTableDataLoading) {
    if (
      NoDataAvailableChecker(hashtagTableData)
    ) {
      HashtagTableData =
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <div className="section_info">
                  <h2 className="section_card_title">{selectedPlatform && selectedPlatform + " "}Hashtag List</h2>
                  <SectionDefinationsBadge
                    title={`${selectedPlatform ? selectedPlatform + " " : ''}Hashtag List`}
                    module={pageSubModule}
                    platform={pagePlatform}
                    section={pagePrimaryTab}
                  />
                </div>              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
    } else {
      HashtagTableData = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tablePagePlatform={pagePlatform}
              tablePageSubModule={pageSubModule}
              tablePagePrimaryTab={pagePrimaryTab}
              tableTitle={`${selectedPlatform ? selectedPlatform + " " : ''}Hashtag List`}
              tableHeader={tableHeaders}
              tableData={hashtagTableData}
              tableLength={10}
              isFooterShow={true}
              searchPlaceHolder={"Search Hashtags"}
              tableID={"hashtag_list_table"}

              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}

                  sectionName="Hashtag List"
                  sectionId={"hashtag_list_table"}
                  sectionData={hashtagTableData}
                />

              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagTableDataerror) {
    HashtagTableData = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info">
                <h2 className="section_card_title">{selectedPlatform && selectedPlatform + " "}Hashtag List</h2>
                <SectionDefinationsBadge
                  title={`${selectedPlatform ? selectedPlatform + " " : ''}Hashtag List`}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={hashtagTableDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isHashtagTableDataLoading) {
    HashtagTableData =
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info">
                <h2 className="section_card_title">{selectedPlatform && selectedPlatform + " "}Hashtag List</h2>
                <SectionDefinationsBadge
                  title={`${selectedPlatform ? selectedPlatform + " " : ''}Hashtag List`}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </div>            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP HASHTAGS CHART
  let topHashtagsBarChart = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>

  // IF DATA IS AVAILABLE
  if (isHashtagChartDataLoaded && !isHashtagChartDataLoading) {
    if (NoDataAvailableChecker(topHashtagChartData)) {
      topHashtagsBarChart = <Wrapper><NoDataAvailableLoader chartType="barChartType" /></Wrapper>
    } else {
      topHashtagsBarChart = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="hashtagTopHashtagsBarChart"
              chartClass="section_card_chart"
              chartData={topHashtagChartData.slice(0, noOfBarsToShow)}
              chartLabels={topHashtagChartDataLabels}
              showLabels={true}
              showShortVerticalLabels={true}
              minimumGridDistance={20}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagCharterror) {
    topHashtagsBarChart = (
      <Wrapper>
        <ServerErrorsLoader chartType="barChartType" error={hashtagCharterror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isHashtagChartDataLoading) {
    topHashtagsBarChart = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
  }

  const activePlatform = (platform) => {
    setisSelectedHashtag(null);
    if (selectedPlatform === "") {
      setSelectedPlatform(platform);
    } else if (selectedPlatform === platform) {
      setSelectedPlatform(null);
    } else {
      setSelectedPlatform(platform);
    }
    IAEvent_Platform_Visit("Marketing Intelligence", "Hashtag", platform);

    EventTracker({
      eventType: "platform_selected",
      module: "Marketing Intelligence",
      subModule: "Hashtag",
      platform: platform === null ? "Overall" : platform,
      ...(BRAND_ID && { brands: selectedBrands }),
      date: {
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate,
        previousStartDate: selectedDates.prevStartDate,
        previousEndDate: selectedDates.prevEndDate,
      }
    })

  };

  // TO ADD PLATFORMS SEARCH QUERRY IN URL
  useEffect(() => {
    setSearchParams({
      platform: selectedPlatform === null ? "overall" : selectedPlatform.toLocaleLowerCase(),
      brands:
        selectedBrands.length === 0
          ? "overall"
          : [...new Set(selectedBrands.map((item) => item.value))].join(","),
    });
  }, [selectedPlatform]);

  var allData = {
    Summary: exportSummary,
    PlatformCards_Platforms: metricCards,
    // [(selectedPlatform ===undefined ? "" : selectedPlatform) +
    // " Trending Hashtags"]: hashtagList,
    [(selectedPlatform === undefined ? "" : selectedPlatform) +
      " Hashtag Table"]: hashtagTableData,
    [(selectedPlatform === undefined ? "" : selectedPlatform) + " Top Hashtags"]:
      topHashtagChartData,
  };

  useEffect(() => {
    setExportPageName(
      `Hashtag ${selectedPlatform === null ? "Overall" : selectedPlatform
      }`
    );
    setFullPageExport(allData);
  }, [metricCards, hashtagList, hashtagTableData, topHashtagChartData]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (hashtagListerror !== null ? true : isHashtagListLoaded && (isHashtagListLoading === false)) &&
      (hashtagTableDataerror !== null ? true : isHashtagTableDataLoaded && (isHashtagTableDataLoading === false)) &&
      (hashtagCharterror !== null ? true : isHashtagChartDataLoaded && (isHashtagChartDataLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    hashtagListerror, isHashtagListLoaded, isHashtagListLoading,
    hashtagTableDataerror, isHashtagTableDataLoaded, isHashtagTableDataLoading,
    hashtagCharterror, isHashtagChartDataLoaded, isHashtagChartDataLoading,
  ])


  useEffect(() => {
    setexportSummary([selectedDates, ...selectedBrands]);
  }, [selectedDates, selectedBrands]);

  return (
    <Wrapper>
      <div className="content">
        <div className="content_section">
          <PageHeader pageTitle={"Hashtag"} />
          <div className="page_content">
            <div className="dashboard_home">
              {metricCardsSection}
              {isMetricCardsLoaded && (
                <Wrapper>
                  <div className="section_card fullpage_section_card grid grid_margin_bottom">
                    <div className="section_card_header">
                      <div className="section_info">

                        <h2 className="section_card_title">
                          {selectedPlatform ? selectedPlatform + " " : ''}Trending Hashtags
                        </h2>
                        <SectionDefinationsBadge
                          title={`${selectedPlatform ? selectedPlatform + " " : ''}Trending Hashtags`}
                          module={pageSubModule}
                          platform={pagePlatform}
                          section={pagePrimaryTab}
                        />
                      </div>
                      <div className="filters_wrapper">
                        <div className="filter form_group flex justify_end">
                          <input
                            className="form_control input"
                            type="text"
                            autoComplete="off"
                            placeholder="Search hashtag"
                            onChange={(event) => {
                              setSeachTerm(event.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="section_card_body badges_list_wrapper_body">
                      <div className="badges_list_wrapper">
                        {topTrendingHashtagsList}
                      </div>
                    </div>
                  </div>
                  {isSelectedHashtag && (
                    <div className="grid grid_cols_4 grid_margin_bottom">
                      <div id="total_engagement" className="grid col_span_2 section_card">
                        <div className="section_card_header">
                          <h2 className="section_card_title">
                            {isSelectedHashtag + " "}: {selectedPlatform === null && "Overall"} Hashtag Engagement
                          </h2>
                          <div data-html2canvas-ignore={true}>
                            <ExportDropdown
                              moduleName={pageModule}
                              subModuleName={pageSubModule}
                              platform={pagePlatform}
                              sectionId={"total_engagement"}
                              sectionName={`DonutWithNonActiveDropdown_Total Engagement_${" : " + isSelectedHashtag}`}
                              sectionData={hashtagEngagementsData}
                            />
                          </div>
                        </div>
                        <div className="grid grid_cols_4 section_card_body chart_table_card">
                          <div className="section_card_chart_wrapper grid col_span_2">
                            {hashtagEnagegmentsDonutPieChart}
                          </div>
                          <div className="section_card_table_wrapper grid col_span_2">
                            {hashtagEngageMentDataTable}
                          </div>
                        </div>
                      </div>
                      <div id="reaction_sentiment" className="grid col_span_2 section_card">
                        <div className="section_card_header">
                          <h2 className="section_card_title">
                            {isSelectedHashtag + " "}: Reaction Bifurcation
                          </h2>
                          <div data-html2canvas-ignore={true}>
                            <ExportDropdown
                              moduleName={pageModule}
                              subModuleName={pageSubModule}
                              platform={pagePlatform}
                              sectionId={"reaction_sentiment"}
                              sectionName={`DonutWithNonActiveDropdown_Reaction Sentiment_${" : " + isSelectedHashtag}`}
                              sectionData={hashtagReactionsData}
                            />
                          </div>
                        </div>
                        <div className="grid grid_cols_4 section_card_body chart_table_card">
                          <div className="section_card_chart_wrapper grid col_span_2">
                            {hashtagReactionSentimentDonutPieChart}
                          </div>
                          <div className="section_card_table_wrapper grid col_span_2">

                            {hashtagReactionSentimentDataTable}

                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="grid grid_margin_bottom">
                    {/* <div className="section_card_header">
                      <h2 className="section_card_title">
                        {selectedPlatform && selectedPlatform + " "}Hashtag List
                      </h2>
                    </div>
                    <div className="grid col_span_4">
                      <div className="section_card_body"> */}
                    {HashtagTableData}
                    {/* </div>
                    </div>*/}
                  </div>
                  <div
                    id="top_hash"
                    className="section_card fullpage_section_card grid"
                  >
                    <div className="section_card_header">
                      <div className="section_info">
                        <h2 className="section_card_title">
                          {selectedPlatform && selectedPlatform + " "}Top Hashtags

                        </h2>
                        <SectionDefinationsBadge
                          title={`${selectedPlatform ? selectedPlatform + " " : ''}Top Hashtags`}
                          module={pageSubModule}
                          platform={pagePlatform}
                          section={pagePrimaryTab}
                        />
                      </div>

                      <div data-html2canvas-ignore={true}>
                        <ExportDropdown
                          moduleName={pageModule}
                          subModuleName={pageSubModule}
                          platform={pagePlatform}

                          sectionId={"top_hash"}
                          sectionName={"TopHashtags_Top Hashtags"}
                          sectionData={topHashtagChartData}
                        />
                      </div>
                    </div>
                    <div className="section_card_body">
                      <div className="section_card_chart_wrapper">
                        {topHashtagsBarChart}
                      </div>
                    </div>
                  </div>
                </Wrapper>
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default Hashtag;
