const HashtagIcon = (props) => {
  return (
    <svg
      className={props.className}
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      stroke="#486F88"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M24.25 15L16.25 40" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M37.25 15L29.25 40" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M39.711 22.887H14" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M39.711 32.416H14" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export default HashtagIcon;
