import { useState } from "react";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";

// PAGES
import WebsiteTechnologyDeviceUsed from "./DeviceUsed";
import WebsiteTechnologyDeviceTrafficType from "./DeviceTrafficType";
import WebsiteTechnologyBrowserUsed from "./BrowserUsed";

// UTILS
import { IAEvent_Secondary_Tab_Visit } from "../../../../utils/IAEvents";

const WebsiteTechnology = ({ viewId }) => {

    const tabData = [
        "Browser Used",
        "Device Used",
        "Device Traffic Type"
    ];

    const [selectedTab, setSelectedTab] = useState(tabData[0]);

    // TO SET THE ACTIVE TAB ONCLICK
    const handleCallback = (event) => {
        if (selectedTab !== event) {
            setSelectedTab(event);
        }
        IAEvent_Secondary_Tab_Visit("Marketing Intelligence", "Website", null, "Technology", event)
    }

    return (
        <Wrapper>
            <div className="grid grid_margin_bottom">
                <Tab
                    varient={"secondary"}
                    tabData={tabData}
                    activeTab={selectedTab}
                    handleCallback={(event) => handleCallback(event)} />
            </div>
            {selectedTab.toLowerCase() === tabData[0].toLowerCase() && <WebsiteTechnologyBrowserUsed viewId={viewId} />}
            {selectedTab.toLowerCase() === tabData[1].toLowerCase() && <WebsiteTechnologyDeviceUsed viewId={viewId} />}
            {selectedTab.toLowerCase() === tabData[2].toLowerCase() && <WebsiteTechnologyDeviceTrafficType viewId={viewId} />}
        </Wrapper>
    );
};

export default WebsiteTechnology;
