const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    module: [],
};

const moduleSlice = createSlice({
    name: "module",
    initialState,
    reducers: {
        setModuleData(state, action) {
            state.module = action.payload;
        },
        resetModuleData: () => initialState,
    },
});

export const { setModuleData, resetModuleData } = moduleSlice.actions;

export default moduleSlice.reducer;
