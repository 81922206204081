import {
  LineChartDataLabelsSample,
  LineChartDataSample,
} from "./chartsDataSamples";
import {
  DefaultPostImage,
  DefaultTwitterHeaderImg,
  DefaultVideoThumbnailImage,
} from "../constants/constants";

export const PrimaryCardData = [
  {
    cardTitle: "Like",
    currentValue: 18,
    previousValue: 10,
    rowOneTitle: "Previous",
    numberVariant: "money",
    forCountry: "India",
  },
  {
    cardTitle: "Views",
    currentValue: 17,
    previousValue: 11,
    rowOneTitle: "Previous",
    numberVariant: "number",
    forCountry: "India",
  },
  {
    cardTitle: "Reach",
    currentValue: 10,
    previousValue: 18,
    rowOneTitle: "Previous",
    numberVariant: "number",
    forCountry: "India",
  },
  {
    cardTitle: "Clicks",
    currentValue: 10,
    previousValue: 10,
    rowOneTitle: "Previous",
    numberVariant: "number",
    forCountry: "India",
  },
];

export const TabCardDataSample = [
  {
    cardTitle: "Footfall",
    showInfo: true,
    hoverDetails:
      "NSV i the Net Sales Value and is equal to the GSV less the Disc. you provide to secure the sale.",
    variant: "target",
    targetedValue: 9878589,
    achievedValue: 78958554,
    currentValue: 78958554,
    previousValue: 9878589,
    rowOneTitle: "Paid",
    second_currentValue: 785,
    second_previousValue: 98,
    rowTwoTitle: "Organic",
    numberVariant: "number",
    forCountry: "United Kingdom",
    chartData: LineChartDataSample,
    chartDataLabels: LineChartDataLabelsSample,
  },
  {
    cardTitle: "Conversion Rate",
    showInfo: true,
    hoverDetails:
      "NSV i the Net Sales Value and is equal to the GSV less the Disc. you provide to secure the sale.",
    variant: "target",
    targetedValue: 9878589,
    achievedValue: 78958554,
    currentValue: 78958554,
    previousValue: 9878589,
    rowOneTitle: "Paid",
    second_currentValue: 785,
    second_previousValue: 98,
    rowTwoTitle: "Organic",
    numberVariant: "number",
    forCountry: "United Kingdom",
    chartData: LineChartDataSample,
    chartDataLabels: LineChartDataLabelsSample,
  },
  {
    cardTitle: "No. of Customer",
    showInfo: true,
    hoverDetails:
      "NSV i the Net Sales Value and is equal to the GSV less the Disc. you provide to secure the sale.",
    variant: "target",
    targetedValue: 9878589,
    achievedValue: 78958554,
    currentValue: 78958554,
    previousValue: 9878589,
    rowOneTitle: "Paid",
    second_currentValue: 785,
    second_previousValue: 98,
    rowTwoTitle: "Organic",
    numberVariant: "number",
    forCountry: "United Kingdom",
    chartData: LineChartDataSample,
    chartDataLabels: LineChartDataLabelsSample,
  },
  {
    cardTitle: "Repeat Customer",
    showInfo: true,
    hoverDetails:
      "NSV i the Net Sales Value and is equal to the GSV less the Disc. you provide to secure the sale.",
    variant: "target",
    targetedValue: 9878589,
    achievedValue: 78958554,
    currentValue: 78958554,
    previousValue: 9878589,
    rowOneTitle: "Paid",
    second_currentValue: 785,
    second_previousValue: 98,
    rowTwoTitle: "Organic",
    numberVariant: "number",
    forCountry: "United Kingdom",
    chartData: LineChartDataSample,
    chartDataLabels: LineChartDataLabelsSample,
  },
];

export const CardsSliderDataSample = [
  {
    label: "Like",
    currentValue: 18,
    previousValue: 10,
    rowOneTitle: "Previous",
  },
  {
    label: "Views",
    currentValue: 17,
    previousValue: 11,
    rowOneTitle: "Previous",
  },
  {
    label: "Reach",
    currentValue: 10,
    previousValue: 18,
    rowOneTitle: "Previous",
  },
  {
    label: "Clicks",
    currentValue: 10,
    previousValue: 10,
    rowOneTitle: "Previous",
  },
  {
    label: "Impressions",
    currentValue: 2487,
    previousValue: 2538,
  },
  {
    label: "Clicks",
    currentValue: 1640,
    previousValue: 2003,
  },
  {
    label: "Conversions",
    currentValue: 1040814,
    previousValue: 1088525,
  },
  {
    label: "Spend",
    currentValue: 2,
    previousValue: 2,
  },
  {
    label: "Engagement",
    currentValue: 1640,
    previousValue: 2003,
  },
  {
    label: "CPC",
    currentValue: 1040814,
    previousValue: 1088525,
  },
  {
    label: "CPM",
    currentValue: 2,
    previousValue: 2,
  },
  {
    label: "CTR",
    currentValue: 2,
  },
];

export const TwitterTopMentionsCardDataSample = [
  {
    name: "John",
    username: "jogndoe",
    isVerified: true,
    profilePicUrl: DefaultPostImage,
    timeStamp: "28 Sept 2022 | 12:45 PM",
    tweet:
      "Cras arcu turpis, tristique at placerat vel, aliquam a sapien. Suspendisse volutpat ligula non vulputate commodo. Donec at auctor nibh. Ut malesuada eros at congue vestibulum. In facilisis at felis at vehicula. Vivamus ultricies aliquet turpis, quis ullamcorper nibh facilisis eu.",
    tweetLink: "https://twitter.com/icogz2/status/1259735133277413376",
    replies: 7858,
    retweets: 7858,
    likes: 7858,
  },
  {
    name: "Doe",
    username: "jogndoe",
    isVerified: false,
    profilePicUrl: DefaultPostImage,
    timeStamp: "28 Sept 2022 | 12:45 PM",
    tweet:
      "Cras arcu turpis, tristique at placerat vel, aliquam a sapien. Suspendisse volutpat ligula non vulputate commodo. Donec at auctor nibh. Ut malesuada eros at congue vestibulum. In facilisis at felis at vehicula. Vivamus ultricies aliquet turpis, quis ullamcorper nibh facilisis eu.",
    tweetLink: "https://twitter.com/icogz2/status/1259735133277413376",
    replies: 7858,
    retweets: 7858,
    likes: 7858,
  },
  {
    name: "John Doe",
    username: "jogndoe",
    isVerified: true,
    profilePicUrl: DefaultPostImage,
    timeStamp: "28 Sept 2022 | 12:45 PM",
    tweet:
      "Cras arcu turpis, tristique at placerat vel, aliquam a sapien. Suspendisse volutpat ligula non vulputate commodo. Donec at auctor nibh. Ut malesuada eros at congue vestibulum. In facilisis at felis at vehicula. Vivamus ultricies aliquet turpis, quis ullamcorper nibh facilisis eu.",
    tweetLink: "https://twitter.com/icogz2/status/1259735133277413376",
    replies: 7858,
    retweets: 7858,
    likes: 7858,
  },
  {
    name: "Doe",
    username: "jogndoe",
    isVerified: false,
    profilePicUrl: DefaultPostImage,
    timeStamp: "28 Sept 2022 | 12:45 PM",
    tweet:
      "Cras arcu turpis, tristique at placerat vel, aliquam a sapien. Suspendisse volutpat ligula non vulputate commodo. Donec at auctor nibh. Ut malesuada eros at congue vestibulum. In facilisis at felis at vehicula. Vivamus ultricies aliquet turpis, quis ullamcorper nibh facilisis eu.",
    tweetLink: "https://twitter.com/icogz2/status/1259735133277413376",
    replies: 7858,
    retweets: 7858,
    likes: 7858,
  },
  {
    name: "John Doe",
    username: "jogndoe",
    isVerified: true,
    profilePicUrl: DefaultPostImage,
    timeStamp: "28 Sept 2022 | 12:45 PM",
    tweet:
      "Cras arcu turpis, tristique at placerat vel, aliquam a sapien. Suspendisse volutpat ligula non vulputate commodo. Donec at auctor nibh. Ut malesuada eros at congue vestibulum. In facilisis at felis at vehicula. Vivamus ultricies aliquet turpis, quis ullamcorper nibh facilisis eu.",
    tweetLink: "https://twitter.com/icogz2/status/1259735133277413376",
    replies: 7858,
    retweets: 7858,
    likes: 7858,
  },
];

export const TwitterTopFollowersCardDataSample = [
  {
    name: "John",
    username: "jogndoe",
    isVerified: true,
    profilePicUrl: DefaultPostImage,
    profileHeaderBannerUrl: DefaultTwitterHeaderImg,
    tweets: 7858,
    following: 7858,
    followers: 7858,
    followersFollowingRation: 1.78,
  },
  {
    name: "Doe",
    username: "jogndoe",
    isVerified: false,
    profilePicUrl: DefaultPostImage,
    profileHeaderBannerUrl: DefaultTwitterHeaderImg,
    tweets: 7858,
    following: 7858,
    followers: 7858,
    followersFollowingRation: 1.78,
  },
  {
    name: "John Doe",
    username: "jogndoe",
    isVerified: true,
    profilePicUrl: DefaultPostImage,
    profileHeaderBannerUrl: DefaultTwitterHeaderImg,
    tweets: 7858,
    following: 7858,
    followers: 7858,
    followersFollowingRation: 1.78,
  },
  {
    name: "Doe",
    username: "jogndoe",
    isVerified: false,
    profilePicUrl: DefaultPostImage,
    profileHeaderBannerUrl: DefaultTwitterHeaderImg,
    tweets: 7858,
    following: 7858,
    followers: 7858,
    followersFollowingRation: 1.78,
  },
  {
    name: "John Doe",
    username: "jogndoe",
    isVerified: true,
    profilePicUrl: DefaultPostImage,
    profileHeaderBannerUrl: DefaultTwitterHeaderImg,
    tweets: 7858,
    following: 7858,
    followers: 7858,
    followersFollowingRation: 1.78,
  },
];

export const VideoThumbnailCardDataSample = [
  {
    thumbnailUrl: DefaultVideoThumbnailImage,
    videoLength: "1:00",
    videoPlatform: "Facebook",
    views: 2564585,
  },
  {
    thumbnailUrl: DefaultVideoThumbnailImage,
    videoLength: "1:00",
    videoPlatform: "Instagram",
    views: 2564585,
  },
  {
    thumbnailUrl: DefaultVideoThumbnailImage,
    videoLength: "1:00",
    videoPlatform: "Twitter",
    views: 2564585,
  },
  {
    thumbnailUrl: DefaultVideoThumbnailImage,
    videoLength: "1:00",
    videoPlatform: "Linkedin",
    views: 2564585,
  },
  {
    thumbnailUrl: DefaultVideoThumbnailImage,
    videoLength: "1:00",
    videoPlatform: "Youtube",
    views: 2564585,
  },
];

export const PlatformCardDataSample = [
  {
    cardTitle: "Facebook",
    currentValue: 0,
    previousValue: 0,
    rowOneTitle: "Paid",
    second_currentValue: 0,
    second_previousValue: 0,
    rowTwoTitle: "Organic",
  },
  {
    cardTitle: "Instagram",
    currentValue: 0,
    previousValue: 0,
    rowOneTitle: "Paid",
    second_currentValue: 0,
    second_previousValue: 0,
    rowTwoTitle: "Organic",
  },
  {
    cardTitle: "Twitter",
    currentValue: 0,
    previousValue: 0,
    rowOneTitle: "Paid",
    second_currentValue: 0,
    second_previousValue: 0,
    rowTwoTitle: "Organic",
  },
  {
    cardTitle: "Linkedin",
    currentValue: 0,
    previousValue: 0,
    rowOneTitle: "Paid",
    second_currentValue: 0,
    second_previousValue: 0,
    rowTwoTitle: "Organic",
  },
  {
    cardTitle: "Youtube",
    currentValue: 0,
    previousValue: 0,
    rowOneTitle: "Paid",
    second_currentValue: 0,
    second_previousValue: 0,
    rowTwoTitle: "Organic",
  },
];

export const SecondPlatformCardDataSample = [
  {
    cardTitle: "Facebook",
    currentValue: 0,
    previousValue: 0,
    rowOneTitle: "Paid",
    second_currentValue: 0,
    second_previousValue: 0,
    rowTwoTitle: "Organic",
  },
  {
    cardTitle: "Instagram",
    currentValue: 0,
    previousValue: 0,
    rowOneTitle: "Paid",
    second_currentValue: 0,
    second_previousValue: 0,
    rowTwoTitle: "Organic",
  },
  {
    cardTitle: "Twitter",
    currentValue: 0,
    previousValue: 0,
    rowOneTitle: "Paid",
    second_currentValue: 0,
    second_previousValue: 0,
    rowTwoTitle: "Organic",
  }
];


export const TopProductCompareSample = {
  "age": {
    "label": {
      "spends": {
        "252220251489093_5517225858321813": [
          {
            "label": "Spends",
            "value": "value"
          }
        ],
        "252220251489093_5956197681091293": [
          {
            "label": "Spends",
            "value": "value"
          }
        ],
        "252220251489093_5864956533548742": [
          {
            "label": "Spends",
            "value": "value"
          }
        ]
      },
      "reach": {
        "252220251489093_5517225858321813": [
          {
            "label": "Reach",
            "value": "value"
          }
        ],
        "252220251489093_5956197681091293": [
          {
            "label": "Reach",
            "value": "value"
          }
        ],
        "252220251489093_5864956533548742": [
          {
            "label": "Reach",
            "value": "value"
          }
        ]
      },
      "impressions": {
        "252220251489093_5517225858321813": [
          {
            "label": "Impressions",
            "value": "value"
          }
        ],
        "252220251489093_5956197681091293": [
          {
            "label": "Impressions",
            "value": "value"
          }
        ],
        "252220251489093_5864956533548742": [
          {
            "label": "Impressions",
            "value": "value"
          }
        ]
      },
      "engagements": {
        "252220251489093_5517225858321813": [
          {
            "label": "Engagements",
            "value": "value"
          }
        ],
        "252220251489093_5956197681091293": [
          {
            "label": "Engagements",
            "value": "value"
          }
        ],
        "252220251489093_5864956533548742": [
          {
            "label": "Engagements",
            "value": "value"
          }
        ]
      },
      "cpm": {
        "252220251489093_5517225858321813": [
          {
            "label": "CPM",
            "value": "value"
          }
        ],
        "252220251489093_5956197681091293": [
          {
            "label": "CPM",
            "value": "value"
          }
        ],
        "252220251489093_5864956533548742": [
          {
            "label": "CPM",
            "value": "value"
          }
        ]
      }
    },
    "data": {
      "spends": {
        "252220251489093_5517225858321813": [
          {
            "label": "Iphone",
            "value": 256106
          },
          {
            "label": "Androidtablet",
            "value": 3099
          },
          {
            "label": "Ipad",
            "value": 2347
          }
        ],
        "252220251489093_5956197681091293": [
          {
            "label": "Iphone",
            "value": 79234
          },
          {
            "label": "Androidtablet",
            "value": 98234
          },
          {
            "label": "Ipad",
            "value": 12342
          }
        ],
        "252220251489093_5864956533548742": [
          {
            "label": "Iphone",
            "value": 902334
          },
          {
            "label": "Androidtablet",
            "value": 124211
          },
          {
            "label": "Ipad",
            "value": 787232
          }
        ]
      },
      "reach": {
        "252220251489093_5517225858321813": [
          {
            "label": "Iphone",
            "value": 256106
          },
          {
            "label": "Androidtablet",
            "value": 3099
          },
          {
            "label": "Ipad",
            "value": 2347
          }
        ],
        "252220251489093_5956197681091293": [
          {
            "label": "Iphone",
            "value": 256106
          },
          {
            "label": "Androidtablet",
            "value": 3099
          },
          {
            "label": "Ipad",
            "value": 2347
          }
        ],
        "252220251489093_5864956533548742": [
          {
            "label": "Iphone",
            "value": 256106
          },
          {
            "label": "Androidtablet",
            "value": 3099
          },
          {
            "label": "Ipad",
            "value": 2347
          }
        ]
      },
      "impressions": {
        "252220251489093_5517225858321813": [
          {
            "label": "Iphone",
            "value": 206
          },
          {
            "label": "Androidtablet",
            "value": 309
          },
          {
            "label": "Ipad",
            "value": 237
          }
        ],
        "252220251489093_5956197681091293": [
          {
            "label": "Iphone",
            "value": 256106
          },
          {
            "label": "Androidtablet",
            "value": 3099
          },
          {
            "label": "Ipad",
            "value": 2347
          }
        ],
        "252220251489093_5864956533548742": [
          {
            "label": "Iphone",
            "value": 256106
          },
          {
            "label": "Androidtablet",
            "value": 3099
          },
          {
            "label": "Ipad",
            "value": 2347
          }
        ]
      },
      "engagements": {
        "252220251489093_5517225858321813": [
          {
            "label": "Iphone",
            "value": 256106
          },
          {
            "label": "Androidtablet",
            "value": 3099
          },
          {
            "label": "Ipad",
            "value": 2347
          }
        ],
        "252220251489093_5956197681091293": [
          {
            "label": "Iphone",
            "value": 256106
          },
          {
            "label": "Androidtablet",
            "value": 3099
          },
          {
            "label": "Ipad",
            "value": 2347
          }
        ],
        "252220251489093_5864956533548742": [
          {
            "label": "Iphone",
            "value": 256106
          },
          {
            "label": "Androidtablet",
            "value": 3099
          },
          {
            "label": "Ipad",
            "value": 2347
          }
        ]
      },
      "cpm": {
        "252220251489093_5517225858321813": [
          {
            "label": "Iphone",
            "value": 256106
          },
          {
            "label": "Androidtablet",
            "value": 3099
          },
          {
            "label": "Ipad",
            "value": 2347
          }
        ],
        "252220251489093_5956197681091293": [
          {
            "label": "Iphone",
            "value": 256106
          },
          {
            "label": "Androidtablet",
            "value": 3099
          },
          {
            "label": "Ipad",
            "value": 2347
          }
        ],
        "252220251489093_5864956533548742": [
          {
            "label": "Iphone",
            "value": 256106
          },
          {
            "label": "Androidtablet",
            "value": 3099
          },
          {
            "label": "Ipad",
            "value": 2347
          }
        ]
      }
    }
  },
  "gender": {
    "spends": {
      "252220251489093_5517225858321813": {
        "male": 2106,
        "female": 3099,
        "unknown": 1000
      },
      "252220251489093_5956197681091293": {
        "male": 2106,
        "female": 3099,
        "unknown": 1000
      },
      "252220251489093_5864956533548742": {
        "male": 2106,
        "female": 3099,
        "unknown": 1000
      }
    },
    "reach": {
      "252220251489093_5517225858321813": {
        "male": 256106,
        "female": 3099,
        "unknown": 2347
      },
      "252220251489093_5956197681091293": {
        "male": 256106,
        "female": 3099,
        "unknown": 2347
      },
      "252220251489093_5864956533548742": {
        "male": 256106,
        "female": 3099,
        "unknown": 2347
      }
    },
    "impressions": {
      "252220251489093_5517225858321813": {
        "male": 256106,
        "female": 3099,
        "unknown": 2347
      },
      "252220251489093_5956197681091293": {
        "male": 256106,
        "female": 3099,
        "unknown": 2347
      },
      "252220251489093_5864956533548742": {
        "male": 256106,
        "female": 3099,
        "unknown": 2347
      }
    },
    "engagements": {
      "252220251489093_5517225858321813": {
        "male": 256106,
        "female": 3099,
        "unknown": 2347
      },
      "252220251489093_5956197681091293": {
        "male": 256106,
        "female": 3099,
        "unknown": 2347
      },
      "252220251489093_5864956533548742": {
        "male": 256106,
        "female": 3099,
        "unknown": 2347
      }
    },
    "cpm": {
      "252220251489093_5517225858321813": {
        "male": 256106,
        "female": 3099,
        "unknown": 2347
      },
      "252220251489093_5956197681091293": {
        "male": 256106,
        "female": 3099,
        "unknown": 2347
      },
      "252220251489093_5864956533548742": {
        "male": 256106,
        "female": 3099,
        "unknown": 2347
      }
    }
  },
  "device": {
    "spends": {
      "252220251489093_5517225858321813": [
        {
          "label": "Iphone",
          "value": 2106
        },
        {
          "label": "Androidtablet",
          "value": 3099
        },
        {
          "label": "Ipad",
          "value": 2347
        }
      ],
      "252220251489093_5956197681091293": [
        {
          "label": "Iphone",
          "value": 78
        },
        {
          "label": "Androidtablet",
          "value": 76
        },
        {
          "label": "Ipad",
          "value": 25
        }
      ],
      "252220251489093_5864956533548742": [
        {
          "label": "Iphone",
          "value": 256106
        },
        {
          "label": "Androidtablet",
          "value": 3099
        },
        {
          "label": "Ipad",
          "value": 2347
        }
      ]
    },
    "reach": {
      "252220251489093_5517225858321813": [
        {
          "label": "Iphone",
          "value": 96
        },
        {
          "label": "Androidtablet",
          "value": 3099
        },
        {
          "label": "Ipad",
          "value": 2347
        }
      ],
      "252220251489093_5956197681091293": [
        {
          "label": "Iphone",
          "value": 36
        },
        {
          "label": "Androidtablet",
          "value": 3099
        },
        {
          "label": "Ipad",
          "value": 2347
        }
      ],
      "252220251489093_5864956533548742": [
        {
          "label": "Iphone",
          "value": 15
        },
        {
          "label": "Androidtablet",
          "value": 3099
        },
        {
          "label": "Ipad",
          "value": 2347
        }
      ]
    },
    "impressions": {
      "252220251489093_5517225858321813": [
        {
          "label": "Iphone",
          "value": 47
        },
        {
          "label": "Androidtablet",
          "value": 3099
        },
        {
          "label": "Ipad",
          "value": 2347
        }
      ],
      "252220251489093_5956197681091293": [
        {
          "label": "Iphone",
          "value": 8
        },
        {
          "label": "Androidtablet",
          "value": 3099
        },
        {
          "label": "Ipad",
          "value": 2347
        }
      ],
      "252220251489093_5864956533548742": [
        {
          "label": "Iphone",
          "value": 23
        },
        {
          "label": "Androidtablet",
          "value": 3099
        },
        {
          "label": "Ipad",
          "value": 2347
        }
      ]
    },
    "engagements": {
      "252220251489093_5517225858321813": [
        {
          "label": "Iphone",
          "value": 256106
        },
        {
          "label": "Androidtablet",
          "value": 3099
        },
        {
          "label": "Ipad",
          "value": 2347
        }
      ],
      "252220251489093_5956197681091293": [
        {
          "label": "Iphone",
          "value": 256106
        },
        {
          "label": "Androidtablet",
          "value": 3099
        },
        {
          "label": "Ipad",
          "value": 2347
        }
      ],
      "252220251489093_5864956533548742": [
        {
          "label": "Iphone",
          "value": 256106
        },
        {
          "label": "Androidtablet",
          "value": 3099
        },
        {
          "label": "Ipad",
          "value": 2347
        }
      ]
    },
    "cpm": {
      "252220251489093_5517225858321813": [
        {
          "label": "Iphone",
          "value": 256106
        },
        {
          "label": "Androidtablet",
          "value": 3099
        },
        {
          "label": "Ipad",
          "value": 2347
        }
      ],
      "252220251489093_5956197681091293": [
        {
          "label": "Iphone",
          "value": 256106
        },
        {
          "label": "Androidtablet",
          "value": 3099
        },
        {
          "label": "Ipad",
          "value": 2347
        }
      ],
      "252220251489093_5864956533548742": [
        {
          "label": "Iphone",
          "value": 256106
        },
        {
          "label": "Androidtablet",
          "value": 3099
        },
        {
          "label": "Ipad",
          "value": 2347
        }
      ]
    }
  }
}


export const AllCardDataSample = [
  {
    label: "Card Label",
    currentValue: 18,
    cardTitle: "Card Title Here",
    previousValue: 10,
    rowOneTitle: "Previous",
    numberVariant: "money",
    forCountry: "India",
    showInfo: true,
    hoverDetails:
      "NSV i the Net Sales Value and is equal to the GSV less the Disc. you provide to secure the sale.",
    variant: "target",
    targetedValue: 9878589,
    achievedValue: 78958554,
    second_currentValue: 785,
    second_previousValue: 98,
    rowTwoTitle: "Organic",
    chartData: LineChartDataSample,
    chartDataLabels: LineChartDataLabelsSample,
    name: "John",
    username: "jogndoe",
    isVerified: true,
    profilePicUrl: DefaultPostImage,
    timeStamp: "28 Sept 2022 | 12:45 PM",
    tweet:
      "Cras arcu turpis, tristique at placerat vel, aliquam a sapien. Suspendisse volutpat ligula non vulputate commodo. Donec at auctor nibh. Ut malesuada eros at congue vestibulum. In facilisis at felis at vehicula. Vivamus ultricies aliquet turpis, quis ullamcorper nibh facilisis eu.",
    tweetLink: "https://twitter.com/icogz2/status/1259735133277413376",
    replies: 7858,
    retweets: 7858,
    likes: 7858,
    thumbnailUrl: DefaultVideoThumbnailImage,
    videoLength: "1:00",
    videoPlatform: "Facebook",
    views: 2564585,
  },
  {
    label: "Card Label",
    currentValue: 18,
    cardTitle: "Card Title Here",
    previousValue: 10,
    rowOneTitle: "Previous",
    numberVariant: "money",
    forCountry: "India",
    showInfo: true,
    hoverDetails:
      "NSV i the Net Sales Value and is equal to the GSV less the Disc. you provide to secure the sale.",
    variant: "target",
    targetedValue: 9878589,
    achievedValue: 78958554,
    second_currentValue: 785,
    second_previousValue: 98,
    rowTwoTitle: "Organic",
    chartData: LineChartDataSample,
    chartDataLabels: LineChartDataLabelsSample,
    name: "John",
    username: "jogndoe",
    isVerified: true,
    profilePicUrl: DefaultPostImage,
    timeStamp: "28 Sept 2022 | 12:45 PM",
    tweet:
      "Cras arcu turpis, tristique at placerat vel, aliquam a sapien. Suspendisse volutpat ligula non vulputate commodo. Donec at auctor nibh. Ut malesuada eros at congue vestibulum. In facilisis at felis at vehicula. Vivamus ultricies aliquet turpis, quis ullamcorper nibh facilisis eu.",
    tweetLink: "https://twitter.com/icogz2/status/1259735133277413376",
    replies: 7858,
    retweets: 7858,
    likes: 7858,
    thumbnailUrl: DefaultVideoThumbnailImage,
    videoLength: "1:00",
    videoPlatform: "Facebook",
    views: 2564585,
  },
  {
    label: "Card Label",
    currentValue: 18,
    cardTitle: "Card Title Here",
    previousValue: 10,
    rowOneTitle: "Previous",
    numberVariant: "money",
    forCountry: "India",
    showInfo: true,
    hoverDetails:
      "NSV i the Net Sales Value and is equal to the GSV less the Disc. you provide to secure the sale.",
    variant: "target",
    targetedValue: 9878589,
    achievedValue: 78958554,
    second_currentValue: 785,
    second_previousValue: 98,
    rowTwoTitle: "Organic",
    chartData: LineChartDataSample,
    chartDataLabels: LineChartDataLabelsSample,
    name: "John",
    username: "jogndoe",
    isVerified: true,
    profilePicUrl: DefaultPostImage,
    timeStamp: "28 Sept 2022 | 12:45 PM",
    tweet:
      "Cras arcu turpis, tristique at placerat vel, aliquam a sapien. Suspendisse volutpat ligula non vulputate commodo. Donec at auctor nibh. Ut malesuada eros at congue vestibulum. In facilisis at felis at vehicula. Vivamus ultricies aliquet turpis, quis ullamcorper nibh facilisis eu.",
    tweetLink: "https://twitter.com/icogz2/status/1259735133277413376",
    replies: 7858,
    retweets: 7858,
    likes: 7858,
    thumbnailUrl: DefaultVideoThumbnailImage,
    videoLength: "1:00",
    videoPlatform: "Facebook",
    views: 2564585,
  },
  {
    label: "Card Label",
    currentValue: 18,
    cardTitle: "Card Title Here",
    previousValue: 10,
    rowOneTitle: "Previous",
    numberVariant: "money",
    forCountry: "India",
    showInfo: true,
    hoverDetails:
      "NSV i the Net Sales Value and is equal to the GSV less the Disc. you provide to secure the sale.",
    variant: "target",
    targetedValue: 9878589,
    achievedValue: 78958554,
    second_currentValue: 785,
    second_previousValue: 98,
    rowTwoTitle: "Organic",
    chartData: LineChartDataSample,
    chartDataLabels: LineChartDataLabelsSample,
    name: "John",
    username: "jogndoe",
    isVerified: true,
    profilePicUrl: DefaultPostImage,
    timeStamp: "28 Sept 2022 | 12:45 PM",
    tweet:
      "Cras arcu turpis, tristique at placerat vel, aliquam a sapien. Suspendisse volutpat ligula non vulputate commodo. Donec at auctor nibh. Ut malesuada eros at congue vestibulum. In facilisis at felis at vehicula. Vivamus ultricies aliquet turpis, quis ullamcorper nibh facilisis eu.",
    tweetLink: "https://twitter.com/icogz2/status/1259735133277413376",
    replies: 7858,
    retweets: 7858,
    likes: 7858,
    thumbnailUrl: DefaultVideoThumbnailImage,
    videoLength: "1:00",
    videoPlatform: "Facebook",
    views: 2564585,
  },
  {
    label: "Card Label",
    currentValue: 18,
    cardTitle: "Card Title Here",
    previousValue: 10,
    rowOneTitle: "Previous",
    numberVariant: "money",
    forCountry: "India",
    showInfo: true,
    hoverDetails:
      "NSV i the Net Sales Value and is equal to the GSV less the Disc. you provide to secure the sale.",
    variant: "target",
    targetedValue: 9878589,
    achievedValue: 78958554,
    second_currentValue: 785,
    second_previousValue: 98,
    rowTwoTitle: "Organic",
    chartData: LineChartDataSample,
    chartDataLabels: LineChartDataLabelsSample,
    name: "John",
    username: "jogndoe",
    isVerified: true,
    profilePicUrl: DefaultPostImage,
    timeStamp: "28 Sept 2022 | 12:45 PM",
    tweet:
      "Cras arcu turpis, tristique at placerat vel, aliquam a sapien. Suspendisse volutpat ligula non vulputate commodo. Donec at auctor nibh. Ut malesuada eros at congue vestibulum. In facilisis at felis at vehicula. Vivamus ultricies aliquet turpis, quis ullamcorper nibh facilisis eu.",
    tweetLink: "https://twitter.com/icogz2/status/1259735133277413376",
    replies: 7858,
    retweets: 7858,
    likes: 7858,
    thumbnailUrl: DefaultVideoThumbnailImage,
    videoLength: "1:00",
    videoPlatform: "Facebook",
    views: 2564585,
  },
  {
    label: "Card Label",
    currentValue: 18,
    cardTitle: "Card Title Here",
    previousValue: 10,
    rowOneTitle: "Previous",
    numberVariant: "money",
    forCountry: "India",
    showInfo: true,
    hoverDetails:
      "NSV i the Net Sales Value and is equal to the GSV less the Disc. you provide to secure the sale.",
    variant: "target",
    targetedValue: 9878589,
    achievedValue: 78958554,
    second_currentValue: 785,
    second_previousValue: 98,
    rowTwoTitle: "Organic",
    chartData: LineChartDataSample,
    chartDataLabels: LineChartDataLabelsSample,
    name: "John",
    username: "jogndoe",
    isVerified: true,
    profilePicUrl: DefaultPostImage,
    timeStamp: "28 Sept 2022 | 12:45 PM",
    tweet:
      "Cras arcu turpis, tristique at placerat vel, aliquam a sapien. Suspendisse volutpat ligula non vulputate commodo. Donec at auctor nibh. Ut malesuada eros at congue vestibulum. In facilisis at felis at vehicula. Vivamus ultricies aliquet turpis, quis ullamcorper nibh facilisis eu.",
    tweetLink: "https://twitter.com/icogz2/status/1259735133277413376",
    replies: 7858,
    retweets: 7858,
    likes: 7858,
    thumbnailUrl: DefaultVideoThumbnailImage,
    videoLength: "1:00",
    videoPlatform: "Facebook",
    views: 2564585,
  },
  {
    label: "Card Label",
    currentValue: 18,
    cardTitle: "Card Title Here",
    previousValue: 10,
    rowOneTitle: "Previous",
    numberVariant: "money",
    forCountry: "India",
    showInfo: true,
    hoverDetails:
      "NSV i the Net Sales Value and is equal to the GSV less the Disc. you provide to secure the sale.",
    variant: "target",
    targetedValue: 9878589,
    achievedValue: 78958554,
    second_currentValue: 785,
    second_previousValue: 98,
    rowTwoTitle: "Organic",
    chartData: LineChartDataSample,
    chartDataLabels: LineChartDataLabelsSample,
    name: "John",
    username: "jogndoe",
    isVerified: true,
    profilePicUrl: DefaultPostImage,
    timeStamp: "28 Sept 2022 | 12:45 PM",
    tweet:
      "Cras arcu turpis, tristique at placerat vel, aliquam a sapien. Suspendisse volutpat ligula non vulputate commodo. Donec at auctor nibh. Ut malesuada eros at congue vestibulum. In facilisis at felis at vehicula. Vivamus ultricies aliquet turpis, quis ullamcorper nibh facilisis eu.",
    tweetLink: "https://twitter.com/icogz2/status/1259735133277413376",
    replies: 7858,
    retweets: 7858,
    likes: 7858,
    thumbnailUrl: DefaultVideoThumbnailImage,
    videoLength: "1:00",
    videoPlatform: "Facebook",
    views: 2564585,
  }
]

// export const TopProductCompareSample2= {
//   "age" : {
//     "spends":{
//       "label" : {
//         "252220251489093_5517225858321813": [
//           {
//             "label": "Spends",
//             "value": "spends"
//           }
//         ],
//         "252220251489093_5956197681091293": [
//           {
//             "label": "Spends",
//             "value": "spends"
//           }
//         ],
//         "252220251489093_5864956533548742": [
//           {
//             "label": "Spends",
//             "value": "spends"
//           }
//         ]
//       },
//       "data" : {
//         "252220251489093_5517225858321813": [
//           {
//             "label": "Iphone",
//             "value": 256106
//           },
//           {
//             "label": "Androidtablet",
//             "value": 3099
//           },
//           {
//             "label": "Ipad",
//             "value": 2347
//           }
//         ],
//         "252220251489093_5956197681091293": [
//           {
//             "label": "Iphone",
//             "value": 256106
//           },
//           {
//             "label": "Androidtablet",
//             "value": 3099
//           },
//           {
//             "label": "Ipad",
//             "value": 2347
//           }
//         ],
//         "252220251489093_5864956533548742": [
//           {
//             "label": "Iphone",
//             "value": 256106
//           },
//           {
//             "label": "Androidtablet",
//             "value": 3099
//           },
//           {
//             "label": "Ipad",
//             "value": 2347
//           }
//         ]
//       },
//     },
//     "reach": {},
//     "impressions":{},
//     "engagements":{},
//     "cpm": {},
//   }
// }


export const marketComparisonTable = [
  {
    label: "Monthly Sales Vol.",
    value: 3742386742,
    label1: "Market Share",
    value1: 14
  },
  {
    label: "TV No. of Creatives",
    value: 14,
    label1: "Digital No. of Campaigns",
    value1: 48
  },
  {
    label: "TV Spends",
    value: 3742386742,
    label1: "Digital Spends",
    value1: 14
  },
  {
    label: "TV Spends",
    value: 3742386742,
    label1: "Digital Spends",
    value1: 14
  }
]


export const marketBrandOverviewTable = [
  {
    label1: "Market Share",
    value1: 14,
    label2: "Monthly Sales Vol.",
    value2: 3742386742,
    label3: "Market Share",
    value3: 14,
    label4: "Monthly Sales Vol.",
    value4: 3742386742,
  },
  {
    label1: "Market Share",
    value1: 14,
    label2: "Monthly Sales Vol.",
    value2: 3742386742,
    label3: "Market Share",
    value3: 14,
    label4: "Monthly Sales Vol.",
    value4: 3742386742,
  },
  {
    label1: "Market Share",
    value1: 14,
    label2: "Monthly Sales Vol.",
    value2: 3742386742,
    label3: "Market Share",
    value3: 14,
    label4: "Monthly Sales Vol.",
    value4: 3742386742,
  },
]


export const TvHaleonNPTData = [
  {
    "title": "English",
    "label1": "PT",
    "value1": 410,
    "label2": "NPT",
    "value2": 390
  },
  {
    "title": "HD",
    "label1": "PT",
    "value1": 430,
    "label2": "NPT",
    "value2": 430
  },
  {
    "title": "Hindi GEC 1",
    "label1": "PT",
    "value1": 360,
    "label2": "NPT",
    "value2": 588
  },
  {
    "title": "Hindi GEC 2",
    "label1": "PT",
    "value1": 405,
    "label2": "NPT",
    "value2": 330
  },
  {
    "title": "Hindi Kids",
    "label1": "PT",
    "value1": 370,
    "label2": "NPT",
    "value2": 877
  },
  {
    "title": "Hindi Movies 1",
    "label1": "PT",
    "value1": 750,
    "label2": "NPT",
    "value2": 405
  },

]

export const GrowthDegrowthTableHeader = [
  { name: "Ad Name", field: "ad_name" },
  { name: "GRP", field: "grp" },
  { name: "Spends", field: "spends" },
  { name: "Reach 1+", field: "reach" },
  { name: "CPRP", field: "cprp" },
  { name: "Prime Time Share", field: "prime_time" },
]
export const GrowthDegrowthTable = [
  {
    ad_name: "Aileen Chatterjee Promotes",
    grp: 235,
    "spends": 18542890,
    "reach": 3542,
    "cprp": -7890630,
    "prime_time": -51
  },
  {
    ad_name: "Annie Kurian Promotes",
    grp: 412,
    "spends": 11231080,
    "reach": 4055,
    "cprp": 27259.90,
    "prime_time": 59
  }, {
    ad_name: "Dr Roshni Gidwani Promotes",
    grp: 325,
    "spends": 19748490,
    "reach": -4323,
    "cprp": 60764.58,
    "prime_time": 0
  }, {
    ad_name: "Dr Srinivas Kore Promotes",
    grp: 126,
    "spends": 21256590,
    "reach": 2895,
    "cprp": 168709.10,
    "prime_time": 56
  }, {
    ad_name: "Jaya Jayaram Promotes",
    grp: -242,
    "spends": -21694310,
    "reach": 3140,
    "cprp": 89645.91,
    "prime_time": 65
  },
]