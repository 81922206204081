import React, { useContext, useEffect, useState } from "react";

// STORAGE
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";

// pages
import FacebookAllVideosOrganic from "./FacebookAllVideosOrganic";
import FacebookAllVideosOverall from "./FacebookAllVideosOverall";
import FacebookAllVideosPaid from "./FacebookAllVideosPaid";

// UTILS
import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";

export default function FacebookAllVideos({ videoDurationType, topCardData }) {
  const { setExportPageName } = useContext(FullPageExportContext);

  const tabData = ["Overall", "Organic", "Paid"];
  const [selectedTab, setSelectedTab] = useState(tabData[0]);

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Video", "Facebook", event)
  };

  useEffect(() => {
    setExportPageName("Video Facebook " + selectedTab)
  }, [selectedTab])


  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <Tab
          varient={"secondary"}
          tabData={tabData}
          activeTab={selectedTab}
          handleCallback={(event) => handleCallback(event)}
        />
      </div>
      {selectedTab.toLowerCase() === tabData[0].toLowerCase() && <FacebookAllVideosOverall videoDurationType={videoDurationType} topCardData={topCardData} />}
      {selectedTab.toLowerCase() === tabData[1].toLowerCase() && <FacebookAllVideosOrganic videoDurationType={videoDurationType} topCardData={topCardData} />}
      {selectedTab.toLowerCase() === tabData[2].toLowerCase() && <FacebookAllVideosPaid videoDurationType={videoDurationType} topCardData={topCardData} />}
    </Wrapper>
  );
}
