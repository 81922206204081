import React, { useState, useMemo, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../store/authContext";
import FullPageExportContext from "../../../store/fullPageExportContext";

// LOADERS
import ServerErrorsLoader from "../../../components/ui/loaders/ServerErrosLoader";
import Loader from "../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../components/ui/loaders/NoDataAvailable";

// COMPONENTS
import Slider from "../../../components/ui/Slider";
import Card from "../../../components/ui/Card";
import axios from "../../../components/helpers/axios";
import Wrapper from "../../../components/helpers/Wrapper";
import TopProductCompare from "../../../components/ui/TopProductCompare";
import SectionDefinationsBadge from "../../../components/ui/SectionDefinationsBadge";
import ExportDropdown from "../../../components/ui/dropdown/ExportDropdown";
import Datatable from "../../../components/ui/tables/Datatable";

// CHARTS
import PieofaPieChart from "../../../components/ui/charts/PieofaPieChart";
import LineChart from "../../../components/ui/charts/LineChart";

// UTILS
import { COKE_COMPANY_ID, DefaultPostImage, HALEON_COMPANY_ID } from "../../../constants/constants";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import NumberFormatter from "../../../utils/NumberFormatter";
import NoDataAvailableChecker from "../../../utils/NoDataAvailableChecker";

import { IAEvent_Hashtag_Visit } from "../../../utils/IAEvents";

export default function OverallVideo({ videoDurationType, topCardData }) {
  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Video"
  let pagePlatform = "Youtube"
  //let pagePrimaryTab = "Organic"

  const authCtx = useContext(AuthContext);

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  let companyID = userData?.company?.id ?? "";

  const { setFullPageExport, exportSummary, setExportPageName, setIsExportDisabled } =
    useContext(FullPageExportContext);
  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const selectedDates = useSelector((state) => state.date);
  const [searchTerm, setSeachTerm] = useState("");

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // Video Length Analysis
  const [videoLengthAnalysis, setVideoLengthAnalysis] = useState([]);
  const [videoLengthAnalysisLabel, setVideoLengthAnalysisLabel] = useState([]);
  // const [videoLengthAnalysisAllDropdownData, setVideoLengthAnalysisAllDropdownData] = useState([]);
  // const [videoLengthAnalysisAllDropdownLabel, setVideoLengthAnalysisAllDropdownLabel] = useState([]);
  const [isVideoLengthAnalysisLoading, setVideoLengthAnalysisIsLoading] = useState(false);
  const [isVideoLengthAnalysisLoaded, setVideoLengthAnalysisIsLoaded] = useState(false);
  const [videoLengthAnalysiserror, setVideoLengthAnalysisError] = useState(null);

  // Daily trend line
  const [viewTrendAnalysis, setViewTrendAnalysis] = useState([]);
  const [viewTrendAnalysisLabel, setViewTrendAnalysisLabel] = useState([]);
  const [isViewTrendAnalysisLoading, setViewTrendAnalysisIsLoading] = useState(false);
  const [isViewTrendAnalysisLoaded, setViewTrendAnalysisIsLoaded] = useState(false);
  const [viewTrendAnalysisError, setViewTrendAnalysisError] = useState(null);

  // VIEW TREND ANALYSIS DETAILS
  const [viewTrendAnalysisDetails, setViewTrendAnalysisDetails] = useState([]);
  const [isViewTrendAnalysisDetailsLoading, setViewTrendAnalysisDetailsIsLoading] = useState(false);
  // const [viewTrendCSVDetails, setviewTrendCSVDetails] = useState([]);
  const [isViewTrendAnalysisDetailsLoaded, setViewTrendAnalysisDetailsIsLoaded] = useState(false);
  const [viewTrendAnalysisDetailserror, setViewTrendAnalysisDetailsError] = useState(null);
  const [viewTrendAnalysisTopPImgUrl, setViewTrendAnalysisTopPImgUrl] = useState("");

  // CAMPAIGN SUMMARY
  const [channelSummary, setChannelSummary] = useState([]);
  // const [channelSummaryAllDropdownsData, setChannelSummaryAllDropdownsData] = useState([]);
  const [isChannelSummaryLoading, setChannelSummaryIsLoading] = useState(false);
  const [isChannelSummaryLoaded, setChannelSummaryIsLoaded] = useState(false);
  const [channelSummaryerror, setChannelSummaryError] = useState(null);

  const channelSummaryTableHeaders = [
    { name: "Channel Logo", field: "channel_logo", sortable: false },
    { name: "Channel Name", field: "channel_name" },
    { name: "Subscriber", field: "subscribers" },
    { name: "SAC ($)", field: "sac" },
    { name: "RS", field: "rs" },
    { name: "Videos", field: "videos" },
    { name: "Push Score", field: "push_score" },
    { name: "Views", field: "views" },
    { name: "Net Audience Views", field: "net_audience_views" },
    { name: "TOFE", field: "tofe" },
    { name: "PES", field: "pes" },
    { name: "More Details", field: "link", sortable: false },
  ];

  // TOP POST
  const [topPosts, setTopPosts] = useState([]);
  const [topPostsLoading, settopPostsLoading] = useState(false);
  const [topPostsLoaded, setTopPostsloaded] = useState(false);
  const [topPostserror, setTopPostsError] = useState(null);

  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState();

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag == "") {
      setSelectedHashtag(badge.hashtag);
      CallAPIs(badge.hashtag)
    } else if (selectedHashtag == badge.hashtag) {
      setSelectedHashtag("");
      CallAPIs()
    } else {
      setSelectedHashtag(badge.hashtag);
      CallAPIs(badge.hashtag)
    }
    IAEvent_Hashtag_Visit("Marketing Intelligence", "Video", "Overall", null, null, badge.hashtag)

  };

  // TO GET THE TOP POST FROM POST IMPRESSIONS
  const [topPostSelectedDate, setTopPostSelectedDate] = useState("");

  let topPostStartDate;
  let topPostEndDate;

  if (topPostSelectedDate !== "") {
    topPostStartDate = topPostSelectedDate;
    topPostEndDate = topPostSelectedDate;
  } else {
    topPostStartDate = selectedDates.startDate;
    topPostEndDate = selectedDates.endDate;
  }

  // TO GET THE DATE FROM LINECHART ONCLICK
  const bulletClicksHandler = (data) => {
    setTopPostSelectedDate(data);
  };


  const CallAPIs = (hashtag) => {
    fetchMetricCardsHandler(hashtag);
    fetchVideoLengthAnalysisHandler(hashtag);
    fetchViewTrendAnalysisHandler(hashtag);
    companyID !== HALEON_COMPANY_ID && fetchChannelSummaryHandler(hashtag);
    fetchTopPostsHandler(hashtag);
    fetchViewTrendAnalysisDetailsHandler(hashtag);

  }

  useEffect(() => {
    setSelectedHashtag('')
    CallAPIs();
    fetchHashtagListHandler()
  }, [BRAND_ID, selectedDates, videoDurationType]);

  useEffect(() => {
    fetchViewTrendAnalysisDetailsHandler();
  }, [topPostSelectedDate]);


  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader =
      ` : ` + (selectedHashtag !== undefined ? selectedHashtag : "");
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async (hashtag) => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        video_duration_type: videoDurationType,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: hashtag ?? null,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/overall/metric-card/", config);

      setMetricCards(res?.data?.data ?? []);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </div>
      </Wrapper>
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="video"
                    platform="overall"
                    section="overall"
                    key={index}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS

  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        video_duration_type: videoDurationType,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/overall/hashtag-list/", config);

      setHashtagList(res?.data?.metrics ?? []);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  let topTrendingHashtagsList = (

    <Wrapper> <Loader loaderType="hashLoader" /></Wrapper>

  );

  if (isHashtagListLoaded && !isHashtagListLoading) {
    if (
      filteredHashtagList[0] === null ||
      NoDataAvailableChecker(filteredHashtagList)
    ) {
      topTrendingHashtagsList = (
        <Wrapper>
          <NoDataAvailableLoader chartType="hashChartType" />
        </Wrapper>
      );
    } else {
      topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
        <Wrapper key={index}>
          <ErrorBoundary chartType="hashChartType">
            <div className="badge_wrapper">
              <button
                type="button"
                className={
                  selectedHashtag === badge.hashtag
                    ? "button_badge active"
                    : "button_badge"
                }
                onClick={() => handleEdit(badge)}
              >
                {badge.hashtag}
              </button>
            </div>
          </ErrorBoundary>
        </Wrapper>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <Wrapper>
        <ServerErrorsLoader chartType="hashChartType" error={hashtagListerror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = <Wrapper> <Loader loaderType="hashLoader" /></Wrapper>;
  }
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video Length Analysis
  const fetchVideoLengthAnalysisHandler = async (hashtag) => {
    setVideoLengthAnalysisIsLoading(true);
    setVideoLengthAnalysisIsLoaded(false);
    setVideoLengthAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: hashtag ?? null,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/overall/platform-overall-length_analysis/",
        config
      );


      setVideoLengthAnalysis(res?.data?.data ?? []);
      setVideoLengthAnalysisLabel(res?.data?.label ?? []);
      setVideoLengthAnalysisIsLoading(false);
      setVideoLengthAnalysisIsLoaded(true);
      setVideoLengthAnalysisError(null);
    } catch (error) {
      setVideoLengthAnalysis([]);
      setVideoLengthAnalysisLabel([]);
      setVideoLengthAnalysisIsLoading(false);
      setVideoLengthAnalysisIsLoaded(false);
      setVideoLengthAnalysisError(error.code);
    }
  };

  let videoLengthAnalysisChart = (
    <Wrapper> <Loader loaderType="pieChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (isVideoLengthAnalysisLoaded && !isVideoLengthAnalysisLoading) {
    if (
      NoDataAvailableChecker(videoLengthAnalysis) ||
      NoDataAvailableChecker(videoLengthAnalysisLabel)
    ) {
      videoLengthAnalysisChart = (
        <Wrapper>  <NoDataAvailableLoader chartType="pieChartType" /></Wrapper>
      );
    } else {
      videoLengthAnalysisChart = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <PieofaPieChart
              chartClass="section_card_chart"
              chartData={videoLengthAnalysis}
              chartId="videoLengthAnalysisChart"
              chartLabels={videoLengthAnalysisLabel}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoLengthAnalysiserror) {
    videoLengthAnalysisChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="pieChartType"
          error={videoLengthAnalysiserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVideoLengthAnalysisLoading) {
    videoLengthAnalysisChart = <Wrapper> <Loader loaderType="pieChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIEW TREND ANALYSIS

  const fetchViewTrendAnalysisHandler = async (hashtag) => {
    setViewTrendAnalysisIsLoading(true);
    setViewTrendAnalysisIsLoaded(false);
    setViewTrendAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: hashtag ?? null,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/overall/platform-overall-trend_analysis/",
        config
      );

      setViewTrendAnalysis(res?.data?.data ?? []);
      setViewTrendAnalysisLabel(res?.data?.dimension ?? []);
      setViewTrendAnalysisIsLoading(false);
      setViewTrendAnalysisIsLoaded(true);
      setViewTrendAnalysisError(null);
    } catch (error) {
      setViewTrendAnalysis([]);
      setViewTrendAnalysisLabel([]);
      setViewTrendAnalysisIsLoading(false);
      setViewTrendAnalysisIsLoaded(false);
      setViewTrendAnalysisError(error.code);
    }
  };

  let viewTrendAnalysisSection = (
    <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (isViewTrendAnalysisLoaded && !isViewTrendAnalysisLoading) {
    if (
      NoDataAvailableChecker(viewTrendAnalysis) ||
      NoDataAvailableChecker(viewTrendAnalysisLabel)
    ) {
      viewTrendAnalysisSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      viewTrendAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartClass="section_card_chart"
              chartData={viewTrendAnalysis}
              chartId="viewTrendAnalysis"
              chartLabels={viewTrendAnalysisLabel}
              showLegend={true}
              showLabels={true}
              showBullets={true}
              onBulletsClick={bulletClicksHandler}
              tooltipWidth={"auto"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (viewTrendAnalysisError) {
    viewTrendAnalysisSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="lineChartType"
              error={viewTrendAnalysisError}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isViewTrendAnalysisLoading) {
    viewTrendAnalysisSection = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIEW TREND ANALYSIS DETAILS

  // TO FETCH DATA FROM API
  const fetchViewTrendAnalysisDetailsHandler = async (hashtag) => {
    setViewTrendAnalysisDetailsIsLoading(true);
    setViewTrendAnalysisDetailsIsLoaded(false);
    setViewTrendAnalysisDetailsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: topPostStartDate,
        end_date: topPostEndDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: hashtag ?? null,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/overall/platform-overall-trend-metric-card/",
        config
      );

      let postUrl = "";


      setViewTrendAnalysisDetails(res?.data?.metric ?? []);
      setViewTrendAnalysisTopPImgUrl(res?.data?.url ?? []);
      setViewTrendAnalysisDetailsIsLoading(false);
      setViewTrendAnalysisDetailsIsLoaded(true);
      setViewTrendAnalysisDetailsError(null);
    } catch (error) {
      setViewTrendAnalysisDetails([]);
      setViewTrendAnalysisTopPImgUrl([]);
      setViewTrendAnalysisDetailsIsLoading(false);
      setViewTrendAnalysisDetailsIsLoaded(false);
      setViewTrendAnalysisDetailsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let viewTrendAnalysisDetailsData = (
    <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>
  );
  let viewTrendAnalysisTopPostImg = (
    <Wrapper> <Loader loaderType="imageLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (isViewTrendAnalysisDetailsLoaded && !isViewTrendAnalysisDetailsLoading) {
    if (NoDataAvailableChecker(viewTrendAnalysisDetails)) {
      viewTrendAnalysisDetailsData = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
      viewTrendAnalysisTopPostImg = (
        <Wrapper>
          <NoDataAvailableLoader chartType="imageChartType" />
        </Wrapper>
      );
    } else {
      viewTrendAnalysisDetailsData = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <div className="chart_details_wrapper">
              <div className="main_wrapper">
                <div className="body">
                  {viewTrendAnalysisDetails && viewTrendAnalysisDetails.map(
                    (details, postImpressionIndex) => (
                      <Wrapper key={postImpressionIndex}>
                        <div className="row">
                          <div className="title_wrapper">
                            {details.label}
                          </div>
                          <div className="value_wrapper">
                            <ErrorBoundary>
                              <NumberFormatter number={details.value} />
                            </ErrorBoundary>
                          </div>
                        </div>
                      </Wrapper>
                    ))}
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );

      viewTrendAnalysisTopPostImg = (
        <Wrapper>
          <div className="img_wrapper">
            <img
              src={viewTrendAnalysisTopPImgUrl}
              alt="img"
              className="section_img"
              onError={(e) => (e.target.src = DefaultPostImage)}
            />
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (viewTrendAnalysisDetailserror) {
    viewTrendAnalysisDetailsData = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={viewTrendAnalysisDetailserror}
        />
      </Wrapper>
    );
    viewTrendAnalysisTopPostImg = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="imageChartType"
          error={viewTrendAnalysisDetailserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isViewTrendAnalysisDetailsLoading) {
    viewTrendAnalysisDetailsData = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>;
    viewTrendAnalysisTopPostImg = <Wrapper> <Loader loaderType="imageLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CHANNEL SUMMARY

  const fetchChannelSummaryHandler = async (hashtag) => {
    setChannelSummaryIsLoading(true);
    setChannelSummaryIsLoaded(false);
    setChannelSummaryError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        video_duration_type: videoDurationType,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: hashtag ?? null,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/overall/platform-overall-summary-table/",
        config
      );
      setChannelSummary(res?.data ?? []);
      setChannelSummaryIsLoading(false);
      setChannelSummaryIsLoaded(true);
      setChannelSummaryError(null);
    } catch (error) {
      setChannelSummary([]);
      setChannelSummaryIsLoading(false);
      setChannelSummaryIsLoaded(false);
      setChannelSummaryError(error.code);
    }
  };

  let channelSummaryTable = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Channel Summary Table
              <SectionDefinationsBadge
                title={"Channel Summary Table"}
                module="video"
                platform="overall"
                section="overall"
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED chartType="tableChartType"
  if (isChannelSummaryLoaded && !isChannelSummaryLoading) {
    if (NoDataAvailableChecker(channelSummary) || NoDataAvailableChecker(channelSummaryTableHeaders)) {
      channelSummaryTable = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">Channel Summary Table
                  <SectionDefinationsBadge
                    title={"Channel Summary Table"}
                    module="video"
                    platform="overall"
                    section="overall"
                  />
                </h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      channelSummaryTable = (
        <ErrorBoundary>
          <Wrapper>
            <Datatable
              tableTitle='Channel Summary Table'
              module="video"
              tablePageSubModule={"video"}
              tablePagePlatform="overall"
              tablePagePrimaryTab={"overall"}
              tableHeader={channelSummaryTableHeaders}
              tableData={channelSummary}
              tableLength={10}
              isFooterShow={true}
              tableDropdown={false}
              searchPlaceHolder={"Search Channel"}
              tableID={"channel_summary_table_section"}
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  //tab={pagePrimaryTab}
                  sectionId={"channel_summary_table_section"}
                  sectionName="Channel Summary Table"
                  sectionData={channelSummary}
                />
              }
            />
          </Wrapper>
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (channelSummaryerror) {
    channelSummaryTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">Channel Summary Table
                <SectionDefinationsBadge
                  title={"Channel Summary Table"}
                  module="video"
                  platform="overall"
                  section="overall"
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={channelSummaryerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isChannelSummaryLoading) {
    channelSummaryTable =
      (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">Channel Summary Table
                  <SectionDefinationsBadge
                    title={"Channel Summary Table"}
                    module="video"
                    platform="overall"
                    section="overall"
                  />
                </h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <Loader loaderType="smallTableLoader" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
  }

  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  // TOP POST
  const fetchTopPostsHandler = async (hashtag) => {
    settopPostsLoading(true);
    setTopPostsloaded(false);
    setTopPostsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        video_duration_type: videoDurationType,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: hashtag ?? null,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/overall/top-five-post/", config);

      setTopPosts(res?.data ?? []);
      settopPostsLoading(false);
      setTopPostsloaded(true);
      setTopPostsError(null);
    } catch (error) {
      setTopPosts([]);
      settopPostsLoading(false);
      setTopPostsloaded(false);
      setTopPostsError(error.code);
    }
  };

  // TOP TWEETS CHART
  let topPostsChart = (
    <Wrapper>
      <div className="section_card fullpage_section_card grid">
        <div className="grid col_span_4 section_card_header">

          <div className="section_info">
            <h2 className="section_card_title">Top 5 Videos</h2>
            <SectionDefinationsBadge
              title={"Top 5 Videos"}
              module="video"
              platform="overall"
              section="overall"
            />

          </div>
        </div>
        <div className="grid col_span_4">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" variantType="compare" />
      </div>
    </Wrapper>
  );


  // IF DATA IS LOADED
  if (topPostsLoaded && !topPostsLoading) {
    if (NoDataAvailableChecker(topPosts)) {
      topPostsChart = (
        <Wrapper>
          <div className="section_card fullpage_section_card grid">
            <div className="grid col_span_4 section_card_header">

              <div className="section_info">
                <h2 className="section_card_title">Top 5 Videos</h2>
                <SectionDefinationsBadge
                  title={"Top 5 Videos"}
                  module="video"
                  platform="overall"
                  section="overall"
                />

              </div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </Wrapper>

      );
    } else {
      topPostsChart = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <TopProductCompare
              module="video"
              social_platform="overall"
              Title={"Videos"}
              products={topPosts}
              isPaid={false}
              module_section="overall"
              hasExportDropdown={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topPostserror) {
    topPostsChart = (
      <Wrapper>
        <div className="section_card fullpage_section_card grid">
          <div className="grid col_span_4 section_card_header">

            <div className="section_info">
              <h2 className="section_card_title">Top 5 Videos</h2>
              <SectionDefinationsBadge
                title={"Top 5 Videos"}
                module="video"
                platform="overall"
                section="overall"
              />

            </div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader chartType="cardChartType" error={topPostserror} />
          </div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader chartType="cardChartType" error={topPostserror} />
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (topPostsLoading) {
    topPostsChart = (
      <Wrapper>
        <div className="section_card fullpage_section_card grid">
          <div className="grid col_span_4 section_card_header">

            <div className="section_info">
              <h2 className="section_card_title">Top 5 Videos</h2>
              <SectionDefinationsBadge
                title={"Top 5 Videos"}
                module="video"
                platform="overall"
                section="overall"
              />
            </div>
          </div>

          <div className="grid col_span_4">
            <Loader loaderType="cardLoader" variantType="compare" />
          </div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </Wrapper>
    );
  }

  var viewTrendAnalysisCSV = {
    "View Trend Analysis": viewTrendAnalysis,
    "View Trend Analysis Details": viewTrendAnalysisDetails,
  };

  var allData = companyID === COKE_COMPANY_ID ? {
    ...topCardData,
    "VideoSummaryCard_Summary Cards": metricCards,
    "PieofPie_Video Length Analysis": videoLengthAnalysis,
    ...viewTrendAnalysisCSV,
    "Channel Summary Table": channelSummary,
    "Compare_Top 5 Videos": topPosts,
  } : {
    ...topCardData,
    "VideoSummaryCard_Summary Cards": metricCards,
    "PieofPie_Video Length Analysis": videoLengthAnalysis,
    ...viewTrendAnalysisCSV,
    "Compare_Top 5 Videos": topPosts,
  }

  useEffect(() => {
    setFullPageExport(allData);
    setExportPageName("Video Overall  ");
  }, [
    exportSummary,
    metricCards,
    videoLengthAnalysis,
    viewTrendAnalysis,
    viewTrendAnalysisDetails,
    channelSummary,
    topPosts,
  ]);


  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (videoLengthAnalysiserror !== null ? true : isVideoLengthAnalysisLoaded && (isVideoLengthAnalysisLoading === false)) &&
      (viewTrendAnalysisError !== null ? true : isViewTrendAnalysisLoaded && (isViewTrendAnalysisLoading === false)) &&
      (channelSummaryerror !== null ? true : isChannelSummaryLoaded && (isChannelSummaryLoading === false)) &&
      (topPostserror !== null ? true : topPostsLoaded && (topPostsLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    videoLengthAnalysiserror, isVideoLengthAnalysisLoaded, isVideoLengthAnalysisLoading,
    viewTrendAnalysisError, isViewTrendAnalysisLoaded, isViewTrendAnalysisLoading,
    channelSummaryerror, isChannelSummaryLoaded, isChannelSummaryLoading,
    topPostserror, topPostsLoaded, topPostsLoading,
  ])


  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <div className="section_info">   <h2 className="section_card_title">Trending Hashtags
          </h2> <SectionDefinationsBadge
              title={"Trending Hashtags"}
              module="video"
              platform="overall"
              section="overall"
            />
          </div>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topTrendingHashtagsList}</div>
        </div>
      </div>
      <Wrapper>
        <div className="grid grid_margin_bottom">
          <div className="card_wrapper">{metricCardsSection}</div>
        </div>
      </Wrapper>

      <div
        id="video_length"
        className="section_card fullpage_section_card grid grid_margin_bottom"
      >
        <div className="section_card_header">
          <div className="section_info">  <h2 className="section_card_title">
            Video Length Analysis {selectedHashtagSectionHeader}
          </h2>  <SectionDefinationsBadge
              title={"Video Length Analysis"}
              module="video"
              platform="overall"
              section="overall"
            />
          </div>
          <div data-html2canvas-ignore={true}>
            <ExportDropdown
              moduleName={pageModule}
              subModuleName={pageSubModule}
              platform={pagePlatform}
              //tab={pagePrimaryTab}
              sectionId={"video_length"}
              sectionName={"PieofPie_Video Length Analysis"}
              sectionData={videoLengthAnalysis}
            />
          </div>
        </div>
        <div className="section_card_body">
          <div className="section_card_chart_wrapper p_t_0 p_b_0">
            {videoLengthAnalysisChart}
          </div>
        </div>
      </div>

      <div
        id="video_trend_analysis_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Video Trend Analysis {selectedHashtagSectionHeader}
            </h2> <SectionDefinationsBadge
                title={"Video Trend Analysis"}
                module="video"
                platform="overall"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                //tab={pagePrimaryTab}
                sectionId={"video_trend_analysis_section"}
                sectionName={"Video Trend Analysis"}
                sectionData={viewTrendAnalysisCSV}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_2 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {viewTrendAnalysisSection}
              </div>
            </div>
          </div>
          <div className="grid col_span_2">
            <div className="grid col_span_1 section_card_body flex column">
              <div className="grid">
                <div className="date_badge_section">
                  <div className="date_badge_wrapper">
                    <div className="date_badge_title">
                      Top post from:{" "}
                      {topPostSelectedDate === "" &&
                        `${selectedDates.startDate} to ${selectedDates.endDate}`}

                    </div>
                    {topPostSelectedDate !== "" && (
                      <div className="badge">
                        {topPostSelectedDate}
                        <div
                          onClick={() => setTopPostSelectedDate("")}
                          className="close_icon"
                        ></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid col_span_1">
                <div className="col_span_2 section_card_img_wrapper">
                  {viewTrendAnalysisTopPostImg}
                </div>
                <div className="col_span_2">
                  <div className="grid col_span_2">
                    <div className="table_wrapper section_card_table_wrapper">
                      {viewTrendAnalysisDetailsData}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {companyID === COKE_COMPANY_ID &&
        <div className="grid_margin_bottom">
          {channelSummaryTable}
        </div>
      }
      {topPostsChart}
    </Wrapper>
  );
}
