import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import { BlueColorStart, BlueColorStart500, PurpleColorStart500 } from "../../../constants/constants";
import { useSelector } from "react-redux";
import { CountryCode } from "../../../constants/countryCurrency";
import { tooltipNumberFormatting } from "../../../utils/Utils";

am5.addLicense("AM5C258896422");

const LayeredColumnChart = ({
	chartId,
	chartClass,
	showLegend,
	showLabels,
	chartData,
	chartLabels,
	showVerticalLabels,
	showHalfVerticalLabels,
	showShortVerticalLabels,
	chartColorSet,
	minimumGridDistance,
}) => {
	const userData = useSelector((state) => state.userData.user[0]);
	let userCountryCode = userData?.country_code ?? "IN"

	let country = {}
	CountryCode.map((data) => {
		if (data.countryCode === userCountryCode) {
			country = data
		}
	})


	useLayoutEffect(() => {
		var root = am5.Root.new(chartId);

		let widthSet = []

		chartLabels.map((data, dataIndex) => {
			widthSet.push((chartLabels.length - dataIndex) * 10)
		})

		// TO CHANGE COLOR OF THE CHART
		if (chartColorSet === undefined) {
			var colorSet = BlueColorStart500
		} else {
			var colorSet = chartColorSet
		}

		if (showLegend === undefined) {
			showLegend = true;
		}

		// Set themes
		// https://www.amcharts.com/docs/v5/concepts/themes/
		root.setThemes([
			am5themes_Animated.new(root)
		]);

		root.interfaceColors.set("grid", am5.color(0xffffff)); // to chang the color of grid lines

		// Create chart
		// https://www.amcharts.com/docs/v5/charts/xy-chart/
		var chart = root.container.children.push(am5xy.XYChart.new(root, {
			panX: false,
			panY: false,
			layout: root.verticalLayout,
			paddingRight: 0,
			paddingTop: 5,
			paddingBottom: 0,
			paddingLeft: 0,
			maxTooltipDistance: -1,
		}));

		// Add cursor
		// https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
		var cursor = chart.set(
			"cursor",
			am5xy.XYCursor.new(root, {
				behavior: "zoomXY",
			})
		);
		cursor.lineY.set("visible", false);
		cursor.lineX.set("visible", false);

		var data = chartData;

		// Create axes
		// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
		var xRenderer = am5xy.AxisRendererX.new(root, {
			minGridDistance: 70,
			stroke: am5.color(0x757575),
			strokeWidth: 0.3,
			strokeOpacity: 1,
		});

		var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
			categoryField: "label",

			// to start and end the line from the start and end
			startLocation: 0.2,
			endLocation: 0.8,

			renderer: xRenderer,
		}));

		xAxis.get("renderer").labels.template.setAll({
			oversizedBehavior: "truncate", // options: "none"/"wrap"/"fit"
			maxWidth: 150,
			textAlign: "center",
			fontSize: 12,
			fontFamily: "Ubuntu",
			fill: "#757575",
			fontWeight: "400"
		});

		xAxis.data.setAll(data);

		var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
			// logarithmic: true,
			treatZeroAs: 0.000001,
			extraMax: 0.1,
			maxPrecision: 0,
			// tooltipNumberFormat: "#.00",
			renderer: am5xy.AxisRendererY.new(root, {
				stroke: am5.color(0x757575),
				strokeWidth: 0.3,
				strokeOpacity: 1,
			}),
			min: 0,
			extraMax: 0.04,
			strictMinMaxSelection: true,

		}));

		yAxis.get("renderer").labels.template.setAll({
			oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
			maxWidth: 150,
			textAlign: "center",
			fontSize: 12,
			fontFamily: "Ubuntu",
			fill: "#757575",
			fontWeight: "400",
		});

		// Add legend
		var legend = chart.children.push(
			am5.Legend.new(root, {
				// background: am5.Rectangle.new(root, {
				//     fill: am5.color(0xff5599),
				//     fillOpacity: 0.2
				// }), // to add the backgrounD to the legends
				position: "relative", // options: "absolute" / "relative"
				// width: am5.percent(100),
				// height: am5.percent(20),
				x: am5.percent(50),
				centerX: am5.percent(50),
				height: chartLabels.length >= 6 ? am5.percent(30) : null,
				marginTop: 15,
				// marginBottom: 15,
				useDefaultMarker: true,
				layout: root.gridLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
				layout: am5.GridLayout.new(root, {
					maxColumns: 10,
					fixedWidthGrid: true,
				}),
				verticalScrollbar: am5.Scrollbar.new(root, {
					orientation: "vertical",
				}),
			})
		);

		legend.markers.template.setAll({
			width: 13,
			height: 13,
		});

		legend.markerRectangles.template.setAll({
			cornerRadiusTL: 10,
			cornerRadiusTR: 10,
			cornerRadiusBL: 10,
			cornerRadiusBR: 10,
		});

		legend.labels.template.setAll({
			fontSize: 12,
			fontWeight: "400",
			fill: "#213345",
		});

		legend.labels.template.setAll({
			// maxWidth: 150,
			//centerY: 0, // if we want labels to be top-aligned
			oversizedBehavior: "wrap",
		});

		// Add series
		function createSeries(name, field, width, index) {
			var series = chart.series.push(am5xy.ColumnSeries.new(root, {
				name: name,
				xAxis: xAxis,
				yAxis: yAxis,
				valueYField: field,
				categoryXField: "label",
				clustered: false,
				fill: am5.color(colorSet[index]),
				stroke: am5.color(colorSet[index]),
			}));

			series.columns.template.setAll({
				width: am5.percent(widthSet[index]),
				tooltipY: 0,
				strokeOpacity: 0,
				cornerRadiusTL: 7,
				cornerRadiusTR: 7,
			});

			var tooltip = series.set(
				"tooltip",
				am5.Tooltip.new(root, {
					getFillFromSprite: false,
					getStrokeFromSprite: false,
					autoTextColor: false,
					pointerOrientation: "horizontal",
					position: "pointer",
				})
			);

			tooltip.get("background").setAll({
				fill: am5.color(0x213345),
				// maxWidth: 200,
				oversizedBehavior: "truncate",
				cornerRadius: 5,
			});

			tooltip.label.setAll({
				text: "[bold]{categoryX}[/]",
				fill: am5.color(0xffffff),
				fontSize: 13,
			});

			tooltip.label.adapters.add("text", function (text, target) {
				chart.series.each(function (series) {

					var tooltipDataItem = series?.get("tooltipDataItem");
					text += "\n[" + series.get("stroke").toString() + "]●[/] " + "[bold]" + series.get("name") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("name"), tooltipDataItem?.get("valueY"), country) + "[/]";

				});
				return text;
			});


			series.data.setAll(data);
		}



		chartLabels.map((label, index) => {
			createSeries(
				label.label,
				label.value,
				label.width,
				index
			);
		});


		// Make stuff animate on load
		chart.zoomOutButton.set("forceHidden", true);
		chart.appear(1000, 100);
		// series0.appear();
		// series1.appear();

		return () => {
			root.dispose();
		};
	}, [chartData, chartLabels]);

	return (
		<Wrapper>
			<div id={chartId} className={chartClass}></div>
		</Wrapper>
	);
};

LayeredColumnChart.propTypes = {
	chartId: PropTypes.string,
	chartClass: PropTypes.string,
	showLegend: PropTypes.bool,
	showLabels: PropTypes.bool,
	showVerticalLabels: PropTypes.bool,
	showHalfVerticalLabels: PropTypes.bool,
	chartData: PropTypes.array,
	chartLabels: PropTypes.array,
	showShortVerticalLabels: PropTypes.bool,
	chartColorSet: PropTypes.array,
	minimumGridDistance: PropTypes.any
};

export default LayeredColumnChart;
