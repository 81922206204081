const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  adminCreateBucketCampaignObjectivesFilter: [],
  adminBucketPlatformandObjective: [],

};

const campaignAdminSlice = createSlice({
  name: "campaignAdmin",
  initialState,
  reducers: {
    setAdminCreateBucketCampaignObjectivesFilter(state, action) {
      state.adminCreateBucketCampaignObjectivesFilter = action.payload;
    },
    setAdminBucketPlatformandObjective(state, action) {
      state.adminBucketPlatformandObjective = action.payload;
    },
    resetcampaignAdmin: () => initialState,
  },
});

export const {
  setAdminCreateBucketCampaignObjectivesFilter,
  setAdminBucketPlatformandObjective,
  resetcampaignAdmin,
} = campaignAdminSlice.actions;

export default campaignAdminSlice.reducer;
