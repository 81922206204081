export const CountryCurrency = [
  {
    "id": "c3894ea0-762e-4639-94d4-0dc5cfb55cba",
    "country_name": "Aruba",
    "country_code": "ABW",
    "numeric_code": 533,
    "currencySymbol": "ƒ"
  },
  {
    "id": "f0ced60c-693a-4fd3-8e46-53f6c1f3428f",
    "country_name": "Afghanistan",
    "country_code": "AFG",
    "numeric_code": 4,
    "currencySymbol": "؋"
  },
  {
    "id": "fd50f4a0-e235-42e5-9347-956eb4c19db0",
    "country_name": "Angola",
    "country_code": "AGO",
    "numeric_code": 24,
    "currencySymbol": "$"
  },
  {
    "id": "3a9c7e4c-4d63-4c49-933c-5b06553cafa4",
    "country_name": "Anguilla",
    "country_code": "AIA",
    "numeric_code": 660,
    "currencySymbol": "$"
  },
  {
    "id": "1227d668-480e-45f8-a59f-72453c7cde38",
    "country_name": "Åland Islands",
    "country_code": "ALA",
    "numeric_code": 248,
    "currencySymbol": "$"
  },
  {
    "id": "2ff2d91c-0b86-4aa1-b7f7-8d41d29f31a6",
    "country_name": "Albania",
    "country_code": "ALB",
    "numeric_code": 8,
    "currencySymbol": "Lek"
  },
  {
    "id": "3eec1f20-2fa4-418c-a69d-9bc7cab5e8cb",
    "country_name": "Andorra",
    "country_code": "AND",
    "numeric_code": 20,
    "currencySymbol": "$"
  },
  {
    "id": "52240fa5-d960-4b20-bf82-97e149930667",
    "country_name": "United Arab Emirates",
    "country_code": "ARE",
    "numeric_code": 784,
    "currencySymbol": "د.إ"
  },
  {
    "id": "96d2edf2-04c2-4608-bc27-bf6088d08bef",
    "country_name": "Argentina",
    "country_code": "ARG",
    "numeric_code": 32,
    "currencySymbol": "$"
  },
  {
    "id": "5ece2d14-f0f8-47a2-8e6e-4ae1aee0c231",
    "country_name": "Armenia",
    "country_code": "ARM",
    "numeric_code": 51,
    "currencySymbol": "$"
  },
  {
    "id": "a1eff4f3-a3c3-458f-959a-efa16793aa5d",
    "country_name": "American Samoa",
    "country_code": "ASM",
    "numeric_code": 16,
    "currencySymbol": "$"
  },
  {
    "id": "8d1b2094-29c8-4e12-b2a9-86e9504a83cb",
    "country_name": "Antarctica",
    "country_code": "ATA",
    "numeric_code": 10,
    "currencySymbol": "$"
  },
  {
    "id": "0b4de015-491d-4d24-acf9-535703748028",
    "country_name": "French Southern Territories",
    "country_code": "ATF",
    "numeric_code": 260,
    "currencySymbol": "$"
  },
  {
    "id": "e43f5185-9bae-48ab-a8d6-51db66956bd7",
    "country_name": "Antigua and Barbuda",
    "country_code": "ATG",
    "numeric_code": 28,
    "currencySymbol": "$"
  },
  {
    "id": "d2b6c027-6d54-4d30-ab81-9755d41485bf",
    "country_name": "Australia",
    "country_code": "AUS",
    "numeric_code": 36,
    "currencySymbol": "$"
  },
  {
    "id": "e4182fea-a72b-444a-a8a5-8d9a7d4841bd",
    "country_name": "Austria",
    "country_code": "AUT",
    "numeric_code": 40,
    "currencySymbol": "$"
  },
  {
    "id": "0222c4b8-ae52-4d07-b9a4-c96928858f31",
    "country_name": "Azerbaijan",
    "country_code": "AZE",
    "numeric_code": 31,
    "currencySymbol": "₼"
  },
  {
    "id": "cc226e20-44f0-47ec-a6c1-ee15eba99613",
    "country_name": "Burundi",
    "country_code": "BDI",
    "numeric_code": 108,
    "currencySymbol": "$"
  },
  {
    "id": "a48436c6-199e-4724-a009-2e29795a2ad3",
    "country_name": "Belgium",
    "country_code": "BEL",
    "numeric_code": 56,
    "currencySymbol": "$"
  },
  {
    "id": "d3949a71-e8a9-47f7-a03e-d32a5baa4b3f",
    "country_name": "Benin",
    "country_code": "BEN",
    "numeric_code": 204,
    "currencySymbol": "$"
  },
  {
    "id": "394e7871-53ab-4d6c-a69d-a7a84f7f19a8",
    "country_name": "Bonaire, Sint Eustatius and Saba",
    "country_code": "BES",
    "numeric_code": 535,
    "currencySymbol": "$"
  },
  {
    "id": "391de829-1f50-41fb-a09f-b0d258d5daca",
    "country_name": "Burkina Faso",
    "country_code": "BFA",
    "numeric_code": 854,
    "currencySymbol": "$"
  },
  {
    "id": "e0922844-2ba7-47e9-9ebf-4360c0967456",
    "country_name": "Bangladesh",
    "country_code": "BGD",
    "numeric_code": 50,
    "currencySymbol": "$"
  },
  {
    "id": "b6e01ebd-a2c8-4d73-ad51-4fb872a703e2",
    "country_name": "Bulgaria",
    "country_code": "BGR",
    "numeric_code": 100,
    "currencySymbol": "лв"
  },
  {
    "id": "7080082c-eb51-4715-a995-cb2efb11728d",
    "country_name": "Bahrain",
    "country_code": "BHR",
    "numeric_code": 48,
    "currencySymbol": "$"
  },
  {
    "id": "78a9d96b-d42b-4df5-9a41-ef6f90a97387",
    "country_name": "Bahamas",
    "country_code": "BHS",
    "numeric_code": 44,
    "currencySymbol": "$"
  },
  {
    "id": "20de2394-f932-49e3-94e3-93cd9e104427",
    "country_name": "Bosnia and Herzegovina",
    "country_code": "BIH",
    "numeric_code": 70,
    "currencySymbol": "KM"
  },
  {
    "id": "6f608ae4-3004-4093-b044-c41c94776f7d",
    "country_name": "Saint Barthélemy",
    "country_code": "BLM",
    "numeric_code": 652,
    "currencySymbol": "$"
  },
  {
    "id": "c8416118-1390-490e-b3bb-a3e72fb3168a",
    "country_name": "Belarus",
    "country_code": "BLR",
    "numeric_code": 112,
    "currencySymbol": "Br"
  },
  {
    "id": "293a0252-35c6-4da0-9d76-9883b9b1af15",
    "country_name": "Belize",
    "country_code": "BLZ",
    "numeric_code": 84,
    "currencySymbol": "BZ$"
  },
  {
    "id": "1681521c-f333-4275-ab9e-04a09ca4404f",
    "country_name": "Bermuda",
    "country_code": "BMU",
    "numeric_code": 60,
    "currencySymbol": "$"
  },
  {
    "id": "8e609b2b-a571-497e-afa9-2a6ec03609bc",
    "country_name": "Bolivia, Plurinational State of",
    "country_code": "BOL",
    "numeric_code": 68,
    "currencySymbol": "$b"
  },
  {
    "id": "ecacfe24-e515-4287-acf9-96368f04b6cc",
    "country_name": "Brazil",
    "country_code": "BRA",
    "numeric_code": 76,
    "currencySymbol": "R$"
  },
  {
    "id": "b13f950a-b9fe-4cb0-a64a-0bdab513893d",
    "country_name": "Barbados",
    "country_code": "BRB",
    "numeric_code": 52,
    "currencySymbol": "$"
  },
  {
    "id": "239375d0-18ea-4d20-bab0-1fcbae83462a",
    "country_name": "Brunei Darussalam",
    "country_code": "BRN",
    "numeric_code": 96,
    "currencySymbol": "$"
  },
  {
    "id": "f84fe9b0-ea72-44a9-9153-d0e7fc90238a",
    "country_name": "Bhutan",
    "country_code": "BTN",
    "numeric_code": 64,
    "currencySymbol": "$"
  },
  {
    "id": "08150803-028c-421a-9df7-8e7161682b78",
    "country_name": "Bouvet Island",
    "country_code": "BVT",
    "numeric_code": 74,
    "currencySymbol": "$"
  },
  {
    "id": "b31fb2b9-e9e4-4d45-8ac9-147d357b55c1",
    "country_name": "Botswana",
    "country_code": "BWA",
    "numeric_code": 72,
    "currencySymbol": "P"
  },
  {
    "id": "c4e34f51-4801-4dc1-b400-f314924e8407",
    "country_name": "Central African Republic",
    "country_code": "CAF",
    "numeric_code": 140,
    "currencySymbol": "$"
  },
  {
    "id": "0bb15934-2ad6-4f63-a298-9b3d32ed3d8d",
    "country_name": "Canada",
    "country_code": "CAN",
    "numeric_code": 124,
    "currencySymbol": "$"
  },
  {
    "id": "c336215d-6282-4d4a-95d0-7d9ea7e7c7f5",
    "country_name": "Cocos (Keeling) Islands",
    "country_code": "CCK",
    "numeric_code": 166,
    "currencySymbol": "$"
  },
  {
    "id": "c5564645-cf36-4d85-97ac-aa05d8a9d054",
    "country_name": "Switzerland",
    "country_code": "CHE",
    "numeric_code": 756,
    "currencySymbol": "CHF"
  },
  {
    "id": "41e311e4-9815-45c0-b41d-3d49eebf4ac8",
    "country_name": "Chile",
    "country_code": "CHL",
    "numeric_code": 152,
    "currencySymbol": "$"
  },
  {
    "id": "da92b29a-e84b-4ff5-9ac6-778c3979f07d",
    "country_name": "China",
    "country_code": "CHN",
    "numeric_code": 156,
    "currencySymbol": "¥"
  },
  {
    "id": "2c02d53a-b4ab-4f38-9718-f36d31d0d0eb",
    "country_name": "Côte d'Ivoire",
    "country_code": "CIV",
    "numeric_code": 384,
    "currencySymbol": "$"
  },
  {
    "id": "87d81969-355e-4437-907c-1e25e3eaa70f",
    "country_name": "Cameroon",
    "country_code": "CMR",
    "numeric_code": 120,
    "currencySymbol": "$"
  },
  {
    "id": "af0248d2-fa83-4111-95bf-a84032143e0a",
    "country_name": "Congo, The Democratic Republic of the",
    "country_code": "COD",
    "numeric_code": 180,
    "currencySymbol": "$"
  },
  {
    "id": "bcd8c905-97e8-4763-acd5-552e61b09fdb",
    "country_name": "Congo",
    "country_code": "COG",
    "numeric_code": 178,
    "currencySymbol": "$"
  },
  {
    "id": "d10f4b28-91c1-4052-a7d1-0ea3e74b31ac",
    "country_name": "Cook Islands",
    "country_code": "COK",
    "numeric_code": 184,
    "currencySymbol": "$"
  },
  {
    "id": "a4767825-f2d1-496d-b479-7c3d181c44a0",
    "country_name": "Colombia",
    "country_code": "COL",
    "numeric_code": 170,
    "currencySymbol": "$"
  },
  {
    "id": "b033feeb-f149-4f10-8431-af9f756ff465",
    "country_name": "Comoros",
    "country_code": "COM",
    "numeric_code": 174,
    "currencySymbol": "$"
  },
  {
    "id": "e3c635b7-9f8a-449a-937d-8d7de5c0c6bf",
    "country_name": "Cabo Verde",
    "country_code": "CPV",
    "numeric_code": 132,
    "currencySymbol": "$"
  },
  {
    "id": "4873f4fd-8bc8-4cd7-a748-e585e32ad8b2",
    "country_name": "Costa Rica",
    "country_code": "CRI",
    "numeric_code": 188,
    "currencySymbol": "₡"
  },
  {
    "id": "8964abef-98f8-40d5-987e-52df3326282e",
    "country_name": "Cuba",
    "country_code": "CUB",
    "numeric_code": 192,
    "currencySymbol": "₱"
  },
  {
    "id": "72931663-fe49-4451-8b7e-f024d927d5ed",
    "country_name": "Curaçao",
    "country_code": "CUW",
    "numeric_code": 531,
    "currencySymbol": "$"
  },
  {
    "id": "674c6db0-8633-443f-850a-1a3cf74ad374",
    "country_name": "Christmas Island",
    "country_code": "CXR",
    "numeric_code": 162,
    "currencySymbol": "$"
  },
  {
    "id": "582462d2-8d9f-498c-883b-b539d0828ce2",
    "country_name": "Cayman Islands",
    "country_code": "CYM",
    "numeric_code": 136,
    "currencySymbol": "$"
  },
  {
    "id": "d45e7fce-04dc-4eb3-a443-2261f5b6854c",
    "country_name": "Cyprus",
    "country_code": "CYP",
    "numeric_code": 196,
    "currencySymbol": "$"
  },
  {
    "id": "88c2215c-052a-4c3b-9027-934fbe8796c1",
    "country_name": "Czechia",
    "country_code": "CZE",
    "numeric_code": 203,
    "currencySymbol": "$"
  },
  {
    "id": "b18d4d16-1b64-48b8-88bf-4f2e9d081479",
    "country_name": "Germany",
    "country_code": "DEU",
    "numeric_code": 276,
    "currencySymbol": "$"
  },
  {
    "id": "09600938-a80e-4cc1-b652-1a041f932efb",
    "country_name": "Djibouti",
    "country_code": "DJI",
    "numeric_code": 262,
    "currencySymbol": "$"
  },
  {
    "id": "a50f34de-b09b-4fc2-ac82-fc47fe3b13e9",
    "country_name": "Dominica",
    "country_code": "DMA",
    "numeric_code": 212,
    "currencySymbol": "$"
  },
  {
    "id": "dbe978d0-a560-483c-9b8e-9cc0f3271d89",
    "country_name": "Denmark",
    "country_code": "DNK",
    "numeric_code": 208,
    "currencySymbol": "kr"
  },
  {
    "id": "2ec3e9f6-8c24-4de8-bddf-ed612806eb37",
    "country_name": "Dominican Republic",
    "country_code": "DOM",
    "numeric_code": 214,
    "currencySymbol": "RD$"
  },
  {
    "id": "adec522b-640e-4f1f-8f7e-2ac29ccc3f64",
    "country_name": "Algeria",
    "country_code": "DZA",
    "numeric_code": 12,
    "currencySymbol": "$"
  },
  {
    "id": "8ead4997-4adc-414d-b211-57afb884c84f",
    "country_name": "Ecuador",
    "country_code": "ECU",
    "numeric_code": 218,
    "currencySymbol": "$"
  },
  {
    "id": "8a6dcf67-8482-48ca-8980-8283edb31b6c",
    "country_name": "Egypt",
    "country_code": "EGY",
    "numeric_code": 818,
    "currencySymbol": "£"
  },
  {
    "id": "31918c11-6858-4adc-92fb-adac2a24eae8",
    "country_name": "Eritrea",
    "country_code": "ERI",
    "numeric_code": 232,
    "currencySymbol": "$"
  },
  {
    "id": "5f2a2881-de13-475a-920e-06904f06c8d7",
    "country_name": "Western Sahara",
    "country_code": "ESH",
    "numeric_code": 732,
    "currencySymbol": "$"
  },
  {
    "id": "1d9562ae-792c-4a30-8d53-3195968dcfe5",
    "country_name": "Spain",
    "country_code": "ESP",
    "numeric_code": 724,
    "currencySymbol": "$"
  },
  {
    "id": "e83a58ac-082d-4acb-a073-fe0822ec1270",
    "country_name": "Estonia",
    "country_code": "EST",
    "numeric_code": 233,
    "currencySymbol": "$"
  },
  {
    "id": "ed06de21-17d2-4096-b618-1a79f402ad2d",
    "country_name": "Ethiopia",
    "country_code": "ETH",
    "numeric_code": 231,
    "currencySymbol": "$"
  },
  {
    "id": "a2411cb4-5155-48a5-8796-d20fd0bbfb8a",
    "country_name": "Finland",
    "country_code": "FIN",
    "numeric_code": 246,
    "currencySymbol": "$"
  },
  {
    "id": "42a7b19d-730f-413a-a357-a2c86b7e74be",
    "country_name": "Fiji",
    "country_code": "FJI",
    "numeric_code": 242,
    "currencySymbol": "$"
  },
  {
    "id": "59e3006d-7dc8-4591-a7ea-4acfa70a0013",
    "country_name": "Falkland Islands (Malvinas)",
    "country_code": "FLK",
    "numeric_code": 238,
    "currencySymbol": "£"
  },
  {
    "id": "ebedfd44-eb60-4084-98f6-fe2070626934",
    "country_name": "France",
    "country_code": "FRA",
    "numeric_code": 250,
    "currencySymbol": "$"
  },
  {
    "id": "3ad9ac73-39c5-4147-be9a-b4e65d2f2ef1",
    "country_name": "Faroe Islands",
    "country_code": "FRO",
    "numeric_code": 234,
    "currencySymbol": "$"
  },
  {
    "id": "b673440b-6c11-49e1-8f5f-0fd8a3a030a3",
    "country_name": "Micronesia, Federated States of",
    "country_code": "FSM",
    "numeric_code": 583,
    "currencySymbol": "$"
  },
  {
    "id": "8160b92a-a0c2-4b09-a8a7-785f4b5ee66d",
    "country_name": "Gabon",
    "country_code": "GAB",
    "numeric_code": 266,
    "currencySymbol": "$"
  },
  {
    "id": "a6269ad0-80b0-4208-a021-0b0f59cca1f6",
    "country_name": "United Kingdom",
    "country_code": "GBR",
    "numeric_code": 826,
    "currencySymbol": "£"
  },
  {
    "id": "f4a8fffe-ac23-42ba-bc26-d144f8c52ca6",
    "country_name": "Georgia",
    "country_code": "GEO",
    "numeric_code": 268,
    "currencySymbol": "$"
  },
  {
    "id": "a23218da-fa05-4728-86c7-cf51dd6f0156",
    "country_name": "Guernsey",
    "country_code": "GGY",
    "numeric_code": 831,
    "currencySymbol": "$"
  },
  {
    "id": "98544489-276a-4aad-9aa4-cabd12129acf",
    "country_name": "Ghana",
    "country_code": "GHA",
    "numeric_code": 288,
    "currencySymbol": "¢"
  },
  {
    "id": "7d80240b-3b55-4e70-8963-bd1f3f8954d3",
    "country_name": "Gibraltar",
    "country_code": "GIB",
    "numeric_code": 292,
    "currencySymbol": "£"
  },
  {
    "id": "6ac5b645-e7a2-4ef7-b1a0-cd124d009ba6",
    "country_name": "Guinea",
    "country_code": "GIN",
    "numeric_code": 324,
    "currencySymbol": "$"
  },
  {
    "id": "fb9201d2-049a-477c-acf1-81579711bf8f",
    "country_name": "Guadeloupe",
    "country_code": "GLP",
    "numeric_code": 312,
    "currencySymbol": "$"
  },
  {
    "id": "7b6b8589-acf3-45b9-94ff-ccdc283443cc",
    "country_name": "Gambia",
    "country_code": "GMB",
    "numeric_code": 270,
    "currencySymbol": "$"
  },
  {
    "id": "ef3fbd06-96c8-4128-b63c-cd548ffc6d26",
    "country_name": "Guinea-Bissau",
    "country_code": "GNB",
    "numeric_code": 624,
    "currencySymbol": "$"
  },
  {
    "id": "182f88d8-e7ed-472f-82be-e1b6d87e5363",
    "country_name": "Equatorial Guinea",
    "country_code": "GNQ",
    "numeric_code": 226,
    "currencySymbol": "$"
  },
  {
    "id": "9d3ef7ee-9ff1-4ce7-a595-b56b8e2af079",
    "country_name": "Greece",
    "country_code": "GRC",
    "numeric_code": 300,
    "currencySymbol": "$"
  },
  {
    "id": "41862735-42d3-49ba-8c1b-1d45fabcf338",
    "country_name": "Grenada",
    "country_code": "GRD",
    "numeric_code": 308,
    "currencySymbol": "$"
  },
  {
    "id": "a7cec1ad-fd3d-4de0-a684-6f8845686047",
    "country_name": "Greenland",
    "country_code": "GRL",
    "numeric_code": 304,
    "currencySymbol": "$"
  },
  {
    "id": "f25ae6fd-ae72-40e2-9b72-6f8f4cc3821e",
    "country_name": "Guatemala",
    "country_code": "GTM",
    "numeric_code": 320,
    "currencySymbol": "$"
  },
  {
    "id": "bd3b2523-b766-4c2d-a07b-33582ed93f37",
    "country_name": "French Guiana",
    "country_code": "GUF",
    "numeric_code": 254,
    "currencySymbol": "$"
  },
  {
    "id": "62627ae7-6f2f-4abc-aaef-51f9ab294fe1",
    "country_name": "Guam",
    "country_code": "GUM",
    "numeric_code": 316,
    "currencySymbol": "$"
  },
  {
    "id": "3d998dcc-c037-43ba-8ffd-b5a09a7501b3",
    "country_name": "Guyana",
    "country_code": "GUY",
    "numeric_code": 328,
    "currencySymbol": "$"
  },
  {
    "id": "8ab0f71f-1420-4c52-b5d6-f65b794a8c01",
    "country_name": "Hong Kong",
    "country_code": "HKG",
    "numeric_code": 344,
    "currencySymbol": "$"
  },
  {
    "id": "3cf62c01-8e9c-4d1c-a93e-27b904b99d0f",
    "country_name": "Heard Island and McDonald Islands",
    "country_code": "HMD",
    "numeric_code": 334,
    "currencySymbol": "$"
  },
  {
    "id": "c2908e41-a8ae-4298-b191-96e0ea91702d",
    "country_name": "Honduras",
    "country_code": "HND",
    "numeric_code": 340,
    "currencySymbol": "L"
  },
  {
    "id": "9881d014-fe33-4ba7-958d-380839336f92",
    "country_name": "Croatia",
    "country_code": "HRV",
    "numeric_code": 191,
    "currencySymbol": "kn"
  },
  {
    "id": "b80e3f83-df1b-4310-a6d3-afaad1c37508",
    "country_name": "Haiti",
    "country_code": "HTI",
    "numeric_code": 332,
    "currencySymbol": "$"
  },
  {
    "id": "a574a15e-6188-4a28-b31a-4ce85cc53efd",
    "country_name": "Hungary",
    "country_code": "HUN",
    "numeric_code": 348,
    "currencySymbol": "Ft"
  },
  {
    "id": "0c5d9ae2-2b74-46a0-9d40-adb9162becf0",
    "country_name": "Indonesia",
    "country_code": "IDN",
    "numeric_code": 360,
    "currencySymbol": "Rp"
  },
  {
    "id": "b021c46a-5262-4b70-a317-16964d1cd078",
    "country_name": "Isle of Man",
    "country_code": "IMN",
    "numeric_code": 833,
    "currencySymbol": "£"
  },
  {
    "id": "ecf4c9ea-7741-4219-af7d-33d68ba3fe4b",
    "country_name": "India",
    "country_code": "IND",
    "numeric_code": 356,
    "currencySymbol": "₹",
    "alternateCurrencySymbol": "$"
  },
  {
    "id": "52b457bb-f91f-44c9-9be4-259a64be125f",
    "country_name": "British Indian Ocean Territory",
    "country_code": "IOT",
    "numeric_code": 86,
    "currencySymbol": "$"
  },
  {
    "id": "b19b1548-1b31-4d1b-a916-9f8386332522",
    "country_name": "Ireland",
    "country_code": "IRL",
    "numeric_code": 372,
    "currencySymbol": "$"
  },
  {
    "id": "875db5fe-f921-4903-a846-ab445ee33ad0",
    "country_name": "Iran, Islamic Republic of",
    "country_code": "IRN",
    "numeric_code": 364,
    "currencySymbol": "﷼"
  },
  {
    "id": "2c5602d7-61ed-47a0-83b6-a11af1c09106",
    "country_name": "Iraq",
    "country_code": "IRQ",
    "numeric_code": 368,
    "currencySymbol": "$"
  },
  {
    "id": "b347554c-22e8-4877-a860-6e022a6741ea",
    "country_name": "Iceland",
    "country_code": "ISL",
    "numeric_code": 352,
    "currencySymbol": "kr"
  },
  {
    "id": "a1aa4623-baca-47c8-90b7-4181b3ef65dc",
    "country_name": "Israel",
    "country_code": "ISR",
    "numeric_code": 376,
    "currencySymbol": "₪"
  },
  {
    "id": "46ef0287-21cd-45ce-9542-4dec06035b32",
    "country_name": "Italy",
    "country_code": "ITA",
    "numeric_code": 380,
    "currencySymbol": "$"
  },
  {
    "id": "9dab6157-a628-41c9-8bc5-a5f00fa5e95b",
    "country_name": "Jamaica",
    "country_code": "JAM",
    "numeric_code": 388,
    "currencySymbol": "J$"
  },
  {
    "id": "79a9268e-fd42-4a77-960c-1a5e84f038dc",
    "country_name": "Jersey",
    "country_code": "JEY",
    "numeric_code": 832,
    "currencySymbol": "£"
  },
  {
    "id": "c0226a3b-31f7-4a68-a47a-c88d053520c9",
    "country_name": "Jordan",
    "country_code": "JOR",
    "numeric_code": 400,
    "currencySymbol": "$"
  },
  {
    "id": "c5b0c62c-3b1c-4724-8cc1-ccd8e833e981",
    "country_name": "Japan",
    "country_code": "JPN",
    "numeric_code": 392,
    "currencySymbol": "¥"
  },
  {
    "id": "14d05b55-6b0d-48cb-8472-ba8acb776296",
    "country_name": "Kazakhstan",
    "country_code": "KAZ",
    "numeric_code": 398,
    "currencySymbol": "лв"
  },
  {
    "id": "479a9366-a8ca-478a-968e-b69402ae983f",
    "country_name": "Kenya",
    "country_code": "KEN",
    "numeric_code": 404,
    "currencySymbol": "$"
  },
  {
    "id": "7551030f-4e38-49af-80a3-e0853dbedd37",
    "country_name": "Kyrgyzstan",
    "country_code": "KGZ",
    "numeric_code": 417,
    "currencySymbol": "лв"
  },
  {
    "id": "0145669c-f55c-44d7-8c0a-d00e74a21830",
    "country_name": "Cambodia",
    "country_code": "KHM",
    "numeric_code": 116,
    "currencySymbol": "៛"
  },
  {
    "id": "b5aa44cb-f400-432c-80fd-e2651cb55a37",
    "country_name": "Kiribati",
    "country_code": "KIR",
    "numeric_code": 296,
    "currencySymbol": "$"
  },
  {
    "id": "27f7f97a-7774-4b76-a4bd-25bd53fb4776",
    "country_name": "Saint Kitts and Nevis",
    "country_code": "KNA",
    "numeric_code": 659,
    "currencySymbol": "$"
  },
  {
    "id": "9f3fc9b3-4619-403f-8d3e-9db3629b6d07",
    "country_name": "Korea, Republic of",
    "country_code": "KOR",
    "numeric_code": 410,
    "currencySymbol": "₩"
  },
  {
    "id": "6f26000b-b110-48a4-b57d-c44f86d45870",
    "country_name": "Kuwait",
    "country_code": "KWT",
    "numeric_code": 414,
    "currencySymbol": "$"
  },
  {
    "id": "d8250365-0318-442a-bcfc-154769105682",
    "country_name": "Lao People's Democratic Republic",
    "country_code": "LAO",
    "numeric_code": 418,
    "currencySymbol": "$"
  },
  {
    "id": "1e2e676e-7bcb-46db-8153-770f18dc59c5",
    "country_name": "Lebanon",
    "country_code": "LBN",
    "numeric_code": 422,
    "currencySymbol": "£"
  },
  {
    "id": "514751b2-220a-4b36-bba7-073f178e33a7",
    "country_name": "Liberia",
    "country_code": "LBR",
    "numeric_code": 430,
    "currencySymbol": "$"
  },
  {
    "id": "51f9200d-8c64-4f19-8ee2-93958c178a88",
    "country_name": "Libya",
    "country_code": "LBY",
    "numeric_code": 434,
    "currencySymbol": "$"
  },
  {
    "id": "2cb4a645-f986-43d0-81af-15a4652a91ae",
    "country_name": "Saint Lucia",
    "country_code": "LCA",
    "numeric_code": 662,
    "currencySymbol": "$"
  },
  {
    "id": "68f2bcaa-8635-45a0-85d2-bbaeb5854adc",
    "country_name": "Liechtenstein",
    "country_code": "LIE",
    "numeric_code": 438,
    "currencySymbol": "$"
  },
  {
    "id": "401f32cc-3767-414c-a279-4726aa798993",
    "country_name": "Sri Lanka",
    "country_code": "LKA",
    "numeric_code": 144,
    "currencySymbol": "₨"
  },
  {
    "id": "35d65010-e288-4aa1-9063-ab237214985e",
    "country_name": "Lesotho",
    "country_code": "LSO",
    "numeric_code": 426,
    "currencySymbol": "$"
  },
  {
    "id": "8b21c4c4-701b-42a1-9383-d5d924ba63e7",
    "country_name": "Lithuania",
    "country_code": "LTU",
    "numeric_code": 440,
    "currencySymbol": "$"
  },
  {
    "id": "e6dfd8e5-6093-456a-8457-330166946691",
    "country_name": "Luxembourg",
    "country_code": "LUX",
    "numeric_code": 442,
    "currencySymbol": "$"
  },
  {
    "id": "a600d55e-fbbc-403b-a4de-50a6373bb091",
    "country_name": "Latvia",
    "country_code": "LVA",
    "numeric_code": 428,
    "currencySymbol": "$"
  },
  {
    "id": "d6c3ff47-379f-4501-9969-f36f77644979",
    "country_name": "Macao",
    "country_code": "MAC",
    "numeric_code": 446,
    "currencySymbol": "$"
  },
  {
    "id": "004cee1b-b6e3-47d1-ae60-f8d512c44ffb",
    "country_name": "Saint Martin (French part)",
    "country_code": "MAF",
    "numeric_code": 663,
    "currencySymbol": "$"
  },
  {
    "id": "e8bbeb2a-1e58-4f45-abd6-07d5a073f376",
    "country_name": "Morocco",
    "country_code": "MAR",
    "numeric_code": 504,
    "currencySymbol": "د.إ"
  },
  {
    "id": "e06cc0e8-ab3c-4771-8555-df6942a7c6e3",
    "country_name": "Monaco",
    "country_code": "MCO",
    "numeric_code": 492,
    "currencySymbol": "$"
  },
  {
    "id": "f0780dbb-d8e9-4707-8d73-8073a71ce95e",
    "country_name": "Moldova, Republic of",
    "country_code": "MDA",
    "numeric_code": 498,
    "currencySymbol": "$"
  },
  {
    "id": "fc4fc153-4847-46ee-a215-ce4c38d95a91",
    "country_name": "Madagascar",
    "country_code": "MDG",
    "numeric_code": 450,
    "currencySymbol": "$"
  },
  {
    "id": "68bfabd0-cf53-4987-88a8-35af6736e000",
    "country_name": "Maldives",
    "country_code": "MDV",
    "numeric_code": 462,
    "currencySymbol": "$"
  },
  {
    "id": "40cf37b9-e765-4d48-ad2c-3e7047fc1d65",
    "country_name": "Mexico",
    "country_code": "MEX",
    "numeric_code": 484,
    "currencySymbol": "$"
  },
  {
    "id": "86a2c651-13a3-4042-9724-c8e65a092da1",
    "country_name": "Marshall Islands",
    "country_code": "MHL",
    "numeric_code": 584,
    "currencySymbol": "$"
  },
  {
    "id": "7525f168-4829-4f73-991b-54b610c8959b",
    "country_name": "North Macedonia",
    "country_code": "MKD",
    "numeric_code": 807,
    "currencySymbol": "ден"
  },
  {
    "id": "31349dbc-d1b2-4b0b-97de-2d43e53e76bb",
    "country_name": "Mali",
    "country_code": "MLI",
    "numeric_code": 466,
    "currencySymbol": "$"
  },
  {
    "id": "9b164764-4ed5-4eb9-ad31-e1b97079ac95",
    "country_name": "Malta",
    "country_code": "MLT",
    "numeric_code": 470,
    "currencySymbol": "$"
  },
  {
    "id": "6e8bcc95-c373-419a-93d6-46bef51735ef",
    "country_name": "Myanmar",
    "country_code": "MMR",
    "numeric_code": 104,
    "currencySymbol": "$"
  },
  {
    "id": "ab6bd052-5bb7-417d-bd3e-2e6a64e221fb",
    "country_name": "Montenegro",
    "country_code": "MNE",
    "numeric_code": 499,
    "currencySymbol": "$"
  },
  {
    "id": "671c0c9e-a53d-413f-ab0f-a052a5d7139c",
    "country_name": "Mongolia",
    "country_code": "MNG",
    "numeric_code": 496,
    "currencySymbol": "₮"
  },
  {
    "id": "f39118f5-ce0b-45b1-95fe-87e15223a94d",
    "country_name": "Northern Mariana Islands",
    "country_code": "MNP",
    "numeric_code": 580,
    "currencySymbol": "$"
  },
  {
    "id": "7b290618-428e-414c-abe8-9916e7a0c4d4",
    "country_name": "Mozambique",
    "country_code": "MOZ",
    "numeric_code": 508,
    "currencySymbol": "MT"
  },
  {
    "id": "e219aa8b-1198-46c3-9d24-6f551ed74433",
    "country_name": "Mauritania",
    "country_code": "MRT",
    "numeric_code": 478,
    "currencySymbol": "$"
  },
  {
    "id": "e409064e-ad6f-4e30-87a4-66976d3367f7",
    "country_name": "Montserrat",
    "country_code": "MSR",
    "numeric_code": 500,
    "currencySymbol": "$"
  },
  {
    "id": "1ac2c720-9149-4e41-955a-a64ff817bb78",
    "country_name": "Martinique",
    "country_code": "MTQ",
    "numeric_code": 474,
    "currencySymbol": "$"
  },
  {
    "id": "3552aea0-9317-40c8-be60-4bcd891c46d6",
    "country_name": "Mauritius",
    "country_code": "MUS",
    "numeric_code": 480,
    "currencySymbol": "₨"
  },
  {
    "id": "4abde2e3-3dac-4b06-967f-835e1c7385f0",
    "country_name": "Malawi",
    "country_code": "MWI",
    "numeric_code": 454,
    "currencySymbol": "$"
  },
  {
    "id": "9804fb39-0c17-48d9-b6c4-57803a6d31a7",
    "country_name": "Malaysia",
    "country_code": "MYS",
    "numeric_code": 458,
    "currencySymbol": "RM"
  },
  {
    "id": "5c581cee-d3e2-4347-9595-04ba265dc9c1",
    "country_name": "Mayotte",
    "country_code": "MYT",
    "numeric_code": 175,
    "currencySymbol": "$"
  },
  {
    "id": "cb90f8ed-0b56-4f7a-bbad-13a731b1a1d4",
    "country_name": "Namibia",
    "country_code": "NAM",
    "numeric_code": 516,
    "currencySymbol": "$"
  },
  {
    "id": "0f1ee754-d91c-4077-84c2-6583f9c89598",
    "country_name": "New Caledonia",
    "country_code": "NCL",
    "numeric_code": 540,
    "currencySymbol": "$"
  },
  {
    "id": "95279c60-de73-4670-928d-289b398e7b1e",
    "country_name": "Niger",
    "country_code": "NER",
    "numeric_code": 562,
    "currencySymbol": "$"
  },
  {
    "id": "30ee177c-8b16-4a09-adc4-8ee14f97ae96",
    "country_name": "Norfolk Island",
    "country_code": "NFK",
    "numeric_code": 574,
    "currencySymbol": "$"
  },
  {
    "id": "41c0c81c-8405-4b98-b2de-2353adf98a7f",
    "country_name": "Nigeria",
    "country_code": "NGA",
    "numeric_code": 566,
    "currencySymbol": "₦"
  },
  {
    "id": "6578636a-1ffa-4b35-b95f-d80ed14350c1",
    "country_name": "Nicaragua",
    "country_code": "NIC",
    "numeric_code": 558,
    "currencySymbol": "C$"
  },
  {
    "id": "ad79d2eb-d34d-43ca-aebc-d7951f8fa00a",
    "country_name": "Niue",
    "country_code": "NIU",
    "numeric_code": 570,
    "currencySymbol": "$"
  },
  {
    "id": "46d8f6ea-68da-4a8c-9db6-166f927babcb",
    "country_name": "Netherlands",
    "country_code": "NLD",
    "numeric_code": 528,
    "currencySymbol": "ƒ"
  },
  {
    "id": "bc1ca55a-cc4c-46f4-907c-017e79b97616",
    "country_name": "Norway",
    "country_code": "NOR",
    "numeric_code": 578,
    "currencySymbol": "kr"
  },
  {
    "id": "8a512b22-2aa1-485b-ad96-e93927596f6e",
    "country_name": "Nepal",
    "country_code": "NPL",
    "numeric_code": 524,
    "currencySymbol": "₨"
  },
  {
    "id": "1f4aa12b-814f-4eb9-a5b2-f5ace94d7044",
    "country_name": "Nauru",
    "country_code": "NRU",
    "numeric_code": 520,
    "currencySymbol": "$"
  },
  {
    "id": "38f1b022-fd77-4507-b226-bed6303606ef",
    "country_name": "New Zealand",
    "country_code": "NZL",
    "numeric_code": 554,
    "currencySymbol": "$"
  },
  {
    "id": "bb05889f-ec93-46b0-a024-7c0142a941af",
    "country_name": "Oman",
    "country_code": "OMN",
    "numeric_code": 512,
    "currencySymbol": "﷼"
  },
  {
    "id": "4a8366ed-ebc8-4a9c-98dc-16b01fbacd68",
    "country_name": "Pakistan",
    "country_code": "PAK",
    "numeric_code": 586,
    "currencySymbol": "₨"
  },
  {
    "id": "8ea1e250-d646-4caf-a3ec-b9a745baedfb",
    "country_name": "Panama",
    "country_code": "PAN",
    "numeric_code": 591,
    "currencySymbol": "B/."
  },
  {
    "id": "47f41ebf-f85e-4db9-926d-ccea3bdd913e",
    "country_name": "Pitcairn",
    "country_code": "PCN",
    "numeric_code": 612,
    "currencySymbol": "$"
  },
  {
    "id": "79b7dec5-b603-4499-b3f0-40e6426b66f1",
    "country_name": "Peru",
    "country_code": "PER",
    "numeric_code": 604,
    "currencySymbol": "S/."
  },
  {
    "id": "d3591ee4-a914-4c9f-a573-1650616b2bd3",
    "country_name": "Philippines",
    "country_code": "PHL",
    "numeric_code": 608,
    "currencySymbol": "₱"
  },
  {
    "id": "c7576999-d051-4606-82df-ede7d1957e34",
    "country_name": "Palau",
    "country_code": "PLW",
    "numeric_code": 585,
    "currencySymbol": "$"
  },
  {
    "id": "96fc0cf4-c1d6-4ade-be8d-49d9e7ca2738",
    "country_name": "Papua New Guinea",
    "country_code": "PNG",
    "numeric_code": 598,
    "currencySymbol": "$"
  },
  {
    "id": "d8fd2c67-59ef-4d88-95e5-9782982a7a57",
    "country_name": "Poland",
    "country_code": "POL",
    "numeric_code": 616,
    "currencySymbol": "zł"
  },
  {
    "id": "ee48465d-ae06-4e1b-9d72-aec37e812b87",
    "country_name": "Puerto Rico",
    "country_code": "PRI",
    "numeric_code": 630,
    "currencySymbol": "$"
  },
  {
    "id": "01b19796-8657-4e25-9ec8-b8de90b24046",
    "country_name": "Korea, Democratic People's Republic of",
    "country_code": "PRK",
    "numeric_code": 408,
    "currencySymbol": "₩"
  },
  {
    "id": "c4411ff7-e28d-45ed-a137-18e123cb193b",
    "country_name": "Portugal",
    "country_code": "PRT",
    "numeric_code": 620,
    "currencySymbol": "$"
  },
  {
    "id": "7e38dccb-9478-462e-a881-0cbea27b8ab3",
    "country_name": "Paraguay",
    "country_code": "PRY",
    "numeric_code": 600,
    "currencySymbol": "Gs"
  },
  {
    "id": "eb31659b-1b30-4e03-afb7-42e4d6017c24",
    "country_name": "Palestine, State of",
    "country_code": "PSE",
    "numeric_code": 275,
    "currencySymbol": "$"
  },
  {
    "id": "b66dab9c-7402-49ce-8c38-7e025876810a",
    "country_name": "French Polynesia",
    "country_code": "PYF",
    "numeric_code": 258,
    "currencySymbol": "$"
  },
  {
    "id": "7761b23d-df84-400f-9754-94e240969ba6",
    "country_name": "Qatar",
    "country_code": "QAT",
    "numeric_code": 634,
    "currencySymbol": "﷼"
  },
  {
    "id": "4328afda-0c15-4854-8ce8-e2ef08283d17",
    "country_name": "Réunion",
    "country_code": "REU",
    "numeric_code": 638,
    "currencySymbol": "$"
  },
  {
    "id": "5b35619e-b60c-4817-89df-091e4b469544",
    "country_name": "Romania",
    "country_code": "ROU",
    "numeric_code": 642,
    "currencySymbol": "lei"
  },
  {
    "id": "9cafb40e-6c2a-4566-ae11-93ddea3f422a",
    "country_name": "Russian Federation",
    "country_code": "RUS",
    "numeric_code": 643,
    "currencySymbol": "₽"
  },
  {
    "id": "ca73d0aa-cc3c-453e-bde7-9273fe6b26a0",
    "country_name": "Rwanda",
    "country_code": "RWA",
    "numeric_code": 646,
    "currencySymbol": "$"
  },
  {
    "id": "106b255d-217c-4803-97a2-9fcb7b32a88d",
    "country_name": "Saudi Arabia",
    "country_code": "SAU",
    "numeric_code": 682,
    "currencySymbol": "﷼"
  },
  {
    "id": "4ce2437f-af80-4ea5-8044-d1c05ed4e484",
    "country_name": "Sudan",
    "country_code": "SDN",
    "numeric_code": 729,
    "currencySymbol": "$"
  },
  {
    "id": "990a6776-9764-4d5f-b520-c5c37279df79",
    "country_name": "Senegal",
    "country_code": "SEN",
    "numeric_code": 686,
    "currencySymbol": "$"
  },
  {
    "id": "5c72354b-7162-4ca2-a6ec-e43a1f02eb72",
    "country_name": "Singapore",
    "country_code": "SGP",
    "numeric_code": 702,
    "currencySymbol": "$"
  },
  {
    "id": "2b75412e-c38e-420c-9610-c3d9b552b66f",
    "country_name": "South Georgia and the South Sandwich Islands",
    "country_code": "SGS",
    "numeric_code": 239,
    "currencySymbol": "$"
  },
  {
    "id": "090fa6cd-1db3-4543-a5b8-d7691f9599a0",
    "country_name": "Saint Helena, Ascension and Tristan da Cunha",
    "country_code": "SHN",
    "numeric_code": 654,
    "currencySymbol": "£"
  },
  {
    "id": "f5f1ade8-4a5c-4494-a412-bef2b2219b42",
    "country_name": "Svalbard and Jan Mayen",
    "country_code": "SJM",
    "numeric_code": 744,
    "currencySymbol": "$"
  },
  {
    "id": "648e3eca-6e7e-4131-a7c7-0b96bb420b93",
    "country_name": "Solomon Islands",
    "country_code": "SLB",
    "numeric_code": 90,
    "currencySymbol": "$"
  },
  {
    "id": "31b00296-e21f-47ba-af6a-4b2934b53bf0",
    "country_name": "Sierra Leone",
    "country_code": "SLE",
    "numeric_code": 694,
    "currencySymbol": "$"
  },
  {
    "id": "78d35386-0fa1-4694-8e29-04453bd2f695",
    "country_name": "El Salvador",
    "country_code": "SLV",
    "numeric_code": 222,
    "currencySymbol": "$"
  },
  {
    "id": "fed8d453-522f-4edc-87b1-37ad81e417b7",
    "country_name": "San Marino",
    "country_code": "SMR",
    "numeric_code": 674,
    "currencySymbol": "$"
  },
  {
    "id": "4aa9b4f1-bf18-4d8e-9eb1-12764a440690",
    "country_name": "Somalia",
    "country_code": "SOM",
    "numeric_code": 706,
    "currencySymbol": "$"
  },
  {
    "id": "8bdcc2e9-a3e2-416c-ac1e-8b539cbe0ac1",
    "country_name": "Saint Pierre and Miquelon",
    "country_code": "SPM",
    "numeric_code": 666,
    "currencySymbol": "$"
  },
  {
    "id": "2bf0c886-bf81-4502-9b88-6701f43b0ad6",
    "country_name": "Serbia",
    "country_code": "SRB",
    "numeric_code": 688,
    "currencySymbol": "Дин."
  },
  {
    "id": "6960ef2f-babc-497b-afdd-1b8a5332646f",
    "country_name": "South Sudan",
    "country_code": "SSD",
    "numeric_code": 728,
    "currencySymbol": "$"
  },
  {
    "id": "5f767017-ce41-4aad-ad59-b7bc9ce2b314",
    "country_name": "Sao Tome and Principe",
    "country_code": "STP",
    "numeric_code": 678,
    "currencySymbol": "$"
  },
  {
    "id": "0994d232-4b71-414b-a6da-64938aed2b0b",
    "country_name": "Suriname",
    "country_code": "SUR",
    "numeric_code": 740,
    "currencySymbol": "$"
  },
  {
    "id": "5f83ed7d-e1e5-4b3d-8305-4f0be944d942",
    "country_name": "Slovakia",
    "country_code": "SVK",
    "numeric_code": 703,
    "currencySymbol": "$"
  },
  {
    "id": "43fe69d5-e630-4ed5-ab30-ec42f98d8a45",
    "country_name": "Slovenia",
    "country_code": "SVN",
    "numeric_code": 705,
    "currencySymbol": "$"
  },
  {
    "id": "decbd031-e50e-4f92-817e-2b5103d0b74e",
    "country_name": "Sweden",
    "country_code": "SWE",
    "numeric_code": 752,
    "currencySymbol": "kr"
  },
  {
    "id": "c52f21dc-99da-4d6c-b39b-feec8bd2c274",
    "country_name": "Eswatini",
    "country_code": "SWZ",
    "numeric_code": 748,
    "currencySymbol": "$"
  },
  {
    "id": "0431fafa-07ff-4269-917f-32e3c7699ba0",
    "country_name": "Sint Maarten (Dutch part)",
    "country_code": "SXM",
    "numeric_code": 534,
    "currencySymbol": "$"
  },
  {
    "id": "22215c75-d916-4c9e-83e8-a50bdb0c0ada",
    "country_name": "Seychelles",
    "country_code": "SYC",
    "numeric_code": 690,
    "currencySymbol": "₨"
  },
  {
    "id": "c7d6728a-78e1-4bd8-859e-395b13424584",
    "country_name": "Syrian Arab Republic",
    "country_code": "SYR",
    "numeric_code": 760,
    "currencySymbol": "£"
  },
  {
    "id": "4c48309e-87ce-4eea-9487-2acdce1286d1",
    "country_name": "Turks and Caicos Islands",
    "country_code": "TCA",
    "numeric_code": 796,
    "currencySymbol": "$"
  },
  {
    "id": "8b3a7f9d-278f-4615-b860-b211c182f418",
    "country_name": "Chad",
    "country_code": "TCD",
    "numeric_code": 148,
    "currencySymbol": "$"
  },
  {
    "id": "0d57e93c-0ca9-4c3e-9291-f07f5e8504c6",
    "country_name": "Togo",
    "country_code": "TGO",
    "numeric_code": 768,
    "currencySymbol": "$"
  },
  {
    "id": "bd5548cf-ed03-4d10-a38d-9311814b52d7",
    "country_name": "Thailand",
    "country_code": "THA",
    "numeric_code": 764,
    "currencySymbol": "฿"
  },
  {
    "id": "e483f27c-a0ac-4a19-a9b1-0645c22e2270",
    "country_name": "Tajikistan",
    "country_code": "TJK",
    "numeric_code": 762,
    "currencySymbol": "$"
  },
  {
    "id": "fb9d0769-1399-4b14-a27e-cb30f674d4c1",
    "country_name": "Tokelau",
    "country_code": "TKL",
    "numeric_code": 772,
    "currencySymbol": "$"
  },
  {
    "id": "2871bced-ab23-4ec8-b3b6-266abcbd759a",
    "country_name": "Turkmenistan",
    "country_code": "TKM",
    "numeric_code": 795,
    "currencySymbol": "$"
  },
  {
    "id": "9823859c-c059-4549-b87b-8e81091e32e1",
    "country_name": "Timor-Leste",
    "country_code": "TLS",
    "numeric_code": 626,
    "currencySymbol": "$"
  },
  {
    "id": "87d69a88-ba34-4515-b01a-4411f7654ab3",
    "country_name": "Tonga",
    "country_code": "TON",
    "numeric_code": 776,
    "currencySymbol": "$"
  },
  {
    "id": "921ce1a0-113b-4fc2-86e4-0bf8273c25a9",
    "country_name": "Trinidad and Tobago",
    "country_code": "TTO",
    "numeric_code": 780,
    "currencySymbol": "TT$"
  },
  {
    "id": "1e15ef1e-5fa8-4899-84d8-8d5ca7ce5a03",
    "country_name": "Tunisia",
    "country_code": "TUN",
    "numeric_code": 788,
    "currencySymbol": "$"
  },
  {
    "id": "1d3505e3-8cb2-48a4-a873-d43feae360bd",
    "country_name": "Turkey",
    "country_code": "TUR",
    "numeric_code": 792,
    "currencySymbol": "₺"
  },
  {
    "id": "97ad21da-08a3-4904-a4e6-ae918f2bc970",
    "country_name": "Tuvalu",
    "country_code": "TUV",
    "numeric_code": 798,
    "currencySymbol": "$"
  },
  {
    "id": "6136833f-8304-43a6-9dde-40fd0435e4a0",
    "country_name": "Taiwan, Province of China",
    "country_code": "TWN",
    "numeric_code": 158,
    "currencySymbol": "NT$"
  },
  {
    "id": "d5620542-5034-43b3-bc61-b46232ae271a",
    "country_name": "Tanzania, United Republic of",
    "country_code": "TZA",
    "numeric_code": 834,
    "currencySymbol": "$"
  },
  {
    "id": "d4b6068f-8383-4f4f-9967-46242e3b83b7",
    "country_name": "Uganda",
    "country_code": "UGA",
    "numeric_code": 800,
    "currencySymbol": "$"
  },
  {
    "id": "9f78bf16-91e4-4394-9276-1c2e6710efcf",
    "country_name": "Ukraine",
    "country_code": "UKR",
    "numeric_code": 804,
    "currencySymbol": "₴"
  },
  {
    "id": "1448c56b-a8df-4d2a-93b6-831668e26d57",
    "country_name": "United States Minor Outlying Islands",
    "country_code": "UMI",
    "numeric_code": 581,
    "currencySymbol": "$"
  },
  {
    "id": "61371738-3928-4ece-9e60-b5a726439aa4",
    "country_name": "Uruguay",
    "country_code": "URY",
    "numeric_code": 858,
    "currencySymbol": "$U"
  },
  {
    "id": "c41cddc0-b10b-4989-9554-d3b4c1cd4a09",
    "country_name": "United States",
    "country_code": "USA",
    "numeric_code": 840,
    "currencySymbol": "$"
  },
  {
    "id": "2625a30d-bef3-4c5d-994e-42204d022b1c",
    "country_name": "Uzbekistan",
    "country_code": "UZB",
    "numeric_code": 860,
    "currencySymbol": "лв"
  },
  {
    "id": "2b72fdcf-2f35-46c7-84b5-d55a7daa99b4",
    "country_name": "Holy See (Vatican City State)",
    "country_code": "VAT",
    "numeric_code": 336,
    "currencySymbol": "$"
  },
  {
    "id": "e5847a46-1ef0-48bd-89d3-f8580fe45082",
    "country_name": "Saint Vincent and the Grenadines",
    "country_code": "VCT",
    "numeric_code": 670,
    "currencySymbol": "$"
  },
  {
    "id": "2ba11a85-7b69-4bcb-9c2c-c3480b478344",
    "country_name": "Venezuela, Bolivarian Republic of",
    "country_code": "VEN",
    "numeric_code": 862,
    "currencySymbol": "Bs"
  },
  {
    "id": "e17842d0-c85f-4fb8-bc52-9acaef7471d7",
    "country_name": "Virgin Islands, British",
    "country_code": "VGB",
    "numeric_code": 92,
    "currencySymbol": "$"
  },
  {
    "id": "686ddb42-ac65-4d0a-ba73-ed0e00b6f60e",
    "country_name": "Virgin Islands, U.S.",
    "country_code": "VIR",
    "numeric_code": 850,
    "currencySymbol": "$"
  },
  {
    "id": "7b177111-5430-4f9f-96a2-be9a0afe1509",
    "country_name": "Viet Nam",
    "country_code": "VNM",
    "numeric_code": 704,
    "currencySymbol": "₫"
  },
  {
    "id": "51ff3bc3-eef5-42d1-bbaa-434e7bc0d662",
    "country_name": "Vanuatu",
    "country_code": "VUT",
    "numeric_code": 548,
    "currencySymbol": "$"
  },
  {
    "id": "f5cdb9ce-2f1d-47a3-aabf-9d6e6500f46f",
    "country_name": "Wallis and Futuna",
    "country_code": "WLF",
    "numeric_code": 876,
    "currencySymbol": "$"
  },
  {
    "id": "f58d3817-61f1-4a64-9876-e9cd1b62445a",
    "country_name": "Samoa",
    "country_code": "WSM",
    "numeric_code": 882,
    "currencySymbol": "$"
  },
  {
    "id": "768ff081-1a18-41ba-aa7b-97b903e7bf17",
    "country_name": "Yemen",
    "country_code": "YEM",
    "numeric_code": 887,
    "currencySymbol": "﷼"
  },
  {
    "id": "a70b0fa5-b847-417b-a7de-edc0949e0e99",
    "country_name": "South Africa",
    "country_code": "ZAF",
    "numeric_code": 710,
    "currencySymbol": "R"
  },
  {
    "id": "2de710ce-1e8d-44b9-bcbb-245864d1c9de",
    "country_name": "Zambia",
    "country_code": "ZMB",
    "numeric_code": 894,
    "currencySymbol": "$"
  },
  {
    "id": "9f0897e2-8318-4865-87f7-fdeba0a1bc2d",
    "country_name": "Zimbabwe",
    "country_code": "ZWE",
    "numeric_code": 716,
    "currencySymbol": "Z$"
  }
]

export const CountryCode = [
  {
    country: "United States",
    countryCode: "USA",
    currency: "USD",
    currencySymbol: "$"
  },
  {
    country: "India",
    countryCode: "IN",
    currency: "Indian Rupee",
    currencySymbol: "₹",
    alternateCurrencySymbol: "$"
  }
]



// [
//   {
//     country: "Albania",
//     countryCode: "ALL",
//     currencySymbol: "Lek",
//   },
//   {
//     country: "Afghanistan",
//     countryCode: "AFN",
//     currencySymbol: "؋",
//   },
//   {
//     country: "Argentina",
//     countryCode: "ARS",
//     currencySymbol: "$",
//   },
//   {
//     country: "Aruba",
//     countryCode: "AWG",
//     currencySymbol: "ƒ",
//   },
//   {
//     country: "Australia",
//     countryCode: "AUD",
//     currencySymbol: "$",
//   },
//   {
//     country: "Azerbaijan",
//     countryCode: "AZN",
//     currencySymbol: "₼",
//   },
//   {
//     country: "Bahamas",
//     countryCode: "BSD",
//     currencySymbol: "$",
//   },
//   {
//     country: "Barbados",
//     countryCode: "BBD",
//     currencySymbol: "$",
//   },
//   {
//     country: "Belarus",
//     countryCode: "BYN",
//     currencySymbol: "Br",
//   },
//   {
//     country: "Belize",
//     countryCode: "BZD",
//     currencySymbol: "BZ$",
//   },
//   {
//     country: "Bermuda",
//     countryCode: "BMD",
//     currencySymbol: "$",
//   },
//   {
//     country: "Bolivia",
//     countryCode: "BOB",
//     currencySymbol: "$b",
//   },
//   {
//     country: "Bosnia",
//     countryCode: "BAM",
//     currencySymbol: "KM",
//   },
//   {
//     country: "Botswana",
//     countryCode: "BWP",
//     currencySymbol: "P",
//   },
//   {
//     country: "Bulgaria",
//     countryCode: "BGN",
//     currencySymbol: "лв",
//   },
//   {
//     country: "Brazil",
//     countryCode: "BRL",
//     currencySymbol: "R$",
//   },
//   {
//     country: "Brunei Darussalam",
//     countryCode: "BND",
//     currencySymbol: "$",
//   },
//   {
//     country: "Cambodia",
//     countryCode: "KHR",
//     currencySymbol: "៛",
//   },
//   {
//     country: "Canada",
//     countryCode: "CAD",
//     currencySymbol: "$",
//   },
//   {
//     country: "Cayman Islands",
//     countryCode: "KYD",
//     currencySymbol: "$",
//   },
//   {
//     country: "Chile",
//     countryCode: "CLP",
//     currencySymbol: "$",
//   },
//   {
//     country: "China Yuan",
//     countryCode: "CNY",
//     currencySymbol: "¥",
//   },
//   {
//     country: "Colombia",
//     countryCode: "COP",
//     currencySymbol: "$",
//   },
//   {
//     country: "Costa Rica",
//     countryCode: "CRC",
//     currencySymbol: "₡",
//   },
//   {
//     country: "Croatia",
//     countryCode: "HRK",
//     currencySymbol: "kn",
//   },
//   {
//     country: "Cuba",
//     countryCode: "CUP",
//     currencySymbol: "₱",
//   },
//   {
//     country: "Czech Republic",
//     countryCode: "CZK",
//     currencySymbol: "Kč",
//   },
//   {
//     country: "Denmark",
//     countryCode: "DKK",
//     currencySymbol: "kr",
//   },
//   {
//     country: "Dominican Republic",
//     countryCode: "DOP",
//     currencySymbol: "RD$",
//   },
//   {
//     country: "East Caribbean",
//     countryCode: "XCD",
//     currencySymbol: "$",
//   },
//   {
//     country: "Egypt",
//     countryCode: "EGP",
//     currencySymbol: "£",
//   },
//   {
//     country: "El Salvador",
//     countryCode: "SVC",
//     currencySymbol: "$",
//   },
//   {
//     country: "Euro Member",
//     countryCode: "EUR",
//     currencySymbol: "€",
//   },
//   {
//     country: "Falkland Islands (Malvinas)",
//     countryCode: "FKP",
//     currencySymbol: "£",
//   },
//   {
//     country: "Fiji",
//     countryCode: "FJD",
//     currencySymbol: "$",
//   },
//   {
//     country: "Ghana",
//     countryCode: "GHS",
//     currencySymbol: "¢",
//   },
//   {
//     country: "Gibraltar",
//     countryCode: "GIP",
//     currencySymbol: "£",
//   },
//   {
//     country: "Guatemala",
//     countryCode: "GTQ",
//     currencySymbol: "Q",
//   },
//   {
//     country: "Guernsey",
//     countryCode: "GGP",
//     currencySymbol: "£",
//   },
//   {
//     country: "Guyana",
//     countryCode: "GYD",
//     currencySymbol: "$",
//   },
//   {
//     country: "Honduras",
//     countryCode: "HNL",
//     currencySymbol: "L",
//   },
//   {
//     country: "Hong Kong",
//     countryCode: "HKD",
//     currencySymbol: "$",
//   },
//   {
//     country: "Hungary",
//     countryCode: "HUF",
//     currencySymbol: "Ft",
//   },
//   {
//     country: "Iceland",
//     countryCode: "ISK",
//     currencySymbol: "kr",
//   },
//   {
//     country: "India",
//     countryCode: "INR",
//     currencySymbol: "₹",
//     alternateCurrencySymbol: "$",
//   },
//   {
//     country: "Indonesia",
//     countryCode: "IDR",
//     currencySymbol: "Rp",
//   },
//   {
//     country: "Iran",
//     countryCode: "IRR",
//     currencySymbol: "﷼",
//   },
//   {
//     country: "Isle of Man",
//     countryCode: "IMP",
//     currencySymbol: "£",
//   },
//   {
//     country: "Israel",
//     countryCode: "ILS",
//     currencySymbol: "₪",
//   },
//   {
//     country: "Jamaica",
//     countryCode: "JMD",
//     currencySymbol: "J$",
//   },
//   {
//     country: "Japan",
//     countryCode: "JPY",
//     currencySymbol: "¥",
//   },
//   {
//     country: "Jersey",
//     countryCode: "JEP",
//     currencySymbol: "£",
//   },
//   {
//     country: "Kazakhstan",
//     countryCode: "KZT",
//     currencySymbol: "лв",
//   },
//   {
//     country: "Korea (North)",
//     countryCode: "KPW",
//     currencySymbol: "₩",
//   },
//   {
//     country: "Korea (South)",
//     countryCode: "KRW",
//     currencySymbol: "₩",
//   },
//   {
//     country: "Kyrgyzstan",
//     countryCode: "KGS",
//     currencySymbol: "лв",
//   },
//   {
//     country: "Laos",
//     countryCode: "LAK",
//     currencySymbol: "₭",
//   },
//   {
//     country: "Lebanon",
//     countryCode: "LBP",
//     currencySymbol: "£",
//   },
//   {
//     country: "Liberia",
//     countryCode: "LRD",
//     currencySymbol: "$",
//   },
//   {
//     country: "Macedonia",
//     countryCode: "MKD",
//     currencySymbol: "ден",
//   },
//   {
//     country: "Malaysia",
//     countryCode: "MYR",
//     currencySymbol: "RM",
//   },
//   {
//     country: "Mauritius",
//     countryCode: "MUR",
//     currencySymbol: "₨",
//   },
//   {
//     country: "Mexico",
//     countryCode: "MXN",
//     currencySymbol: "$",
//   },
//   {
//     country: "Mongolia",
//     countryCode: "MNT",
//     currencySymbol: "₮",
//   },
//   {
//     country: "Moroccan",
//     countryCode: "MNT",
//     currencySymbol: " د.إ",
//   },
//   {
//     country: "Mozambique",
//     countryCode: "MZN",
//     currencySymbol: "MT",
//   },
//   {
//     country: "Namibia",
//     countryCode: "NAD",
//     currencySymbol: "$",
//   },
//   {
//     country: "Nepal",
//     countryCode: "NPR",
//     currencySymbol: "₨",
//   },
//   {
//     country: "Netherlands Antilles",
//     countryCode: "ANG",
//     currencySymbol: "ƒ",
//   },
//   {
//     country: "New Zealand",
//     countryCode: "NZD",
//     currencySymbol: "$",
//   },
//   {
//     country: "Nicaragua",
//     countryCode: "NIO",
//     currencySymbol: "C$",
//   },
//   {
//     country: "Nigeria",
//     countryCode: "NGN",
//     currencySymbol: "₦",
//   },
//   {
//     country: "Norway",
//     countryCode: "NOK",
//     currencySymbol: "kr",
//   },
//   {
//     country: "Oman",
//     countryCode: "OMR",
//     currencySymbol: "﷼",
//   },
//   {
//     country: "Pakistan",
//     countryCode: "PKR",
//     currencySymbol: "₨",
//   },
//   {
//     country: "Panama",
//     countryCode: "PAB",
//     currencySymbol: "B/.",
//   },
//   {
//     country: "Paraguay",
//     countryCode: "PYG",
//     currencySymbol: "Gs",
//   },
//   {
//     country: "Peru",
//     countryCode: "PEN",
//     currencySymbol: "S/.",
//   },
//   {
//     country: "Philippines",
//     countryCode: "PHP",
//     currencySymbol: "₱",
//   },
//   {
//     country: "Poland",
//     countryCode: "PLN",
//     currencySymbol: "zł",
//   },
//   {
//     country: "Qatar",
//     countryCode: "QAR",
//     currencySymbol: "﷼",
//   },
//   {
//     country: "Romania",
//     countryCode: "RON",
//     currencySymbol: "lei",
//   },
//   {
//     country: "Russia",
//     countryCode: "RUB",
//     currencySymbol: "₽",
//   },
//   {
//     country: "Saint Helena",
//     countryCode: "SHP",
//     currencySymbol: "£",
//   },
//   {
//     country: "Saudi Arabia",
//     countryCode: "SAR",
//     currencySymbol: "﷼",
//   },
//   {
//     country: "Serbia",
//     countryCode: "RSD",
//     currencySymbol: "Дин.",
//   },
//   {
//     country: "Seychelles",
//     countryCode: "SCR",
//     currencySymbol: "₨",
//   },
//   {
//     country: "Singapore",
//     countryCode: "SGD",
//     currencySymbol: "$",
//   },
//   {
//     country: "Solomon Islands",
//     countryCode: "SBD",
//     currencySymbol: "$",
//   },
//   {
//     country: "Somalia",
//     countryCode: "SOS",
//     currencySymbol: "S",
//   },
//   {
//     country: "South Korean",
//     countryCode: "KRW",
//     currencySymbol: "₩",
//   },
//   {
//     country: "South Africa",
//     countryCode: "ZAR",
//     currencySymbol: "R",
//   },
//   {
//     country: "Sri Lanka",
//     countryCode: "LKR",
//     currencySymbol: "₨",
//   },
//   {
//     country: "Sweden",
//     countryCode: "SEK",
//     currencySymbol: "kr",
//   },
//   {
//     country: "Switzerland",
//     countryCode: "CHF",
//     currencySymbol: "CHF",
//   },
//   {
//     country: "Suriname",
//     countryCode: "SRD",
//     currencySymbol: "$",
//   },
//   {
//     country: "Syria",
//     countryCode: "SYP",
//     currencySymbol: "£",
//   },
//   {
//     country: "Taiwan New",
//     countryCode: "TWD",
//     currencySymbol: "NT$",
//   },
//   {
//     country: "Thailand",
//     countryCode: "THB",
//     currencySymbol: "฿",
//   },
//   {
//     country: "Trinidad and Tobago",
//     countryCode: "TTD",
//     currencySymbol: "TT$",
//   },
//   {
//     country: "Turkey",
//     countryCode: "TRY",
//     currencySymbol: "₺",
//   },
//   {
//     country: "Tuvalu",
//     countryCode: "TVD",
//     currencySymbol: "$",
//   },
//   {
//     country: "Ukraine",
//     countryCode: "UAH",
//     currencySymbol: "₴",
//   },
//   {
//     country: "UAE",
//     countryCode: "AED",
//     currencySymbol: " د.إ",
//   },
//   {
//     country: "United Kingdom",
//     countryCode: "GBP",
//     currencySymbol: "£",
//   },
//   {
//     country: "United States",
//     countryCode: "USD",
//     currencySymbol: "$",
//   },
//   {
//     country: "Uruguay",
//     countryCode: "UYU",
//     currencySymbol: "$U",
//   },
//   {
//     country: "Uzbekistan",
//     countryCode: "UZS",
//     currencySymbol: "лв",
//   },
//   {
//     country: "Venezuela",
//     countryCode: "VEF",
//     currencySymbol: "Bs",
//   },
//   {
//     country: "Viet Nam",
//     countryCode: "VND",
//     currencySymbol: "₫",
//   },
//   {
//     country: "Yemen",
//     countryCode: "YER",
//     currencySymbol: "﷼",
//   },
//   {
//     country: "Zimbabwe",
//     countryCode: "ZWD",
//     currencySymbol: "Z$",
//   },
// ];
