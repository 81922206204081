import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import { BubbleChartSampleData } from "../../../data/chartsDataSamples";

am5.addLicense("AM5C258896422");

const BubbleChart = ({
  chartId,
  chartClass,
  chartData
}) => {
  useLayoutEffect(() => {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    var root = am5.Root.new(chartId);

    // to format numbers
    root.numberFormatter.setAll({
      numberFormat: "#.##a",

      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: [
        { number: 1e3, suffix: "K" },
        { number: 1e6, suffix: "M" },
        { number: 1e9, suffix: "B" },
      ],

      // Do not use small number prefixes at all
      smallNumberPrefixes: [],
    });

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);
    root.interfaceColors.set("grid", am5.color(0xffffff)); // to chang the color of grid lines

    var data = chartData;

    // Create wrapper container
    var container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout,
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding
    var series = container.children.push(
      am5hierarchy.ForceDirected.new(root, {
        downDepth: 2,
        topDepth: 1,
        initialDepth: 0,
        maxRadius: 60,
        minRadius: 20,
        valueField: "value",
        categoryField: "label",
        childDataField: "children",
        manyBodyStrength: -10,
        centerStrength: 0.8,
      })
    );

    series.labels.template.setAll({
      fontSize: 12,
      fontWeight: "400",
      fill: "#ffffff",
    });

    series
      .get("colors")
      .set("colors", [
        am5.color(0x11a1fd),
        am5.color(0x5a75f9),
        am5.color(0x07c180),
        am5.color(0xff9931),
        am5.color(0x4cc3fd),
        am5.color(0x8298fb),
        am5.color(0x3cd795),
        am5.color(0xffb866),
      ]);

    series.get("colors").setAll({
      step: 1,
    });

    series.links.template.setAll({
      strokeWidth: 2,
    });

    series.nodes.template.setAll({
      tooltipText: "[bold]{category}[/]: {value}",
      cursorOverStyle: "pointer",
    });

    var tooltip = series.set(
      "tooltip",
      am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: false,
        autoTextColor: false,
        pointerOrientation: "horizontal",
        position: "pointer",
      })
    );

    tooltip.get("background").setAll({
      fill: am5.color(0x213345),
      maxWidth: 200,
      oversizedBehavior: "truncate",
      cornerRadius: 5,
    });

    tooltip.label.setAll({
      fill: am5.color(0xffffff),
      fontSize: 13,
    });

    series.data.setAll(data);

    // Make stuff animate on load
    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <Wrapper>
      <div id={chartId} className={chartClass}></div>
    </Wrapper>
  );
};

BubbleChart.propTypes = {
  chartId: PropTypes.string,
  chartClass: PropTypes.string,
  chartData: PropTypes.array
};

export default BubbleChart;
