import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5wc from "@amcharts/amcharts5/wc";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";

am5.addLicense("AM5C258896422");

const TagCloud = ({
  chartId,
  chartClass,
  chartData
}) => {

  useLayoutEffect(() => {
    var root = am5.Root.new(chartId);

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    var data = chartData;

    data.map((word) => {
      if (word.sentiment === "positive") {
        word["labelSettings"] = { fill: am5.color(0x07c180) }
      } else if (word.sentiment === "negative") {
        word["labelSettings"] = { fill: am5.color(0xd51f30) }
      } else if (word.sentiment === "neutral") {
        word["labelSettings"] = { fill: am5.color(0xff9931) }
      }
    })


    // Add series
    // https://www.amcharts.com/docs/v5/charts/word-cloud/
    var container = root.container.children.push(am5wc.WordCloud.new(root, {
      width: am5.percent(100),
      height: am5.percent(100),
      layout: root.verticalLayout
    }));

    var series = container.children.push(am5wc.WordCloud.new(root, {
      categoryField: "label",
      valueField: "value",
      minFontSize: am5.percent(20),
      maxFontSize: am5.percent(60),
    }));

    series.set("heatRules", [{
      target: series.labels.template,
      dataField: "value"
    }]);

    series.labels.template.setAll({
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 5,
      paddingRight: 5,
      fontFamily: "Ubuntu",
      cursorOverStyle: "pointer",
      templateField: "labelSettings"
    });

    // Add

    // to format numbers
    root.numberFormatter.setAll({
      numberFormat: "#.##a",
      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: [
        { number: 1e3, suffix: "K" },
        { number: 1e6, suffix: "M" },
        { number: 1e9, suffix: "B" },
      ],
      // Do not use small number prefixes at all
      smallNumberPrefixes: [],
    });


    // Configure labels
    series.labels.template.setAll({
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 5,
      paddingRight: 5,
      fontFamily: "Ubuntu"
    });
    series.data.setAll(data);

    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <Wrapper>
      <div id={chartId} className={chartClass}></div>
    </Wrapper>
  );
};

TagCloud.propTypes = {
  chartId: PropTypes.string,
  chartClass: PropTypes.string,
  chartData: PropTypes.array,
};

export default TagCloud;
