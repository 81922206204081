import { useLocation, useMatch, useSearchParams } from "react-router-dom";

import Wrapper from "../helpers/Wrapper";

import Dropdown from "../ui/dropdown/Dropdown";
import DatePicker from "../ui/Datepicker";
import { useContext, useEffect, useMemo, useState } from "react";

import TvBrandsDropdown from "../ui/dropdown/TvBrandsDropdown";
import ErrorBoundary from "../../utils/ErrorBoundary";
import SalesMarketDropdown from "../ui/dropdown/SalesMarketDropdown";
import { useDispatch } from "react-redux";
import {
  setSelectedBrandIds,
  setSelectedBrands,
} from "../../store/globalBrandsDropdownSlice";
import { useSelector } from "react-redux";
import ExportDropdown from "../ui/dropdown/ExportDropdown";
import CamapaignDropdown from "../ui/dropdown/CampaignDropdown";
import AuthContext from "../../store/authContext";
import { DefaultFlagIcon, DefaultProfileImage } from "../../constants/constants";
import IcogzLogo from "../../assets/brand/icogz-logo.svg";
import { extractData } from "../../utils/Utils";
import DatepickerAlertContext from "../../store/datepickerAlertContext";
import EventTracker from "../eventsTracking/EventTracker";

const Header = ({ identity, name, data, headers, disabled }) => {
  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const { setShowDataUnavailableBrands } = useContext(DatepickerAlertContext)

  // SELECTED BRANDS
  const selectedBrands = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrands
  );

  const optionBrands = useSelector(
    (state) => state.globalSelectedBrandIds.brandOptions
  );

  // SELECTED BRANDS FROM DROPDOWN
  const selectedDates = useSelector((state) => state.date);

  // TO GET THE MODULE AND SUBMODULE FORM THE ACTIVE URL
  const location = useLocation();
  let splitURL = location.pathname.split("/");
  let activeModule = splitURL[1] === undefined ? null : splitURL[1].length === 0 ? null : splitURL[1].toLowerCase();
  let activeSubmodule = splitURL[2] === undefined ? null : splitURL[2].length === 0 ? null : splitURL[2].toLowerCase();

  let [searchParams, setSearchParams] = useSearchParams();
  let platformParams = searchParams.get("platform") === undefined ? "overall" : searchParams.get("platform");
  let activeBrands = searchParams.get("brands");



  // BRANDS PLATFORM WISE DATE
  const platformBrandsDates = useSelector((state) => state.moduleData.module);
  const extractedData = extractData(platformBrandsDates);


  var brandArrWDate = [];

  extractedData.map((x) => {
    if (brandArrWDate.some((y) => y.brand_id === x.brand_id)) {
      var elementPos = brandArrWDate.map(function (z) { return z.brand_id; }).indexOf(x.brand_id);

      var getObj = brandArrWDate[elementPos];

      if (new Date(x.min_date).getTime() < new Date(getObj.min_date).getTime()) {
        brandArrWDate[elementPos].min_date = x.min_date
      }

      if (new Date(x.max_date).getTime() > new Date(getObj.max_date).getTime()) {
        brandArrWDate[elementPos].max_date = x.max_date
      }
    } else {
      brandArrWDate.push(x);
    }
  });


  const [isDropdownOptionsLoading, setDropdownOptionsIsLoading] = useState(false);
  const [isDropdownOptionsLoaded, setDropdownOptionsIsLoaded] = useState(false);

  const brandsTempArr2 = useMemo(() => {
    let filteredList = [];

    if (authCtx.isLoggedIn && Object.values(platformBrandsDates).length !== 0) {
      if (activeModule === "admin" || activeModule === "login" || activeModule === null || activeModule === undefined || (activeModule === null && activeSubmodule === null)) {
        // FOR COKE SUMMARY, DASHBOARD SUMMARY  
        filteredList = brandArrWDate.map((brand) => ({
          label: brand.brand_name,
          value: brand.brand_id,
          icon: (
            <img
              src={brand.brand_logo === "" || typeof brand.brand_logo !== "string" ? DefaultProfileImage : brand.brand_logo}
              style={{ width: "30px", height: "30px", borderRadius: "100%" }}
            />
          ),
        }));
      } else if (activeModule !== null && activeSubmodule === null) {
        filteredList = []
      } else if (activeSubmodule === "influencers") {
        filteredList = []
      } else if (activeSubmodule === "tv" || activeSubmodule === "website") {
        const brandList = platformBrandsDates[activeModule][activeSubmodule][activeSubmodule];
        if (brandList !== undefined) {
          filteredList = Object.values(brandList).map((brandData) => ({
            label: brandData.brand_name,
            value: brandData.brand_id,
            icon: (
              <img
                src={brandData.brand_logo === "" || typeof brandData.brand_logo !== "string" ? DefaultProfileImage : brandData.brand_logo}
                style={{ width: "30px", height: "30px", borderRadius: "100%" }}
                alt={`Icon for ${brandData.brand_name}`}
              />
            ),
          }));
        }
      } else {


        var updatedActiveSubModule = activeSubmodule === 'social-listening' ? 'social_listening' : activeSubmodule
        const brandList = platformBrandsDates[activeModule][updatedActiveSubModule][platformParams];
        if (brandList !== undefined) {
          filteredList = Object.values(brandList).map((brandData) => ({
            label: brandData.brand_name,
            value: brandData.brand_id,
            icon: (
              <img
                src={brandData.brand_logo === "" || typeof brandData.brand_logo !== "string" ? DefaultProfileImage : brandData.brand_logo}
                style={{ width: "30px", height: "30px", borderRadius: "100%" }}
                alt={`Icon for ${brandData.brand_name}`}
              />
            ),
          }));
        }
      }
    }

    return filteredList;
  }, [authCtx, platformBrandsDates, activeModule, activeSubmodule, platformParams]);

  useEffect(() => {
    authCtx.token !== undefined
      ? setDropdownOptionsIsLoading(false) && setDropdownOptionsIsLoaded(true)
      : setDropdownOptionsIsLoading(true) && setDropdownOptionsIsLoaded(false)
  }, [authCtx]);

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const [showBrandsDropdown, setShowBrandsDropdown] = useState(false);
  const [showCamapaignDropdown, setShowCamapaignDropdown] = useState(false);
  const [showTVBrandsDropdown, setShowTVBrandsDropdown] = useState(false);
  const [showSalesBrandsDropdown, setShowSalesBrandsDropdown] = useState(false);


  // BRAND DROPDOWN STATE
  const [selectedBrandDropdownOption, setSelectedBrandDropdownOption] = useState(selectedBrands === undefined ? [] : selectedBrands);

  useEffect(() => {


    if (activeBrands === undefined || activeBrands === null) {
      dispatch(setSelectedBrands([]))
      setSelectedBrandDropdownOption([])
      return
    }


    if (activeBrands !== 'overall') {

      let brandList = activeBrands.split(',')
      let brandOption = brandsTempArr2.filter((x) => brandList.includes(x.value));
      dispatch(setSelectedBrands(brandOption));
      setSelectedBrandDropdownOption(brandOption)
    } else {
      setSelectedBrandDropdownOption(null)
      dispatch(setSelectedBrands([]));
    }
  }, [])



  useEffect(() => {
    if (location.pathname === "/") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/mi/campaigns") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(true);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/mi/social") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/mi/video") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/mi/hashtag") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/mi/compare") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/mi/website") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/mi/influencers") {
      setShowBrandsDropdown(false);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/mi/tv" || location.pathname === "/ri/tv") {
      setShowBrandsDropdown(false);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(true);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/ri/sales") {
      setShowBrandsDropdown(false);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(true);
    } else if (location.pathname === "/ri/market") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    }
    else if (location.pathname === "/mi/social-listening") {
      setShowBrandsDropdown(true);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    } else if (location.pathname === "/admin") {
      setShowBrandsDropdown(false);
      setShowCamapaignDropdown(false);
      setShowTVBrandsDropdown(false);
      setShowSalesBrandsDropdown(false);
    }
  }, [location])

  // BRAND DROPDOWN OPTIONS SELECTION HANDLER
  const brandDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedBrandDropdownOption("");
    } else {
      setSelectedBrandDropdownOption(e);
      dispatch(setSelectedBrandIds([...new Set(e.map((item) => item.value))]));
      dispatch(setSelectedBrands(e));
    }

    EventTracker({
      eventType: "brands_selected",
      module: activeModule,
      subModule: activeSubmodule,
      platform: platformParams,
      section: name,
      brands: e.length === 0 ? "Overall" : [...new Set(e.map((item) => item.label))],
      date: {
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate,
        previousStartDate: selectedDates.prevStartDate,
        previousEndDate: selectedDates.prevEndDate,
      }
    })

  };

  const handleCallback2 = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  // TO ADD BRANDS SEARCH QUERRY IN URL
  useEffect(() => {
    setSearchParams({
      platform: platformParams,
      brands:
        selectedBrands.length === 0
          ? "overall"
          : [...new Set(selectedBrands.map((item) => item.value))].join(","),
    });

  }, [selectedBrands]);

  return (
    <Wrapper>
      <div className="dashboard_navbar">
        <a href="#" className="icogz_logo_link">
          {/* ICOGZ LOGO */}
          <img src={IcogzLogo} className="icogz_logo" alt="icogz logo" />

        </a>
        <div className="mobile_navbar_filter">
          {/* <div className=""> filters</div> */}
        </div>
        <div className="flex justify_between" id="top_nav_bar">
          <div className="left">
          </div>
          <div className="right">
            <div className="flex justify_end align_center">
              {showCamapaignDropdown &&
                <CamapaignDropdown />
              }

              {showTVBrandsDropdown && (
                <div className="flex start top_nav_options">
                  <ErrorBoundary>
                    <TvBrandsDropdown />
                  </ErrorBoundary>
                </div>
              )}

              {showSalesBrandsDropdown && (
                <div className="flex start top_nav_options">
                  <ErrorBoundary>
                    <SalesMarketDropdown />
                  </ErrorBoundary>
                </div>
              )}

              {showBrandsDropdown && (
                <div className="flex start top_nav_options">
                  <ErrorBoundary>

                    <Dropdown
                      dropdownLoading={isDropdownOptionsLoading}
                      className={"top_nav_dropdown"}
                      placeholder={"Select Brand"}
                      options={brandsTempArr2}
                      ismulti={true}
                      value={selectedBrandDropdownOption}
                      selectedOptions={selectedBrandDropdownOption}
                      setStatedropdown={brandDropdownSelectionHandler}
                    />
                  </ErrorBoundary>
                </div>
              )}

              <div className="flex center top_nav_options">
                <ErrorBoundary>
                  <DatePicker
                    open={isDatePickerOpen}
                    setOpen={handleCallback2}
                  />
                </ErrorBoundary>
              </div>

              <div className="flex center top_nav_options" data-html2canvas-ignore={true}>
                <ExportDropdown
                  sectionId={identity}
                  sectionName={name}
                  sectionData={data}
                  sectionDataHeaders={headers}
                  sectionDisabled={disabled} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Header;