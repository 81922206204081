import { useEffect, useState, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

import Wrapper from "../../components/helpers/Wrapper";
import Slider from "../../components/ui/Slider";
import BarChart from "./charts/BarChart";
//import DefaultPostImg from "../../components/ui/tables/Datatable";
import {
  DefaultPostImage,
  DonutChartColorSet,
  DonutChartColorSet2,
  DonutChartColorSet3,
} from "../../constants/constants";

import DonutPieChart from "../../components/ui/charts/DonutPieChart";
import Button from "../../components/ui/Button";
import ComparePostCard from "../../components/ui/ComparePostCard";
import NumberFormatter from "../../utils/NumberFormatter";

import NoDataAvailableLoader from "./loaders/NoDataAvailable";
import ServerErrorsLoader from "./loaders/ServerErrosLoader";
import Loader from "./loaders/Loader";
import AuthContext from "../../store/authContext";
import axios from "../helpers/axios";
import NumberSeparator from "../../utils/NumberSeparator";
import ErrorBoundary from "../../utils/ErrorBoundary";
import LineChart from "./charts/LineChart";
import ExportDropdown from "./dropdown/ExportDropdown";
import SectionDefinationsBadge from "./SectionDefinationsBadge";
import NoDataAvailableChecker from "../../utils/NoDataAvailableChecker";

const CokeCompare = ({
  products,
  Title,
  isPaid,
  isSales,
  social_platform,
  start_date,
  end_date,
  tabClear,
  salesBrand,
  compare_platform,
  mapZone,
  mapStateCode,
  hasExportDropdown,
  exportHeaders,
  compareType,
}) => {
  // PAGE CONSTANTS
  let pageModule = "Retail Intelligence";
  let pageSubModule = "Sales";
  const [productsList, setProductsList] = useState(products);

  const [isComapreBtnDisabled, setIsComapreBtnDisabled] = useState(true);

  const [productsListTitle, setProductsListTitle] = useState("top_5");

  const [selectedProductsToCompare, setSelectedProductsToCompare] = useState(
    []
  );
  const authCtx = useContext(AuthContext);

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const [noOfElements, setNoOfElements] = useState(4);

  const [postIdentity, setPostIdentity] = useState([]);

  const [paidData, setPaidData] = useState([]);

  const [isViewMoreClicked, setIsViewMoreClicked] = useState(false);
  const [viewMoreClicked, setViewMoreClicked] = useState("");

  // TO GET THE TOP POST FROM POST GRPS
  const [topPostSelectedDate, setTopPostSelectedDate] = useState("");

  let topPostStartDate;
  let topPostEndDate;

  if (topPostSelectedDate !== "") {
    topPostStartDate = topPostSelectedDate;
    topPostEndDate = topPostSelectedDate;
  } else {
    topPostStartDate = selectedDates.startDate;
    topPostEndDate = selectedDates.endDate;
  }

  // TO GET THE DATE FROM LINECHART ONCLICK
  const bulletClicksHandler = (data) => {
    setTopPostSelectedDate(data);
  };

  function formatMoney(n) {
    return (Math.round(n * 100) / 100).toLocaleString();
  }

  // const [checkedComparedItemList, setCheckedComparedItemList] = useState([]);

  const scollToRef = useRef();

  let filtered = [];
  let newSelectedProductsToCompare = [];

  // TO HANDLE THE CHECKBOX SELECTION
  const checkboxEventHandler = (childData, event) => {
    if (salesBrand === true) {
      filtered = productsList.filter((a) => a.isChecked === true);

      if (postIdentity.includes(childData.id)) {
        let index = postIdentity.indexOf(childData.id);
        postIdentity.splice(index, 1);
      } else {
        postIdentity.push(childData.id);
      }

      setPostIdentity([...postIdentity]);

      if (
        paidData.some(
          (paidData) =>
            paidData.is_company === childData.is_company &&
            paidData.id === childData.brand_id
        )
      ) {
        paidData.splice(
          paidData.findIndex((a) => a.id === childData.brand_id),
          1
        );
        setPaidData([...paidData]);
      } else {
        paidData.push({
          is_company: childData.is_company,
          id: childData.brand_id,
          name: childData.brand_name,
        });
        setPaidData([...paidData]);
      }

      if (filtered.length <= 2 || event.target.checked == false) {
        event.target.checked === true
          ? (childData.isChecked = true)
          : (childData.isChecked = false);

        if (filtered.length > 1 && event.target.checked == true) {
          productsList.map(function (product) {
            product.isChecked === true
              ? (product.isDisabled = false)
              : (product.isDisabled = true);
          });
        } else if (filtered.length >= 3 && event.target.checked == false) {
          productsList.map(function (product) {
            product.isChecked === true
              ? (product.isDisabled = false)
              : (product.isDisabled = false);
          });
        }
        setProductsList([...productsList]);
      }

      // TO MAKE COMPARE BUTTON ENABLED AND DISABLED
      if (filtered.length > 0 && event.target.checked == true) {
        setIsComapreBtnDisabled(false);
      } else if (filtered.length <= 2 && event.target.checked == false) {
        setIsComapreBtnDisabled(true);
      }
    } else {
      filtered = productsList.filter((a) => a.isChecked === true);

      if (postIdentity.includes(childData.state_code)) {
        let index = postIdentity.indexOf(childData.state_code);
        postIdentity.splice(index, 1);
      } else {
        postIdentity.push(childData.state_code);
      }

      setPostIdentity([...postIdentity]);
      if (filtered.length <= 2 || event.target.checked == false) {
        event.target.checked === true
          ? (childData.isChecked = true)
          : (childData.isChecked = false);

        if (filtered.length > 1 && event.target.checked == true) {
          productsList.map(function (product) {
            product.isChecked === true
              ? (product.isDisabled = false)
              : (product.isDisabled = true);
          });
        } else if (filtered.length >= 3 && event.target.checked == false) {
          productsList.map(function (product) {
            product.isChecked === true
              ? (product.isDisabled = false)
              : (product.isDisabled = false);
          });
        }
        setProductsList([...productsList]);
      }

      // TO MAKE COMPARE BUTTON ENABLED AND DISABLED
      if (filtered.length > 0 && event.target.checked == true) {
        setIsComapreBtnDisabled(false);
      } else if (filtered.length <= 2 && event.target.checked == false) {
        setIsComapreBtnDisabled(true);
      }
    }
  };

  // FOR SHOWING TOP 5 OR ALL POSTS
  const productListHandler = () => {
    if (productsListTitle === "top_5") {
      setProductsListTitle("view_all");
      closeCompare();
    } else {
      setProductsListTitle("top_5");
      closeCompare();
    }
  };

  // TO ADD PRODUCTS IN COMPARE SECTION
  const MainCompare = () => {
    newSelectedProductsToCompare = productsList.filter(
      (a) => a.isChecked === true
    );
  /*   fetchPaidDataHandler(); */
    setSelectedProductsToCompare([...newSelectedProductsToCompare]);
    setTimeout(() => {
      scollToRef.current.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }, 500);
  };

  /*  useEffect(() => {
     fetchPaidDataHandler();
   }, [viewMoreClicked, postIdentity]); */

  // TO SHOW ALL PRODUCTS
  const slice = productsList.slice(0, noOfElements);
  const loadMore = () => {
    if (slice.length >= productsList.length) {
      setNoOfElements(4);
    } else {
      setNoOfElements(4 + noOfElements);
    }
  };

  // TO SHOW ONLY 4 PRODUCTS IN VIEW ALL PRODUCTS
  const showLess = () => {
    setNoOfElements(4);
  };

  // TO SHOW AND HIDE VIEW MORE SECTION
  const viewMoreOnclickHandler = (viewMoreSelected) => {
    if (viewMoreClicked === viewMoreSelected) {
      setIsViewMoreClicked(false);
      setViewMoreClicked("");
    } else {
      setIsViewMoreClicked(true);
      setViewMoreClicked(viewMoreSelected);
    }
  };

  // TO CLOSE THE VIEW MORE SECTION
  const closeViewMore = () => {
    setIsViewMoreClicked(false);
    setViewMoreClicked("");
  };

  // TO CLOSE THE COMPARE SECTION
  const closeCompare = () => {
    setSelectedProductsToCompare(selectedProductsToCompare.length === 0);
    productsList.map(function (product) {
      if (product.isChecked === true) {
        product.isChecked = false;
        product.isDisabled = false;
        setIsComapreBtnDisabled(true);
      } else {
        product.isChecked = false;
        product.isDisabled = false;
        setIsComapreBtnDisabled(true);
      }
    });

    setPaidData([]);
    //setProductsList([...productsList]);
  };

  // TO AUTO ADD AND AUTO REMOVE PRODUCTS FROM COMPARE
  useEffect(() => {
    if (selectedProductsToCompare.length > 1) {
      MainCompare();
    }
  }, [productsList]);

  const [compareParams, setCompareParams] = useState({
    //brand_id: BRAND_ID,
    start_date: selectedDates.startDate,
    end_date: selectedDates.endDate,
    previous_start_date: selectedDates.prevStartDate,
    previous_end_date: selectedDates.prevEndDate,
    // post_id: isViewMoreClicked === true ? viewMoreClicked.id : postIdentity.join(","),
    //state_code: postIdentity.join(","),
    compare: JSON.stringify(paidData),
  });



  useEffect(() => {
    closeCompare();
    setProductsList([...productsList]);
    setPostIdentity([]);
  }, [tabClear]);

  const exportProductsData = products.map((a) => ({ ...a }));
  // const exportSelectedProductsData = selectedProductsToCompare.map(a => ({...a}));

  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_top">
        <div className="grid grid_cols_1 section_card_header">
          <div className="section_info">
           
            <h2 className="section_card_title">
            {Title}
            </h2>
           {/*  <SectionDefinationsBadge
              title={"Top 5 " + Title}
              module={module}
              platform={social_platform}
              section={module_section}
            /> */}
          </div>

          <div className="grid col_span_2 flex justify_end top_post_export">
            <div
              className="btn_wrapper width_fit_content"
              style={{ width: "150px" }}
            >
              <Button
                handleClick={() => MainCompare()}
                disabled={isComapreBtnDisabled}
                className="primary_btn"
                type="button"
              >
                Compare
              </Button>
            </div>
           {/*  <div
              className="btn_wrapper width_fit_content"
              style={{ width: "150px" }}
            >
              <Button
                handleClick={() => productListHandler()}
                disabled={productsList.length > 0 ? false : true}
                className="secondary_btn"
                type="button"
              >
                {productsListTitle === "top_5"
                  ? "View All " + filterTitle
                  : "View Top 5 " + filterTitle}
              </Button>
            </div> */}
            {hasExportDropdown && (
              <div data-html2canvas-ignore={true}>
                <ExportDropdown
                  // moduleName={splitUrl1}
                  // subModuleName={splitUrl2}
                  // platform={splitUrl3}
                  // tab={splitUrl4}
                  sectionId={"brand_export"}
                  sectionName={"Compare_" + Title}
                  sectionData={exportProductsData}
                />
              </div>
            )}
          </div>
        </div>
        
        {productsList.length > 0 ? (
         
         
              <div className="card_wrapper grid_padding">
                <Slider
                  sliderTitle={""}
                  slidestoshow={3}
                  breakpoints={{
                    0: {
                      slidesPerView: "auto",
                    },
                    426: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    1300: {
                      slidesPerView: 3,
                    },
                    2100: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {productsList.map((post, index) => (
                    <SwiperSlide key={index}>
                      <ComparePostCard
                        postData={post}
                        checkboxEventHandler={checkboxEventHandler}
                        viewMoreOnclickHandler={viewMoreOnclickHandler}
                        viewBrandInfo={true}
                      />
                    </SwiperSlide>
                  ))}
                </Slider>
              </div>
            
              ) : (
          <div className="grid-cols-1">
            <div className="grid grid-cols-1 table-content">
              <div className="flex justify_center">
                <h4>No Data Available</h4>
              </div>
            </div>
          </div> 
        )}
        </div>   
        
        
         {/* FOR SELECTED POSTS TO COMPARE */}
            {selectedProductsToCompare.length > 1 && (
              <Wrapper>
               
               
                <div
                  ref={scollToRef}
                  className="grid grid_cols_1 grid_margin_top compare_sec"
                >
                  <div className="section_card fullpage_section_card grid">
                    <div className="section_card_header">
                      <div className="section_info">
                        <h2 className="section_card_title">Compare Results</h2>
                        <SectionDefinationsBadge
                          title={"Compare Table"}
                          module="sales compare"
                          platform="overall"
                          section="overall"
                        />
                      </div>
                      <div className="flex" style={{ width: "100px" }}>
                        <Button
                          handleClick={() => closeCompare()}
                          className="secondary_btn"
                          type="button"
                        >
                          Close
                        </Button>
                        {hasExportDropdown && (
                          <div data-html2canvas-ignore={true}>
                            <ExportDropdown
                              moduleName={pageModule}
                              subModuleName={pageSubModule}
                              sectionId={"coke_compare"}
                              sectionName={"Compare_Selected"+" "+ Title}
                              sectionData={selectedProductsToCompare}
                              sectionChart={"topProductCompare"}
                            />
                          </div>
                        )}
                      </div>

                      {/* <div className="btn_wrapper" style={{ width: "150px" }}>
                      <Button
                        handleClick={() => closeCompare()}
                        className="secondary_btn"
                        type="button"
                      >
                        Close
                      </Button>
                    </div> */}
                    </div>
                    <div className="section_card_body brand-comp-table">
                      <div className="section_card_table_wrapper">
                        <div className="grid grid_cols_3  grid_margin_bottom">
                          {selectedProductsToCompare.map((product, index) => (
                            <div
                              key={index}
                              className="grid grid_cols_1 card_border_right"
                            >
                              <div className="table_wrapper">
                                <table className="table table_striped table_bordered wrapped-table">
                                  <tbody>
                                    <tr>
                                      <td colSpan={2}>
                                        <div className="tableCard_wrap">
                                          <img
                                            src={
                                              product.brand_logo === "" ||
                                              product.brand_logo ===
                                                undefined ||
                                              product.brand_logo === null
                                                ? DefaultPostImage
                                                : product.brand_logo
                                            }
                                            alt="img"
                                            onError={(e) =>
                                              (e.target.src = DefaultPostImage)
                                            }
                                            className="tableCard_img"
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                    {product.metrics_data.map(
                                      (metric, index) => (
                                        <tr key={index}>
                                          <td className="">
                                            <span className="bold">
                                              {metric.label}
                                            </span>
                                          </td>
                                          <td className="align_right value_td">
                                            <ErrorBoundary>
                                              {/*   <NumberSeparator
                                          number={metric.value}
                                          title={metric.label}
                                        /> */}

                                              <NumberFormatter
                                                title={metric.label}
                                                number={metric.value}
                                              />
                                            </ErrorBoundary>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              </Wrapper>
            )}
         
      
    
    </Wrapper>
  );
};

export default CokeCompare;
