import { NavLink, useLocation } from "react-router-dom";
import Wrapper from "../../helpers/Wrapper";
import EventTracker from "../../eventsTracking/EventTracker";

const Submenu = ({ activeModule, subModules, sidebarToggleHandler }) => {

  const submodulesToggleHandler = (item) => {
    if (window.innerWidth < 1599) {
      sidebarToggleHandler(false);
    }

    EventTracker({
      eventType: "sidebar_navigation_click",
      module: activeModule,
      subModule: item.subModuleName
    });
  };

  const location = useLocation()

  return (
    <Wrapper>
      {subModules.map((item, index) => (
        <NavLink
          key={index}
          onClick={() => submodulesToggleHandler(item)}
          className={({ isActive }) =>
            isActive
              ? "sub_nav_link active"
              : "sub_nav_link"
          }
          to={(item.subModuleLink === location.pathname) ? { pathname: location.pathname, search: location.search } : item.subModuleLink}
        >
          <div className="sub_nav_link_icon_wrapper">{item.subModuleIcon}</div>
          <div className="sub_nav_link_title">{item.subModuleName}</div>
        </NavLink>
      ))}
    </Wrapper>
  );
};

export default Submenu;
