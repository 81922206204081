import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../store/authContext";
import FullPageExportContext from "../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../components/helpers/axios";
import Wrapper from "../../../components/helpers/Wrapper";
import PageHeader from "../../../components/layout/pageHeader";
import Slider from "../../../components/ui/Slider";
import Card from "../../../components/ui/Card";

// LOADER
import NoDataAvailableLoader from "../../../components/ui/loaders/NoDataAvailable";
import Loader from "../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../components/ui/loaders/ServerErrosLoader";

// PAGES
import CampaignOverall from "./campaignOverall/CampaignOverall";
import CampaignGoogle from "./campaignGoogle/CampaignGoogle";
import CampaignFacebook from "./campaignFacebook/CampaignFacebook";
import CampaignInstagram from "./campaignInstagram/CampaignInstagram";
import CampaignTwitter from "./campaignTwitter/CampaignTwitter";
import CampaignLinkedin from "./campaignLinkedin/CampaignLinkedin";
import CampaignDv360 from "./dv360/CampaignDv360";
import CampaignOTT from "./campaignOTT/CampaignOTT";
import CampaignOffline from "./campaignOffline/CampaignOffline";

// UTILS
import { IAEvent_Platform_Visit } from "../../../utils/IAEvents";
import NoDataAvailableChecker from "../../../utils/NoDataAvailableChecker";
import CampaignMarketplace from "./campaignMarketplace/CampaignMarketplace";
import { useParams, useSearchParams } from "react-router-dom";
import CampaignBing from "./CampaignBing/CampaignBing";
import Badge from "../../../components/ui/Badge";
import CampaignRollworks from "./campaignRollworks/CampaignRollworks";
import EventTracker from "../../../components/eventsTracking/EventTracker";

const Campaign = () => {
  const authCtx = useContext(AuthContext);
  const { setexportSummary } = useContext(FullPageExportContext);

  const params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  let activeBrands = searchParams.get("brands");


  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

  // SELECTED CAMPAIGNS
  const selectedCampaignPlatformsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignPlatform);
  const selectedCampaignObjectivesFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignObjectives);
  const selectedCampaignsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaigns);
  // const selectedBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedBuckets);
  const selectedAllBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedAllBuckets);


  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null
  } else {
    BRAND_ID = globalSelectedBrandIds.join(',')
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);
  const selectedBrands = useSelector((state) => state.globalSelectedBrandIds.selectedBrands);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  const [selectedPlatform, setSelectedPlatform] = useState("Overall");

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
  }, [BRAND_ID, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/campaign-metric-card/", config
      );

      setMetricCards(res.data === undefined || res.data.length === 0 ? [] : res.data);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection =
    <Wrapper>
      <Loader loaderType="campaignLoader" />
    </Wrapper>

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection =
        <Wrapper>
          <NoDataAvailableLoader chartType="cardChartType" />
        </Wrapper>
    } else {
      metricCardsSection = (
        <Wrapper>
          <div className="grid">
            <div className="card_wrapper">
              <Slider
                sliderWrapperClass={"tab_card_slider"}
                showSliderHeader={false}
                showNavigation={false}
                sliderTitle={"Summary"}
              >
                {metricCards.map((data, index) => (
                  <SwiperSlide
                    key={index}
                    onClick={() => activePlatform(data.cardTitle)}
                  >
                    <Card
                      key={index}
                      cardClass={
                        selectedPlatform === data.cardTitle
                          ? "tabCard active"
                          : "tabCard "
                      }
                      variant={"compare"}
                      cardTitle={data.cardTitle}
                      currentValue={data.currentValue}
                      previousValue={data.previousValue}
                      rowOneTitle={data.rowOneTitle}
                      second_currentValue={data.second_currentValue}
                      second_previousValue={data.second_previousValue}
                      rowTwoTitle={data.rowTwoTitle}
                    />
                  </SwiperSlide>
                ))}
              </Slider>
            </div>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection =
      <Wrapper>
        <ServerErrorsLoader chartType="cardChartType" error={metricCardserror} />
      </Wrapper>
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection =
      <Wrapper>
        <Loader loaderType="campaignLoader" />
      </Wrapper>
  }

  // TO SET PLATFORM ACTIVE ON CLICK
  const activePlatform = (platform) => {
    if (selectedPlatform !== platform) {
      setSelectedPlatform(platform);
    } else {
      setSelectedPlatform("Overall");
    }
    IAEvent_Platform_Visit("Marketing Intelligence", "Campaign", platform)

    EventTracker({
      eventType: "platform_selected",
      module: "Marketing Intelligence",
      subModule: "Campaign",
      platform: platform === null ? "Overall" : platform,
      ...(BRAND_ID && { brands: selectedBrands }),
      date: {
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate,
        previousStartDate: selectedDates.prevStartDate,
        previousEndDate: selectedDates.prevEndDate,
      }
    })
  };

  // TO ADD PLATFORMS SEARCH QUERRY IN URL
  useEffect(() => {
    setSearchParams({
      platform: selectedPlatform.toLowerCase().replace(/ /g, ''),
      brands: selectedBrands.length === 0
        ? "overall"
        : [...new Set(selectedBrands.map((item) => item.value))].join(",")
    });
  }, [selectedPlatform]);

  var brandsExport = (function () {
    if (selectedBrands === undefined || selectedBrands.length === 0) {
      return { "Brand": "" }
    } else {
      var obj = {};
      for (var i = 0; i < selectedBrands.length; i++) {
        if (selectedBrands.length === 1) {
          obj["Brand"] = selectedBrands[i].label
        } else {
          obj["Brand " + (i + 1)] = selectedBrands[i].label
        }
      }
      return obj;
    }
  })()


  useEffect(() => {
    setexportSummary([
      selectedDates,
      // ...selectedBrands,
      brandsExport
    ]);

  }, [
    selectedDates,
    selectedBrands,
  ]);

  return (
    <Wrapper>
      <div className="content">
        <div className="content_section">
          {selectedCampaignsFromFilter.length !== 0 && selectedCampaignsFromFilter !== undefined && (
            <div className="header_badges_wrapper">
              <div className="badges_list_wrapper">
                <div className="badge_wrapper">
                  <div className="button_badge">
                    {selectedCampaignPlatformsFromFilter.length} Platforms Selected
                  </div>
                </div>
                <div className="badge_wrapper">
                  <div className="button_badge">
                    {selectedCampaignObjectivesFromFilter.length} Objectives Selected
                  </div>
                </div>
                <div className="badge_wrapper">
                  <div className="button_badge">
                    {selectedCampaignsFromFilter.length} Campaigns Selected
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedAllBucketsFromFilter !== undefined && selectedAllBucketsFromFilter.length !== 0 &&

            <div className="header_badges_wrapper">
              <div className="badges_list_wrapper">
                {selectedAllBucketsFromFilter.map((item) => (
                  <div className="badge_wrapper">
                    <div className="button_badge position-relative" style={{ whiteSpace: 'nowrap' }}>
                      {item.name}
                      {
                        item.description === undefined ||
                          item.description === null ||
                          item.description === '' ? "" :
                          <div className="hover_badge_wrapper section_badge">
                            <Badge hoverDetails={item.description} badgeClasses={'bg_dark_blue'} />
                          </div>
                      }
                    </div>
                  </div>
                ))}
              </div>
            </div>
          }
          <PageHeader pageTitle={"Campaigns"} />
          <div className="page_content">
            <div className="dashboard_home">

              {metricCardsSection}

              {isMetricCardsLoaded && (
                <Wrapper>
                  {selectedPlatform.toLowerCase() === "overall" && <CampaignOverall platformData={metricCards} />}
                  {selectedPlatform.toLowerCase() === "google" && <CampaignGoogle platformData={metricCards} />}
                  {selectedPlatform.toLowerCase() === "facebook" && <CampaignFacebook platformData={metricCards} />}
                  {selectedPlatform.toLowerCase() === "instagram" && <CampaignInstagram platformData={metricCards} />}
                  {selectedPlatform.toLowerCase() === "twitter" && <CampaignTwitter platformData={metricCards} />}
                  {selectedPlatform.toLowerCase() === "linkedin" && <CampaignLinkedin platformData={metricCards} />}
                  {selectedPlatform.toLowerCase() === "dv 360" && <CampaignDv360 platformData={metricCards} />}
                  {selectedPlatform.toLowerCase() === "ott" && <CampaignOTT platformData={metricCards} />}
                  {selectedPlatform.toLowerCase() === "direct buys" && <CampaignOffline platformData={metricCards} />}
                  {selectedPlatform.toLowerCase() === "marketplaces" && <CampaignMarketplace platformData={metricCards} />}
                  {selectedPlatform.toLowerCase() === "bing" && <CampaignBing platformData={metricCards} />}
                  {selectedPlatform.toLowerCase() === "rollworks" && <CampaignRollworks platformData={metricCards} />}

                </Wrapper>
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default Campaign;
