const { createSlice } = require("@reduxjs/toolkit");


const initialState = {
  sidebarData: []
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setSidebarData(state, action) {
      state.sidebarData = action.payload;
    },
    resetSidebar: () => initialState
  },
});

export const { setSidebarData, resetSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
