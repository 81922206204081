import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { SwiperSlide } from 'swiper/react';

// STORAGE 
import AuthContext from '../../store/authContext';
import FullPageExportContext from '../../store/fullPageExportContext';

// COMPONENT
import axios from "../../components/helpers/axios";
import Wrapper from '../../components/helpers/Wrapper'
import ExportDropdown from '../../components/ui/dropdown/ExportDropdown';
import Slider from '../../components/ui/Slider';
import PageHeader from '../../components/layout/pageHeader';
import Card from '../../components/ui/Card';
import SectionDefinationsBadge from '../../components/ui/SectionDefinationsBadge';
import Datatable from '../../components/ui/tables/Datatable';
import Dropdown from '../../components/ui/dropdown/Dropdown';
import CompareMarketsSummary from '../../components/ui/CompareMarketsSummary';

// CHARTS
import IndiaMap from '../../components/ui/map/IndiaMap';
import BubbleChartSizeShapeDynamic from '../../components/ui/charts/BubbleChartSizeShapeDynamic';
import SimpleTreeMap from '../../components/ui/charts/SimpleTreeMap';
import MultiAxisMultiLine from '../../components/ui/charts/MultiAxisMultiLine';
import StackedClusteredBarChart from '../../components/ui/charts/StackedClusteredBarChart';
import MultiAxis from '../../components/ui/charts/MultiAxis';

// LOADERS
import Loader from '../../components/ui/loaders/Loader';
import ServerErrorsLoader from '../../components/ui/loaders/ServerErrosLoader';
import NoDataAvailableLoader from '../../components/ui/loaders/NoDataAvailable';

// UTILS
import ErrorBoundary from '../../utils/ErrorBoundary';
import NoDataAvailableChecker from '../../utils/NoDataAvailableChecker';
import TextFormatter from '../../utils/TextFormatter';
import NumberFormatter from '../../utils/NumberFormatter';
import GrowthConverterFunc from '../../utils/GrowthConverterFunc';
import { BubbleChartSizeShapeDynamicLabels } from '../../utils/Utils';
import LineChart from '../../components/ui/charts/LineChart';
import PartitionedLineChart from '../../components/ui/charts/PartitionedLineChart';
import Modal from '../../components/ui/Modal';
import ExpandIcon from '../../assets/icons/ExpandIcon';
import { COKE_COMPANY_ID } from '../../constants/constants';
import { useParams, useSearchParams } from 'react-router-dom';

export default function Market() {
	// PAGE CONSTANTS
	let pageModule = "Market";
	let pageSubModule = "Market";
	let pagePlatform = "";
	let pageSection = "";
	let pagePrimaryTab = "";

	const authCtx = useContext(AuthContext);
	const { setFullPageExport, setIsExportDisabled, setExportPageName, setexportSummary, exportSummary } = useContext(FullPageExportContext);

	const params = useParams();
	let [searchParams, setSearchParams] = useSearchParams();

	const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
	const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)
	const userData = useSelector((state) => state.userData.user[0]);

	let companyID = userData?.company?.id ?? '';

	// currentDate / endDate / prevCurrentDate / prevEndDate
	const selectedDates = useSelector((state) => state.date);
	const selectedBrands = useSelector((state) => state.globalSelectedBrandIds.selectedBrands);
	const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

	let BRAND_ID = null;
	if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
		BRAND_ID = null;
	} else {
		BRAND_ID = globalSelectedBrandIds.join(",");
	}

	// INDIA MAP
	const [MapData, setMapData] = useState([]);
	const [isMapLoading, setMapIsLoading] = useState(false);
	const [isMapLoaded, setIsMapLoaded] = useState(false);
	const [MapError, setMapError] = useState(null);
	const [centerPoint, setCenterPoint] = useState(["82,22"]);
	const [northPoint, setNorthPoint] = useState(["72.6416015625, 37.71859032558816",]);
	const [southPoint, setSouthPoint] = useState(["93.1640625, 5.7908968128719565",]);
	let zoomLevel = 2.5;
	const [mapLevel, setMapLevel] = useState(0);
	const [mapState, setMapState] = useState(null);
	const [mapStateCode, setMapStateCode] = useState(null);
	const [marketCode, setMarketCode] = useState(null);

	const [mapBreadCrumbList, setMapBreadCrumbList] = useState(["India"]);

	// PERFORMANCE METRIC CARDS
	const [performanceMetricCards, setPerformanceMetricCards] = useState([]);
	const [isPerformanceMetricCardsLoading, setPerformanceMetricCardsIsLoading] = useState(false);
	const [isPerformanceMetricCardsLoaded, setPerformanceMetricCardsIsLoaded] = useState(false);
	const [performanceMetricCardserror, setPerformanceMetricCardsError] = useState(null);

	// MARKET COMPARE : ALL INDIA
	const [isMarketTableHidden, setIsMarketTableHidden] = useState({ class: "hide_rows", text: "View More" })

	const [marketCompareAllIndia, setMarketCompareAllIndia] = useState([]);
	const [marketCompareAllIndiaLabel, setMarketCompareAllIndiaLabel] = useState([]);
	const [marketCompareAllIndiaTable, setMarketCompareAllIndiaTable] = useState([]);
	const [isMarketCompareAllIndiaLoading, setMarketCompareAllIndiaIsLoading] = useState(false);
	const [isMarketCompareAllIndiaLoaded, setMarketCompareAllIndiaIsLoaded] = useState(false);
	const [marketCompareAllIndiaerror, setMarketCompareAllIndiaError] = useState(null);

	const [marketComparisonDropdownOptions, setMarketComparisonDropdownOptions] = useState([])
	const [marketComparisonDropdownOptionsLoading, setMarketComparisonDropdownOptionsLoading] = useState(false)
	const [marketAllIndiaDropdownValue, setMarketAllIndiaDropdownValue] = useState('');

	const marketAllIndiaDropdownHandler = (e) => {
		if (e === null) {
			setMarketAllIndiaDropdownValue("")
		} else {
			setMarketAllIndiaDropdownValue(e)
		}
	};


	// MARKET COMPARE : STATE
	const [marketCompareState, setMarketCompareState] = useState([]);
	const [marketCompareStateLabel, setMarketCompareStateLabel] = useState([]);
	const [marketCompareStateTable, setMarketCompareStateTable] = useState([]);
	const [isMarketCompareStateLoading, setMarketCompareStateIsLoading] = useState(false);
	const [isMarketCompareStateLoaded, setMarketCompareStateIsLoaded] = useState(false);
	const [marketCompareStateerror, setMarketCompareStateError] = useState(null);

	const [marketStateDropdownValue, setMarketStateDropdownValue] = useState("");

	const marketStateDropdownHandler = (e) => {
		if (e === null) {
			setMarketStateDropdownValue("")
		} else {
			setMarketStateDropdownValue(e)
		}
	};

	useEffect(() => {
		const fetchMarketAllIndiaDropdownValue = async () => {
			setMarketComparisonDropdownOptionsLoading(true);

			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/sales/haleon-market-filter-dropdown/", config);
				setMarketComparisonDropdownOptions(res?.data ?? []);

				setMarketStateDropdownValue(res?.data[1] ?? '')
				setMarketAllIndiaDropdownValue(res?.data[0] ?? '')
				setMarketComparisonDropdownOptionsLoading(false);
			} catch {
				setMarketStateDropdownValue("")
				setMarketAllIndiaDropdownValue("")
				setMarketComparisonDropdownOptions([]);
				setMarketComparisonDropdownOptionsLoading(false);
			}
		};
		fetchMarketAllIndiaDropdownValue()
	}, [BRAND_ID, selectedDates, authCtx])


	// PERFORMANCE TREND 
	const [performanceTrend, setPerformanceTrend] = useState([]);
	const [performanceTrendLabel, setPerformanceTrendLabel] = useState([]);
	const [performanceTrendTable, setPerformanceTrendTable] = useState([]);
	const [isPerformanceTrendLoading, setPerformanceTrendIsLoading] = useState(false);
	const [isPerformanceTrendLoaded, setPerformanceTrendIsLoaded] = useState(false);
	const [performanceTrenderror, setPerformanceTrendError] = useState(null);

	const [perfTrendDropdownOptions, setPerfTrendDropdownOptions] = useState([])
	const [perfTrendFirstDropdownValue, setperfTrendFirstDropdownValue] = useState('');

	const perfTrendFirstDropdownHandler = (e) => {
		if (e === null) {
			setperfTrendFirstDropdownValue("")
		} else {
			setperfTrendFirstDropdownValue(e)
		}
	};

	const [perfTrendSecondDropdownValue, setperfTrendSecondDropdownValue] = useState("");

	const perfTrendSecondDropdownHandler = (e) => {
		if (e === null) {
			setperfTrendSecondDropdownValue("")
		} else {
			setperfTrendSecondDropdownValue(e)
		}
	};

	const [perfTrendThirdDropdownValue, setperfTrendThirdDropdownValue] = useState("");

	const perfTrendThirdDropdownHandler = (e) => {
		if (e === null) {
			setperfTrendThirdDropdownValue("")
		} else {
			setperfTrendThirdDropdownValue(e)
		}
	};

	const [perfTrendDropdownOptionsLoading, setPerfTrendDropdownOptionsLoading] = useState(false)
	const [perfTrendDropdownOptionsLoaded, setPerfTrendDropdownOptionsLoaded] = useState(false)



	useEffect(() => {
		const fetchPerfTrendDropdownOptions = async () => {
			setPerfTrendDropdownOptionsLoading(true);
			setPerfTrendDropdownOptionsLoaded(false)
			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/sales/haleon-performance-trend-filter-dropdown/", config);
				setPerfTrendDropdownOptions(res?.data?.data ?? []);

				setperfTrendFirstDropdownValue(res?.data?.data[0] ?? '')
				setperfTrendSecondDropdownValue(res?.data?.data[1] ?? '')
				setperfTrendThirdDropdownValue(res?.data?.data[2] ?? '')

				setPerfTrendDropdownOptionsLoading(false);
				setPerfTrendDropdownOptionsLoaded(true)
			} catch {
				setPerfTrendDropdownOptions([]);

				setperfTrendFirstDropdownValue("")
				setperfTrendSecondDropdownValue("")
				setperfTrendThirdDropdownValue("")
				setPerfTrendDropdownOptionsLoaded(false)
				setPerfTrendDropdownOptionsLoading(false);
			}
		};
		fetchPerfTrendDropdownOptions()
	}, [authCtx, selectedDates, BRAND_ID, mapStateCode, marketCode])


	// TOP BOTTOM PERFORMING MARKETS
	const [topBottomPerformingMarketsData, setTopBottomPerformingMarketsData] = useState([]);
	const [topBottomPerformingMarketsTableData, setTopBottomPerformingMarketsTableData] = useState([]);
	const [topBottomPerformingMarketsTableHeader, setTopBottomPerformingMarketsTableHeader] = useState([]);

	const [topBottomPerformingMarketsLabels, setTopBottomPerformingMarketsLables] = useState([]);
	const [isTopBottomPerformingMarketsLoading, setTopBottomPerformingMarketsIsLoading] = useState(false);
	const [isTopBottomPerformingMarketsLoaded, setTopBottomPerformingMarketsIsLoaded] = useState(false);
	const [topBottomPerformingMarketsError, setTopBottomPerformingMarketsError] = useState(null);

	const TopBottomPerformingMarketsTableHeaderTV = [
		{ name: "Markets", field: "market" },
		{ name: "Monthly Sales Vol.", field: "monthly_sales_vol" },
		{ name: "Monthly Sales Vol. Change (%)", field: "monthy_sales_vol_change" },
		{ name: "TV Spends", field: "tv_spends" },
		{ name: "TV Spends Change (%)", field: "tv_spends_change" },
		{ name: "TV GRP", field: "tv_grp" },
		{ name: "TV GRP Change (%)", field: "tv_grp_change" },
		{ name: "Total Spend (%)", field: "total_spend_percent" },
		{ name: "Total Sales Vol. (%)", field: "total_monthly_sales_vol_percent" },
	]

	const TopBottomPerformingMarketsTableHeaderAllDigital = [
		{ name: "Markets", field: "market" },
		{ name: "Monthly Sales Vol.", field: "monthly_sales_vol" },
		{ name: "Monthly Sales Vol. Change (%)", field: "monthy_sales_vol_change" },
		{ name: "All Digital Impressions", field: "all_digital_impressions" },
		{ name: "All Digital Impressions Change (%)", field: "all_digital_impressions_change" },
		{ name: "All Digital Spends", field: "all_digital_spends" },
		{ name: "All Digital Spends Change (%)", field: "all_digital_spends_change" },
		{ name: "Total Spend (%)", field: "total_spend_percent" },
		{ name: "Total Sales Vol. (%)", field: "total_monthly_sales_vol_percent" },
	]


	const TopBottomPerformingMarketsTableHeaderDigitalMeta = [
		{ name: "Markets", field: "market" },
		{ name: "Monthly Sales Vol.", field: "monthly_sales_vol" },
		{ name: "Monthly Sales Vol. Change (%)", field: "monthy_sales_vol_change" },
		{ name: "Digital Meta Impressions", field: "digital_meta_impressions" },
		{ name: "Digital Meta Impressions Change (%)", field: "digital_meta_impressions_change" },
		{ name: "Digital Meta Spends", field: "digital_meta_spends" },
		{ name: "Digital Meta Spends Change (%)", field: "digital_meta_spends_change" },
		{ name: "Total Spend (%)", field: "total_spend_percent" },
		{ name: "Total Sales Vol. (%)", field: "total_monthly_sales_vol_percent" },
	]


	const TopBottomPerformingMarketsTableHeaderDigitalDV360 = [
		{ name: "Markets", field: "market" },
		{ name: "Monthly Sales Vol.", field: "monthly_sales_vol" },
		{ name: "Monthly Sales Vol. Change (%)", field: "monthy_sales_vol_change" },
		{ name: "Digital DV360 Impressions", field: "digital_dv360_impressions" },
		{ name: "Digital DV360 Impressions Change (%)", field: "digital_dv360_impressions_change" },
		{ name: "Digital DV360 Spends", field: "digital_dv360_spends" },
		{ name: "Digital DV360 Spends Change (%)", field: "digital_dv360_spends_change" },
		{ name: "Total Spend (%)", field: "total_spend_percent" },
		{ name: "Total Sales Vol. (%)", field: "total_monthly_sales_vol_percent" },
	]

	const TopBottomPerformingMarketsTableHeaderDigitalTvDigital = [
		{ name: "Markets", field: "market" },
		{ name: "Monthly Sales Vol.", field: "monthly_sales_vol" },
		{ name: "Monthly Sales Vol. Change (%)", field: "monthy_sales_vol_change" },
		{ name: "TV + Digital Impressions", field: "tv_digital_impressions" },
		{ name: "TV + Digital Impressions Change (%)", field: "tv_digital_impressions_change" },
		{ name: "TV + Digital Spends", field: "tv_digital_spends" },
		{ name: "TV + Digital Spends Change (%)", field: "tv_digital_spends_change" },
		{ name: "Total Spend (%)", field: "total_spend_percent" },
		{ name: "Total Sales Vol. (%)", field: "total_monthly_sales_vol_percent" },
	];

	const [topBottomPerformDataDropdownOptions, setTopBottomPerformDataDropdownOptions] = useState([])
	const [topBottomPerformDataDropdownOptionsLoading, setTopBottomPerformDataDropdownOptionsLoading] = useState(false);
	const [topBottomPerformDataDropdownOptionsLoaded, setTopBottomPerformDataDropdownOptionsLoaded] = useState(false);


	const [topBottomPerformDataDropdownValue, settopBottomPerformDataDropdownValue] = useState("");

	const topBottomPerformDataDropdownHandler = (e) => {
		if (e === null) {
			settopBottomPerformDataDropdownValue("")
		} else {
			settopBottomPerformDataDropdownValue(e)
		}
	};


	useEffect(() => {
		const fetchTopBottomPerformingMarketDropdown = async () => {
			setTopBottomPerformDataDropdownOptionsLoading(true);
			setTopBottomPerformDataDropdownOptionsLoaded(false)

			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/sales/haleon-top-bottom-performing-market-filter-dropdown/", config);
				setTopBottomPerformDataDropdownOptions(res?.data?.data ?? []);

				settopBottomPerformDataDropdownValue(res?.data?.data[0] ?? '')
				setTopBottomPerformDataDropdownOptionsLoading(false);
				setTopBottomPerformDataDropdownOptionsLoaded(true)
			} catch {
				settopBottomPerformDataDropdownValue("")
				setTopBottomPerformDataDropdownOptions([]);
				setTopBottomPerformDataDropdownOptionsLoading(false);
				setTopBottomPerformDataDropdownOptionsLoaded(false)

			}
		};
		fetchTopBottomPerformingMarketDropdown()
	}, [BRAND_ID, selectedDates, authCtx])


	useEffect(() => {

		const fetchTopBottomPerformingMarketTableHeader = () => {
			if (topBottomPerformDataDropdownValue.value === "all_digital") {
				setTopBottomPerformingMarketsTableHeader(TopBottomPerformingMarketsTableHeaderAllDigital);
			} else if (topBottomPerformDataDropdownValue.value === "tv") {
				setTopBottomPerformingMarketsTableHeader(TopBottomPerformingMarketsTableHeaderTV)
			} else if (topBottomPerformDataDropdownValue.value === "digital_meta") {
				setTopBottomPerformingMarketsTableHeader(TopBottomPerformingMarketsTableHeaderDigitalMeta)
			} else if (topBottomPerformDataDropdownValue.value === "digital_dv360") {
				setTopBottomPerformingMarketsTableHeader(TopBottomPerformingMarketsTableHeaderDigitalDV360)
			} else if (topBottomPerformDataDropdownValue.value === "tv_digital") {
				setTopBottomPerformingMarketsTableHeader(TopBottomPerformingMarketsTableHeaderDigitalTvDigital)
			} else {
				setTopBottomPerformingMarketsTableHeader([])
			}
		}

		fetchTopBottomPerformingMarketTableHeader()

	}, [topBottomPerformDataDropdownValue])



	// BRANDWISE PERFORMANCE
	const [marketCampaignDeepdive, setMarketCampaignDeepdive] = useState([]);
	const [isMarketCampaignDeepdiveLoading, setMarketCampaignDeepdiveIsLoading] = useState(false);
	const [isMarketCampaignDeepdiveLoaded, setMarketCampaignDeepdiveIsLoaded] = useState(false);
	const [marketCampaignDeepdiveerror, setMarketCampaignDeepdiveError] = useState(null);

	// OVERALL MEDIA PERFORMANCE
	const [overallMediaPerformance, setOverallMediaPerformance] = useState([]);
	const [overallMediaPerformanceLabel, setOverallMediaPerformanceLabel] = useState([]);
	const [overallMediaPerformanceTableData, setOverallMediaPerformanceTableData] = useState([]);

	const [isOverallMediaPerformanceLoading, setOverallMediaPerformanceIsLoading] = useState(false);
	const [isOverallMediaPerformanceLoaded, setOverallMediaPerformanceIsLoaded] = useState(false);
	const [overallMediaPerformanceerror, setOverallMediaPerformanceError] = useState(null);


	//   BRANDWISE PERFORMANCE - TREE MAP HANDLER
	let treemapLevel = 1;
	const [treemapBrand, setTreemapBrand] = useState("");
	const [treemapStateCode, setTreemapStateCode] = useState("");

	const [treemapBrandId, setTreemapBrandId] = useState("");
	const [treemapPlatform, setTreemapPlatform] = useState("");
	const [treemapParams, setTreemapParams] = useState({});

	useEffect(() => {
		if (marketCode === undefined || marketCode === null) {
			setTreemapParams({
				brand_id: BRAND_ID,
				start_date: selectedDates.startDate,
				end_date: selectedDates.endDate,
				previous_start_date: selectedDates.prevStartDate,
				previous_end_date: selectedDates.prevEndDate,
				level: 1,
			})
			setTreemapBrand("");
			setTreemapStateCode('')
			setTreemapPlatform("");
		} else {
			setTreemapParams({
				brand_id: BRAND_ID,
				start_date: selectedDates.startDate,
				end_date: selectedDates.endDate,
				previous_start_date: selectedDates.prevStartDate,
				previous_end_date: selectedDates.prevEndDate,
				market_code: marketCode,
				level: 1,
			})
			setTreemapBrand("");
			setTreemapStateCode('')
			setTreemapPlatform("");

		}
	}, [BRAND_ID, marketCode, selectedDates])



	const chartlevelHandler = (data) => {
		if (data.level === 1) {
			setTreemapParams({
				brand_id: BRAND_ID,
				start_date: selectedDates.startDate,
				end_date: selectedDates.endDate,
				previous_start_date: selectedDates.prevStartDate,
				previous_end_date: selectedDates.prevEndDate,
				market_code: marketCode === undefined || marketCode === null ? data.market_code : marketCode,
				state_code: mapStateCode,
				level: 2,
			});
			setTreemapBrand(data.market_code);
			setTreemapStateCode(data.name)
		} else if (data.level === 2) {
			setTreemapParams({
				brand_id: BRAND_ID,
				start_date: selectedDates.startDate,
				end_date: selectedDates.endDate,
				previous_start_date: selectedDates.prevStartDate,
				previous_end_date: selectedDates.prevEndDate,
				level: 3,
				platform: data.name,
				market_code: marketCode === undefined || marketCode === null ? treemapBrand : marketCode,
				state_code: mapStateCode,
			});
			setTreemapPlatform(data.name)
		} else {
			alert("No further drill down available");
		}
	};


	// PERFORMANCE BY SOURCE
	const [brandPerfOverview, setBrandPerfOverview] = useState([]);
	const [brandPerfOverviewDetails, setBrandPerfOverviewDetails] = useState([]);
	const [brandPerfOverviewDataLabels, setBrandPerfOverviewDataLabels] = useState([]);
	const [isBrandPerfOverviewLoading, setBrandPerfOverviewIsLoading] = useState(false);
	const [isBrandPerfOverviewLoaded, setBrandPerfOverviewIsLoaded] = useState(false);
	const [brandPerfOverviewerror, setBrandPerfOverviewError] = useState(null);

	const [brandPerfOverviewFirstDropdownValue, setbrandPerfOverviewFirstDropdownValue] = useState('');

	const brandPerfOverviewFirstDropdownHandler = (e) => {
		if (e === null) {
			setbrandPerfOverviewFirstDropdownValue("")
		} else {
			setbrandPerfOverviewFirstDropdownValue(e)
		}
	};

	const [brandPerfOverviewDropdownOptions, setBrandPerfOverviewDropdownOptions] = useState([])
	const [brandPerfOverviewDropdownOptionsLoading, setBrandPerfOverviewDropdownOptionsLoading] = useState(false)
	const [brandPerfOverviewDropdownOptionsLoaded, setBrandPerfOverviewDropdownOptionsLoaded] = useState(false)

	const [brandPerfOverviewSecondDropdownValue, setbrandPerfOverviewSecondDropdownValue] = useState('');

	const brandPerfOverviewSecondDropdownHandler = (e) => {
		if (e === null) {
			setbrandPerfOverviewSecondDropdownValue("")
		} else {
			setbrandPerfOverviewSecondDropdownValue(e)
		}
	};


	useEffect(() => {
		const fetchBrandPerfOverviewDropdownHandler = async () => {
			setBrandPerfOverviewDropdownOptionsLoading(true);
			setBrandPerfOverviewDropdownOptionsLoaded(false)
			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/sales/haleon-brand-performance-overview-filter-dropdown/", config);
				setBrandPerfOverviewDropdownOptions(res?.data?.data ?? []);

				setbrandPerfOverviewFirstDropdownValue(res?.data?.data[0] ?? '')
				setbrandPerfOverviewSecondDropdownValue(res?.data?.data[1] ?? '')
				setBrandPerfOverviewDropdownOptionsLoading(false);
				setBrandPerfOverviewDropdownOptionsLoaded(true)

			} catch {
				setbrandPerfOverviewFirstDropdownValue("")
				setbrandPerfOverviewSecondDropdownValue("")
				setBrandPerfOverviewDropdownOptions([]);
				setBrandPerfOverviewDropdownOptionsLoading(false);
				setBrandPerfOverviewDropdownOptionsLoaded(false)

			}
		};
		fetchBrandPerfOverviewDropdownHandler()
	}, [BRAND_ID, selectedDates, authCtx])

	// TOP POST
	const [topPosts, setTopPosts] = useState([]);
	const [topPostsLoading, settopPostsLoading] = useState(false);
	const [topPostsLoaded, setTopPostsloaded] = useState(false);
	const [topPostserror, setTopPostsError] = useState(null);

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// INDIA MAP
	useEffect(() => {
		const fetchMapDataHandler = async () => {
			setMapIsLoading(true);
			setIsMapLoaded(false);
			setMapError(null);

			const config = {
				params: {
					brand_id: BRAND_ID,
					market_code: marketCode,
					state_code: mapStateCode,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/sales/haleon-map-data/", config);

				setMapData(res?.data ?? []);
				setMapIsLoading(false);
				setIsMapLoaded(true);
				setMapError(null);
			} catch (error) {
				setMapIsLoading(false);
				setIsMapLoaded(false);
				setMapError(error.code);
			}
		};

		fetchMapDataHandler();
	}, [
		authCtx,
		BRAND_ID,
		selectedDates,
		marketCode,
		mapStateCode,
	]);

	// INDIA MAP
	let IndiaMapChart = <Wrapper><Loader loaderType="indiaMapLoader" /></Wrapper>;

	// IF DATA IS LOADED
	if (isMapLoaded && !isMapLoading) {
		if (NoDataAvailableChecker(MapData)) {
			IndiaMapChart = <Wrapper><NoDataAvailableLoader chartType="indiaChartType" /></Wrapper>;
		} else {
			IndiaMapChart = (
				<Wrapper>
					<ErrorBoundary chartType="indiaChartType">
						<IndiaMap
							showBreadcrumb={true}
							geoJson={MapData}
							northPoint={northPoint}
							southPoint={southPoint}
							centerPoint={centerPoint}
							zoomLevel={zoomLevel}
							mapLevel={mapLevel}
							onChange={handleIndiaMapChange}
							growthDegrowth={"sales_volume"}
							toolTipValues={["sales_volume", "tv_spends", "tv_grp", "tv_sov", "digital_impressions", "digital_spends"]}
							toolTipLabels={["Sales Vol.", "TV Spends", "TV GRP", "TV SOV%", "Digital Impressions", "Digital Spends"]}
						/>
					</ErrorBoundary>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (MapError) {
		IndiaMapChart = (
			<Wrapper><ServerErrorsLoader chartType="indiaChartType" error={MapError} /></Wrapper>
		);
	}

	// IF DATA LOADING
	if (isMapLoading) {
		IndiaMapChart = <Wrapper><Loader loaderType="indiaMapLoader" /></Wrapper>;
	}

	function mapBreadcrumbsHandler(index, value) {

		if (index === 0) {
			let a = ["INDIA"];
			setMapBreadCrumbList(a);
			setMapLevel(0);
			// setMapZone(null);
			setMapState();
			setMarketCode()
			setMapStateCode();
			setCenterPoint(["82,22"]);
			setNorthPoint(["72.6416015625, 37.71859032558816"]);
			setSouthPoint(["93.1640625, 5.7908968128719565"]);
		}
		if (index === 1) {
			let a = ["INDIA", value];
			setMapBreadCrumbList(a);
			setMapLevel(1);
			setMapState();
			setMapStateCode();
		}
	}

	async function handleIndiaMapChange(value) {
		setMapLevel(value[0] + 1);
		setCenterPoint(value[1]);
		setNorthPoint(value[2]);
		setSouthPoint(value[3]);
		setMarketCode(value[7]);


		if (value[0] === 1) {
			setMapState(value[5]);
			setMapStateCode(value[6]);
			setMapBreadCrumbList(["INDIA", value[8], value[5]]);
		} else {
			setMapBreadCrumbList(["INDIA", value[8]]);
		}
	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// PERFORMANCE METRIC CARDS

	// TO FETCH PERFORMANCE METRIC CARDS FROM API

	useEffect(() => {

		const fetchPerformanceMetricCardsHandler = async () => {
			setPerformanceMetricCardsIsLoading(true);
			setPerformanceMetricCardsIsLoaded(false);
			setPerformanceMetricCardsError(null);

			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					market_code: marketCode,
					state_code: mapStateCode,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/sales/haleon-metric-card/", config);

				setPerformanceMetricCards(res?.data?.data ?? []);
				setPerformanceMetricCardsIsLoading(false);
				setPerformanceMetricCardsIsLoaded(true);
				setPerformanceMetricCardsError(null);
			} catch (error) {
				setPerformanceMetricCards([]);
				setPerformanceMetricCardsIsLoading(false);
				setPerformanceMetricCardsIsLoaded(false);
				setPerformanceMetricCardsError(error.code);
			}
		};

		fetchPerformanceMetricCardsHandler()
	}, [
		authCtx,
		BRAND_ID,
		selectedDates,
		marketCode,
		mapStateCode,
	])

	// OVERVIEW METRICS CARDS SLIDER DATA HANDLING
	let performanceMetricCardsSection = (
		<Wrapper>
			<div className="grid grid_cols_4 grid_margin_bottom">
				<div className="grid col_span_4 section_header">
					<div className="section_info">
						<h2 className="section_card_title">Performance Metrics
						</h2>
					</div>
				</div>
				<div className="grid col_span_4 ">
					<Loader loaderType="cardLoader" />
				</div>
			</div>
		</Wrapper>
	);

	// IF DATA IS LOADED
	if (isPerformanceMetricCardsLoaded && !isPerformanceMetricCardsLoading) {
		if (NoDataAvailableChecker(performanceMetricCards)) {
			performanceMetricCardsSection = (
				<Wrapper>
					<div className="grid grid_cols_4 grid_margin_bottom">
						<div className="grid col_span_4 section_header">
							<div className="section_info">
								<h2 className="section_card_title">Performance Metrics
								</h2>
							</div>
						</div>
						<div className="grid col_span_4  ">
							<NoDataAvailableLoader chartType="cardChartType" />
						</div>
					</div>
				</Wrapper>
			);
		} else {
			performanceMetricCardsSection = (
				<Wrapper>
					<ErrorBoundary chartType="cardChartType">
						<Slider sliderTitle={"Performance Metrics"}>
							{performanceMetricCards.map((data, index) => (
								<SwiperSlide key={index}>
									<Card
										module="tv"
										platform="tv_overall"
										section="tv_overall"
										key={index}
										variant={"chartCard"}
										cardTitle={data.label}
										currentValue={data.currentValue}
										previousValue={data.previousValue}
										chartType={"Line"}
										chartData={
											NoDataAvailableChecker(data.subData)
												? []
												: NoDataAvailableChecker(data.subData.data)
													? []
													: data.subData.data
										}
										chartDataLabels={
											NoDataAvailableChecker(data.subData.dimension)
												? []
												: NoDataAvailableChecker(data.subData.dimension)
													? []
													: data.subData.dimension
										}
									/>
								</SwiperSlide>
							))}
						</Slider>
					</ErrorBoundary>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (performanceMetricCardserror) {
		performanceMetricCardsSection = (
			<Wrapper>
				<div className="grid grid_cols_4 grid_margin_bottom">
					<div className="grid col_span_4 section_header">
						<div className="section_info">
							<h2 className="section_card_title">Performance Metrics</h2>
						</div>
					</div>
					<div className="grid col_span_4  ">
						<ServerErrorsLoader chartType="cardChartType" error={performanceMetricCardserror} />
					</div>
				</div>
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (isPerformanceMetricCardsLoading) {
		performanceMetricCardsSection = (
			<Wrapper>
				<div className="grid grid_cols_4 grid_margin_bottom">
					<div className="grid col_span_4 section_header">
						<div className="section_info">
							<h2 className="section_card_title">Performance Metrics
							</h2>
						</div>
					</div>
					<div className="grid col_span_4  ">
						<Loader loaderType="cardLoader" />
					</div>
				</div>
			</Wrapper>
		);
	}
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// MARKET COMPARISON : ALL INDIA

	let defaultcompareAllIndiaDisabledvalues = [];

	if (showAllLegendsinMultiLineCluster) {
		defaultcompareAllIndiaDisabledvalues = []
	} else {
		defaultcompareAllIndiaDisabledvalues = []
	}

	const [isCompareAllIndiaOpen, setIsCompareAllIndiaOpen] = useState(false)
	const [compareAllIndiaDisabledvalues, setCompareAllIndiaDisabledvalues] = useState(defaultcompareAllIndiaDisabledvalues)

	useEffect(() => {
		const fetchMarketCompareAllIndiaHandler = async () => {
			setMarketCompareAllIndiaIsLoading(true);
			setMarketCompareAllIndiaIsLoaded(false);
			setMarketCompareAllIndiaError(null);

			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					market_state_code: marketAllIndiaDropdownValue.value
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/sales/haleon-market-comparison/", config);

				setMarketCompareAllIndiaTable(res?.data?.table_data ?? []);
				setMarketCompareAllIndia(res?.data?.chart_data?.data ?? []);
				setMarketCompareAllIndiaLabel(res?.data?.chart_data?.dimension ?? []);
				setMarketCompareAllIndiaIsLoading(false);
				setMarketCompareAllIndiaIsLoaded(true);
				setMarketCompareAllIndiaError(null);
			} catch (error) {
				setMarketCompareAllIndiaTable([]);
				setMarketCompareAllIndia([]);
				setMarketCompareAllIndiaLabel([]);
				setMarketCompareAllIndiaIsLoading(false);
				setMarketCompareAllIndiaIsLoaded(false);
				setMarketCompareAllIndiaError(error.code);
			}
		};
		setIsMarketTableHidden({ text: "View More", class: "hide_rows" });
		(marketAllIndiaDropdownValue !== undefined && marketAllIndiaDropdownValue !== null && marketAllIndiaDropdownValue !== "" && typeof marketAllIndiaDropdownValue !== "string") && fetchMarketCompareAllIndiaHandler()
	}, [
		authCtx,
		BRAND_ID,
		selectedDates,
		marketAllIndiaDropdownValue
	])

	let marketCompareAllIndiaSection = <Wrapper><Loader loaderType="lineChartLoader" /></Wrapper>;
	let marketCompareAllIndiaSection2 = <Wrapper><Loader loaderType="lineChartLoader" /></Wrapper>;
	let marketCompareAllIndiaTableSection = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>;

	// IF DATA IS LOADED
	if (isMarketCompareAllIndiaLoaded && !isMarketCompareAllIndiaLoading) {
		if (NoDataAvailableChecker(marketCompareAllIndia) || NoDataAvailableChecker(marketCompareAllIndiaLabel)) {
			marketCompareAllIndiaSection = (<Wrapper><NoDataAvailableLoader chartType="multiAxisMultiLineChartLoader" /></Wrapper>);
			marketCompareAllIndiaSection2 = (<Wrapper><NoDataAvailableLoader chartType="multiAxisMultiLineChartLoader" /></Wrapper>);
			marketCompareAllIndiaTableSection = (<Wrapper><NoDataAvailableLoader chartType="tableChartType" /></Wrapper>);

		} else {
			if (isMultiLineCluster) {
				marketCompareAllIndiaSection = (
					<Wrapper>
						<ErrorBoundary chartType="lineChartType">
							<LineChart
								chartClass="section_card_chart"
								chartData={marketCompareAllIndia}
								chartId="marketCompareAllIndia"
								chartLabels={marketCompareAllIndiaLabel}
								showLegend={true}
								disabledLegendArray={compareAllIndiaDisabledvalues}
								setDisabledValues={setCompareAllIndiaDisabledvalues}
							/>
						</ErrorBoundary>
					</Wrapper>
				);
				marketCompareAllIndiaSection2 = (
					<Wrapper>
						<ErrorBoundary chartType="lineChartType">
							<PartitionedLineChart
								chartClass="section_card_chart"
								chartData={marketCompareAllIndia}
								chartId="marketCompareAllIndia_2"
								chartLabels={marketCompareAllIndiaLabel}
								showLegend={true}
								disabledLegendArray={compareAllIndiaDisabledvalues}
								setDisabledValues={setCompareAllIndiaDisabledvalues}
							/>
						</ErrorBoundary>
					</Wrapper>
				);
			} else {
				marketCompareAllIndiaSection = (
					<Wrapper>
						<ErrorBoundary chartType="lineChartType">
							<MultiAxisMultiLine
								chartClass="section_card_chart"
								chartData={marketCompareAllIndia}
								chartId="marketCompareAllIndia"
								chartLabels={marketCompareAllIndiaLabel}
								showLegend={true}
							/>
						</ErrorBoundary>
					</Wrapper>
				);
			}




			let len = marketCompareAllIndiaTable === undefined || marketCompareAllIndiaTable.length === 0 ? [] : marketCompareAllIndiaTable.length;
			var marketAllIndiaArr = []


			for (var i = 0; i <= len; i++) {
				if (marketAllIndiaArr === undefined) break;
				if (i % 2 === 0) {
					var obj = {}


					if (marketCompareAllIndiaTable[i] !== undefined) {
						obj["label"] = marketCompareAllIndiaTable[i].label
						obj["value"] = marketCompareAllIndiaTable[i].value
					} else {
						obj["label"] = ''
						obj["value"] = ""
					}

					if (marketCompareAllIndiaTable[i + 1] !== undefined && marketCompareAllIndiaTable[i] !== undefined) {
						obj["label1"] = marketCompareAllIndiaTable[i + 1].label
						obj["value1"] = marketCompareAllIndiaTable[i + 1].value
					} else if (marketCompareAllIndiaTable[i + 1] === undefined && marketCompareAllIndiaTable[i] !== undefined) {
						obj["label"] = marketCompareAllIndiaTable[i].label
						obj["value"] = marketCompareAllIndiaTable[i].value
						obj["label1"] = ""
						obj["value1"] = ""
					} else {
						obj["label"] = ""
						obj["value"] = ''
						obj["label1"] = ""
						obj["value1"] = ""
					}

					marketAllIndiaArr.push(obj)
				}
			}

			marketCompareAllIndiaTableSection = (
				<Wrapper>
					<table className={`market_compare_table ${isMarketTableHidden.class}`} id='market_compare_india' >
						<tbody>
							{marketAllIndiaArr && marketAllIndiaArr.map((item, itemIndex) => {
								return (
									<Wrapper key={itemIndex}>
										<tr>
											<td>
												<div className='market_compare_label'>
													{item.label}
												</div>
												<div className='market_compare_value'>
													<ErrorBoundary>
														<NumberFormatter number={item.value} title={item.label} />
													</ErrorBoundary>
												</div>
											</td>
											<td>
												<div className='market_compare_label'>
													{item.label1}
												</div>
												<div className='market_compare_value'>
													<ErrorBoundary>
														<NumberFormatter number={item.value1} title={item.label1} />
													</ErrorBoundary>
												</div>
											</td>
										</tr>
									</Wrapper>
								)
							})}
						</tbody>
					</table>
				</Wrapper>
			)
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (marketCompareAllIndiaerror) {
		marketCompareAllIndiaSection = (
			<Wrapper>
				<ServerErrorsLoader
					chartType="lineChartType"
					error={marketCompareAllIndiaerror}
				/>
			</Wrapper>
		);
		marketCompareAllIndiaSection2 = (
			<Wrapper>
				<ServerErrorsLoader
					chartType="lineChartType"
					error={marketCompareAllIndiaerror}
				/>
			</Wrapper>
		);
		marketCompareAllIndiaTableSection = <Wrapper><ServerErrorsLoader chartType="tableChartType" error={marketCompareAllIndiaerror} /></Wrapper>;

	}

	// IF DATA LOADING
	if (isMarketCompareAllIndiaLoading) {
		marketCompareAllIndiaSection = (<Wrapper><Loader loaderType="multiAxisMultiLineChartLoader" /></Wrapper>);
		marketCompareAllIndiaSection2 = (<Wrapper><Loader loaderType="multiAxisMultiLineChartLoader" /></Wrapper>);
		marketCompareAllIndiaTableSection = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>;

	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// MARKET COMPARISON : STATE

	let defaultcompareStateDisabledvalues = [];

	if (showAllLegendsinMultiLineCluster) {
		defaultcompareStateDisabledvalues = []
	} else {
		defaultcompareStateDisabledvalues = []
	}

	const [isCompareStateOpen, setIsCompareStateOpen] = useState(false)
	const [compareStateDisabledvalues, setCompareStateDisabledvalues] = useState(defaultcompareStateDisabledvalues)

	useEffect(() => {
		const fetchMarketCompareStateHandler = async () => {
			setMarketCompareStateIsLoading(true);
			setMarketCompareStateIsLoaded(false);
			setMarketCompareStateError(null);

			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					market_state_code: marketStateDropdownValue.value
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/sales/haleon-market-comparison/", config);

				setMarketCompareState(res?.data?.chart_data?.data ?? []);
				setMarketCompareStateLabel(res?.data?.chart_data?.dimension ?? []);
				setMarketCompareStateTable(res?.data?.table_data ?? []);
				setMarketCompareStateIsLoading(false);
				setMarketCompareStateIsLoaded(true);
				setMarketCompareStateError(null);
			} catch (error) {
				setMarketCompareState([]);
				setMarketCompareStateLabel([]);
				setMarketCompareStateTable([]);
				setMarketCompareStateIsLoading(false);
				setMarketCompareStateIsLoaded(false);
				setMarketCompareStateError(error.code);
			}
		};
		(marketStateDropdownValue !== undefined && marketStateDropdownValue !== null && marketStateDropdownValue !== "" && typeof marketStateDropdownValue !== "string") && fetchMarketCompareStateHandler()
	}, [
		authCtx,
		BRAND_ID,
		selectedDates,
		marketStateDropdownValue
	])

	let marketCompareStateSection = <Wrapper><Loader loaderType="lineChartLoader" /></Wrapper>;
	let marketCompareStateSection2 = <Wrapper><Loader loaderType="lineChartLoader" /></Wrapper>;
	let marketCompareStateTableSection = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>;

	// IF DATA IS LOADED
	if (isMarketCompareStateLoaded && !isMarketCompareStateLoading) {
		if (NoDataAvailableChecker(marketCompareState) || NoDataAvailableChecker(marketCompareStateLabel)) {
			marketCompareStateSection = (<Wrapper><NoDataAvailableLoader chartType="multiAxisMultiLineChartLoader" /></Wrapper>);
			marketCompareStateSection2 = (<Wrapper><NoDataAvailableLoader chartType="multiAxisMultiLineChartLoader" /></Wrapper>);
			marketCompareStateTableSection = (<Wrapper><NoDataAvailableLoader chartType="tableChartType" /></Wrapper>);

		} else {

			if (isMultiLineCluster) {
				marketCompareStateSection = (
					<Wrapper>
						<ErrorBoundary chartType="lineChartType">
							<LineChart
								chartClass="section_card_chart"
								chartData={marketCompareState}
								chartId="marketCompareState"
								chartLabels={marketCompareStateLabel}
								showLegend={true}
								disabledLegendArray={compareStateDisabledvalues}
								setDisabledValues={setCompareStateDisabledvalues}
							/>
						</ErrorBoundary>
					</Wrapper>
				);

				marketCompareStateSection2 = (
					<Wrapper>
						<ErrorBoundary chartType="lineChartType">
							<PartitionedLineChart
								chartClass="section_card_chart"
								chartData={marketCompareState}
								chartId="marketCompareState_2"
								chartLabels={marketCompareStateLabel}
								showLegend={true}
								disabledLegendArray={compareStateDisabledvalues}
								setDisabledValues={setCompareStateDisabledvalues}
							/>
						</ErrorBoundary>
					</Wrapper>
				);
			} else {
				marketCompareStateSection = (
					<Wrapper>
						<ErrorBoundary chartType="lineChartType">
							<MultiAxisMultiLine
								chartClass="section_card_chart"
								chartData={marketCompareState}
								chartId="marketCompareState"
								chartLabels={marketCompareStateLabel}
								showLegend={true}
							/>
						</ErrorBoundary>
					</Wrapper>
				);
			}

			let len = marketCompareStateTable === undefined || marketCompareStateTable.length === 0 ? [] : marketCompareStateTable.length;
			var marketStateArr = []

			for (var i = 0; i <= len; i++) {
				if (marketStateArr === undefined) break;
				if (i % 2 === 0) {
					var obj = {}


					if (marketCompareStateTable[i] !== undefined) {
						obj["label"] = marketCompareStateTable[i].label
						obj["value"] = marketCompareStateTable[i].value
					} else {
						obj["label"] = ''
						obj["value"] = ""
					}

					if (marketCompareStateTable[i + 1] !== undefined && marketCompareStateTable[i] !== undefined) {
						obj["label1"] = marketCompareStateTable[i + 1].label
						obj["value1"] = marketCompareStateTable[i + 1].value
					} else if (marketCompareStateTable[i + 1] === undefined && marketCompareStateTable[i] !== undefined) {
						obj["label"] = marketCompareStateTable[i].label
						obj["value"] = marketCompareStateTable[i].value
						obj["label1"] = ""
						obj["value1"] = ""
					} else {
						obj["label"] = ""
						obj["value"] = ''
						obj["label1"] = ""
						obj["value1"] = ""
					}

					marketStateArr.push(obj)
				}
			}

			marketCompareStateTableSection = (
				<Wrapper>
					<ErrorBoundary>
						<table className={`market_compare_table ${isMarketTableHidden.class}`} id='market_compare_state'>
							<tbody>
								{marketStateArr && marketStateArr.map((item, itemIndex) => {
									return (
										<Wrapper key={itemIndex}>
											<tr>
												<td>
													<div className='market_compare_label'>
														{item.label}
													</div>
													<div className='market_compare_value'>
														<ErrorBoundary>
															<NumberFormatter number={item.value} title={item.label} />
														</ErrorBoundary>
													</div>
												</td>
												<td>
													<div className='market_compare_label'>
														{item.label1}
													</div>
													<div className='market_compare_value'>
														<ErrorBoundary>
															<NumberFormatter number={item.value1} title={item.label1} />
														</ErrorBoundary>
													</div>
												</td>
											</tr>
										</Wrapper>)
								})}
							</tbody>
						</table>
					</ErrorBoundary>
				</Wrapper>
			)
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (marketCompareStateerror) {
		marketCompareStateSection = (
			<Wrapper>
				<ServerErrorsLoader
					chartType="lineChartType"
					error={marketCompareStateerror}
				/>
			</Wrapper>
		);
		marketCompareStateSection2 = (
			<Wrapper>
				<ServerErrorsLoader
					chartType="lineChartType"
					error={marketCompareStateerror}
				/>
			</Wrapper>
		);
		marketCompareStateTableSection = <Wrapper><ServerErrorsLoader chartType="tableChartType" error={marketCompareStateerror} /></Wrapper>;

	}

	// IF DATA LOADING
	if (isMarketCompareStateLoading) {
		marketCompareStateSection = (<Wrapper><Loader loaderType="multiAxisMultiLineChartLoader" /></Wrapper>);
		marketCompareStateSection2 = (<Wrapper><Loader loaderType="multiAxisMultiLineChartLoader" /></Wrapper>);
		marketCompareStateTableSection = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>;

	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// PERFORMANCE TREND
	let defaultPerformanceTrendDisabledvalues = [];

	if (showAllLegendsinMultiLineCluster) {
		defaultPerformanceTrendDisabledvalues = []
	} else {
		defaultPerformanceTrendDisabledvalues = []
	}
	const [isPerformanceTrendOpen, setIsPerformanceTrendOpen] = useState(false)
	const [performanceTrendDisabledvalues, setPerformanceTrendDisabledvalues] = useState(defaultPerformanceTrendDisabledvalues)


	useEffect(() => {
		const fetchPerformanceTrendHandler = async () => {
			setPerformanceTrendIsLoading(true);
			setPerformanceTrendIsLoaded(false);
			setPerformanceTrendError(null);

			var dropdown2 = perfTrendSecondDropdownValue === undefined ? "" : perfTrendSecondDropdownValue.value === undefined ? perfTrendDropdownOptions[1].value : perfTrendSecondDropdownValue.value
			var dropdown1 = perfTrendFirstDropdownValue === undefined ? "" : perfTrendFirstDropdownValue.value === undefined ? perfTrendDropdownOptions[0].value : perfTrendFirstDropdownValue.value
			var dropdown3 = perfTrendThirdDropdownValue === undefined ? "" : perfTrendThirdDropdownValue.value === undefined ? perfTrendDropdownOptions[2].value : perfTrendThirdDropdownValue.value
			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					market_code: marketCode,
					state_code: mapStateCode,
					performance_filter: `${dropdown1},${dropdown2},${dropdown3}`
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/sales/haleon-trend-line/", config);

				setPerformanceTrend(res?.data?.data ?? []);
				setPerformanceTrendLabel(res?.data?.dimension ?? []);
				setPerformanceTrendTable(res?.data?.table_data ?? [])
				setPerformanceTrendIsLoading(false);
				setPerformanceTrendIsLoaded(true);
				setPerformanceTrendError(null);
			} catch (error) {
				setPerformanceTrend([]);
				setPerformanceTrendLabel([]);
				setPerformanceTrendTable([])
				setPerformanceTrendIsLoading(false);
				setPerformanceTrendIsLoaded(false);
				setPerformanceTrendError(error.code);
			}
		};
		(perfTrendDropdownOptionsLoaded === true) && fetchPerformanceTrendHandler()
	}, [
		authCtx,
		BRAND_ID,
		selectedDates,
		marketCode,
		mapStateCode,
		perfTrendDropdownOptions,
		perfTrendFirstDropdownValue,
		perfTrendSecondDropdownValue,
		perfTrendThirdDropdownValue,
		perfTrendDropdownOptionsLoaded
	])

	let performanceTrendSection = <Wrapper><Loader loaderType="multiAxisMultiLineChartLoader" /></Wrapper>;
	let performanceTrendTableSection = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>;
	let performanceTrendSection2 = <Wrapper><Loader loaderType="multiAxisMultiLineChartLoader" /></Wrapper>;

	// IF DATA IS LOADED
	if (isPerformanceTrendLoaded && !isPerformanceTrendLoading) {
		if (NoDataAvailableChecker(performanceTrend) || NoDataAvailableChecker(performanceTrendLabel)) {
			performanceTrendSection = (<Wrapper><NoDataAvailableLoader chartType="multiAxisMultiLineChartLoader" /></Wrapper>);
			performanceTrendSection2 = (<Wrapper><NoDataAvailableLoader chartType="multiAxisMultiLineChartLoader" /></Wrapper>);
			performanceTrendTableSection = (<Wrapper><NoDataAvailableLoader chartType="tableChartType" /></Wrapper>);
		} else {

			if (isMultiLineCluster) {
				performanceTrendSection = (
					<Wrapper>
						<ErrorBoundary chartType="lineChartType">
							<LineChart
								chartClass="section_card_chart"
								chartData={performanceTrend}
								chartId="performanceTrend"
								chartLabels={performanceTrendLabel}
								showLegend={true}
								disabledLegendArray={performanceTrendDisabledvalues}
								setDisabledValues={setPerformanceTrendDisabledvalues}
							/>
						</ErrorBoundary>
					</Wrapper>
				);

				performanceTrendSection2 = (
					<Wrapper>
						<ErrorBoundary chartType="lineChartType">
							<PartitionedLineChart
								chartClass="section_card_chart"
								chartData={performanceTrend}
								chartId="performanceTrend_2"
								chartLabels={performanceTrendLabel}
								showLegend={true}
								disabledLegendArray={performanceTrendDisabledvalues}
								setDisabledValues={setPerformanceTrendDisabledvalues}
							/>
						</ErrorBoundary>
					</Wrapper>
				);


			} else {
				performanceTrendSection = (
					<Wrapper>
						<ErrorBoundary chartType="lineChartType">
							<MultiAxisMultiLine
								chartClass="section_card_chart"
								chartData={performanceTrend}
								chartId="performanceTrend"
								chartLabels={performanceTrendLabel}
								showLegend={true}
							/>
						</ErrorBoundary>
					</Wrapper>
				);
			}



			performanceTrendTableSection = (
				<Wrapper>
					<ErrorBoundary chartType="tableChartType">
						<div className="main_wrapper">
							<div className="body">
								{performanceTrendTable && performanceTrendTable.map(
									(details, postImpressionIndex) => (
										<Wrapper key={postImpressionIndex}>
											<div className="row">
												<div className="title_wrapper">
													{details.label}
												</div>
												<div className="value_wrapper">
													<ErrorBoundary>
														<NumberFormatter number={details.value} title={details.label} />
													</ErrorBoundary>

												</div>
											</div>
										</Wrapper>
									))}
							</div>

						</div>
					</ErrorBoundary>
				</Wrapper>
			)

		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (performanceTrenderror) {
		performanceTrendSection = (<Wrapper><ServerErrorsLoader chartType="lineChartType" error={performanceTrenderror} /></Wrapper>);
		performanceTrendSection2 = (<Wrapper><ServerErrorsLoader chartType="lineChartType" error={performanceTrenderror} /></Wrapper>);
		performanceTrendTableSection = <Wrapper><ServerErrorsLoader loaderType="tableChartType" error={performanceTrenderror} /></Wrapper>;

	}

	// IF DATA LOADING
	if (isPerformanceTrendLoading) {
		performanceTrendSection = (<Wrapper><Loader loaderType="multiAxisMultiLineChartLoader" /></Wrapper>);
		performanceTrendSection2 = (<Wrapper><Loader loaderType="multiAxisMultiLineChartLoader" /></Wrapper>);
		performanceTrendTableSection = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>;

	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// TOP BOTTOM PERFORMING MARKETS

	useEffect(() => {
		const fetchTopBottomPerformingMarketHandler = async () => {
			setTopBottomPerformingMarketsIsLoading(true);
			setTopBottomPerformingMarketsIsLoaded(false);
			setTopBottomPerformingMarketsError(null);

			var dropdownVal = topBottomPerformDataDropdownValue === undefined || topBottomPerformDataDropdownValue === "" ? "" :
				topBottomPerformDataDropdownValue.value === undefined ? topBottomPerformDataDropdownOptions[0].value :
					`${topBottomPerformDataDropdownValue.value}`

			const config = {
				params: {
					brand_id: BRAND_ID,
					market_code: marketCode,
					state_code: mapStateCode,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					performance_filter: dropdownVal,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/sales/haleon-top-bottom-markets/", config);

				let rawLabels = BubbleChartSizeShapeDynamicLabels(res?.data?.dimension[0] ?? []);

				setTopBottomPerformingMarketsData(res?.data?.data ?? []);
				setTopBottomPerformingMarketsLables(res?.data?.dimension ?? []);
				setTopBottomPerformingMarketsTableData(res?.data?.table_data ?? [])

				setTopBottomPerformingMarketsIsLoading(false);
				setTopBottomPerformingMarketsIsLoaded(true);
				setTopBottomPerformingMarketsError(null);
			} catch (error) {
				setTopBottomPerformingMarketsData([]);
				setTopBottomPerformingMarketsLables([]);
				setTopBottomPerformingMarketsIsLoading(false);
				setTopBottomPerformingMarketsIsLoaded(false);
				setTopBottomPerformingMarketsError(error.code);
			}



		};
		(topBottomPerformDataDropdownOptionsLoaded === true) && fetchTopBottomPerformingMarketHandler()
	}, [
		BRAND_ID,
		authCtx,
		selectedDates,
		topBottomPerformDataDropdownValue,
		marketCode,
		mapStateCode,
		topBottomPerformDataDropdownOptionsLoading,
		topBottomPerformDataDropdownOptions,
		topBottomPerformDataDropdownOptionsLoaded

	])


	// SALES METRICS CARDS SLIDER DATA HANDLING
	let topBottomPerfMarketSection = <Wrapper><Loader loaderType="bubbleChartLoader" /></Wrapper>;
	let topBottomPerfMarketTableSection = (
		<Wrapper>
			<div className="grid grid_cols_4 fullpage_section_card section_card">
				<div className="grid col_span_4">
					<div className="section_card_body">
						<Loader loaderType="smallTableLoader" />
					</div>
				</div>
			</div>
		</Wrapper>
	);

	// IF DATA IS LOADED
	if (isTopBottomPerformingMarketsLoaded && !isTopBottomPerformingMarketsLoading) {
		if (NoDataAvailableChecker(topBottomPerformingMarketsData) || NoDataAvailableChecker(topBottomPerformingMarketsLabels)) {
			topBottomPerfMarketSection = (
				<Wrapper><NoDataAvailableLoader chartType="bubbleChartType" /></Wrapper>
			);
			topBottomPerfMarketTableSection = (
				<Wrapper>
					<div className="grid grid_cols_4 fullpage_section_card section_card">
						<div className="grid col_span_4">
							<div className="section_card_body">
								<NoDataAvailableLoader chartType="tableChartType" />
							</div>
						</div>
					</div>
				</Wrapper>
			);
		} else {
			topBottomPerfMarketSection = (
				<Wrapper>
					<ErrorBoundary chartType="bubbleChartType">
						<BubbleChartSizeShapeDynamic
							chartId="zoomablechart"
							chartClass="section_card_chart"
							showLegend={true}
							showLabels={true}
							chartData={topBottomPerformingMarketsData}
							chartLabels={topBottomPerformingMarketsLabels}
							riMarket={true}
						/>
					</ErrorBoundary>
				</Wrapper>
			);

			topBottomPerfMarketTableSection = (
				<ErrorBoundary>
					<Wrapper>
						<Datatable
							tableTitle='Top / Bottom Performing Markets Summary'
							tableHeader={topBottomPerformingMarketsTableHeader}
							tableData={topBottomPerformingMarketsTableData}
							tableLength={10}
							isFooterShow={true}
							tableDropdown={false}
							searchPlaceHolder={"Search Markets"}
							tableID={"top_bottom_table"}
							tableExportDropdown={
								<ExportDropdown
									moduleName={pageModule}
									platform={pagePlatform}
									subModuleName={pageSubModule}
									sectionId={"top_bottom_table"}
									sectionName="Top - Bottom Performing Table"
									sectionData={topBottomPerformingMarketsTableData}
								/>
							}
						/>
					</Wrapper>
				</ErrorBoundary>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (topBottomPerformingMarketsError) {
		topBottomPerfMarketSection = (
			<Wrapper>
				<ServerErrorsLoader chartType="bubbleChartType" error={topBottomPerformingMarketsError} />
			</Wrapper>
		);

		topBottomPerfMarketTableSection = (
			<Wrapper>
				<div className="grid grid_cols_4 fullpage_section_card section_card">
					<div className="grid col_span_4">
						<div className="section_card_body">
							<ServerErrorsLoader chartType="tableChartType" error={topBottomPerformingMarketsError} />
						</div>
					</div>
				</div>
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (isTopBottomPerformingMarketsLoading) {
		topBottomPerfMarketSection = <Wrapper><Loader loaderType="bubbleChartLoader" /></Wrapper>;

		topBottomPerfMarketTableSection =
			(
				<Wrapper>
					<div className="grid grid_cols_4 fullpage_section_card section_card">
						<div className="grid col_span_4">
							<div className="section_card_body">
								<Loader loaderType="smallTableLoader" />
							</div>
						</div>
					</div>
				</Wrapper>
			);
	}



	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// OVERALL MEDIA PERFORMANCE

	useEffect(() => {
		const fetchOverallMediaPerformanceHandler = async () => {
			setOverallMediaPerformanceIsLoading(true);
			setOverallMediaPerformanceIsLoaded(false);
			setOverallMediaPerformanceError(null);

			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					market_code: marketCode,
					state_code: mapStateCode,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/sales/haleon-overall-performance/", config);

				setOverallMediaPerformance(res?.data?.chart_data?.data ?? [])
				setOverallMediaPerformanceLabel(res?.data?.chart_data?.dimension ?? [])
				setOverallMediaPerformanceTableData(res?.data?.table_data ?? [])

				setOverallMediaPerformanceIsLoading(false);
				setOverallMediaPerformanceIsLoaded(true);
				setOverallMediaPerformanceError(null);
			} catch (error) {
				setOverallMediaPerformance([]);
				setOverallMediaPerformanceLabel([]);
				setOverallMediaPerformanceIsLoading(false);
				setOverallMediaPerformanceIsLoaded(false);
				setOverallMediaPerformanceError(error.code);
			}
		};
		fetchOverallMediaPerformanceHandler()
	}, [
		authCtx,
		BRAND_ID,
		selectedDates,
		marketCode,
		mapStateCode,
	])

	let overallMediaPerformanceSection = <Wrapper><Loader loaderType="lineChartLoader" /></Wrapper>;
	let overallMediaPerformanceTableSection = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>;

	// IF DATA IS LOADED
	if (isOverallMediaPerformanceLoaded && !isOverallMediaPerformanceLoading) {
		if (NoDataAvailableChecker(overallMediaPerformance) || NoDataAvailableChecker(overallMediaPerformanceLabel)) {
			overallMediaPerformanceSection = (<Wrapper><NoDataAvailableLoader chartType="multiAxisMultiLineChartLoader" /></Wrapper>);
			overallMediaPerformanceTableSection = <Wrapper><NoDataAvailableLoader chartType="tableChartType" /></Wrapper>;
		} else {
			overallMediaPerformanceSection = (
				<Wrapper>
					<ErrorBoundary chartType="lineChartType">
						<StackedClusteredBarChart
							chartClass="section_card_chart"
							chartData={overallMediaPerformance}
							chartId="overallMediaPerformance"
							chartLabels={overallMediaPerformanceLabel}
							minGridDistance={20}
						/>
					</ErrorBoundary>
				</Wrapper>
			);

			overallMediaPerformanceTableSection = (
				<Wrapper>
					<ErrorBoundary chartType="tableChartType">
						<div className="main_wrapper">
							<div className="body">
								{overallMediaPerformanceTableData && overallMediaPerformanceTableData.map(
									(details, postImpressionIndex) => (
										<Wrapper key={postImpressionIndex}>
											<div className="row">
												<div className="title_wrapper">
													{details.label}
												</div>
												<div className="value_wrapper">
													<ErrorBoundary>
														<NumberFormatter number={details.value} title={details.label} />
													</ErrorBoundary>

												</div>
											</div>
										</Wrapper>
									))}
							</div>

						</div>
					</ErrorBoundary>
				</Wrapper>
			)

		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (overallMediaPerformanceerror) {
		overallMediaPerformanceSection = (
			<Wrapper>
				<ServerErrorsLoader
					chartType="lineChartType"
					error={overallMediaPerformanceerror}
				/>
			</Wrapper>
		);
		overallMediaPerformanceTableSection = <Wrapper><ServerErrorsLoader loaderType="tableChartType" error={overallMediaPerformanceerror} /></Wrapper>;

	}

	// IF DATA LOADING
	if (isOverallMediaPerformanceLoading) {
		overallMediaPerformanceSection = (<Wrapper><Loader loaderType="multiAxisMultiLineChartLoader" /></Wrapper>);
		overallMediaPerformanceTableSection = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>;

	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	//   MARKET CAMPAIGN / CREATIVE
	useEffect(() => {
		const fetchMarketCampaignDeepdiveHandler = async () => {
			setMarketCampaignDeepdiveIsLoading(true);
			setMarketCampaignDeepdiveIsLoaded(false);
			setMarketCampaignDeepdiveError(null);

			const config = {
				params: treemapParams,
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/sales/haleon-markets-performance/", config);

				setMarketCampaignDeepdive(res?.data?.data ?? []);
				setMarketCampaignDeepdiveIsLoading(false);
				setMarketCampaignDeepdiveIsLoaded(true);
				setMarketCampaignDeepdiveError(null);
			} catch (error) {
				setMarketCampaignDeepdive([]);
				setMarketCampaignDeepdiveIsLoading(false);
				setMarketCampaignDeepdiveIsLoaded(false);
				setMarketCampaignDeepdiveError(error.code);
			}
		};
		Object.keys(treemapParams).length !== 0 && fetchMarketCampaignDeepdiveHandler();
	}, [authCtx, treemapParams]);


	let marketCampaignDeepdiveSection = (
		<Wrapper>
			<div className="section_card_chart_wrapper flex column">
				<Loader loaderType="treeChartLoader" />
			</div>
		</Wrapper>
	);

	// IF DATA IS AVAILABLE
	if (isMarketCampaignDeepdiveLoaded && !isMarketCampaignDeepdiveLoading) {
		if (NoDataAvailableChecker(marketCampaignDeepdive) || (marketCampaignDeepdive.length > 1 && marketCampaignDeepdive[0].impressions === 0)) {
			marketCampaignDeepdiveSection = (
				<Wrapper>
					<div className="section_card_chart_wrapper flex column">
						<NoDataAvailableLoader chartType="treeChartType" />
					</div>
				</Wrapper>
			);
		} else {
			if (treemapLevel > 3) {
				marketCampaignDeepdiveSection = (
					<Wrapper>
						<div className="section_card_chart_wrapper flex column">
							<NoDataAvailableLoader chartType="treeChartType" />
						</div>
					</Wrapper>
				);
			} else {
				marketCampaignDeepdiveSection = (
					<Wrapper>
						<div className="section_card_chart_wrapper flex column">
							<ErrorBoundary chartType="treeChartType">
								<SimpleTreeMap
									chartId="simpleTreemaptest"
									chartClass="section_card_chart"
									chartData={marketCampaignDeepdive}
									levelHandler={chartlevelHandler}
									colorChangeParameter={"growth_percentage"}
									chartLabels={[
										{
											name: "name",
											value: "impressions",
											toolTipvalue1: "impressions",
											toolTipvalue1Label: "Impressions",
											toolTipvalue2: "monthly_sales_vol",
											toolTipvalue2Label: "Sales Vol.",
											toolTipvalue3: "spends",
											toolTipvalue3Label: "Spends",
											colorChangeParameter: "growth"
										},
									]}
									positiveNegativeNeutral={true}
								/>
							</ErrorBoundary>
						</div>
						{isMarketCampaignDeepdiveLoaded &&
							!isMarketCampaignDeepdiveLoading &&
							Object.keys(marketCampaignDeepdive).length !== 0 && (
								<div className="w_100">
									<ul className="icz-rilist-inline">
										<li>
											<div className="legend-circle circleGreen"></div> Spends Growth
										</li>
										<li>
											<div className="legend-circle circleOrange"></div> Stable Spend
										</li>
										<li>
											<div className="legend-circle circleRed"></div> Spends Decline
										</li>
									</ul>
								</div>
							)}
					</Wrapper>
				);
			}
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (marketCampaignDeepdiveerror) {
		marketCampaignDeepdiveSection = (
			<Wrapper>
				<div className="section_card_chart_wrapper flex column">
					<ServerErrorsLoader
						chartType="treeChartType"
						error={marketCampaignDeepdiveerror}
					/>
				</div>
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (isMarketCampaignDeepdiveLoading) {
		marketCampaignDeepdiveSection = (
			<Wrapper>
				<div className="section_card_chart_wrapper flex column">
					<Loader loaderType="treeChartLoader" />
				</div>
			</Wrapper>
		);
	}


	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// PERFORMANCE BY SOURCE

	useEffect(() => {
		const fetchBrandPerfOverviewHandler = async () => {
			setBrandPerfOverviewIsLoading(true);
			setBrandPerfOverviewIsLoaded(false);
			setBrandPerfOverviewError(null);

			var dropdown1 = brandPerfOverviewFirstDropdownValue === undefined ? "" : brandPerfOverviewFirstDropdownValue.value === undefined ? brandPerfOverviewDropdownOptions[0].value : brandPerfOverviewFirstDropdownValue.value
			var dropdown2 = brandPerfOverviewSecondDropdownValue === undefined ? "" : brandPerfOverviewSecondDropdownValue.value === undefined ? brandPerfOverviewDropdownOptions[1].value : brandPerfOverviewSecondDropdownValue.value

			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					market_code: marketCode,
					state_code: mapStateCode,
					performance_parameters: `${dropdown1},${dropdown2}`
				},
				headers: {
					"Content-Type": "application/json",

					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/sales/haleon-brand-performance-overview/", config);


				setBrandPerfOverview(res?.data?.chart_data[0]?.data ?? []);
				setBrandPerfOverviewDataLabels(res?.data?.chart_data[0]?.dimensions ?? []);
				setBrandPerfOverviewDetails(res?.data?.table_data ?? []);

				setBrandPerfOverviewIsLoading(false);
				setBrandPerfOverviewIsLoaded(true);
				setBrandPerfOverviewError(null);
			} catch (error) {

				setBrandPerfOverview([]);
				setBrandPerfOverviewDataLabels([]);
				setBrandPerfOverviewDetails([]);
				setBrandPerfOverviewIsLoading(false);
				setBrandPerfOverviewIsLoaded(false);
				setBrandPerfOverviewError(error.code);
			}
		};
		(brandPerfOverviewDropdownOptionsLoaded === true) && fetchBrandPerfOverviewHandler();
	}, [
		authCtx, BRAND_ID, selectedDates, brandPerfOverviewFirstDropdownValue,
		brandPerfOverviewSecondDropdownValue, marketCode, mapStateCode, brandPerfOverviewDropdownOptionsLoaded,
		brandPerfOverviewDropdownOptions])

	//  DATA HANDLING
	let brandPerfOverviewChart = (
		<Wrapper>
			<Loader loaderType="singleLineChartLoader" />
		</Wrapper>
	);

	// IF DATA IS LOADED
	if (isBrandPerfOverviewLoaded && !isBrandPerfOverviewLoading) {
		if (NoDataAvailableChecker(brandPerfOverview) || NoDataAvailableChecker(brandPerfOverviewDataLabels)) {
			brandPerfOverviewChart = (
				<Wrapper>
					<NoDataAvailableLoader chartType="lineChartType" />
				</Wrapper>
			);
		} else {
			brandPerfOverviewChart = (
				<Wrapper>
					<ErrorBoundary chartType="lineChartType">
						<MultiAxis
							chartId="performance_by_source"
							chartClass="section_card_chart"
							chartData={brandPerfOverview}
							chartLabels={brandPerfOverviewDataLabels}
							showLegend={true}
							showLabels={true}
							showVerticalLabels={true}
							minGridDistance={20}
						/>
					</ErrorBoundary>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (brandPerfOverviewerror) {
		brandPerfOverviewChart = (
			<Wrapper>
				<ServerErrorsLoader
					chartType="lineChartType"
					error={brandPerfOverviewerror}
				/>
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (isBrandPerfOverviewLoading) {
		brandPerfOverviewChart = (
			<Wrapper>
				<Loader loaderType="singleLineChartLoader" />
			</Wrapper>
		);
	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// PERFORMANCE BY SOURCE DETAILS

	// DATA HANDLING
	let brandPerfOverviewDetailsData = (
		<Wrapper>
			<Loader loaderType="smallTableLoader" />
		</Wrapper>
	);

	// IF DATA IS LOADED
	if (isBrandPerfOverviewLoaded && !isBrandPerfOverviewLoading) {
		if (NoDataAvailableChecker(brandPerfOverviewDetails)) {
			brandPerfOverviewDetailsData = (
				<Wrapper>
					<NoDataAvailableLoader chartType="tableChartType" />
				</Wrapper>
			)
		} else {
			brandPerfOverviewDetailsData = (
				<Wrapper>
					<ErrorBoundary chartType="tableChartType">
						<table className='brand_performance_overview_table'>
							<tbody>
								<div className='brand_performance_overview_wrap'>
									{brandPerfOverviewDetails.map((item, i) => {
										return (
											<Wrapper key={Math.random(109090)}>
												<div className='section_subtitle'>{item.label}</div>
												{item.subData.map((el, elIndex) => {
													return (
														<Wrapper key={elIndex}>
															<tr className='brand_performance_overview_row'>
																<td>
																	<div className='brand_performance_overview_value'>
																		<NumberFormatter number={el.value1} title={el.label1} />
																	</div>
																	<div className='brand_performance_overview_label'>
																		{el.label1}
																	</div>
																</td>
																<td>
																	<div className='brand_performance_overview_value flex align_center'>
																		<NumberFormatter number={el.value2} title={el.label2} />
																		<div className='pl_10'>
																			<GrowthConverterFunc currentValue={el.value2} />
																		</div>

																	</div>
																	<div className='brand_performance_overview_label'>
																		{el.label2}
																	</div>
																</td>
															</tr>
														</Wrapper>
													)
												})}

											</Wrapper>
										)
									})}
								</div>
							</tbody>

						</table>

					</ErrorBoundary>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (brandPerfOverviewerror) {
		brandPerfOverviewDetailsData = (
			<Wrapper>
				<ServerErrorsLoader
					chartType="tableChartType"
					error={brandPerfOverviewerror}
				/>
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (isBrandPerfOverviewLoading) {
		brandPerfOverviewDetailsData = (
			<Wrapper>
				<Loader loaderType="smallTableLoader" />
			</Wrapper>
		);
	}


	// ----------------------------------------------------
	// ----------------------------------------------------
	// ----------------------------------------------------
	// ----------------------------------------------------
	// TOP POST

	useEffect(() => {
		const fetchTopPostsHandler = async () => {
			settopPostsLoading(true);
			setTopPostsloaded(false);
			setTopPostsError(null);

			const config = {
				params: {
					brand_id: BRAND_ID,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					market_code: marketCode,
					state_code: mapStateCode,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/sales/haleon-compare-brands/", config);

				setTopPosts(res?.data ?? []);
				settopPostsLoading(false);
				setTopPostsloaded(true);
				setTopPostsError(null);
			} catch (error) {
				setTopPosts([]);
				settopPostsLoading(false);
				setTopPostsloaded(false);
				setTopPostsError(error.code);
			}
		};
		fetchTopPostsHandler();
	}, [authCtx, selectedDates, marketCode, mapStateCode])




	// TOP TWEETS CHART
	let topPostsChart = (
		<Wrapper>
			<div className="section_card fullpage_section_card grid">
				<div className="grid col_span_4 section_card_header">
					<div className="section_info">
						<h2 className="section_card_title">Top Brands</h2>
					</div>
				</div>
				<div className="grid col_span_4">
					<Loader loaderType="cardLoader" variantType="compare" />
				</div>
			</div>

		</Wrapper>
	);


	// IF DATA IS LOADED
	if (topPostsLoaded && !topPostsLoading) {
		if (NoDataAvailableChecker(topPosts)) {
			topPostsChart = (
				<Wrapper>
					<div className="section_card fullpage_section_card grid">
						<div className="grid col_span_4 section_card_header">
							<div className="section_info">
								<h2 className="section_card_title">Top Brands</h2>
							</div>
						</div>
						<div className="grid col_span_4 grid_margin_bottom">
							<NoDataAvailableLoader chartType="cardChartType" />
						</div>
					</div>
				</Wrapper>

			);
		} else {
			topPostsChart = (
				<Wrapper>
					<ErrorBoundary chartType="cardChartType">
						<CompareMarketsSummary
							mapStateCode={mapStateCode}
							products={topPosts}
							isPaid={true}
							Title={"Top Brands"}
							hasExportDropdown={true}
						/>
					</ErrorBoundary>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (topPostserror) {
		topPostsChart = (
			<Wrapper>
				<div className="section_card fullpage_section_card grid">
					<div className="grid col_span_4 section_card_header">

						<div className="section_info">
							<h2 className="section_card_title">Top Brands</h2>
							<SectionDefinationsBadge
								title={"Top Brands"}
								module="video"
								platform="overall"
								section="overall"
							/>

						</div>
					</div>
					<div className="grid col_span_4 grid_margin_bottom">
						<ServerErrorsLoader chartType="cardChartType" error={topPostserror} />
					</div>
				</div>
				<div className="grid col_span_4 grid_margin_bottom">
					<ServerErrorsLoader chartType="cardChartType" error={topPostserror} />
				</div>
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (topPostsLoading) {
		topPostsChart = (
			<Wrapper>
				<div className="section_card fullpage_section_card grid">
					<div className="grid col_span_4 section_card_header">

						<div className="section_info">
							<h2 className="section_card_title">Top Brands</h2>
							<SectionDefinationsBadge
								title={"Top Brands"}
								module="video"
								platform="overall"
								section="overall"
							/>
						</div>
					</div>

					<div className="grid col_span_4">
						<Loader loaderType="cardLoader" variantType="compare" />
					</div>
				</div>

			</Wrapper>
		);
	}


	function HandleTableView() {
		var sectionIndia = document.getElementById('market_compare_india')

		if (sectionIndia.classList.contains("hide_rows")) {
			setIsMarketTableHidden({ text: "View Less", class: "" })
		} else {
			setIsMarketTableHidden({ text: "View More", class: "hide_rows" })
		}
	}

	var brandsExport = (function () {
		if (selectedBrands === undefined || selectedBrands.length === 0) {
			return { "": "" }
		} else {
			var obj = {};
			for (var i = 0; i < selectedBrands.length; i++) {
				if (selectedBrands.length === 1) {
					obj["Brand"] = selectedBrands[i].label
				} else {
					obj["Brand " + (i + 1)] = selectedBrands[i].label
				}
			}
			return obj;
		}
	})()

	var stateCodeLabel = mapState === undefined || mapState === null ? "" : " : " + mapState;
	var marketCodeLabel = marketCode === undefined || marketCode === null ? "" : " : " + marketCode;

	useEffect(() => {
		setexportSummary([
			selectedDates, brandsExport
		]);
	}, [selectedDates, selectedBrands]);

	var performanceTrendExport = {
		[`DateFirstNonActiveDropdownLabels_Performance Trend_${marketCodeLabel + stateCodeLabel}`]: performanceTrend,
		[`TableDynaLabels_Performance Trend Table_${marketCodeLabel + stateCodeLabel}`]: performanceTrendTable
	}

	var topBottomPerformingMarketsExport = {
		[`TopPerfMarketSummary_Top - Bottom Performing Markets_${topBottomPerformDataDropdownValue.label + marketCodeLabel + stateCodeLabel}`]: topBottomPerformingMarketsData

	}

	var marketCompareExport = {
		[`${marketAllIndiaDropdownValue && marketAllIndiaDropdownValue.label} Chart`]: marketCompareAllIndia,
		[`${marketAllIndiaDropdownValue && marketAllIndiaDropdownValue.label} Table`]: marketCompareAllIndiaTable,
		[`${marketStateDropdownValue && marketStateDropdownValue.label} Chart`]: marketCompareState,
		[`${marketStateDropdownValue && marketStateDropdownValue.label} Table`]: marketCompareStateTable
	}

	var overallMediaPerformanceExport = {
		[`DateFirstNonActiveDropdownLabels_Overall Media Performance_${marketCodeLabel + stateCodeLabel}`]: overallMediaPerformance,
		[`TableDynaLabels_Overall Media Perf Table_${marketCodeLabel + stateCodeLabel}`]: overallMediaPerformanceTableData
	}

	var brandPerfOverviewExport = {
		[`BrandPerfOverview_Brand Performance Overview_${marketCodeLabel + stateCodeLabel}`]: brandPerfOverview,
		[`MarketsPieofPie_Brand Perforamnce Table_${marketCodeLabel + stateCodeLabel}`]: brandPerfOverviewDetails
	}

	useEffect(() => {
		var allData = {
			Summary: exportSummary,
			"MarketIndiaChart_India Map": MapData.features,
			[`SummaryCardDynaLabels_Performance Metrics_${marketCodeLabel + stateCodeLabel}`]: performanceMetricCards,
			...marketCompareExport,
			...performanceTrendExport,
			...topBottomPerformingMarketsExport,
			[`Top - Bottom Performing Table`]: topBottomPerformingMarketsTableData,
			[`MarketTreemap_Market Campaign Deepdive_${marketCodeLabel + stateCodeLabel}`]: marketCampaignDeepdive,
			...overallMediaPerformanceExport,
			...brandPerfOverviewExport,
			"Compare_Top Post": topPosts

		};
		setExportPageName("Market Summary");
		setFullPageExport(allData);
	}, [
		exportSummary,
		MapData,
		performanceMetricCards,
		performanceTrend,
		marketCompareAllIndia,
		marketCompareState,
		overallMediaPerformance,
		overallMediaPerformanceTableData,
		brandPerfOverview,
		brandPerfOverviewDetails,
		marketCampaignDeepdive,
		topPosts,
		mapState,
	])

	useEffect(() => {
		if (
			(MapError !== null ? true : isMapLoaded && (isMapLoading === false)) &&
			(performanceMetricCardserror !== null ? true : isPerformanceMetricCardsLoaded && (isPerformanceMetricCardsLoading === false)) &&
			(performanceTrenderror !== null ? true : isPerformanceTrendLoaded && (isPerformanceTrendLoading === false)) &&
			(marketCompareAllIndiaerror !== null ? true : isMarketCompareAllIndiaLoaded && (isMarketCompareAllIndiaLoading === false)) &&
			(marketCompareStateerror !== null ? true : isMarketCompareStateLoaded && (isMarketCompareStateLoading === false)) &&
			(overallMediaPerformanceerror !== null ? true : isOverallMediaPerformanceLoaded && (isOverallMediaPerformanceLoading === false)) &&
			(brandPerfOverviewerror !== null ? true : isBrandPerfOverviewLoaded && (isBrandPerfOverviewLoading === false)) &&
			(marketCampaignDeepdiveerror !== null ? true : isMarketCampaignDeepdiveLoaded && (isMarketCampaignDeepdiveLoading === false)) &&
			(topPostserror !== null ? true : topPostsLoaded && (topPostsLoading === false))
		) {
			setIsExportDisabled(false)
		} else {
			setIsExportDisabled(true)
		}
	}, [
		MapError, isMapLoaded, isMapLoading,
		performanceMetricCardserror, isPerformanceMetricCardsLoaded, isPerformanceMetricCardsLoading,
		performanceTrenderror, isPerformanceTrendLoaded, isPerformanceTrendLoading,
		marketCompareAllIndiaerror, isMarketCompareAllIndiaLoaded, isMarketCompareAllIndiaLoading,
		marketCompareStateerror, isMarketCompareStateLoaded, isMarketCompareStateLoading,
		overallMediaPerformanceerror, isOverallMediaPerformanceLoaded, isOverallMediaPerformanceLoading,
		brandPerfOverviewerror, isBrandPerfOverviewLoaded, isBrandPerfOverviewLoading,
		marketCampaignDeepdiveerror, isMarketCampaignDeepdiveLoaded, isMarketCampaignDeepdiveLoading,
		topPostserror, topPostsLoaded, topPostsLoading,
	])

	useEffect(() => {

		setSearchParams({
			brands: selectedBrands.length === 0 ? "overall" : selectedBrands,
		});

	}, [selectedBrands])


	return (
		<Wrapper>
			<div className="grid drawer_grid">
				<div className="left_drawer col_span_1 fixed-left-grid mapRightWrapper" id="india_map">
					<div className="section_card">
						<div className="mapHeaderWrapper w_100">
							<div className="grid col_span_4">
								<div className="section_card_header mapHeader">
									<div className="section_info">
										<h2 className="section_card_title card_map_title">
											India
										</h2>
									</div>
									<div data-html2canvas-ignore={true}>
										<ExportDropdown
											sectionId={"india_map"}
											sectionName={"MarketIndiaChart_India Map"}
											sectionData={MapData.features}
										/>
									</div>
								</div>
							</div>
							<div className="grid col_span_4">
								<div className="section_card_header map_breadcrumb_header">
									{mapBreadCrumbList.map((item, index) => (
										<div
											onClick={() => {
												mapBreadcrumbsHandler(index, item);
											}}
											key={index}
										>
											{index === 0 ? " " : "/ "}
											<TextFormatter string={item} />
										</div>
									))}
								</div>
							</div>
						</div>

						<div className="section_card_body mapBoxContainer">
							<div className="grid grid_cols_4 grid_margin_bottom">
								<div className="grid col_span_4">
									<div className="section_card_body">
										<div className="section_card_map_wrapper">
											{IndiaMapChart}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="mapBoxContainer">
							{/* <div className="w_100">
								<ul className="icz-rilist-inline">
									<li>
										<div className="legend-circle icz-rilist-bullet1"></div>
										North
									</li>
									<li>
										<div className="legend-circle icz-rilist-bullet2"></div>
										West
									</li>
									<li>
										<div className="legend-circle icz-rilist-bullet3"></div>
										South
									</li>
									<li>
										<div className="legend-circle icz-rilist-bullet4"></div>
										East
									</li>
								</ul>
							</div> */}
							<div className="w_100">
								<ul className="icz-rilist-inline">
									<li>
										<div className="legend-circle circleGreen"></div> Growth
									</li>
									<li>
										<div className="legend-circle circleRed"></div> Degrowth
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="right_side col_span_3 relative-right-grid">
					<div className="content">
						<div className="content_section">
							<PageHeader pageTitle={"Market"} />
							<div className="page_content">
								<div className="dashboard_home">
									{performanceMetricCardsSection}

									<div className="section_card section_dropdown_header">
										<div className="grid grid_cols_1">
											<div className="section_card_header">
												<h2 className="section_card_title">Market Comparison</h2>
												<div data-html2canvas-ignore={true}>
													<ExportDropdown
														sectionId={"time_slot_grp_section"}
														sectionName={"Market Comparison"}
														sectionData={marketCompareExport}
													/>

												</div>
											</div>
										</div>
									</div>

									<div id="top_genra_grp_section" className="grid grid_cols_2 grid_margin_bottom">
										<div id="time_slot_grp_section" className="grid col_span_1 section_card h_auto adjust_new_line">
											<div className="section_card_header">
												<h2 className="section_card_title title_new_line">
													{marketAllIndiaDropdownValue && marketAllIndiaDropdownValue.label}
												</h2>
												<Modal
													open={isCompareAllIndiaOpen}
													setOpen={setIsCompareAllIndiaOpen}
													exportDropdown={
														<ExportDropdown
															sectionId={"marketCompareAllIndia_2"}
															sectionName={"Market Comparison"}
															sectionData={marketCompareExport}
														/>}
													parentId={"time_slot_grp_section"}
													dropdown={
														<Dropdown
															ismulti={false}
															placeholder={"Select Market"}
															options={marketComparisonDropdownOptions}
															className="form_dropdown section_dropdown small-dropdown pl_20"
															value={marketAllIndiaDropdownValue}
															selectedOptions={marketAllIndiaDropdownValue}
															dropdownLoading={marketComparisonDropdownOptionsLoading}
															setStatedropdown={marketAllIndiaDropdownHandler}
														/>
													}
													title={`${marketAllIndiaDropdownValue && marketAllIndiaDropdownValue.label}`}
													chart={marketCompareAllIndiaSection2}
												/>
												<div data-html2canvas-ignore={true}>
													{isMultiLineCluster && <button onClick={() => setIsCompareAllIndiaOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
														<ExpandIcon />
													</button>}
													<Dropdown
														ismulti={false}
														placeholder={"Select Market"}
														options={marketComparisonDropdownOptions}
														className="form_dropdown section_dropdown small-dropdown pl_20"
														value={marketAllIndiaDropdownValue}
														selectedOptions={marketAllIndiaDropdownValue}
														dropdownLoading={marketComparisonDropdownOptionsLoading}
														setStatedropdown={marketAllIndiaDropdownHandler}
													/>
												</div>
											</div>
											<div className="grid grid_cols_1 section_card_body">
												<div className="section_card_chart_wrapper">
													{marketCompareAllIndiaSection}
												</div>
												<div className='table_wrapper'>
													{marketCompareAllIndiaTableSection}
													{isMarketCompareAllIndiaLoaded && <div className='text_end'>
														<a className='market_compare_link' onClick={() => HandleTableView()}>{isMarketTableHidden.text}</a>
													</div>}
												</div>
											</div>
										</div>
										<div id="ad_duration_wise_grp_section" className="grid col_span_1 section_card adjust_new_line">
											<div className="section_card_header">
												<div className="section_info">
													<h2 className="section_card_title title_new_line">
														{marketStateDropdownValue && marketStateDropdownValue.label}
													</h2>
												</div>
												<Modal
													open={isCompareStateOpen}
													setOpen={setIsCompareStateOpen}
													exportDropdown={
														<ExportDropdown
															sectionId={"marketCompareState_2"}
															sectionName={"Market Comparison"}
															sectionData={marketCompareExport}
														/>}
													parentId={"ad_duration_wise_grp_section"}
													dropdown={
														<Dropdown
															ismulti={false}
															placeholder={"Select Market"}
															options={marketComparisonDropdownOptions}
															className="form_dropdown section_dropdown small-dropdown pl_20"
															value={marketStateDropdownValue}
															selectedOptions={marketStateDropdownValue}
															dropdownLoading={marketComparisonDropdownOptionsLoading}
															setStatedropdown={marketStateDropdownHandler}
														/>
													}
													title={`${marketStateDropdownValue && marketStateDropdownValue.label}`}
													chart={marketCompareStateSection2}
												/>
												<div data-html2canvas-ignore={true}>
													{isMultiLineCluster && <button onClick={() => setIsCompareStateOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
														<ExpandIcon />
													</button>}
													<Dropdown
														ismulti={false}
														placeholder={"Select Market"}
														options={marketComparisonDropdownOptions}
														className="form_dropdown section_dropdown small-dropdown pl_20"
														value={marketStateDropdownValue}
														selectedOptions={marketStateDropdownValue}
														dropdownLoading={marketComparisonDropdownOptionsLoading}
														setStatedropdown={marketStateDropdownHandler}
													/>

												</div>
											</div>
											<div className="grid grid_cols_1 section_card_body h_auto">
												<div className="section_card_chart_wrapper">
													{marketCompareStateSection}
												</div>
												<div className='table_wrapper'>
													{marketCompareStateTableSection}
													{isMarketCompareStateLoaded && <div className='text_end'>
														<a className='market_compare_link' onClick={() => HandleTableView()}>{isMarketTableHidden.text}</a>
													</div>}
												</div>

											</div>
										</div>
									</div>
									<div id="performance_trend" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
										<div className="grid col_span_4 adjust_new_line ">
											<div className="section_card_header perfTrendLine">
												<div className="section_info">
													<h2 className="section_card_title">
														Performance Trend
													</h2>
												</div>
												<Modal
													open={isPerformanceTrendOpen}
													setOpen={setIsPerformanceTrendOpen}
													exportDropdown={
														<ExportDropdown
															sectionId="performanceTrend_2"
															sectionName="Performance Trend"
															sectionData={performanceTrendExport}
														/>}
													dropdown={
														<>
															<Dropdown
																ismulti={false}
																isClearable={false}
																placeholder={"Select Trend"}
																options={perfTrendDropdownOptions}
																className="form_dropdown section_dropdown pl_20 mr_20"
																value={perfTrendFirstDropdownValue}
																selectedOptions={perfTrendFirstDropdownValue}
																setStatedropdown={perfTrendFirstDropdownHandler}
																dropdownLoading={perfTrendDropdownOptionsLoading}
															/>
															<Dropdown
																ismulti={false}
																isClearable={false}
																placeholder={"Select Trend"}
																options={perfTrendDropdownOptions}
																className="form_dropdown section_dropdown  pl_20"
																value={perfTrendSecondDropdownValue}
																selectedOptions={perfTrendSecondDropdownValue}
																setStatedropdown={perfTrendSecondDropdownHandler}
																dropdownLoading={perfTrendDropdownOptionsLoading}
															/>
															<Dropdown
																ismulti={false}
																isClearable={false}
																placeholder={"Select Trend"}
																options={perfTrendDropdownOptions}
																className="form_dropdown section_dropdown  pl_20"
																value={perfTrendThirdDropdownValue}
																selectedOptions={perfTrendThirdDropdownValue}
																setStatedropdown={perfTrendThirdDropdownHandler}
																dropdownLoading={perfTrendDropdownOptionsLoading}
															/>
														</>
													}
													parentId={"performance_trend"}
													title={`Performance Trend`}
													chart={performanceTrendSection2}
												/>
												<div data-html2canvas-ignore={true}>
													{isMultiLineCluster && <button onClick={() => setIsPerformanceTrendOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
														<ExpandIcon />
													</button>}
													<Dropdown
														ismulti={false}
														isClearable={false}
														placeholder={"Select Trend"}
														options={perfTrendDropdownOptions}
														className="form_dropdown section_dropdown pl_20 mr_20"
														value={perfTrendFirstDropdownValue}
														selectedOptions={perfTrendFirstDropdownValue}
														setStatedropdown={perfTrendFirstDropdownHandler}
														dropdownLoading={perfTrendDropdownOptionsLoading}
													/>
													<Dropdown
														ismulti={false}
														isClearable={false}
														placeholder={"Select Trend"}
														options={perfTrendDropdownOptions}
														className="form_dropdown section_dropdown  pl_20"
														value={perfTrendSecondDropdownValue}
														selectedOptions={perfTrendSecondDropdownValue}
														setStatedropdown={perfTrendSecondDropdownHandler}
														dropdownLoading={perfTrendDropdownOptionsLoading}
													/>
													<Dropdown
														ismulti={false}
														isClearable={false}
														placeholder={"Select Trend"}
														options={perfTrendDropdownOptions}
														className="form_dropdown section_dropdown  pl_20"
														value={perfTrendThirdDropdownValue}
														selectedOptions={perfTrendThirdDropdownValue}
														setStatedropdown={perfTrendThirdDropdownHandler}
														dropdownLoading={perfTrendDropdownOptionsLoading}
													/>
													<ExportDropdown
														sectionId="performance_trend"
														sectionName="Performance Trend"
														sectionData={performanceTrendExport}
													/>
												</div>
											</div>
										</div>
										<div className="grid grid_cols_4">
											<div className="grid col_span_3">
												<div className="section_card_body">
													<div className="section_card_chart_wrapper">
														{performanceTrendSection}
													</div>
												</div>
											</div>
											<div className="grid col_span_1">
												<div className="section_card_body">
													<div className="chart_details_wrapper">
														{performanceTrendTableSection}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div id="Bottom-performance-market" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
										<div className="grid col_span_4">
											<div className="section_card_header">
												<div className="section_info">
													<h2 className="section_card_title">
														Top / Bottom Performing Markets
													</h2>
												</div>
												<div data-html2canvas-ignore={true}>
													<Dropdown
														ismulti={false}
														placeholder={"Select Market"}
														options={topBottomPerformDataDropdownOptions}
														className="form_dropdown section_dropdown pl_20"
														value={topBottomPerformDataDropdownValue}
														selectedOptions={topBottomPerformDataDropdownValue}
														dropdownLoading={topBottomPerformDataDropdownOptionsLoading}
														setStatedropdown={topBottomPerformDataDropdownHandler}
													/>
													<ExportDropdown
														sectionId="Bottom-performance-market"
														sectionName="Top - Bottom Performing Markets"
														sectionData={topBottomPerformingMarketsExport}
													/>
												</div>
											</div>
										</div>
										<div className="grid col_span_4">
											<div className="section_card_body">
												<div className="section_card_chart_wrapper flex column">
													{topBottomPerfMarketSection}
												</div>
												{isTopBottomPerformingMarketsLoaded &&
													!isTopBottomPerformingMarketsLoading &&
													Object.keys(topBottomPerformingMarketsData)
														.length !== 0 && (
														<div className="w_100">
															<ul className="icz-rilist-inline">
																<li>
																	<div className="legend-circle circleGreen"></div>{" "}
																	Growth
																</li>
																<li>
																	<div className="legend-circle circleRed"></div>{" "}
																	Degrowth
																</li>
															</ul>
														</div>
													)}
											</div>
										</div>
									</div>

									<div className="grid_margin_bottom">
										{topBottomPerfMarketTableSection}
									</div>

									<div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
										<div className="grid col_span_4">
											<div className="section_card_header p_b_0 ">
												<div className="flex column align_start">
													<div className="section_info">    <h2 className="section_card_title">
														Market - Campaigns / Creatives Deepdive
													</h2>
													</div>
												</div>
												<div data-html2canvas-ignore={true}>
													<ExportDropdown
														sectionId={"brandwise_performance_section"}
														sectionName={`MarketTreemap_Market Campaign Deepdive_${marketCodeLabel + stateCodeLabel}`}
														sectionData={marketCampaignDeepdive}
													/>
												</div>
											</div>
										</div>
										<div className='grid col_span_4' style={{ margin: "0 20px" }}>
											{(
												<div className="treemap_breadcrumb">
													<span
														onClick={() => {
															setTreemapParams({
																brand_id: BRAND_ID,
																start_date: selectedDates.startDate,
																end_date: selectedDates.endDate,
																previous_start_date: selectedDates.prevStartDate,
																previous_end_date: selectedDates.prevEndDate,
																market_code: marketCode,
																level: 1,
															});
															setTreemapBrand("");
															setTreemapStateCode('')
															setTreemapPlatform("");
														}}
													>
														Home
													</span>
													{treemapStateCode && (
														<span
															onClick={() => {
																setTreemapParams({
																	brand_id: BRAND_ID,
																	start_date: selectedDates.startDate,
																	end_date: selectedDates.endDate,
																	previous_start_date: selectedDates.prevStartDate,
																	previous_end_date: selectedDates.prevEndDate,
																	market_code: marketCode === undefined || marketCode === null ? treemapBrand : marketCode,
																	level: 2,
																});
																setTreemapPlatform("");
															}}
														>
															{" "}/ <TextFormatter string={treemapStateCode} />
														</span>
													)}

													{treemapPlatform && (
														<span>
															{" / "}
															<TextFormatter
																string={treemapPlatform}
															/>
														</span>
													)}
												</div>
											)}
										</div>
										<div className="grid col_span_4">
											<div className="section_card_body">
												{marketCampaignDeepdiveSection}
											</div>
										</div>
									</div>

									<div id="Bottom-performance-market" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
										<div className="grid col_span_4">
											<div className="section_card_header">
												<div className="section_info">
													<h2 className="section_card_title">
														Overall Media Performance
													</h2>
												</div>
												<div data-html2canvas-ignore={true}>
													<ExportDropdown
														sectionId="overall_media_performance"
														sectionName="Overall Media Performance"
														sectionData={overallMediaPerformanceExport}
													/>
												</div>
											</div>
										</div>
										<div className="grid grid_cols_4">
											<div className="grid col_span_3">
												<div className="section_card_body">
													<div className="section_card_chart_wrapper">
														{overallMediaPerformanceSection}
													</div>
												</div>
											</div>
											<div className="grid col_span_1">
												<div className="section_card_body">
													<div className="chart_details_wrapper">
														{overallMediaPerformanceTableSection}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="section_card section_dropdown_header">
										<div className="grid grid_cols_1">
											<div className="section_card_header">
												<h2 className="section_card_title">Competitor TV and Sales Analysis</h2>
											</div>
										</div>
									</div>
									<div id="performance_source_section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
										<div className="grid grid_cols_1">
											<div className="section_card_header">
												<div className="section_info">
													<h2 className="section_card_title">
														Brand Performance Overview
													</h2>
												</div>
												<div data-html2canvas-ignore={true}>
													<Dropdown
														ismulti={false}
														placeholder={"Select Overview"}
														options={brandPerfOverviewDropdownOptions}
														className="form_dropdown section_dropdown pl_20"
														value={brandPerfOverviewFirstDropdownValue}
														selectedOptions={brandPerfOverviewFirstDropdownValue}
														dropdownLoading={brandPerfOverviewDropdownOptionsLoading}
														setStatedropdown={brandPerfOverviewFirstDropdownHandler}
													/>
													<Dropdown
														ismulti={false}
														placeholder={"Select Overview"}
														options={brandPerfOverviewDropdownOptions}
														className="form_dropdown section_dropdown pl_20"
														value={brandPerfOverviewSecondDropdownValue}
														selectedOptions={brandPerfOverviewSecondDropdownValue}
														dropdownLoading={brandPerfOverviewDropdownOptionsLoading}
														setStatedropdown={brandPerfOverviewSecondDropdownHandler}
													/>
													<ExportDropdown
														sectionId={"brand_performance_overview"}
														sectionName={"Brand Performance Overview"}
														sectionData={brandPerfOverviewExport}
													/>
												</div>
											</div>
										</div>
										<div className="grid col_span_4">
											<div className="grid col_span_2">
												<div className="section_card_body">
													<div className="section_card_chart_wrapper">
														{brandPerfOverviewChart}
													</div>
												</div>
											</div>
											<div className="grid col_span_1">
												<div className="section_card_body">
													<div className="chart_details_wrapper">
														{brandPerfOverviewDetailsData}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="grid grid_cols_4 fullpage_section_card section_card riCompare">
										<div className="section_card_body">
											{topPostsChart}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	)
}
