import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import { BlueColorStart, BlueColorStart500, PurpleColorStart500, USER_COUNTRY } from "../../../constants/constants";
import { CountryCode, CountryCurrency } from "../../../constants/countryCurrency";
import { useSelector } from "react-redux";
import { tooltipNumberFormatting } from "../../../utils/Utils";

am5.addLicense("AM5C258896422");

const StackedClusteredTwoBarChart = ({
	chartId,
	chartClass,
	chartData,
	chartLabels,
	minGridDistance
}) => {

	let minimumGridDistanceValue = null

	if (minGridDistance === undefined) {
		minimumGridDistanceValue = null
	} else {
		minimumGridDistanceValue = minGridDistance
	}

	// USER DATA
	const userData = useSelector((state) => state.userData.user[0]);

	let userCountryCode = userData?.country_code ?? "IN"

	let country = {}

	CountryCode.map((data) => {
		if (data.countryCode === userCountryCode) {
			country = data
		}
	})

	const convertPrefixes = (name) => {
		const tonnesMetrics = ["Monthly Sales Vol."];

		const liquidMetrics = ["Sales Volume"];
		const percentageMetrics = ["Video Completion Rate", "Audience Retention Rate", "Engagement Rate", "TV Spends %", "Digital Meta Spends %", "Digital DV360 Spends %", "TV Impressions %", "Digital DV360 Impressions %", "Digital Meta Impressions %", "Digital google spends %", "Digital Meta Impression %", "Digital Google Impressions %"];

		if (liquidMetrics.includes(name)) {
			return "-L"
		}
		else if (percentageMetrics.includes(name)) {
			return "%"
		} else if (tonnesMetrics.includes(name)) {
			return " T"
		}
		else {
			return ""
		}
	}



	useLayoutEffect(() => {
		const addCurrencySymbols = (data) => {
			const currencyMetrics = ["Spends", "Spend", "Sales Value", "TV Spends", "Digital Meta Spends", 'Digital google spends'];
			if (currencyMetrics.includes(data)) {
				// root.numberFormatter.set("bigNumberPrefixes", [
				// 	{ number: 1e3, suffix: "K" },
				// 	{ number: 1e5, suffix: "L" },
				// 	{ number: 1e7, suffix: "Cr" },
				// ])
				return country.currencySymbol
			}
			else {
				return ""
			}
		}

		var root = am5.Root.new(chartId);

		let colorSet = [0x11a1fd, 0x5a75f9, 0xff9931, 0x5a75f9];

		root.setThemes([am5themes_Animated.new(root)]);
		root.interfaceColors.set("grid", am5.color(0xffffff)); // to chang the color of grid lines

		var chart = root.container.children.push(
			am5xy.XYChart.new(root, {
				pinchZoomX: "none",
				pinchZoomY: "none",
				panX: false,
				panY: false,
				panXY: false,
				wheelX: "none",
				wheelY: "none",
				layout: root.verticalLayout,
				paddingRight: 0,
				paddingTop: 5,
				paddingBottom: 0,
				paddingLeft: 0,
				maxTooltipDistance: -1,
			})
		);
		var cursor = chart.set(
			"cursor",
			am5xy.XYCursor.new(root, {
				behavior: "zoomX",
			})
		);
		cursor.lineY.set("visible", false);
		cursor.lineX.set("visible", false);

		var data = chartData

		var xRenderer = am5xy.AxisRendererX.new(root, {
			minGridDistance: minimumGridDistanceValue,
			stroke: am5.color(0x757575),
			strokeWidth: 0.3,
			strokeOpacity: 1,
			cellStartLocation: 0.1,
			cellEndLocation: 0.9
		});

		xRenderer.labels.template.setAll({
			rotation: -80,
			centerY: am5.p50,
			centerX: am5.p100,
			paddingRight: 15
		});

		var xAxis = chart.xAxes.push(
			am5xy.CategoryAxis.new(root, {
				categoryField: "label",
				renderer: xRenderer,
			})
		);

		xRenderer.grid.template.setAll({
			location: 1
		})

		xAxis.get("renderer").labels.template.setAll({
			oversizedBehavior: "truncate", // options: "none"/"wrap"/"fit"
			maxWidth: 100,
			textAlign: "center",
			fontSize: 12,
			fontFamily: "Ubuntu",
			fill: "#757575",
			fontWeight: "400"
		});

		xAxis.data.setAll(data);

		var yAxis = chart.yAxes.push(
			am5xy.ValueAxis.new(root, {
				min: 0,
				extraMax: 0.1,
				renderer: am5xy.AxisRendererY.new(root, {
					strokeOpacity: 0.1,
					stroke: am5.color(0x757575),
					strokeWidth: 0.3,
					strokeOpacity: 1,
				})
			})
		);

		yAxis.get("renderer").labels.template.setAll({
			oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
			maxWidth: 150,
			textAlign: "center",
			fontSize: 12,
			fontFamily: "Ubuntu",
			fill: "#757575",
			fontWeight: "400",
			fill: am5.color(colorSet[0]),
		});



		root.numberFormatter.setAll({
			numberFormat: "#.##a",

			// Group only into M (millions), and B (billions)
			bigNumberPrefixes: [
				{ number: 1e3, suffix: "K" },
				{ number: 1e6, suffix: "M" },
				{ number: 1e9, suffix: "B" },
			],

			// Do not use small number prefixes at all
			smallNumberPrefixes: [],
		});

		// Add legend
		var legend = chart.children.push(
			am5.Legend.new(root, {
				// background: am5.Rectangle.new(root, {
				//     fill: am5.color(0xff5599),
				//     fillOpacity: 0.2
				// }), // to add the backgrounD to the legends
				position: "relative", // options: "absolute" / "relative"
				// width: am5.percent(100),
				height: am5.percent(30),
				x: am5.percent(50),
				centerX: am5.percent(50),
				// clickTarget: "none", // TO REMOVE TOGGLE ON LEGENDS
				marginTop: 15,
				// marginBottom: 15,
				layout: root.gridLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
				layout: am5.GridLayout.new(root, {
					maxColumns: 10,
					fixedWidthGrid: true,
				}),
				verticalScrollbar: am5.Scrollbar.new(root, {
					orientation: "vertical",
				}),
			})
		);

		legend.markers.template.setAll({
			width: 13,
			height: 13,
		});

		legend.markerRectangles.template.setAll({
			cornerRadiusTL: 10,
			cornerRadiusTR: 10,
			cornerRadiusBL: 10,
			cornerRadiusBR: 10,
		});

		legend.labels.template.setAll({
			fontSize: 12,
			fontWeight: "400",
			fill: "#213345",
		});

		legend.labels.template.setAll({
			// maxWidth: 150,
			//centerY: 0, // if we want labels to be top-aligned
			oversizedBehavior: "wrap",
		});


		const createSeries = (name, field, index) => {

			var series = chart.series.push(am5xy.ColumnSeries.new(root, {
				stacked: true,
				name: name,
				xAxis: xAxis,
				yAxis: yAxis,
				valueYField: field,
				categoryXField: "label",
				min: 0,
				max: 100,
			}));

			series.columns.template.setAll({
				// cornerRadiusTL: 7,
				// cornerRadiusTR: 7,
				width: 30,
			});

			series.columns.template.width = am5.percent(60);

			series.data.setAll(data);
			legend.data.push(series);


			var tooltip = series.set(
				"tooltip",
				am5.Tooltip.new(root, {
					getFillFromSprite: false,
					getStrokeFromSprite: false,
					autoTextColor: false,
					pointerOrientation: "horizontal",
					position: "pointer",
				})
			);

			tooltip.get("background").setAll({
				fill: am5.color(0x213345),
				maxWidth: "auto",
				oversizedBehavior: "truncate",
				cornerRadius: 5,
			});

			tooltip.label.setAll({
				text: "[bold]{categoryX}[/]",
				fill: am5.color(0xffffff),
				fontSize: 13,
			});

			tooltip.label.adapters.add("text", function (text, target) {
				chart.series.each(function (series) {
					// let Prefixes = convertPrefixes(series.get("name"), series.get("valueYField"));
					// let CurrencySymbol = addCurrencySymbols(series.get("name"));

					// text += "\n[" + series.get("stroke").toString() + "]●[/] [bold width:80px]" + series.get("name") + ":[/] [bold width:100px]" + CurrencySymbol + "{" + series.get("valueYField") + "}" + Prefixes + "[/]";
					// if (series.get("childLabel") != undefined) {
					// 	text += "\n[]●[/] [bold width:100px]" + series.get("childLabel") + ": [bold width:100px]" + addCurrencySymbols(series.get("childLabel")) + "{" + series.get("childValue") + "}" + convertPrefixes(series.get("childLabel")) + "[/]";
					// }

					var tooltipDataItem = series?.get("tooltipDataItem");
					text += "\n[" + series.get("stroke").toString() + "]●[/] " + "[bold]" + series.get("name") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("name"), tooltipDataItem?.get("valueY"), country) + "[/]";

					if (series.get("childLabel") != undefined) {
						text += "\n[" + series.get("stroke").toString() + "]●[/] " + "[bold]" + series.get("childLabel") + ":[/]" + " " + "[bold]" + tooltipNumberFormatting(series.get("childLabel"), tooltipDataItem?.dataContext[series.get('childValue')], country) + "[/]";
					}
				});
				return text;
			});
		}
		chartLabels.map((label, index) => {
			createSeries(
				label.label,
				label.value,
				index
			);
		});

		return () => {
			root.dispose();
		};
	}, [chartData, chartLabels]);


	return (
		<Wrapper>
			<div id={chartId} className={chartClass}></div>
		</Wrapper>
	);
};

StackedClusteredTwoBarChart.propTypes = {
	chartId: PropTypes.string,
	chartClass: PropTypes.string,
	showLegend: PropTypes.bool,
	showLabels: PropTypes.bool,
	showVerticalLabels: PropTypes.bool,
	showHalfVerticalLabels: PropTypes.bool,
	chartData: PropTypes.array,
	chartLabels: PropTypes.array,
	showShortVerticalLabels: PropTypes.bool,
	chartColorSet: PropTypes.array,
	minimumGridDistance: PropTypes.any,
	minGridDistance: PropTypes.number
};

export default StackedClusteredTwoBarChart;
