const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    user: []
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserData(state, action) {
            state.user = action.payload;
        },
        resetUserData: () => initialState,
    },
});

export const { setUserData, resetUserData } = userSlice.actions;

export default userSlice.reducer;
