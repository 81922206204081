// PERSONAL DETAILS
export const FirstNameCharLimit = 100;
export const MiddleNameCharLimit = 100;
export const LastNameCharLimit = 100;
export const EmailCharLimit = 254;
export const PasswordCharLimit = 20;
export const MobileNoCharLimit = 10;

// COMPANY DETAILS
export const CompanyNameCharLimit = 100;
export const CompanyWebsiteCharLimit = 100;
export const CompanyAboutCharLimit = 1500;
export const CompanyCategoryCharLimit = 100;

// LOCATION DETAILS
export const TimeZoneCharLimit = 100;
export const AddressLineCharLimit = 255;
export const LandmarkCharLimit = 255;
export const PincodeCharLimit = 6;
export const CountryCharLimit = 100;
export const StateCharLimit = 100;
export const CityCharLimit = 100;
