const InfluencersIcon = (props) => {
    return (
        <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
            <g id="Group_503" data-name="Group 503" transform="translate(-1025 -394)">
                <g id="Group_208" data-name="Group 208" transform="translate(31.212 -54.263)">
                    <g id="Group_207" data-name="Group 207" transform="translate(1002.788 462.263)">
                        <g id="Group_209" data-name="Group 209" transform="translate(0 0)">
                            <path id="Path_202" data-name="Path 202" d="M28.08,14.834a7.6,7.6,0,0,0-3.852-4.966.889.889,0,0,0,.167-.09,5.264,5.264,0,0,0,1.661-4.792A5.322,5.322,0,0,0,19.985.57,5.1,5.1,0,0,0,15.7,4.242a5.119,5.119,0,0,0,1.408,5.466c.18.18.146.222-.044.333A7.455,7.455,0,0,0,13.7,14.11c-.032.092-.008.22-.15.273a7.889,7.889,0,0,0-2.079-1.635,5.346,5.346,0,1,0-7.027-8.06A5.1,5.1,0,0,0,2.657,8.169,5.3,5.3,0,0,0,4.48,12.742c-.066.044-.1.07-.142.095A7.373,7.373,0,0,0,.5,19.513C.5,22.028.5,23.23.5,25.745a2.287,2.287,0,0,0,2.347,2.526c2.764,0,4.375-.017,7.139,0a1.59,1.59,0,0,0,1.226-.509c2.433-2.448,4.88-4.882,7.314-7.33.2-.2.3-.236.516-.012.789.821,1.6,1.617,2.41,2.42a1.987,1.987,0,0,0,.322.278,1.1,1.1,0,0,0,1.465-.274c3.75-3.749,9.4-9.4,13.148-13.151" transform="translate(-0.501 -0.5)" fill="none" strokeLinecap="round" strokeWidth="1.5" />
                        </g>
                    </g>
                    <g id="Icon_feather-arrow-up" data-name="Icon feather-arrow-up" transform="translate(1037.457 467.981) rotate(45)">
                        <path id="Path_171" data-name="Path 171" d="M0,6.808V0" transform="translate(3.404 0)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <path id="Path_172" data-name="Path 172" d="M0,3.4,3.4,0l3.4,3.4" transform="translate(0 0)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </g>
                </g>
            </g>
        </svg>

    );
};

export default InfluencersIcon;
