import React from 'react'

export default function PrivateIcon(props) {
  return (
    <svg className={props.className} width="40" height="50" viewBox="0 0 40 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.4 16.9999H3C1.89543 16.9999 1 17.8953 1 18.9999V46.9999C1 48.1044 1.89543 48.9999 3 48.9999H37.4C38.5046 48.9999 39.4 48.1044 39.4 46.9999V18.9999C39.4 17.8953 38.5046 16.9999 37.4 16.9999Z" strokeWidth='2.3' stroke="white" strokeLinejoin="round" />
      <path d="M9.00012 17V2.6C9.00012 2.17565 9.16869 1.76869 9.46875 1.46863C9.76881 1.16857 10.1758 1 10.6001 1H20.2001" stroke="white" strokeLinejoin="round" strokeWidth='2.3' />
      <path d="M31.4 17V2.6C31.4 2.17565 31.2314 1.76869 30.9313 1.46863C30.6313 1.16857 30.2243 1 29.8 1H20.2" stroke="white" strokeLinejoin="round" strokeWidth='2.3' />
      <path d="M20.1998 34.6C22.8507 34.6 24.9998 32.451 24.9998 29.8C24.9998 27.149 22.8507 25 20.1998 25C17.5488 25 15.3998 27.149 15.3998 29.8C15.3998 32.451 17.5488 34.6 20.1998 34.6Z" stroke="white" strokeLinejoin="round" strokeWidth='2.3' />
      <path d="M20.2 34.6002V42.6002" stroke="white" strokeLinejoin="round" strokeWidth='2.3' />
    </svg>

  )
}
