import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

// STORAGE
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper"
import Tab from "../../../../components/ui/Tab";

// UTILS
import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";

// CONSTANT
import { COKE_COMPANY_ID, HALEON_COMPANY_ID, IDFC_COMPANY_ID, IMPRIVATA_COMPANY_ID, OLX_AUTOS_COMPANY_ID } from "../../../../constants/constants";
import CampaignBingOverallOverall from "./CampaignBingOverall";

const CampaignBing = ({ platformData }) => {
  const { exportSummary, setExportPageName } = useContext(FullPageExportContext);

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  let companyID = userData?.company?.id;

  let tabData = [];

  if (companyID === COKE_COMPANY_ID || companyID === HALEON_COMPANY_ID || companyID === IDFC_COMPANY_ID || companyID === OLX_AUTOS_COMPANY_ID || companyID === IMPRIVATA_COMPANY_ID) {
    tabData = ["Overall"];
  } else {
    tabData = ["Overall", "Video"];
  }

  const [selectedTab, setSelectedTab] = useState(tabData[0]);

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Campaign", "Google", event)
  };

  useEffect(() => {
    setExportPageName("Campaign Bing " + selectedTab)
  }, [selectedTab])

  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <Tab
          varient={"primary"}
          tabData={tabData}
          activeTab={selectedTab}
          handleCallback={(event) => handleCallback(event)}
        />
      </div>
      {selectedTab.toLowerCase() === "overall" && <CampaignBingOverallOverall topCardData={{ Summary: exportSummary, "PlatformCards_Platform Cards": platformData }} />}
    </Wrapper>
  )
}

export default CampaignBing;