import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import axios from "../../../../components/helpers/axios";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";

// CHARTS
import BarChart from "../../../../components/ui/charts/BarChart";

// UTILS
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";
import EventTracker from "../../../../components/eventsTracking/EventTracker";

export default function MonthlyTraffic({ viewId }) {

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Website"
  let pagePlatform = "Traffic"
  let pagePrimaryTab = "Monthly Traffic"

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setExportPageName, exportSummary, setIsExportDisabled } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const selectedDates = useSelector((state) => state.date);

  // GLOBAL DROPDOWN
  const [dropdownValue, setDropdownValue] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [isDropdownOptionsLoading, setDropdownOptionsIsLoading] = useState(false);

  // DROPDOWN TOGGLER
  const dropdownSelectionHandler = (e) => {
    if (e === null) {
      setDropdownValue("");
      IAEvent_Dropdown_Visit("Marketing Intelligence", "Website", null, "Traffic", "Monthly Traffic", "Monthly Traffic", e)
    } else {
      setDropdownValue(e);
      IAEvent_Dropdown_Visit("Marketing Intelligence", "Website", null, "Traffic", "Monthly Traffic", "Monthly Traffic", e.label)
    }

    EventTracker({
      eventType: "dropdown_selected",
      module: pageModule,
      subModule: pageSubModule,
      tab: pagePrimaryTab,
      section: "Monthly Traffic",
      dropdownValue: e === null ? "Overall" : e.label,
      ...(BRAND_ID && { brands: BRAND_ID }),
      date: {
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate,
        previousStartDate: selectedDates.prevStartDate,
        previousEndDate: selectedDates.prevEndDate,
      }
    })

  };

  const fetchDropdownHandler = async () => {
    setDropdownOptionsIsLoading(true);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        view_id: viewId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("website/ga-channel-name/", config);

      setDropdownOptions(res?.data?.metrics ?? []);
      setDropdownValue(res?.data?.metrics[0] ?? '');
      setDropdownOptionsIsLoading(false);
    } catch {
      setDropdownOptions([]);
      setDropdownValue([]);
      setDropdownOptionsIsLoading(false);
    }
  };

  // MONTHLY COMPARISON
  const [monthlyComparison, setMonthlyComparison] = useState([]);
  const [monthlyComparisonLabels, setMonthlyComparisonLabels] = useState([]);

  const [isMonthlyComparisonLoading, setMonthlyComparisonIsLoading] =
    useState(false);
  const [isMonthlyComparisonLoaded, setMonthlyComparisonIsLoaded] =
    useState(false);
  const [monthlyComparisonerror, setMonthlyComparisonError] = useState(null);

  // TRAFFIC SOURCE
  const [trafficSource, setTrafficSource] = useState([]);
  const [trafficSourceLabels, setTrafficSourceLabels] = useState([]);

  const [isTrafficSourceLoading, setTrafficSourceIsLoading] = useState(false);
  const [isTrafficSourceLoaded, setTrafficSourceIsLoaded] = useState(false);
  const [trafficSourceerror, setTrafficSourceError] = useState(null);

  useEffect(() => {
    fetchDropdownHandler();
  }, [BRAND_ID, viewId, selectedDates]);

  useEffect(() => {
    viewId === undefined ?
      (
        <NoDataAvailableLoader chartType="barChartType" />
      ) :
      viewId.length === 0 ?
        (
          <NoDataAvailableLoader chartType="barChartType" />
        ) :
        (
          fetchMonthlyComparisonHandler()
        )
  }, [BRAND_ID, viewId, selectedDates, dropdownValue]);

  useEffect(() => {
    viewId === undefined ?
      (
        <NoDataAvailableLoader chartType="barChartType" />
      ) :
      viewId.length === 0 ?
        (
          <NoDataAvailableLoader chartType="barChartType" />
        ) :
        (
          fetchTrafficSourceHandler()
        )
  }, [BRAND_ID, viewId, selectedDates, dropdownValue]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // MONTHLY COMPARISON

  // TO FETCH MONTHLY COMPARISON FROM API
  const fetchMonthlyComparisonHandler = async () => {
    setMonthlyComparisonIsLoading(true);
    setMonthlyComparisonIsLoaded(false);
    setMonthlyComparisonError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        channel_name: dropdownValue.value,
        view_id: viewId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "website/ga-monthly-trafic-comparison/",
        config
      );

      setMonthlyComparison(res?.data?.data ?? []);
      setMonthlyComparisonLabels(res?.data?.dimension ?? []);
      setMonthlyComparisonIsLoading(false);
      setMonthlyComparisonIsLoaded(true);
      setMonthlyComparisonError(null);
    } catch (error) {
      setMonthlyComparison([]);
      setMonthlyComparisonLabels([]);
      setMonthlyComparisonIsLoading(false);
      setMonthlyComparisonIsLoaded(false);
      setMonthlyComparisonError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let monthlyComparisonSection =
    <Wrapper>

      {viewId === undefined ? (
        <NoDataAvailableLoader chartType="barChartType" />
      ) : viewId.length === 0 ? (
        <NoDataAvailableLoader chartType="barChartType" />
      ) : (
        <Loader loaderType="barChartLoader" />
      )}



    </Wrapper>

  // IF DATA IS LOADED
  if (isMonthlyComparisonLoaded && !isMonthlyComparisonLoading) {
    if (
      NoDataAvailableChecker(monthlyComparison) ||
      NoDataAvailableChecker(monthlyComparisonLabels)
    ) {
      monthlyComparisonSection =
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
    } else {
      monthlyComparisonSection = (
        <Wrapper>
          <ErrorBoundary>
            <BarChart
              chartClass="section_card_chart"
              chartData={monthlyComparison}
              chartId="monthlyComparison"
              chartLabels={monthlyComparisonLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (monthlyComparisonerror) {
    monthlyComparisonSection = (
      <Wrapper>
        <ServerErrorsLoader chartType="barChartType" error={monthlyComparisonerror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMonthlyComparisonLoading) {
    monthlyComparisonSection =
      <Wrapper>
        {viewId === undefined ? (
          <NoDataAvailableLoader chartType="barChartType" />
        ) : viewId.length === 0 ? (
          <NoDataAvailableLoader chartType="barChartType" />
        ) : (
          <Loader loaderType="barChartLoader" />
        )}

      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TRAFFIC SOURCE

  // TO FETCH TRAFFIC SOURCE FROM API
  const fetchTrafficSourceHandler = async () => {
    setTrafficSourceIsLoading(true);
    setTrafficSourceIsLoaded(false);
    setTrafficSourceError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        channel_name: dropdownValue.value,
        view_id: viewId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("website/ga-trafic-source/", config);

      setTrafficSource(res?.data?.trafic_source_list ?? []);
      setTrafficSourceLabels(res?.data?.dimension ?? []);
      setTrafficSourceIsLoading(false);
      setTrafficSourceIsLoaded(true);
      setTrafficSourceError(null);
    } catch (error) {
      setTrafficSource([]);
      setTrafficSourceLabels([]);
      setTrafficSourceIsLoading(false);
      setTrafficSourceIsLoaded(false);
      setTrafficSourceError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let trafficSourceSection =
    <Wrapper>
      {viewId === undefined ? (
        <NoDataAvailableLoader chartType="barChartType" />
      ) : viewId.length === 0 ? (
        <NoDataAvailableLoader chartType="barChartType" />
      ) : (
        <Loader loaderType="barChartLoader" />
      )}

    </Wrapper>

  // IF DATA IS LOADED
  if (isTrafficSourceLoaded && !isTrafficSourceLoading) {
    if (
      NoDataAvailableChecker(trafficSource) ||
      NoDataAvailableChecker(trafficSourceLabels)
    ) {
      trafficSourceSection =
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
    } else {
      trafficSourceSection = (
        <Wrapper>
          <ErrorBoundary>
            <BarChart
              chartClass="section_card_chart"
              chartData={trafficSource}
              chartId="trafficSource"
              chartLabels={trafficSourceLabels}
              showShortVerticalLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (trafficSourceerror) {
    trafficSourceSection =
      <Wrapper>
        <ServerErrorsLoader error={trafficSourceerror} />
      </Wrapper>
  }

  // IF DATA LOADING
  if (isTrafficSourceLoading) {
    trafficSourceSection =
      <Wrapper>
        {viewId === undefined ? (
          <NoDataAvailableLoader chartType="barChartType" />
        ) : viewId.length === 0 ? (
          <NoDataAvailableLoader chartType="barChartType" />
        ) : (
          <Loader loaderType="barChartLoader" />
        )}

      </Wrapper>
  }

  var exportDd = dropdownValue === undefined || dropdownValue === null || dropdownValue === "" ? "" : ": " + dropdownValue.value

  var allData = {
    "Summary": exportSummary,
    [`MonthlyTraffic_Monhtly Traffic Comparison_${exportDd}`]: monthlyComparison,
    [`TrafficSource_Traffic Source_${exportDd}`]: trafficSource,
  };

  useEffect(() => {
    setExportPageName('Website Traffic - Monthly Traffic')
    setFullPageExport(allData);
  }, [monthlyComparison, trafficSource]);

  useEffect(() => {
    if (
      (monthlyComparisonerror !== null ? true : isMonthlyComparisonLoaded && (isMonthlyComparisonLoading === false)) &&
      (trafficSourceerror !== null ? true : isTrafficSourceLoaded && (isTrafficSourceLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    monthlyComparisonerror, isMonthlyComparisonLoaded, isMonthlyComparisonLoading,
    trafficSourceerror, isTrafficSourceLoaded, isTrafficSourceLoading,
  ])


  return (
    <>
      <div className="grid grid_cols_1 flex justify_end grid_margin_bottom">
        <Dropdown
          ismulti={false}
          isClearable={true}
          dropdownLoading={isDropdownOptionsLoading}
          placeholder={"Select"}
          options={dropdownOptions}
          value={dropdownValue}
          selectedOptions={dropdownValue}
          setStatedropdown={dropdownSelectionHandler}
          className="form_dropdown section_dropdown"
        />
      </div>

      <div className="grid grid_cols_2">
        <div
          id="monthly_traffic_comp"
          className="grid grid_cols_1 section_card"
        >
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info"> <h2 className="section_card_title">Monthly Traffic Comparison {dropdownValue && " : " + dropdownValue.value}
              </h2> <SectionDefinationsBadge
                  title={"Monthly Traffic Comparison"}
                  module="website"
                  platform="traffic"
                  section="monthly"
                />
              </div>
              <div data-html2canvas-ignore={true}>
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"monthly_traffic_comp"}
                  sectionName={`MonthlyTraffic_Monthly Traffic Comparison_${exportDd}`}
                  sectionData={monthlyComparison}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {monthlyComparisonSection}
              </div>
            </div>
          </div>
        </div>
        <div id="traffic_source" className="grid grid_cols_1 section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info">   <h2 className="section_card_title">Traffic Source{dropdownValue && " : " + dropdownValue.value}
              </h2> <SectionDefinationsBadge
                  title={"Traffic Source"}
                  module="website"
                  platform="traffic"
                  section="monthly"
                />
              </div>
              <div data-html2canvas-ignore={true}>
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"traffic_source"}
                  sectionName={`TrafficSource_Traffic Source_${exportDd}`}
                  sectionData={trafficSource}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {trafficSourceSection}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
